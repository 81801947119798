import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  minDate,
} from '@rxweb/reactive-form-validators';

export class CardDetailsModel {
  @prop()
  id: number;

  @required()
  @pattern({ expression: { CardNumber: /^[0-9 ]{14,23}$/ } })
  cardNumber: string;

  @required()
  @maxLength({ value: 5 })
  @pattern({
    expression: {
      ExpirationDate: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
    },
  })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @minDate({
    conditionalExpression: function (control: AbstractControl) {
      if (
        control['expirationDate'] != null &&
        control['expirationDate'].length == 5
      ) {
        let [expMonth, expYear] = control['expirationDate'].split('/');
        let currentMonth = new Date().getMonth() + 1;
        let currentYear = parseInt(
          new Date().toLocaleDateString('en', {
            year: '2-digit',
          })
        );

        return parseInt(expYear) > currentYear ||
          (parseInt(expYear) == currentYear &&
            parseInt(expMonth) >= currentMonth)
          ? false
          : true;
      }
      return false;
    },
    message: 'Invalid Expiration Date',
  })
  expirationDate: string;

  @prop()
  @required()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @minLength({ value: 3, message: 'Invalid cvv code' })
  @maxLength({ value: 4 })
  cvv: number;

  @prop()
  isRecurring: boolean;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  frequency: string;

  @prop()
  isEndOfMonth: boolean;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  scheduledDate: string;

  @prop()
  totalAmount: number;

  @prop()
  agreesToSurcharge: boolean;

  @prop()
  surchargeAmount: number;

  @prop()
  surchargePercent: any;

  @prop()
  cardType: string;

  @prop()
  @maxLength({ value: 2, message: 'Invalid installment count' })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  installment: number;

  @prop()
  isInstallmentTransaction: boolean = false;

  @prop()
  skipPayment: boolean = false;
}
