import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NonMfaLoginService extends CrudService<any, any> {
  _url: string;
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_AUTHENTICATION_URL}`);
    this._url = this._base + '/api/v2/Authentication/GetToken'
  }

  getNonMfaToken(clientId: string, emailId: string, uniqueToken: string): Observable<any> {
    return this._http.get<any>(
        this._url + '?clientId='+clientId + '&emailId='+emailId + '&uniqueToken='+uniqueToken
    );
  }
}
