<mat-spinner *ngIf="isLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{loadMessage}}
</mat-spinner>
<div class="transactions-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="80%">
        <span class="totalTransactionLbl">Total Transactions</span>
    </div>
    <div fxLayout="column" fxFlex="20%">
        <button data-html2canvas-ignore="true" mat-stroked-button color="primary" class="white-button-style reportBtn" (click)="navigateToReportPage()">
            VIEW MORE
        </button>
    </div>
</div>
<div class="transactions-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="6%">
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
            <mat-label data-html2canvas-ignore="true" class="ach-amount-style"> Transactions (in %) </mat-label>
        </div>
    </div>
    <div fxLayout="column" fxFlex="94%">
        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
            [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
        </canvas>
    </div>
</div>