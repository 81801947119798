import { AbstractControl } from '@angular/forms';
import { required, prop, maxLength, pattern, minLength, propObject, maxNumber, minNumber } from '@rxweb/reactive-form-validators';

export class HostedPaymentDesignCustomisation {
  @prop()
  logo: string;

  @prop()
  themeTextColor: string;

  @prop()
  themeColor: string;

  @prop()
  themeFont: string;

  @prop()
  uploadedFilelist: any[] = [];
}

export class HostedPaymentConfiguration {
  @prop()
  isInvoiceNumberView: boolean = false;


  @prop()
  isDescriptionView: boolean = false;


  @prop()
  paymentOptionAch: string;

  @prop()
  paymentOptionCard: string;

  @prop()
  paymentOptionTypes: any;

  @prop()
  finzeoPaymentOptionsType: any;

  @prop()
  availRecuringAch: string;

  @prop()
  availRecuringCard: string;

  @prop()
  availRecurringFor: Array<string> = [];

  @prop()
  availConvenienceFee: string;

  @prop()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } ,message : 'Only numbers with 2 decimal points are allowed'})
  @minNumber({ value: 0.1,  conditionalExpression: function (control: AbstractControl) {
    return (this.availConvenienceFee == "Yes" ? 0.1 : 0.0);
  } })
  @maxNumber({ value: 100 })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.availConvenienceFee == "Yes" ? true : false;
    }
  })
  convenienceFeeAmount: number;

  @prop()
  shippingDetails: boolean = false;

  @prop()
  sendNotificationSMS: string;

  @prop()
  sendNotificationEmail: string;

  @prop()
  sendNotificationSMSOwner: string;

  @prop()
  sendNotificationEmailOwner: string;

  @prop()
  sendNotificationOn: Array<string> = [];

  @prop()
  sendNotificationToOwnerOn: Array<string> = [];


  @prop()
  @maxLength({ value: 50 })
  @pattern({ expression: { 'Email': /^[\w%\+\-]+(\.[\w%\+\-]+)*@\w+((-\w+)|(\w*))\.[a-z]{3,3}$/ }, message: 'Please enter valid email' })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  email: string;

  @prop()
  registeredNumber: string;

  @prop()
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Number': /^(\+\d{1,3}[- ]?)?\d{10}$/ }, message: 'Please enter valid mobile number' })   
  @pattern({ expression: { 'Number': /^\+[1-9]{1}[0-9]{3,14}$/ }, message: 'Please enter country code' })
  mobileNo: string;

  @prop()
  emailList: Array<string> = [];

  @prop()
  mobileList: Array<string> = [];

  @prop()
  customerPortalOnBoarding : string;
}

export class HostedPaymentTabModel {
  @propObject(HostedPaymentDesignCustomisation)
  customisationTabForm: HostedPaymentDesignCustomisation;

  @propObject(HostedPaymentConfiguration)
  configurationTabForm: HostedPaymentConfiguration;
  
  @prop()
  finzeoPaymentOption: string;
}


/* export class HostedPaymentModel {
  @propObject(HostedPaymentTabModel)
  hostedPaymentTabModel: HostedPaymentTabModel;

 
} */

