// Shared module's Components

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DataListComponent } from './components/data-list/data-list.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { NotificationService } from './services/notification/notification.service';
import { AuthService } from './services/authentication/authentication.service';
import { SignalRService } from './services/signalR/signal-r.service';
import { BrowserModule } from '@angular/platform-browser';
import { SelectBankAccountComponent } from './components/feature-related/select-bank-account/select-bank.component';
import { SearchCustomerComponent } from './components/feature-related/search-customer/search-customer.component';
import { SelectProcessorComponent } from './components/feature-related/select-processor/select-processor.component';
import { DBHelperService } from './services/helper/db-helper.service';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SuccessDialogComponent } from './components/dialogs/success-dialog/success-dialog.component';
import { CommonService } from './services/common/common.service';
import { MaskPipePipe } from './utils/mask-pipe.pipe';
import { ExportDialogComponent } from './components/dialogs/export-dialog/export-dialog.component';
import { ImportDialogComponent } from './components/dialogs/import-dialog/import-dialog.component';
import { TwoDigitDecimalNumberDirective } from './utils/two-digit-decimal-number.directive';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { SendInvoiceDialogComponent } from './components/dialogs/send-invoice-dialog/send-invoice-dialog.component';
import { SendByEmailComponent } from './components/dialogs/send-invoice-dialog/send-by-email/send-by-email.component';
import { SendBySmsComponent } from './components/dialogs/send-invoice-dialog/send-by-sms/send-by-sms.component';
import { SubmitReviewDialogComponent } from './components/dialogs/submit-review-dialog/submit-review-dialog.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { GradeScoreChartComponent } from './components/feature-related/grade-score-chart/grade-score-chart.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { EulaDialogComponent } from './components/dialogs/eula-dialog/eula-dialog.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    ReactiveFormsModule,
    GaugeChartModule,
    NgxIntlTelInputModule,
  ],
  declarations: [
    LoadingSpinnerComponent,
    DataListComponent,
    DataTableComponent,
    SearchCustomerComponent,
    SelectBankAccountComponent,
    SelectProcessorComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    ExportDialogComponent,
    ImportDialogComponent,
    MaskPipePipe,
    TwoDigitDecimalNumberDirective,
    PagenotfoundComponent,
    SendInvoiceDialogComponent,
    SendByEmailComponent,
    SendBySmsComponent,
    SubmitReviewDialogComponent,
    StarRatingComponent,
    GradeScoreChartComponent,
    EulaDialogComponent
  ],
  providers: [
    CommonService,
    AuthService,
    NotificationService,
    SignalRService,
    DBHelperService,
  ],
  exports: [
    LoadingSpinnerComponent,
    DataListComponent,
    DataTableComponent,
    SearchCustomerComponent,
    SelectBankAccountComponent,
    SelectProcessorComponent,
    ConfirmationDialogComponent,
    SuccessDialogComponent,
    MaskPipePipe,
    TwoDigitDecimalNumberDirective,
    PagenotfoundComponent,
    StarRatingComponent,
    GradeScoreChartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
