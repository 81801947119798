import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { InventoryService } from 'src/app/featureModules/services/inventory.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { InventoryFilterModel } from 'src/app/sharedModules/models/inventory-filter.model';
import { InventoryCategoryService } from 'src/app/featureModules/services/inventory-category.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-inventory-item-list',
  templateUrl: './inventory-item-list.component.html',
  styleUrls: ['./inventory-item-list.component.scss'],
})
export class InventoryItemListComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  IsActive = null;
  countFilter: ListFilter;
  gotDataResponse = false;
  filteredCustomer: any[] = [];
  minLengthTerm = 3;
  isShowNoFound: boolean = false;
  customerId: string;
  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';
  categoryId: string;
  selectedCategory = [];
  userfilterText:string

  private timeout: number;

  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  dropdownSettings: any = {};
  categoryList: any = [];
  searchTerm: string;
  userRole :any;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Select', name: 'select', width: '3%' },
    { displayName: 'ITEM NAME', name: 'itemName', width: '14%' },
    { displayName: 'CATEGORY', name: 'categoryName', width: '10%' },
    { displayName: 'SKU', name: 'sku', width: '10%' },
    {
      displayName: 'QUANTITY',
      name: 'quantity',
      width: '13%',
      className: 'centerAlign',
    },
    { displayName: 'UNIT PRICE', name: 'unitPrice', width: '10%' },
    { displayName: 'CREATED BY', name: 'enteredBy', width: '12%' },
    { displayName: 'CREATED ON', name: 'entryDate', width: '14%' },
    { displayName: 'STATUS', name: 'isActive', width: '8%' },
    { displayName: 'ACTION', name: 'action', width: '6%' },
  ];

  allColumns: TableColumn[];

  filterFormGroup: FormGroup;
  filterModel : InventoryFilterModel;

  advanceFilters: string;
  advanceFilterCount: number;
  advanceFiltersList: any;
  advanceFiltersList1: string;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private router: Router,
    private inventoryService: InventoryService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private categoryService: InventoryCategoryService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _customerService: CustomerService
  ) {
    this.allColumns = this.columns;
    
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.filter = this._commonService.getListDataFromLocalStorage(
      'inventoryItemGridFilter',
      'inventory',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryItemGridPageIndex',
          'inventory',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryItemGridPageSize',
          'inventory',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'inventoryItemGridSortActive',
        'inventory',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'inventoryItemGridSortDirection',
        'inventory',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'inventoryItemAdvanceFilter',
      'inventory',
      ''
    );

    this.advanceFiltersList1 = this.advanceFilters.slice(0, -1);
    this.advanceFiltersList = this.advanceFiltersList1.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length -1 ;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'inventoryItemAdvanceFilterForm',
        'inventory',
        JSON.stringify(new InventoryFilterModel())
      )
    );

    this.filterModel = new InventoryFilterModel();
    Object.assign(this.filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(this.filterModel);
  }

  onDelete(id: string) {}

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    this.selection.clear();
    this.inventoryService
      .getAllInventoryGrid(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();
          if (data.data != null) {
            this.data = data.data.services;
            this.page.length = data.data.totalRecords;
            var list = [];
            this.data?.map((item) => {
              let record = {
                id: item.id,
                itemName: item.itemName,
                categoryName: item.categoryName,
                sku: item.sku,
                enteredBy: item.enteredBy,
                quantity: item.quantity + ';' + item.availableQuantity,
                unitPrice: item.unitPrice,
                entryDate: item.entryDate,
                isActive: item.isActive,
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'inventoryItemGridFilter',
        'inventory'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'inventoryItemGridPageSize',
        'inventory'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'inventoryItemGridPageIndex',
        'inventory'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'inventoryItemGridSortActive',
        'inventory'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'inventoryItemGridSortDirection',
        'inventory'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'status':
        this.sortBy.active = 'isActive';
        break;
      case 'categoryName':
        this.sortBy.active = 'category.categoryName';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onDeleteClick(item: any) {
    let type = item.isActive ? 'Deactivate' : 'Activate';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: type.toUpperCase(),
        successMessage: "Are you sure you want to '" + type + "' this ?",
        subSuccessMessage:
          "Clicking on '" + type + "' button will update the status",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this.inventoryService
          .deactivateService(item.id, !item.isActive)
          .subscribe((data) => {
            this.isLoading = false;
            if (data.data != null) {
              this._toastrService.success('Item status updated successfully!');
              this.loadData(
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize,
                this.advanceFilters
              );
              //window.location.reload();
            }
          });
      }
    });
  }

  onBlurSearch() {
    if (this.userfilterText) this.userfilterText = this.userfilterText?.trim();
  }
  onClearText() {
    this.userfilterText = '';    
  }

  public restForm() {
    if (this.filterFormGroup) {      
      this.filterFormGroup.reset();
      this.filterFormGroup.get('createdBy').setValue(null);
      this.filterModel = new InventoryFilterModel();
      this.filterFormGroup = this.formBuilder.formGroup(
        this.filterModel
      );
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryItemGridPageSize',
          'inventory',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'inventoryItemGridSortActive',
        'inventory',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'inventoryItemGridSortDirection',
        'inventory',
        'desc'
      ),
    };
    this.advanceFilters = '';

    if (data.categoryId && data.categoryId.length > 0) {
      let filter = '';
      data.categoryId.forEach((element) => {
        filter ? (filter += ',' + element.id) : (filter = element.id);
      });
      this.advanceFilters = 'categoryId:' + filter + '|';
    }
    if (data.quantityFrom && data.quantityTo) {
      if (parseInt(data.quantityFrom) >= parseInt(data.quantityTo)) {
        this._toastrService.warning(
          'From Quantity Must be lower than To Quantity'
        );
        return;
      } else {
        this.advanceFilters +=
          'quantity:' + data.quantityFrom + 'to' + data.quantityTo + '|';
      }
    }

    if (data.priceFrom && data.priceTo) {
      if (parseInt(data.priceFrom) >= parseInt(data.priceTo)) {
        this._toastrService.warning(
          'Price From cannot be greater than Price To'
        );
        return;
      } else {
        this.advanceFilters +=
          'unitprice:' + data.priceFrom + 'to' + data.priceTo + '|';
      }
    }

    if (data.periodStartDate && data.periodEndDate) {
      this.advanceFilters +=
        'cdate:' +
        getSafeIsoDateString(data.periodStartDate) +
        'to' +
        getSafeIsoDateString(data.periodEndDate) +
        '|';
    }

    if (data.createdBy) {
      this.advanceFilters += 'createdBy:' + data.createdBy + '|';
      this.userfilterText = data.createdBy;
    }

    this.advanceFilters += 'isactive:' + data.isActive + '|';

    this.advanceFiltersList1 = this.advanceFilters.slice(0, -1);
    this.advanceFiltersList = this.advanceFiltersList1.split('|');

    if (this.advanceFiltersList[0] == 'isactive:null') {
      this.advanceFilterCount = 0;
    } else if (
      this.advanceFiltersList[this.advanceFiltersList.length - 1] ==
      'isactive:null'
    ) {
      this.advanceFilterCount = this.advanceFiltersList.length - 1;
    } else {
      this.advanceFilterCount = this.advanceFiltersList.length;
    }

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'inventoryItemAdvanceFilter',
      'inventory'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'inventoryItemAdvanceFilterForm',
      'inventory'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  AddService() {
    this.router.navigate(['customers/manage-customer']);
  }

  deleteBasedOnSelection(status: any) {
    let type = status ? 'Activate' : 'Deactivate';
    let serviceIdList = this.selection['_selected'].map(({ id }) => id);
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            serviceIds: serviceIdList,
            status: status,
          };
          this.inventoryService
            .bulkDeactivateService(statusUpdate)
            .subscribe((data) => {
              this.isLoading = false;
              if (data.code == 200) {
                this._toastrService.success(
                  'Selected item status updated successfully!'
                );
                this.loadData(
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize,
                  this.advanceFilters
                );
                //window.location.reload();
              }
            });
        }
      });
    }
  }

  serviceEdit(serviceId: any) {
    this.router.navigate(['other/inventory/update-item', serviceId]);
  }

  getAllCategories() {
    this.inventoryService.getAllCategories(true).subscribe(
      (data) => {
        if (data.code == 200 && data.data) {
          this.categoryList = data.data;
        } else {
          this.isLoading = false;
        }
      },
      (error) => this.showError(error)
    );
  }
  private showError(error: any) {
    if (error.error.message != null || error.error.message != undefined) {
      this._toastrService.warning(error.error.message);
    } else {
      this._toastrService.warning(error.error.content);
    }
    this.isLoading = false;
  }

  ngOnInit() {
    this.categoryService.categoryCountEvent.subscribe((data) => {
      if (data) {
        this.categoryId = data.id;
        if (this.categoryId) {
          this.advanceFilters = 'categoryid:' + this.categoryId + '|';
          this.advanceFilters += 'isactive:' + true + '|';
          this.advanceFilterCount = 2;
          this.filterFormGroup.controls['isActive'].setValue(true);
          this.selectedCategory = [
            { id: this.categoryId, categoryName: data.categoryName },
          ];
          this.loadData(
            this.filter,
            this.sortBy.active,
            this.sortBy.direction,
            this.page.pageIndex,
            this.page.pageSize,
            this.advanceFilters
          );
        }
      }
    });

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.deletedFilterEvent.subscribe(this.onDeletedFilter.bind(this));

    // this.filterFormGroup.controls['createdBy'].valueChanges
    //   .pipe(
    //     filter((res) => {
    //       this.isShowNoFound = false;
    //       if (res) {
    //         return (
    //           this.filterFormGroup.controls['createdBy'].valid &&
    //           res !== null &&
    //           res.length >= this.minLengthTerm
    //         );
    //       } else {
    //         this.filteredCustomer = [];
    //       }
    //     }),
    //     distinctUntilChanged(),
    //     debounceTime(10),
    //     tap(() => {
    //       this.isShowNoFound = false;
    //       this.filteredCustomer = [];
    //       this.isLoading = true;
    //     }),
    //     switchMap((value) =>
    //       this._customerService.SearchCustomers(value, true).pipe(
    //         finalize(() => {
    //           this.isLoading = false;
    //         })
    //       )
    //     )
    //   )
    //   .subscribe((data: any) => {
    //     if (data.data && !data.data.length) {
    //       this.filteredCustomer = [];
    //       this.isShowNoFound = true;
    //     } else {
    //       this.filteredCustomer = data.data;
    //       this.isShowNoFound = false;
    //       this.customerId = this.filteredCustomer[0].customerId;
    //     }
    //   });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'categoryName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.getAllCategories();
  }

  selectedCustomerName(customer: any) {
    return customer.firstName?.trim() + ' ' + customer.lastName?.trim();
  }
  onSelected() {
    var selected = (this.filteredCustomer = this.filteredCustomer.filter(
      (customer: any) =>
        this.filterFormGroup.get('createdBy').value ==
        customer.firstName?.trim() + ' ' + customer.lastName?.trim()
    ));
    if (selected && selected[0])
      this.filterFormGroup.get('createdById').setValue(selected[0].customerId);
    this.filterFormGroup
      .get('createdBy')
      .setValue(selected[0].firstName + ' ' + selected[0].lastName);
  }
  clearSelection() {
    this.filterFormGroup.get('createdBy').setValue(null);
    this.filterFormGroup.get('createdById').setValue(null);
    this.filteredCustomer = [];
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);    
    if(!(this.router.url.includes('others') || this.router.url.includes('inventory'))){
      localStorage.removeItem('inventory');
    }    
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
  }
  onSelectAll(items: any) {
    console.log('onSelectAll', items);
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
      allowSearchFilter: this.ShowFilter,
    });
  }

  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
        limitSelection: 2,
      });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, {
        limitSelection: null,
      });
    }
  }
}
