import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html',
  styleUrls: ['./import-dialog.component.scss']
})
export class ImportDialogComponent implements OnInit {
  upload : string;
  public fileName: string;
  importForm: FormGroup;
  file: File;

  constructor(private builder: FormBuilder) { }

  ngOnInit(): void {
    
    this.importForm = this.builder.group({
      // upload: [null, [Validators.required]],
    })
  }

  fileChange(file){
    let size = file.target.files[0].size/1024/1024;
    if (size > 5)
    {
      this.fileName = "";
      return;
    }
    this.fileName = file.target.files[0].name;
  }
}
