import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { CustomerCardDetailsService } from 'src/app/featureModules/services/customer-card-details.service';

@Component({
  selector: 'app-bank-and-card-details',
  templateUrl: './bank-and-card-details.component.html',
  styleUrls: ['./bank-and-card-details.component.scss'],
})
export class BankAndCardDetailsComponent implements OnInit {
  constructor(private _customerBankDetailsService: CustomerBankDetailsService,
    private _customerCardDetailsService: CustomerCardDetailsService,
    private router: Router) { }

  @Input()
  customerId: any;

  isLoading: boolean = false;
  bankList = [];
  debitCreditCardsList = [];
  banktabselected: boolean = true;
  cardtabselected: boolean = false;

  ngOnInit(): void {
    this.getBankDetails();
    this.getCardDetails();
  }

  getBankDetails() {
    this.isLoading = true;
    this._customerBankDetailsService.GetAllBankAccounts(this.customerId,false).subscribe(data => {
      this.isLoading = false;
      if (data.data != null) {
        this.bankList = data.data.listOfBankAccountsToReturn;
      }
    },
    (error) => {
      this.isLoading = false;
    });
  }

  getCardDetails() {
    this.isLoading = true;
    this._customerCardDetailsService.GetAllCards(this.customerId,false).subscribe(data => {
      this.isLoading = false;
      if (data.data != null) {
        this.debitCreditCardsList = data.data;
      }
    },
    (error) => {
      this.isLoading = false;
    });
  }

  selectedTabChangeEvent(event: any) {
    switch (event.index) {
      case 0:
        this.banktabselected = true;
        this.cardtabselected = false;
        break;
      case 1:
        this.banktabselected = false;
        this.cardtabselected = true;
        break;
    }
  }

  editCardDetails(card: any) {
    this.router.navigate(["customers/manage-customer", 2, this.customerId, card.customerCardId]);
  }

  editBankDetails(bank: any) {
    this.router.navigate(["customers/manage-customer", 1, this.customerId, bank.customerBankAccountId]);
  }

}
