<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
        <button mat-icon-button (click)="navigateToPage()">
            <mat-icon>arrow_back </mat-icon>
        </button>
        Settings
    </mat-label>
</div>
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Manage Notifications</div>
        <div class="page-sub-header-text-1">
          All Invoice Notification Setup related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>
<div class="manage-notification-container ">
  <div class="manage-notification-inner-container">
    <form [formGroup]="manageNotificationFormGroup">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="manage-notification-form">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Scheduling Payment Reminders</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <mat-radio-group fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center" class="form-control"
                name="paymentReminder" (change)="onReminderMediumChanged($event)">
                <mat-radio-button [value]="1" [checked]="
                manageNotificationFormGroup.controls['paymentReminder'].value ==
                1
              ">Send By Email</mat-radio-button>
                <mat-radio-button [value]="2" [checked]="
                manageNotificationFormGroup.controls['paymentReminder'].value ==
                2
              ">Send By SMS</mat-radio-button>
                <mat-radio-button [value]="3" [checked]="
                manageNotificationFormGroup.controls['paymentReminder'].value ==
                3
              ">Send By Both</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center"
            formArrayName="sendReminderList" *ngFor="
          let days of manageNotificationFormGroup.get('sendReminderList')[
            'controls'
          ];
          let i = index
        ">
            <ng-container [formGroupName]="i">
              <div fxLayout="column" fxFlex="24%"></div>
              <div fxLayout="column" fxFlex="9%">
                <mat-label>Send Reminder</mat-label>
              </div>
              <div fxLayout="column" fxFlex="8%">
                <mat-form-field appearance="outline">
                  <mat-label>Days</mat-label>
                  <input matInput formControlName="days" appTwoDigitDecimalNumber class="form-control" name="days" />
                  <mat-error>
                    {{
                    manageNotificationFormGroup.controls["sendReminderList"][
                    "controls"
                    ][i].controls.days["_errorMessage"]
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="column" fxFlex="14%">
                <mat-label>Days Before Due Date</mat-label>
              </div>
              <div *ngIf="deleteButtonCondition()" fxLayout="column">
                <img class="deleteImg" src="{{ getIconSrc(delete_const) }}" (click)="removeSendReminderField(i)" />
              </div>
            </ng-container>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxLayout="column" fxFlex="24%"></div>
            <div fxLayout="column">
              <button type="button" mat-button color="primary" class="white-button-style addreminderbutton"
                (click)="addSendReminderField()">
                ADD MORE REMINDERS
              </button>
            </div>
          </div>
          <br />

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Send Notifications By</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <mat-radio-group fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center" class="form-control"
                name="sendNotificationBy" (change)="onNotificationMediumChanged($event)">
                <mat-radio-button [value]="1" [checked]="
                manageNotificationFormGroup.controls['sendNotificationBy']
                  .value == 1
              ">Send By Email</mat-radio-button>
                <mat-radio-button [value]="2" [checked]="
                manageNotificationFormGroup.controls['sendNotificationBy']
                  .value == 2
              ">Send By SMS</mat-radio-button>
                <mat-radio-button [value]="3" [checked]="
                manageNotificationFormGroup.controls['sendNotificationBy']
                  .value == 3
              ">Send By Both</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <br />

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="16px">
            <div fxLayout="column" fxFlex="24%">
              <mat-label class="required fontBold">Due Date</mat-label>
            </div>
            <div fxLayout="column" fxFlex="8%">
              <mat-form-field appearance="outline">
                <mat-label>Days</mat-label>
                <input matInput formControlName="dueDate" class="form-control" name="dueDate" />
                <mat-error>
                  {{
                  manageNotificationFormGroup.controls["dueDate"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="column">
              <mat-label>Days after schedule date</mat-label>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="saveandcancel">
          <button mat-stroked-button color="primary" class="white-button-style" (click)="resetForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button color="primary" class="page-header-button-style" (click)="saveSettings()">
            SAVE
          </button>
        </div>
      </div>
    </form>
  </div>
</div>