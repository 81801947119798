import { SelectionModel } from '@angular/cdk/collections';
import { NumberFormatStyle } from '@angular/common';
import { Component, Input, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { getSafeIsoDateTimeStringToLocal } from 'src/app/sharedModules/utils/dates';
import { InstantVerifyService } from '../../services/instant-verify.service';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-instant-verify',
  templateUrl: './instant-verify.component.html',
  styleUrls: ['./instant-verify.component.scss'],
})
export class InstantVerifyComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  noRecordMessage: string =
    'Instant Bank Analysis needs to be created for given search criteria!';

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  isAccess = false;
  userRole :any;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'sentOn',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '4%' },
    { displayName: 'Name', name: 'name', width: '20%' },
    { displayName: 'Created By', name: 'enteredBy', width: '20%' },
    { displayName: 'Bank Name', name: 'bankName', width: '20%' },
    {
      displayName: 'Created On',
      name: 'entryDate',
      width: '12%',
      converter: (value) => getSafeIsoDateTimeStringToLocal(value),
    },
    { displayName: 'Sent VIA', name: 'sendVia', width: '11%' },
    { displayName: 'Status', name: 'status', width: '9%' },
    { displayName: 'Action', name: 'ibvaction', width: '4%' },
  ];

  allColumns: TableColumn[];

  constructor(
    private router: Router,
    private instantVerifyService: InstantVerifyService,
    private _toastrService: ToastrService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.filter = this._commonService.getListDataFromLocalStorage(
      'ibaMainGridFilter',
      'instantBankAnalysis',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaMainGridPageIndex',
          'instantBankAnalysis',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaMainGridPageSize',
          'instantBankAnalysis',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ibaMainGridSortActive',
        'instantBankAnalysis',
        'sentOn'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ibaMainGridSortDirection',
        'instantBankAnalysis',
        'desc'
      ),
    };
  }

  ngOnInit(): void {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );
    });

    this.filterEvent.subscribe(this.onFilter.bind(this));
  }

  onRefresh(id: string) {}

  AddInstantBankVerifyRequest() {
    this.router.navigate(['instantBankAnalysis/add-ibv']);
  }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: NumberFormatStyle
  ) {
    this.isLoading = true;
    this.selection.clear();

    this.instantVerifyService
      .GetAllIbvBankVerificationRequests(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.isAccess = true;
          if (data.data != null) {
            this.noRecordMessage = '';
            this.data = data.data.ibvBankVerificationRecordList;
            this.page.length = data.data.totalRecords;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this.isAccess = false;
        }
      );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'ibaMainGridFilter',
        'instantBankAnalysis'
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'ibaMainGridPageSize',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'ibaMainGridPageIndex',
        'instantBankAnalysis'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'ibaMainGridSortActive',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'ibaMainGridSortDirection',
        'instantBankAnalysis'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }
  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public navigateToCustomerRefresh(customer: any) {
    this.router.navigate([
      'instantBankAnalysis/customer-refresh-details',
      customer.customerId,
      customer.bankVerificationId,
    ]);
  }

  ibvResend(customer: any) {
    this.isLoading = true;

    this.instantVerifyService
      .ResendInstantVerifyRequest(customer.bankVerificationId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.code == 201) {
            this._toastrService.success(
              'Verification Link Resent Successfully'
            );
            this.loadData(
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  ibvReportView(customer: any) {
    this.router.navigate(
      [
        'instantBankAnalysis/ibv-report',
        customer.customerId,
        customer.bankVerificationId,
      ],
      {
        state: {
          url: '/instantBankAnalysis',
          backHeaderLabel: 'Banking / Instant Bank Analysis',
        },
      }
    );
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!this.router.url.includes('instantBankAnalysis'))
    localStorage.removeItem('instantBankAnalysis');
  }
}
