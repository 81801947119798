<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>

<div class="page-header-bottom">
    <div class="backHeader">
        <mat-label>
            <button mat-icon-button (click)="navigateToBackPage()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            Ticket Listing
        </mat-label>
    </div>
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Update A Ticket</div>
                <div class="page-sub-header-text-1">
                    All ticket related information can be viewed from this page
                </div>
            </div>
        </div>
    </div>
</div>

<form [formGroup]="updateTicketFormGroup">
<div class="tickets-overview">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center start" class="ticket-id-box">
        ID : {{ticketDetailsModel.ticketNumber}}   
    </div>
    <div class="ticket-form">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="subject-line">
            {{ticketDetailsModel.subject}}  
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="userDetails"> 
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" >            
              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="description-label" >            
                    Description :                       
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="description" >            
                  {{ticketDetailsModel.description}}                      
              </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="userDetails"> 
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="w-100">            
              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="description-label" >            
                    Ticket Attachments :                       
              </div>
              <div fxLayout="row" fxLayout.xs="column" *ngIf="verifyAttachment(attachmentsList)" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" >            
                    No Attachments Found                   
              </div>
              <div fxLayout="row" fxLayout.xs="column" *ngFor ="let attachment of attachmentsList" fxLayoutAlign="start stretch" fxLayoutWrap>                           
                <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="file-upload-box w-100">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                    <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px" fxLayoutAlign="start stretch" fxFlex="80%">
                        <div class="file-name">
                            <span> {{ attachment.name }}</span>
                        </div>
                        <div class="file-size">
                            <span> {{ attachment.size | formatFileSize}}</span>
                        </div>                      
                    </div>
                    <div fxLayout="column" fxLayout.xs="column"  fxLayoutAlign="end end" fxFlex="20%">
                      <img class="downloadImg" matTooltip="Download Attachment" alt="downloadImg"
                        src="{{ download_const | showIcon }}" (click)="onFileDownload(attachment.path,attachment.name)" />
                  </div>                   
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="start stretch" class="file-progress-bar">
                  </div>
                </div> 
              </div> 
            </div>
        </div> 
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="userDetails">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="created-by">
                    Created By :
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">  
                    <mat-label class="userNameCircle">
                        {{ getNameInitials(ticketDetailsModel.firstName, ticketDetailsModel.lastName) }}
                    </mat-label>
                    <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px" fxLayoutAlign="start stretch" >
                        <div class="name-fr-text">
                            <span
                            matTooltip="{{ ticketDetailsModel.firstName }} {{ ticketDetailsModel.lastName }}"
                            class="ellip"
                            >
                            {{ ticketDetailsModel.firstName }} {{ ticketDetailsModel.lastName }}
                            </span>
                        </div>
                        <div class="sub-text">
                            <span matTooltip="{{ ticketDetailsModel.enteredBy }}" class="ellip">
                            {{ ticketDetailsModel.enteredBy }}
                            </span>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start stretch" class="details-header">
            <div fxLayout="column" fxFlex="10%">Priority</div>
            <div fxLayout="column" fxFlex="10%">Ticket Status</div>
            <div fxLayout="column" fxFlex="10%">Category</div>
            <div fxLayout="column" fxFlex="10%">Created On</div>            
            <div fxLayout="column" fxFlex="10%">Assigned To</div>                  
        </div> 
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start stretch" class="details-data">
            <div fxLayout="column" fxFlex="10%">{{ticketDetailsModel.priority === 'N/A' ? ticketDetailsModel.priority : (ticketDetailsModel.priority | titlecase)}}</div>
            <div fxLayout="column" fxFlex="10%">{{ticketDetailsModel.ticketStatus | titlecase }}</div>
            <div fxLayout="column" fxFlex="10%">{{ticketDetailsModel.category}}</div>
            <div fxLayout="column" fxFlex="10%">{{ticketDetailsModel.createdOn | date:'mediumDate' }}</div>            
            <div fxLayout="column" fxFlex="10%">{{ticketDetailsModel.assignedTo}}</div>                  
        </div>               
        <div *ngIf ="this.userRole != 1" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
            class="top-margin">
            <div fxLayout="column" fxFlex="100%">
                <mat-label class="subheader-style">Comment :</mat-label>
                <div class="textarea-spacing">
                    <mat-form-field appearance="outline">
                        <textarea autosize matInput name="comment" rows="5" placeholder="Enter Text*"
                            formControlName="comment">
                        </textarea>
                        <mat-error>
                            {{ updateTicketFormGroup.controls["comment"]["_errorMessage"] }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf = "this.userRole != '1'" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch" class="top-margin">                
            <div fxLayout="column" class="upload-box">
                <label
                color="primary"
                class="file-select">
                    UPLOAD ATTACHMENT
                    <input id="inputAttachment" type="file" class="file-input" (change)="onFileSelected($event)" 
                    accept=".pdf,.doc,.docx,.txt,.rtf,.xls,.xlsx,.csv,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.zip,.rar,.log"
                    #fileUpload placeholder="UPLOAD ATTACHMENT">     
                </label>                                           
            </div>                                                         
        </div> 
        <mat-hint *ngIf = "this.userRole != '1'" class="hint-style">Maximum Upload file size is 5 MB*. Can Upload upto 5 attachments.</mat-hint>                          
        <div fxLayout="row" fxLayout.xs="column" *ngFor="let file of uploadedFilelist" fxLayoutAlign="start stretch" class="top-margin">
        <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" fxFlex="100%" class="file-upload-box">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px" fxLayoutAlign="start stretch" fxFlex="80%">
                <div class="file-name">
                    <span> {{ file.name }}</span>
                </div>
                <div class="file-size">
                    <span> {{ file.size | formatFileSize}}</span>
                </div>
            </div> 
            <div fxLayout="column" fxLayout.xs="column"  fxLayoutAlign="end end"  fxFlex="20%">
                <img class="deleteImg" matTooltip="Remove Attachment" alt="deleteimg"
                    src="{{ delete_const | showIcon }}" (click)="onFileDeleted(file)" />
            </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="start stretch" 
                class="file-progress-bar">
            </div>
        </div> 
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="3%" fxLayoutAlign="start stretch" class="label-text top-margin">
            RECENT COMMENTS :
        </div>                     
        <div fxLayout="row" fxLayout.xs="column" *ngFor="let comment of commentsList" fxLayoutAlign="start stretch" class="mb-2">
            <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="w-100">
               <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="space-between stretch">
                <div fxLayout="column" class="width100" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                            <mat-label class="userNameCircle">
                                {{ getNameInitials(comment.firstName, comment.lastName) }}
                            </mat-label>
                        </div>
                        <div fxLayout="column" class="width100"  fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                            <div fxLayout="row"  fxLayoutWrap class="name-fr-text" fxLayoutAlign="space-between center">
                                <div fxLayout="column" fxLayoutWrap  fxLayoutAlign="start stretch">
                                    <span matTooltip="{{ comment.firstName }} {{ comment.lastName }}" class="ellip">
                                        {{ comment.firstName }} {{ comment.lastName }}
                                    </span>
                                </div>
                                <div fxLayout="column" fxLayoutWrap fxLayout.xs="column" fxLayoutAlign="end center" class="sub-text">
                                    <span matTooltip="{{ comment.entryDate }}" class="ellip align">
                                        Submitted On : {{ comment.entryDate | date :'mediumDate' }} at {{ comment.entryDate |
                                        date :'shortTime' }}
                                    </span>
                                </div>
                            </div>
                            <div fxLayout="row"  fxLayoutAlign="start stretch" class="sub-text">
                                <span  class="ellip">
                                    {{ comment.comment }}
                                </span>
                            </div>
                        </div>                       
                    </div>
                </div>                
            </div>
              <div fxLayout="row" fxLayout.xs="column" *ngFor ="let attachment of comment.ticketAttachments" fxLayoutAlign="start stretch" fxLayoutWrap fxLayoutGap="4%">
                <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"></div>              
                <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" class="file-upload-box w-100">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                    <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px" fxLayoutAlign="start stretch" fxFlex="80%">
                        <div class="file-name">
                            <span> {{ attachment.name }}</span>
                        </div>
                        <div class="file-size">
                            <span> {{ attachment.size | formatFileSize}}</span>
                        </div>
                    </div>
                    <div fxLayout="column" fxLayout.xs="column"  fxLayoutAlign="end end"  fxFlex="20%">
                        <img class="downloadImg" matTooltip="Download Attachment" alt="downloadImg"
                          src="{{ download_const | showIcon }}" (click)="onFileDownload(attachment.path,attachment.name)" />
                    </div>                    
                  </div>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="start stretch" class="file-progress-bar">
                  </div>
                </div> 
              </div>             
            </div> 
        </div> 
    </div>  
</div>      
        <div fxLayout="column" *ngIf = "this.userRole != '1'" fxLayoutAlign="center end">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="saveandcancel">
                <button mat-stroked-button color="primary" class="white-button-style" (click)="navigateToBackPage()">
                    CANCEL
                </button>
                &nbsp;&nbsp;
                <button mat-raised-button color="primary" class="page-header-button-style" (click)="UpdateTicket()">
                    UPDATE
                </button>
            </div>
        </div>
</form>  