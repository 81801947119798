
import { FormGroup } from '@angular/forms';
    
export function ConfirmedValidator(newPassword: string, confirmPassword: string){
    return (formGroup: FormGroup) => {
        const controlNewPassword = formGroup.controls[newPassword];
        const controlconfirmPassword = formGroup.controls[confirmPassword];
        if (controlconfirmPassword.errors && !controlconfirmPassword.errors['confirmedValidator']) {
            return;
        }
        if (controlNewPassword.value !== controlconfirmPassword.value) {
            controlconfirmPassword.setErrors({ confirmedValidator: true });
        } else {
            controlconfirmPassword.setErrors(null);
        }
    }
}