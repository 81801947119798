<div class="card-style">
  <app-dashboard-transaction-amount-chart
    [transactionType]="transactionType"
    [period]="period"
  >
  </app-dashboard-transaction-amount-chart>
</div>

<app-data-table
  [columns]="totalAmountcolumns"
  [data]="totalAmountGridData"
  [isLoading]="isLoading"
  [isLoadingPaging]="isLoadingPaging"
  [page]="page"
  [sortBy]="sortBy"
  [noRecordMessage]="noRecordMessage"
  [showMoreMenu]="true"
  [allColumns]="totalAllColumns"
  [selection]="selection"
  [actionRefresh]="actionRefresh"
  (actionRefreshEvent)="onRefresh($event)"
  (pageChangedEvent)="onPageChanged($event)"
  (rowClickedEvent)="onRowClick($event)"
  (sortChangedEvent)="onSortChanged($event)"
  (onFilterEvent)="onFilter($event)"
  [isFilter]="false"
  [isExport]="false"
  [isAddBtn]="false"
  [isCreditDebitSummary]="false"
  [isTotalTransactionLbl]="true"
  [isSearch]="false"
  [isTableHeading]="true"
  [isTableHeadingText]="tableLabel"
  [showPaginator]="false"
>
</app-data-table>
