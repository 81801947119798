import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { model, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs';
import { InventoryCategoryService } from 'src/app/featureModules/services/inventory-category.service';
import { InventoryService } from 'src/app/featureModules/services/inventory.service';
import { AddInventoryItemModel } from 'src/app/sharedModules/models/add-Inventory-Item.model';
import { findInvalidControls } from 'src/app/sharedModules/utils/dev-utils';

@Component({
  selector: 'app-add-inventory-item',
  templateUrl: './add-inventory-item.component.html',
  styleUrls: ['./add-inventory-item.component.scss']
})
export class AddInventoryItemComponent implements OnInit, OnDestroy {
  isLoading = false;
  loadMessage: string = '';
  addInventoryFormGroup: FormGroup;
  addInventoryModel: AddInventoryItemModel = new AddInventoryItemModel();
  inventoryId: any;
  isEdit: boolean = false;

  disabled = false;
  ShowFilter = false;
  limitSelection = false;
  cities: any = [];
  selectedItems: any = [];
  dropdownSettings: any = {};
  categoryList: any = [];
  searchTerm: string = null;
  isShowNoFound: boolean;

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private inventoryService: InventoryService,
    private inventoryCategoryService: InventoryCategoryService
  ) {

  }

  ngOnInit(): void {    
    this.activatedRoute.paramMap.subscribe((params) => {
      this.inventoryId = params.get('id');
    });
    this.addInventoryFormGroup = this.formBuilder.formGroup(
      this.addInventoryModel
    );

    if (this.isEdit) { this.getCategories(); }

    this.getAllCategories();    
  }

  selectedCategoryName(category: any) {
    if (category) {
      return category.categoryName;
    }
    else {
      return '';
    }
  }

  reset() {
    this.addInventoryModel = new AddInventoryItemModel();
    this.addInventoryFormGroup.reset();
    this.addInventoryFormGroup.markAsPristine();
    this.addInventoryFormGroup.markAsUntouched();
  }

  cancel() {
    this.router.navigateByUrl('/others');
  }

  setSku(event: any) {
    this.addInventoryFormGroup.controls['sku'].setValue(this.addInventoryFormGroup.controls['sku'].value?.toUpperCase());
    this.addInventoryModel.sku = this.addInventoryFormGroup.value.sku;
  }

  addInventory() {    
    this.isLoading = true;
    let modelData = this.addInventoryFormGroup.value;
    if(this.addInventoryFormGroup.value.categoryIdList == null){
      this.toastrService.error('Please fill all the required details!');
      this.isLoading = false;
      return false;
    }
    modelData.categoryIdList = this.addInventoryFormGroup.value.categoryIdList.map(x => x.id);    
    findInvalidControls(this.addInventoryFormGroup);
    if (this.addInventoryFormGroup.valid) {
      this.inventoryService.addInventory(modelData).subscribe(
        (data) => {
          if (data.code == 201 && data.data) {
            this.toastrService.success('Item added into inventory successfully!');
            this.isLoading = false;
            this.reset();
            this.navigateToBackPage();
          } else if (data.code == 400) {
            this.isLoading = false;
            this.toastrService.error(data.errorMessage);
          } else {
            this.isLoading = false;
          }
        },
        (error) => this.showError(error));
    } else {
      this.addInventoryFormGroup.markAllAsTouched();
      this.toastrService.error('Please fill all the required details!');
      this.isLoading = false;
    }
  }

  selectedCategoryNames(product: any) {
    return product.categoryName?.trim();
  }

  displayCategoryName(categoryId: any) {
    if (this.categoryList && this.categoryList.length > 0) {
      let data = this.categoryList.find((x) => x.id === categoryId);
      if (data) {
        return data.categoryName;
      }
    }
  }

  getCategories() {
    this.addInventoryFormGroup.controls['categoryId'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.addInventoryFormGroup.controls['categoryId'].valid &&
              res !== null
            );
          } else {
            this.categoryList = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.categoryList = [];
          this.isLoading = true;
        }),
        // value => this.apiService.getArticles()
        switchMap((value) =>
          this.inventoryCategoryService.searchCategories(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.isShowNoFound = true;
          this.categoryList = [];
        } else {
          this.isShowNoFound = false;
          this.categoryList = data.data;
        }
      });
  }

  async getAllCategories() {
    await this.inventoryService.getAllCategories(true).subscribe(
      (data) => {
        if (data.code == 200 && data.data) {
          this.categoryList = data.data;
          this.getInventoryById();         
          this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'categoryName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 1,
            allowSearchFilter: true
        };    
      
          let client = JSON.parse(localStorage.getItem('userProfile'));
          this.addInventoryFormGroup.controls['enteredby'].setValue(client['firstName'] + ' ' + client['lastName']);
        } else {
          this.isLoading = false;
        }
      },
      (error) => this.showError(error));
  }

  setQuantity(event: any) {
    setTimeout(() => {
      this.addInventoryModel.availableQuantity = event.target.value;
    }, 10);
  }

  getInventoryById() {    
    if (!this.inventoryId) return;
    this.isLoading = true ;
    this.inventoryService
      .getInventoryById(this.inventoryId)
      .subscribe((data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.isEdit = true;
          this.addInventoryModel = data.data;
          this.addInventoryFormGroup.patchValue(this.addInventoryModel);
          this.addInventoryFormGroup.markAsPristine();
          this.addInventoryFormGroup.markAsUntouched();
        }
      },
        (error) => this.showError(error));
  }

  updateInventory() {
    this.isLoading = true;
    findInvalidControls(this.addInventoryFormGroup);
    let modelData = this.addInventoryFormGroup.value;    
    if (this.addInventoryFormGroup.valid) {
      this.inventoryService.updateInventory(this.inventoryId, modelData).subscribe(
        (data) => {
          if (data.code == 200) {
            this.toastrService.success('Inventory item updated successfully!');
            this.isLoading = false;
            this.reset();
            this.navigateToBackPage();
          } else if (data.code == 400) {
            this.isLoading = false;
            this.toastrService.error(data.errorMessage);
          } else {
            this.isLoading = false;
          }
        },
        (error) => this.showError(error));
    } else {
      this.addInventoryFormGroup.markAllAsTouched();
      this.toastrService.error('Please fill all the required details!');
      this.isLoading = false;
    }

  }

  private showError(error: any) {
    if (error.error.message != null || error.error.message != undefined) {
      this.toastrService.warning(error.error.message);
    } else {
      this.toastrService.warning(error.error.content);
    }
    this.isLoading = false;
  }

  navigateToBackPage() {
    this.router.navigateByUrl('/others');
  }

  ngOnDestroy() {
    this.inventoryId = null;
    this.addInventoryModel = null;
    this.isEdit = false;
    this.isLoading = false;
  }

  onItemSelect(event: any) {
    //this.addInventoryFormGroup.controls['categoryId'].setValue(event.value)
  }
  onSelectAll(items: any) {    
  }
  toogleShowFilter() {
    this.ShowFilter = !this.ShowFilter;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { allowSearchFilter: this.ShowFilter });
  }
  handleLimitSelection() {
    if (this.limitSelection) {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: 2 });
    } else {
      this.dropdownSettings = Object.assign({}, this.dropdownSettings, { limitSelection: null });
    }
  }
}
