<div *ngIf="isDisplay">
    <div class="header-label">
        <p>Customer Challenger Score vs Client's Average Score </p>
    </div>
    <div *ngIf="isLoading" fxLayoutAlign="center stretch" class="stats-chart-loading">
        <mat-spinner [diameter]="100" [strokeWidth]="3"></mat-spinner>
    </div>
    <div class="stats-chart-body" *ngIf="!isLoading">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center stretch">
            <div fxFlex="33%" fxLayoutAlign="center stretch">
                <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValueCustomer" [centralLabel]="centralLabel"
                    [options]="optionsCustomer" [name]="name" [bottomLabel]="bottomLabelCustomer"></rg-gauge-chart>
            </div>
    
            <div fxFlex="33%" fxLayoutAlign="center stretch">
                <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValueClient" [centralLabel]="centralLabel"
                    [options]="optionsClient" [name]="name" [bottomLabel]="bottomLabelClient"></rg-gauge-chart>
            </div>
    
            <div fxFlex="33%" fxLayoutAlign="center stretch">
                <rg-gauge-chart [canvasWidth]="canvasWidth" [needleValue]="needleValueSystem" [centralLabel]="centralLabel"
                    [options]="optionsSystem" [name]="name" [bottomLabel]="bottomLabelSystem"></rg-gauge-chart>
            </div>
        </div>
    
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center stretch">
            <div fxFlex="33%" class="Customer-label" fxLayoutAlign="center stretch">
                <p>{{this.filter?.customer?.firstName}} {{this.filter?.customer?.lastName}}</p>
            </div>
    
            <div fxFlex="33%" class="Customer-label" fxLayoutAlign="center stretch">
                <p>{{this.clientName}} </p>
            </div>
    
            <div fxFlex="33%" class="Customer-label" fxLayoutAlign="center stretch">
                <p>System</p>
            </div>
    
        </div>

        <div fxLayout="row" class="bottomCaption bottom" fxLayoutAlign="center stretch">
            <ng-container *ngFor="let item of this.colorsSettings; let i = index">
                <label style="background-color: {{item.color}} !important;"></label>
                <label class="label">{{item.label}}</label>
            </ng-container>
    
        </div>
    </div>
</div>
