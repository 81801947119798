<div class="page-design">
  <form [formGroup]="paymentLinkFormGroup">
        <div class="form-group" formGroupName="settingTabForm">
        <div fxLayout="row" fxLayout.xs="column" class="expiration-text">
            <mat-label>Expiration</mat-label>
        </div>
        <mat-radio-group
                fxLayout="column"
                fxLayout.xs="column"
                fxLayoutWrap
                fxLayoutGap="16px"
                fxLayoutAlign="start stretch"
                class="form-control"
                name="dateExpiring"
                formControlName="dateExpiring"
                (change)="onExpirationChanged()">
                <mat-radio-button value="Never">Never</mat-radio-button>
                <mat-radio-button value="One Time">One Time Payment</mat-radio-button>
                <mat-radio-button value="Time Bound">At Specific Hours</mat-radio-button>
                <mat-radio-button value="DateTime Bound">At Specific Date</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="timePick">        
            <div fxLayout="column" class="top-margin">
                <input 
                    [ngxTimepicker]="picker" 
                    placeholder="hh:mm"                     
                    class="input-box" 
                    formControlName="specificTime" 
                    name="specificTime"                    
                    [format]="24"
                    onkeydown="event.preventDefault()" 
                >                
                <ngx-material-timepicker
                    [timepickerClass]="'custome-class'"                    
                    #picker>
                </ngx-material-timepicker>
            </div>        
            
        </div>
        <div *ngIf="dateTimePick">
            <mat-form-field appearance="outline">
                <input matInput [ngxMatDatetimePicker]="picker" formControlName="specificDateTime" 
                    name="specificDateTime" placeholder="Choose a Date and Time" [formControl]="dateControl"
                    [min]="minDate" [max]="maxDate" [disabled]="disabled" onkeydown="event.preventDefault()">
                <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds"
                    [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
                    [touchUi]="touchUi" [color]="color" [enableMeridian]="enableMeridian" 
                    >
                </ngx-mat-datetime-picker>
                </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.xs="column" class="expiration-text">
            <mat-label>Allow Recurring Transaction</mat-label>
        </div>
        <mat-radio-group
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-control"
        name="isRecurringAllowed"
        formControlName="isRecurringAllowed"
        (change)="isRecurringAllowedChanged($event)"
        >
        <mat-radio-button value="false">Yes</mat-radio-button>
        <mat-radio-button value="true">No</mat-radio-button>
        </mat-radio-group>
        </div>
    </form>
</div>
