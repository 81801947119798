<mat-dialog-content class="content">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="start stretch"
    class="main-message"
    [innerHtml]="data.message"
  ></div>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="center center"
    class="main-message"
    [innerHtml]=""
  ></div>
  <br />
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="center center"
    class="main-message"
  >
    {{ transform(counter) }}
  </div>
  <br />
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    class="allow-more-time"
    mat-button
    [style.background-color]="data.color"
    [mat-dialog-close]="true"
  >
    Allow More Time
  </button>
</mat-dialog-actions>
