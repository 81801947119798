<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>
<div class="backHeader" style="background-color: #fafafa">
  <mat-label>
    <button mat-icon-button (click)="navigateToBillingProfile()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Billing
  </mat-label>
</div>
<div class="page-header-bottom">
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Billing Invoice</div>
        <div class="page-sub-header-text-1">
          All billing Invoice related information can be viewed from this
          page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        
          
        <button *ngIf="this.isInvoiceFailed"
          mat-stroked-button
          color="primary"
          class="white-button-style"
          (click)="onRaiseATicketClick()"
        >
          RAISE A TICKET
        </button>
        
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          mat-raised-button
          color="primary"
          class="page-header-button-style" 
          (click)="DownloadBillingDetailsFile()"        
        >
          DOWNLOAD
        </button>
      </div>
    </div>
  </div>
</div>
<div class="invoice-ticket-sample">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      style="margin-bottom: 4%; margin-top: 2%"
    >
    <div  fxLayout="column">
      <div fxFlex="45%" style="line-height: 30px" fxLayoutAlign="start center">
        <strong>INVOICE :</strong>
      {{displayBillingInvoiceModel.invoiceNo}}
      </div>
      <div fxFlex="45%" style="line-height: 30px" fxLayoutAlign="start center">
        <strong>TRANSACTION # :</strong>
       {{displayBillingInvoiceModel?.trackingId}}
      </div>     
    </div>    
      <div fxFlex="55%">
        <mat-label class="centerHeader"> INVOICE </mat-label>
      </div>
    </div>
   
    <mat-divider> </mat-divider>
  
    <div
      fxLayout="row"
      fxLayout.xs="column"
      class="responsive-center"
      fxLayoutWrap
      fxLayoutGap="3%"
      fxLayoutAlign="start stretch"
    >
      <div
        fxLayout="column"
        fxLayout.xs="row"
        fxFlex="22%"
        class="logo-div"
        fxLayoutAlign="center center"
      >
      <img
      *ngIf="displayBillingInvoiceModel.invoiceLogo"
      src="{{ displayBillingInvoiceModel.invoiceLogo }}"
      class="logo-img"
      alt="InvoiceLogo image.png"
      title="sample InvoiceLogo image"
      />
      </div>
  
      <div
        fxLayout="column"
        class="client-div"
        fxFlex="50%"
        fxLayout.xs="column"
        fxLayoutGap="0.5%"
        fxLayoutAlign="start stretch"
      >
        <div
          fxLayout="row"
          class="client-address-detail"
          fxLayout.xs="row"
          fxLayoutGap="12px"
          fxLayoutAlign="start stretch"
        >
          <div fxLayout="column">
            <mat-icon
              ><img
                src="assets/invoices-icons/location.png"
                width="28px"
                height="28px"
                alt="location icon.svg"
                title="location"
            /></mat-icon>
          </div>
          <div fxLayout="column">
            <mat-chip-list aria-label="Client Address">
              <mat-chip class="page-header-profile-sublabel">
               {{displayBillingInvoiceModel.clientAddress}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <span class="spacer"></span>
        <span class="spacer"></span>
        <div
          fxLayout="row"
          class="mobile-responsive-width client-address-detail"
          fxLayout.xs="row"
          fxLayoutGap="12px"
          fxLayoutAlign="start stretch"
        >
          <div fxLayout="column">
            <mat-icon
              ><img
                src="assets/invoices-icons/email.png"
                width="28px"
                height="28px"
                alt="email icon.svg"
                title="email"
            /></mat-icon>
          </div>
          <div fxLayout="column">
            <mat-chip-list aria-label="Client Email">
              <mat-chip class="page-header-profile-sublabel">
             {{displayBillingInvoiceModel.email}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <span class="spacer"></span>
        <span class="spacer"></span>
        <div
          fxLayout="row"
          class="mobile-responsive-width client-address-detail"
          fxLayout.xs="row"
          fxLayoutGap="12px"
          fxLayoutAlign="start stretch"
          margin-left="10%"
        >
          <div fxLayout="column">
            <mat-icon
              ><img
                src="assets/invoices-icons/mobile.png"
                width="28px"
                height="28px"
                alt="mobile icon.svg"
                title="mobile"
            /></mat-icon>
          </div>
          <div fxLayout="column">
            <mat-chip-list aria-label="Client Phone">
              <mat-chip class="page-header-profile-sublabel">
               {{displayBillingInvoiceModel.phone}}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
  
      <div
        fxLayout="column"
        class="date-div"
        fxFlex="22%"
        fxLayout.xs="row"
        fxLayoutAlign="start stretch"
      >
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="5%"
          fxLayoutAlign="start start"
          class="dateInfo"
        >
          <mat-label class="invoice-text">
            <strong>DATE : </strong>            
            {{ displayBillingInvoiceModel.scheduledDate | date : "MM/dd/yyyy" }}
            </mat-label
          >
          
        </div>
    
        <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="5%"
        fxLayoutAlign="start stretch"
        class="periodInfo"
      >
        <mat-label class="period-text">
          <strong>PERIOD : </strong>          
          {{displayBillingInvoiceModel.fromDate | date : "MM/dd/yyyy"}}-{{displayBillingInvoiceModel.toDate | date : "MM/dd/yyyy"}}
          </mat-label
        >
        
      </div>
      </div>
    </div>
  
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutAlign="start stretch"
      class="customerAddress"
    >
      <mat-label
        ><b>BILL TO:   </b>  
          {{displayBillingInvoiceModel.name}},{{displayBillingInvoiceModel.clientAddress}}
        </mat-label
      >
    </div>

    <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="2%"
    style="box-shadow: rgba(3, 3, 71, 0.322) 0px 0px 15px 2px"
  >
    <div class="dynamic-invoice-gererate-form">
      <div
        class="dv-mar web-dynamic-invoice"
        fxLayout="column"
        fxLayout.xs="column"
        fxLayoutGap="1%"
      >
        <div
          class="dynamic-invoice-header"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="2%"
          fxLayoutAlign="start stretch end"
        >
      
          <div
            fxLayout="column"
            fxFlex="5%"
            fxLayoutAlign="start start stretch"
          >
             
          </div>
          
          <div fxLayout="column" fxFlex="57%">PRODUCT & SERVICES</div>
          <div fxLayout="column" fxFlex="22%">UNIT PRICE</div>
          <div fxLayout="column" fxFlex="20%">TOTAL PRICE</div>
        </div>

        <ng-container
        *ngFor="
          let product of displayBillingInvoiceModel?.parentProductDetails
        "
      >
          <mat-divider> </mat-divider>
        
          <div class="row-details"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="2%"
            fxLayoutAlign="start stretch center"
            style="margin-bottom: -1%"
          >
          
       
          
            <div
              class="billing-product-name-text"
              fxLayout="column"
              fxFlex="55%"
             
            >
          
              <mat-label>{{ product.ProductName}}</mat-label>
            </div>

           
            <div
              class="billing-product-amount-text"
              fxLayout="column"
              fxFlex="23%"            
            >
           
            <mat-label
                >${{ product.parentUnitPrice}}</mat-label
              >
            
            </div>
            <div
              class="billing-product-totalPrice-text"
              fxLayout="column"
              fxFlex="20%"
            >
              <mat-label
                >${{ product.parentTotalPrice}}</mat-label
              >
            </div>
          </div>
          <details>
            <summary></summary>
            <div class="product-expansion-details"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="1%"
            style="box-shadow: rgba(3, 3, 71, 0.322) 0px 0px 15px 2px"           
          >
            <div class="billing-gererate-form">
              <div
                class="dv-mar web-dynamic-invoice"
                fxLayout="column"
                fxLayout.xs="column"
                fxLayoutGap="1%"
              >
                <div
                  class="dynamic-invoice-header"
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutWrap
                  fxLayoutGap="2%"
                  fxLayoutAlign="start stretch end"
                >
                  <div
                    fxLayout="column"
                    fxFlex="50%"
                    fxLayoutAlign="start start stretch"
                  >
                   PRODUCTS & SERVICES
                  </div>
        
                  <div fxLayout="column" fxFlex="16%">QUANTITY</div>
                  <div fxLayout="column" fxFlex="13%">UNIT PRICE</div>
                  <div fxLayout="column" fxFlex="14%">TOTAL PRICE</div>
                </div>
        
                <ng-container
                *ngFor="
                  let childproduct of this.product.clientInvoiceLineItems
                "
              >
                  <mat-divider> </mat-divider>
        
                  <div
                    fxLayout="row"
                    fxLayout.xs="column"
                    fxLayoutWrap
                    fxLayoutGap="2%"
                    fxLayoutAlign="start stretch end"
                  >
                    <div
                      class="invoice-product-name-text"
                      fxLayout="column"
                      fxFlex="50%"
                      
                    >
                      <mat-label>{{childproduct.itemName}}</mat-label>
                    </div>
        
                    <div
                      class="invoice-product-text-quantity"
                      fxLayout="column"
                      fxFlex="18%"
                    >
                      <mat-label>{{childproduct.quantity}}</mat-label>
                    </div>
                    
                    <div
                    class="invoice-product-amount-text"
                    fxLayout="column"
                    fxFlex="14%"
                    *ngIf="(childproduct.itemName==='Discount Rates'||childproduct.itemName==='DiscountRates')"
                  >
                    <mat-label
                      > {{childproduct.unitPrice}}%</mat-label
                    >
                    
                  </div>
                  <div
                      class="invoice-product-amount-text"
                      fxLayout="column"
                      fxFlex="14%"
                      
                      *ngIf="!(childproduct.itemName==='Discount Rates'||childproduct.itemName==='DiscountRates')"
                    >
                      <mat-label 
                        >$ {{childproduct.unitPrice}}</mat-label
                      >
                    </div>
                    <div
                      class="invoice-product-amount-text"
                      fxLayout="column"
                      fxFlex="13%"
                    >
                      <mat-label
                        >$ {{childproduct.totalPrice}}</mat-label
                      >
                    </div>                  
                  </div>


                </ng-container>
              </div>
            </div>
          </div>
      </details>
        </ng-container>
      </div>
      
      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
        <div
          fxLayout="column"
          class="recurring-left responsive-left mobile-responsive-subTotal"
          fxLayout.xs="row"
          fxFlex="65%"
          fxLayout.xs="column"
          fxLayoutAlign="center center"
        >
          <div fxLayout="row" fxLayout.xs="row" fxLayoutAlign="center center">
            <mat-label
              ><strong
                >{{footerText}}</strong
              ></mat-label
            >
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayout.xs="column"
          class="recurring-right totalinfosection mobile-responsive-subTotal"
          fxFlex="35%"
          fxLayout.xs="column"
          fxLayoutAlign="center start"
          fxLayoutGap="0%"
          style="background-color: rgb(232, 232, 232)"
        >
          <div
            fxLayout="row"
            fxLayout.xs="row"
            fxLayoutAlign="start start"
            fxLayoutGap="1%"
          >
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoLeftSection billing-product-total-text fontBold"
                >SUB TOTAL :
              </mat-label>
            </div>
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoRightSection billing-product-total-text"
              >
                ${{ this.displayBillingInvoiceModel.subtotal}}
                </mat-label
              >
              
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="row"
            fxLayoutAlign="start center"
            fxLayoutGap="1%"
          >
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoLeftSection billing-product-total-text fontBold"
                >SALES TAX :
              </mat-label>
            </div>
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoRightSection billing-product-total-text"
                >${{ this.displayBillingInvoiceModel.salesTax}}</mat-label
              >
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="row"
            fxLayoutAlign="start center"
            fxLayoutGap="1%"
          >
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoLeftSection billing-product-total-text fontBold"
                >DISCOUNTS :
              </mat-label>
            </div>
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoRightSection billing-product-total-text"
                >${{ this.displayBillingInvoiceModel.discount}}</mat-label
              >
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="row"
            fxLayoutAlign="start center"
            fxLayoutGap="1%"
          >
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoLeftSection billing-product-text fontBold"
                >TOTAL :</mat-label
              >
            </div>
            <div
              fxLayout="column"
              fxLayout.xs="column"
              fxLayoutAlign="center stretch"
              fxFlex="40%"
            >
              <mat-label
                class="totalinfoRightSection .billing-product-total-font-text"
              >
                $
                {{ this.displayBillingInvoiceModel.total}}</mat-label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
