import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-employer-verification',
  templateUrl: './employer-verification.component.html',
  styleUrls: ['./employer-verification.component.scss'],
})
export class EmployerVerificationComponent {
  isLoading = false;
  employerDetails: any;
  na = 'N/A';

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  @Output() loadEmployerVerificationCardChange = new EventEmitter<boolean>();
  @Input('loadEmployerVerificationCard')
  set loadEmployerVerificationCard(loadEmployerVerificationCard: boolean) {
    if (loadEmployerVerificationCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .EmployerVerificationDetails(
          this.ibvBankVerificationId,
          this.accountType,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data != null) {
              this.employerDetails = data.data;
              this._instantVerifyReportsService.EmployerVerificationChange.emit(this.employerDetails);
              this.employerDetails.payFrequencySubmitted =
                this.employerDetails.payFrequencySubmitted == 0
                  ? this.na
                  : this.employerDetails.payFrequencySubmitted;

              this.employerDetails.payFrequencyReceived =
                this.employerDetails.payFrequencyReceived == 0
                  ? this.na
                  : this.employerDetails.payFrequencyReceived;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }

    this.loadEmployerVerificationCardChange.emit(false);
  }

  getStatusStyle(status: string) {
    return status?.toUpperCase() == 'VERIFIED'
      ? { 'background-color': '#4CAF50;' }
      : { 'background-color': '#FB8C00;' };
  }
}
