<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Inventory Management </div>
                <div class="page-sub-header-text-1">
                    All Invoice Inventory Management related data can be managed from this page
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
        style="padding-left: 1%">
        <mat-tab-group fxFlex="100%" animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
            (selectedTabChange)="selectedTabChangeEvent()">
            <mat-tab label="Product Management">
                <div class="sub-inner-div" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <!-- <div>
                    <button mat-raised-button class="white-button-style" >
                        IMPORT
                    </button>
                </div> -->
                    <div>
                        <button *ngIf = "this.userRole != '1'" mat-raised-button color="primary" class="page-header-button-style" (click)="AddItem()">
                            ADD
                        </button>
                    </div>
                </div>
                <app-inventory-item-stats></app-inventory-item-stats>
                <app-inventory-item-list></app-inventory-item-list>
            </mat-tab>

            <mat-tab label="Category Management">
                <div class="sub-inner-div" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <!-- <div>
                    <button mat-raised-button class="white-button-style" >
                        IMPORT
                    </button>
                </div> -->
                    <div>
                        <button *ngIf = "this.userRole != '1'" mat-raised-button color="primary" class="page-header-button-style" (click)="AddItem()">
                            ADD
                        </button>
                    </div>
                </div>
                <app-inventory-category-list></app-inventory-category-list>
            </mat-tab>

        </mat-tab-group>
    </div>
</div>