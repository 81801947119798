import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  minNumber,
  maxNumber,
} from '@rxweb/reactive-form-validators';

export class AddInventoryCategoryModel {
  @prop()
  id: string;

  @required()
  @pattern({
    expression: {
      Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,
    },
    message: 'Only characters, periods, hyphens are allowed.',
  })
  @pattern({
    expression: { No_Space: /.*\S.*/ },
    message: 'Pre and Post Spaces are not allowed',
  })
  @minLength({ value: 5 })
  @maxLength({ value: 30 })
  categoryName: string;

  @prop()
  @required()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00  })
  @maxNumber({ value:100})
  salesTax: number;

  @prop()
  @required()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00  })
  discount: number;

  @prop()
  @required()   
  discountType: string ='Percentage';

  @prop()
  isActive: Boolean =true;

  @prop()
  enteredby:string ;
}






 