import { FormGroup } from '@angular/forms';
    
export function ConfirmedValidator(password: string, confirmPassword: string){
    return (formGroup: FormGroup) => {
        const controlPassword = formGroup.controls[password];
        const controlConfirmPassword = formGroup.controls[confirmPassword];
        if (controlConfirmPassword.errors && !controlConfirmPassword.errors['confirmedValidator']) {
            return;
        }
        if (controlPassword.value !== controlConfirmPassword.value) {
            controlConfirmPassword.setErrors({ confirmedValidator: true });
        } else {
            controlConfirmPassword.setErrors(null);
        }
    }
}