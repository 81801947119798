<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div fxLayout="column" class="container" 
[style.--active-fontFamily]="pageSettingModel?.themeFont" 
fxLayoutWrap>
  <div class="payment-div">
    <div
      *ngIf="!isLoading"
      class="payment-order-container w100"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="5%"
    >
      <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
        <img
          class="img-invalid-token"
          src="/assets/payment-widget-icons/msg_failed.svg"
          alt="{{ errorMessage }}"
          title="{{ errorMessage }}"
        />
      </div>

      <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
        <span class="header-label"> {{ errorMessage }} </span>
      </div>
    </div>
  </div>
</div>
