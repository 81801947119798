import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-theme-dialog',
  templateUrl: './confirmation-theme-dialog.component.html',
  styleUrls: ['./confirmation-theme-dialog.component.scss'],
})
export class ConfirmationThemeDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationThemeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close('Cancel');
  }
}
