<div class="rule-card">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayout.xs="column" class="rule-card-header">
        <div fxFlex="70%" >
            <label>{{model?.ruleTitle}}</label>
        </div>
        <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end">
            <!-- We always enable this rule -->
            <!-- <div class="ck-active-rule">
                <mat-checkbox class="checkbox-margin" (change)="chkActive($event)" [(ngModel)]="model.isActive">Active</mat-checkbox>
            </div> -->
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayout.xs="column" class="rule-card-body">
        <div fxLayout="column" fxFlex="70%" fxLayoutGap="5px">
            <div *ngFor="let ruleItem of this.model?.scoreRules; let i = index">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutWrap  fxLayoutGap="20px">
                    <div class="rule-card-item-label">
                        <label>Multiply with</label>
                    </div>
                    <div fxFlex="100px">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Points</mat-label>
                            <input type="number" min="0" max="1000" maxlength="4" matInput required title="Deduct" placeholder="" [(ngModel)]="ruleItem.deduct"  [disabled]="!model.isActive" (change)="OnDeductScoreChangeAsync()">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" *ngIf="this.validateErrors != null">
                    <div *ngFor="let error of this.validateErrors;">
                        <mat-error class="invalid-feedback"> {{error[0].value}} </mat-error>
                    </div>
                </div>
            </div>
        </div>
        <div class="rule-card-footer" fxLayout="row">
            <div class="rule-note" fxFlex="70%">
                <span class="rule-note-title">Note: </span>
                <div class="rule-note-content">This is default rule. {{model?.ruleNote}}</div>
            </div>
        </div>
    </div>
</div>