import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { AppMaterialModule } from 'src/app/app-material.module';
import { PaymentInformationComponent } from './pages/payment-information/payment-information.component';
import { OrderDetailsComponent } from './pages/payment-information/featureComponents/order-details/order-details.component';
import { PaymentDetailsComponent } from './pages/payment-information/featureComponents/payment-details/payment-details.component';
import { BillingDetailsComponent } from './pages/payment-information/featureComponents/billing-details/billing-details.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PaymentOrderComponent } from './pages/payment-order/payment-order.component';
import { DebitCreditFormatDirective } from './directives/debit-credit-format';
import { SurchargeDialogComponent } from './dialogs/surcharge-dialog/surcharge-dialog.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { SessionTimeOutDialogComponent } from './dialogs/session-time-out-dialog/session-time-out-dialog.component';
import { ConfirmationThemeDialogComponent } from './dialogs/confirmation-theme-dialog/confirmation-theme-dialog.component';
import { SharedModule } from 'src/app/sharedModules/shared.module';
import { PaymentFailureComponent } from './pages/payment-failure/payment-failure.component';
import { CardPaymentInfoComponent } from './pages/payment-information/featureComponents/payment-details/card-payment-info/card-payment-info.component';
import { AchPaymentInfoComponent } from './pages/payment-information/featureComponents/payment-details/ach-payment-info/ach-payment-info.component';
import { CardExpirationDateFormat } from './directives/card-expiration-date-format';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    NgxIntlTelInputModule,
    SharedModule,
  ],
  declarations: [
    PaymentInformationComponent,
    OrderDetailsComponent,
    PaymentDetailsComponent,
    BillingDetailsComponent,
    PaymentOrderComponent,
    DebitCreditFormatDirective,
    CardExpirationDateFormat,
    SurchargeDialogComponent,
    PaymentSuccessComponent,
    SessionTimeOutDialogComponent,
    ConfirmationThemeDialogComponent,
    PaymentFailureComponent,
    CardPaymentInfoComponent,
    AchPaymentInfoComponent,
  ],
  exports: [],
  providers: [],
})
export class PaymentWidgetModule {}
