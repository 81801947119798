<mat-card class="employer-verification-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="employer-verification-card" fxLayout="column" fxFlex="100%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      style="margin-bottom: 1%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/employerVerification.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap>
        <span class="section-header">Employer Verification</span>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      style="box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.13); overflow-y: auto"
      fxLayoutGap="16px"
      fxLayoutWrap
    >
      <div fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          class="balance-row-2"
        >
          <div
            fxLayout="column"
            fxFlex="25%"
            class="text-labels bold-style"
          ></div>
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            SUBMITTED
          </div>
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            RECEIVED
          </div>
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            STATUS
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 1% 2%"
        >
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            Employer
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.employerSubmitted ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.employerReceived ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            <button
              mat-flat-button
              color="primary"
              class="button-styles"
              [ngStyle]="getStatusStyle(employerDetails?.employerStatus)"
            >
              {{
                employerDetails?.employerStatus?.toUpperCase() ?? "NON VERIFIED"
              }}
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 1% 2%"
        >
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            Average Income
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.averageIncomeSubmitted ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.averageIncomeReceived ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            <button
              mat-flat-button
              color="primary"
              class="button-styles"
              [ngStyle]="getStatusStyle(employerDetails?.averageIncomeStatus)"
            >
              {{ employerDetails?.averageIncomeStatus ?? "NON VERIFIED" }}
            </button>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <mat-divider [inset]="true"></mat-divider>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 1% 2%"
        >
          <div fxLayout="column" fxFlex="25%" class="text-labels bold-style">
            Pay Frequency
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.payFrequencySubmitted ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            {{ employerDetails?.payFrequencyReceived ?? na }}
          </div>

          <div fxLayout="column" fxFlex="25%" class="text-labels normal-style">
            <button
              mat-flat-button
              color="primary"
              class="button-styles"
              [ngStyle]="getStatusStyle(employerDetails?.payFrequencyStatus)"
            >
              {{ employerDetails?.payFrequencyStatus ?? "NON VERIFIED" }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
