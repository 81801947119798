import { Component, Input, OnInit } from '@angular/core';
import { PaymentErrorConstants } from '../../constants/payment-error.const';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/sharedModules/services/local-storage/local-storage.service';
import { PageSettingModel, PaymentPageCustomizationModel } from '../../models/payment-page-customization.model';

@Component({
  selector: 'app-payment-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit {
  isLoading = false;

  token: string;

  pageSettingModel: PageSettingModel;

  errorMessageTitle: string;
  errorMessage: string;
  senderEmail: string;
  paymentPageSettingData: string;
  logotext : string ;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public localStorageService: LocalStorageService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.errorMessage =
      navigation?.extras?.state['errorMessage'] ??
      PaymentErrorConstants.InvalidToken;

    this.logotext = navigation?.extras?.state['logotext'] ?? "FinZeo";

    this.paymentPageSettingData =
      navigation?.extras?.state['paymentPageSettingData'] ??
      "";
  }

  ngOnInit(): void {
    this.errorMessageTitle = 'Invalid Payment Link';
    this.senderEmail = 'dhruval@transcendpay.com';
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    if (this.token) {
      this.getThemeFromResponse();
    } else {
      this.errorMessage = PaymentErrorConstants.InvalidPaymentLink;
    }
  }

  getThemeFromResponse() {
    if (this.paymentPageSettingData) {
      let paymentPageCustomizationModel = new PaymentPageCustomizationModel();
      paymentPageCustomizationModel.pageCustomizationSettings = JSON.parse(this.paymentPageSettingData);
      this.pageSettingModel = new PageSettingModel(paymentPageCustomizationModel);
    } else {
      this.getThemeFromLocalStorage();
    }
  }

  getThemeFromLocalStorage() {
    let key = this.token;
    this.pageSettingModel = new PageSettingModel(
      this.localStorageService.getPaymentPageCustomizationBasedObjectByKey(key));
  }
}
