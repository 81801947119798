<div style="height: 100%">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
      <app-dashboard-pie-chart-reports
        [transactionType]="transactionType"
        [period]="period"
        [showReturn]="false"
      >
      </app-dashboard-pie-chart-reports>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
      <app-dashboard-transaction-amount-chart
        [transactionType]="transactionType"
        [period]="period"
      >
      </app-dashboard-transaction-amount-chart>
    </div>
  </div>

  <mat-divider
    data-html2canvas-ignore="true"
    style="height: 15px; background: #ebeef1"
  ></mat-divider>

  <!-- hiding as we dont have import available at the moment -->
  <!-- <div
    data-html2canvas-ignore="true"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxFlex="100%">
      <app-data-table
        [columns]="columns"
        [data]="data"
        [isLoading]="isLoading"
        [page]="page"
        [sortBy]="sortBy"
        [noRecordMessage]="noRecordMessage"
        [allColumns]="allColumns"
        [isExport]="false"
        [isFilter]="false"
        [showMoreMenu]="true"
        [isSearch]="false"
        (pageChangedEvent)="onPageChanged($event)"
        (sortChangedEvent)="onSortChanged($event)"
        [isTableHeading]="true"
        [isTableHeadingText]="'Today’s Batch'"
      >
      </app-data-table>
    </div>
  </div> -->
</div>
