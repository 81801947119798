import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs';
import {
  InvoiceMainModel,
  InvoiceProductModel,
} from 'src/app/featureModules/models/invoice-product.model';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { SendInvoiceDialogComponent } from 'src/app/sharedModules/components/dialogs/send-invoice-dialog/send-invoice-dialog.component';

@Component({
  selector: 'app-dynamic-invoice',
  templateUrl: './dynamic-invoice.component.html',
  styleUrls: ['./dynamic-invoice.component.scss'],
})
export class DynamicInvoiceComponent implements OnInit {
  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input()
  customerId: string;

  @Input()
  data: any;

  clientlogo_const = 'logo';
  email_const = 'email';
  location_const = 'location';
  mobile_const = 'mobile';
  mobile_const1 = '';
  delete_const = 'delete';
  add_const = 'add';
  applyDiscount: boolean = false;
  isRecurringEnabled: boolean = false;
  dollerOrpercent: boolean =false ;
  isItemSelected: boolean =false ; 
  isSkuSelected: boolean =false ;
  count: number;
  serviceId:string;
  quantitydata:any;

  today = new Date();
  totalPrice: number;

  itemsalesTaxAmount : number;
  itemDiscountAmount : number;
  itemTotalPrice :number ;

  discounttype = [
    { value: '%', key: 'Percentage' },
    { value: '$', key: 'Flat' },
  ];
  isLoading: boolean;
  isFilterLoading: boolean;
  isSkuFilterLoading: boolean;
  loadMessage: 'Please wait...';
  invoiceProductFormGroup: FormGroup;
  invoiceProductModel: InvoiceProductModel;
  invoiceMainModel: InvoiceMainModel;
  isShowNoFound: boolean = false;
  isShowNoFoundSku: boolean = false;
  returnMessage: string;
  minLengthTerm = 2;
  filteredProduct: any[] = [];
  filteredSkuRecord: any[] = [];

  constructor(
    private formBuilder: RxFormBuilder,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.invoiceMainModel = new InvoiceMainModel();
    this.invoiceMainModel.invoiceProductModelList =
      new Array<InvoiceProductModel>();

    this.invoiceProductFormGroup = this.formBuilder.formGroup(
      this.invoiceMainModel
    );
    this._invoiceService.invoiceDataEmitter.subscribe((data) => {
      if (data) {
        this.getInvoice(data);       
        this.calculateAllowedRecurringCount();
      } else {
        this.addProduct();
      }
    }); 
  }

  searchProduct(i:any){    
    let isValid =  this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'].productName.valid;
    this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'].productName
    .valueChanges
    .pipe(
      filter((res:any,i:any) => {
        this.isShowNoFound = false;
        if (res) {
          return (isValid && res !== null && res.length >= this.minLengthTerm);
        } else {
          this.filteredProduct = [];
        }
      }),
      distinctUntilChanged(),
      debounceTime(10),
      tap(() => {
        this.isShowNoFound = false;
        this.filteredProduct = [];
        this.isFilterLoading = true;
      }),
      switchMap((value:any) =>
        this._invoiceService.searchServices(value, true).pipe(
          finalize(() => {
            this.isFilterLoading = false;
          })
        )
      )
    )
    .subscribe((data: any) => {
      if (data.data && !data.data.length) {
        this.filteredProduct = [];
        this.isShowNoFound = true;
      } else {
        this.filteredProduct = data.data;
        this.isShowNoFound = false;
        this.serviceId = this.filteredProduct[0].id;       
      }
    });
  }
  
  clearSelection(i:any) { 
    this.isItemSelected = false ;  
    this.isSkuSelected= false; 
    let control =this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'];   
    control.productName.setValue(null); 
    control.categoryId.setValue(null);   
    control.sku.setValue(null);
    control.quantity.setValue(0);
    control.basePrice.setValue(0);
    control.salesTax.setValue(0);
    control.discount.setValue(0);
    control.totalPrice.setValue(0);
    this.filteredProduct = [];
    this.reCalculate();
  }

  onSelected(event:any , i:any) {            
    let control =this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'];
    var selected = (this.filteredProduct = this.filteredProduct.filter((product: any) => 
    control.productName.value == product.itemName?.trim()));
    if (selected && event.option.value)  {           
        if(event.option.value.availableQuantity <= 0){
          this._toastrService.warning('Selected item is not available in the inventory!!');
          control.inventoryId.setValue(null); 
          control.categoryId.setValue(null);
          control.isActive.setValue(null);
          control.productName.setValue(null);    
          control.sku.setValue(null);
          control.quantity.setValue(0);
          control.basePrice.setValue(0);
          control.salesTax.setValue(0);
          control.discount.setValue(0);
          control.discountType.setValue(null);
          control.totalPrice.setValue(0);  
          return;          
        }        
        this.isItemSelected = true ;
        control.inventoryId.setValue(event.option.value.id);
        control.categoryId.setValue(event.option.value.categoryId);
        control.isActive.setValue(event.option.value.isActive);
        control.productName.setValue(event.option.value.itemName);
        control.sku.setValue(event.option.value.sku);
        control.quantity.setValue(1); 
        control.availableQuantity.setValue(event.option.value.availableQuantity);       
        control.basePrice.setValue(event.option.value.unitPrice);
        control.totalPrice.setValue(this.getItemTotalPrice(event.option.value));
        control.salesTax.setValue(event.option.value.salesTax);
        control.discount.setValue(event.option.value.discount);  
        control.discountType.setValue(event.option.value.discountType);        
        this.reCalculate(); 
        this.isItemSelected = false ;   
  }
} 

  getItemTotalPrice(item:any){        
    this.itemsalesTaxAmount = ((1 * item.unitPrice) * item.salesTax) /100 ;
    if(item.discountType == 'Flat'){
      this.itemDiscountAmount = item.discount;
      this.itemTotalPrice = (1 * item.unitPrice) + this.itemsalesTaxAmount - this.itemDiscountAmount ;      
    }
    else{
      this.itemDiscountAmount = ((1 * item.unitPrice) * item.discount) /100 ;
      this.itemTotalPrice = (1 * item.unitPrice) + this.itemsalesTaxAmount - this.itemDiscountAmount;
    }
    if(this.itemTotalPrice < 0 ){    
      this.itemDiscountAmount = 0 ;
      this.itemTotalPrice = (1 * item.unitPrice) + this.itemsalesTaxAmount ;
      this._toastrService.error('Discount for selected category is greater than actual calculated amount!!');    
    }      
    return this.itemTotalPrice.toFixed(2) ;      
  }

  searchSkuNumber(i:any){      
    let isValid =  this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls']
    .sku.valid;
    this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'].sku
    .valueChanges
    .pipe(
      filter((res:any,i:any) => {
        this.isShowNoFoundSku = false;
        if (res) {
          return (isValid && res !== null && res.length >= this.minLengthTerm);
        } else {
          this.filteredSkuRecord = [];
        }
      }),
      distinctUntilChanged(),
      debounceTime(10),
      tap(() => {
        this.isShowNoFoundSku = false;
        this.filteredSkuRecord = [];
        this.isSkuFilterLoading = true;
      }),
      switchMap((value:any) =>
        this._invoiceService.searchSkuNumber(value, true).pipe(
          finalize(() => {
            this.isSkuFilterLoading = false;
          })
        )
      )
    )
    .subscribe((data: any) => {
      if (data.data && !data.data.length) {
        this.filteredSkuRecord = [];
        this.isShowNoFoundSku = true;
      } else {
        this.filteredSkuRecord = data.data;
        this.isShowNoFoundSku = false;
        this.serviceId = this.filteredSkuRecord[0].id;       
      }
    });
  }

  selectedSkuRecord(product: any) {
    return product.sku?.trim();
  }

  clearSelectedSku(i:any) {  
    this.isSkuSelected = false ;
    this.isItemSelected = false;  
    let control =this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'];   
    control.productName.setValue(null); 
    control.categoryId.setValue(null);   
    control.sku.setValue(null);
    control.quantity.setValue(0);
    control.basePrice.setValue(0);
    control.salesTax.setValue(0);
    control.discount.setValue(0);
    control.totalPrice.setValue(0);
    control.discountType.setValue(null);
    this.filteredSkuRecord = [];
    this.reCalculate();
  }

  onSkuSelected(event :any ,i:any) {    
    let control =this.invoiceProductFormGroup.controls["invoiceProductModelList"]['controls'][i]['controls'];
    var selected = (this.filteredSkuRecord = this.filteredSkuRecord.filter((product: any) => 
    control.sku.value == product.sku?.trim()));
    if (selected && event.option.value)        
        if(event.option.value.availableQuantity <= 0){
          this._toastrService.warning('Selected item is not available in the inventory!!');
          control.inventoryId.setValue(null); 
          control.categoryId.setValue(null);
          control.isActive.setValue(null);
          control.productName.setValue(null);    
          control.sku.setValue(null);
          control.quantity.setValue(0);
          control.basePrice.setValue(0);
          control.salesTax.setValue(0);
          control.discount.setValue(0);
          control.discountType.setValue(null);
          control.totalPrice.setValue(0);  
          return;          
        }
        this.isSkuSelected = true ;
        control.inventoryId.setValue(event.option.value.id);
        control.categoryId.setValue(event.option.value.categoryId);
        control.isActive.setValue(event.option.value.isActive);
        control.productName.setValue(event.option.value.itemName);
        control.sku.setValue(event.option.value.sku);
        control.quantity.setValue(1); 
        control.availableQuantity.setValue(event.option.value.availableQuantity);        
        control.basePrice.setValue(event.option.value.unitPrice);
        control.totalPrice.setValue(this.getItemTotalPrice(event.option.value));
        control.salesTax.setValue(event.option.value.salesTax);
        control.discount.setValue(event.option.value.discount);
        control.discountType.setValue(event.option.value.discountType);  
        this.reCalculate();    
        this.isSkuSelected = false ;
  } 

  getInvoice(data: any) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    this.invoiceMainModel.customerId = this.customerId;
    this.invoiceMainModel.invoiceNumber = data.invoiceNumber;
    this.invoiceMainModel.fromDate = this.startDate;
    this.invoiceMainModel.toDate = this.endDate;
    this.invoiceMainModel.address = data.info.address;
    this.invoiceMainModel.email = data.info.email;
    this.invoiceMainModel.phone = data.info.phone;
    this.invoiceMainModel.customerName = data.customerName;
    this.invoiceMainModel.customerBillToAddress = data.customerBillToAddress;
    this.invoiceMainModel.clientLogo = localStorage.getItem('logoUrl'); //data.invoiceLogo;
    this.invoiceMainModel.isRecurring = false;
    this.invoiceMainModel.transactionFrequency = 'MONTHLY';
    this.invoiceMainModel.recurringCount = 0;
    this.invoiceMainModel.subTotalAmount = data.subtotalAmount;
    this.invoiceMainModel.serviceTaxAmount = data.serviceTaxAmount;
    this.invoiceMainModel.isDiscountApplied = false;  
    this.invoiceMainModel.appliedDiscountAmount = data.appliedDiscountAmount;
    this.invoiceMainModel.totalAmount = data.totalAmount;
    this.invoiceMainModel.sendBy = data.sendBy;
    this.invoiceMainModel.registeredEmail = data.registeredEmail;
    this.invoiceMainModel.registeredNumber = data.registeredNumber;
    this.invoiceMainModel.returnPolicyText = data.returnPolicyText;
    this.invoiceMainModel.dueDateDays = data.dueDateDays;
    this.invoiceMainModel.maxPayments = data.maxPayments;
    //Code For Form Array Loading    
    qaControlList.clear();
    qaControlList['arrayObject'] = [];
    if (data.customerInvoiceProduct?.length == 0) {
      var newControl = this.formBuilder.formGroup(InvoiceProductModel);
      qaControlList.push(newControl);
    } else {      
      data.customerInvoiceProduct.forEach((item: any) => {
        var newControl = this.formBuilder.formGroup(InvoiceProductModel);
        var newItem = new InvoiceProductModel();
        newItem.inventoryId=item.id;
        newItem.isActive=item.isActive;
        newItem.productName = item.productName;
        newItem.sku = item.sku;
        newItem.quantity = item.quantity;
        newItem.basePrice = item.basePrice.toFixed(2);
        newItem.salesTax = item.salesTax.toFixed(2);
        newItem.discount = item.discount;
        newItem.discountType =item.discountType;
        newItem.totalPrice = item.totalAmount; 
        newItem.availableQuantity = item.availableQuantity;
        newItem.categoryId = item.categoryId;
        newControl.patchValue(newItem);  
        newControl.controls['totalPrice'].disable();        
        qaControlList.push(newControl);
      });
    }
    this.invoiceProductFormGroup.patchValue(this.invoiceMainModel);
  }

  addProduct() {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    if (qaControlList.length > 9) {
      this._toastrService.warning('You can not add more than 10 Items !!');
      return;
    }
    var newControl = this.formBuilder.formGroup(InvoiceProductModel);
    newControl.controls['totalPrice'].disable();
    newControl.controls['discountType'].setValue("Percentage");
    qaControlList.push(newControl);
  }

  deleteProduct(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '175px',
      width: '500px',
      data: {
        delete: true,
        buttonName: 'Delete',
        successMessage: 'Are you sure you want to delete this item ?',
        subSuccessMessage:
          'Clicking on delete button will delete current item.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        let qaControlList = this.invoiceProductFormGroup.controls[
          'invoiceProductModelList'
        ] as FormArray;
        if (qaControlList.length >= 1) {
          qaControlList.removeAt(index);
          this.reCalculate();          
        } else {
          var newControl = this.formBuilder.formGroup(InvoiceProductModel);
          qaControlList.push(newControl);
          this.calculate();
        }
      }
    });
  }  

  sendInvoice() {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    //this.invoiceMainModel.customerInvoiceProduct = null;
    //qaControlList['arrayObject'] = this.invoiceMainModel.invoiceProductModelList;
    this.invoiceMainModel.customerInvoiceProduct = qaControlList['arrayObject'];    
    this.invoiceProductFormGroup.patchValue(this.invoiceMainModel);
    this._invoiceService.invoiceServiceMainModel.dueDateDays = this.invoiceMainModel.dueDateDays;
    this._invoiceService.invoiceServiceMainModel = this.invoiceProductFormGroup.value;  

    if (this._invoiceService.invoiceServiceMainModel.invoiceProductModelList.length == 0) {
      this._toastrService.warning('Please add atleast one item to generate invoice !');
      return false;
    }
    this._invoiceService.invoiceServiceMainModel = this.invoiceProductFormGroup.value;
    if (this._invoiceService.invoiceServiceMainModel.totalAmount <= 0) {
      this._toastrService.warning('Invoice with total zero or less than zero cannot be created!');
      return false;
    }
    if (this._invoiceService.invoiceServiceMainModel.invoiceProductModelList.length == 1) {
      let data = this._invoiceService.invoiceServiceMainModel.invoiceProductModelList[0];
      if (!(data.productName  && data.quantity && data.basePrice)) {
        this._toastrService.warning('Please fill all the required details in the item line !');
        return false;
      }
    }
    if (this.isRecurringEnabled && this.invoiceProductFormGroup.controls['recurringCount'].value == 0) {
      this._toastrService.warning('Recurring is Enabled , Please add recurring count greater than Zero !');
      return false;
    }  
    
    let itemNotAvailable = false;
    this.invoiceMainModel.customerInvoiceProduct.forEach((item: any) => {       
    if(item.quantity > item.availableQuantity){
      this._toastrService.warning(item.productName +' is not available in the inventory');
      itemNotAvailable = true;
    }
    if(item.isActive == false){
      this._toastrService.warning(item.productName +' is inactive in the inventory');
      itemNotAvailable = true;
    }
    });
    if(itemNotAvailable) return;

    if (this.invoiceProductFormGroup.valid) {
      this._invoiceService.dialogRef = this.dialog.open(
        SendInvoiceDialogComponent,
        {
          width: '730px',
          height: '610px',
        }
      );

      this._invoiceService.dialogRef
        .afterClosed()
        .subscribe((confirmed: boolean) => {
          if (confirmed) {
            this.isLoading = true;
            this._invoiceService
              .saveInvoiceDetails(this.invoiceMainModel)
              .subscribe((data) => {
                this.isLoading = false;
                if (data.data != null) {
                  this._toastrService.success('Invoice Sent Successfully!');
                }
              });
          }
        });
    }
  }

  reCalculate() {
    let qaControlList = this.invoiceProductFormGroup.controls['invoiceProductModelList'] as FormArray;
    let controls = this.invoiceProductFormGroup.controls;

    this.invoiceMainModel.subTotalAmount = 0;
    this.invoiceMainModel.serviceTaxAmount = 0;
    this.invoiceMainModel.appliedDiscountAmount = 0;
    
    qaControlList.controls.forEach(control => {
      // total amount 
      let prTotal = control.get('quantity').value * control.get('basePrice').value;

      // sales tax amount
      control.get('salesTaxAmount').setValue((control.get('salesTax').value * prTotal)/100);

      //discount value as selection 
      if(control.get('discountType').value == "Percentage"){
        control.get('discountAmount').setValue((control.get('discount').value * prTotal) / 100);

      }else if(control.get('discountType').value == "Flat"){
        control.get('discountAmount').setValue(control.get('discount').value);
      }

      // product total
      control.get('productTotal').setValue(prTotal);

      //set individual total price
      let itemTotalPrice = prTotal + control.get('salesTaxAmount').value - control.get('discountAmount').value
      control.get('totalPrice').setValue(itemTotalPrice.toFixed(2));

      //set to model 
      this.invoiceMainModel.subTotalAmount += control.get('productTotal').value;
      this.invoiceMainModel.serviceTaxAmount += control.get('salesTaxAmount').value;
      this.invoiceMainModel.appliedDiscountAmount += control.get('discountAmount').value;
    });
    
    
    this.invoiceMainModel.totalAmount = this.invoiceMainModel.subTotalAmount +
     this.invoiceMainModel.serviceTaxAmount -
      this.invoiceMainModel.appliedDiscountAmount;

    //this.invoiceMainModel.totalAmount = this.invoiceMainModel.subTotalAmount;

    controls['subTotalAmount'].setValue(this.invoiceMainModel.subTotalAmount);
    controls['serviceTaxAmount'].setValue( this.invoiceMainModel.serviceTaxAmount);
    controls['appliedDiscountAmount'].setValue(this.invoiceMainModel.appliedDiscountAmount);
    controls['totalAmount'].setValue(this.invoiceMainModel.totalAmount);
    this.calculateAllowedRecurringCount();
  }

  checkSku(event: any, i) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('sku').setValue(control.get('sku').value?.toUpperCase());
    if(control.value.sku){
    this._invoiceService
              .searchSku(control.get('sku').value,control.get('inventoryId').value)
              .subscribe((data) => {
                this.isLoading = false;
                if (data.data == false) {
                  this._toastrService.warning('This SKU has already been registered in Item Inventory!');
                  control.get('sku').setValue(null);
                }
              });
      }    
  }

  checkProduct(i) {        
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];    
    qaControlList.controls.forEach((obj, index) => {
      if(control.value.sku){
      if (i != index && 
        control.value.productName && 
        control.value.sku && 
        control.value.productName == obj.get('productName').value &&
        control.value.sku == obj.get('sku').value &&
        control.value.categoryId == obj.get('categoryId').value
        ) 
      {
        this._toastrService.warning('This item has already been used you can increase quantity there !' );
        control.get('productName').setValue(null);
        control.get('sku').setValue(null);
        control.get('quantity').setValue(0);
        control.get('basePrice').setValue(0);
        control.get('salesTax').setValue(0);
        control.get('discount').setValue(0);
        control.get('totalPrice').setValue(0);
        this.reCalculate();
      }
    }
    });
  }

  setSKU(i) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('SKU');
  }

  setQuantity(event:any ,i:any) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
   
    let control = qaControlList.controls[i];
    //let productValue = control.get('quantity').value * control.get('basePrice').value ;
    if(control.get('quantity').value > 100000) 
    {
      control.get('totalPrice').setValue(0);
      control.get('totalPrice').setValue(control.get('quantity').value * control.get('basePrice').value);
      control.get('productTotal').setValue(control.get('quantity').value * control.get('basePrice').value);
      control.get('productTotal').updateValueAndValidity();
      this.calculate();
      return false;      
    }
    if(control.value.productName){
    if(control.get('availableQuantity').value < event.target.value){
      this._toastrService.warning("Entered quantity is greater than available quantity in the inventory !!");
      control.get('quantity').setValue(control.get('availableQuantity').value);
      this.setSalesTax(event,i);
      control.get('totalPrice').setValue((control.get('quantity').value * control.get('basePrice').value) + control.get('salesTaxAmount').value-control.get('discountAmount').value);
      this.reCalculate();
    }
  }
    else{
      this._toastrService.warning("Please Add Item Name First !!");
    }

    if (!isNaN(control.get('quantity').value * control.get('basePrice').value) &&
      control.get('quantity').value * control.get('basePrice').value > 0 ){      
      control.get('totalPrice').setValue(control.get('quantity').value * control.get('basePrice').value);
      control.get('productTotal').setValue(control.get('quantity').value * control.get('basePrice').value);
      control.get('productTotal').updateValueAndValidity();
    } else {
      control.get('totalPrice').setValue(0);
    }       
    this.reCalculate();
  }

  setBasePrice(event:any,i) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    let productValue = control.get('quantity').value * control.get('basePrice').value ;
    if (!isNaN(control.get('quantity').value * control.get('basePrice').value) &&
      control.get('quantity').value * control.get('basePrice').value > 0) {
      control.get('totalPrice').setValue(control.get('quantity').value * control.get('basePrice').value);
      control.get('productTotal').setValue(productValue);
      control.get('productTotal').updateValueAndValidity();
    } 
    else{
      control.get('totalPrice').setValue(0);
    }
    if (control.get('basePrice').value > 100000) {
      control.get('totalPrice').setValue(0);
    }    
    this.setSalesTax(event,i);
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value - control.get('discountAmount').value ?? 0);
    this.calculate();
  }

  setSalesTax(event:any,i:any) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    let productValue = control.get('quantity').value * control.get('basePrice').value ;
    control.get('salesTax');
    control.get('salesTaxAmount').setValue((event.target.value * productValue)/100);
    control.get('salesTaxAmount').updateValueAndValidity();
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value - control.get('discountAmount').value ?? 0);
    control.get('productTotal').setValue(productValue);
    control.get('productTotal').updateValueAndValidity();
    this.reCalculate();
  }

  setTotalPrice(i) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    let productValue = control.get('quantity').value * control.get('basePrice').value ;
    if (control.get('totalPrice').value >= 100000) {
      control.get('basePrice').setValue(null);
      control.get('quantity').setValue(null);
    }
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value-control.get('discountAmount').value ?? 0);
    this.calculate();
  }

  selectDiscountType(event: any,i:any) { 
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    this.dollerOrpercent = event.value == 'Percentage' ? false : true;
    let productValue = control.get('quantity').value * control.get('basePrice').value ;
    control.get('discount').setValue(0);
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value);
    control.get('productTotal').setValue(productValue);
    control.get('productTotal').updateValueAndValidity();
    this.reCalculate();
  }

  setDiscountPercent(event:any,i:any){
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    let productValue = control.get('quantity').value * control.get('basePrice').value ;
    if (this.dollerOrpercent) {
    if (event.target.value >= productValue) {
      this._toastrService.error('Flat discount cannot be greater than total price !!');
      control.get('discount').setValue(0); 
      this.reCalculate();               
      return;
    }
    control.get('discountAmount').setValue(event.target.value*1);
    control.get('discountAmount').updateValueAndValidity(); 
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value-control.get('discountAmount').value ?? 0);
    control.get('productTotal').setValue(productValue);
    control.get('productTotal').updateValueAndValidity();
    this.calculate();     
  }
  else{
    if((event.target.value * productValue) / 100 >= productValue)    
    {
      this._toastrService.error('Percentage Discount cannot be greater than total price!!' );  
      control.get('discount').setValue(0); 
      this.reCalculate();          
      return;
    }
    control.get('discountAmount').setValue((event.target.value * productValue) / 100);
    control.get('discountAmount').updateValueAndValidity(); 
    control.get('totalPrice').setValue(productValue + control.get('salesTaxAmount').value-control.get('discountAmount').value ?? 0);
    control.get('productTotal').setValue(productValue);
    control.get('productTotal').updateValueAndValidity();
    this.calculate();
  }
  }

  calculate() {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let controls = this.invoiceProductFormGroup.controls;
    this.invoiceMainModel.subTotalAmount = 0;
    this.invoiceMainModel.serviceTaxAmount = 0;
    this.invoiceMainModel.appliedDiscountAmount = 0;
    
    qaControlList.controls.map((ctr) => {   
      //subtotal += Quantity(i)*UnitPrice(i)   
      this.invoiceMainModel.subTotalAmount += ctr.get('productTotal').value;
      //Total sales tax
      this.invoiceMainModel.serviceTaxAmount += ctr.get('salesTaxAmount').value;
       //Total Discount Amount
      this.invoiceMainModel.appliedDiscountAmount += ctr.get('discountAmount').value;
    });
    
    this.invoiceMainModel.totalAmount = this.invoiceMainModel.subTotalAmount +
    this.invoiceMainModel.serviceTaxAmount - this.invoiceMainModel.appliedDiscountAmount;

    controls['subTotalAmount'].setValue(this.invoiceMainModel.subTotalAmount);
    controls['serviceTaxAmount'].setValue( this.invoiceMainModel.serviceTaxAmount);
    controls['appliedDiscountAmount'].setValue(this.invoiceMainModel.appliedDiscountAmount);
    controls['totalAmount'].setValue(this.invoiceMainModel.totalAmount);

    this.calculateAllowedRecurringCount();
  }

  onIsRecurringSelected(event: any) {
    if (event.checked == true) {
      this.isRecurringEnabled = true;
      this.invoiceProductFormGroup.controls['recurringCount'].setValue(0);
    }
    if (event.checked == false) {
      this.invoiceProductFormGroup.controls['recurringCount'].setValue(0);
      this.isRecurringEnabled = false;
    }
  }

  stringToModelList(dataString: string): any[] {
    let values: any = [];
    if (dataString) {
      let c1 = dataString.split(':');
      c1.map((col) => {
        values.push({
          amount: parseInt(col),
        });
      });
      return values;
    } else {
      values = [{ amount: 0 }];
      return values;
    }
  }

  getErrorMessage(controlName:any){
    switch (controlName) {
      case 'productName':
        this.returnMessage = "Inventory Item is required";
        break;
      case 'sku':
        this.returnMessage = "SKU should be of 11 characters";
        break;
      case 'quantity':
        this.returnMessage = "Invalid Quantity";
        break;
      case 'basePrice':
        this.returnMessage = "Invalid BasePrice";
        break;
      case 'salesTax':
        this.returnMessage = "Invalid Salestax Percent";
        break;
      case 'discount':
        this.returnMessage = "This field is required";
        break;
    }
    return this.returnMessage;
  }

  calculateAllowedRecurringCount() {
    if (this.invoiceMainModel.maxPayments) {
      let calAllowedRecurringCount =
        this.invoiceMainModel.maxPayments.split('|');
      for (let n = 0; n < calAllowedRecurringCount.length; ++n) {
        let values = this.stringToModelList(calAllowedRecurringCount[n]);
        if (
          values &&
          values[0].amount <= this.invoiceMainModel.totalAmount &&
          this.invoiceMainModel.totalAmount <= values[1].amount
        ) {
          this.count = values[3].amount;
        }
      }
    }
  }

  checkCount(event: any) {
    if (event.target.value > 11) {
      this._toastrService.warning('Maximum Recurring Count (11) allowed !!');
      this.invoiceProductFormGroup.controls['recurringCount'].setValue(0);
    }
    if (event.target.value > this.count) {
      this._toastrService.warning(
        'For the Invoice Total of amount  ' +
          this.invoiceProductFormGroup.controls['totalAmount'].value +
          '  maximum of  ' +
          this.count +
          '  recurring counts are allowed !!'
      );
      this.invoiceProductFormGroup.controls['recurringCount'].setValue(0);      
    }
   
  }

  getLogoSrc(iconName: string) {
    return 'assets/invoices-icons/' + iconName + '.svg';
  }

  getIconSrc(iconName: string) {
    return 'assets/invoices-icons/' + iconName + '.png';
  }

  // getStyleForFormGroup(index) {
  //   return !(this.invoiceProductFormGroup.controls['invoiceProductModelList'][
  //     'controls'
  //   ][index].touched && this.invoiceProductFormGroup.controls['invoiceProductModelList'][
  //     'controls'
  //   ][index].invalid)
  //     ? { 'margin-bottom': '0%;' }
  //     : { 'margin-bottom': '3%;' };
  // }

  // getStyleForFormGroup(index) {
  //   return this.invoiceProductFormGroup.controls['invoiceProductModelList'][
  //     'controls'
  //   ][index].valid
  //     ? { 'margin-bottom': '0%;' }
  //     : { 'margin-bottom': '0%;' };
  // }

  checkNumberFormater(i) {
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('quantity').setValue(1 * control.get('quantity').value);       
  }

  checkNumberFormaterOther(event:any) {
   this.invoiceProductFormGroup.controls['appliedDiscountValue'].setValue(1 * event.target.value);
  }

  checkNumberFormaterbasep(i){
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('basePrice').setValue(1 * control.get('basePrice').value);
  }

  checkNumberFormaterDiscount(i){
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('discount').setValue(1 * control.get('discount').value);
  }

  checkNumberFormaterSalesTax(i){
    let qaControlList = this.invoiceProductFormGroup.controls[
      'invoiceProductModelList'
    ] as FormArray;
    let control = qaControlList.controls[i];
    control.get('salesTax').setValue(1 * control.get('salesTax').value);
  }
}




