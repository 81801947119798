<button mat-icon-button class="close-button" [mat-dialog-close]="true">
  <mat-icon style="color: black">close</mat-icon>
</button>
<div class="diaLog-confirm">
  <h2 style="padding-bottom: 3%">Import</h2>
  <h2>Required Columns</h2>
  <span>
    First Name, Last Name, Email ID, Company Customer, Phone(10digit numeric)
  </span>
  <br />
  <h2>Optional</h2>
  <span> Address, City, State, Zip(5digit numeric), SSN (XXX-XX-XXXX) </span>
</div>
<form [formGroup]="importForm">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start stretch"
    class="form-group"
  >
    <div
      fxLayout="row"
      fxFlex="100%"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutAlign="start stretch"
      class="form-group"
    >
      <div fxFlex="70%">
        <mat-form-field class="example-full-width" appearance="outline">
          <input
            type="text"
            matInput
            formControlName="upload"
            placeholder="Click here to Upload file"
            [(ngModel)]="fileName"
            value="{{ fileName }}"
            readonly
            required
          />
        </mat-form-field>
      </div>

      <div fxFlex="5%"></div>
      <div fxFlex="15%" style="padding-top: 2%">
        <button
          mat-button
          class="button-style button-Browse"
          (click)="file.click()"
        >
          Browse
        </button>
        <input
          type="file"
          #file
          (change)="fileChange($event)"
          style="display: none"
        />
      </div>
    </div>
  </div>
  <p class="note">Maximum Upload file size is 5MB</p>

  <br />
  <br />
  <br />
  <br />

  <mat-dialog-actions>
    <button mat-button matDialogClose="no" class="button-style-back download">
      DOWNLOAD TEMPLATE
    </button>
    <div style="width: 70%; text-align: end">
      <button mat-button matDialogClose="no" class="button-style-back">
        Cancel
      </button>
      <button mat-button class="button-style">Import</button>
    </div>
  </mat-dialog-actions>
</form>
