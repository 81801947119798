<form [formGroup]="sendInvoiceFormGroup" class="formclass">
  <h4>Registered Mobile No:</h4>

  <mat-label class="mobileNo" name="registredNumber">{{
    sendInvoiceModel.registeredNumber
  }}</mat-label>

  <h4 class="h4-style">Add New Mobile Number:</h4>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="3%"
    fxLayoutAlign="start center"
    style="margin-top: 3%;"
  >

    <div fxLayout="column" fxLayoutWrap fxFlex="40%">
      <ngx-intl-tel-input
        [ngClass]="{
          'phone-invaid':
            sendInvoiceFormGroup.controls['mobileNo'].invalid &&
            sendInvoiceFormGroup.controls['mobileNo'].touched,
          'phone-valid': sendInvoiceFormGroup.controls['mobileNo'].valid
        }"
        [preferredCountries]="preferredCountries"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [customPlaceholder]="'Mobile Number'"
        [searchCountryFlag]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.UnitedStates"
        [phoneValidation]="true"
        [separateDialCode]="separateDialCode"
        [numberFormat]="PhoneNumberFormat.National"
        [value]="phoneNumber"
        name="mobileNo"
        formControlName="mobileNo"
        [maxLength]="maxLength"
        (countryChange)="changePhone($event)"
      >
      </ngx-intl-tel-input>
      <mat-error
        *ngIf="sendInvoiceFormGroup.controls['mobileNo'].touched"
        >{{
          sendInvoiceFormGroup.controls["mobileNo"]["_errorMessage"]
        }}</mat-error
      >
      <div *ngIf="phonehint" class="hint">
        eg.: {{ phonehint }}
      </div>
      <div *ngIf="phonehint" class="hint">
        Maximum of 5 No's can be added
      </div>
    </div>
    <div fxLayout="column" fxFlex="5%" class="mb7">
      <button mat-raised-button color="primary" (click)="addNewMobileNos()">
        ADD
      </button>
    </div>
  </div>
  <br />
  <label
    *ngIf="sendInvoiceModel?.mobileNumberList?.length"
    class="additional-text"
    >Additional Mobile No:</label
  >
  <br />
  <mat-chip-list>
    <mat-chip
      class="custom-chip-style"
      *ngFor="let mn of sendInvoiceModel?.mobileNumberList; index as i"
    >
      <mat-label> {{ mn }} </mat-label>
      <mat-icon class="cancelIcon" (click)="removeNewMobileNos(i)"
        >cancel</mat-icon
      >
    </mat-chip>
  </mat-chip-list>
</form>
