<mat-card class="potential-payment-amounts-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card
    class="potential-payment-amounts-card"
    fxLayout="column"
    fxFlex="100%"
  >
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin-bottom: 1%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/recurring-debits.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap fxLayoutAlign="start start">
        <span class="section-header">Re-Payment Capability</span>
      </div>
    </div>
    <br />

    <div
      fxLayout="row"
      fxLayoutWrap
      fxLayoutAlign="center center"
      class="result-Amount"
    >
      <div fxLayout="column" class="potentialPaymentAmount">
        {{ "$" + (potentialPaymentAmount ?? 0 | number : "1.2-2") }}
      </div>
    </div>
  </mat-card>
</div>
