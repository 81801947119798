import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-finish',
  templateUrl: './forgot-password-finish.component.html',
  styleUrls: ['./forgot-password-finish.component.scss'],
})
export class ForgotPasswordFinishComponent implements OnInit {
  public forgotPassForm: FormGroup;
  isAdminLogin: boolean = false;

  constructor(private router: Router) {
    this.isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
  }

  ngOnInit(): void {}

  signInEvent() {
    this.isAdminLogin
      ? this.router.navigate(['/csr'])
      : this.router.navigate(['/']);
  }
}
