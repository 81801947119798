<mat-card class="cashflow-analysis-card" *ngIf="isLoading">
    <mat-spinner
      class="loadSpinner"
      mode="indeterminate"
      [diameter]="50"
      [strokeWidth]="3"
    >
    </mat-spinner>
  </mat-card>
  
  <div
    *ngIf="!isLoading"
    fxLayout="row"
    fxLayoutGap="12px"
    fxLayoutAlign="start stretch"
  >
    <mat-card class="cashflow-analysis-card" fxLayout="column" fxFlex="100%">
        <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
        style="margin-bottom: 1%"
      >
        <div fxLayout="column">
          <img
            class="icons"
            src="assets/ibv-report-icons/checking_savings/sectionIcons/cash-flow-analysis.png"
          />
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <span class="section-header">Cash Flow Analysis</span>
        </div>
        </div> 
        
        <div class="transactions-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">  
          <div *ngIf="!noDataforGraph" fxLayout="column" fxFlex="100%">
            <canvas height="300" baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
            [type]="barChartType" >
            </canvas>
          </div>
          <div
           *ngIf="noDataforGraph"
            fxLayout="column"
            fxFlex="100%"
            style="padding:1%;"
            class="text-labels normal-style"
            fxLayoutAlign="center center"
          >
            No data available
          </div>
        </div>
    </mat-card>
  </div>

  