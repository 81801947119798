import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-invoice-dashboard-payment-method',
  templateUrl: './invoice-dashboard-payment-method.component.html',
  styleUrls: ['./invoice-dashboard-payment-method.component.scss']
})

export class InvoiceDashboardPaymentMethodComponent implements OnInit, OnChanges {

  @Input()
  period: string;
  
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  totalCurrentCards: number = 0;
  totalCurrentEcheck: number = 0;
  totalCurrentACH: number = 0;
  totalLastCards: number = 0;
  totalLastEcheck: number = 0;
  totalLastACH: number = 0;
  
  periodCurrentLabel: string = '';
  periodLastLabel: string = '';
  achLastToolTip:string='';
  achCurrentToolTip:string='';
  echeckLastToolTip:string='';
  echeckCurrentToolTip:string='';
  cardsLastToolTip:string='';
  cardsCurrentToolTip:string='';

  constructor(private _toastrService: ToastrService,
    private _invoiceDashboardService:InvoiceDashboardService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.GetRecurringTransactionsPercentage(this.period)
    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'This Year';
      this.periodLastLabel = 'Last Year';
      this.achLastToolTip = 'ACH Last Year';
      this.achCurrentToolTip = 'ACH This Year';
      this.echeckLastToolTip = 'E-Check Last Year';
      this.echeckCurrentToolTip = 'E-Check This Year';
      this.cardsLastToolTip = 'Credit/Debit Last Year';
      this.cardsCurrentToolTip = 'Credit/Debit This Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'This Month';
      this.periodLastLabel = 'Last Month';
      this.achLastToolTip = 'ACH Last Month';
      this.achCurrentToolTip = 'ACH This Month';
      this.echeckLastToolTip = 'E-Check Last Month';
      this.echeckCurrentToolTip = 'E-Check This Month';
      this.cardsLastToolTip = 'Credit/Debit Last Month';
      this.cardsCurrentToolTip = 'Credit/Debit This Month';
    }
    else {
      this.periodCurrentLabel = 'This Week';
      this.periodLastLabel = 'Last Week';
      this.achLastToolTip = 'ACH Last Week';
      this.achCurrentToolTip = 'ACH This Week';
      this.echeckLastToolTip = 'E-Check Last Week';
      this.echeckCurrentToolTip = 'E-Check This Week';
      this.cardsLastToolTip = 'Credit/Debit Last Week';
      this.cardsCurrentToolTip = 'Credit/Debit This Week';
    }
  }

  ngOnInit(): void {
  }

  GetRecurringTransactionsPercentage(period) {
    this.isLoading = true;
    this._invoiceDashboardService.getPaymentMethodPercentage(period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.totalCurrentACH = data.data.ach.current;
          this.totalLastACH = data.data.ach.previous;

          this.totalCurrentEcheck = data.data.eCheck.current;
          this.totalLastEcheck = data.data.eCheck.previous;

          this.totalCurrentCards = data.data.cards.current;
          this.totalLastCards = data.data.cards.previous;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }
}
