<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
    <mat-label>
        <button mat-icon-button (click)="navigateToTransactionsPage()">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <span *ngIf="!existingCustomer"> Instant Fund Transactions Summary </span>
        <span *ngIf="existingCustomer"> Instant Fund Transactions Details </span>
    </mat-label>
</div>
<br />

<div class="add-page-header-spacing page-header-text">
    Create Instant Fund Transactions
</div>
<div class="add-page-header-spacing page-header-sub-text-1">
    All individual & company related transactions can be created from this page
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="40%">
        <img src="assets/grid-icons/addTransaction.png">
    </div>
    <div fxLayout="column" fxFlex="50%" style="background: #f5f5f5;">
        <mat-label class="create-now">Create Now</mat-label>
        <mat-stepper orientation="vertical" [linear]="true" #stepper style="background: #f5f5f5;">
            <ng-template matStepperIcon="edit">
                <mat-icon>check</mat-icon>
            </ng-template>
            <mat-step [stepControl]="firstFormGroup" *ngIf="!existingCustomer">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel class="stepper-header-style">
                        <span class="stepper-header-label">
                            CUSTOMER
                            <span *ngIf="userName != ''">
                                : {{ userName }}
                            </span>
                        </span>
                    </ng-template>
                    <br />

                    <mat-label class="inner-stepper-text">Select Customer</mat-label>
                    <app-search-customer (onCustomerSelectionEvent)="onCustomerSelection($event)"
                        [onlyIndividual]="true"></app-search-customer>
                    <div style="padding-top: 10px;">
                        <button mat-stroked-button matStepperNext class="page-header-button-style">CONTINUE</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="firstFormGroup" *ngIf="existingCustomer">
                <form [formGroup]="firstFormGroup">
                    <ng-template matStepLabel class="stepper-header-style">
                        <span class="stepper-header-label">
                            CUSTOMER
                            <span *ngIf="userName != ''">
                                : {{ userName }}
                            </span>
                        </span>
                    </ng-template>
                </form>
            </mat-step>
            <mat-step [stepControl]="secondFormGroup">
                <form [formGroup]="secondFormGroup">
                    <ng-template matStepLabel class="stepper-header-style">
                        <span class="stepper-header-label">FUNDED AMOUNT</span>
                    </ng-template>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                        fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex="100%">
                            <mat-label class="inner-stepper-text">Add Amount</mat-label>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                        fxLayoutAlign="start stretch">
                        <div fxLayout="column" fxFlex="100%" class="form-group">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label class="required">Amount</mat-label>
                                <input matInput currencyMask formControlName="amount" class="form-control"
                                    name="routingNumber" placeholder="Amount" autocomplete="off"
                                    appTwoDigitDecimalNumber (blur)="onBlur()" />
                                <span matPrefix>$&nbsp;&nbsp;</span>
                                <mat-error>
                                    {{secondFormGroup.controls['amount']['_errorMessage']}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div style="padding-top: 10px;">
                        <button mat-stroked-button matStepperNext class="page-header-button-style"
                            (click)="addInstantFundTransaction()">SUBMIT</button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </div>
    <div fxLayout="column" fxFlex="10%" style="background: #f5f5f5;"></div>
</div>