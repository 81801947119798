import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCardExpirationDateFormat]',
})
export class CardExpirationDateFormat {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '').slice(0, 5);
    if (trimmed.length > 2 && trimmed.indexOf('/') == -1) {
      trimmed = trimmed.slice(0, 2) + '/' + trimmed.slice(2);
    }
    input.value = trimmed;
  }
}
