import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { BillingDetailsModel } from 'src/app/externalModules/paymentWidget/models/billing-details.model';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';
import { PaymentWidgetCardService } from 'src/app/externalModules/paymentWidget/services/payment-widget-card.service';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit {
  @Input()
  billingDetailsFormGroup: FormGroup;

  @Input()
  widgetInfo: any;

  billingDetailsModel: BillingDetailsModel;
  states: any = [];

  @Input()
  pageSettingModel: PageSettingModel;

  @Output()
  formUpdatedData = new EventEmitter<FormGroup>();

  //Phone related
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneNumber;
  phonehint: any = '(201) 555-0123';
  maxLength: string = '15';

  @Output()
  billingDetailsFormGroupChange = new EventEmitter<any>();

  isCustomer: boolean = true;
  isAchMode: boolean = false;
  countries = [
    { name: 'United States', code: 'USA' },
    { name: 'Canada', code: 'CA' }
  ];
  constantJson : any;

  constructor(
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private http: HttpClient,
    private paymentWidgetCardService: PaymentWidgetCardService
  ) {}

  ngOnInit(): void {
    

    this.http.get('./assets/constants.json').subscribe((data) => {
      this.constantJson = data;
      this.loadStates('USA');
      if (this.widgetInfo != undefined) {
        this.widgetInfo.state =
          this.widgetInfo.state != null &&
          this.states.findIndex((x) => x.postalCode == this.widgetInfo.state) ==
            -1
            ? null
            : this.widgetInfo.state;
      }
    });

    this.billingDetailsFormGroup.controls['country'].setValue('USA');
    let zipCode = this.widgetInfo.zip?.trim().toUpperCase();
    this.widgetInfo.zip = zipCode;
    this.billingDetailsFormGroup.patchValue(this.widgetInfo);   

    this.billingDetailsFormGroup.valueChanges.subscribe((x) => {
      this.billingDetailsFormGroupChange.emit(this.billingDetailsFormGroup);
    });

    this.paymentWidgetCardService.entryCodeEmitter.subscribe((data) => {
      if (data) {
        this.billingDetailsFormGroup.patchValue({
          customerType: data.isCustomer,
        });
        this.isAchMode = data.isAch;
        this.isCustomer = data.isCustomer;
      }
    });
  }  

  onCountryChange(event: any): void {
    const countryCode = event.value;
    this.loadStates(countryCode);
  }

  loadStates(countryCode: string): void {
    if (this.constantJson && this.constantJson[countryCode]) {
      this.states = this.constantJson[countryCode];
    } else {
      this.states = this.constantJson['USA'];
    }
  }

  onPostalCodeFocusOut(event: any) {
    let zipCode = event.target.value.toUpperCase();
    this.billingDetailsFormGroup.controls['zip'].setValue(zipCode);
  }

  changePhone(event: any) {
    if (
      event.iso2.toLowerCase() ==
      this.billingDetailsModel?.isoCode?.toLowerCase()
    ) {
      this.billingDetailsFormGroup.controls['phone'].setValue(
        this.billingDetailsModel.phone
      );
    } else {
      this.billingDetailsFormGroup.controls['phone'].setValue('');
    }
    this.phonehint = event.placeHolder;
  }

  getCompanyClass() {
    return this.isAchMode && !this.isCustomer ? 'required' : '';
  }
}
