<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="90%">
    <h1>Export</h1>
  </div>

  <div
    fxLayout="column"
    fxFlex="10%"
    fxLayoutAlign="end stretch"
    style="margin-bottom: 25px"
  >
    <mat-icon [mat-dialog-close]>close</mat-icon>
  </div>
</div>

<div mat-dialog-content>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="25px"
    fxLayoutAlign="start stretch"
  >
    <mat-radio-group fxFlex="40%" formControlName="type" name="type">
      <mat-radio-button
        *ngFor="let type of listSelection"
        [value]="type.value"
        [checked]="type.checked"
      >
        {{ type.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <hr />

  <h3>Select Date to Export:</h3>

  <p>Hit Date from, Hit Date to, Settle Date From, Settle Date To</p>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <div style="justify-content: space-between; width: 100%">
        <mat-form-field appearance="outline" fxFlex="40%">
          <mat-label>Hit Date From </mat-label>

          <input matInput type="date" [(ngModel)]="hitDateFrom" />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40%">
          <mat-label>Settle Date From </mat-label>

          <input matInput type="date" [(ngModel)]="settleDateFrom" />
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <div style="justify-content: space-between; width: 100%">
        <mat-form-field appearance="outline" fxFlex="40%">
          <mat-label>Hit Date To </mat-label>

          <input matInput type="date" [(ngModel)]="hitDateTo"/>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="40%">
          <mat-label>Settle Date To </mat-label>

          <input matInput type="date" [(ngModel)]="settleDateTo"/>
        </mat-form-field>
      </div>
    </div>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-stroked-button color="primary" [mat-dialog-close]>Cancel</button>

  <button mat-raised-button [mat-dialog-close] color="primary" (click)="export()">Export</button>
</div>
