import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  minNumber,
  maxNumber,
} from '@rxweb/reactive-form-validators';

export class AddInventoryItemModel {
  @prop()
  id: string;

  @required()
  @pattern({
    expression: {
      Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,
    },
    message: 'Only characters, periods, hyphens are allowed.',
  })
  @pattern({
    expression: { No_Space: /.*\S.*/ },
    message: 'Pre and Post Spaces are not allowed',
  })
  @minLength({ value: 2 })
  @maxLength({ value: 50 })
  itemName: string;

  @prop()
  @required()
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @minNumber({ value: 1, message: 'Invalid Quantity*' })
  @maxNumber({ value: 99999, message: 'Invalid Quantity*' })
  quantity: number;

  @prop()
  availableQuantity:number

  @prop()
  @required()
  @minNumber({ value: 0.01, message: 'Invalid Unit Price*' })
  @maxNumber({ value: 99999, message: 'Invalid Unit Price*' })
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  unitPrice: number;

  @prop()
  @pattern({
    expression: {
      Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,
    },
    message: 'Only characters, periods, hyphens are allowed.',
  })
  @pattern({
    expression: { No_Space: /.*\S.*/ },
    message: 'Pre and Post Spaces are not allowed *',
  })
  @minLength({ value: 11, message: 'SKU should be of 11 characters*' })
  @maxLength({ value: 11, message: 'SKU should be of 11 characters*' })
  sku: string;

  @prop()
  isActive: Boolean = true;

  @prop()
  enteredby:string ;

  @prop()
  categoryId:any ;

  @prop()
  categoryIdList:any ;
  
}






