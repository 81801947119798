import { AbstractControl } from "@angular/forms";
import { prop, required, pattern, maxLength, minDate, minLength } from "@rxweb/reactive-form-validators";

export class HostedPaymentInformationModel {

    //Card-Detail Model
    @prop()
    id: number;

    @required()
    @pattern({ expression: { CardNumber: /^[0-9 ]{14,23}$/ } })
    cardNumber: string;

    @required()
    @maxLength({ value: 5 })
    @pattern({
        expression: {
            ExpirationDate: /^(0[1-9]|1[0-2])\/([0-9]{2})$/,
        },
    })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @minDate({
        conditionalExpression: function (control: AbstractControl) {
            if (
                control['expirationDate'] != null &&
                control['expirationDate'].length == 5
            ) {
                let [expMonth, expYear] = control['expirationDate'].split('/');
                let currentMonth = new Date().getMonth() + 1;
                let currentYear = parseInt(
                    new Date().toLocaleDateString('en', {
                        year: '2-digit',
                    })
                );

                return parseInt(expYear) > currentYear ||
                    (parseInt(expYear) == currentYear &&
                        parseInt(expMonth) >= currentMonth)
                    ? false
                    : true;
            }
            return false;
        },
        message: 'Invalid Expiration Date',
    })
    expirationDate: string;

    @prop()
    @required()
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { Number: /^[0-9]*$/ } })
    @minLength({ value: 3, message: 'Invalid cvv code' })
    @maxLength({ value: 4 })
    cvv: number;

    @prop()
    isRecurring: boolean;

    @prop()
    @required({
        conditionalExpression: function (control: AbstractControl) {
            return this.isRecurring == false ? false : true;
        },
    })
    frequency: string;

    @prop()
    isEndOfMonth: boolean;

    @prop()
    @required({
        conditionalExpression: function (control: AbstractControl) {
            return this.isRecurring == false ? false : true;
        },
    })
    scheduledDate: string;

    @prop()
    totalAmount: number;

    @prop()
    agreesToSurcharge: boolean;

    @prop()
    surchargeAmount: number;

    @prop()
    surchargePercent: any;

    @prop()
    cardType: string;

    @prop()
    @maxLength({ value: 2, message: 'Invalid installment count' })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { Number: /^[0-9]*$/ } })
    @required({
        conditionalExpression: function (control: AbstractControl) {
            return this.isRecurring == false ? false : true;
        },
    })
    installment: number;

    @prop()
    isInstallmentTransaction: boolean = false;

    @prop()
    skipPayment: boolean = false;

    //ACH Detail MOdel
    @prop()
    convenienceFees: any;

    @prop()
    agreesToConvenienceFees: boolean = false;

    @prop()
    @required()
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { Number: /^[0-9]*$/ } })
    @minLength({ value: 6 })
    @maxLength({ value: 17 })
    accountNumber: string;

    @prop()
    @required()
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { Number: /^[0-9]*$/ } })
    @maxLength({ value: 12 })
    @minLength({ value: 9 })
    routingNumber: string;

    @prop()
    @required()
    @maxLength({ value: 3 })
    @minLength({ value: 3 })
    entryCode: string = "PPD";

    // Billing Details Model
    @prop()
    paymentType: string;

    @required()
    customerType: boolean = true;

    @required({
        conditionalExpression: function (control: AbstractControl) {
            if (this.paymentType == 'ACH') {
                return this.customerType == true ? false : true;
            }
            return false;
        },
    })
    @maxLength({ value: 50 })
    companyName: string;

    @required({
        conditionalExpression: function (control: AbstractControl) {
            if (this.paymentType == 'ACH') {
                return this.customerType == true ? true : false;
            }
            return true;
        },
    })
    @maxLength({ value: 50 })
    @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    firstName: string;

    @required({
        conditionalExpression: function (control: AbstractControl) {
            if (this.paymentType == 'ACH') {
                return this.customerType == true ? true : false;
            }
            return true;
        },
    })
    @maxLength({ value: 50 })
    @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    lastName: string;

    @required()
    phone: string;

    @required()
    @maxLength({ value: 50 })
    @pattern({
        expression: { Email: /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/ },
    })
    email: string;

    @required()
    @maxLength({ value: 150 })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
    address1: string;

    @maxLength({ value: 150 })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
    address2: string;

    @required()
    @maxLength({ value: 50 })
    @pattern({ expression: { Alpha: /^[a-zA-Z ]*$/ } })
    @pattern({ expression: { No_Space: /.*\S.*/ } })
    city: string;

    @required()
    @maxLength({ value: 3 })
    state: string;

    @required()
    country: string;

    @required()
    @maxLength({ value: 10 })
    @pattern({ expression: { 'Alpha_Numbers': /^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/ }, message :'Enter valid postal code (e.g. 12345, 12345-6789, A1B 2C3)' })
    zip: string;

    @prop()
    isoCode: string;
}