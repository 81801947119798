import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss'],
})
export class StatementsComponent implements OnInit {
  selectedTabIndex = 0;
  loadAchTransaction: boolean = true;
  loadCurrent: boolean;
  loadHistory: boolean;
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    this.selectedTabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;
  }

  ngOnInit(): void {
    this.onTabChange();
  }

  onTabChange() {
    switch (this.selectedTabIndex) {
      case 0:
        this.loadCurrent = true;
        this.router.navigate(['/statements'], {
          queryParams: { tab: 'account-summary' },
        });
        break;
      case 1:
        this.router.navigate(['/statements'], {
          queryParams: { tab: 'statements' },
        });
        this.loadHistory = true;
        break;
    }
  }
}
