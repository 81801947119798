import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AddInventoryCategoryModel } from 'src/app/sharedModules/models/add-Inventory-Category.model';
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root',
})

export class InventoryCategoryService extends CrudService<any, any> {

  categoryCountEvent = new EventEmitter<any>(); 

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}InventoryCategory`);
  }

  getCategoryById(categoryId: any): Observable<any> {
    return this._http.get<any>(this._base + "/getInventoryCategoryById/"+ categoryId);
  }

  bulkDeactivateCategories(categoriesAndStatus: any): Observable<any> {
    return this._http.put<any>(this._base + "/bulkDeactivateCategories", categoriesAndStatus);
  }

  deactivateCategories(categoryId: any,status:boolean): Observable<any> {
    return this._http.put<any>(this._base + "/deactivateInventoryCategory/" + categoryId+ "/" +status,null);
  }

  addInventoryCategory(model: AddInventoryCategoryModel): Observable<any> {
    return this._http.post<any>(this._base + "/addInventoryCategory", model);
  }

  updateInventoryCategory(categoryId: any, model: AddInventoryCategoryModel): Observable<any> {
    return this._http.put<any>(this._base + "/updateInventoryCategory/" + categoryId, model);
  }

  getAllInventoryCategoriesGrid( filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/getAllInventoryCategories?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  searchCategories(filterText:string,isActive:boolean){
    return this._http.get<any>(this._base + '/searchForCategory/'+ filterText +'/'+ isActive);
  }
}