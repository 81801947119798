import { prop, required } from '@rxweb/reactive-form-validators';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';

export class DownloadOriginationReportModel {

  @prop()
  batchDate: AnyCatcher;

  @prop()
  achSortBy: string;

  @prop()
  achSortDirection: string;

  @prop()
  cardSortBy: string;

  @prop()
  cardSortDirection: string;
}
