import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { DesignTabControlModel, PaymentLinkCreationModel, PricingTabControlModel, SettingTabControlModel } from 'src/app/featureModules/models/payment-link-creation.model';

@Component({
  selector: 'app-tab-control',
  templateUrl: './tab-control.component.html',
  styleUrls: ['./tab-control.component.scss']
})
export class TabControlComponent implements OnInit {

  @Input()
  paymentLinkFormGroup : FormGroup;

  constructor(){ }

  ngOnInit(): void {
  }

}

