import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Utils } from 'src/app/featureModules/helper/utils';
import { StatementService } from 'src/app/featureModules/services/statement.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import moment from 'moment';
const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;
@Component({
  selector: 'app-statement-history',
  templateUrl: './statement-history.component.html',
  styleUrls: ['./statement-history.component.scss'],
})
export class StatementHistoryComponent implements OnInit, OnDestroy {
  @Input()
  tabIndex: number;
  @Input()
  noRecordMessage: string;
  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  userRole: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'EffectiveDate',
    direction: 'desc',
  };
  selection = new SelectionModel<TableColumn>(true, []);
  fromDate : string = getSafeIsoDateString(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  toDate : string = getSafeIsoDateString(new Date());
  selectedPeriod : string = 'From ' + moment(this.fromDate).format('MMM DD, yyyy')+ ' To ' + moment(this.toDate).format('MMM DD, yyyy');
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  dialogRef: MatDialogRef<any, any>;
  columns: TableColumn[] = [
    { displayName: 'ID', name: 'statementPageId', width: '5%' },
    { displayName: 'EFFECTIVE DATE', name: 'statementEffectiveDate', width: '14%' },
    { displayName: 'TOTAL DEBITS', name: 'statementTotalDebits', width: '14%' },
    { displayName: 'TOTAL CREDITS', name: 'statementTotalCredits', width: '15%' },
    { displayName: 'TOTAL RETURNS', name: 'statementTotalReturns', width: '15%' },
    { displayName: 'DAILY NET', name: 'statementDailyNet', width: '14%' }, 
    { displayName: 'ENDING BALANCE', name: 'statementEndingBalance', width: '15%' }, 
    { displayName: 'PENDING DEBITS', name: 'statementPendingDebits', width: '15%' }, 
    { displayName: 'AVAILABLE BALANCE', name: 'statementAvaiableBalance', width: '15%' },
  ];

  allColumns: TableColumn[];
  constructor(
    private router: Router,
    private statementsService: StatementService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _utils: Utils,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    
  }

  ngOnInit(): void {
    this.loadData(
      this.fromDate,
      this.toDate,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
  }
  onDelete(id: string) {}

  onRefresh(id: string) {}

  loadData(
    fromDate: any,
    toDate: any,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    this.isLoading = true;
    this.selection.clear();
    this.statementsService
      .getCurrentStatement(fromDate,toDate)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.data != null) {
            this.page.length = res.data.totalItems;
            var list = [];

            res.data?.transactions.map((item) => {
              let record = { 
                statementPageId: item.id,
                statementEffectiveDate: item.effectiveDate,
                statementTotalDebits: item.totalDebits,
                statementTotalCredits: item.totalCredits,
                statementTotalReturns: item.totalReturns,
                statementDailyNet: item.dailyNet,
                statementEndingBalance: item.endingBalance,                  
                statementPendingDebits: item.pendingDebits,                 
                statementAvaiableBalance: item.availableBalance, 
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = []
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      undefined,
      undefined,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
  }
  // Server side Filtering
  public onFilter(event: any) {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      // if (typeof this.filter === 'string' && this.filter) {
      //   this.filter = this.filter.replace("'", "''");
      // }
      this.fromDate = event.fromDate ? getSafeIsoDateString(event.fromDate) : undefined;
      this.toDate = event.toDate ? getSafeIsoDateString(event.toDate) : undefined;
      this.selectedPeriod = 'From ' + moment(this.fromDate).format('MMM DD, yyyy')+ ' To ' + moment(this.toDate).format('MMM DD, yyyy');

      this.loadData(
        this.fromDate,
        this.toDate,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        this.fromDate,
        'statementsHistoryGridDateFilter',
        'statements'
      );
    }
  }
  openDialog() {
    this.dialogRef = this.dialog.open(this.callAPIDialog, {
      width: '25%',
    });
  }
  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.fromDate,
        this.toDate,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'statementsHistoryGridPageSize',
        'statements'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'statementsHistoryGridPageIndex',
        'statements'
      );
    }
  }
  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.fromDate,
        this.toDate,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'statementsHistoryGridSortActive',
        'statements'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'statementsHistoryGridSortDirection',
        'statements'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }
  public clearDate() {
    this.fromDate = this._commonService.getListDataFromLocalStorage(
      'statementsHistoryGridDateFilter',
      'statements',
      undefined
    );
    this.toDate = null;
  }
  public onRowClick(row: any) {
    sessionStorage.setItem('fromDate', row.StartDate + 'Z');
    sessionStorage.setItem('toDate', row.EndDate + 'Z');
    this.router.navigate(['statements', row.id, 1], {
      state: {
        tabIndex: this.tabIndex,
      },
    });
  }
  viewPDF(row: any) {
    this.statementsService.PreviewPDF(row.id);
  }
  downloadPDF() {
    this.statementsService.generateCurrentStatementPDF(
      this.fromDate,
      this.toDate)
  }
  generateStatement() {
    if (this.fromDate != null && this.toDate != null) {
      this.isLoading = true;
      this.dialogRef.close();
      this.statementsService
        .generateNewStatementRecord(this.fromDate, this.toDate)
        .subscribe(
          (res) => {
            if (res.code == 200) {
              this.isLoading = false;
              this.loadData(
                this.fromDate,
                this.toDate,
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize
              );
              this.fromDate = null;
              this.toDate = null;
            }
          },
          (err) => {
            this.isLoading = false;
            this._toastrService.error(
              err.errorMessage
                ? err.errorMessage
                : 'Something went wrong, Please contact administrator!'
            );
          }
        );
    }
  }
  deleteBasedOnSelection(status: any) {
    let type = 'Delete';
    let customerIdList = this.selection['_selected'].map(({ id }) => id);
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            customerIds: customerIdList,
            status: status,
          };
          this.statementsService.deleteStatement(customerIdList).subscribe(
            (data) => {
              this.isLoading = false;
              if (data.code == 200) {
                this._toastrService.success('Delete statement successfully!');
                this.loadData(
                  undefined,
                  undefined,
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize
                );
              }
            },
            (err) => {
              this.isLoading = false;
              this._toastrService.error(
                err.errorMessage
                  ? err.errorMessage
                  : 'Something went wrong, Please contact administrator!'
              );
            }
          );
        }
      });
    }
  }
  ngOnDestroy() {
    if (!this.router.url.includes('statements'))
      localStorage.removeItem('statements');
  }
}
