import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentErrorConstants } from '../constants/payment-error.const';

@Injectable({
  providedIn: 'root',
})
export class PaymentWidgetAchService extends CrudService<any, any> {
  errorMessage: string = PaymentErrorConstants.InvalidPaymentLink;

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_PAYMENTWIDGET_ACH}`);
  }

  SubmitAchPaymentWidget(model: any): Observable<any> {
    return this._http.post<any>(
this._base + 'ExternalAchHostedPaymentPage/SubmitAchPaymentWidget',
      model
    );
  }

  GetUpcomingHolidays(calendarYear: number) {
    return this._http.post<any>(
this._base + 'ExternalAchHostedPaymentPage/GetUpcomingHolidays',
      {
        calendarYear: calendarYear,
      }
    );
  }
}
