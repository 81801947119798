import { AbstractControl } from "@angular/forms";
import { maxLength, pattern, prop, required } from "@rxweb/reactive-form-validators";

export class TicketFilterModel {
  @prop()
  ticketStatus: string;

  @prop()
  priority: string;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {      
      return this.periodEndDate != null ? true : false;
    },
  })
  periodStartDate: Date;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {      
      return this.periodStartDate != null ? true : false;
    },
  }) 
  periodEndDate: Date;
}
