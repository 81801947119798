<mat-spinner
  *ngIf="isLoading"
  style="margin: 0 auto; top: 50%"
  mode="indeterminate"
  [diameter]="50"
  [strokeWidth]="3"
>
  {{ loadMessage }}
</mat-spinner>

<div *ngIf="showReturn">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    style="margin-left: 1em; margin-bottom: 2em"
  >
    <div fxLayout="column" fxFlex="85%">
      <span class="pieLabel">Return Rate</span>
    </div>
    <div fxLayout="column" fxFlex="15%">
      <button
        data-html2canvas-ignore="true"
        mat-stroked-button
        color="primary"
        class="white-button-style reportBtn"
        (click)="navigateToReportPage()"
      >
        VIEW MORE
      </button>
    </div>
  </div>
  
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch">  
    <div fxLayout="column" fxFlex="30%" *ngIf="isAdminDataAvailable">
      <app-dashboard-pie-chart
        [doughnutChartData]="doughnutChartAdminData"
        [doughnutChartType]="doughnutChartType"
        [doughnutChartOptions]="doughnutChartReturnRateOptions"
        [period]="period"
        [name]="'Admin'"
      >
      </app-dashboard-pie-chart>
      <div class="label-style admin-text-width">Admin</div>
    </div>
    <div fxLayout="column" fxFlex="30%" *ngIf="isHostileDataAvailable">
      <app-dashboard-pie-chart
        [doughnutChartData]="doughnutChartHostileData"
        [doughnutChartType]="doughnutChartType"
        [doughnutChartOptions]="doughnutChartReturnRateOptions"
        [period]="period"
      >
      </app-dashboard-pie-chart>
      <div class="label-style admin-text-width">Hostile</div>
    </div>
    <div fxLayout="column" fxFlex="30%" *ngIf="isNonHostileDataAvailable">
      <app-dashboard-pie-chart
        [doughnutChartData]="doughnutChartNonHostileData"
        [doughnutChartType]="doughnutChartType"
        [doughnutChartOptions]="doughnutChartReturnRateOptions"
        [period]="period"
      >
      </app-dashboard-pie-chart>
      <div class="label-style admin-text-width">Non-Hostile</div>
    </div>
    </div>  
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  style="margin-left: 1em; margin-bottom: 2em"
>
  <div fxLayout="column" fxFlex="85%">
    <span class="pieLabel">Transaction Status</span>
  </div>
  <div *ngIf="!showReturn" fxLayout="column" fxFlex="15%">
    <button
      data-html2canvas-ignore="true"
      mat-stroked-button
      color="primary"
      class="white-button-style reportBtn"
      (click)="navigateToReportPage()"
    >
      VIEW MORE
    </button>
  </div>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch">  
  <div fxLayout="column" fxFlex="30%" *ngIf="isSuccessDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartSuccessData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Success</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isPendingDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartPendingData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Pending</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isFailedDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartFailedData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Failed</div>
  </div>
</div>
<div *ngIf= "isReturnDataAvailable || isCancelledDataAvailable" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="5%">  
  <div fxLayout="column" fxFlex="30%" *ngIf="isReturnDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartReturnedData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Returned</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isCancelledDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartCancelledData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Cancelled</div>
  </div>
</div>
