import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hosted-payment-page-btm-preview-web',
  templateUrl: './hosted-payment-page-btm-preview-web.component.html',
  styleUrls: ['./hosted-payment-page-btm-preview-web.component.scss']
})
export class HostedPaymentPageBtmPreviewWebComponent implements OnInit {

  isSmallView: boolean;
  deviceType: string;

  @Input()
  formData: any;

  @Input()
  changesIsLoading: boolean;
  
  @Input()
  finzeoPaymentOption: string;
  
  @Output()
  onPageChangeEvent = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
    this.isSmallView = true;
    this.deviceType = "web";
  }

  onPageChange(pageIndex: string){
    this.onPageChangeEvent.emit(pageIndex);
   }

}
