<!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center" class="title">
    EULA Agreement
</div> -->
<div class="eula-container">
  <div #eula_Page1 id="eula_Page1">
    <div class="eula-title">      
      <b *ngIf="logotext"> END-USER LICENSE AGREEMENT (EULA) FOR {{logotext}} FINANCIAL SUITE</b>
      <b *ngIf="!logotext"> END-USER LICENSE AGREEMENT (EULA) FOR {{logotext}} FINANCIAL SUITE</b>
    </div>
    <br /><br />
    <div class="eula-points">
      <b>IMPORTANT:</b> PLEASE READ THIS AGREEMENT CAREFULLY BEFORE CONTINUING
      WITH THE REGISTRATION PROCESS AND USING THE PRODUCT.<br />
      This End-User License Agreement ("EULA") is a legal agreement between you
      (either an individual or a single entity) and {{logotext}} for the {{logotext}}
      Financial Suite, a web-based software as a service product, which may
      include associated online or electronic documentation ("Software Product"
      or "Service"). By registering, accessing or otherwise using the Software
      Product, you agree to be bound by the terms of this EULA. If you do not
      agree to the terms of this EULA, do not register or use the Software
      Product.
      <br /><br />
      <b>1. GRANT OF LICENSE:</b> This EULA grants you the following rights:-
      **Access and Use:** You may access and use the Service provided you have a
      valid and active user account. <br /><br />
      <b>2. PROHIBITED ACTIVITIES:</b> You agree not to use the Service for any
      illegal or harmful activities, including but not limited to money
      laundering, fraud, or any other activity that violates applicable law.
      <br /><br />
      <b>3. CONFIDENTIALITY:</b> You agree that all non-public information you
      obtain through the use of the Service is the proprietary confidential
      information of {{logotext}} and its licensors. You agree not to disclose,
      distribute, or share this information with any third party without the
      express written consent of {{logotext}}. <br /><br />
      <b>4. INTENDED USE:</b> You agree that your use of the Service is strictly
      for your business operations and is not intended for, nor shall it be used
      for, the purpose of creating, directly or indirectly, a product or service
      that is competitive with or similar to the Service. <br /><br />
      <b>5. DESCRIPTION OF OTHER RIGHTS AND LIMITATIONS:</b>- Maintenance of
      Copyright Notices: You must not remove or alter any copyright notices on
      the Service. <br /><br />
      <b>6. THIRD-PARTY MATERIALS:</b> The Service may display, include, or make
      available third-party content (including data, information, applications,
      and other products services) or provide links to third-party websites or
      services ("Third-Party Materials"). You acknowledge and agree that {{logotext}}
      is not responsible for Third-Party Materials, including their accuracy,
      completeness, timeliness, validity, copyright compliance, legality,
      decency, quality, or any other aspect thereof. <br /><br />
    </div>
  </div>
  <div #eula_Page2 id="eula_Page2" class="eula-points">
    <b>7. DATA COLLECTION AND USE:</b> By using {{logotext}} Financial Suite, you
    agree that {{logotext}} may collect and maintain information provided by you. This
    data will be handled in accordance with {{logotext}}'s Privacy Policy.
    <br /><br />
    <b>8. TERMINATION:</b> Without prejudice to any other rights, {{logotext}} may
    terminate this EULA if you fail to comply with the terms and conditions of
    this EULA. In such event, you must cease all use of the Service and destroy
    all copies of any related documentation. <br /><br />
    <b>9. COPYRIGHT:</b> All title, including but not limited to copyrights, in
    and to the Service and any copies thereof (if applicable) are owned by
    {{logotext}} or its suppliers. All title and intellectual property rights in and
    to the content that may be accessed through use of the Service is the
    property of the respective content owner and may be protected by applicable
    copyright or other intellectual property laws and treaties. <br /><br />
    <b>10. NO WARRANTIES:</b> {{logotext}} expressly disclaims any warranty for the
    Service. The Service is provided 'As Is' without any express or implied
    warranty of any kind. <br /><br />
    <b>11. LIMITATION OF LIABILITY:</b> In no event shall {{logotext}} or its
    suppliers be liable for any damages whatsoever (including, without
    limitation, damages for loss of business profits, business interruption,
    loss of business information, or any other pecuniary loss) arising out of the use 
    of or inability to use the {{logotext}} Financial Suite.
    <br /><br />
    <b>12. MFA OTP AGREEMENT:</b> By submitting your contact information email, 
    and cell phone you agree to receive both email and text  communication for marketing, 
    system notifications and MFA from {{logotext}} in english.
    <br /><br />
    This EULA is governed by the laws of the United States.
  </div>
</div>
<div *ngIf="data.actionButton">
  <div *ngIf="!data.viewOnly" class="checkbox">
    <mat-checkbox color="primary" (change)="checkCheckboxValue($event)">
      I accept above terms and conditions.
    </mat-checkbox>
  </div>
  <div *ngIf="data.viewOnly" class="checkbox">
    You have approved your Eula on
    <mat-label class="date-class">{{
      data.eulaApprovedDate | date : "medium"
    }}</mat-label>
  </div>
  <div fxLayout="row" fxLayout.xs="column">
    <div *ngIf="data.viewOnly" fxLayout="column" fxLayoutAlign="center center">
      <button
        mat-raised-button
        color="primary"
        class="mL"
        (click)="downloadEula()"
      >
        DOWNLOAD
      </button>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        class="mR"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="end end"
      >
        <button
          *ngIf="!data.viewOnly"
          mat-stroked-button
          color="primary"
          class="white-button-style"
          [mat-dialog-close]="false"
        >
          <span>DECLINE</span>
        </button>
        <button
          *ngIf="!data.viewOnly"
          [disabled]="!iAcceptChecked"
          mat-raised-button
          color="primary"
          class="page-header-button-style"
          [mat-dialog-close]="true"
        >
          {{ data.buttonName }}
        </button>
        <button
          *ngIf="data.viewOnly"
          mat-stroked-button
          color="primary"
          class="white-button-style"
          [mat-dialog-close]="false"
        >
          <span>CLOSE</span>
        </button>
      </div>
    </div>
  </div>
</div>
