import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxOtpInputComponent, NgxOtpInputConfig } from 'ngx-otp-input';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { AuthService } from 'src/app/sharedModules/services/authentication/authentication.service';
import {
  ResendOtpRequest,
  ValidateOtpRequest,
} from 'src/app/sharedModules/services/login/login.proxies';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-send-otp',
  templateUrl: './send-otp.component.html',
  styleUrls: ['./send-otp.component.scss'],
})
export class SendOtpComponent implements OnInit {
  @ViewChild('ngxotp') ngxOtp: NgxOtpInputComponent;
  ngOnInit(): void {}
  otp: string;
  isRememberToken: boolean = false;
  form: FormGroup;
  isAdminLogin: boolean;

  private _response: any;
  public get response(): any {
    return this._response;
  }
  public set response(value: any) {
    this._response = value;
  }
  constructor(
    private router: Router,
    private loginService: LoginService,
    private notiService: NotificationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
  }

  backEvent() {
    this.isAdminLogin
      ? this.router.navigate(['/csr'])
      : this.router.navigate(['/']);
  }
  verifyOtp = async () => {
    var req: ValidateOtpRequest = new ValidateOtpRequest({
      otp: this.otp.toString(),
      userEmail: localStorage.getItem('userEmail'),
      tokenProvider: localStorage.getItem('tokenProvider'),
      isAdminLogin: this.isAdminLogin,
    });

    this.loginService.validateOTP(req, this.isRememberToken);
  };

  resendOtp = async () => {
    var req: ResendOtpRequest = new ResendOtpRequest({
      tokenProvider: localStorage.getItem('tokenProvider'),
      isAdminLogin: this.isAdminLogin,
    });

    await this.loginService.resendOTP(req);
  };
  clearOtpCode() {
    this.ngxOtp.clear();
  }
  handleOtpChange($event: string[]): void {
    this.otp = $event.join('');
  }
  checkCheckboxVakue($event) {
    this.isRememberToken = $event.checked;
  }

  handleFill($event: string): void {}
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      container: ['my-super-container', 'otp'],
      inputBox: 'my-super-box-class',
      input: ['my-super-input-class', 'my-super-input-class-array-test'],
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
}
