import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {InventoryCategoryService} from 'src/app/featureModules/services/inventory-category.service';


@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss']
})
export class OtherComponent implements OnInit {

  
  loadMessage: string = 'Please wait..';
  isLoading: boolean;
  period:string = "weekly";
  selectedTabIndex: number = 0;
  tabIndex:any;
  loadProductList: boolean = true;
  loadCategoryList: boolean = false;
  userRole : any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private categoryService:InventoryCategoryService
  ) { 
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  ngOnInit(): void {    
    this.tabIndex = this.activatedRoute.snapshot.paramMap.get('index');    
    if(this.tabIndex){
      this.selectedTabIndex = parseInt(this.tabIndex);     
    }

    this.categoryService.categoryCountEvent.subscribe((data) =>{
      if(data){        
        this.selectedTabIndex = 0;     
        this.selectedTabChangeEvent();
      }
    });
  }

  AddItem(){    
    switch (this.selectedTabIndex) {
      case 0:
        this.router.navigate(['other/inventory/add-item']);
        break;
      case 1:
        this.router.navigate(['other/inventory/add-category']);
        break;
    }
    
  }

  selectedTabChangeEvent() {
    switch (this.selectedTabIndex) {
      case 0:
        this.loadProductList = true;
        break;
      case 1:
        this.loadCategoryList = true;
        break;
    }
  }
 
}
