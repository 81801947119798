import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'mat-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class StarRatingComponent implements OnInit {
 
  @Input('rating')  
  rating: number;

  @Input('starCount')
  starCount: number;

  @Input('color')  
  color: string;

  @Output()
  ratingUpdated = new EventEmitter();
  
  selectedRating = 0;
  stars = [
    {
      id: 1,
      icon: 'star_border',
       class: 'star-blue star-hover star' 
    },
    {
      id: 2,
      icon: 'star_border',
       class: 'star-blue star-hover star'
    },
    {
      id: 3,
      icon: 'star_border',
       class: 'star-blue star-hover star' 
    },
    {
      id: 4,
      icon: 'star_border',
       class: 'star-blue star-hover star'
    },
    {
      id: 5,
      icon: 'star_border',
       class: 'star-blue star-hover star' 
    }

  ];

  constructor() {}
  ngOnInit(): void {
    this.selectStar(this.rating);
  }


  selectStar(value): void{
      this.stars.filter( (star) => {
        if ( star.id <= value){
          star.icon = 'star';
        }else{
          star.icon = 'star_border';
        }
        return star;
      });
    this.selectedRating = value;
    this.ratingUpdated.emit(this.selectedRating);
  } 
}





