<div>
    <div>
        <rg-gauge-chart
        [canvasWidth]="canvasWidth"
        [needleValue]="needleValue"
        [centralLabel]="centralLabel"
        [options]="options"
        [name]="name"
        [bottomLabel]="bottomLabel"></rg-gauge-chart>
    </div>
    <div fxLayout="row" class="bottomCaption bottom" fxLayoutAlign="start stretch" >
        <ng-container *ngFor="let item of this.model.legends; let i = index">
            <label style="background-color: {{item.color}} !important;"></label>
            <label class="label">{{item.label}}</label>
        </ng-container>
        
    </div>
</div>


