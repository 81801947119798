import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AddInventoryItemModel } from 'src/app/sharedModules/models/add-Inventory-Item.model';
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root',
})

export class InventoryService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}Inventory`);
  }

  getInventoryById(inventoryId: any): Observable<any> {
    return this._http.get<any>(this._base + "/getServiceById/"+ inventoryId);
  }

  getInventoryStats(): Observable<any> {
    return this._http.get<any>(this._base + "/getInventoryStats");
  }

  bulkDeactivateService(serviceAndStatus: any): Observable<any> {
    return this._http.put<any>(this._base + "/bulkDeactivateService", serviceAndStatus);
  }

  deactivateService(serviceId: any,status:boolean): Observable<any> {
    return this._http.put<any>(this._base + "/deactivateService/" + serviceId+ "/" +status,null);
  }

  addInventory(model: AddInventoryItemModel): Observable<any> {
    return this._http.post<any>(this._base + "/addService", model);
  }

  updateInventory(serviceId: any, model: AddInventoryItemModel): Observable<any> {
    return this._http.put<any>(this._base + "/updateService/" + serviceId, model);
  }

  getAllInventoryGrid( filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/GetAllServices?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  getAllCategories(isActive:boolean){
    return this._http.get<any>(this._base + "/searchCategories/"+ isActive);
  }
}