import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  getNextBusinessDate(date) {
    do {
      if (this.is_weekend(date)) {
        date.setDate(date.getDate() + 1);
      }
    } while (this.is_weekend(date));

    return date;
  }

  is_weekend = function (date) {
    var dt = new Date(date);
    if (dt.getDay() == 6 || dt.getDay() == 0) {
      return true;
    }
    return false;
  };

  // remove Z at end of date if any
  removeZ = function (date: string) {
    if (date.endsWith('Z')) {
      return new Date(date.substring(0, date.length - 1));
    }
    return date;
  };
}
