import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { SendInvoiceModel } from 'src/app/sharedModules/models/send-invoice.model';

@Component({
  selector: 'app-send-by-email',
  templateUrl: './send-by-email.component.html',
  styleUrls: ['./send-by-email.component.scss'],
})
export class SendByEmailComponent implements OnInit {
  sendInvoiceFormGroup: FormGroup;
  sendInvoiceModel: SendInvoiceModel;
  newEmailList: string;

  @Input()
  RegEmailId: string;

  @Output()
  EmailListEvent = new EventEmitter();

  constructor(
    private formBuilder: RxFormBuilder,
    private fb: FormBuilder,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    //this.getExistingdetails();
    this.sendInvoiceModel = new SendInvoiceModel();

    this.sendInvoiceModel.emailList = [];

    this.sendInvoiceModel.registeredEmail =
      this._invoiceService.invoiceServiceMainModel.registeredEmail;

      this.sendInvoiceFormGroup = this.formBuilder.formGroup(this.sendInvoiceModel);
  }

  addNewEmails() {
    this.sendInvoiceModel.email = this.sendInvoiceFormGroup.controls['email'].value;

    if (this.sendInvoiceFormGroup.controls['email'].valid &&!this.sendInvoiceModel.email) 
    {
      this._toastrService.warning('Please enter valid additional email id !');
      this.sendInvoiceFormGroup.controls['email'].setValue('');
      this.sendInvoiceModel.email = '';
      return false;
    }
    
    if (!this.sendInvoiceFormGroup.controls['email'].valid)
    {
      //this._toastrService.warning('Please enter valid email id !');
      //this.sendInvoiceFormGroup.controls['email'].setValue('');
      return false;
    }
    
    if (this.sendInvoiceModel.email?.toLowerCase() == this.sendInvoiceModel.registeredEmail?.toLowerCase()) 
    {
      this._toastrService.warning('Please enter email id other than registered email id!');
      this.sendInvoiceFormGroup.controls['email'].setValue('');
      this.sendInvoiceModel.email = '';
      return false;
    }
    if (this.sendInvoiceModel.emailList.indexOf(this.sendInvoiceModel.email) < 0) 
    {
      if (this.sendInvoiceModel.emailList.length > 4) 
      {
        this._toastrService.warning('Only 5 additional emails are allowed !');
        return;
      }
      this.sendInvoiceModel.emailList.push(this.sendInvoiceModel.email);
      this.sendInvoiceFormGroup.controls['email'].setValue('');
      this.sendInvoiceModel.email = '';
    } 
    else 
    {
      this._toastrService.warning('Email id already exists in the list !');
      this.sendInvoiceFormGroup.controls['email'].setValue('');
      this.sendInvoiceModel.email = '';
      return false;
    }
    this.EmailListEvent.emit(this.sendInvoiceModel.emailList);
    this._invoiceService.invoiceServiceMainModel.emailList = this.sendInvoiceModel.emailList;
  }

  removeNewEmails(index: number): void {
    this.sendInvoiceModel.emailList.splice(index, 1);
  }
}
