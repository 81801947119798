import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { CreditDebitTransactionService } from 'src/app/featureModules/services/creditDebit-transaction.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-creditdebit-transactions-summary',
  templateUrl: './creditdebit-transactions-summary.component.html',
  styleUrls: ['./creditdebit-transactions-summary.component.scss'],
})
export class CreditdebitTransactionsSummaryComponent implements OnInit {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  @Input()
  tabIndex: number;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  isAccess = false;
  userRole :any;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'total',
    direction: 'asc',
  };

  columns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '5%' },
    { displayName: 'NAME', name: 'name', width: '25%' },
    { displayName: 'Total Transactions', name: 'total', width: '25%' },
    { displayName: 'Amount', name: 'amount', width: '21%' },
    { displayName: 'Pending', name: 'pending', width: '12%' },
    // { displayName: 'Returned', name: 'returned', width: '10%' },
    { displayName: 'Success', name: 'successful', width: '12%' },
    // { displayName: 'Recurring', name: 'recurring', width: '10%' },
  ];

  allColumns: TableColumn[];

  constructor(
    private router: Router,
    private _creditDebitTransaction: CreditDebitTransactionService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    
    this.filter = this._commonService.getListDataFromLocalStorage(
      'cardsSummaryGridFilter',
      'cards',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsSummaryGridPageIndex',
          'cards',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'cardsSummaryGridPageSize',
          'cards',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'cardsSummaryGridSortActive',
        'cards',
        'total'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'cardsSummaryGridSortDirection',
        'cards',
        'asc'
      ),
    };
  }

  ngOnInit(): void {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
  }

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    this.isLoading = true;
    this._creditDebitTransaction
      .GetTransactionSummary(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.isAccess = true;
          if (data.data != null) {
            this.gridData = data.data.transactionsInformation;
            this.page.length = data.data.totalRecords;
          } else {
            this.gridData = [];
          }
        },
        (error) => {
          this.isAccess = false;
          this.isLoading = false;
        }
      );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'cardsSummaryGridFilter',
        'cards'
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'cardsSummaryGridPageSize',
        'cards'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'cardsSummaryGridPageIndex',
        'cards'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'cardsSummaryGridSortActive',
        'cards'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'cardsSummaryGridSortDirection',
        'cards'
      );
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {
    this.router.navigate(
      ['transactions/customer-creditdebit', customer.customerId, 1],
      {
        state: {
          tabIndex: this.tabIndex,
        },
      }
    );
  }

  navigationToPage() {
    this.router.navigate(['transactions/add-creditdebit'], {
      state: {
        url: '/transactions',
        tabIndex: this.tabIndex,
      },
    });
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!(this.router.url.includes('transactions') || this.router.url.includes('customer-ach'))){
    localStorage.removeItem('cards');
    }
  }
}
