import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material.module';
import { SharedModule } from '../sharedModules/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatureModule } from '../featureModules/feature.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { LoginComponent } from './pages/login/login.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerService } from './services/customers.service';
import { InstantVerifyComponent } from './pages/instant-verify/instant-verify.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { InstantVerifyService } from './services/instant-verify.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthenticationServiceProxy } from 'src/app/sharedModules/services/login/login.proxies';
import { NotificationService } from '../sharedModules/services/notification/notification.service';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { ForgotPasswordStep1Component } from './pages/login/forgot-password-step1/forgot-password-step1.component';
import { ForgotPasswordFinishComponent } from './pages/login/forgot-password-finish/forgot-password-finish.component';
import { LeftImageComponent } from './pages/login/left-image/left-image.component';
import { InternalAchServiceProxy, StatementServiceProxy } from '../sharedModules/services/ach-transactions/ach-transactions.proxies';
import { UsersComponent } from './pages/user-management/users/users.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { UserManagementServiceProxy, UserProfileServiceProxy } from '../sharedModules/services/user-management/user-managament.proxies';
import { UserProfileComponent } from './pages/user-management/user-profile/user-profile.component';
import { StatementsComponent } from './pages/statements/statements.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { ChallengerScoreSettingsComponent } from './pages/challenger-score/challenger-score-settings.component';
import { CustomerChallengerScoreServiceProxy } from '../sharedModules/services/customer/customer.proxies';
import { OtherComponent } from './pages/other/other.component';
import { IbvReportGenerationServiceProxy } from '../sharedModules/services/ibv/ibv.proxies';
import { TicketComponent } from './pages/ticket/ticket.component';
import { SettingComponent } from './pages/setting/setting.component';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { BillingComponent } from './pages/billing/billing.component';
import { BatchTransactionsComponent } from './pages/batch-transactions/batch-transactions.component';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    SharedModule,
    FeatureModule
  ],
  declarations: [
    LoginComponent,
    CustomersComponent,
    InstantVerifyComponent,
    TransactionsComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    ForgotPasswordStep1Component,
    ForgotPasswordFinishComponent,
    LeftImageComponent,
    UsersComponent,
    ReportsComponent,
    UserProfileComponent,
    StatementsComponent,
    InvoiceComponent,
    ChallengerScoreSettingsComponent,
    OtherComponent,
    TicketComponent,
    SettingComponent,
    AdminLoginComponent,
    BillingComponent,
    BatchTransactionsComponent
  ],
  exports: [
    LoginComponent,
    CustomersComponent,
    InstantVerifyComponent,
    DashboardComponent,
    ReportsComponent,
    ReportsComponent,
    AdminLoginComponent       
  ],
  providers: [
    CustomerService,
    NotificationService,
    InstantVerifyService,
    AuthenticationServiceProxy,
    InternalAchServiceProxy,
    UserManagementServiceProxy,
    StatementServiceProxy,
    UserProfileServiceProxy,
    CustomerChallengerScoreServiceProxy,
    IbvReportGenerationServiceProxy
  ]
})
export class BlockModule {}
