<div class="row">
    <div class="col-md-12">
        <div class="error-template">
            <h1> Page Not Found!</h1>
            <div class="error-details">
                Sorry, Requested page not found!
            </div>
            <div class="error-actions">
                <button mat-raised-button color="primary" (click)="navigateToPage()">
                    <mat-icon>home</mat-icon>&nbsp; Take Me Home
                </button>
            </div>
        </div>
    </div>
</div>