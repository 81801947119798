import { AbstractControl } from '@angular/forms';
import { required, prop, maxLength, pattern, minLength, minNumber } from '@rxweb/reactive-form-validators';

export class ECheckTransactionModel {
  @prop()
  customerId: string;

  // @prop()
  // companyProcessorId:string

  @prop()
  bankAccountId:string
  
  @prop()
  @required()
  @minNumber({ value: 0.01 })  
  amount: number;

  @prop()
  referenceCheckNumber: string;

  @prop()
  @required()
  scheduledDate: string;

  @prop()
  memoText: string;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    }
  })
  frequency: string;

  @prop()
  isSameDay:boolean=false;

  @prop()
  isRecurring: boolean=false;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    }
  })
  installments: number;

  @prop()
  totalAmount:number=0;

}
