import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TicketDetailsModel } from 'src/app/featureModules/models/ticket-details.model';
import { UpdateTicketModel } from 'src/app/featureModules/models/update-ticket.model';
import { FileUploadService } from 'src/app/featureModules/services/file-upload.service';
import { TicketService } from 'src/app/featureModules/services/ticket.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';


@Component({
  selector: 'app-ticket-update',
  templateUrl: './ticket-update.component.html',
  styleUrls: ['./ticket-update.component.scss']
})
export class TicketUpdateComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  commentsList =[];
  attachmentsList : any [] = [];
  isLoading: boolean;
  loadMessage: string = 'Please wait..'; 
  ticketDetailsModel: TicketDetailsModel;
  ticketId:string;
  updateTicketFormGroup: FormGroup;
  updateTicketModel: UpdateTicketModel;
  fileName:string;
  fileAttr:string;
  fileUploadSubscription$: Subscription;
  fileDeleteSubscription$: Subscription;
  fileDownloadSubscription$: Subscription;

  uploadedFilelist: any[] = [];
  delete_const = 'Delete1';
  download_const ='DownloadImage';
  userRole : any;
  
  constructor( private router: Router,
    private _ticketService: TicketService,
    private _toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: RxFormBuilder,
    private fileUploadService : FileUploadService,
    private dialog: MatDialog) {
      var userProfile = JSON.parse(localStorage.getItem('userProfile'));
      this.userRole = userProfile['role'];
    }

  ngOnInit(): void {
    this.updateTicketModel = new UpdateTicketModel();
    this.updateTicketFormGroup = this.formBuilder.formGroup(this.updateTicketModel);  
    this.ticketDetailsModel = new TicketDetailsModel();
    this.ticketId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.ticketId) {
      this.getTicketDetails(this.ticketId);
    }      
  }

  navigateToBackPage(){
    this.router.navigate(['ticket']);
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  getTicketDetails(ticketId:string) {
    this.isLoading = true;
     this._ticketService.getTicketDetails(ticketId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if(data.data){
            this.initializeTicketDetails(data.data);
          } else {
            this._toastrService.error('Something went wrong, Please contact administrator!');
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      ); 
  }

  initializeTicketDetails(response: any){
    this.ticketDetailsModel.ticketNumber = response.ticketNumber;
    this.ticketDetailsModel.subject = response.subject;
    this.ticketDetailsModel.description = response.description;
    this.ticketDetailsModel.firstName = response.firstName;
    this.ticketDetailsModel.lastName = response.lastName;
    this.ticketDetailsModel.enteredBy = response.enteredBy;
    this.ticketDetailsModel.priority = response.priority;
    this.ticketDetailsModel.ticketStatus = response.ticketStatus;
    this.ticketDetailsModel.category = response.category;
    this.ticketDetailsModel.createdOn = response.createdOn;
    this.ticketDetailsModel.assignedTo = response.assignedTo;
    this.ticketDetailsModel.review = response.review;
    this.ticketDetailsModel.rating = response.rating;
    this.commentsList= response.comments; 
    this.attachmentsList = response.attachmentList;
  }
  verifyAttachment(list:any){
    if(list && list.length > 0){
      return false;      
    }
    return true;
  }

  onFileSelected(selectedFile:any){
    let file: File = selectedFile.target.files[0];
    if (file) {
    const allowedTypes = ["application/pdf", "application/msword", "image/jpeg","image/png","image/gif","image/jpg",
    "application/rtf" ,"application/vnd.ms-excel" ,"text/csv","text/plain","application/x-zip-compressed",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedTypes.includes(file.type)) {
        this._toastrService.warning('File type is not allowed');
        this.clearSelectedFile();
        return;
      }
    }
    if(this.uploadedFilelist.length == 5){
      this._toastrService.warning("You can upload only 5 attachments");
      return;
    }
    let isFileAlreadyUploaded =false;    
    if (file.size > 5000000) {
      this._toastrService.warning('Max 5MB file can be uploaded');
      return;
    }

    this.uploadedFilelist.forEach((item: any) => {
      if(item.name === file.name){
      this._toastrService.warning('This file is already uploaded');
      isFileAlreadyUploaded =true;
      }
    });
    if(isFileAlreadyUploaded) return;

    this.fileUploadSubscription$ = this.fileUploadService
      .uploadFile(file)
      .subscribe(
        (response) => {
          if (response.code == 201) {
            this.uploadedFilelist.push({
              name: file.name,
              path: response.data,
              size: file.size,
            });
            this._toastrService.success('Attachment uploaded successfully!');
            this.clearSelectedFile();
          } else {
            this._toastrService.warning(response.errorMessage);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  onFileDownload(filePath: any,fileName:any) {
    this._toastrService.info('Please wait.. Attachment is getting downloaded.');     
    this.fileDownloadSubscription$ = this.fileUploadService
    .downloadFile(filePath,fileName)
    .subscribe(
      (data) => {
        if (data != null) {                        
          FileSaver.saveAs( data ,fileName);                    
        } else {
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
}

  onFileDeleted(file: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: 'DELETE',
        successMessage: 'Are you sure you want to delete this Attachment?',
        subSuccessMessage:
          'Clicking on delete button will remove attachment permanently',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this.fileDeleteSubscription$ = this.fileUploadService
      .deleteFile(file.path)
      .subscribe(
        (data) => {
          if (data.code == 201) {
            let index = this.uploadedFilelist.findIndex(
              (x) => x.name == file.name
            );
            this.uploadedFilelist.splice(index,1);
            this._toastrService.success('Attachment removed successfully!');
          } else {
            this._toastrService.warning(data.errorMessage);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
      }
    });  
  }

  clearSelectedFile() {
    this.fileUpload.nativeElement.value = null;
}

  UpdateTicket(){
    let client = JSON.parse(localStorage.getItem('userProfile')); 
    this.updateTicketModel.firstName = client['firstName'] ; 
    this.updateTicketModel.lastName = client['lastName'];
    this.updateTicketModel.attachments = this.uploadedFilelist;
    this.updateTicketModel.comment = this.updateTicketFormGroup.controls['comment'].value;
    this.ticketId = this.activatedRoute.snapshot.paramMap.get('id');
     if (this.updateTicketFormGroup.valid) {
      this._ticketService.updateTicket(this.ticketId,this.updateTicketModel)
        .subscribe(
          (data) => {
            if (data.code == 201) {              
              this._toastrService.success('Ticket updated successfully!');
              this.getTicketDetails(this.ticketId);
              this.router.navigate(['ticket']);
            } else {
              this._toastrService.warning(data.errorMessage);
            }
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this._toastrService.warning('Please fill all the required details');
      this.isLoading = false;
    }
  }

  ngOnDestroy(): void {
    this.uploadedFilelist = [];
    this.updateTicketModel = null;   
    if (this.fileUploadSubscription$) {
      this.fileUploadSubscription$.unsubscribe();
      this.fileUploadSubscription$ = null;
    }
    if (this.fileDeleteSubscription$) {
      this.fileDeleteSubscription$.unsubscribe();
      this.fileDeleteSubscription$ = null;
    }
    if (this.fileDownloadSubscription$) {
      this.fileDownloadSubscription$.unsubscribe();
      this.fileDownloadSubscription$ = null;
    }
  }
}

