<div
  class="basicDetailsForm app-tertiary-box-theme-color"
  fxLayout="column"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="3%"
>
  <div
    fxLayout="row"
    class="invoice-header"
    fxLayout.xs="row"
    fxLayoutWrap
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxFlex="45%" fxLayoutGap="2%">
      <div class="invoice-text">
        <strong>INVOICE :</strong>
        #3232
      </div>
      <div class="invoice-text">
        <strong>DATE'S : </strong>12/01/2020 - 12/31/2022
      </div>
    </div>
    <div fxLayout="column" fxFlex="55%" class="invoice-header-text">
      INVOICE
    </div>
  </div>

  <mat-divider></mat-divider>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="2%"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayout.xs="row" fxFlex="25%">
      <img
        src="{{ getClientLogoSrc(clientlogo_const) }}"
        class="logo"
        alt="clientlogo image.png"
        title="sample clientlogo image"
      />
    </div>
    <div
      fxLayout="column"
      class="client-div mobile-responsive-width"
      fxFlex="55%"
      fxLayout.xs="column"
      fxLayoutGap="2%"
    >
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="12px"
        fxLayoutAlign="start center"
      >
        <div fxLayout="column">
          <img
            matTooltip="Client Address"
            src="{{ getLogoSrc(location_const) }}"
          />
        </div>
        <div fxLayout="column">
          <mat-chip-list aria-label="Client Address">
            <mat-chip class="page-header-profile-sublabel">
              Fort Street Ste 2121 ,Honolulu ,Hi 96813
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="12px"
        fxLayoutAlign="start center"
      >
        <div fxLayout="column">
          <img matTooltip="Client Email" src="{{ getLogoSrc(email_const) }}" />
        </div>
        <div fxLayout="column">
          <mat-chip-list aria-label="Client Email">
            <mat-chip class="page-header-profile-sublabel">
              Billin@Finzeo.cendPay.com
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="12px"
        fxLayoutAlign="start center"
      >
        <div fxLayout="column">
          <img matTooltip="Client Phone" src="{{ getLogoSrc(mobile_const) }}" />
        </div>
        <div fxLayout="column">
          <mat-chip-list aria-label="Client Phone">
            <mat-chip class="page-header-profile-sublabel">
              (808) 762 9622
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="20%" fxLayoutAlign="start end">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutGap="5%"
        class="dateInfo"
      >
        <mat-label><b>DATE :</b> {{ today | date : "MM/dd/yyyy" }}</mat-label>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start stretch"
    class="customerAddress"
  >
    <mat-label
      >BILL TO: J Smith Financials FDL d/b/a Eagle Rock BatesLand ,SD
      57716</mat-label
    >
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="2%"
    style="box-shadow: rgb(0 0 7 / 9%) 0px 0px 15px 2px"
  >
    <div
      class="dynamic-invoice-gererate-form app-tertiary-box-theme-color"
      fxLayout="column"
      fxLayout.xs="column"
      fxLayoutGap="1%"
    >
      <div
        class="dynamic-invoice-header"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="2%"
        fxLayoutAlign="start stretch"
      >
        <div class="fontBold" fxLayout="column" fxFlex="40%">ITEM NAME</div>
        <div class="fontBold" fxLayout="column" fxFlex="21%">SKU</div>
        <div class="fontBold" fxLayout="column" fxFlex="12%">QUANTITY</div>
        <div class="fontBold" fxLayout="column" fxFlex="12%">UNIT PRICE</div>
        <div class="fontBold" fxLayout="column" fxFlex="15%">TOTAL PRICE</div>
      </div>

      <ng-container *ngFor="let product of customerInvoiceProductsList">
        <mat-divider></mat-divider>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="2%"
          fxLayoutAlign="start stretch"
        >
          <div class="invoice-product-name-text" fxLayout="column" fxFlex="40%">
            <mat-label>{{ product?.productName }}</mat-label>
          </div>
          <div class="invoice-other-column-text" fxLayout="column" fxFlex="21%">
            <mat-label>{{ product?.sku }}</mat-label>
          </div>
          <div class="invoice-other-column-text" fxLayout="column" fxFlex="12%">
            <mat-label>{{ product?.quantity }}</mat-label>
          </div>
          <div class="invoice-other-column-text" fxLayout="column" fxFlex="12%">
            <mat-label>$ {{ product?.basePrice | number : "1.2-2" }}</mat-label>
          </div>
          <div class="invoice-other-column-text" fxLayout="column" fxFlex="15%">
            <mat-label
              >$ {{ product?.totalAmount | number : "1.2-2" }}</mat-label
            >
          </div>
        </div>
      </ng-container>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="row">
        <mat-divider class="blank-divider"></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="65%" fxLayout.xs="column">
          <mat-label class="fontBold">RECURRING COUNT : 9</mat-label>
        </div>

        <div
          fxLayout="column"
          class="totalinfosection"
          fxFlex="35%"
          fxLayoutAlign="start stretch"
          fxLayoutGap="2%"
        >
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="16px"
          >
            <div fxLayout="column" fxLayoutAlign="end end" fxFlex="60%">
              <mat-label class="fontBold">SUB TOTAL :</mat-label>
            </div>
            <div fxLayout="column" fxFlex="40%">
              <mat-label> ${{ subTotalAmount | number : "1.2-2" }}</mat-label>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="16px"
          >
            <div fxLayout="column" fxLayoutAlign="end end" fxFlex="60%">
              <mat-label class="fontBold"
                >SALES TAX ({{ salesTax | number : "1.2-2" }} %) :
              </mat-label>
            </div>
            <div fxLayout="column" fxFlex="40%">
              <mat-label>${{ salesTaxAmount | number : "1.2-2" }}</mat-label>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="16px"
          >
            <div fxLayout="column" fxLayoutAlign="end end" fxFlex="60%">
              <mat-label class="fontBold">DISCOUNT (8.0 %) :</mat-label>
            </div>
            <div fxLayout="column" fxFlex="40%">
              <mat-label>${{ discountAmount | number : "1.2-2" }} </mat-label>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="16px"
          >
            <div fxLayout="column" fxLayoutAlign="end end" fxFlex="60%">
              <mat-label class="fontBold">TOTAL :</mat-label>
            </div>
            <div fxLayout="column" fxFlex="40%">
              <mat-label>{{ totalAmount | number : "1.2-2" }}</mat-label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    fxLayout="column"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="center center"
    class="notesection"
  >
    {{ returnPolicy }}
  </div>
</div>
