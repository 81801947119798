import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-dashboard-report-service-based',
  templateUrl: './dashboard-report-service-based.component.html',
  styleUrls: ['./dashboard-report-service-based.component.scss']
})
export class DashboardReportServiceBasedComponent implements OnInit {

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  @Input()
  listRefreshEvent = new EventEmitter();
  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoadingPaging = false;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  transactionStatusgridData: any;
  returnRateGridData: any;
  totalAmountGridData: any;
  transactionType: string = '';
  tableLabel: string = ""
  period: string = "yearly"
  canvasContainer: any;
  transactionHeaderLabel: string = '';

  constructor(private router: Router,
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private _toastrService : ToastrService) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.transactionType = params.get('product');
      if (this.transactionType == 'ach') {
        this.tableLabel = "ACH Transaction Amount";
        this.transactionHeaderLabel = 'ACH';
      }
      if (this.transactionType == 'creditdebit') {
        this.tableLabel = "Credit/Debit Transaction Amount";
        this.transactionHeaderLabel = 'Credit/Debit';
      }
      // hiding the INF feature
      // if (this.transactionType == 'instantfund') {
      //   this.tableLabel = "Instant Fund Transaction Amount";
      //   this.transactionHeaderLabel = 'Instant Fund';
      // }
      if (this.transactionType == 'echeck') {
        this.tableLabel = "E-Check Transaction Amount";
        this.transactionHeaderLabel = 'E-Check';
      }
    });

  }

  navigateToDashboardPage() {
    this.router.navigate(['dashboard']);
  }

  selectedTabChangeEvent(event) {
    switch (event.tab.textLabel) {
      case "RETURN RATE":
        this.canvasContainer = document.getElementById("returnRateContainer");
        break;
      case "TRANSACTION STATUS":
        this.canvasContainer = document.getElementById("transactionStatusContainer");
        break;
      case "TOTAL AMOUNT":
        this.canvasContainer = document.getElementById("totalAmountContainer");
        break;
    }
  }

  onPrintClicked() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isLoading = true;
    let fileName = "Dashboard_Service_Based_Report";
    this.canvasContainer = this.canvasContainer == null ? document.getElementById("canvasContainer") : this.canvasContainer;
    let content = [];

    content.push({ text: "Dashboard - " + this.tableLabel + " Report", style: "infoHeader" });
    content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: "#148F77",
        },
      ],
    });
    content.push("\n");

    html2canvas(this.canvasContainer, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL("image/png", 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });
      // content.push({ text: "\n", id: "breakPage_1" });

      generatedDashboardPDF(content, fileName, null);
      this.isLoading = false;
      this._toastrService.clear();
      this._toastrService.success('PDF downloaded successfully.');
    });
  }
}
