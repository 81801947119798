<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>



<div class="backHeader" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <mat-label>
    <button mat-icon-button (click)="navigateToCustomerGroupPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Back to Customer Groups
  </mat-label>
  <span class="spacer"></span>
  <div fxLayout="column" class="padding-right" fxLayoutAlign="center end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="75%" fxLayoutAlign="start stretch">
      <button mat-raised-button color="primary" (click)="customerGroupEdit()" class="page-header-button-style">
        EDIT
      </button>
    </div>
  </div>
</div>

<div fxLayout="column" fxLayoutGap="16px">
  <mat-card class="totalTransactionsDiv" fxFlex="100%">
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100%">
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Group Name</mat-label>
        <input matInput [(ngModel)]="groupName" readonly />
      </mat-form-field>

      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Transaction Type</mat-label>
        <input matInput [(ngModel)]="transactionType" readonly />
      </mat-form-field>
    </div>
  </mat-card>
  <div fxLayout="row">
    <mat-card class="totalTransactionsDiv" style="padding:0px" fxFlex="100%">
      <h3 style="padding:16px; padding-bottom: 0px;margin-bottom: 0px;">Customer Groups({{page.length}})
      </h3>
      <app-data-table [columns]="customerGrpscolumn" [data]="data" [isLoading]="isLoading"
        [noRecordMessage]="noRecordMessage" [allColumns]="allColumns" [isExport]="false" [role]="this.userRole"
        [page]="page" [isFilter]="false" [isSearch]="false" [datatablemargin]="this.datatablemargin" (pageChangedEvent)="onPageChanged($event)"
        [sortBy]="sortBy" (sortChangedEvent)="onSortChanged($event)">
      </app-data-table>
    </mat-card>
  </div>
</div>