import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatCalendarCellClassFunction,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { catchError, map, of } from 'rxjs';
import {
  AchEntryCodeKeyConstants,
  AchEntryCodeValueConstants,
} from 'src/app/externalModules/paymentWidget/constants/ach-entry-code.const';
import { SurchargeDialogComponent } from 'src/app/externalModules/paymentWidget/dialogs/surcharge-dialog/surcharge-dialog.component';
import { AchDetailsModel } from 'src/app/externalModules/paymentWidget/models/ach-details.model';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';
import { PaymentWidgetAchService } from 'src/app/externalModules/paymentWidget/services/payment-widget-ach.service';
import { PaymentWidgetCardService } from 'src/app/externalModules/paymentWidget/services/payment-widget-card.service';
import { HolidayUtils } from 'src/app/featureModules/helper/holiday.utils';

interface DrowpDown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ach-payment-info',
  templateUrl: './ach-payment-info.component.html',
  styleUrls: ['./ach-payment-info.component.scss'],
})
export class AchPaymentInfoComponent implements OnInit {
  @Input()
  achDetailsFormGroup: FormGroup;

  @Output()
  paymentMethodEvent = new EventEmitter();

  achDetailsModel: AchDetailsModel;

  minDate = new Date();
  maxDate = new Date();
  defaultScheduledDate = new Date();

  @Input()
  amount: number;

  @Input()
  isRecurringDisabled: boolean;

  frequencies: DrowpDown[] = [
    { value: 'WEEKLY', viewValue: 'Weekly' },
    { value: 'BIWEEKLY', viewValue: 'Bi-Weekly' },
    { value: 'MONTHLY', viewValue: 'Monthly' },
    { value: 'YEARLY', viewValue: 'Yearly' },
  ];

  entryCodes = [
    {
      key: AchEntryCodeKeyConstants.PPD,
      value: AchEntryCodeValueConstants.Personal,
    },
    {
      key: AchEntryCodeKeyConstants.CCD,
      value: AchEntryCodeValueConstants.Business,
    },
  ];

  @Output()
  achDetailsFormGroupChange = new EventEmitter<any>();

  @Input()
  pageSettingModel: PageSettingModel;

  constructor(
    private paymentWidgetCardService: PaymentWidgetCardService,
    private paymentWidgetAchService: PaymentWidgetAchService,
    private dialog: MatDialog,
    private _holidayUtils: HolidayUtils
  ) { }

  ngOnInit(): void {
    this.isRecurringDisabled = this.isRecurringDisabled ? this.isRecurringDisabled : !this.pageSettingModel?.availRecurringForAch;

    this.achDetailsFormGroup.controls['isRecurring'].setValue(false);

    this.achDetailsFormGroup.valueChanges.subscribe((x) => {
      this.achDetailsFormGroupChange.emit(this.achDetailsFormGroup);
    });

    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    this.setScheduledDateAndHolidayFilter();

    if (this.pageSettingModel?.isPaymentOptionAch
      && !this.pageSettingModel?.isPaymentOptionCard && this.pageSettingModel?.isAvailConvenienceFee) {
        this.setConvenienceFee();
        this.paymentWidgetCardService.entryCodeEmitter.emit(
          this.paymentWidgetCardService.getAchCustomerTypeSelection(
            true,
            true
          )
        );
      }
  }

  holidays: any;
  setScheduledDateAndHolidayFilter() {
    const onSuccess = (data: any) => {
      this.holidays = data.data;
    };
    const onError = (e) => {
      return of([]);
    };

    this.paymentWidgetAchService
      .GetUpcomingHolidays(this.minDate.getFullYear())
      .pipe(map(onSuccess), catchError(onError))
      .subscribe(() => {
        this.defaultScheduledDate =
          this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
            this.defaultScheduledDate,
            this.holidays
          );

        this.achDetailsFormGroup.controls['scheduledDate'].setValue(
          this.defaultScheduledDate
        );
      });
  }

  holidayFilter = (date: Date): boolean => {
    if (date != null) {
      return this._holidayUtils.getHolidaysFilter(this.holidays, date);
    }
  };

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date, view) => {
    if (
      view === 'month' &&
      this.maxDate.getDate() == date.getDate() &&
      this.maxDate.getMonth() == date.getMonth() &&
      this.maxDate.getFullYear() == date.getFullYear()
    ) {
      let lastDateOfMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );

      lastDateOfMonth = this._holidayUtils.getLastBusinessDate(
        lastDateOfMonth,
        this.holidays
      );

      if (this.maxDate.getDate() < lastDateOfMonth.getDate()) {
        this.achDetailsFormGroup.controls['isEndOfMonth'].disable();
        this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      }
    } else if (this.maxDate.getMonth() != date.getMonth()) {
      this.achDetailsFormGroup.controls['isEndOfMonth'].enable();
    }
    return this._holidayUtils.getDateClass(this.holidays, date, view);
  };

  onCalendarClose() {
    this.setEOFForCalendar();
  }
  onCalendarCancel() {
    this.setEOFForCalendar();
  }

  setEOFForCalendar() {
    if (
      this.achDetailsFormGroup.controls['scheduledDate'].value.getMonth() !=
      this.maxDate.getMonth()
    ) {
      this.achDetailsFormGroup.controls['isEndOfMonth'].enable();

      let currentDate =
        this.achDetailsFormGroup.controls['scheduledDate'].value;
      let lastDateOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      lastDateOfMonth = this._holidayUtils.getLastBusinessDate(
        lastDateOfMonth,
        this.holidays
      );

      if (currentDate.getDate() == lastDateOfMonth.getDate()) {
        this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(true);
        this.achDetailsFormGroup.controls['isEndOfMonth'].enable();
      } else{
        this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
        this.achDetailsFormGroup.controls['isEndOfMonth'].disable();
      }
      if(this.achDetailsFormGroup.controls['frequency'].value == 'DAILY' ||
      this.achDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
      this.achDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
      this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      this.achDetailsFormGroup.controls['isEndOfMonth'].disable();
    } else{
      this.achDetailsFormGroup.controls['isEndOfMonth'].enable();
    }
    } 
    else {
      this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
      this.achDetailsFormGroup.controls['isEndOfMonth'].disable();
    }
  }

  onFocusInEvent(event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.type = 'text';
  }

  onFocusOutEvent(event) {
    const inputElement = event.target as HTMLInputElement;
    inputElement.type = 'password';
  }

  openConvenienceFeesDialog() {
    const dialogRef = this.dialog.open(SurchargeDialogComponent, {
      disableClose: true,
      data: {
        title: 'Convenience Fee',
        color: this.pageSettingModel.themeColor,
        mainMessage:
          'A convenience fee of $'+ this.pageSettingModel?.convenienceFeeAmount+' will be added to this transaction. Do you wish to continue?',
        subMessage:
          'Clicking Accept will add the convenience fee to the total amount to be charged.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        this.achDetailsFormGroup.reset();
        this.achDetailsFormGroup.markAsUntouched();
        this.achDetailsFormGroup.markAsPristine();

        this.achDetailsFormGroup.controls['isRecurring'].setValue(false);
        this.achDetailsFormGroup.controls['convenienceFees'].setValue(
          this.pageSettingModel?.convenienceFeeAmount
        );
        this.achDetailsFormGroup.controls['totalAmount'].setValue(this.amount);
        this.achDetailsFormGroup.controls['agreesToConvenienceFees'].setValue(
          false
        );
        this.paymentMethodEvent.emit('payWithCard');
      } else {
        this.setConvenienceFee();
      }
    });
  }

  setConvenienceFee() {
    this.achDetailsFormGroup.controls['agreesToConvenienceFees'].setValue(
      true
    );
    this.achDetailsFormGroup.controls['totalAmount'].setValue(
      parseFloat(this.amount?.toString()) + parseFloat(this.pageSettingModel?.convenienceFeeAmount?.toString())
    );
  }

  recurringChecked(event) {
    this.achDetailsFormGroup.controls['isRecurring'].setValue(event.checked);
  }

  endOfMonthCheck(event) {
    if (event.checked) {
      let currentDate = new Date();
      if((!this.achDetailsFormGroup.controls['frequency'].value) ||
      this.achDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
      this.achDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
        this.achDetailsFormGroup.controls['frequency'].setValue('MONTHLY');
        this.onFrequencyChanged(this.achDetailsFormGroup.controls['frequency'].value);
      } 
      currentDate = new Date(this.achDetailsFormGroup.controls['scheduledDate'].value);

      let lastDateOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      lastDateOfMonth = this._holidayUtils.getLastBusinessDate(
        lastDateOfMonth,
        this.holidays
      );

      this.achDetailsFormGroup.controls['scheduledDate'].setValue(
        lastDateOfMonth
      );
    } else {
      this.onFrequencyChanged(this.achDetailsFormGroup.controls['frequency'].value);
      this.achDetailsFormGroup.controls['scheduledDate'].markAsUntouched();
      this.achDetailsFormGroup.controls['scheduledDate'].markAsPristine();
    }
  }

  onFrequencyChanged(event: any){
    this.defaultScheduledDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    if (event == "WEEKLY"){      
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 7);      
    } else if (event == "BIWEEKLY"){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 14);
    } else if (event == "MONTHLY"){
      this.defaultScheduledDate.setMonth(this.defaultScheduledDate.getMonth() + 1);
    } else if (event == "YEARLY"){
      this.defaultScheduledDate.setFullYear(this.defaultScheduledDate.getFullYear() + 1);
      this.maxDate = new Date(this.defaultScheduledDate); 
      this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    }
    
    this.defaultScheduledDate =
          this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
            this.defaultScheduledDate,
            this.holidays
          );
    if(event == "MONTHLY" || event == "YEARLY"){
      if(this.achDetailsFormGroup.controls['isEndOfMonth'].value == true){
        let lastDateOfMonth = new Date(
          this.defaultScheduledDate.getFullYear(),
          this.defaultScheduledDate.getMonth() + 1,
          0
        );
        //this.defaultScheduledDate = lastDateOfMonth;
        this.achDetailsFormGroup.controls['scheduledDate'].setValue(
          lastDateOfMonth
        );
      } else{        
        this.achDetailsFormGroup.controls['scheduledDate'].setValue(this.defaultScheduledDate);
      }
    } else{
      this.achDetailsFormGroup.controls['scheduledDate'].setValue(this.defaultScheduledDate);
    }
    if(
        this.achDetailsFormGroup.controls['frequency'].value == 'WEEKLY' ||
        this.achDetailsFormGroup.controls['frequency'].value == 'BIWEEKLY'){
        this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
        this.achDetailsFormGroup.controls['isEndOfMonth'].disable();
      } else{
        this.achDetailsFormGroup.controls['isEndOfMonth'].enable();
      }      
  }

  onscheduledDateChanged(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;

    let lastDateOfMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    );

    lastDateOfMonth = this._holidayUtils.getLastBusinessDate(
      lastDateOfMonth,
      this.holidays
    );

    this.achDetailsFormGroup.controls['scheduledDate'].setValue(selectedDate);
    if (selectedDate.getDate() == lastDateOfMonth.getDate()) {
      this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(true);
    } else {
      this.achDetailsFormGroup.controls['isEndOfMonth'].setValue(false);
    }
  }

  // getLastBusinessDate(date) {
  //   do {
  //     if (this.is_weekend(date)) {
  //       date.setDate(date.getDate() - 1);
  //     }
  //   } while (this.is_weekend(date));

  //   return date;
  // }

  checkIfFrequencyDisabled(frequency: string) {
    return (
      this.achDetailsFormGroup.controls['isEndOfMonth'].value &&
      (frequency == 'BIWEEKLY' || frequency == 'WEEKLY')
    );
  }

  onEntryCodeSelected(event: any) {
    this.paymentWidgetCardService.entryCodeEmitter.emit(
      this.paymentWidgetCardService.getAchCustomerTypeSelection(
        true,
        event.value == AchEntryCodeKeyConstants.PPD
      )
    );
  }

  checkIfRecurring() {
    return this.achDetailsFormGroup.controls['isRecurring'].value;
  }
}
