<mat-card class="transactions-overview">
    <mat-spinner *ngIf="isLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50"
        [strokeWidth]="3">
        {{loadMessage}}
    </mat-spinner>

    <div *ngIf="!isLoading" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
        <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
            
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="36px" class="topContainer">
                <div fxLayout="column" fxFlex="20%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Items" class="summaryIcons"
                                src="assets/others-icon/totalItem.png" />
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <span class="amountCls">{{ inventoryStatsModel.totalServicesCount }}</span>
                            <span class="amountLblCls">Total Items</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="20%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Quantity" class="summaryIcons"
                                src="assets/others-icon/totalItem.png" />
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <span class="amountCls">{{ inventoryStatsModel.totalQuantity }}</span>
                            <span class="amountLblCls">Total Quantity</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="20%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Amount" class="summaryIcons"
                                src="assets/others-icon/totalAmount.png" />
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <span class="amountCls">{{"$" + (totalUnitPrice | number : "1.2-2")   }}</span>
                            <span class="amountLblCls">Total Amount</span>
                        </div>
                    </div>
                </div>

                <mat-divider [vertical]="true" class="divider-style"></mat-divider>

                <div fxLayout="column" fxFlex="20%" fxLayoutWrap>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Active" class="summaryIcons"
                                src="assets/others-icon/active.png" />
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <span class="amountCls">{{ inventoryStatsModel.activeStatus }}</span>
                            <span class="amountLblCls">Active</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="20%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Inactive" class="summaryIcons"
                                src="assets/others-icon/inactive.png" />
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center start">
                            <span class="amountCls">{{ inventoryStatsModel.inactiveStatus }}</span>
                            <span class="amountLblCls">Inactive</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</mat-card>