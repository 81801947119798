import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ScoreSettingRule } from 'src/app/sharedModules/services/customer/customer.proxies';
import { ScoreSettingRuleCardModel, ScoreSettingRuleCardModelArgs } from '../score-settings/models/score-settings-model';
import { validateScoreSettings } from '../validation/validateScoreSettings';

@Component({
  selector: 'app-ratio-max-point-card',
  templateUrl: './ratio-max-point.component.html',
  styleUrls: ['./ratio-max-point.component.scss']
})
export class RatioMaxPointComponent implements OnInit {
  
  private _model: ScoreSettingRuleCardModel;
  public get model(): ScoreSettingRuleCardModel {
    return this._model;
  }
  public set model(value: ScoreSettingRuleCardModel) {
    this._model = value;
  }
  public validateErrors : any;
  @Input() ruleIndex: number = 0;
  @Input()
  public set dataSource(value: ScoreSettingRule) {
    this.model = ScoreSettingRuleCardModel.fromDto(value);
  }
  
  @Output() isValidate = new EventEmitter<boolean>();
  @Output() onModelChanged = new EventEmitter<ScoreSettingRuleCardModelArgs>();

  constructor(private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.raiseModelChanged();
  }

  public chkActive(event: any) {
    this.raiseModelChanged();
  }

  private raiseModelChanged() {
    let args = new ScoreSettingRuleCardModelArgs(this._model, this.ruleIndex);
    this.onModelChanged.emit(args);
  }

  public OnDeductScoreChangeAsync(){
    this.validateErrors = validateScoreSettings(this._model);
    this.raiseModelChanged();

  }
}