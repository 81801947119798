<mat-spinner *ngIf="isLoading" class="loadSpinner" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
  {{ loadMessage }}
</mat-spinner>

<div class="amount-transactions-container">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
    <mat-label class="total-amount-text">Amount Transaction</mat-label>
  </div>
  <br />

  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="6%">
      <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
        <mat-label data-html2canvas-ignore="true" class="ach-amount-style">Transactions (in %)</mat-label>
      </div>
    </div>
    <div fxLayout="column" fxFlex="94%">
      <div style="display: block">
        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
          [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
        </canvas>
      </div>
    </div>
  </div>
</div>