import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { Observable } from 'rxjs';
import { CreateBatchModel } from 'src/app/sharedModules/models/create-batch.model';
import { CloneBatchModel } from '../../sharedModules/models/clone-batch.model';
import { UpdateBatchModel } from 'src/app/sharedModules/models/update-batch.model';
@Injectable({
  providedIn: 'root',
})
export class BatchTransactionsService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(
      _http,
      `${environment.API_BASE_URL_CUSTOMER}internal/batch-transaction`
    );
  }

  getAllActiveBatches(
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ): Observable<any> {
    let baseUrl = this._base + '/?';
    baseUrl = sortBy !== undefined ? baseUrl + 'sortBy=' + sortBy : baseUrl;
    baseUrl =
      baseUrl +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize;
    baseUrl = filterBy ? baseUrl + '&filterBy=' + filterBy : baseUrl;
    baseUrl = advanceFilters
      ? baseUrl + '&advanceFilters=' + advanceFilters
      : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  CreateBatch(model: CreateBatchModel): Observable<any> {
    return this._http.post<any>(this._base + '/', model);
  }

  CloneBatch(model: CloneBatchModel): Observable<any> {
    return this._http.post<any>(this._base + '/clone', model);
  }

  UpdateBatch(model: UpdateBatchModel, batchId): Observable<any> {
    return this._http.put<any>(this._base + '/' + batchId, model);
  }

  getAllCustomerByBatchId(id): Observable<any> {
    return this._http.get<any>(this._base + '/' + id);
  }

  activateOrDeactivateBatchById(id: string, type: "Activate" | "Deactivate"): Observable<any> {
    const url = `${this._base}/${id}/active`;
    return type === "Activate"
      ? this._http.post<any>(url, null)
      : this._http.delete<any>(url);
  }
}