import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ProcessBatchModel } from 'src/app/sharedModules/models/process-batch-model';

@Injectable({
  providedIn: 'root',
})
export class AchInternalService extends CrudService<any, any> {
  constructor(
    protected override _http: HttpClient
  ) {
    super(_http, `${environment.API_ACH_TRANSACTION_URL}/api/v2/InternalAch`);
  }

  ProcessBatchByFileTrackingId(model: ProcessBatchModel): Observable<any> {
    return this._http.post<any>(this._base + '/nacha-file/process', model);
  }
}
