<!-- <app-loading-spinner
  [isLoading]="isAchLoading || isCardsLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner> -->

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="onManageFilterClick()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Reports
    </mat-label>
  </div>
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Origination Report</div>
        <div class="page-sub-header-text-1">
          All Origination Report related data can be managed from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
          mat-stroked-button
          color="primary"
          class="white-button-style"
          (click)="onManageFilterClick()"
        >
          MANAGE FILTERS
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          mat-raised-button
          color="primary"
          class="page-header-button-style"
          (click)="downloadReportToExcel()"
        >
          DOWNLOAD
        </button>
      </div>
    </div>
  </div>
</div>

<mat-card class="report-stats-box">
  <mat-spinner *ngIf="isAchLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50"
        [strokeWidth]="3">
        {{loadMessage}}
  </mat-spinner>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start center"
    class="batchDateRow"
  >
    <div fxLayout="column" fxFlex="73%">
      <span class="paymentTypeLabel">ACH Batch Records</span>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="start end" fxFlex="27%">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
        class="batchDateContainer"
      >
        <div fxLayout="column">
          <img
            matTooltip="BatchDate"
            class="calenderIcon"
            src="assets/grid-icons/calender.png"
          />
        </div>
        <div fxLayout="column">
          <mat-label
            >Sent Date : {{ batchDate | date : "dd MMM,yyyy" }}</mat-label
          >
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start stretch"
    class="stats-box"
  >
    <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
      <div
        fxLayout="row"
        fxLayoutWrap
        fxLayoutAlign="start stretch"
        fxLayoutGap="2px"
        class="topContainer"
      >
        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="4px"
            style="margin-left: 25px"
          >
            <div fxLayout="column">
              <img
                matTooltip="Total Count"
                class="summaryIcons"
                src="assets/grid-icons/totalCount.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{ totalCount }}</span>
              <span class="amountLblCls">Total Count</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Amount"
                class="summaryIcons"
                src="assets/grid-icons/totalAmt.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{
                "$" + (totalAmount | number : "1.2-2")
              }}</span>
              <span class="amountLblCls">Total Amount</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider [vertical]="true" class="divider-style"></mat-divider>

    <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="2px"
        fxLayoutAlign="start stretch"
        class="topContainer"
      >
        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Credit Count"
                class="summaryIcons"
                src="assets/grid-icons/credit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{ totalAchCreditCount }} </span>
              <span class="amountLblCls">Total Credit Count</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Credit Amount"
                class="summaryIcons"
                src="assets/grid-icons/credit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{
                "$" + (totalAchCreditAmount | number : "1.2-2")
              }}</span>
              <span class="amountLblCls">Total Credit Amount</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider [vertical]="true" class="divider-style"></mat-divider>

    <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="2px"
        fxLayoutAlign="start stretch"
        class="topContainer"
      >
        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Debit Count"
                class="summaryIcons"
                src="assets/grid-icons/debit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{ totalAchDebitCount }}</span>
              <span class="amountLblCls">Total Debit Count</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Debit Amount"
                class="summaryIcons"
                src="assets/grid-icons/debit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{
                "$" + (totalAchDebitAmount | number : "1.2-2")
              }}</span>
              <span class="amountLblCls">Total Debit Amount</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-drawer-container>
    <mat-drawer-content>
      <app-data-table
        [columns]="achColumns"
        [allColumns]="achAllColumns"
        [data]="achData"
        [isLoading]="isAchLoading"
        [page]="achPage"
        [sortBy]="achSortBy"
        [showMoreMenu]="true"
        [isSearch]="false"
        (pageChangedEvent)="onAchPageChanged($event)"
        (sortChangedEvent)="onAchSortChanged($event)"
        [isSearch]="false"
        [isFilter]="false"
        [isExport]="false"
        [isScroll]="true"
      >
      </app-data-table>
    </mat-drawer-content>
  </mat-drawer-container>
</mat-card>

<mat-card class="report-stats-box">
  <mat-spinner *ngIf="isCardsLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50"
        [strokeWidth]="3">
        {{loadMessage}}
  </mat-spinner>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start center"
    class="batchDateRow"
  >
    <div fxLayout="column" fxFlex="70%">
      <span class="paymentTypeLabel">Credit Debit Card Batch Records</span>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="start end" fxFlex="30%">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="10px"
        class="batchDateContainer"
      >
        <div fxLayout="column">
          <img
            matTooltip="BatchDate"
            class="calenderIcon"
            src="assets/grid-icons/calender.png"
          />
        </div>
        <div fxLayout="column">
          <mat-label
            >Effective Date : {{ batchDate | date : "dd MMM,yyyy" }}</mat-label
          >
        </div>
      </div>
    </div>
  </div>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutAlign="start stretch"
    class="stats-box"
  >
    <div fxLayout="column" fxFlex="36%" fxLayoutWrap>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="2px"
        fxLayoutAlign="start stretch"
        class="topContainer"
      >
        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayoutGap="4px" style="margin-left: 25px">
            <div fxLayout="column">
              <img
                matTooltip="Total Debit Count"
                class="summaryIcons"
                src="assets/grid-icons/debit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{ totalCardsDebitCount }}</span>
              <span class="amountLblCls">Total Debit Count</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4px">
            <div fxLayout="column">
              <img
                matTooltip="Total Debit Amount"
                class="summaryIcons"
                src="assets/grid-icons/debit.png"
              />
            </div>
            <div fxLayout="column" class="amountDiv">
              <span class="amountCls">{{
                "$" + (totalCardsDebitAmount | number : "1.2-2")
              }}</span>
              <span class="amountLblCls">Total Debit Amount</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-drawer-container>
    <app-data-table
      [columns]="creditDebiColumns"
      [allColumns]="cardsAllColumns"
      [data]="cardsData"
      [isLoading]="isCardsLoading"
      [page]="cardsPage"
      [sortBy]="cardsSortBy"
      [isExport]="false"
      [isFilter]="false"
      [showMoreMenu]="true"
      [isSearch]="false"
      (pageChangedEvent)="onCardsPageChanged($event)"
      (sortChangedEvent)="onCardsSortChanged($event)"
      [isScroll]="true"
    >
    </app-data-table>
  </mat-drawer-container>
</mat-card>
