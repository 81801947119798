<div fxLayout="column" fxLayout.xs="row">
  <div fxLayout="row" fxLayout.xs="column" class="preview-box" fxFlex="100%">
    <div
      fxLayout="column"
      fxFlex="40%"
      fxLayoutAlign="center start"
      class="preview-text"
    >
      Preview
    </div>
    <div fxLayout="column" fxFlex="60%" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" class="note-text">
        <b>Note:</b> &nbsp; Once link created cannot be edited.
      </div>
    </div>
  </div>

  <div
    *ngIf="this.formData?.designTabForm?.linkType == 'Button'"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="center center"
    class="button-preview-design"
  >
    <button
      class="button-style"
      [style.--active-color-buttonBgColor]="
        this.formData.designTabForm.buttonBgColor ?? '#1e4ed8'
      "
      [style.--active-color-buttonTextColor]="
        this.formData.designTabForm.buttonTextColor ?? '#FFFFFF'
      "
      [style.--active-color-borderRadius]="
        this.formData.designTabForm.borderRadius ?? '4px'
      "
      [style.--active-color-borderColor]="
        this.formData.designTabForm.borderColor
      "
      [style.--active-color-borderShadow]="
        this.formData.designTabForm.shadow == 'ON'
          ? '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
          : 'None'
      "
      [style.--active-color-fontFamily]="this.formData.designTabForm.fontFamily"
      [style.--active-color-fontSize]="this.formData.designTabForm.fontSize"
      [style.--active-color-fontStyle]="this.formData.designTabForm.fontStyle"
    >
      <mat-spinner *ngIf="isLoading" color="accent" diameter="20">
      </mat-spinner>
      <span *ngIf="!isLoading">{{
        this.formData?.designTabForm?.linkText ?? "BUY NOW"
      }}</span>
    </button>
  </div>

  <div
    *ngIf="this.formData?.designTabForm?.linkType == 'Link'"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="center center"
    class="button-preview-design"
  >
    <a
      class="link-style"
      [style.--active-color-linkBgColor]="
        this.formData.designTabForm.linkBgColor ?? '#1e4ed8'
      "
      [style.--active-color-linkTextColor]="
        this.formData.designTabForm.linkTextColor ?? '#FFFFFF'
      "
      [style.--active-color-fontFamily]="this.formData.designTabForm.fontFamily"
      [style.--active-color-fontSize]="this.formData.designTabForm.fontSize"
      [style.--active-color-fontStyle]="this.formData.designTabForm.fontStyle"
    >
      <mat-spinner *ngIf="isLoading" color="accent" diameter="20">
      </mat-spinner>
      <span *ngIf="!isLoading">
        {{ this.formData?.designTabForm?.linkText ?? "BUY NOW" }}</span
      >
    </a>
  </div>
</div>
