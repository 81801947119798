

<div class="page-header-bottom">
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Billing</div>
        <div class="page-sub-header-text-1">
          All billing related information can be managed from this page          
        </div>
      </div>
    </div>   
  </div>
</div>

 <div class="billing-list-container">
   <app-billing-list></app-billing-list>
</div> 
  
