import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { EmailSetupDetailsModel } from 'src/app/featureModules/models/email-setup-details.model';
import { InvoiceSetupDetailsModel } from 'src/app/featureModules/models/invoice-setup-details.model';
import { SettingsModel } from 'src/app/featureModules/models/settings.model';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { SettingsService } from 'src/app/featureModules/services/settings.service';

@Component({
  selector: 'app-email-setup',
  templateUrl: './email-setup.component.html',
  styleUrls: ['./email-setup.component.scss'],
})
export class EmailSetupComponent implements OnInit {

  @Input()
  settingsModel: SettingsModel;

  emailSetupData: any;
  isLoading: boolean;

  headerimg_const = 'headerImg';
  emailSetupFormGroup: FormGroup;
  emailSetupDetailsModel: EmailSetupDetailsModel;
  companyId: string = 'CCP001';
  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService
  ) { }

  ngOnInit(): void {

    this.emailSetupDetailsModel = new EmailSetupDetailsModel();
    this.emailSetupFormGroup = this.formBuilder.formGroup(
      this.emailSetupDetailsModel
    );
    this.getInvoiceSetupSettings();
  }


  getInvoiceSetupSettings() { 
    this.isLoading = true;
    this._invoiceService.getInvoiceSetupDetails().subscribe(
      (data) => {
        if(data.code == 200){
          this.settingsModel = data.data;             
          this.loadEmailSetupData();                    
        }
        else{
          this.settingsModel = new SettingsModel();
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;                
      },
      (error) => {
        if (error.error.message != null || error.error.message != undefined) {
          this._toastrService.warning(error.error.message);
        } else {
          this._toastrService.warning(error.error.content);
        }
        this.isLoading = false;
      }
    );
  }

  loadEmailSetupData() {
    this.emailSetupDetailsModel.subject = this.settingsModel.subject;
    this.emailSetupDetailsModel.mailContent = this.settingsModel.mailContent;
    this.emailSetupDetailsModel.header = this.settingsModel.header;
    this.emailSetupDetailsModel.footer = this.settingsModel.footer;
    this.emailSetupFormGroup.patchValue(this.emailSetupDetailsModel);

  }

  saveEmailSetupSettings() {
    this.isLoading = true;
    this.emailSetupFormGroup.controls['companyId'].setValue(this.companyId);
    if (this.emailSetupFormGroup.valid) {
      this._invoiceService
        .updateEmailSetupDetails(
          this.emailSetupFormGroup.value)
        .subscribe(
          (data) => {
            if (data.code == 200) {
              this._toastrService.success(
                'Email Setup Details Updated successfully!'
              );
              this.isLoading = false;
            } else {
              this.isLoading = false;
              this._toastrService.warning(data.errorMessage);
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.warning(error.error.content);
            }
            this.isLoading = false;
          }
        );
    } else {
      this._toastrService.warning('Please fill all the required details');
      this.isLoading = false;
    }
  }

  resetEmailSetupForm() {
    this.emailSetupFormGroup.reset();
    this.emailSetupDetailsModel = new EmailSetupDetailsModel();
    Object.keys(this.emailSetupFormGroup.controls).forEach((key) => {
      const control = this.emailSetupFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  getImageSrc(iconName: string) {
    return 'assets/settings-icons/' + iconName + '.png';
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }
}
