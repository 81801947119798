import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-dti-quick-view',
  templateUrl: './dti-quick-view.component.html',
  styleUrls: ['./dti-quick-view.component.scss'],
})
export class DtiQuickViewComponent {
  isLoading: boolean = false;
  isChartDataAvailable: boolean = false;
  dtiPercentage = 0;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  public dtiChartLabels: string[] = ['Disposable Income', 'Monthly Debits'];

  public dtiChartData: ChartData<'pie'> = {
    labels: this.dtiChartLabels,
    datasets: [
      {
        label: '',
        data: [40, 60],
        backgroundColor: ['#FB8C00', '#1E4ED8'],
        hoverBackgroundColor: ['#FB8C00', '#1E4ED8'],
        borderJoinStyle: 'round',
        borderWidth: 0,
        hoverBorderColor: ['#FF0000', '#FF0000'],
        hoverOffset: 4,
      },
    ],
  };

  public dtiChartType: ChartType = 'pie';
  public dtiChartOptions: ChartOptions<'pie'> = {
    cutout: 0,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.label + ' : $' + tooltipItem.formattedValue;
          },
        },
      },
    },
    radius: 80,
  };

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _toastrService: ToastrService
  ) {}

  @Output() loadDtiQuickOverviewCardChange = new EventEmitter<boolean>();
  @Input('loadDtiQuickOverviewCard')
  set loadDtiQuickOverviewCard(loadDtiQuickOverviewCard: boolean) {
    if (loadDtiQuickOverviewCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .DtiQuickViewDetails(this.ibvBankVerificationId, this.accountType)
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data != null) {
              this.dtiPercentage = data.data.dtiPercentage;
              this.dtiChartData.datasets[0].data = [
                data.data.disposableIncome.toFixed(2) ?? 0,
                data.data.monthlyDebits.toFixed(2) ?? 0,
              ];
              this.isChartDataAvailable =
                data.data.disposableIncome == 0 && data.data.monthlyDebits == 0
                  ? false
                  : true;
            }
          },
          (error) => {
            this.isLoading = false;
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        );
    }

    this.loadDtiQuickOverviewCardChange.emit(false);
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }
}
