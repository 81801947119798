<form [formGroup]="sendInvoiceFormGroup" class="formclass">
  <h4>Registered Email:</h4>

  <mat-chip class="custom-chip-style" name="registredEmail">
    <mat-label>
      {{ sendInvoiceModel.registeredEmail }}
    </mat-label>
  </mat-chip>

  <h4 class="h4-style">Add New Email:</h4>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="3%"
    fxLayoutAlign="start center"
  >
    <div fxLayout="column" fxFlex="40%">
      <mat-form-field appearance="outline">
        <mat-label>Enter Email</mat-label>
        <input
          matInput
          name="email"
          class="form-control"
          formControlName="email"          
        />
        <mat-error>
          {{ sendInvoiceFormGroup.controls["email"]["_errorMessage"] }}
        </mat-error>
        <mat-hint class="hint">Maximum of 5 id's can be added</mat-hint>
      </mat-form-field>
    </div>
    <div fxLayout="column" fxFlex="5%">
      <button mat-raised-button color="primary" (click)="addNewEmails()">
        ADD
      </button>
    </div>
  </div>

  <br />
  <label *ngIf="sendInvoiceModel?.emailList?.length" class="additional-text"
    >Additional Email:</label
  >
  <br />
  <mat-chip-list>
    <mat-chip
      class="custom-chip-style"
      *ngFor="let email of sendInvoiceModel?.emailList; index as i"
    >
      <mat-label> {{ email }} </mat-label>
      <mat-icon class="cancelIcon" (click)="removeNewEmails(i)"
        >cancel</mat-icon
      >
    </mat-chip>
  </mat-chip-list>
</form>
