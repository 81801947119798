<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px">
  <div fxFlex="100%" #canvasContainer_Page1_0 id="canvasContainer_Page1_0">
    <app-calender-slider-card
      (calenderSlideEvent)="onInputChange($event)"
    ></app-calender-slider-card>
  </div>
</div>
<br />
<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="16px"
  #canvasContainer_Page1_1
  id="canvasContainer_Page1_1"
>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="16px"
    fxLayoutAlign="space-between start"
    fxFlex="100%"
    style="height: 210px"
  >
    <div fxFlex="24%" class="section-align">
      <app-potential-payment-amounts
        class="section-align"
        [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
        [accountType]="this.ibvPdfReport.accountType"
        [(loadPotentialPaymentAmountsCard)]="loadPotentialPaymentAmountsCard"
      >
      </app-potential-payment-amounts>
    </div>
    <div fxFlex="34%" class="section-align">
      <app-dti-quick-view
        class="section-align"
        [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
        [accountType]="this.ibvPdfReport.accountType"
        [(loadDtiQuickOverviewCard)]="loadDtiQuickOverviewCard"
      >
      </app-dti-quick-view>
    </div>
    <div fxFlex="42%" class="section-align">
      <app-flags
        class="section-align"
        [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
        [accountType]="this.ibvPdfReport.accountType"
        [startDate]="this.ibvPdfReport.startDate"
        [endDate]="this.ibvPdfReport.endDate"
        [(loadFlagsCard)]="loadFlagsCard"
      ></app-flags>
    </div>
  </div>
</div>
<br />
<div
  fxLayout="row"
  fxLayout.xs="column"
  #canvasContainer_Page1_2
  id="canvasContainer_Page1_2"
>
  <div fxLayout="column" fxFlex="100%">
    <app-cash-flow-analysis
      [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
      [accountType]="this.ibvPdfReport.accountType"
      [startDate]="this.ibvPdfReport.startDate"
      [endDate]="this.ibvPdfReport.endDate"
      [(loadCashFlowAnalysisGraph)]="loadCashFlowAnalysisGraph"
    ></app-cash-flow-analysis>
  </div>
</div>
<br />
<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="16px"
  #canvasContainer_Page1_3
  id="canvasContainer_Page1_3"
>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutGap="2%"
    fxLayoutAlign="space-between start"
    fxFlex="100%"
  >
    <div fxFlex="39%" class="section-align">
      <app-potential-loans class="section-align"></app-potential-loans>
    </div>
    <div fxFlex="59%" style="height: 100%">
      <app-recurring-credits
        style="height: 100%; display: grid; width: 100%"
        [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
        [accountType]="this.ibvPdfReport.accountType"
        [startDate]="this.ibvPdfReport.startDate"
        [endDate]="this.ibvPdfReport.endDate"
        [(loadRecurringCreditCard)]="loadRecurringCreditCard"
      ></app-recurring-credits>
    </div>
  </div>
</div>
<br />
<div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            style="margin-bottom: 1%"
          >
            <div fxLayout="column">
              <img
                class="icons"
                src="assets/ibv-report-icons/checking_savings/sectionIcons/transactions.png"
              />
            </div>
            <div fxLayout="column" fxLayoutWrap>
              <span class="section-header">Transactions</span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxLayout="column" fxFlex="100%">
          <recurring-debits-deposits
            [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
            [accountType]="this.ibvPdfReport.accountType"
            [startDate]="this.ibvPdfReport.startDate"
            [endDate]="this.ibvPdfReport.endDate"
            [(loadRecurringDebitsCard)]="loadRecurringDebitsCard"
          ></recurring-debits-deposits>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<br />
<div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
            style="margin-bottom: 1%"
          >
            <div fxLayout="column">
              <img
                class="icons"
                src="assets/ibv-report-icons/checking_savings/sectionIcons/transactionsByCategory.png"
              />
            </div>
            <div fxLayout="column" fxLayoutWrap>
              <span class="section-header">Transactions By Categories</span>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout="row" fxLayout.xs="column">
        <div fxLayout="column" fxFlex="100%">
          <app-report-transactions-card
            [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
            [accountType]="this.ibvPdfReport.accountType"
            [startDate]="this.ibvPdfReport.startDate"
            [endDate]="this.ibvPdfReport.endDate"
            [amountLabel]="'Withdrawal Amount'"
            [transactionType]="'Debit'"
            [(loadTransactionCard)]="loadTransactionCard"
          >
          </app-report-transactions-card>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<div class="hiddenContent">
  <app-finzeo-grade
    [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
    [accountType]="this.ibvPdfReport.accountType"
    [(loadFinzeoGradeCard)]="loadFinzeoGradeCard"
  >
  </app-finzeo-grade>
  <br />
  <app-bank-quick-view
    [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
    [accountType]="this.ibvPdfReport.accountType"
    [startDate]="this.ibvPdfReport.startDate"
    [endDate]="this.ibvPdfReport.endDate"
    [(loadBankQuickViewCard)]="loadBankQuickViewCard"
  ></app-bank-quick-view>
  <app-employer-verification
    [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
    [accountType]="this.ibvPdfReport.accountType"
    [startDate]="this.ibvPdfReport.startDate"
    [endDate]="this.ibvPdfReport.endDate"
    [(loadEmployerVerificationCard)]="loadEmployerVerificationCard"
  ></app-employer-verification>
</div>
