<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="5%"
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select
                class="form-control"
                formControlName="ticketStatus"
                name="status"
              >
                <mat-option
                  *ngFor="let status of statusList"
                  [value]="status.value"
                  >{{ status.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Priority</mat-label>
              <mat-select
                class="form-control"
                formControlName="priority"
                name="priority"
              >
                <mat-option
                  *ngFor="let priority of priorityList"
                  [value]="priority.value"
                  >{{ priority.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column" fxFlex="50%">
              <mat-form-field
                appearance="outline"
                class="mat-form-field-width2"
              >
                <mat-label>From - To</mat-label>
                <mat-date-range-input [rangePicker]="dateRangePicker">
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="periodStartDate"
                    name="periodStartDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="periodEndDate"
                    name="periodEndDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dateRangePicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                <mat-error>
                  {{
                    filterFormGroup.controls["periodStartDate"]["_errorMessage"]
                  }}
                </mat-error>
                <mat-error>
                  {{
                    filterFormGroup.controls["periodEndDate"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end" class="marginTop">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            [disabled]="!filterFormGroup.valid"
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [actionDelete]="actionDelete"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [selection]="selection"
      (actionDeleteEvent)="onDelete($event)"
      [deletedFilter]="deletedFilter"
      [actionRefresh]="actionRefresh"
      (actionRefreshEvent)="onRefresh($event)"
      (pageChangedEvent)="onPageChanged($event)"
      (withdrawClickedEvent)="onWithdrawClick($event)"
      (ticketClickedEvent)="onticketClick($event)"
      (writeReviewClickedEvent)="onwriteRewiewClick($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      [isExport]="false"
      [isTicket]="true"
      [filterText]="this.filter?.toString() ?? ''"
      [advanceFilterCount]="advanceFilterCount"
      [role]="this.userRole"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
