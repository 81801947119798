import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SendInvoiceDialogComponent } from 'src/app/sharedModules/components/dialogs/send-invoice-dialog/send-invoice-dialog.component';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';
import { EmailSetupDetailsModel } from '../models/email-setup-details.model';
import {
  InvoiceMainModel,
  InvoiceProductModel,
} from '../models/invoice-product.model';
import { InvoiceSetupDetailsModel } from '../models/invoice-setup-details.model';
import { ManageNotificationDetailsModel } from '../models/manage-notification-details.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends CrudService<any, any> {
  invoiceServiceMainModel: InvoiceMainModel;
  invoiceDataEmitter = new EventEmitter();
  customerIdEmitter = new EventEmitter();
  cId: string;
  cName: string;
  isProductAdded: boolean = false;

  dialogRef: MatDialogRef<SendInvoiceDialogComponent, any>;
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}CustomerInvoice`);
  }

  getInvoiceSetupDetails(): Observable<any> {
    return this._http.get<any>(this._base + '/GetInvoiceSetup/');
  }

  updateInvoiceSetupDetails(model: InvoiceSetupDetailsModel): Observable<any> {
    return this._http.post<any>(this._base + '/SaveInvoiceSetup/', model);
  }

  updateEmailSetupDetails(model: EmailSetupDetailsModel): Observable<any> {
    return this._http.post<any>(this._base + '/SaveEmailSetup/', model);
  }

  updateManageNotifcationSetupDetails(
    model: ManageNotificationDetailsModel
  ): Observable<any> {
    return this._http.post<any>(this._base + '/SaveManageNotification/', model);
  }

  saveInvoiceDetails(model: InvoiceMainModel): Observable<any> {
    return this._http.post<any>(this._base + '/SendInvoice/', model);
  }

  getInvoiceDetails(customerId: string): Observable<any> {
    return this._http.get<any>(
      this._base + '/GetCustomerInvoice?CustomerId=' + customerId
    );
  }

  searchSku(searchTerm: string, inventoryId: string): Observable<any> {
    return this._http.get<any>(
      this._base +
        '/SearchSKU/' +
        searchTerm +
        '?' +
        'inventoryId=' +
        inventoryId
    );
  }

  searchServices(searchTerm: string, isActive: boolean): Observable<any> {
    return this._http.get<any>(
      this._base + '/searchServices/' + searchTerm + '/' + isActive
    );
  }

  searchSkuNumber(searchTerm: string, isActive: boolean): Observable<any> {
    return this._http.get<any>(
      this._base + '/searchSkuNo/' + searchTerm + '/' + isActive
    );
  }

  getUpcomingHolidays(calendarYear: number) {
    return this._http.post<any>(this._base + '/getUpcomingHolidays', {
      calendarYear: calendarYear,
    });
  }
}
