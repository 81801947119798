<div class="page-header-bottom">
    <div class="backHeader">
      <mat-label>
          <button mat-icon-button (click)="navigateToPage()">
              <mat-icon>arrow_back </mat-icon>
          </button>
          Settings
      </mat-label>
  </div>
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <div class="page-header-text">Challenger Score Settings</div>
          <div class="page-sub-header-text-1">
            All Challenger Score Settings related data can be managed from this page
          </div>
        </div>
      </div>
    </div>
  </div>

<mat-tab-group>
    <mat-tab label="Score Settings">
        <app-score-settings></app-score-settings>
    </mat-tab>
    <mat-tab label="Grade Settings">
        <app-grade-setting></app-grade-setting>
    </mat-tab>
</mat-tab-group>