import { Component, Inject ,OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-eula-dialog',
  templateUrl: './eula-dialog.component.html',
  styleUrls: ['./eula-dialog.component.scss']
})
export class EulaDialogComponent implements OnInit {

  iAcceptChecked:boolean =false;
  isLoading:boolean = false;
  isEulaApproved:boolean = false;
  logotext: string;

  constructor(public dialogRef: MatDialogRef<EulaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private _toastrService: ToastrService) { }

  ngOnInit(): void {
    this.logotext = localStorage.getItem('logotext');
  }

  downloadEula() {
    this._toastrService.info('Please wait..Eula_Agreement PDF is getting generated.');
    this.isLoading = true;
    let fileName = "Eula_Agreement";
    var page1 = document.getElementById("eula_Page1");
    var page2 = document.getElementById("eula_Page2");
    this.logotext = localStorage.getItem('logotext');
    
    let content = [];    

    html2canvas(page1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 480;
      var imgHeight = (canvasPage1.height * 480) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL("image/png", 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });

      content.push({ text: "\n", id: "breakPage_1" });

      //To capture Tabs canvas separately on separate page
      html2canvas(page2, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((canvasPage2) => {
        var imgWidth = 480;
        var imgHeight = (canvasPage2.height * 480) / canvasPage2.width;
  
        content.push({
          image: canvasPage2.toDataURL("image/png", 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });
      generatedDashboardPDF(content, fileName, null);
      this.isLoading = false;
      this._toastrService.clear();
      this._toastrService.success('Eula Agreement PDF downloaded successfully.');
      });
    });
  }

  checkCheckboxValue(event:any){
    this.iAcceptChecked = event.checked;
  }

}
