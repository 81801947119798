import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import {
  IbvAccountTypeFull,
  IbvAccountTypeShort,
} from 'src/app/featureModules/enums/ibvAccountType';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
import { IbvPdfReport } from 'src/app/sharedModules/models/ibv-pdf-report.details.model';
import {
  generatedIbvPdf,
  getDetailsWithStatus,
} from 'src/app/sharedModules/utils/pdf-utils';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-instant-verify-report-dda',
  templateUrl: './instant-verify-report-dda.component.html',
  styleUrls: ['./instant-verify-report-dda.component.scss'],
})
export class InstantVerifyReportDDAComponent implements OnInit {
  ibvPdfReport: IbvPdfReport = new IbvPdfReport();
  loadBankQuickViewCard: boolean = false;
  loadFlagsCard: boolean = false;
  loadEmployerVerificationCard: boolean = false;
  loadDepositsCard: boolean = false;
  loadRecurringWithdrawalsCard: boolean = false;
  loadDtiQuickOverviewCard: boolean = false;
  loadPotentialPaymentAmountsCard: boolean = false;
  loadFinzeoGradeCard: boolean = false;
  loadTransactionCard: boolean = false;
  loadNsfVsPaidTransactionCard: boolean = false;
  loadRecurringDebitsCard: boolean = false;
  loadCashFlowAnalysisGraph: boolean = false;
  loadRecurringCreditCard: boolean = false;

  profileContentData = [];

  @Input()
  customerName: string;

  @Input()
  customerStatus: string;

  @Input()
  bankDetails: string;

  @Input()
  bankStatus: string;

  @Input()
  reportSeen: any;

  @Input()
  firstSeen: any;

  @Input()
  lastSuccessfulUpdate: any;

  constructor(
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {
    this.ibvPdfReport.accountType = IbvAccountTypeShort.DDA;
    this.ibvPdfReport.ibvBankVerificationId =
      this.route.snapshot.paramMap.get('bankVerificationId');
  }

  ngOnInit() {
    this.subscribeToProfileContent();
  }

  @Output() loadDDAAccountReportChange = new EventEmitter<boolean>();
  @Input('loadDDAAccountReport')
  set loadDDAAccountReport(loadDDAAccountReport: boolean) {
    if (loadDDAAccountReport) {
      let startDate = new Date();
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - (3 - 1));

      this.ibvPdfReport.startDate = startDate;
      this.ibvPdfReport.endDate = new Date();
      this.setLoadFlagsForReportDataPull();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onInputChange(event: any) {
    this.ibvPdfReport.startDate = event.startDate;
    this.ibvPdfReport.endDate = event.endDate;
    this.setLoadFlagsForReportDataPull();
  }

  subscribeToProfileContent() {
    this._instantVerifyReportsService.printIbaReport.subscribe((data) => {      
      if (data.length > 0) {
        this.profileContentData.push(...data);
      } else {
        this.profileContentData.push({
          canvas: [
            {
              type: 'line',
              x1: 0,
              y1: 2,
              x2: 515,
              y2: 2,
              lineWidth: 1,
              lineColor: '#148F77',
            },
          ],
        });
      }
    });
  }

  setLoadFlagsForReportDataPull() {
    this.loadBankQuickViewCard = true;
    this.loadFlagsCard = true;
    this.loadEmployerVerificationCard = true;
    this.loadDepositsCard = true;
    this.loadRecurringWithdrawalsCard = true;
    this.loadDtiQuickOverviewCard = true;
    this.loadFinzeoGradeCard = true;
    this.loadPotentialPaymentAmountsCard = true;
    this.loadTransactionCard = true;
    this.loadNsfVsPaidTransactionCard = true;
    this.loadRecurringDebitsCard = true;
    this.loadCashFlowAnalysisGraph = true;
    this.loadRecurringCreditCard = true;
  }

  @Output() printReportChange = new EventEmitter<boolean>();
  @Input('printReport')
  set printReport(printReport: boolean) {
    if (printReport) {
      this.subscribeToProfileContent();
      this._toastrService.info('Please wait..PDF report is getting generated.');
      let fileName = 'IBV_Transaction_Report_DDA';

      var page1_0 = document.getElementById('canvasContainer_Page1_0');
      var page1_1 = document.getElementById('canvasContainer_Page1_1');
      var page1_2 = document.getElementById('canvasContainer_Page1_2');
      var page1_3 = document.getElementById('canvasContainer_Page1_3');
      let content = [];

      content.push({
        text: 'IBA - ' + IbvAccountTypeFull.DDA + ' REPORT',
        style: 'infoHeader',
      });
      content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 2,
            x2: 515,
            y2: 2,
            lineWidth: 1,
            lineColor: '#148F77',
          },
        ],
      });
      content.push('\n');
      content.push(this.profileContentData);

      html2canvas(page1_0, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((page1_0) => {
        var imgWidth = 520;
        var imgHeight = (page1_0.height * imgWidth) / page1_0.width;

        content.push({
          image: page1_0.toDataURL('image/png', 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });
        content.push({ text: '\n' });

        //To capture Tabs canvas separately on separate page
        html2canvas(page1_1, {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          scale: 2,
          allowTaint: true,
        }).then((page1_1) => {
          var p11_imgWidth = 520;
          var p11_imgHeight = (page1_1.height * p11_imgWidth) / page1_1.width;

          content.push({
            image: page1_1.toDataURL('image/png', 0),
            margin: [0, 0, 0, 0],
            width: p11_imgWidth,
            height: p11_imgHeight,
          });
          content.push({ text: '\n' });

          html2canvas(page1_2, {
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            scale: 2,
            allowTaint: true,
          }).then((page1_2) => {
            var p12_imgWidth = 520;
            var p12_imgHeight = (page1_2.height * p12_imgWidth) / page1_2.width;

            content.push({
              image: page1_2.toDataURL('image/png', 0),
              margin: [0, 0, 0, 0],
              width: p12_imgWidth,
              height: p12_imgHeight,
            });
            content.push({ text: '\n' });

            html2canvas(page1_3, {
              scrollX: -window.scrollX,
              scrollY: -window.scrollY,
              scale: 2,
              allowTaint: true,
            }).then((page1_3) => {
              var p13_imgWidth = 520;
              var p13_imgHeight =
                (page1_3.height * p13_imgWidth) / page1_3.width;

              content.push({
                image: page1_3.toDataURL('image/png', 0),
                margin: [0, 0, 0, 0],
                width: p13_imgWidth,
                height: p13_imgHeight,
              });

              content.push({ text: '\n', id: 'breakPage_1' });
              this.generatePDFReport(content, fileName);
            });
          });
        });
      });
    }
    this.printReportChange.emit(false);
  }

  generatePDFReport(content: any, fileName: string) {
    this._instantVerifyReportsService
      .GetTransactionDetailsForPdf(
        this.ibvPdfReport.ibvBankVerificationId,
        this.ibvPdfReport.accountType,
        this.ibvPdfReport.startDate,
        this.ibvPdfReport.endDate
      )
      .subscribe(
        (data) => {
          if (data.data != null) {
            generatedIbvPdf(
              content,
              fileName,
              data.data,
              this.ibvPdfReport.accountType
            );
            this._toastrService.clear();
            this._toastrService.success('PDF downloaded successfully.');
          }
        },
        (error) => {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
