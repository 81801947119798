import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}FileUpload`);
  }

  uploadFile(file: File): Observable<any> {
    const payload = new FormData();
    payload.append('file', file);

    return this._http.post<any>(this._base + '/uploadAttachment', payload);
  }

  uploadFile1(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${this._base}/uploadAttachment`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this._http.request(req);
  }

  deleteFile(path: string): Observable<any> {
    return this._http.delete<any>(
      this._base + '/deleteUploadFile?filePath=' + path
    );
  }

  downloadFile(filePath: string,fileName:string): Observable<any> {
    return this._http.post(this._base + '/downloadUploadedFile' , {filePath,fileName} , { responseType:'blob' ,headers: { Accept: '*' }});    
  }
}
