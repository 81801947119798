import { Component, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private _toastrService: ToastrService) { }

  isLoading = false;
  loadMessage: string = '';
  period:string = "weekly";

  ngOnInit(): void {
  }

  onPrintClicked() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isLoading = true;
    let fileName = "Dashboard_Overall_Report";
    var page1 = document.getElementById("canvasContainer_Page1");
    var page2 = document.getElementById("canvasContainer_Page2");

    let content = [];

    content.push({ text: "Dashboard Overall Report", style: "infoHeader" });
    content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: "#148F77",
        },
      ],
    });
    content.push("\n");

    html2canvas(page1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL("image/png", 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });
      content.push({ text: "\n", id: "breakPage_1" });

      //To capture Tabs canvas separately on separate page
      html2canvas(page2, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((canvasPage2) => {
        var imgWidth = 520;
        var imgHeight = (canvasPage2.height * 480) / canvasPage2.width;
  
        content.push({
          image: canvasPage2.toDataURL("image/png", 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });
        // content.push({ text: "\n", id: "breakPage_2" });

        generatedDashboardPDF(content, fileName, null);
        this.isLoading = false;
        this._toastrService.clear();
        this._toastrService.success('PDF downloaded successfully.');
      });
    });
  }

}
