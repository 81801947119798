import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import FileSaver from 'file-saver';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CardsOriginationReportsService extends CrudService<any, any> {

  constructor(
    protected override _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    super(_http, `${environment.API_BASE_URL_CREDITDEBIT}InternalOrigination`);
  }

  GetDailyReport(reportDate: any,sortBy: string, sortDirection: string,
    page: number, pageSize: number) {     
    return this._http.get<any>(
      this._base +
      '/OriginationDailyReport?reportDate=' +
      reportDate + "&sortBy=" + sortBy + "&sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize
    );
  }

  GetWeeklyReport(reportDate: any) {
    return this._http.get<any>(
      this._base +
      '/OriginationWeeklyReport?ReportDate=' +
      reportDate
    );
  }   

  DownloadReportForOrigination(model: any) {
    this._toastrService.info('Please wait, we are downloading report for you.');
    this._http
      .post(this._base + '/DownloadAllOriginationReport', model, {
        responseType: 'blob',
        headers: { Accept: 'text/xlsx' },
      })
      .subscribe(
        (data) => {
          if (data != null) {
            FileSaver.saveAs(
              data,              
              "OriginationReport_" + moment(model.batchDate).format('MM-DD-YYYY')
            );
            this._toastrService.clear();
            this._toastrService.success('Origination report is downloaded');
          }
        },
        (error) => {
          this._toastrService.clear();
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
