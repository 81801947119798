import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { HostedPaymentInformationModel } from 'src/app/featureModules/models/hosted-payment-information.model';
import { environment } from 'src/environments/environment';
interface DrowpDown {
  value: string;
  viewValue: string;
}

export const AchEntryCodeKeyConstants = {
  "PPD": "PPD",
  "CCD": "CCD"
}

export const AchEntryCodeValueConstants = {
  "Personal": "Personal",
  "Business": "Business"
}

@Component({
  selector: 'app-hosted-payment-page-information',
  templateUrl: './hosted-payment-page-information.component.html',
  styleUrls: ['./hosted-payment-page-information.component.scss']
})
export class HostedPaymentPageInformationComponent implements OnInit {

  @Input()
  formData: any;

  @Input()
  finzeoPaymentOption: string;

  @Input()
  changesIsLoading: boolean;

  isLoading = false;
  amount: any = 1000;
  invoiceNumber: any;
  description: any;
  achConvenienceFees: any;
  isPayWithCard = true;
  isPayWithAch = false;
  isPayWithAchSelected = true;
  isPayWithCardSelected = true;
  companyLogo: string;
  headerBGColor: string;
  primaryButtonBGColor: string;
  labelColor: string;
  borderColor: string;
  surchargePercent = 10;
  surchargeFees = 10;
  convenienceFees = 10;
  availConvenienceFee = true;
  totalAmount = 254.56;
  agreesToConvenienceFees = true;
  agreesToSurcharge = true;
  minDate = new Date();
  maxDate = new Date();
  defaultScheduledDate = this.addDays(new Date(), 1);
  widgetInfo: any;
  token: string;
  isRecurring: boolean = false;
  logotext: string;

  //Phone related
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneNumber;
  phonehint: any = '(201) 555-0123';
  maxLength: string = '15';

  paymentMethod: string = 'Card';
  hostedPaymentInformationModel: HostedPaymentInformationModel;

  informationFormGroup: FormGroup;

  frequencies: DrowpDown[] = [
    { value: 'weekly', viewValue: 'Weekly' },
    { value: 'biweekly', viewValue: 'Bi-Weekly' },
    { value: 'monthly', viewValue: 'Monthly' },
    { value: 'yearly', viewValue: 'Yearly' },
  ];


  entryCodes = [
    {
      key: AchEntryCodeKeyConstants.PPD,
      value: AchEntryCodeValueConstants.Personal,
    },
    {
      key: AchEntryCodeKeyConstants.CCD,
      value: AchEntryCodeValueConstants.Business,
    },
  ];

  @Input()
  isSmallView: boolean;

  @Input()
  deviceType: string;

  logoBlobPath: string;
  disableForm: boolean = true;

  previewWiseStyle = {
    divPreviewHeight: "",
    headerLableFontSize: "",
    orderLableFontSize: "",
    orderValueFontSize: "",
    buttonTextFontSize: "",
    buttonMinWidth: "",
    logoImageHeight: "",
    secureLogoImageHeight: "",
    headerToolbarHeight: "",
    footerDivHeight: "",
    successImageSize: "",
    logoPaymentType: "",
    logoPaymentTypeCheck: "",
    inputControlClass: "",
    cardImageMarRight: ""
  }

  deviceWiseStyle = {
    headerToolbarClass: "",
    orderSuccessContainerClass: "",
    paymentSuccessLogoClass: "",
    headerToolbarLabelDivClass: "",
    companyLogoDivClass: "",
    orderContainerFooterClass: "",
    orderContainerFooterMarginClass: "",
    orderContainerMainClass: "",
    orderContainerMainInnerClass: "",
    orderContainerInnerClass: "",
    orderContainerInnerInnerClass: "",
    cardImageMarRightClass: ""
  }

  constructor(private formBuilder: RxFormBuilder) { }

  ngOnInit(): void {
    this.companyLogo = '/assets/logo.png';
    this.headerBGColor = '#1A237E';
    this.borderColor = this.formData.customisationTabForm.themeColor ? this.formData.customisationTabForm.themeColor : '#1A237E';
    this.primaryButtonBGColor = '#3D4EAE';
    this.labelColor = '#1A237E';

    this.invoiceNumber = "MER-898u96-yuyt";
    this.amount = 234.56;
    this.description = "this is related to payment";
    this.logotext = localStorage.getItem('logotext');
    
    this.convenienceFees = this.formData?.configurationTabForm?.paymentOptionTypes?.ACH?.convenienceFeeAmount;
    this.availConvenienceFee = this.formData?.configurationTabForm?.paymentOptionTypes?.ACH?.availConvenienceFee == "Yes" ?? false;

    this.hostedPaymentInformationModel = new HostedPaymentInformationModel();

    this.informationFormGroup = this.formBuilder.formGroup(
      this.hostedPaymentInformationModel
    );
    
    this.formData;
    // small - big view related changes
    this.previewWiseStyle.divPreviewHeight = this.isSmallView ? "160px !important" : "100%";
    this.previewWiseStyle.headerLableFontSize = this.isSmallView ? "4px" : "15px";
    this.previewWiseStyle.orderValueFontSize = this.isSmallView ? "3px" : "12px";
    this.previewWiseStyle.orderLableFontSize = this.isSmallView ? "3px" : "12px";
    this.previewWiseStyle.buttonTextFontSize = this.isSmallView ? "3.5px" : "12px";
    this.previewWiseStyle.buttonMinWidth = this.isSmallView ? "25px" : "64px";
    this.previewWiseStyle.logoImageHeight = this.isSmallView ? "15px" : "50px";    
    this.previewWiseStyle.logoPaymentType = this.isSmallView ? "7px" : "35px";
    this.previewWiseStyle.logoPaymentTypeCheck = this.isSmallView ? "7px" : "25px";
    if(this.deviceType=="mobile"){
      this.previewWiseStyle.logoPaymentType = this.isSmallView ? "7px" : "28px";
      this.previewWiseStyle.logoPaymentTypeCheck = this.isSmallView ? "7px" : "17px";
    }
    this.previewWiseStyle.secureLogoImageHeight = this.isSmallView ? "7.5px" : "32px";
    this.previewWiseStyle.headerToolbarHeight = this.isSmallView ? "75%" : "64px";
    this.previewWiseStyle.footerDivHeight = this.isSmallView ? "25px !important" : "82px !important";
    this.previewWiseStyle.successImageSize = this.isSmallView ? "10px" : "20px";
    this.previewWiseStyle.inputControlClass = "input-control-" + (this.isSmallView ? "small" : "big");

    //device type wise changes
    if (this.deviceType) {
      this.deviceWiseStyle.headerToolbarClass = "header-toolbar-" + this.deviceType;
      this.deviceWiseStyle.orderSuccessContainerClass = "order-success-container-" + this.deviceType;
      this.deviceWiseStyle.headerToolbarLabelDivClass = "header-toolbar-label-div-" + this.deviceType;
      this.deviceWiseStyle.paymentSuccessLogoClass = "payment-success-logo-" + this.deviceType;
      this.deviceWiseStyle.companyLogoDivClass = "company-logo-div-" + this.deviceType;
      this.deviceWiseStyle.orderContainerFooterClass = "order-container-footer-" + this.deviceType;
      this.deviceWiseStyle.orderContainerFooterMarginClass = "order-container-footer-margin-" + this.deviceType;
      this.deviceWiseStyle.orderContainerMainClass = "order-container-main-" + this.deviceType;
      this.deviceWiseStyle.orderContainerMainInnerClass = "order-container-main-inner-" + this.deviceType;
      this.deviceWiseStyle.orderContainerInnerClass = "order-container-inner-" + this.deviceType;
      this.deviceWiseStyle.orderContainerInnerInnerClass = "order-container-inner-inner-" + this.deviceType;
      this.deviceWiseStyle.cardImageMarRightClass = "card-image-mar-right-" + this.deviceType;
    }
    this.informationFormGroup.disable();
    this.informationFormGroup.controls['isRecurring'].enable();
  }

  enablePaymentType(type: string) {
    this.isPayWithCard = type == 'payWithCard';
    this.isPayWithAch = !this.isPayWithCard;
    this.isRecurring = false;
    this.informationFormGroup.controls['isRecurring'].setValue(false);
  }

  onEntryCodeSelected(event: any) {
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  recurringChecked(event) {
    this.isRecurring = event.checked;
    this.informationFormGroup.controls['isRecurring'].setValue(event.checked);
  }

  checkIfRecurring() {
    return this.isRecurring;
  }

  validateCard(data: any) {
    if (!data.configurationTabForm.paymentOptionCard && !data.configurationTabForm.paymentOptionAch){
      this.isPayWithCard = true;
       return true;
    }else{
      return data.configurationTabForm.paymentOptionCard;
      this.isPayWithCard=data.configurationTabForm.paymentOptionCard;
    }
  }
    


validateAch(data: any) {
  if (data.configurationTabForm.paymentOptionCard && data.configurationTabForm.paymentOptionAch) {
    this.isPayWithCard = true;
    this.isPayWithAch = false;
    return true;
  } else if (!data.configurationTabForm.paymentOptionCard && data.configurationTabForm.paymentOptionAch) {
    this.isPayWithAch = true;
    this.isPayWithCard = false;
    return true;
  }
  this.isPayWithAch = false;
  this.isPayWithCard = true;
  return false;
}

validateField(data: string) {

  if (data && data.toLocaleLowerCase() == "show") {
    return true;
  }
  return false;
}
getLogoPath(data: any) {     
    if (data != undefined) {
      var logo = data.customisationTabForm.logo;
      this.logoBlobPath = `${environment.ADMIN_CONTAINER_PATH}` + logo;
      return this.logoBlobPath;
    }
}

}
