
<app-loading-spinner [isLoading]="isLoading">
</app-loading-spinner>

<div fxLayout="row"   fxLayoutAlign="start stretch" >
    <div fxLayout="column" fxFlex="100%" >
        <div class="form-filter">
            <div class="info-filter">
    
                <form [formGroup]="formFilter" autocomplete="off" >
                    <div fxLayout="column">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" >
                            <div fxLayout="row" fxFlex="35%" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                                <div *ngIf="this.selectedCustomer" fxFlex="100%" class="sectionCutomer">
                                    <div>{{this.selectedCustomer.firstName}} {{this.selectedCustomer.lastName}}</div>
                                </div>
                                <div *ngIf="!this.selectedCustomer" fxFlex="100%" class="sectionCutomer">
                                    <mat-label class="inner-stepper-text">Select a customer</mat-label>
                                    <app-search-customer (onCustomerSelectionEvent)="onCustomerSelection($event)"></app-search-customer>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center">
                            <div fxLayout="row" fxFlex="35%" fxLayoutGap="10">
                                <mat-form-field appearance="outline">
                                    <mat-label>From</mat-label>
                                    <input matInput [matDatepicker]="fromdatepicker" formControlName="fromDate" readonly (dateChange)="changeFromDate()">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matSuffix [for]="fromdatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #fromdatepicker>
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                    </mat-datepicker-actions>
                                    </mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline">
                                    <mat-label>To</mat-label>
                                    <input matInput [matDatepicker]="todatepicker" formControlName="toDate" readonly (dateChange)="changeToDate()">
                                    <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                                    <mat-datepicker-toggle matSuffix [for]="todatepicker"></mat-datepicker-toggle>
                                    <mat-datepicker #todatepicker>
                                    <mat-datepicker-actions>
                                        <button mat-button matDatepickerCancel>Cancel</button>
                                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                                    </mat-datepicker-actions>
                                    </mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxFlex="65%" fxLayoutAlign="start center">
                                <div class="button" fxLayout="row" fxFlex="100%" fxLayoutAlign="start center" fxLayoutGap="10">
                                    <div fxFlex="60%" fxLayoutAlign="start center">
                                        <div fxFlex="10px"></div>
                                        <div>
                                            <button mat-button class="button-style-reset" (click)="reset()" >Reset</button>
                                        </div>
                                        <div fxFlex="10px"></div>
                                        <div>
                                            <button mat-button class="button-style" [disabled]="!canGenerateReport()" (click)="generateReport()">Generate Report</button>
                                        </div>
                                    </div>
                                    <div fxFlex="40%" fxLayoutAlign="end center">
                                        <button mat-button class="button-style" [disabled]="!isPrintReport" (click)="printReport()">Print</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>


<div #canvasContainer_Page1 id="canvasContainer_Page1">
    <div fxLayout="row" fxLayoutAlign="start stretch" class="stats-result" >
        <div fxLayout="column" fxFlex="100%" fxLayoutGap="20px" >
            <div class="form-filter">
                <customer-score-stats-card1 [filter]="filter"></customer-score-stats-card1>
            </div>
    
            <div class="form-filter">
                <customer-score-stats-card2 [filter]="filter"></customer-score-stats-card2>
            </div>
        </div>
    </div>
</div>
