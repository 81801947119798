import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit, OnChanges {
  @Input()
  isViewCustomerDetails: boolean;

  @Input()
  isEditCustomerDetails: boolean;

  @Input()
  customerModel: AddCustomerModel;

  @Input()
  userRole : any;
  
  @Input()
  isDataManagement: boolean = true;

  customerDetailsFormGroup: FormGroup;
  customerDetailsShowList = [];
  ipAddressList= [];
  isLoading: boolean = false;

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private customerService: CustomerService,
    private _toastrService: ToastrService
  ) {
    this.customerModel = new AddCustomerModel();    
    this.customerDetailsFormGroup = this.formBuilder.formGroup(
      this.customerModel
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customerModel != null) {
      this.customerDetailsFormGroup.patchValue(this.customerModel);       
    }
    this.customerDetailsFormGroup.controls['mobileNumber'].disable();
  }

  ngOnInit(): void {
    if (this.customerModel != null) {
      this.customerDetailsFormGroup.patchValue(this.customerModel);
      this.ipAddressList = this.getIpAddressList(this.customerModel.ipAddressList);
    }
    this.customerDetailsFormGroup.controls['mobileNumber'].disable();
  }
  
  getIpAddressList(data:any){
    if(data && typeof data == "string")
    { return data?.split(',') }
    else if (!data){
      return [];
    }else{
      return data;
    }
  }

  onActivateDeactivateClick() {
    let type = this.customerDetailsFormGroup.value.isActive
      ? 'Inactive'
      : 'Activate';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: this.customerDetailsFormGroup.value.isActive ? true : false,
        buttonName: type,
        successMessage: "Are you sure you want to '" + type + "' this?",
        subSuccessMessage:
          "Clicking on '" + type + "' button will update the status",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        let statusUpdate = {
          customerIds: [this.customerModel.customerId],
          status: !this.customerModel.isActive,
        };
        this.customerService.deactivateCustomer(statusUpdate).subscribe(
          (data) => {
            this.isLoading = false;
            if (data.code == 200) {
              this._toastrService.success(
                'Customer status updated successfully!'
              );
              this.router.navigate(['customers']);
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
      } else {
        this.customerDetailsFormGroup.controls['isActive'].setValue(
          this.customerModel.isActive
        );
      }
    });
  }

  isCustomerDetailsPresent(propertyName: any) {
    if (
      this.customerDetailsFormGroup.controls[propertyName]?.value == null ||
      this.customerDetailsFormGroup.controls[propertyName]?.value ==
        undefined ||
      this.customerDetailsFormGroup.controls[propertyName].value == ''
    ) {
      return false;
    } else {
      return true;
    }
  }
}
