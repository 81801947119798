<div class="data-table" [style.--active-data-table-margin]="datatablemargin" [class.auto-layout]="isScroll" [class.fixed-layout]="!isScroll">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div
      fxLayout="row"
      class="search-box-style"
      [style.--active-padding]="searchboxpadding"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
    >
      <div *ngIf="isSearch || isTableHeading" fxLayout="column" fxFlex={{fxFlexCustom}} >
        <mat-form-field
          *ngIf="isSearch"
          appearance="outline"
          class="form-field-search"
          style="padding-top: 2%"
        >
          <span matPrefix>
            <mat-icon>search</mat-icon>
          </span>
          <input
            matInput
            placeholder="Search"
            autocomplete="off"
            [(ngModel)]="filterText"
            (keyup.enter)="onEnter()"
            (blur)="onBlurSearch()"
            pattern="{{ searchTextPattern }}"
          />
          <button
            *ngIf="filterText"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            style="padding-bottom: 1.34375em"
            (click)="filterText = ''"
          >
            <mat-icon (click)="onClearText()">close</mat-icon>
          </button>
        </mat-form-field>
        <div fxLayout="row" *ngIf="isTableHeading" class="table-heading">
          {{ isTableHeadingText }}
        </div>
      </div>
      <div *ngIf="isSearchDate" fxLayout="column" fxFlex="40%">
        <div fxLayout="row">
          <div style="width: 50%">
            <mat-form-field appearance="outline">
              <mat-label>Search Date</mat-label>
              <input
                matInput
                [matDatepicker]="datepicker"
                [(ngModel)]="fromDate"
                readonly
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="datepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #datepicker>
                <mat-datepicker-actions>
                  <button mat-button matDatepickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDatepickerApply>
                    Apply
                  </button>
                </mat-datepicker-actions>
              </mat-datepicker>

              <button
                *ngIf="fromDate"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                style="padding-bottom: 1.34375em"
                (click)="fromDate = null; toDate = null"
              >
                <mat-icon (click)="onClearDate()">close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            style="margin: auto; margin-left: 5%"
            (click)="onEnterSearchDate()"
          >
            SEARCH
          </button>
        </div>
      </div>
      <div *ngIf="isSearchMonth" fxLayout="column" fxFlex="55%">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5%">
          <div style="width: 40%">
            <mat-form-field appearance="outline">
              <mat-label>Select Month/Year</mat-label>
              <input
                matInput
                [matDatepicker]="monthPicker"
                [formControl]="dateControl"
                [matDatepickerFilter]="filterMonths"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="monthPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker
                #monthPicker
                startView="multi-year"
                (monthSelected)="onMonthSelected($event, monthPicker)"
                panelClass="month-picker"
              >
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div style="width: 45%">
            <mat-chip-list>
              <mat-chip class="page-header-customerEmailChip">
                {{ statementSelectedPeriod }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </div>
      <div
        class="availableQuantityDiv"
        *ngIf="isInventory"
        fxLayout="column"
        fxFlex="13%"
      >
        <span *ngIf="isInventory" class="availableQuantityHeading">
          AVAILABLE QUANTITY
        </span>
      </div>
      <!-- <div *ngIf="isViewInvoicePage" fxLayout="column" fxFlex="10%">
        <mat-slide-toggle
          name="unPaid"
          class="toggle-unpaid-style"
          [(ngModel)]="invoiceUnpaid"
          (change)="onInvoiceToggleChange($event, 'unpaid')"
        >
          <mat-label class="showDetailsValue"> UNPAID </mat-label>
        </mat-slide-toggle>
      </div>
      <div *ngIf="isViewInvoicePage" fxLayout="column" fxFlex="13%">
        <mat-slide-toggle
          name="overdue"
          class="toggle-overdue-style"
          [(ngModel)]="invoiceOverdue"
          (change)="onInvoiceToggleChange($event, 'overdue')"
        >
          <mat-label class="showDetailsValue"> OVERDUE </mat-label>
        </mat-slide-toggle>
      </div> -->
      <div *ngIf="isViewInvoicePage" fxLayout="column" fxFlex="15%">
        <mat-slide-toggle
          name="recurring"
          class="toggle-recurring-style"
          [(ngModel)]="invoiceRecurring"
          (change)="onInvoiceToggleChange($event, 'recurring')"
        >
          <mat-label class="showDetailsValue"> RECURRING </mat-label>
        </mat-slide-toggle>
      </div>

      <span *ngIf="!isSpacerRequired" class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end stretch">
          <span class="totalTransactionLbl" *ngIf="isTotalTransactionLbl">
            TY : This Year &nbsp;&nbsp; LY : Last Year
          </span>
          <a
            mat-button
            *ngIf="selection.hasValue() && isActive"
            class="button-text"
            (click)="deleteBasedOnSelectionClick()"
          >
            <mat-icon *ngIf="CheckingSelectedItem()" style="color: red"
              >delete</mat-icon
            >&nbsp;
            <span *ngIf="CheckingSelectedItem()" style="color: red"
              >Deactivate</span
            >
            <mat-icon *ngIf="!CheckingSelectedItem()" style="color: green"
              >done_all</mat-icon
            >&nbsp;
            <span *ngIf="!CheckingSelectedItem()" style="color: green"
              >Activate</span
            >
          </a>
          <a
            mat-button
            *ngIf="selection.hasValue() && isDelete"
            class="button-text"
            style="color: red"
            (click)="deleteBasedOnSelectionClick()"
          >
            <mat-icon>delete</mat-icon>&nbsp;
            <span>Delete</span>
          </a>
          &nbsp;&nbsp;
          <a
            mat-button
            class="button-text exportText"
            [disabled]="true"
            *ngIf="isExport"
            (click)="onExportClick()"
          >
            <mat-icon>arrow_upward</mat-icon>&nbsp;EXPORT
          </a>
          &nbsp;&nbsp;
          <a
            mat-button
            class="button-text filter-color"
            (click)="onAdvanceFilterClick()"
            *ngIf="isFilter"
          >
            <mat-icon>filter_list</mat-icon>&nbsp;
            <span
              matBadge="{{ advanceFilterCount }}"
              style="position: relative"
            >
              FILTER
            </span>
          </a>
          <button
            mat-raised-button
            color="primary"
            *ngIf="isAddBtn && role != 1"
            class="page-header-button-style"
            (click)="navigateToPage()"
          >
            ADD
          </button>
          <button
            *ngIf="isDownloadButtonVisible"
            mat-raised-button
            color="primary"
            class="page-header-button-style"
           (click)="onDownloadClicked()">
            DOWNLOAD PDF
          </button>
          <button
            mat-stroked-button
            color="primary"
            *ngIf="isImportBtn"
            class="white-button-style"
            (click)="onImportClick()"
          >
            IMPORT
          </button>

          <a
            *ngIf="isRefreshBanKTransaction"
            class="button-text filter-color"
            mat-button
            (click)="refreshBankTransaction('RefreshBanKTransaction')"
          >
            <mat-icon class="refreshIcon-color">sync_alt</mat-icon>&nbsp;REFRESH
            BANK TRANSACTIONS
          </a>
          &nbsp;&nbsp;
          <a
            *ngIf="isRefreshbankBalance"
            class="button-text filter-color"
            mat-button
            (click)="refreshBankTransaction('RefreshBanKBalance')"
          >
            <mat-icon class="refreshIcon-color">attach_money</mat-icon
            >&nbsp;REFRESH BANK BALANCE
          </a>
          &nbsp;&nbsp;
          <a
            *ngIf="isRefreshall"
            class="button-text filter-color"
            mat-button
            (click)="refreshBankTransaction('RefreshAll')"
          >
            <mat-icon class="refreshIcon-color">refresh</mat-icon>&nbsp;REFRESH
            ALL
          </a>
        </div>
      </div>
      <span *ngIf="isSpacerRequired" class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end stretch">
          <button
            mat-raised-button
            color="primary"
            *ngIf="isCreateNewBtn && role != 1"
            class="page-header-button-style"
            (click)="navigateToPage()"
          >
            CREATE NEW
          </button>
        </div>
      </div>
    </div>

    <table mat-table  [class.auto-layout]="isScroll" [class.fixed-layout]="!isScroll" fxFlex="100%" [dataSource]="dataSource" matSort>
      <ng-container
        matColumnDef="{{ column.name }}"
        *ngFor="let column of columnsPrepared"
      >
        <!-- Checkbox Column -->
        <ng-container *ngIf="column.name == 'select'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <!-- <mat-checkbox
              [disabled]="this.dataSource.data.length == 0"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox> -->
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let row"
          >
            <mat-checkbox
              *ngIf="role != 1"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Checkbox Column -->
        <ng-container *ngIf="column.name == 'select'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <!-- <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox> -->
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let row"
          >
            <mat-checkbox
              *ngIf="role != 1"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'icon'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <mat-label class="userNameCircle">
              {{ getNameInitials(data["firstName"], data["lastName"]) }}
            </mat-label>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'achTransactionType' && !isViewBatch && transactionType?.toLowerCase() === 'credit & debit' ">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div class="achTransactionType">         
              <mat-form-field appearance="outline" class="form-field-search">
              <mat-select
                class="form-control"
                [(value)]="data[column.name]"
                name="transactionBatchType"
                [disabled]="!data.isInclude"
              >
                 <mat-option
                  *ngFor="let type of transactionTypes"
                  [value]="type.value"
                >
                  {{ type.displayName }}
                </mat-option> 
              </mat-select>
              <!-- formControlName="groupName" -->
              <!-- <mat-error>
                {{ applyToAllForm.controls["transactionTypeApplyAll"]["_errorMessage"] }}
              </mat-error> -->
             </mat-form-field>           
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.name === 'lastYearReturnRate' ||
            column.name === 'currentYearReturnRate'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenu"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] | number : "1.2-2" }}%
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'amount' ||
            column.name === 'unitPrice' ||
            column.name === 'units' ||
            column.name === 'TotalAmount' ||
            column.name === 'pendingAmount' ||
            column.name === 'paidAmount' ||
            column.name === 'unpaidAmount' ||
            column.name === 'pastDueAmount' ||
            column.name === 'returnedAmount' ||
            column.name === 'successAmount' ||
            column.name === 'recurringAmount' ||
            column.name === 'failedAmount' ||
            column.name === 'currentYearAmount' ||
            column.name === 'lastYearAmount' ||
            column.name === 'surchargeAmount' ||
            column.name === 'totalAmount' ||
            column.name === 'Total'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <!-- <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div> -->
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                style="color: green"
                *ngIf="
                  data['transactionType']?.toLowerCase()?.includes('credit')
                "
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>

              <mat-label
                style="color: red"
                *ngIf="
                  data['transactionType']?.toLowerCase()?.includes('debit')
                "
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>

              <mat-label *ngIf="!data['transactionType']?.toLowerCase()">
                {{
                  column.name == "units"
                    ? (data[column.name] | number : "1.2-2")
                    : "$" + (data[column.name] | number : "1.2-2")
                }}
              </mat-label>
              <mat-label
                *ngIf="
                  data['transactionType']?.toLowerCase() &&
                  data['paymentType']?.toLowerCase()
                "
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'EndingBalance'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{
                  data[column.name] < 0
                    ? "($" + (-data[column.name] | number : "1.2-2") + ")"
                    : "$" + (data[column.name] | number : "1.2-2")
                }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'Balance' ||
            column.name === 'Credits' ||
            column.name === 'Debits' ||
            column.name === 'description' ||
            column.name === 'Activity' ||
            (column.name == 'itemName' && isInventory) ||
            (column.name == 'enteredBy' && isInventory)
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div
              *ngIf="
                column.name === 'Activity' ||
                column.name === 'description' ||
                ((column.name == 'itemName' || column.name == 'enteredBy') &&
                  isInventory)
              "
            >
              {{ column.displayName }}
            </div>
            <div
              *ngIf="showMoreMenu && column.name == 'itemName' && isInventory"
              (click)="$event.stopPropagation()"
            >
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div
              *ngIf="showMoreMenu && column.name != 'itemName'"
              (click)="$event.stopPropagation()"
            >
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
            <div
              fxLayout="column"
              class="lineUp"
              *ngIf="
                column.name == 'Credits' ||
                column.name === 'Debits' ||
                column.name === 'Balance'
              "
              style="text-align: right"
            >
              {{ column.displayName }}
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div
              class="name-fr-text"
              *ngIf="
                column.name == 'description' ||
                ((column.name == 'itemName' || column.name == 'enteredBy') &&
                  isInventory)
              "
            >
              <span matTooltip=" {{ data[column.name] }}" class="ellip">
                {{ data[column.name] }}
              </span>
            </div>
            <div fxLayout="column" *ngIf="column.name == 'Activity'">
              <mat-label>
                {{ data[column.name] | date : "MMM d, y (HH:mm:ss)" }}
              </mat-label>
            </div>
            <div
              fxLayout="column"
              class="lineUp"
              *ngIf="
                column.name == 'Credits' ||
                column.name === 'Debits' ||
                column.name === 'Balance'
              "
            >
              <mat-label *ngIf="data[column.name] < 0">
                {{ "($" + (-data[column.name] | number : "1.2-2") + ")" }}
              </mat-label>
              <mat-label *ngIf="data[column.name] > 0">
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>
              <mat-label *ngIf="data[column.name] == 0"> - </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'Amount' ||
            column.name === 'invoiceBillAmountPaid' ||
            column.name === 'invoiceBillAmount' ||
            column.name === 'surchargeAmount'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                style="color: green"
                *ngIf="
                  data['PaymentType']?.toLowerCase()?.includes('debit') ||
                  data['cardPaymentType']?.toLowerCase()?.includes('debit') ||
                  column.name == 'invoiceBillAmountPaid'
                "
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>

              <mat-label
                style="color: red"
                *ngIf="data['PaymentType']?.toLowerCase()?.includes('credit')"
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>
              <mat-label *ngIf="column.name == 'invoiceBillAmount'">
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.name === 'name' || column.name === 'FullName'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div class="name-fr-text">
              <span
                matTooltip="{{ data['firstName'] }} {{ data['lastName'] }}"
                class="ellip"
              >
                {{ data["firstName"] }} {{ data["lastName"] }}
              </span>
            </div>
            <div class="sub-text">
              <span matTooltip="{{ data['email'] }}" class="ellip">
                {{ data["email"] }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container
        *ngIf="column.name === 'nameWithoutEmail'"
      >
        <th
          [class]="column.className"
          width="{{ column.width }}"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
        >
          {{ column.displayName }}
          <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
            <button
              mat-icon-button
              [matMenuTriggerFor]="TableHeaderMenuName"
              [matMenuTriggerData]="{ displayName: column.displayName }"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <div *ngIf="!showMoreMenu">
            <button mat-icon-button></button>
          </div>
        </th>
        <td
          [class]="column.className"
          width="{{ column.width }}"
          mat-cell
          *matCellDef="let data"
        >
          <div class="name-fr-text">
            <span
              matTooltip="{{ data['firstName'] }} {{ data['lastName'] }}"
              class="ellip"
            >
              {{ data["firstName"] }} {{ data["lastName"] }}
            </span>
          </div>
        </td>
      </ng-container>

        <ng-container *ngIf="column.name === 'nameWithoutHeader'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            style="display: none"
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div class="name-fr-text">
              <span
                matTooltip="{{ data['firstName'] }} {{ data['lastName'] }}"
                class="ellip"
              >
                {{ data["firstName"] }} {{ data["lastName"] }}
              </span>
            </div>
            <div class="sub-text">
              <span matTooltip="{{ data['email'] }}" class="ellip">
                {{ data["email"] }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'CustomerName'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <span matTooltip="{{ data['email'] }}">
                <div>
                  {{
                    data["CustomerName"]
                      ?.trim()
                      .split(" ")
                      .slice(0, -1)
                      .join("")
                  }}
                  {{
                    data["CustomerName"]?.trim().split(" ").slice(-1).join("")
                  }}
                </div>
                <div class="sub-text">{{ data["email"] }}</div>
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.name === 'sku' || column.name === 'enteredBy'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            (column.name == 'categoryName' && isInventory) ||
            column.name == 'linkPurpose'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div
              class="name-fr-text"
              *ngIf="
                (column.name == 'categoryName' && isInventory) ||
                column.name == 'linkPurpose'
              "
            >
              <span matTooltip=" {{ data[column.name] }}" class="ellip">
                {{ data[column.name] }}
              </span>
            </div>
          </td>
        </ng-container>

        <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            [matMenuTriggerFor]="TableHeaderMenuName"
            [matMenuTriggerData]="{ displayName: column.displayName }"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>

        <ng-container *ngIf="column.name === 'totalBatch'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label matTooltip="{{ data[column.name] }} ">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'categoryName' && isCategory">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label matTooltip="{{ data[column.name] }} ">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'checkNumber' ||
            column.name === 'TrackingId' ||
            column.name === 'trackingId' ||
            column.name === 'subCategory' ||
            column.name === 'itemName'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                *ngIf="column.name === 'itemName'"
                matTooltip="{{ data[column.name] }} "
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label class="name-fr-text" *ngIf="column.name === 'TrackingId' || column.name === 'trackingId'">
                <span matTooltip=" {{ data[column.name] }}" class="ellip">
                  {{ data[column.name] }}
                </span>
                </mat-label>
              <mat-label *ngIf="column.name !== 'itemName' && column.name !== 'TrackingId' && column.name !== 'trackingId'">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'quantity'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                <div fxLayout="row">
                  <div
                    class="totalDiv"
                    fxLayout="column"
                    fxLayoutAlign="center end"
                  >
                    <span class="totalQuantity">
                      {{ data[column.name].split(";")[0] }}
                    </span>
                  </div>
                  <span class="margins">/</span>
                  <div fxLayout="column">
                    <span class="availableQuantity">
                      {{ data[column.name].split(";")[1] }}
                    </span>
                  </div>
                </div>
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'discountType'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                <div fxLayout="row">
                  <div fxLayout="column" class="mL">
                    <span *ngIf="data[column.name] == 'Percentage'"> % </span>
                    <span *ngIf="data[column.name] == 'Flat'"> $ </span>
                  </div>
                </div>
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'ticketNumber'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label class="ticketId-link" (click)="ticketClick(data)">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'invoiceNo'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                class="ticketId-link"
                (click)="invoiceNumberClick(data)"
              >
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.name === 'invoiceNumber'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <!-- add columns which dont need sorting -->

        <ng-container
          *ngIf="
            column.name === 'BankAccountNumber' ||
            column.name === 'cardNumber' ||
            column.name === 'totalSurcharge' ||
            column.name === 'cardPaymentType' ||
            column.name === 'statementEffectiveDate' ||
            column.name === 'statementTotalDebits' ||
            column.name === 'statementTotalCredits' ||
            column.name === 'statementDailyNet' ||
            column.name === 'statementEndingBalance' ||
            column.name === 'statementPendingDebits' ||
            column.name === 'statementAvaiableBalance' ||
            column.name === 'statementPageId' ||
            column.name === 'statementTotalReturns' ||
            column.name === 'bankAccountNumber' ||
            column.name === 'routingNumber' ||
            column.name === 'accountNumber'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenu"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                matTooltip="{{ data.AccountType }}"
                *ngIf="
                  column.name !== 'statementTotalDebits' &&
                  column.name !== 'statementTotalCredits' &&
                  column.name !== 'statementDailyNet' &&
                  column.name !== 'statementEndingBalance' &&
                  column.name !== 'statementPendingDebits' &&
                  column.name !== 'statementAvaiableBalance' &&
                  column.name !== 'statementTotalReturns' &&
                  column.name !== 'bankAccountNumber' &&
                  column.name !== 'routingNumber' &&
                  column.name !== 'accountNumber' &&
                  column.displayName !== 'CARD NUMBER'
                "
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="
                  column.name === 'statementTotalDebits' ||
                  column.name === 'statementTotalCredits' ||
                  column.name === 'statementDailyNet' ||
                  column.name === 'statementEndingBalance' ||
                  column.name === 'statementPendingDebits' ||
                  column.name === 'statementAvaiableBalance' ||
                  column.name === 'statementTotalReturns'
                "
              >
                {{ "$" + (data[column.name] | number : "1.2-2") }}
              </mat-label>
              <mat-label
                *ngIf="
                  column.name === 'bankAccountNumber' ||
                  column.name === 'routingNumber' ||
                  column.name === 'accountNumber'
                "
              >
                {{ data[column.name] | maskPipe : 3 }}
              </mat-label>
              <mat-label
                *ngIf="
                  column.displayName === 'CARD NUMBER' &&
                  column.name === 'cardNumber'
                "
              >
                {{ cardNumberEncreption(data[column.name]) }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'productCount'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenu"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label class="pCount-link" (click)="productCountClick(data)">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'linkText'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                class="ticketId-link name-fr-text"
                (click)="paymentLinkViewClick(data.id)"
              >
                <span matTooltip=" {{ data[column.name] }}" class="ellip">
                  {{ data[column.name] }}
                </span>
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'discount'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'salesTax'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            <div class="mat-sort-header-container centerAlign">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label class="mL-sales">
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'month'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'Role'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{
                  data[column.name] == 0
                    ? "Admin"
                    : data[column.name] == 1
                    ? "Viewer"
                    : "Viewer"
                }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.name === 'dateExpiring' || column.name === 'linkType'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.name === 'frequency' ||
            column.name === 'period' ||
            column.name === 'noOfProducts'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              {{ data[column.name] }}
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="column.name === 'billingDueAmount'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            <div class="mat-sort-header-container">
              {{ column.displayName }}
              <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="TableHeaderMenuName"
                  [matMenuTriggerData]="{ displayName: column.displayName }"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
              <div *ngIf="!showMoreMenu">
                <button mat-icon-button></button>
              </div>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column" style="color: red">
              {{ "$" + (data[column.name] | number : "1.2-2") }}
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.name === 'Installments' ||
            column.name === 'PendingInstallments' ||
            column.name === 'TransactionCount'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column" style="margin-left: 25%">
              <mat-label>
                {{ data[column.name] }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'type'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuType"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            {{ data[column.name] == true ? "Company" : "Individual" }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'entryDate' || column.name === 'invoiceDueDate'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="
                  column.displayName == 'REFRESH DATE'
                    ? TableHeaderMenuName
                    : TableHeaderMenu
                "
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div *ngIf="isInstantBankAnalysis && data[column.name]">
              <div>{{ data[column.name] | date : "MMMM d, y" }}</div>
              <div class="sub-text">
                {{ data[column.name] | date : "hh:mm a" }}
              </div>
            </div>
            <span
              *ngIf="
                !isInstantBankAnalysis &&
                !isInventory &&
                !isTicket &&
                !isCategory &&
                data[column.name]
              "
              >{{ data[column.name] | date : "MMMM d, y" }}</span
            >
            <div *ngIf="isInventory && data[column.name]">
              <div>
                {{ data[column.name] | date : "mediumDate" }} <br />({{
                  data[column.name] | date : "hh:mm:ss a"
                }})
              </div>
            </div>
            <div *ngIf="isTicket && data[column.name]">
              <div>
                {{ data[column.name] | date : "mediumDate" }} <br />
                ({{ data[column.name] | date : "hh:mm:ss a" }})
              </div>
            </div>
            <div *ngIf="isCategory && data[column.name]">
              <div>
                {{ data[column.name] | date : "mediumDate" }} <br />
                ({{ data[column.name] | date : "hh:mm:ss a" }})
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'effective' ||
            column.name === 'processedDate' ||
            column.name === 'sent' ||
            column.name === 'settlementDate' ||
            column.name === 'hitDate' ||
            column.name === 'scheduledDate' ||
            column.name === 'scheduleDate' ||
            column.name === 'EffectiveDate' ||
            column.name === 'SentDate' ||
            column.name === 'CompletedDate' ||
            column.name === 'HitOn' ||
            column.name === 'created' ||
            column.name === 'completed' ||
            column.name === 'postedDate' ||
            column.name === 'transactionDate' ||
            column.name === 'effectiveDate' ||
            column.name === 'sentDate' ||
            column.name === 'completedDate' ||
            column.name === 'EndDate' ||
            column.name === 'GeneratedDate' ||
            column.name === 'StartDate' ||
            column.name === 'createdDate' ||
            column.name === 'cutOffTime'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span
              *ngIf="
                !isInstantBankAnalysis &&
                !isStatementHistorySummary &&
                !isBatch &&
                data[column.name]
              "
              >{{ data[column.name] | date : "MMM d, y" }}</span
            >
            <span
              *ngIf="(isInstantBankAnalysis || isBatch) && data[column.name]"
            >
              <ng-container
                *ngIf="column.name === 'cutOffTime'; else otherFormat"
              >
              {{ getDateTimeWithZoneFormate(data[column.name]).date }}<br />
              {{ getDateTimeWithZoneFormate(data[column.name]).time }}
              </ng-container>
              <ng-template #otherFormat>
                {{ data[column.name] | date : "MM/dd/yyyy" }}
              </ng-template>
            </span>
            <span *ngIf="isStatementHistorySummary && data[column.name]">{{
              data[column.name] | date : "MMM d, y"
            }}</span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'batchStatus'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span style="text-align: left">
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'new'"
                mat-raised-button
                matTooltip="Batch is created but not submitted to processing"
                class="batch-status-button"
                style="background: #D4EED5; border-color: rgb(76, 175, 80); color: rgb(76, 175, 80)"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'queued'"
                mat-raised-button
                matTooltip="Batch is created and ready to process"
                class="batch-status-button"
                style="background: #FFEDD7; border-color: rgb(255, 179, 0); color: rgb(255, 179, 0)"
              >
                {{ data[column.name] }}
              </button>
              <!-- <button
                *ngIf="data[column.name]?.toLowerCase() === 'processing'"
                mat-raised-button
                matTooltip="Batch is picked up for processing"
                class="batch-status-button"
                style="background: #E8F0F9; border-color:rgb(33, 150, 243); color: rgb(33, 150, 243)"
              >
                {{ data[column.name] }}
              </button> -->
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'sent'"
                mat-raised-button
                matTooltip="Batch is sent for processing"
                class="batch-status-button"
                style="background: #E8F0F9; border-color:rgb(63, 81, 181); color: rgb(63, 81, 181)"
                >
                {{ data[column.name] }}
              </button>
              <!-- <button
                *ngIf="data[column.name]?.toLowerCase() === 'posted'"
                mat-raised-button
                matTooltip="Batch is successfully processed"
                class="batch-status-button"
                style="background: rgb(151, 112, 158); border-color:rgb(156, 39, 176); color: rgb(156, 39, 176)"
                >
                {{ data[column.name] }}
              </button> -->
              <!-- <button
                *ngIf="data[column.name]?.toLowerCase() === 'parsefailed'"
                mat-raised-button
                matTooltip="Some transactions are partially failed in batch"
                class="batch-status-button"
                style="background: #F9E8E8; border-color:rgb(255, 87, 34); color: rgb(255, 87, 34)"
                >
                {{ data[column.name] }}
              </button> -->
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'failed'"
                mat-raised-button
                matTooltip="All transactions are failed in batch"
                class="batch-status-button"
                style="background: #F9E8E8; border-color: rgb(244, 67, 54); color: rgb(244, 67, 54)"
              >
                {{ data[column.name] }}
              </button>
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'batchTransactionStatus'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span style="text-align: left">
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'cancelled'"
                mat-raised-button
                matTooltip="Transaction is cancelled"
                class="batch-transaction-status-button"
                style="background: rgb(221, 221, 221); color: rgb(101, 101, 101)"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'pending'"
                mat-raised-button
                matTooltip="New transaction is created but pending for processing"
                class="batch-transaction-status-button"
                style="background: rgb(255, 236, 212); color: rgb(242, 146, 25)"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'returned'"
                mat-raised-button
                matTooltip="Transaction is returned"
                class="batch-transaction-status-button"
                style="background: rgb(255, 221, 221); color: rgb(255, 84, 84)"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'successful'"
                mat-raised-button
                matTooltip="Transaction is successful"
                class="batch-transaction-status-button"
                style="background: rgb(208, 235, 220); color: rgb(43, 164, 98)"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'failed'"
                mat-raised-button
                matTooltip="Transaction is failed"
                class="batch-transaction-status-button"
                style="background: rgb(255, 236, 236); color: rgb(187, 11, 12)"
              >
                {{ data[column.name] }}
              </button>
            </span>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name === 'status' ||
            column.name === 'Status' ||
            column.name === 'ticketStatus'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span style="text-align: left">
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'under review'"
                mat-button
                class="status-button-style"
                style="background: #ff5252"
                matTooltip="{{ data[column.name] }}"
              >
                {{
                  data[column.name]?.length > 12
                    ? data[column.name]?.substring(0, 12) + "..."
                    : data[column.name]
                }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'success' ||
                  data[column.name]?.toLowerCase() === 'successful' ||
                  data[column.name]?.toLowerCase() === 'verified'
                "
                mat-button
                class="status-button-style"
                style="background: #17af87"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'cancelled' ||
                  data[column.name]?.toLowerCase() === 'cancelled recurring'
                "
                mat-button
                style="background: #525252"
                class="status-button-style"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'failed' ||
                  data[column.name]?.toLowerCase() === 'returned' ||
                  data[column.name]?.toLowerCase() === 'expired' ||
                  data[column.name]?.toLowerCase() === 'error'
                "
                mat-button
                class="status-button-style"
                style="background: #f16063"
                matTooltip="{{
                  data?.errorReason
                    ? data?.errorReason
                    : data['ReturnStatusCode']
                    ? data['ReturnMessage'] + ' ' + data['ReturnStatusCode']
                    : data['ReturnMessage']
                }}"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'errorurlfiserv'"
                mat-button
                class="status-button-style"
                style="background: #f16063"
              >
                ERROR
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'sent' ||
                  data[column.name]?.toLowerCase() === 'clicked' ||
                  data[column.name]?.toLowerCase() === 'revalidation' ||
                  data[column.name]?.toLowerCase() === 'pending' ||
                  data[column.name]?.toLowerCase() === 'process'
                "
                mat-button
                class="status-button-style"
                style="background: #ffb23f"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </button>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'closed'"
                class="status-label-style status-label"
                style="background: #4caf50"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'resolved'"
                class="status-label-style status-label"
                style="background: #4caf50"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'open'"
                class="status-label-style status-label"
                style="background: #ff5252"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'assigned'"
                class="status-label-style status-label"
                style="background: #1e4ed8"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'in process'"
                class="status-label-style status-label"
                style="background: #fb8c00"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'withdrawn'"
                class="status-label-style status-label"
                style="background: #101827"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'reopened'"
                class="status-label-style status-label"
                style="background: #f70a8c"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
              <mat-label
                *ngIf="data[column.name]?.toLowerCase() === 'info req'"
                class="status-label-style status-label"
                style="background: #055cfd"
                matTooltip="{{ data?.errorReason ? data?.errorReason : '' }}"
              >
                {{ data[column.name] }}
              </mat-label>
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'invoiceStatus'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span style="text-align: left">
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'sent'"
                mat-button
                matTooltip="TRANSACTION IN PROCESS"
                class="status-button-style"
                style="background: #c161e2"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'incomplete'"
                mat-button
                matTooltip="MISSING INFORMATION (UW)"
                class="status-button-style"
                style="background: #e26167"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="
                  data[column.name]?.toLowerCase() === 'paid' ||
                  data[column.name]?.toLowerCase() === 'completed' ||
                  data[column.name]?.toLowerCase() === 'successful'
                "
                mat-button
                matTooltip="TRANSACTION SUCCESSFUL"
                class="status-button-style"
                style="background: #4caf50"
              >
                {{ data[column.name] }}
              </button>

              <button
                *ngIf="data[column.name]?.toLowerCase() === 'onhold'"
                mat-button
                matTooltip="TRANSACTION ON HOLD"
                class="status-button-style"
                style="background: #0655e7"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'unpaid'"
                mat-button
                class="status-button-style"
                style="background: #22252c7a"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'pastdue'"
                mat-button
                class="status-button-style"
                style="background: #ff5252"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'failed'"
                mat-button
                class="status-button-style"
                matTooltip="TRANSACTION FAILED"
                style="background: #ff5252"
              >
                {{ data[column.name] }}
              </button>
              <button
                *ngIf="data[column.name]?.toLowerCase() === 'pending'"
                mat-button
                class="status-button-style"
                matTooltip="TRANSACTION PENDING"
                style="background: #0655e7"
              >
                {{ data[column.name] }}
              </button>
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'isActive'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <mat-label
              *ngIf="data[column.name] && !isInventory && !isCategory"
              style="color: green"
            >
              Active
            </mat-label>
            <mat-label
              *ngIf="!data[column.name] && !isInventory && !isCategory"
              style="color: red"
            >
              Inactive
            </mat-label>

            <button
              *ngIf="!data[column.name] && (isInventory || isCategory)"
              mat-button
              class="status-button-style"
              style="background: #ff5252"
            >
              Inactive
            </button>

            <button
              *ngIf="data[column.name] && (isInventory || isCategory)"
              mat-button
              class="status-button-style"
              style="background: #17af87"
            >
              Active
            </button>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.name === 'rating' || column.name == 'comment'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column" *ngIf="getRating(data[column.name]) !== '0'">
              <span class="star" (click)="$event.stopPropagation()">
                <img
                  *ngFor="
                    let item of [].constructor(
                      getGivenStarCount(data[column.name])
                    );
                    let i = index
                  "
                  class="startImgWidth1"
                  src="assets/grid-icons/star-empty.png"
                />
                <img
                  *ngFor="
                    let item of [].constructor(
                      getRemainStarCount(data[column.name])
                    );
                    let i = index
                  "
                  class="startImgWidth2"
                  src="assets/grid-icons/star-filled.png"
                />
              </span>
            </div>

            <div fxLayout="column" *ngIf="getRating(data[column.name]) === '0'">
              <mat-label
                class="ticketId-link"
                *ngIf="role != 1"
                (click)="linkClick(data, getStatus(data.ticketStatus))"
              >
                {{ getStatus(data.ticketStatus) }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'raiseAction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                class="ticketId-link"
                *ngIf="role != 1"
                (click)="raiseTicket(data, getStatus(data.invoiceStatus))"
              >
                {{ getStatus(data.invoiceStatus) }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.name === 'bankAction' || column.name === 'cardAction'"
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label
                class="ticketId-link"
                *ngIf="role != 1"
                (click)="
                  column.name === 'bankAction'
                    ? editBankClick(data.customerBankAccountId)
                    : editCardClick(data.customerCardId)
                "
              >
                Edit
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'isPrimary'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-checkbox [checked]="data.isPrimary" disabled></mat-checkbox>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'cardExpDate'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{
                  (data.expMonth.toString().length < 2 ? "0" : "") +
                    data.expMonth +
                    "/" +
                    data.expYear.toString().slice(-2)
                }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'accountType'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div fxLayout="column">
              <mat-label>
                {{ data.accountType | titlecase }}
              </mat-label>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'actionUser'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="role != 1"
                matTooltip="Edit user"
                class="sectionSubImg"
                src="assets/grid-icons/Edit.png"
                (click)="editCustomerClick(data.userId)"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'actionStatement'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="View PDF"
                class="sectionSubImg"
                src="assets/grid-icons/readOnlyView.png"
                (click)="viewPDF(data)"
              />
            </span>
            <span>&nbsp;&nbsp;</span>
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="Download PDF"
                class="sectionSubImg"
                src="assets/grid-icons/install.png"
                (click)="downloadPDF(data)"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'actionLinkPayment'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="View Button Details"
                class="sectionSubImg"
                src="assets/grid-icons/eye.png"
                (click)="paymentLinkViewClick(data.id)"
              />
            </span>
            <span>&nbsp;&nbsp;</span>
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="Duplicate Link"
                class="sectionSubImg"
                src="assets/grid-icons/duplicate.png"
                (click)="paymentDuplicateLinkClick(data.id)"
              />
            </span>
            <span>&nbsp;&nbsp;</span>
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="Active/Inactive Link"
                class="sectionSubImg"
                src="assets/grid-icons/delete.png"
                (click)="deleteServiceClick(data)"
              />
            </span>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.name !== 'action' &&
            column.name !== 'customerGroupAction' &&
            column.name !== 'batchTrxnAction' &&
            column.name !== 'notes' &&
            column.name !== 'isInclude' &&
            column.name !== 'customerGroupStatus' &&
            column.name !== 'achTransactionType' &&
            column.name !== 'checkSignBank' &&
            column.name !== 'checkSignCredit' &&
            column.name !== 'defaultAmtText' &&
            column.name !== 'defaultAmount' &&
            column.name !== 'select' &&
            column.name !== 'name' &&
            column.name !== 'nameWithoutEmail' &&
            column.name !== 'nameWithoutHeader' &&
            column.name !== 'type' &&
            column.name !== 'entryDate' &&
            column.name !== 'status' &&
            column.name !== 'ibvaction' &&
            column.name !== 'cardsAction' &&
            column.name !== 'achAction' &&
            column.name !== 'refreshType' &&
            column.name !== 'isActive'
          "
        >
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                *ngIf="column.name == 'transactionId'"
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuName"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <button
                *ngIf="column.name == 'sendVia'"
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenuNameForSendVia"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <button
                *ngIf="
                  column.name !== 'transactionId' && column.name !== 'sendVia'
                "
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
            [id]="data.id"
          >
            <span
              *ngIf="data[column.name]?.length > 12"
              class="ellip"
              matTooltip="{{ formatData(data, column) }}"
            >
              {{ formatData(data, column) }}
            </span>
            <span *ngIf="!(data[column.name]?.length > 12)">
              {{ formatData(data, column) }}
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'cardsAction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="
                  data.action?.toLowerCase() == 'cancel recurring' && role != 1
                "
                matTooltip="Cancel Transaction"
                class="recuImg"
                src="assets/grid-icons/cancelled-recurring.png"
                (click)="deleteCardRecurringTransactionClick(data.trackingId)"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'achAction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="
                  data.achAction?.toLowerCase() == 'cancel recurring' &&
                  role != 1
                "
                matTooltip="Cancel Transaction"
                class="recuImg"
                src="assets/grid-icons/cancelled-recurring.png"
                (click)="deleteAchRecurringTransactionClick(data.TrackingId)"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'action'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span
              *ngIf="!(isInventory || isCategory)"
              (click)="$event.stopPropagation()"
            >
              <img
                *ngIf="role != 1"
                matTooltip="Edit customer"
                class="sectionSubImg"
                src="assets/grid-icons/Edit.png"
                (click)="editCustomerClick(data.customerId)"
              />
            </span>

            <span
              *ngIf="isInventory || isCategory"
              (click)="$event.stopPropagation()"
            >
              <img
                *ngIf="role != 1"
                matTooltip="Edit Service"
                class="sectionSubImg"
                src="assets/grid-icons/Edit.png"
                (click)="editServiceClick(data.id)"
              />
            </span>
            <span
              *ngIf="isInventory || isCategory"
              (click)="$event.stopPropagation()"
            >
              &nbsp;
              <img
                *ngIf="role != 1"
                matTooltip="Active/Inactive Service"
                class="sectionSubImg"
                src="assets/grid-icons/delete.png"
                (click)="deleteServiceClick(data)"
              />
            </span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.name === 'achTransactionType'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <mat-label>
              {{ data[column.name] }}
            </mat-label>
          </td>
        </ng-container>
        <ng-container *ngIf="column.name === 'customerGroupAction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="View Customer Group"
                class="sectionSubImg"
                src="assets/grid-icons/eye.png"
                (click)="viewCustomerGroupClick(data.id)"
              />
            </span>
            <span (click)="$event.stopPropagation()">
              &nbsp;
              <img
                matTooltip="Edit Customer Group"
                class="sectionSubImg"
                src="assets/grid-icons/Edit.png"
                (click)="editCustomerGroupClick(data.id)"
              />
            </span>

            <!-- <span *ngIf="data.isActive" (click)="$event.stopPropagation()">
              &nbsp;
              <img
                matTooltip="Deactivate Customer Group"
                class="sectionSubImg delete-margin"
                src="assets/grid-icons/delete_blue.png"
                (click)="deleteCustomerGroupClick(data.id, data.isActive)"
              />
            </span> -->

            <!-- <span *ngIf="!data.isActive" (click)="$event.stopPropagation()">
              &nbsp;
              <img
                matTooltip="Activate Customer Group"
                class="sectionSubImg delete-margin"
                src="assets/grid-icons/delete_blue.png"
                (click)="deleteCustomerGroupClick(data.id, data.isActive)"
              />
            </span> -->
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'batchTrxnAction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                matTooltip="View Batch Transactions"
                class="sectionSubImg view-margin"
                src="assets/grid-icons/eye.png"
                (click)="viewBatchTransactionsClick(data.batchId)"
              />
            </span>

            <span (click)="$event.stopPropagation()">
              &nbsp;
              <button
                mat-icon-button
                [matMenuTriggerFor]="BatchRowMoreMenu"
                [matMenuTriggerData]="{ batchId: data.batchId, batchStatus: data.batchStatus, isActive : data.isActive}"
                (click)="isIconClicked(data.cutOffTime)"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <!-- <img
              matTooltip="More Option"
              class="sectionSubImg
              src="assets/grid-icons/delete_blue.png"
              (click)="deleteCustomerGroupClick(data.id)"
            /> -->
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'notes'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                [matTooltip]="!data.isInclude ? '' : (isViewBatch ? 'View Notes' : 'Add Notes')"
                class="sectionSubImg"
                src="assets/grid-icons/notes.png"
                (click)="!data.isInclude || viewBatchTransactionsClick(data.customerId)"
                [attr.disabled]="!data.isInclude"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'isInclude'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <mat-slide-toggle
                class="form-control"
                style="margin-left: 8px"
                [(ngModel)]="data.isInclude"
                [disabled]="!data.accountNumber || isViewBatch"
              >
                <mat-label class="showDetailsValue"></mat-label>
              </mat-slide-toggle>
            </span>
          </td>
        </ng-container>

        
        <ng-container *ngIf="column.name === 'checkSignBank'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            style="text-align: center"
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
            style="text-align: center"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="data.hasBankAccount || data.hasBankAccounts"
                matTooltip="Has Bank"
                class="sectionSubImg"
                src="assets/grid-icons/check_sign.png"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'checkSignCredit'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            style="text-align: center"
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
            style="text-align: center"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="data.hasCard || data.hasCards"
                matTooltip="Yes"
                class="sectionSubImg"
                src="assets/grid-icons/check_sign.png"
              />
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'customerGroupStatus'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
          >
            {{ column.displayName }}
            <div *ngIf="showMoreMenu" (click)="$event.stopPropagation()">
              <button
                mat-icon-button
                [matMenuTriggerFor]="TableHeaderMenu"
                [matMenuTriggerData]="{ displayName: column.displayName }"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <div *ngIf="!showMoreMenu">
              <button mat-icon-button></button>
            </div>
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <button
                *ngIf="data.status?.toLowerCase() == 'active'"
                mat-button
                matTooltip="CUSTOMER GROUP IS ACTIVE"
                class="batch-transaction-status-button"
                style="background: #d4eed5; color: #1e8f22"
              >
                ACTIVE
              </button>
            </span>
            <span (click)="$event.stopPropagation()">
              <button
                *ngIf="data.status?.toLowerCase() == 'draft'"
                mat-button
                matTooltip="CUSTOMER GROUP IS IN DRAFT"
                class="batch-transaction-status-button"
                style="background: #ffedd7; color: #f88a00"
              >
                DRAFT
              </button>
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'defaultAmtText'"> 
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <div class="defaultAmtText" >
              <img 
                class="icon"
                matTooltip="doller"
                src="assets/grid-icons/doller.png"
              />&nbsp;&nbsp; 
              <mat-form-field appearance="outline" class="form-field-search">
                <input
                  matInput
                  placeholder="0.00"
                  autocomplete="off"
                  [(ngModel)]="data.defaultAmtText"
                  (ngModelChange)="checkValidation(data)"
                  name="defaultAmtText"
                  #defaultAmtText="ngModel"
                  appTwoDigitDecimalNumber
                  required
                  min="0"
/>
                <mat-error
                *ngIf="defaultAmtText.invalid && defaultAmtText.touched && column.name !== 'defaultAmtText'"
                 >
                  Only integers are allowed
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'defaultAmount'"> 
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
          <mat-label *ngIf="isViewBatch">
            {{ "$" + (data[column.name] | number : "1.2-2") }}
          </mat-label>
            <div *ngIf="!isViewBatch" class="defaultAmount" >
              <img 
                class="icon"
                matTooltip="doller"
                src="assets/grid-icons/doller.png"
              />&nbsp;&nbsp; 
              <mat-form-field appearance="outline" class="form-field-search">
                <input
                  matInput
                  placeholder="0.00"
                  autocomplete="off"
                  [(ngModel)]="data.defaultAmount"
                  (ngModelChange)="checkValidation(data)"
                  [name]="column.name"
                  #inputField="ngModel"
                  appTwoDigitDecimalNumber
                  required
                  min="0"
                  type="number"
                  [disabled]="!data.isInclude"
                />
                <mat-error *ngIf="inputField.invalid && inputField.touched && isViewBatch">
                  Only integers are allowed
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'defaultAmount'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
          <mat-label *ngIf="isViewBatch">
            {{ "$" + (data[column.name] | number : "1.2-2") }}
          </mat-label>
            <div *ngIf="!isViewBatch" class="defaultAmount" >
              <img 
                class="icon"
                matTooltip="doller"
                src="assets/grid-icons/doller.png"
              />&nbsp;&nbsp; 
              <mat-form-field appearance="outline" class="form-field-search">
                <input
                  matInput
                  placeholder="0.00"
                  autocomplete="off"
                  [(ngModel)]="data.defaultAmount"
                  (ngModelChange)="checkValidation(data)"
                  [name]="column.name"
                  #inputField="ngModel"
                  appTwoDigitDecimalNumber
                  required
                  min="0"
                  type="number"
                  [disabled]="!data.isInclude"
/>
                <mat-error *ngIf="inputField.invalid && inputField.touched && isViewBatch">
                  Only integers are allowed
                </mat-error>
              </mat-form-field>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'refreshType'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <mat-icon
                *ngIf="data.refreshType?.toLowerCase() == 'refreshtransaction'"
                matTooltip="Refresh Bank Transactions"
                class="refreshIcon-color"
                >sync_alt</mat-icon
              >
              <mat-icon
                *ngIf="data.refreshType?.toLowerCase() == 'refreshall'"
                matTooltip="Refresh All"
                class="refreshIcon-color"
                >refresh</mat-icon
              >
              <mat-icon
                *ngIf="data.refreshType?.toLowerCase() == 'refreshbankbalance'"
                matTooltip="Refresh Bank Balance"
                class="refreshIcon-color"
                >attach_money</mat-icon
              >
            </span>
          </td>
        </ng-container>

        <ng-container *ngIf="column.name === 'ibvaction'">
          <th
            [class]="column.className"
            width="{{ column.width }}"
            mat-header-cell
            *matHeaderCellDef
          >
            {{ column.displayName }}
          </th>
          <td
            [class]="column.className"
            width="{{ column.width }}"
            mat-cell
            *matCellDef="let data"
          >
            <span (click)="$event.stopPropagation()">
              <img
                *ngIf="data.action?.toLowerCase() == 'view'"
                matTooltip="View Report"
                class="sectionSubImg"
                matTooltipTouchGestures="off"
                src="assets/grid-icons/eye.png"
                (click)="ibvViewClick(data)"
              />
              <img
                *ngIf="role != 1 && data.action?.toLowerCase() == 'resend'"
                matTooltip="Resend Request"
                class="sectionSubImg"
                matTooltipTouchGestures="off"
                src="assets/grid-icons/resend.png"
                (click)="ibvResendClick(data)"
              />
              <img
                *ngIf="data.action?.toLowerCase() == 'revalidation'"
                matTooltip="Revalidation Link Sent"
                matTooltipTouchGestures="off"
                src="assets/grid-icons/readOnlyResend.png"
              />
              <img
                *ngIf="data.action?.toLowerCase() == 'viewdisable'"
                matTooltip="{{ getActionToolTip(data.status) }}"
                class="sectionSubImg"
                matTooltipTouchGestures="off"
                src="assets/grid-icons/readOnlyView.png"
              />
            </span>
          </td>
        </ng-container>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="getColumnNames(columnsPrepared)"
      ></tr>
      <tr
        mat-row
        (click)="rowClicked(data)"
        (dblclick)="rowDoubleClicked(data)"
        *matRowDef="let data; columns: getColumnNames(columnsPrepared)"
        [ngClass]="{ highlighted: isRowHighlighted(data) }"
      ></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell"
          [attr.colspan]="columnsPrepared.length"
          style="text-align: center"
        >
          {{
            noRecordMessage != undefined && noRecordMessage != ""
              ? noRecordMessage
              : "No Records Found!"
          }}
          <div *ngIf="noRecordMessage === '   '" class="center-content">
            <span class="title"
              ><b
                >Create a group by selecting customers from the left panel</b
              ></span
            >
            <span class="new-line">
              <img
                src="assets/grid-icons/next.png"
                alt="Arrow Icon"
                class="arrow-image"
              />
              <img
                src="assets/grid-icons/previous.png"
                alt="Arrow Icon"
                class="arrow-image"
              />
              Move selected customers
            </span>
            <span class="new-line" style="margin-right: 2.5rem">
              <img
                src="assets/grid-icons/nextall.png"
                alt="Arrow Icon"
                class="arrow-image arrow-image-height"
              />
              <img
                src="assets/grid-icons/previousall.png"
                alt="Arrow Icon"
                class="arrow-image arrow-image-height"
              />
              Move all customers
            </span>
          </div>
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="page.pageSize"
      [pageIndex]="page.pageIndex"
      [length]="page.length"
      [pageSizeOptions]="pageSizeOptions"
      showFirstLastButtons
      *ngIf="showPaginator"
    >
    </mat-paginator>
  </div>

  <mat-menu #TableHeaderMenu="matMenu">
    <ng-template matMenuContent let-displayName="displayName">
      <button (click)="headerMenuClick('Unsort', displayName)" mat-menu-item>
        Unsort
      </button>
      <button (click)="headerMenuClick('Hide', displayName)" mat-menu-item>
        Hide column
      </button>
      <button
        (click)="headerMenuClick('ShowColumns', displayName)"
        mat-menu-item
      >
        Show all columns
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #TableHeaderMenuType="matMenu">
    <ng-template matMenuContent let-displayName="displayName">
      <button (click)="headerMenuClick('Unsort', displayName)" mat-menu-item>
        Unsort
      </button>
      <button
        (click)="headerMenuClick('SortByCompany', displayName)"
        mat-menu-item
      >
        Sort by Company
      </button>
      <button
        (click)="headerMenuClick('SortByIndividual', displayName)"
        mat-menu-item
      >
        Sort by Individual
      </button>
      <button (click)="headerMenuClick('Hide', displayName)" mat-menu-item>
        Hide column
      </button>
      <button
        (click)="headerMenuClick('ShowColumns', displayName)"
        mat-menu-item
      >
        Show all columns
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #TableHeaderMenuName="matMenu">
    <ng-template matMenuContent let-displayName="displayName">
      <button
        *ngIf="displayName !== 'MONTH'"
        (click)="headerMenuClick('Unsort', displayName)"
        mat-menu-item
      >
        Unsort
      </button>
      <button
        (click)="headerMenuClick('ShowColumns', displayName)"
        mat-menu-item
      >
        Show all columns
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #TableHeaderMenuNameForSendVia="matMenu">
    <ng-template matMenuContent let-displayName="displayName">
      <button (click)="headerMenuClick('Unsort', displayName)" mat-menu-item>
        Unsort
      </button>
      <button (click)="headerMenuClick('Hide', displayName)" mat-menu-item>
        Hide column
      </button>
      <button
        (click)="headerMenuClick('ShowColumns', displayName)"
        mat-menu-item
      >
        Show all columns
      </button>
      <button (click)="headerMenuClick('Sms', displayName)" mat-menu-item>
        Search by SMS
      </button>
      <button (click)="headerMenuClick('Email', displayName)" mat-menu-item>
        Search by Email
      </button>
      <button (click)="headerMenuClick('Both', displayName)" mat-menu-item>
        Search by Both
      </button>
      <button (click)="headerMenuClick('All', displayName)" mat-menu-item>
        Search by All
      </button>
    </ng-template>
  </mat-menu>

  <mat-menu #BatchRowMoreMenu="matMenu">
    <ng-template matMenuContent let-batchId="batchId" let-batchStatus="batchStatus" let-isActive="isActive">
      <button *ngIf="(batchStatus?.toLowerCase() === 'new' && isBeforeCutoff())" 
        (click)="headerMenuClick('Delete', batchId)" 
        mat-menu-item>
        {{isActive ? "Deactivate" : "Activate"}}
    </button>
    <button (click)="headerMenuClick('Clone', batchId)" mat-menu-item>
      Clone
    </button>
    <button *ngIf="(isActive && batchStatus?.toLowerCase() === 'queued')" (click)="headerMenuClick('ProcessNow', batchId)" mat-menu-item>
      Submit Now
    </button>
    </ng-template>
  </mat-menu>
</div>
