<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToECheckPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span> E-Check Transactions Details </span>
  </mat-label>
</div>
<br />

<div class="add-page-header-spacing page-header-text">
  Create E-Check Transaction
</div>
<div class="add-page-header-spacing page-header-sub-text-1">
  All individual & company related transactions can be created from this page
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="40%">
    <img src="assets/grid-icons/addTransaction.png" />
  </div>
  <div fxLayout="column" fxFlex="50%" style="background: #f5f5f5">
    <mat-label class="create-now">Create Now</mat-label>
    <mat-stepper
      orientation="vertical"
      [linear]="true"
      #stepper
      style="background: #f5f5f5"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="customerFormGroup" *ngIf="!existingCustomer">
        <form [formGroup]="customerFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
          <br />

          <mat-label class="inner-stepper-text">Select Customer</mat-label>
          <app-search-customer
            style="margin-top: 2%"
            (onCustomerSelectionEvent)="onCustomerSelection($event)"
          >
          </app-search-customer>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="customerFormGroup" *ngIf="existingCustomer">
        <form [formGroup]="customerFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
        </form>
      </mat-step>
      <mat-step [stepControl]="bankFormGroup">
        <form [formGroup]="bankFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              BANK ACCOUNT
              <!-- <span *ngIf="processorName"> : {{ processorName }}</span> -->
              <span *ngIf="bankName"> - {{ bankName }}</span>
            </span>
          </ng-template>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column" fxFlex="100%">
              <div class="inner-stepper-text">Choose Bank Account</div>
            </div>
          </div>
          <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="100%">
              <mat-form-field appearance="outline">
                <mat-label>Select</mat-label>
                <mat-select name="processor" formControlName="processorCtrl"
                  (selectionChange)="processorSelection($event.value)">
                  <mat-option *ngFor="let echeck of echecklist" [value]="echeck.value">
                    {{ echeck.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
          <div *ngIf="bankList.length > 0">
            <div *ngFor="let bank of bankList">
              <div class="bank-account">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutWrap
                  fxLayoutGap="16px"
                  fxLayoutAlign="start stretch"
                  style="padding: 20px"
                >
                  <div fxLayout="column" fxFlex="5%">
                    <div style="margin-top: 8px">
                      <mat-radio-group
                        (change)="onBankSelected($event, bank)"
                        fxFlex="100%"
                        class="form-control"
                        name="bankSelection"
                      >
                        <mat-radio-button
                          class="add-page-radio-button"
                          [value]="true"
                        >
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div
                    fxLayout="column"
                    fxFlex="80%"
                    fxLayoutWrap
                    fxLayoutGap="10px"
                  >
                    <div fxLayout="row">
                      <span class="ellip" matTooltip="{{ bank.bankName }}">
                        {{ bank.bankName }}
                      </span>
                    </div>
                    <div fxLayout="row">
                      {{ bankEncreption(bank.bankAccountNumber) }}
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="15%">
                    <div style="margin-top: 8px">
                      <img
                        src="assets/grid-icons/dummy-bank.png"
                        height="28px"
                        width="28px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div *ngIf="bankList.length == 0">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
              style="padding: 20px"
            >
              <div fxLayout="column" fxFlex="100%">
                <span class="inner-stepper-text"
                  >No bank accounts available. Please add new bank account for
                  this customer using 'Customers' page to create
                  transaction</span
                >
              </div>
            </div>
            <br />
          </div>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
            &nbsp;&nbsp;
            <button mat-button matStepperPrevious class="white-button-style">
              BACK
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="amountFormGroup">
        <form [formGroup]="amountFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <mat-label class="stepper-header-label">AMOUNT</mat-label>
          </ng-template>
          <br />
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="100%" style="margin-bottom: 5px">
              <span class="inner-stepper-text"
                >How much amount you want to transfer?</span
              >
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%" style="margin-bottom: 5px">
              <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <input
                  type="text"
                  matInput
                  (focusout)="calculateTotalAmount()"
                  formControlName="amount"
                  placeholder="Amount"
                  appTwoDigitDecimalNumber
                  autocomplete="off"
                />
                <span matPrefix class="material-icons">attach_money</span>
                <mat-error>
                  {{ amountFormGroup.controls["amount"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="5%"></div>
            <div fxFlex="45%" style="margin-bottom: 5px">
              <mat-form-field appearance="outline">
                <mat-label>Reference Check Number</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="referenceCheckNumber"
                  name="referenceCheckNumber"
                  placeholder="Reference Check Number"
                  autocomplete="off"
                />
                <mat-error>
                  {{
                    amountFormGroup.controls["referenceCheckNumber"][
                      "_errorMessage"
                    ]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%" style="margin-bottom: 5px">
              <mat-form-field appearance="outline">
                <mat-label>Check date</mat-label>
                <input
                  matInput
                  [matDatepicker]="datepicker"
                  formControlName="scheduledDate"
                  [min]="minDate"
                  [max]="maxDate"
                  readonly
                  [matDatepickerFilter]="holidayFilter"
                  placholder="MM/DD/YYYY"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datepicker"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  #datepicker
                  [startAt]="defaultScheduledDate"
                  [dateClass]="dateClass"
                >
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button
                      mat-raised-button
                      color="primary"
                      matDatepickerApply
                    >
                      Apply
                    </button>
                  </mat-datepicker-actions>
                </mat-datepicker>
                <mat-error>
                  {{
                    amountFormGroup.controls["scheduledDate"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="95%" style="margin-bottom: 5px">
              <mat-form-field appearance="outline">
                <mat-label>Notes</mat-label>
                <textarea
                  matInput
                  placeholder="Notes"
                  formControlName="memoText"
                  name="memoText"
                  autocomplete="off"
                ></textarea>
                <mat-error>
                  {{ amountFormGroup.controls["memoText"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <!-- <div fxFlex="30%" style="margin-bottom: 5px">
              <section class="example-section">
                <mat-checkbox (change)="onSameDayChanged($event)" class="example-margin" [disabled]="!isSameDayAllowed"
                  formControlName="isSameDay" name="isSameDay">
                  Same Day
                </mat-checkbox>
                <mat-error>
                  {{ amountFormGroup.controls["isSameDay"]["_errorMessage"] }}
                </mat-error>
              </section>
            </div> -->
            <div fxFlex="40%" style="margin-bottom: 5px">
              <section class="example-section">
                <mat-checkbox
                  class="example-margin"
                  (change)="recurringChecked($event)"
                  formControlName="isRecurring"
                >
                  Recurring Transaction
                </mat-checkbox>
                <mat-error>
                  {{ amountFormGroup.controls["isRecurring"]["_errorMessage"] }}
                </mat-error>
              </section>
            </div>
          </div>
          <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" class="form-group">
            <div fxFlex="100%" style="margin-bottom: 5px">
              <label class="processingLbl" *ngIf="isSameDayAllowed">
                (Processing is allowed till {{ endWindowTime }} Hours)
              </label>
              <label class="processingLbl" *ngIf="!isSameDayAllowed">
                (Same day is not allowed at this time. Window time is
                {{ startWindowTime }} to {{ endWindowTime }})
              </label>
            </div>
          </div> -->
          <div
            *ngIf="isRecurring"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Installments*</mat-label>
                <input
                  type="text"
                  (focusout)="calculateTotalAmount()"
                  matInput
                  formControlName="installments"
                  placeholder="Installments"
                  autocomplete="off"
                />
                <mat-error>
                  {{
                    amountFormGroup.controls["installments"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="5%"></div>
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Total Amount</mat-label>
                <input
                  type="text"
                  matInput
                  readonly
                  formControlName="totalAmount"
                  placeholder="Total Amount"
                />
                <mat-error>
                  {{ amountFormGroup.controls["totalAmount"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            *ngIf="isRecurring"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Frequency*</mat-label>
                <mat-select name="frequency" formControlName="frequency">
                  <mat-option
                    *ngFor="let frequency of frequencies"
                    [value]="frequency.value"
                  >
                    {{ frequency.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ amountFormGroup.controls["frequency"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <br /><br />
        </form>
        <div style="padding-top: 10px">
          <button
            mat-stroked-button
            matStepperNext
            class="page-header-button-style"
            (click)="addECheckTransaction()"
          >
            SUBMIT
          </button>
          &nbsp;&nbsp;
          <button mat-button matStepperPrevious class="white-button-style">
            BACK
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div fxLayout="column" fxFlex="10%" style="background: #f5f5f5"></div>
</div>
