<div class="rule-card">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayout.xs="column" class="rule-card-header">
        <div fxFlex="70%" >
            <label>{{model?.ruleTitle}}</label>
        </div>
        <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end">
            <div class="ck-active-rule">
                <mat-checkbox class="checkbox-margin" (change)="chkActive($event)" [(ngModel)]="model.isActive">Active</mat-checkbox>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayout.xs="column" class="rule-card-body">
        <div fxLayout="column" fxFlex="70%" fxLayoutGap="5px">
            <div *ngFor="let ruleItem of this.model?.scoreRules; let i = index">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center"  fxLayoutGap="20px" >
                    <div class="rule-card-item-label">
                        <label>From value</label>
                    </div>
                    <div fxFlex="100px">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>From</mat-label>
                            <input type="number" min="0" max="999999999" maxlength="4" matInput title="From" placeholder="" [(ngModel)]="ruleItem.from" [disabled]="!model.isActive" (change)="OnScoreChangeAsync()" required>
                        </mat-form-field>
                    </div>
                    <div class="rule-card-item-label">
                        <label>to value</label>
                    </div>
                    <div fxFlex="100px">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>To</mat-label>
                            <input type="number" min="0" max="999999999" maxlength="4" matInput title="To" placeholder="" [(ngModel)]="ruleItem.to" [disabled]="!model.isActive" (change)="OnScoreChangeAsync()" required>
                        </mat-form-field>
                    </div>
                    <div class="rule-card-item-label">
                        <label>then deduct score</label>
                    </div>
                    <div fxFlex="100px">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Deduct</mat-label>
                            <input type="number" min="0" max="1000" maxlength="4" matInput required title="Deduct" placeholder="" [(ngModel)]="ruleItem.deduct"  [disabled]="!model.isActive" (change)="OnScoreChangeAsync()">
                        </mat-form-field>
                    </div>
                    
                    <button type="button" mat-icon-button color="warn" title="Remove" class="btn-Remove" [disabled]="!model.isActive" (click)="openDialog(i)" >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>

                <div  *ngIf="this.validateErrors != null">
                    <div *ngFor="let error of this.validateErrors;">
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" >
                            <mat-error  class="invalid-feedback" *ngIf = "lstIndex[i] == error[0].key"> {{error[0].value}} </mat-error>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="rule-card-footer" fxLayout="row">
            <div class="rule-note" fxFlex="70%">
                <div *ngIf = model?.ruleNote >
                    <span class="rule-note-title">Note: </span>
                    <div class="rule-note-content">{{model?.ruleNote}}</div>
                </div>
            </div>
            <div class="rule-action" fxFlex="30%" fxLayout="row" fxLayoutAlign="end">
                <button mat-stroked-button class="button-style btn-Add" [disabled]="!model.isActive" (click)="addNewRuleItem()">Add</button>
            </div>
        </div>
    </div>
</div>

<ng-template #callAPIDialog>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
        <div >
            <h3>Are you sure you want to remove this score setting?</h3> 
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="removeRuleItem()">Yes</button> 
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>
