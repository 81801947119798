import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { HostedPaymentTabModel, HostedPaymentConfiguration, HostedPaymentDesignCustomisation } from 'src/app/sharedModules/models/hosted-payment-tab.model'

@Component({
  selector: 'app-hosted-payment-page-config-setting',
  templateUrl: './hosted-payment-page-config-setting.component.html',
  styleUrls: ['./hosted-payment-page-config-setting.component.scss']
})
export class HostedPaymentPageConfigSettingComponent implements OnInit {

  @Input()
  hostedPaymentpageFormGroup: FormGroup;
  
  @Input()
  finzeoPaymentOption:string;
 /*  public hostedPaymentpageFormGroup: FormGroup;
  public hostedPaymentTabModel: HostedPaymentTabModel; */
  //formData: any;
  constructor(
    private formBuilder: RxFormBuilder,
  ) { }

  ngOnInit(): void {
    /* this.hostedPaymentTabModel = new HostedPaymentTabModel();
    this.hostedPaymentTabModel.configurationTabForm = new HostedPaymentConfiguration();
    this.hostedPaymentTabModel.customisationTabForm = new HostedPaymentDesignCustomisation();
    this.hostedPaymentpageFormGroup = this.formBuilder.formGroup(this.hostedPaymentTabModel);
    this.hostedPaymentpageFormGroup.valueChanges.subscribe(data => {
     
      this.formData = data;
    });   */
  } 
}
