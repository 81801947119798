import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddCustomerCardDetailsModel } from 'src/app/sharedModules/models/add-customer-card-details.model';

@Injectable({
  providedIn: 'root',
})
export class IbvFavoriteService extends CrudService<
  AddCustomerCardDetailsModel,
  any
> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_IBV}Favorite`);
  }

  GetAllCategoryFavorite(accountType: string): Observable<any> {
    return this._http.get<any>(
      this._base + '/GetAllCategoryFavorite/' + accountType
    );
  }

  AddAndRemoveFavoriteCategory(
    categoryId: any,
    accountType: string,
    action: boolean
  ): Observable<any> {
    return this._http.post<any>(this._base + '/AddAndRemoveFavoriteCategory', {
      categoryId: categoryId,
      accountType: accountType,
      action: action,
    });
  }
}
