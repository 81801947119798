<mat-tab-group
  mat-align-tabs="start"
  animationDuration="0ms"
  [(selectedIndex)]="selectedTabIndex"
  (selectedTabChange)="onTabChange()"
>
  <mat-tab label="ACH Transaction">
    <app-customer-ach-transaction *ngIf="loadAchTransaction" [showTransctionHeader]="false" [tabIndex]="selectedTabIndex">
    </app-customer-ach-transaction>
  </mat-tab>
  <mat-tab label="Credit/Debit">
    <app-customer-creditdebit-transaction
      *ngIf="loadCreditDebit"
      [showTransctionHeader]="false"
      [tabIndex]="selectedTabIndex"
    >
    </app-customer-creditdebit-transaction>
  </mat-tab>
  <!-- hiding the INF feature -->
  <!-- <mat-tab label="Instant Fund" *ngIf="!customerType">
    <div style="height: 100%">
      <app-customer-instantfund-transaction
        *ngIf="loadInstantFund"
        [showTransctionHeader]="false"
        [tabIndex]="selectedTabIndex"
      >
      </app-customer-instantfund-transaction>
    </div>
  </mat-tab> -->
  <mat-tab label="E-Check">
    <app-customer-echeck-transaction
      *ngIf="loadEcheck"
      [showTransctionHeader]="false"
      [tabIndex]="selectedTabIndex"
    >
    </app-customer-echeck-transaction>
  </mat-tab>

  <!-- <mat-tab disabled *ngIf="customerType"></mat-tab> -->
  <mat-tab disabled></mat-tab>
  <mat-tab disabled></mat-tab>
  <mat-tab disabled></mat-tab>

  <mat-tab disabled>
    <ng-template mat-tab-label *ngIf="isActive && userRole != 1">
      <mat-icon style="color: blue">add</mat-icon>
      <u class="add-link" (click)="AddTransaction()">ADD</u>
    </ng-template>
  </mat-tab>
</mat-tab-group>
