import { prop, required } from "@rxweb/reactive-form-validators";

export class addUserModel {
  public firstName: string;
  public lastName: string;
  public userName: string;
  public jobTitle: string;
  @prop()
  @required()
  public phone: string;
  
  public role: string;
  public password: string;
  public isSubscribeReceivingTransactionEmail: boolean =false;
  public email : string;
  public newPassword: string;
  public userId: string;
  @prop()
  isoCode: string;

}