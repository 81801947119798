<mat-spinner *ngIf="isLoading" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{ loadMessage }}
  </mat-spinner>
  
    <div class="basicDetailsForm-inner stats">
      <div *ngIf="!isLoading" fxLayout="row" fxLayoutGap="2%" fxLayout.xs="column" fxLayoutAlign="start center">
        <div fxLayout="column" fxFlex="15%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="center center">
            <div fxLayout="column">
              <img matTooltip="Total Invoices" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/total_invoices.png" />
            </div>
            <div fxLayout="column">
              <span class="amountCls">{{
                invoiceStatsModel.totalInvoices | number : "1.0-2"
                }}</span>
              <span class="amountLblCls">Total Invoices</span>
            </div>
          </div>
        </div>
  
        <div fxLayout="column" fxFlex="15%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="center center">
            <div fxLayout="column">
              <img matTooltip="Total Amount" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/total_amount.png" />
            </div>
            <div fxLayout="column">
              <span class="amountCls">{{ "$" + (invoiceStatsModel.totalAmount | number : "1.2-2") }}
              </span>
              <span class="amountLblCls">Total Amount</span>
            </div>
          </div>
        </div>
  
        <mat-divider [vertical]="true" class="divider-style"></mat-divider>
  
        <div fxLayout="column" fxFlex="15%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="center center">
            <div fxLayout="column">
              <img matTooltip="Paid Invoices" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/paid_status.png" />
            </div>
            <div fxLayout="column">
              <span class="amountCls">{{
                invoiceStatsModel.paidStatus | number : "1.0-2"
                }}</span>
              <span class="amountLblCls">Paid</span>
            </div>
          </div>
        </div>
  
        <div fxLayout="column" fxFlex="15%">
          <div fxLayout="row" fxLayoutGap="2%" fxLayoutAlign="center center">
            <div fxLayout="column">
              <img matTooltip="Sent Invoices" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/sent_status.png" />
            </div>
            <div fxLayout="column">
              <span class="amountCls">{{ invoiceStatsModel.sentStatus | number : "1.0-2" }}
              </span>
              <span class="amountLblCls">Sent</span>
            </div>
          </div>
        </div>

        <div fxLayout="column" fxFlex="15%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="center center">
              <div fxLayout="column">
                <img matTooltip="Pending Invoices" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/overdue_status.png"/>
              </div>
              <div fxLayout="column">
                <span class="amountCls">{{
                  invoiceStatsModel.overdueStatus | number : "1.0-2"
                  }}</span>
                <span class="amountLblCls">PastDue</span>
              </div>
            </div>
          </div>
  
        <div fxLayout="column" fxFlex="15%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="center center">
            <div fxLayout="column">
              <img matTooltip="Unpaid Invoices" class="summaryIcons" src="assets/invoices-icons/invoices-detail-icons/unpaid_status.png"/>
            </div>
            <div fxLayout="column">
              <span class="amountCls">{{
                invoiceStatsModel.unpaidStatus | number : "1.0-2"
                }}</span>
              <span class="amountLblCls">Unpaid</span>
            </div>
          </div>
        </div>        
      </div>
    </div>
