import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hosted-payment-page-order',
  templateUrl: './hosted-payment-page-order.component.html',
  styleUrls: ['./hosted-payment-page-order.component.scss']
})
export class HostedPaymentPageOrderComponent implements OnInit {

  @Input()
  formData: any;
  isLoading = false;

  @Input()
  changesIsLoading: boolean;

  companyLogo: any;
  invoiceNumber: any;
  amount: any;
  description: any;

  paymentHeaderBGColor: any;
  primaryButtonBGColor: any;
  labelColor: any;
  logotext: string;

  @Input()
  isSmallView: boolean;

  @Input()
  deviceType: string;

  logoBlobPath: string;

  previewWiseStyle = {
    divPreviewHeight: "",
    headerLableFontSize: "",
    orderLableFontSize: "",
    orderValueFontSize: "",
    buttonTextFontSize: "",
    inputControlClass: "",
    logoImageHeight:"",
  }

  deviceWiseStyle = {
    paymentDivClass: "",
    companyLogoClass: "",
    paymentOrderContainerClass: "",
    securePaymentLogo: ""
  }

  constructor() { }

  ngOnInit(): void {

    this.formData;
    this.invoiceNumber = "MER-898u96-yuyt";
    this.amount = 234.56;
    this.description = "this is related to payment";

    this.paymentHeaderBGColor = '#1A237E';
    this.primaryButtonBGColor = '#3D4EAE';
    this.labelColor = '#1A237E';

    // small - big view related changes
    this.previewWiseStyle.divPreviewHeight = this.isSmallView ? "160px !important" : "100%";
    // this.previewWiseStyle.headerLableFontSize = this.isSmallView ? "6px" : "12px";
    // this.previewWiseStyle.orderValueFontSize = this.isSmallView ? "3px" : "6.5px";
    // this.previewWiseStyle.orderLableFontSize = this.isSmallView ? "3.5px" : "7.5px";
    // this.previewWiseStyle.buttonTextFontSize = this.isSmallView ? "3.5px" : "7px";
    this.previewWiseStyle.headerLableFontSize = this.isSmallView ? "6px" : "15px";
    this.previewWiseStyle.orderValueFontSize = this.isSmallView ? "3px" : "11px";
    this.previewWiseStyle.orderLableFontSize = this.isSmallView ? "3.5px" : "11px";
    this.previewWiseStyle.buttonTextFontSize = this.isSmallView ? "3.5px" : "11px";
    this.previewWiseStyle.logoImageHeight = this.isSmallView ? "45px" : "110px";
    this.previewWiseStyle.inputControlClass = "input-control-" + (this.isSmallView ? "small" : "big");
    this.logotext = localStorage.getItem('logotext');

    //device type wise changes
    if (this.deviceType) {
      this.deviceWiseStyle.companyLogoClass = "company-logo-" + this.deviceType;
      this.deviceWiseStyle.paymentDivClass = "payment-div-" + this.deviceType;
      this.deviceWiseStyle.paymentOrderContainerClass = "payment-order-container-" + this.deviceType;
      this.deviceWiseStyle.securePaymentLogo = "secure-payment-logo-" + this.deviceType;
    }
  }

  getLogoPath(data: any) {     
    if (data != undefined) {
      var logo = data.customisationTabForm.logo;
      this.logoBlobPath = `${environment.ADMIN_CONTAINER_PATH}` + logo;
      return this.logoBlobPath;
    }
}

  validateField(data: string) {

    if (data && data.toLocaleLowerCase() == "show") {
      return true;
    }
    return false;
  }
}
