<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToDashboardPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Dashboard
    </mat-label>
  </div>

  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Total Transactions</div>
        <div class="page-sub-header-text-1">
          All transactions, amount, individual, company related data can be seen
          from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
          mat-raised-button
          color="primary"
          (click)="onPrintClicked()"
          class="page-header-button-style"
        >
          PRINT
        </button>
      </div>
    </div>
  </div>
</div>

<div #canvasContainer id="canvasContainer">
  <app-data-table
    [columns]="columns"
    [data]="gridData"
    [isLoading]="isLoading"
    [isLoadingPaging]="isLoadingPaging"
    [page]="page"
    [sortBy]="sortBy"
    [noRecordMessage]="noRecordMessage"
    [showMoreMenu]="true"
    [allColumns]="allColumns"
    [selection]="selection"
    [actionRefresh]="actionRefresh"
    (actionRefreshEvent)="onRefresh($event)"
    (pageChangedEvent)="onPageChanged($event)"
    (rowClickedEvent)="onRowClick($event)"
    (sortChangedEvent)="onSortChanged($event)"
    (onFilterEvent)="onFilter($event)"
    [isFilter]="false"
    [isExport]="false"
    [isAddBtn]="false"
    [isCreditDebitSummary]="false"
    [isTotalTransactionLbl]="true"
    [isSearch]="false"
    [isTableHeading]="true"
    [isTableHeadingText]="tableLabel"
    [showPaginator]="false"
  >
  </app-data-table>
</div>
