import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { SelectionModel } from '@angular/cdk/collections';
import html2canvas from 'html2canvas';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';
import { ToastrService } from 'ngx-toastr';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-dashboard-report-total-transactions',
  templateUrl: './dashboard-report-total-transactions.component.html',
  styleUrls: ['./dashboard-report-total-transactions.component.scss']
})
export class DashboardReportTotalTransactionsComponent implements OnInit {

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  tableLabel: string = "Number Of Transactions";
  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: '',
    direction: 'asc',
  };

  columns: TableColumn[] = [
    { displayName: 'MONTH', name: 'month', width: '11%' },
    { displayName: 'ACH (LY)', name: 'lastAch', width: '11%' },
    { displayName: 'ACH (TY)', name: 'currentAch', width: '11%' },
    { displayName: 'CR/DB (LY)', name: 'lastCards', width: '11%' },
    { displayName: 'CR/DB (TY)', name: 'currentCards', width: '11%' },
    { displayName: 'E-CHECK (LY)', name: 'lastECheck', width: '11%' },
    { displayName: 'E-CHECK (TY)', name: 'currentECheck', width: '11%' },
    // hiding the INF feature
    // { displayName: 'INF (LY)', name: 'lastInstantFund', width: '11%' },
    // { displayName: 'INF (TY)', name: 'currentInstantFund', width: '11%' },
  ];

  allColumns: TableColumn[];

  constructor(private router: Router,
    private _dashboardService: DashboardService, private _toastrService: ToastrService) {
    this.allColumns = this.columns;
  }

  ngOnInit(): void {
    this.loadData(this.sortBy.active,
      this.sortBy.direction);
  }

  navigateToDashboardPage() {
    this.router.navigate(['dashboard']);
  }

  onRefresh(id: string) { }

  loadData(sortBy: string,
    sortDirection: string) {
    this.isLoading = true;
    this._dashboardService.GetServicesBasedTransactionsCount(sortBy, sortDirection).subscribe((data) => {
      this.isLoading = false;
      if (data.data != null) {
        this.gridData = data.data;
        this.page.length = data.data.totalRecords;
      }
      else {
        this.gridData = [];
      }
    },
    (error) => {
      this.isLoading = false;
    });
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(this.sortBy.active,
        this.sortBy.direction);
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'month':
        this.sortBy.active = 'month';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {
  }

  onPrintClicked() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isLoading = true;
    let fileName = "Dashboard_Total_Transaction_Report";
    let canvasContainer = document.getElementById("canvasContainer");
    let content = [];

    content.push({ text: "Dashboard - Total Transaction Report", style: "infoHeader" });
    content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: "#148F77",
        },
      ],
    });
    content.push("\n");

    html2canvas(canvasContainer, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL("image/png", 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });

      generatedDashboardPDF(content, fileName, null);
      this.isLoading = false;
      this._toastrService.clear();
      this._toastrService.success('PDF downloaded successfully.');
    });
  }
}
