import { prop, required } from '@rxweb/reactive-form-validators';

export class ReportSearchModel {
  @prop()
  customerId: any;

  @prop()
  customerName: string;

  @prop()
  typeOfService: string;

  // @prop()
  // processor: any;

  // @prop()
  // processorId: any;

  @prop()
  @required()
  startDate: any;

  @prop()
  @required()
  endDate: any;
}
