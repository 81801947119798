import { formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import {
  IbvAccountTypeFull,
  IbvAccountTypeShort,
} from 'src/app/featureModules/enums/ibvAccountType';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
import { IbvPdfReport } from 'src/app/sharedModules/models/ibv-pdf-report.details.model';
import {
  generatedIbvPdf,
  getDetailsWithStatus,
} from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-instant-verify-report-cca',
  templateUrl: './instant-verify-report-cca.component.html',
  styleUrls: ['./instant-verify-report-cca.component.scss'],
})
export class InstantVerifyReportCCAComponent {
  ibvPdfReport: IbvPdfReport = new IbvPdfReport();
  loadBalanceCard: boolean = false;
  loadTransactionCard: boolean = false;

  @Input()
  customerName: string;

  @Input()
  customerStatus: string;

  @Input()
  bankDetails: string;

  @Input()
  bankStatus: string;

  @Input()
  reportSeen: any;

  @Input()
  firstSeen: any;

  @Input()
  lastSuccessfulUpdate: any;

  constructor(
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {
    this.ibvPdfReport.accountType = IbvAccountTypeShort.CCA;
    this.ibvPdfReport.ibvBankVerificationId =
      this.route.snapshot.paramMap.get('bankVerificationId');
  }

  @Output() loadCCAAccountReportChange = new EventEmitter<boolean>();
  @Input('loadCCAAccountReport')
  set loadCCAAccountReport(loadCCAAccountReport: boolean) {
    if (loadCCAAccountReport) {
      let startDate = new Date();
      startDate.setDate(1);
      startDate.setMonth(startDate.getMonth() - (3 - 1));

      this.ibvPdfReport.startDate = startDate;
      this.ibvPdfReport.endDate = new Date();

      this.setLoadFlagsForReportDataPull();
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onInputChange(event: any) {
    this.ibvPdfReport.startDate = event.startDate;
    this.ibvPdfReport.endDate = event.endDate;
    this.setLoadFlagsForReportDataPull();
  }

  setLoadFlagsForReportDataPull() {
    this.loadBalanceCard = true;
    this.loadTransactionCard = true;
  }

  @Output() printReportChange = new EventEmitter<boolean>();
  @Input('printReport')
  set printReport(printReport: boolean) {
    if (printReport) {
      this._toastrService.info('Please wait..PDF report is getting generated.');
      let fileName = 'IBV_Transaction_Report_CCA';

      var page1_0 = document.getElementById('canvasContainer_Page1_0');
      var page1_1 = document.getElementById('canvasContainer_Page1_1');

      let content = [];

      content.push({
        text: 'IBA - ' + IbvAccountTypeFull.CCA + ' REPORT',
        style: 'infoHeader',
      });
      content.push({
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 2,
            x2: 515,
            y2: 2,
            lineWidth: 1,
            lineColor: '#148F77',
          },
        ],
      });
      content.push('\n');

      content.push(
        getDetailsWithStatus(
          this.customerName,
          this.customerStatus,
          'Customer Name',
          'Customer'
        )
      );
      content.push('\n');

      content.push(
        getDetailsWithStatus(
          this.bankDetails,
          this.bankStatus,
          'Bank Details',
          'Bank'
        )
      );
      content.push('\n');

      content.push(
        getDetailsWithStatus(this.reportSeen, '', 'Report Seen', 'Report Seen')
      );
      content.push('\n');

      content.push(
        getDetailsWithStatus(
          formatDate(this.firstSeen, 'MM/dd/yyyy (HH:mm:ss)', 'en-US'),
          '',
          'First Seen',
          'First Seen'
        )
      );
      content.push('\n');

      content.push(
        getDetailsWithStatus(
          formatDate(
            this.lastSuccessfulUpdate,
            'MM/dd/yyyy (HH:mm:ss)',
            'en-US'
          ),
          '',
          'Last Successful Update',
          'Last Successful Update'
        )
      );
      content.push('\n');

      html2canvas(page1_0, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((page1_0) => {
        var imgWidth = 520;
        var imgHeight = (page1_0.height * imgWidth) / page1_0.width;

        content.push({
          image: page1_0.toDataURL('image/png', 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });
        content.push({ text: '\n' });

        //To capture Tabs canvas separately on separate page
        html2canvas(page1_1, {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          scale: 2,
          allowTaint: true,
        }).then((page1_1) => {
          var imgWidth = 520;
          var imgHeight = (page1_1.height * 480) / page1_1.width;

          content.push({
            image: page1_1.toDataURL('image/png', 0),
            margin: [0, 0, 0, 0],
            width: imgWidth,
            height: imgHeight,
          });
          content.push({ text: '\n' });

          this.generatePDFReport(content, fileName);
        });
      });
    }

    this.printReportChange.emit(false);
  }

  generatePDFReport(content: any, fileName: string) {
    this._instantVerifyReportsService
      .GetTransactionDetailsForPdf(
        this.ibvPdfReport.ibvBankVerificationId,
        this.ibvPdfReport.accountType,
        this.ibvPdfReport.startDate,
        this.ibvPdfReport.endDate
      )
      .subscribe(
        (data) => {
          if (data.data != null) {
            generatedIbvPdf(
              content,
              fileName,
              data.data,
              this.ibvPdfReport.accountType
            );
            this._toastrService.clear();
            this._toastrService.success('PDF downloaded successfully.');
          }
        },
        (error) => {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
