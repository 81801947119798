import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { InternalAchServiceProxy } from 'src/app/sharedModules/services/ach-transactions/ach-transactions.proxies';

@Injectable({
  providedIn: 'root',
})
export class AchOriginationReportsService extends CrudService<any, any> {
  constructor(
    protected override _http: HttpClient,
    private achTransactionService: InternalAchServiceProxy,
  ) {
    super(_http, `${environment.API_ACH_TRANSACTION_URL}/api/Origination`);
  }

  GetDailyReport(reportDate: any,sortBy: string, sortDirection: string,
    page: number, pageSize: number) {
    return this.achTransactionService.getDailyOriginationAchReport(reportDate,sortBy,sortDirection,page,pageSize);
  }

  GetWeeklyReport(reportDate: any) {
    return this._http.get<any>(
      this._base + '/OriginationWeeklyReport?reportDate=' + reportDate
    );
  }
}
