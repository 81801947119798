import { prop } from "@rxweb/reactive-form-validators";
import { ScoreSettingRule, ScoreSettingRuleItem } from "src/app/sharedModules/services/customer/customer.proxies";

export class ScoreSettingRuleItemCardModel {
    @prop() from!: number | undefined;
    @prop() to!: number | undefined;
    @prop() deduct!: number;

    public static fromDto(dto: ScoreSettingRuleItem) {
        let ns = new ScoreSettingRuleItemCardModel();
        Object.assign(ns, dto);
        return ns
    }

    public static toDto(model: ScoreSettingRuleItemCardModel) {
        let ns = new ScoreSettingRuleItem();
        Object.assign(ns, model);
        return ns
    }
}

export class ScoreSettingRuleCardModel {
    @prop() ruleName!: string | undefined;
    @prop() ruleTitle!: string | undefined;
    @prop() ruleNote!: string | undefined;
    @prop() scoreRules!: ScoreSettingRuleItemCardModel[] | undefined;
    @prop() isActive!: boolean;
    @prop() ruleIndex!: number;

    
    public removeRuleItem(index: number) {
        this.scoreRules?.splice(index, 1);
      }
    
      public addNewRuleItem() {
        let newItem = new ScoreSettingRuleItemCardModel();
        Object.assign(newItem, {from: 0, to: 0, deduct: 0});
        this.scoreRules?.push(newItem);
      } 

    public static fromDto(scoreSettingRule: ScoreSettingRule) {
        let result = new ScoreSettingRuleCardModel();
        Object.assign(result, scoreSettingRule);
        result.scoreRules = scoreSettingRule.scoreRules.map(s => ScoreSettingRuleItemCardModel.fromDto(s));
        return result;
    }

    public static toDto(model: ScoreSettingRuleCardModel) {
        let result = new ScoreSettingRule();
        Object.assign(result, model);
        result.scoreRules = model.scoreRules.map(s => ScoreSettingRuleItemCardModel.toDto(s));
        return result;
    }

}

export class ScoreSettingRuleCardModelArgs {
    @prop()  rule: ScoreSettingRuleCardModel;
    @prop()  ruleIndex: number;
    constructor(model: ScoreSettingRuleCardModel, ruleIndex: number) {
        this.rule = model;
        this.ruleIndex = ruleIndex;
    }
}
