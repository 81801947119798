import { AbstractControl } from '@angular/forms';
import {
  required,
} from '@rxweb/reactive-form-validators';

export class ApplyToAllModel {

  @required()
  transactionTypeApplyAll: string;

  @required()
  defaultAmount: number;

}
