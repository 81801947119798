import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
export interface SummarizedTransaction {
  source: string;
  firstDeposit: string;
  recurringDates: string;
  frequency: string;
  avgPayment: number;
  lastPayment: number;
}
@Component({
  selector: 'app-recurring-credits',
  templateUrl: './recurring-credits.component.html',
  styleUrls: ['./recurring-credits.component.scss']
})
export class RecurringCreditsComponent {
  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  isLoading = false;
  TransactionType : string = "Credit";
  transactions: SummarizedTransaction[] = [];
  noDataForRecurringCredit: boolean = false;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _toastrService: ToastrService
  ) {}

  @Output() loadRecurringCreditCardChange = new EventEmitter<boolean>();
  @Input('loadRecurringCreditCard')
  set loadRecurringCreditCard(loadRecurringCreditCard: boolean) {
    if (loadRecurringCreditCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
      .GetAllTransactionSummaryBasedOnTrnType(
        this.ibvBankVerificationId,
        this.accountType,
        this.startDate,
        this.endDate,
        this.TransactionType
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
              if (data.data.summarizedTransList.length == 0) {
                this.noDataForRecurringCredit = true;
              } else {
                this.transactions = data.data.summarizedTransList;
                this.noDataForRecurringCredit = false;
          }
        }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
          'Something went wrong while fetching recurring credit summary, Please contact administrator!'
        );
        }
      );
    }
    this.loadRecurringCreditCardChange.emit(false);
  }
}
