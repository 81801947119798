<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div fxLayout="column" class="container" fxLayoutWrap>
    <div class="payment-header-dv" fxLayout="row" fxFlex="50%" fxLayoutWrap
        [style.background-color]="pageSettingModel?.themeColor" fxLayoutAlign="center center">
        <img class="company-logo" *ngIf="!isLoading" [src]="pageSettingModel?.logo" alt="company logo" />
    </div>
    <div class="payment-div" [style.--active-fontFamily]="pageSettingModel?.themeFont">
        <div *ngIf="!isLoading" class="payment-order-container w100" fxLayout="column" fxLayoutAlign="start stretch"
            fxLayoutGap="10%">
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
                <label class="header-label">Order Details</label>
            </div>
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
                <div class="w100" fxLayout="column" fxLayoutGap="2.5%" fxLayoutWrap fxLayoutAlign="start space-between">
                    <div *ngIf="displayFields(pageSettingModel?.isInvoiceNumberView)" fxLayout="row" fxLayoutWrap
                        fxLayoutAlign="space-between center">
                        <div class="order-label" fxLayout="column" fxLayoutWrap fxLayoutAlign="center start">
                            CONFIRMATION NUMBER
                        </div>
                        <div class="order-value" fxLayout="column" fxLayoutWrap fxLayoutAlign="center end">
                            {{ this.invoiceNumber }}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
                        <div class="order-label" fxLayout="column" fxLayoutWrap fxLayoutAlign="center start">
                            AMOUNT
                        </div>
                        <div class="order-value" fxLayout="column" fxLayoutWrap fxLayoutAlign="center end">
                            ${{this.amount | number : "1.2-2" }}
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start space-between"
                *ngIf="displayFields(pageSettingModel?.isDescriptionView)">
                <div class="w100" fxLayout="column" fxLayoutGap="1.25%" fxLayoutWrap
                    fxLayoutAlign="start space-between">
                    <div class="order-description-label" fxLayout="row" fxLayoutWrap
                        fxLayoutAlign="start space-between">
                        Description
                    </div>
                    <div class="order-description-value" fxLayout="row" fxLayoutWrap fxLayoutAlign="start space-between"
                        title="{{ this.description }}">
                        {{this.displayDescription}}
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
                <button class="payment-button" [style.background-color]="pageSettingModel?.themeColor"
                    [style.color]="pageSettingModel?.themeTextColor" mat-flat-button (click)="doMakePayment()">Make
                    Payment</button>
            </div>

        </div>
    </div>   
    <div fxLayout="row" fxFlex="50%" fxLayoutWrap class="payment-footer-dv" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center center" class="payment-footer-div-col">
        <img  class="secure-payment-logo" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
            <label style="text-align:center"> Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
        </div>
    </div>
</div>