import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hosted-payment-page-preview',
  templateUrl: './hosted-payment-page-preview.component.html',
  styleUrls: ['./hosted-payment-page-preview.component.scss']
})
export class HostedPaymentPagePreviewComponent implements OnInit {

  viewType: string;
  pageIndex: string;
  @Input()
  formData: any;
  webPageView = 'web';
  tabPageView = 'tab';
  mobilePageView = 'mobile';

  @Input()
  finzeoPaymentOption:string;
  
  @Input()
  changesIsLoading: boolean;
  constructor() { }

  ngOnInit(): void {
    this.viewType = this.webPageView;
    this.pageIndex = "1";
    this.getWidth();
  }

  changeView(viewTypeIndex) {
    this.viewType = viewTypeIndex;
  }

  onPageChange(pageIndex) {
    this.pageIndex = pageIndex;
  }

  getIndexImage(index: number) {
    if (this.pageIndex == index.toString()) {
      return "assets/payment-widget-icons/pay-cust-page-index-" + index + "-blue.png"
    }
    return "assets/payment-widget-icons/pay-cust-page-index-" + index + "-black.png"
  }
  getWidth() {
    if(this.viewType==this.webPageView){
      return "28%";
    }
    if(this.viewType==this.tabPageView){
      return "25%";
    }
    if(this.viewType==this.mobilePageView){
      return "20%";
    }
  }
}
