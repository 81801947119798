import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { json } from 'd3';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public _constantData: any;

  constructor(private http: HttpClient) {
    this._constantData = JSON.parse(localStorage.getItem('constantData'));
  }

  navigationEvent = new EventEmitter<string>();

  public getJSON(jsonFileName) {
    this.http.get('./assets/' + jsonFileName + '.json').subscribe((data) => {
      this._constantData = data;
      localStorage.setItem('constantData', JSON.stringify(data));
    });
  }

  public setListDataToLocalStorage(
    data: string,
    dataKeyName: string,
    listKeyName: string
  ) {
    let listObject = JSON.parse(localStorage.getItem(listKeyName));
    if (listObject) {
      const existingIndex = listObject.findIndex((item: any) =>
        item.hasOwnProperty(dataKeyName)
      );

      if (existingIndex !== -1) {
        listObject.splice(existingIndex, 1); // Remove the existing object with matching dataKeyName
      }
    } else {
      listObject = [];
    }

    listObject.push({ [dataKeyName]: data });
    localStorage.setItem(listKeyName, JSON.stringify(listObject));
  }

  public getListDataFromLocalStorage(
    dataKeyName: string,
    listKeyName: string,
    defaultValue
  ) {
    let listObject = JSON.parse(localStorage.getItem(listKeyName));

    return listObject && listObject.find((x) => x.hasOwnProperty(dataKeyName))
      ? listObject.find((x) => x.hasOwnProperty(dataKeyName))[dataKeyName] ?? ''
      : defaultValue;
  }
}
