import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Component({
  selector: 'app-reports-recurring',
  templateUrl: './reports-recurring.component.html',
  styleUrls: ['./reports-recurring.component.scss'],
})
export class ReportsRecurringComponent implements OnInit, OnChanges {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';

  @Input()
  transactionType: string;

  @Input()
  showReturn: boolean = true;

  @Input()
  searchFields: any;
  reportRecurring: any;

  color: ThemePalette = 'primary';
  diameter: number = 100;
  mode: ProgressSpinnerMode = 'determinate';
  strokeWidth: number = 15;

  constructor(
    private _toastrService: ToastrService,
    private reportsService: ReportsService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.GetTransactionStatusPercentage();
  }

  GetTransactionStatusPercentage() {
    this.isLoading = true;
    this.reportRecurring = null;

    this.searchFields.startDate = new Date(this.searchFields.startDate);
    this.searchFields.endDate = new Date(this.searchFields.endDate);
    this.searchFields.startDate?.setHours(12, 0, 0);
    this.searchFields.endDate?.setHours(12, 0, 0);

    this.reportsService
      .GetRecurringTransactions(
        getSafeIsoDateString(this.searchFields.startDate),
        getSafeIsoDateString(this.searchFields.endDate)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.reportRecurring = data.data;
          }
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
