import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingService } from 'src/app/featureModules/services/billing-details.service';
import { BillingInvoiceStats } from 'src/app/sharedModules/models/billing-filter-model';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  isLoading = false;
  loadMessage: string = 'Please wait';
  data:any;
  billingInvoiceStats:BillingInvoiceStats;
  
  constructor(private router: Router,private _billingService: BillingService) {}

  ngOnInit(): void {
    this.billingInvoiceStats=new BillingInvoiceStats();
    
  }
  navigatetoprofile() {
    this.router.navigate(['billing/billing-list']);
  }
  navigatetoBillingProfile() {
    this.router.navigate(['billing/billing-profile']);
  }
  
}
