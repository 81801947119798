import { AbstractControl } from '@angular/forms';
import { required, prop, maxLength, pattern, minLength } from '@rxweb/reactive-form-validators';

export class AddCustomerCardDetailsModel {
  @required()
  customerId: string;

  @prop()
  customerCardId: any;

  @prop()
  @required()
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Number': /^[0-9]*$/ } })
  @maxLength({ value: 19 })
  @minLength({ value: 13 })
  cardNumber: string;

  @required()
  @maxLength({ value: 100 })
  @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^[a-zA-Z.\-\s]*$/ } })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  nameOnCard: string;

  @prop()
  @required()
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Number': /^[0-9]*$/ } })
  @maxLength({ value: 2 })
  @minLength({ value: 2 })
  expMonth: string;

  @prop()
  @required()
  expYear: string;

  @prop()
  @maxLength({ value: 900 })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Alpha_Numbers': /^[a-zA-Z0-9 ]*$/ } })
  address: string;

  @prop()
  @maxLength({ value: 30 })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  city: string;

  @prop()
  @maxLength({ value: 3 })
  state: string;

  @prop()
  @maxLength({ value: 10 })
  @pattern({ expression: { 'Alpha_Numbers': /^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/ }, message :'Enter valid postal code (e.g. 12345, 12345-6789, A1B 2C3)' })
  zip: string;

  @prop()
  status: boolean = true;

  @prop()
  isPrimary: boolean = true;
}
