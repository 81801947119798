import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class JwtResponseInterceptor implements HttpInterceptor {
  constructor(
    private jwtHelper: JwtHelperService,
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          // debugger;
          // Check JWT for allowed origin value
          const jwtTokenFromResponse = event?.body?.data?.accessToken;
          const jwtToken = localStorage.getItem('accessToken'); // Replace 'jwt' with your actual JWT token key
          // const jwtToken = event.body.data.accessToken; // Replace 'jwt' with your actual JWT token key
          const allowedOrigin = this.getAllowedOriginFromJWT(jwtTokenFromResponse ? jwtTokenFromResponse : jwtToken); // Implement this method to extract allowed origin from JWT
          //console.log(allowedOrigin);
          // If allowed origin exists in JWT, add X-Frame-Options header
          // if (allowedOrigin) {
          //   const clonedResponse = event.clone({
          //     headers: event.headers.set('X-Frame-Options', `ALLOW-FROM ${allowedOrigin}`)
          //   });
          //   console.log(clonedResponse);
          //   return clonedResponse;
          // }
          // else{
          //   const clonedResponse = event.clone({
          //     headers: event.headers.set('X-Frame-Options', 'DENY')
          //   });
          //   console.log(clonedResponse);

          //   return clonedResponse;
          // }
        }
        return event;
      })
    );
  }

  // Implement this method to extract allowed origin from JWT
  private getAllowedOriginFromJWT(token: string): string {
    // Parse JWT token and extract allowed origin value
    // Replace this implementation with your actual logic
    // For example, if JWT is a JSON Web Token, decode it and extract the allowed origin value
    // Return the allowed origin value if found, otherwise return null
    var accessToken = this.jwtHelper.decodeToken(token);
    return accessToken?.allowedOrigins;
  }
}
