<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<mat-toolbar class="header-toolbar" [style.--active-fontFamily]="pageSettingModel?.themeFont"
  [style.--active-bg-color]="pageSettingModel?.themeColor">
  <div class="w-100" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxFlex="33.3%" fxFlex.xs="100%" fxLayoutAlign="center start"
      fxLayoutAlign.xs="stretch start">
      <mat-label class="headerBackButton" [style.--active-text-color]="pageSettingModel?.themeTextColor"
        (click)="navigateToOrderSection()">
        <button mat-icon-button>
          <mat-icon>arrow_back_ios</mat-icon>
        </button>
        Order Section
      </mat-label>
    </div>

    <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxFlex="33.3%" fxFlex.xs="100%" fxLayoutAlign="center center">
      <img class="company-logo" [src]="pageSettingModel?.logo" alt="company logo" />
    </div>
    <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxFlex="33.3%" fxFlex.xs="0%" fxLayoutAlign="center start">
    </div>
  </div>
</mat-toolbar>

<div class="order-container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="start stretch"
  [style.--active-fontFamily]="pageSettingModel?.themeFont">
  <div fxLayout="column" fxFlex="49%" fxLayoutAlign.sm="start stretch" fxLayoutAlign="start stretch">
    <app-order-details [invoiceNumber]="invoiceNumber" [amount]="amount" [totalAmount]="
        paymentMethod == 'Card'
          ? cardDetailsFormGroup?.value?.totalAmount ?? this.amount
          : achDetailsFormGroup?.value?.totalAmount ?? this.amount
      " [description]="widgetInfo?.description ?? ''"
      [surchargeFees]="cardDetailsFormGroup?.value?.surchargeAmount ?? 0"
      [surchargePercent]="cardDetailsFormGroup?.value?.surchargePercent ?? 0" [agreesToSurcharge]="
        cardDetailsFormGroup?.value?.agreesToSurcharge ?? false
      " [convenienceFees]="achDetailsFormGroup?.value?.convenienceFees ?? 0" [agreesToConvenienceFees]="
        achDetailsFormGroup?.value?.agreesToConvenienceFees ?? false
      " [pageSettingModel]="pageSettingModel"></app-order-details>
    <br />

    <app-payment-details [uniqueToken]="token" [amount]="amount" [cardDetailsFormGroup]="cardDetailsFormGroup"
      [achDetailsFormGroup]="achDetailsFormGroup" (showLoaderEvent)="getLoaderEvent($event)"
      (cardDetailsFormGroupChange)="getCardDetailsFormGroup($event)"
      (achDetailsFormGroupChange)="getAchDetailsFormGroup($event)" (paymentMethodEvent)="getPaymentMethod($event)"
      [pageSettingModel]="pageSettingModel" [isRecurringDisabled]="isRecurringDisabled"></app-payment-details>
    <br />
  </div>

  <div fxLayout="column" fxFlex="49%" fxLayoutAlign.sm="start stretch" fxLayoutAlign="space-between stretch">
    <app-billing-details [widgetInfo]="widgetInfo" [billingDetailsFormGroup]="billingDetailsFormGroup"
      (billingDetailsFormGroupChange)="billingDetailsFormGroupChange($event)"
      [pageSettingModel]="pageSettingModel"></app-billing-details>
  </div>
</div>

<div class="order-container-footer" fxLayout="row" fxLayout.xs="column-reverse" fxLayoutGap="7.5%"
  fxLayoutAlign="center center" [style.--active-fontFamily]="pageSettingModel?.themeFont">


  <div fxLayout="column">
    <label style="text-align:center"> Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
  </div>

  <div fxLayout="column">
    <img  class="securePayment" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
  </div>

  <div fxLayout="column">
    <div fxLayout="row">
      <div fxLayout="column">
        <button mat-button class="secondaryBtn button-line-height"
          [style.--active-bg-color]="pageSettingModel?.themeColor" (click)="onCancelClick()">
          Cancel
        </button>
      </div>

      <div fxLayout="column">
        <button [disabled]="!pageSettingModel.isPaymentOptionAch && !pageSettingModel.isPaymentOptionCard" mat-flat-button class="primaryBtn button-line-height" (click)="makePayment()"
          [style.--active-text-color]="pageSettingModel?.themeTextColor"
          [style.--active-bg-color]="pageSettingModel?.themeColor">
          Make Payment
        </button>
      </div>
    </div>
  </div>
</div>