import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TicketService } from 'src/app/featureModules/services/ticket.service';
import { SubmitReviewDialogComponent } from 'src/app/sharedModules/components/dialogs/submit-review-dialog/submit-review-dialog.component';
import { SubmitReviewModel } from 'src/app/sharedModules/models/submit-review.model';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {

  loadMessage: string = 'Please wait..';
  isLoading: boolean =false;

  ticketId :any ;
  submitReviewModel :SubmitReviewModel
  userRole : any;


  constructor(private _ticketService: TicketService,
    private _toastrService: ToastrService,
    private dialog: MatDialog,
    private router: Router) { 

      var userProfile = JSON.parse(localStorage.getItem('userProfile'));
      this.userRole = userProfile['role'];
    }

  ngOnInit(): void {
    this.submitReviewModel = new SubmitReviewModel();
  }

  createTicket(){
    this.router.navigate(['ticket/create-ticket']);
  }

  submitReview(){
    const dialogRef = this.dialog.open(SubmitReviewDialogComponent,
      {        
        height: '370px',
        width: '634px',
      }
    );    
    dialogRef.afterClosed()
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          this._ticketService
            .submitReview(this.ticketId,this.submitReviewModel)
            .subscribe((data) => {
              this.isLoading = false;
              if (data.data != null) {
                this._toastrService.success('Invoice Sent Successfully!');
              }
            });
        }
      });
  }
  }

