import { maxLength, pattern, prop } from "@rxweb/reactive-form-validators";

export class paymentLinkAdvanceFilterModel {
    @prop()
    status: string = 'All';

    @prop()
    type: string = 'All';

    @prop()
    linkExpiry: string = 'All';

    @prop()
    fromAmount: number;

    @prop()
    toAmount: number;

}
