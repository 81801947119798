<div [class]="previewWiseStyle.inputControlClass" [style.height]="previewWiseStyle.divPreviewHeight" class="container-success-page">
  <mat-spinner class="loadSpinner" *ngIf="changesIsLoading"  color="accent" diameter="50"></mat-spinner>
  <!-- <mat-toolbar [style.height]="previewWiseStyle.headerToolbarHeight" [class]="deviceWiseStyle.headerToolbarClass"
    [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
    [style.--active-color-textColor]="this.formData?.customisationTabForm?.themeTextColor ?? '#1e4ed8'"
    class="header-toolbar">
    <div class="w-100" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="center center">
      <img [style.height]="previewWiseStyle.logoImageHeight" [src]="getLogoPath(this.formData)" alt="logo" class="header-success-logo" />
    </div>
  </mat-toolbar> -->

  <div [class]="deviceWiseStyle.orderSuccessContainerClass" class="order-success-container"
    [style.--active-color-fontFamily]="this.formData?.customisationTabForm?.themeFont" fxLayout="row"
    fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div class="w-100" fxLayout="column" fxLayoutGap="4%" fxLayoutAlign="start stretch">
      <div fxLayout="row" [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
       class="payment-success-logo-dv">
        <img [style.height]="previewWiseStyle.logoImageHeight" [class]="deviceWiseStyle.paymentSuccessLogoClass" class="payment-success-logo" [src]="getLogoPath(this.formData)" alt="image" />
      </div>

      <div class="margin-success-text" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%"
        fxLayoutAlign="center center">
        <!-- <div fxLayout="column">
          <img [style.height]="previewWiseStyle.successImageSize" [style.width]="previewWiseStyle.successImageSize"
            class="successIcons" [src]="paymentIcon" alt="sucess-icon" />
        </div> -->
        <div fxLayout="column">
          <div [style.font-size]="previewWiseStyle.headerLableFontSize" [class]="paymentClass">
            {{ paymentMessage }}
          </div>
        </div>
      </div>

      <div *ngIf="isPaymentSuccessful">
        <mat-divider class="margin-mat-start"></mat-divider>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
          *ngIf="validateField(this.formData?.configurationTabForm?.isInvoiceNumberView)">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Confirmation Number
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              {{ invoiceNumber }}
            </mat-label>
          </div>
        </div>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName"> Payment Method
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              {{ paymentMethod }}
            </mat-label>
          </div>
        </div>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
          *ngIf="transactionId">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName"> Transaction Id
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              {{ transactionId }}
            </mat-label>
          </div>
        </div>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Transaction Date
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              {{ transactionDate | date : "dd MMM YYYY hh:mm a" }}
            </mat-label>
          </div>
        </div>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Amount
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              ${{ amount | number : "1.2-2" }}
            </mat-label>
          </div>
        </div>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
          *ngIf="this.agreesToSurcharge && this.surchargePercentage !== 0">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Surcharge Fees
              ({{ surchargePercentage ?? 0 }}%)</mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              ${{ surchargeFee ?? 0 | number : "1.2-2" }}
            </mat-label>
          </div>
        </div>

        <ng-container *ngIf="availConvenienceFee">
        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
          *ngIf="agreesToConvenienceFees">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Convenience
              Fees</mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="showDetailsValue">
              ${{ convenienceFees ?? 0 | number : "1.2-2" }}
            </mat-label>
          </div>
        </div>
      </ng-container>

        <mat-divider class="margin-mat-end"></mat-divider>

        <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" class="showDetailsName">Total
            </mat-label>
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <mat-label [style.font-size]="previewWiseStyle.orderValueFontSize" class="font-bold showDetailsValue">
              ${{ totalAmount ?? 0 | number : "1.2-2" }}
            </mat-label>
          </div>
        </div>

        <div class="mar-b-0" fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
          <span [class]="deviceWiseStyle.circleDrawLeftClass" class="circle-draw circle-draw-left"></span>
          <hr class="circle-mat-divder" />
          <span [class]="deviceWiseStyle.circleDrawRightClass" class="circle-draw circle-draw-right"></span>
        </div>

        <div *ngIf="validateField(this.formData?.configurationTabForm?.isDescriptionView)" fxLayout="row"
          fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" class="margin-details-text">
          <div [style.font-size]="previewWiseStyle.orderValueFontSize" class="description-text">{{ description }}</div>
        </div>
      </div>

      <div fxLayout="row" fxFlex="50%" fxLayoutWrap class="payment-footer-dv" [style.height]="previewWiseStyle.footerDivHeight" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center center" class="payment-footer-div-col">
        <img  [style.height]="previewWiseStyle.logoImageHeight" class="payment-success-logo safetySecurity-logo" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
            <label style="text-align:center"
            [style.font-size]="previewWiseStyle.buttonTextFontSize"
            [style.font-family]="this.formData?.customisationTabForm?.themeFont"> Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
        </div>
      </div>
      
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <br />
  </div>
 
</div>