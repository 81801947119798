import { maxLength, pattern, prop } from "@rxweb/reactive-form-validators";

export class ViewInvoiceFilterModel {
  @prop()
  statusAll: boolean = true;

  @prop()
  statusPaid: boolean = true;

  @prop()
  statusUnpaid: boolean = true;

  @prop()
  statusSent: boolean = true;

  @prop()
  statusOverdue: boolean = true;

  @prop()
  statusPending: boolean = true;

  @prop()
  createdStartDate: string;

  @prop()
  createdEndDate: string;

  @prop()
  periodStartDate: string;

  @prop()
  periodEndDate: string;

  @prop()
  dueStartDate: string;

  @prop()
  dueEndDate: string;

  @prop()
  amountRangeFrom: string;

  @prop()
  amountRangeTo: string;
}
