import { Injectable, EventEmitter, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PaymentErrorConstants } from '../constants/payment-error.const';

@Injectable({
  providedIn: 'root',
})
export class PaymentWidgetCardService extends CrudService<any, any> {
  errorMessage: string = PaymentErrorConstants.InvalidPaymentLink;

  @Output()
  entryCodeEmitter = new EventEmitter<any>();

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_PAYMENTWIDGET_CARDS}`);
  }

  LookUpBin(model: any): Observable<any> {
    return this._http.post<any>(this._base + 'payment/lookUpBin', model);
  }

  GetPaymentPageInformation(token: any): Observable<any> {
    return this._http.get(
      `${this._base + 'payment/widgetInformation?uniqueToken=' + token}`
    );
  }

  Sale(model: any): Observable<any> {
    return this._http.post<any>(this._base + 'payment/sale', model);
  }

  getAchCustomerTypeSelection(isAch: boolean, isCustomer: boolean) : any {
    return {"isAch" : isAch, "isCustomer" : isCustomer};
  }
}
