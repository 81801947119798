import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HostedPaymentCustomizationService } from 'src/app/featureModules/services/hosted-payment-customization.service';

@Component({
  selector: 'app-design-customisation',
  templateUrl: './design-customisation.component.html',
  styleUrls: ['./design-customisation.component.scss'],
})
export class DesignCustomisationComponent implements OnInit {
  @Input()
  hostedPaymentpageFormGroup: FormGroup;
  @ViewChild('fileUpload') fileUpload: ElementRef;
  isLoading: boolean;
  loadMessage: string = 'Please wait..';
  fileName: string;
  fileAttr: string;
  uploadedFilelist: any[] = [];
  delete_const = 'Delete1';
  progress = 0;
  fileDeleteSubscription$: Subscription;
  fileUploadSubscription$: Subscription;

  currentFile?: File;
  message = '';

  color: string = '#FFFFFF';
  themeTextColor: string;
  themeColor: string;

  fontFamilyArray = [
    { value: 'Arial', key: 'Arial' },
    { value: 'Arial Black', key: 'Arial Black' },
    { value: 'Times New Roman', key: 'Times New Roman' },
    { value: 'Cambria', key: 'Cambria' },
    { value: 'Comic Sans MS', key: 'Comic Sans MS' },
    { value: 'Courier New', key: 'Courier New' },
    { value: 'Georgia', key: 'Georgia' },
    { value: 'Garamond', key: 'Garamond' },
    { value: 'Segoe UI', key: 'Segoe UI' },
    { value: 'Lora', key: 'Lora' },
    { value: 'Tahoma', key: 'Tahoma' },
    { value: 'Century Schoolbook', key: 'Century Schoolbook' },
    { value: 'Verdana', key: 'Verdana' },
    { value: 'Kalam', key: 'Kalam' },
    { value: 'Impact', key: 'Impact' },
    { value: 'ROBOTO', key: 'Roboto' },
    { value: 'Sans-serif', key: 'sans-serif' },
    { value: 'Hind', key: 'hind' },
  ];

  imgheight: number;
  imgwidth: number;
  result: boolean;

  constructor(
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService,
    private _hostedPaymentCustomService: HostedPaymentCustomizationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if(this.hostedPaymentpageFormGroup.value['customisationTabForm'].logo != null)
      {
        this.uplodadFileAtInitial(
          this.hostedPaymentpageFormGroup.value['customisationTabForm'].logo
          );
      }
  }

  uplodadFileAtInitial(filepath : any){
    var fileName = filepath.split('/')[2];
    this.uploadedFilelist.push({
      name: fileName,
      path: filepath,
      size: 2301,
    });
    this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['logo'].setValue(filepath);
  }

   getImageFileSizeValidated(fileUpload: any) {
    const URL = window.URL || window.webkitURL;
    const Img = new Image();

    const filesToUpload = fileUpload.target.files;
    Img.src = URL.createObjectURL(filesToUpload[0]);

    return new Promise ( (res, rej) => {
    Img.onload = (e: any) => {
      this.imgheight = e.target.height;
      this.imgwidth = e.target.width;
      if (this.imgheight > 134 || this.imgwidth > 430) {
        this._toastrService.warning(
          'Image with W430*H134 diamensions can be uploaded!'
        );
        res(true);
      }
      res(false);
    };
  });    
  }

  async onFileSelected(selectedFile: any) {  
    await this.getImageFileSizeValidated(selectedFile).then((data) => {
      if (data) {
        return;
      }
      if (this.uploadedFilelist.length == 1) {
        this._toastrService.warning('You can upload only 1 attachments');
        return;
      }
      let isFileAlreadyUploaded = false;
      let file: File = selectedFile.target.files[0];
      if (file.size > 5000000) {
        this._toastrService.warning('Max 5MB file can be uploaded');
        return;
      }
      this.uploadedFilelist.forEach((item: any) => {
        if (item.name == file.name) {
          this._toastrService.warning('File is already uploaded');
          isFileAlreadyUploaded = true;
        }
      });
      if (isFileAlreadyUploaded) return;
  
      this.fileUploadSubscription$ = this._hostedPaymentCustomService
        .uploadFile(file)
        .subscribe(
          (response) => {
            if (response.code == 201) {
              this.uploadedFilelist.push({
                name: file.name,
                path: response.data,
                size: file.size,
              });
              this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['logo'].setValue(response.data);
              this._toastrService.success('Attachment uploaded successfully!');
              this.clearSelectedFile();
              console.log('');
            } else {
              this._toastrService.warning(response.errorMessage);
            }
            this.isLoading = false;
          },
          (error) => {
            this.isLoading = false;
          }
        );
    });
  }

  onFileDeleted(file: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: 'DELETE',
        successMessage: 'Are you sure you want to delete this Attachment?',
        subSuccessMessage:
          'Clicking on delete button will remove attachment permanently',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {        
        this.isLoading = true;
        if(file.name.includes('Default') || file.path.includes('/whitelabelsettings_logos')){
                let index = this.uploadedFilelist.findIndex(
                  (x) => x.name == file.name
                );
                this.uploadedFilelist.splice(index, 1);
                this._toastrService.success('Attachment removed successfully!');
                this.clearSelectedFile();
                this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['logo'].setValue(null);
        }      
        else{
        this.fileDeleteSubscription$ = this._hostedPaymentCustomService
          .deleteFile(file.path)
          .subscribe(
            (data) => {
              if (data.code == 201) {
                let index = this.uploadedFilelist.findIndex(
                  (x) => x.name == file.name
                );
                this.uploadedFilelist.splice(index, 1);
                this._toastrService.success('Attachment removed successfully!');
                this.clearSelectedFile();
                this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['logo'].setValue(null);
              } else {
                this._toastrService.warning(data.errorMessage);
              }
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    }
    });
  }

  clearSelectedFile() {
    this.fileUpload.nativeElement.value = null;
  }

  public onThemeColorChange(color: string): void {
    this.hostedPaymentpageFormGroup.controls['customisationTabForm'][
      'controls'
    ]['themeColor'].setValue(color.toUpperCase());
  }

  public onTextColorChange(color: string): void {
    this.hostedPaymentpageFormGroup.controls['customisationTabForm'][
      'controls'
    ]['themeTextColor'].setValue(color.toUpperCase());
  }

  ngOnDestroy(): void {
    this.uploadedFilelist = [];
    if (this.fileUploadSubscription$) {
      this.fileUploadSubscription$.unsubscribe();
      this.fileUploadSubscription$ = null;
    }
    if (this.fileDeleteSubscription$) {
      this.fileDeleteSubscription$.unsubscribe();
      this.fileDeleteSubscription$ = null;
    }
  }
}
