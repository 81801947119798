<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start"
  style="padding-left: 1%" >


  <mat-tab-group fxFlex="100%" animationDuration="0ms">

    <mat-tab label="DESIGN">
      <app-design-tab [paymentLinkFormGroup]="paymentLinkFormGroup"></app-design-tab>
    </mat-tab>

    <mat-tab label="SETTING">
      <app-setting-tab [paymentLinkFormGroup]="paymentLinkFormGroup"></app-setting-tab>
    </mat-tab>

    <mat-tab label="PRICING">
      <app-pricing-tab [paymentLinkFormGroup]="paymentLinkFormGroup"></app-pricing-tab>
    </mat-tab>
    
  </mat-tab-group>
</div>
