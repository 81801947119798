import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  InternalAchServiceProxy,
  UpcomingHolidaysRequestModel,
} from 'src/app/sharedModules/services/ach-transactions/ach-transactions.proxies';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class AchTransactionsService {
  constructor(
    private achTransactionService: InternalAchServiceProxy,
    private notifyService: NotificationService,
    private router: Router,
    private _toastrService: ToastrService
  ) {}

  getCustomerAchSummaryTransactions(
    customerId: string,
    customerNameFilter: string,
    customerType: any,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    return this.achTransactionService.getCustomerAchSummaryTransactions(
      customerId,
      customerNameFilter,
      customerType,
      sortBy,
      sortDirection,
      page,
      pageSize
    );
  }
  getCustomerAchTransaction(
    customerId: string,
    searchString: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string,
    onlyRecurring: boolean
  ) {
    return this.achTransactionService.getCustomerAchTransactions(
      customerId,
      searchString,
      sortBy,
      sortDirection,
      page,
      pageSize,
      advanceFilters,
      onlyRecurring
    );
  }
  CreateAchTransaction(
    model: any,
    dialogRef: MatDialogRef<any, any>,
    customerId: any,
    previousUrl: string,
    selectedTransactionTabIndex: number,
    transactionIndex: number,
    tabIndex: number
  ) {
    this.achTransactionService.createNewAchTransaction(model).subscribe(
      (res) => {
        if (res.code == 200) {
          this._toastrService.success('Transaction Details Added Successfully');
          dialogRef.close();
          this.router.navigateByUrl(previousUrl, {
            state: {
              selectedTransactionTabIndex: selectedTransactionTabIndex,
              transactionIndex: transactionIndex,
              tabIndex: tabIndex,
            },
          });
        } else if (res.code == 400) {
          this._toastrService.error(res.errorMessage);
        }
      },

      (error) => {
        dialogRef.close();
        if (error.status == 401) {
          this.notifyService.showError(
            'Your current session times out. Please login again! '
          );
          localStorage.removeItem('userProfile');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('validUser');       
          localStorage.removeItem('callCompanyList');

          JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
            ? window.location.replace('csr')
            : window.location.replace('/');
        } else {
          this._toastrService.error(error.errorMessage);
        }
        // this._toastrService.error("Can not submit data");
      }
    );
  }

  GetCustomerAchReturnedRate(customerId: any) {
    return this.achTransactionService.getCustomerAchReturnedRate(customerId);
  }

  GetClientTimeZoneSameDay() {
    return this.achTransactionService.getClientTimeZoneSameDay();
  }

  GetUpcomingHolidays(calendarYear: number) {
    return this.achTransactionService.getUpcomingHolidays(
      new UpcomingHolidaysRequestModel({
        calendarYear: calendarYear,
        country: null,
        includeWeekends: false,
      })
    );
  }

  DeleteAchRecurringTransaction(trackingId) {
    return this.achTransactionService.deleteAchRecurringTransaction(trackingId);
  }
}
