<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div class="backHeader">
  <mat-label (click)="navigateToDashboard()" style="cursor: pointer">
    <button mat-icon-button>
      <mat-icon>arrow_back</mat-icon>
    </button>
    Dashboard
  </mat-label>
</div>
<div class="page-header-customer-details">
  <div fxLayout="column" fxLayoutGap="16px">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="30px"
      fxLayoutAlign="start stretch"
    >
      <div fxLayout="column">
        <mat-label class="page-header-userNameCircle">
          {{ getNameInitials(userModel?.firstName, userModel?.lastName) }}
        </mat-label>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerName">
            {{ userModel?.firstName }} {{ userModel?.lastName }}
          </mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerEmail">
            Email/Unique id: &nbsp;&nbsp;
          </mat-label>
          <mat-chip-list>
            <mat-chip class="page-header-customerEmailChip">
              {{ userModel?.email }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <span class="spacer"></span>
      <div *ngIf="isEditEnabled()" fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="onEditClick()"
          >
            EDIT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="16px"
  fxLayoutAlign="start stretch"
  style="padding-left: 1%"
>
  <mat-card>
    <div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-label class="section-header">User Profile</mat-label>
      </div>
      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> First Name: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.firstName }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Last Name: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.lastName }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Email: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.email }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Job Title: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.jobTitle }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Role: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.role }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Contact Number: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ userModel?.phone }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>
    </div>
  </mat-card>
</div>
