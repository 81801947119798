 import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
 providedIn: 'root'
})
export class SharedService {

    newUser: Subject<object>;
    users: object;
    constructor(){
        this.newUser = new Subject<object>();
        this.users = new Object();
    }
}