import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/sharedModules/services/local-storage/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { LocationStrategy } from '@angular/common';
import { PageSettingModel } from '../../models/payment-page-customization.model';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  invoiceNumber: any;
  paymentMethod: any;
  amount: number;
  totalAmount: number;
  transactionId: any;
  transactionDate: any;
  agreesToSurcharge: boolean = false;
  surchargeFee: any;
  surchargePercentage: any;
  description: any;
  agreesToConvenienceFees: boolean = false;
  convenienceFees: any;
  logotext : string;

  isPaymentSuccessful: boolean = false;
  paymentMessage: any;
  paymentIcon: any;
  paymentClass: any;

  pageSettingModel: PageSettingModel;

  stateData: any;
  token: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    private _tostNotification: ToastrService,
    private location: LocationStrategy
  ) {
    // preventing back button
    history.pushState(null, null, window.location.href);
    history.replaceState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
      history.replaceState(null, null, window.location.href);
    });

    const navigation = this.router.getCurrentNavigation();
    this.stateData = navigation?.extras?.state;

    this.invoiceNumber = this.stateData?.invoiceNumber;
    this.amount = this.stateData?.amount;
    this.totalAmount = this.stateData?.totalAmount;
    this.paymentMethod = this.stateData?.paymentMethod;
    this.transactionId = this.stateData?.transactionId;
    this.transactionDate = this.stateData?.transactionDate;
    this.agreesToSurcharge = this.stateData?.agreesToSurcharge;
    this.surchargeFee = this.stateData?.surchargeFee;
    this.surchargePercentage = this.stateData?.surchargePercentage;
    this.description = this.stateData?.description;
    this.agreesToConvenienceFees = this.stateData?.agreesToConvenienceFees;
    this.convenienceFees = this.stateData?.convenienceFees;
    this.paymentMessage = this.stateData?.paymentMessage;
    this.logotext = this.stateData?.logotext;
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    if (this.token && !this.stateData) {
      this.router.navigate(['/payment/' + this.token]);
    }

    this.getThemeFromLocalStorage();
    this.isPaymentSuccessful =
      this.stateData?.code == '200' || this.stateData?.code == '202';

    if (this.isPaymentSuccessful) {
      this.paymentIcon = 'assets/payment-widget-icons/payment-success.png';
      this.paymentClass = 'success-payemnt-text';
      this._tostNotification.success('Payment Receipt Sent by Email');
    } else {
      this.paymentMessage = 'Oops! your payment failed, please try again.';
      this.paymentIcon = 'assets/payment-widget-icons/payment-failed.png';
      this.paymentClass = 'failed-payemnt-text';
    }
  }

  getThemeFromLocalStorage() {
    let key = this.token;
    this.pageSettingModel = new PageSettingModel(
      this.localStorageService.getPaymentPageCustomizationBasedObjectByKey(key));
  }

  displayFields(conditionType: string): boolean {
    if (conditionType.toLowerCase() === "show") {
      return true;
    }
    if (conditionType.toLowerCase() === "hide") {
      return false
    }
  }
}
