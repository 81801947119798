<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px">
  <div fxFlex="100%" #canvasContainer_Page1_0 id="canvasContainer_Page1_0">
    <app-calender-slider-card
      (calenderSlideEvent)="onInputChange($event)"
    ></app-calender-slider-card>
  </div>
</div>
<br />

<div fxLayout="row" fxLayout.xs="column">
  <div
    #canvasContainer_Page1_1
    id="canvasContainer_Page1_1"
    fxLayout="column"
    fxFlex="100%"
  >
    <app-cca-balance-card
      [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
      [accountType]="this.ibvPdfReport.accountType"
      [startDate]="this.ibvPdfReport.startDate"
      [endDate]="this.ibvPdfReport.endDate"
      [(loadBalanceCard)]="loadBalanceCard"
    ></app-cca-balance-card>
  </div>
</div>
<br />

<div fxLayout="row" fxLayout.xs="column">
  <div fxLayout="column" fxFlex="100%">
    <app-report-transactions-card
      [ibvBankVerificationId]="this.ibvPdfReport.ibvBankVerificationId"
      [accountType]="this.ibvPdfReport.accountType"
      [startDate]="this.ibvPdfReport.startDate"
      [endDate]="this.ibvPdfReport.endDate"
      [amountLabel]="'Withdrawal Amount'"
      [transactionType]="'Debit'"
      [(loadTransactionCard)]="loadTransactionCard"
    ></app-report-transactions-card>
  </div>
</div>
