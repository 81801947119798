import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IbvPdfReport } from 'src/app/sharedModules/models/ibv-pdf-report.details.model';
import FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class InstantVerifyReportsService extends CrudService<any, any> {
  
  FinzeoGradeCardAndScoreChange = new EventEmitter();
  BankQuickViewDataChange = new EventEmitter();
  EmployerVerificationChange = new EventEmitter();
  FlagAndLoanDataChange = new EventEmitter();
  printIbaReport = new EventEmitter();
  constructor(
    protected override _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    super(_http, `${environment.API_BASE_URL_IBV}IBVReportGeneration`);
  }

  GetVerifiedAccountTypesForReport(bankVerificationId: any): Observable<any> {
    return this._http.get<any>(
      this._base + '/GetVerifiedAccountTypesForReport/' + bankVerificationId
    );
  }

  GetAccountDetailsForReport(
    ibvBankVerificationId: any,
    accountType: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/GetAccountDetailsForReport', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
    });
  }

  BankQuickViewDetails(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/BankQuickViewDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  FinZeoGradeDetails(
    ibvBankVerificationId: any,
    accountType: string
  ): Observable<any> {
    return this._http.post<any>(this._base + '/FinZeoGradeDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
    });
  }

  FlagsDetails(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/FlagsDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  PotentialPaymentAmountDetails(
    ibvBankVerificationId: any,
    accountType: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/PotentialPaymentAmountDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
    });
  }

  EmployerVerificationDetails(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/EmployerVerificationDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  DtiQuickViewDetails(
    ibvBankVerificationId: any,
    accountType: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/DtiQuickViewDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
    });
  }

  NsfVsPaidTransactionDetails(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/NsfVsPaidTransactionDetails', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  GetBalanceDetailsForReport(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/GetBalanceDetailsForReport', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  GetRecurringDebitsList(
    ibvBankVerificationId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number

  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetRecurringDebitsList',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        pageSize: pageSize
      }
    );
  }

  GetAllTransactionBasedOnTrnType(
    ibvBankVerificationId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    transactionType: any,
    searchString: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetAllTransactionBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType,
        filterText: searchString,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        pageSize: pageSize
      }
    );
  }

  GetAllCategoryTransactionListBasedOnTrnType(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any,
    transactionType?: string
  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetAllCategoryTransactionListBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType,
      }
    );
  }

  GetSingleCategoryTransactionListBasedOnTrnType(
    ibvBankVerificationId: any,
    categoryId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    transactionType: string,
    searchString: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetSingleCategoryTransactionListBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        categoryId: categoryId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType,
        filterText: searchString,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        pageSize: pageSize,
      }
    );
  }

  GetTransactionDetailsForPdf(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/GetTransactionDetailsForPdf', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  GetRecurringTransactionsBasedOnTrnType(
    ibvBankVerificationId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    transactionType: string,    
    searchString: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetRecurringTransactionsBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType,
        filterText: searchString,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        pageSize: pageSize,        
      }
    );
  }

  CashFlowAnalysisData(
    ibvBankVerificationId: any,
    accountType: any,
    startDate: any,
    endDate: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/GetCashFlowAnalysis', {
      ibvBankVerificationId: ibvBankVerificationId,
      accountType: accountType,
      startDate: startDate,
      endDate: endDate,
    });
  }

  GetAllTransactionSummaryBasedOnTrnType(
    ibvBankVerificationId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    transactionType: any
  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetAllTransactionSummaryBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType
      }
    );
  }

  GetNonRecurringTransactionBasedOnTrnType(
    ibvBankVerificationId: any,
    accountType: string,
    startDate: any,
    endDate: any,
    transactionType: any,
    searchString: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
  ): Observable<any> {
    return this._http.post<any>(
      this._base + '/GetNonRecurringTransactionBasedOnTrnType',
      {
        ibvBankVerificationId: ibvBankVerificationId,
        accountType: accountType,
        startDate: startDate,
        endDate: endDate,
        transactionType: transactionType,
        filterText: searchString,
        sortBy: sortBy,
        sortDirection: sortDirection,
        page: page,
        pageSize: pageSize
      }
    );
  }
}
