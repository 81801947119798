import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import html2canvas from 'html2canvas';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  loadMessage: string = 'Please wait..';
  isLoading: boolean;

  period:string = "weekly";
  userRole : any;

  constructor(private router: Router,private _toastrService: ToastrService) {
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
   }

  ngOnInit(): void {
  }

  downloadReport() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isLoading = true;
    let fileName = "Invoice_Dashboard_Overall_Report";
    var page1 = document.getElementById("canvasContainer_Page1");
    var page2 = document.getElementById("canvasContainer_Page2");
    var page3 = document.getElementById("canvasContainer_Page3");

    let content = [];

    content.push({ text: "Invoice Dashboard Overall Report", style: "infoHeader" });
    content.push({
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: "#148F77",
        },
      ],
    });
    content.push("\n");

    html2canvas(page1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL("image/png", 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });

      content.push("\n");
      content.push({ text: "Y : Number of Invoices, X : Invoice Status", style: "defaultStyle1" });
      content.push({
       canvas: [
         {
          type: "line",
           x1: 0,
           y1: 2,
           x2: 515,
           y2: 2,
           lineWidth: 1,
           lineColor: "#919191",
         },
       ],
     });   
      content.push("\n");      
      content.push({ text: "Invoice Transaction Amount Chart ", style: "sumHeader" });
      content.push("\n");
      //content.push({ text: "\n", id: "breakPage_1" });

      //To capture Tabs canvas separately on separate page
      html2canvas(page2, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((canvasPage2) => {
        var imgWidth = 520;
        var imgHeight = (canvasPage2.height * 480) / canvasPage2.width;
  
        content.push({
          image: canvasPage2.toDataURL("image/png", 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });
        content.push({ text: "Y : Invoice Transaction Amount , X : Duration", style: "defaultStyle1" });
         content.push({
          canvas: [
            {
              type: "line",
              x1: 0,
              y1: 2,
              x2: 515,
              y2: 2,
              lineWidth: 1,
              lineColor: "#919191",
            },
          ],
        });
         content.push({ text: "\n", id: "breakPage_2" });

         
        

         html2canvas(page3, {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          scale: 2,
          allowTaint: true,
        }).then((canvasPage3) => {
          var imgWidth = 520;
          var imgHeight = (canvasPage3.height * imgWidth) / canvasPage3.width;
    
          content.push({
            image: canvasPage3.toDataURL("image/png", 0),
            margin: [0, 0, 0, 0],
            width: imgWidth,
            height: imgHeight,
          });

        generatedDashboardPDF(content, fileName, null);
        this.isLoading = false;
        this._toastrService.clear();
        this._toastrService.success('PDF downloaded successfully.');
      });
    });
   });
  }

  generateInvoice() {
    this.router.navigate(['invoices/generate-invoice']);
  }

}
