import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

@Component({
  selector: 'app-design-tab',
  templateUrl: './design-tab.component.html',
  styleUrls: ['./design-tab.component.scss']
})
export class DesignTabComponent implements OnInit {


  @Input()
  paymentLinkFormGroup : FormGroup;

  button:boolean=true;

  color:string ="#FFFFFF" ;
  borderColor:string ;
  buttonTextColor:string;
  buttonBgColor:string;
  linkTextColor:string;
  linkBgColor:string;

  radiusCount =[
    { value: 'none', key: 'none' },
    { value: '5px', key: '5px' },
    { value: '6px', key: '6px' },
    { value: '7px', key: '7px' },
    { value: '8px', key: '8px' },
    { value: '9px', key: '9px' },
    { value: '10px', key: '10px' },
    { value: '11px', key: '11px' },
    { value: '12px', key: '12px' },
    { value: '13px', key: '13px' },
    { value: '14px', key: '14px' },
    { value: '15px', key: '15px' },
    { value: '16px', key: '16px' },
    { value: '17px', key: '17px' },
    { value: '18px', key: '18px' },
  ];

  shadowType = [
    { value: 'ON', key: 'ON' },
    { value: 'OFF', key: 'OFF' },
  ];

  fontFamilyArray = [
    { value: 'Arial', key: 'Arial' },
    { value: 'Arial Black', key: 'Arial Black' },  
    { value: 'Times New Roman', key: 'Times New Roman' },
    { value: 'Cambria', key: 'Cambria' },
    { value: 'Comic Sans MS', key: 'Comic Sans MS' },
    { value: 'Courier New', key: 'Courier New' },
    { value: 'Georgia', key: 'Georgia' },
    { value: 'Garamond', key: 'Garamond' },
    { value: 'Segoe UI', key: 'Segoe UI' },
    { value: 'Lora', key: 'Lora' },
    { value: 'Tahoma', key: 'Tahoma' },
    { value: 'Century Schoolbook', key: 'Century Schoolbook' },
    { value: 'Verdana', key: 'Verdana' },
    { value: 'Kalam', key: 'Kalam' },
    { value: 'Impact', key: 'Impact' },
    { value: 'ROBOTO', key: 'Roboto' },
    { value: 'Sans-serif', key: 'sans-serif' },
    { value: 'Hind', key: 'hind' },
  ];

  fontStyleArray = [
    { value: 'Normal', key: 'normal' },
    { value: 'Italic', key: 'italic' },
    { value: 'Oblique', key: 'oblique' },    
  ];
  
  fontSizeArray = [
    { value: '8pt', key: '8px' },
    { value: '9pt', key: '9px' },
    { value: '10pt', key: '10px' },
    { value: '11pt', key: '11px' },
    { value: '12pt', key: '12px' },
    { value: '13pt', key: '13px' },
    { value: '14pt', key: '14px' },
    { value: '15pt', key: '15px' },
    { value: '16pt', key: '16px' },
    { value: '17pt', key: '17px' },
    { value: '18pt', key: '18px' },
    { value: '19pt', key: '24px' },
    { value: '20pt', key: '36px' }      
  ];
  
  constructor() { }

  ngOnInit(): void {
    this.onTypeChanged();
  }

  onTypeChanged(){    
    if(this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkType'].value == 'Button'){
      this.button = true;
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkTextColor'].setValue('#2848D3');
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkBgColor'].setValue('#FEFEFE');
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['target'].setValue('NewWindow');            
    }
    else{
      this.button = false;     
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonTextColor'].setValue('#F6F2F2'); 
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonBgColor'].setValue('#2658DD'); 
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderColor'].setValue('#FFFFFF'); 
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['shadow'].setValue('OFF'); 
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderRadius'].setValue('none'); 
    } 
  }

  public onButtonTextColorChange(color: string): void {    
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonTextColor'].setValue(color.toUpperCase());    
  }

  public onButtonBgColorChange(color: string): void {    
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonBgColor'].setValue(color.toUpperCase());    
  }

  public onBorderColorChange(color: string): void {    
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderColor'].setValue(color.toUpperCase());    
  }

  public onLinkTextColorChange(color: string): void {    
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkTextColor'].setValue(color.toUpperCase());    
  }

  public onLinkBgColorChange(color: string): void {    
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkBgColor'].setValue(color.toUpperCase());    
  }

}
