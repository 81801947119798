<mat-card class="cca-balance-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="cca-balance-card" fxLayout="column" fxFlex="33.33%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin: 2%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/creditCard/sectionIcons/credit.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap>
        <span class="section-header">Credit</span>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="16px"
      fxLayoutWrap
      style="margin: 2%"
    >
      <div fxLayout="column" fxFlex="100%" class="balance-row-white box-shadow">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Credit Limit
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.creditLimit ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Cash Advance Limit
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.cashAdvanceLimit ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Available Credit
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.availableCredit ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">Deposits</div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.summaryDeposits ?? 0 | number : "1.2-2" }}
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="cca-balance-card" fxLayout="column" fxFlex="33.33%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin: 2%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/creditCard/sectionIcons/balance.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap>
        <span class="section-header">Balance</span>
      </div>

      <!-- Commented as per discussion over email as no one confirmed about these CCA terms -->
      <!-- <span class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="center end" fxLayoutWrap>
        <span class="section-sub-header"
          >Points Redeemed :
          {{ balanceDetails?.pointsRedeemed ?? 0 | number: "1.2-2" }}</span
        >
      </div> -->
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="16px"
      fxLayoutWrap
      style="margin: 2%"
    >
      <div fxLayout="column" fxFlex="100%" class="balance-row-white box-shadow">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Statement Balance
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.statementBalance ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Outstanding Balance
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.outstanding ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Rewards Balance
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.currentRewardsBalance ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Withdrawals
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.summaryWithdrawals ?? 0 | number : "1.2-2" }}
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="cca-balance-card" fxLayout="column" fxFlex="33.33%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin: 2%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/creditCard/sectionIcons/payment.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap>
        <span class="section-header">Payment</span>
      </div>

      <!-- Commented as per discussion over email as no one confirmed about these CCA terms -->
      <!-- <span class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="center end" fxLayoutWrap>
        <span class="section-sub-header"
          >APR : {{ balanceDetails?.purchasesApr ?? 0 | number: "1.2-2" }}</span
        >
      </div> -->
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="16px"
      fxLayoutWrap
      style="margin: 2%"
    >
      <div fxLayout="column" fxFlex="100%" class="balance-row-white box-shadow">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Finance Charge
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.financeCharges ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
        >
          <div fxLayout="column" class="text-labels bold-style">
            Minimum Payment Due
          </div>
          <div fxLayout="column" class="text-labels normal-style alignment">
            ${{ balanceDetails?.minimumPaymentDue ?? 0 | number : "1.2-2" }}
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          style="padding: 4% 4%"
          *ngFor="let index of [0, 1, 2]"
        >
          <div fxLayout="column" class="text-labels bold-style"></div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
