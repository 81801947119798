<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
    <div class="backHeader">
        <mat-label>
            <button mat-icon-button (click)="navigateToInvoiceDashboardPage()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            Invoice Dashboard
        </mat-label>
    </div>

    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Invoices</div>
                <div class="page-sub-header-text-1">
                    All Invoice related data can be managed from this page
                </div>
            </div>
        </div>
        <!-- <span class="spacer"></span>
        <div fxLayout="column" fxLayoutAlign="center end">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <button mat-raised-button color="primary" class="page-header-button-style" (click)="onPrintClicked()">
                    DOWNLOAD
                </button>
            </div>
        </div> -->
    </div>
</div>

<div class=" basicDetailsForm">
    <app-invoice-detail-stats></app-invoice-detail-stats>
</div>

<div class=" basicDetails-List">
    <app-invoice-detail-list></app-invoice-detail-list>
</div>