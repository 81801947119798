<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer-content>
    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [showMoreMenu]="false"
      [isExport]="false"
      [isDownloadButtonVisible]="true"
      [isFilter]="false"
      [isAddBtn]="false"
      [isDelete]="true"
      [isSearch]="false"
      [isActive]="false"
      [isSearchMonth]="true"
      [isTableHeading]="false"
      [allColumns]="allColumns"
      [selection]="selection"
      (actionDeleteEvent)="onDelete($event)"
      [deletedFilter]="deletedFilter"
      (actionRefreshEvent)="onRefresh($event)"
      (onFilterEvent)="onFilter($event)"
      (viewPDFEvent)="viewPDF($event)"
      (downloadPDFEvent)="downloadPDF()"
      (navigationToPageEvent)="openDialog()"
      [isStatementHistorySummary]="true"
      [filterText]="this.filter?.toString() ?? ''"
      [statementSelectedPeriod] = "this.selectedPeriod"
      [startDate]="this.fromDate"
      [endDate]="this.toDate"
      [role]="this.userRole"
      [showPaginator]="false"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
<ng-template #callAPIDialog class="dialog">
  <form #userForm="ngForm" (ngSubmit)="generateStatement()">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="dialog-header"
    >
      <div fxLayout="column" fxFlex="90%">
        <h2 style="padding-bottom: 3%">Create New Statement Form</h2>
      </div>
      <div
        fxLayout="column"
        fxFlex="10%"
        fxLayoutAlign="end stretch"
        style="margin-bottom: 25px"
      >
        <mat-icon [mat-dialog-close] (click)="clearDate()">close</mat-icon>
      </div>
    </div>

    <div class="dialog-content">
      <mat-form-field appearance="outline" style="width: 80%">
        <mat-label>Create Date Range</mat-label>
        <mat-date-range-input [rangePicker]="scheduledPicker">
          <input
            [(ngModel)]="fromDate"
            [ngModelOptions]="{ standalone: true }"
            matStartDate
            placeholder="Start date"
            required
            readonly
          />
          <input
            [(ngModel)]="toDate"
            [ngModelOptions]="{ standalone: true }"
            matEndDate
            placeholder="End date"
            required
            readonly
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="scheduledPicker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #scheduledPicker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <mat-dialog-actions align="end">
      <button
        mat-button
        (click)="clearDate()"
        matDialogClose="no"
        class="button-style-back"
      >
        Cancel
      </button>
      <button mat-button class="button-style">Create</button>
    </mat-dialog-actions>
  </form>
</ng-template>
