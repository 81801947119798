import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScoreSettingRule } from 'src/app/sharedModules/services/customer/customer.proxies';
import { ScoreSettingRuleCardModel, ScoreSettingRuleCardModelArgs } from '../score-settings/models/score-settings-model';
import { validateScoreSettings } from '../validation/validateScoreSettings';

@Component({
  selector: 'app-range-deduct-card',
  templateUrl: './range-deduct-score.component.html',
  styleUrls: ['./range-deduct-score.component.scss']
})
export class RangeDeductScoreComponent implements OnInit {

  private _model: ScoreSettingRuleCardModel;
  public get model(): ScoreSettingRuleCardModel {
    return this._model;
  }
  public set model(value: ScoreSettingRuleCardModel) {
    this._model = value;
  }
  
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  dialogRef : MatDialogRef<any, any>;
  public validateErrors : any;
  public lstIndex = [];
  public _index : number;
  @Input() ruleIndex: number = 0;
  @Input()
  public set dataSource(value: ScoreSettingRule) {
    this.model = ScoreSettingRuleCardModel.fromDto(value);
  }
  
  @Output() onModelChanged = new EventEmitter<ScoreSettingRuleCardModelArgs>();

  constructor(private _formBuilder: FormBuilder, private dialog : MatDialog,) { }

  ngOnInit(): void {
    this.raiseModelChanged();
  }

  public chkActive(event: any) {
    this.raiseModelChanged();
  }

  public removeRuleItem() {
    this.dialogRef.close();
    this._model.removeRuleItem(this._index);
    this.raiseModelChanged();

    this.lstIndex = [];
    for(var item in this._model?.scoreRules){
      this.lstIndex.push(item);
    }
    this.validateErrors = validateScoreSettings(this._model);
  }

  public addNewRuleItem() {
    this._model.addNewRuleItem();
    this.raiseModelChanged();

    this.lstIndex = [];
    for(var item in this._model?.scoreRules){
      this.lstIndex.push(item);
    }
    this.validateErrors = validateScoreSettings(this._model);
  }

  public OnScoreChangeAsync(){
    this.lstIndex = [];
    for(var item in this._model?.scoreRules){
      this.lstIndex.push(item);
    }
    this.validateErrors = validateScoreSettings(this._model);
    this.raiseModelChanged();
    
  }
  private raiseModelChanged() {
    let args = new ScoreSettingRuleCardModelArgs(this._model, this.ruleIndex);
    this.onModelChanged.emit(args);
  }

  openDialog(index: number) {
    this._index = index;
    this.dialogRef = this.dialog.open(this.callAPIDialog);
  }

}
