import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CustomerFilterModel } from 'src/app/sharedModules/models/customer-filter.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CustomerService } from '../../../services/customers.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  userName: string = null;
  userRole : any;
  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'UpdateDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [    
    { displayName: '', name: 'icon', width: '4%' },
    { displayName: 'NAME', name: 'FullName', width: '20%' },
    { displayName: 'CONTACT NUMBER', name: 'PhoneNumber', width: '20%' },
    { displayName: 'ROLE', name: 'Role', width: '20%' },
    { displayName: 'JOB TITLE', name: 'JobTitle', width: '20%' },
    { displayName: 'ACTION', name: 'actionUser', width: '4%' },
  ];

  allColumns: TableColumn[];

  filterFormGroup: FormGroup;

  typeList = [
    { key: 'All', value: 'All' },
    { key: 0, value: 'Individual' },
    { key: 1, value: 'Company' },
  ];
  states: any[] = [];
  advanceFilters: string;
  advanceFilterCount: number;
  advanceFiltersList: any;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private router: Router,
    private userManagerService: UserManagementService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private dialog: MatDialog,
    private _toastrService: ToastrService
  ) {
    this.allColumns = this.columns; 

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    
    this.filter = this._commonService.getListDataFromLocalStorage(
      'usersGridFilter',
      'users',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'usersGridPageIndex',
          'users',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'usersGridPageSize',
          'users',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'usersGridSortActive',
        'users',
        'UpdateDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'usersGridSortDirection',
        'users',
        'desc'
      ),
    };
    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'usersGridAdvanceFilter',
      'users',
      ''
    );
    
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'usersGridAdvanceFilterForm',
        'users',
        JSON.stringify(new CustomerFilterModel())
      )
    );

    let filterModel = new CustomerFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  onDelete(id: string) {}

  onRefresh(id: string) {}

  loadData(
    userName: string,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    //this.restForm();
    this.selection.clear();
    this.userManagerService
      .getUsers(
        userName?.toString(),
        filter == null || filter == '' ? null : filter.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize
      )
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.drawer?.close();
          this.isLoading = false;
          if (res.data != null) {
            this.page.length = res.data.totalItems;
            var list = [];
            res.data?.items.map((item) => {
              let record = {
                UserName: item.userName,
                CustomerName: item.customerName,
                firstName: item.firstName,
                lastName: item.lastName,
                JobTitle: item.jobTitle,
                email: item.email,
                PhoneNumber: item.phone,
                Role: item.role,
                userId: item.userId,
                isSubscribeReceivingTransactionEmail:
                  item.isSubscribeReceivingTransactionEmail,
              };
              list.push(record);
            });
            this.data = list;
          } else this.data = [];
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.userName,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  protected onRecurringFilter(event: boolean = false) {
    this.recurringFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.userName,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      // if (typeof this.filter === 'string' && this.filter) {
      //   this.filter = this.filter.replace("'", "''");
      // }
      this.loadData(
        this.userName,
        this.filter?.toString().replace(/[^a-zA-Z0-9 ]/g, ''),
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'usersGridFilter',
        'users'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.userName,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'usersGridPageSize',
        'users'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'usersGridPageIndex',
        'users'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      //this.sortByMapping();
      this.loadData(
        this.userName?.toString(),
        this.filter?.toString(),
        this.sortBy.active,
        this.sortBy.direction ? this.sortBy.direction : 'desc',
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'usersGridSortActive',
        'users'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'usersGridSortDirection',
        'users'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  public onRowClick(data: any) {
    this.router.navigate(['edit-user', data.userId]);
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      let filterModel = new CustomerFilterModel();
      filterModel.type = 'All';
      filterModel.state = 'All';
      this.filterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData?.['USA'];
    this.states = [
      { state: 'All', abbreviation: 'All', postalCode: 'All' },
    ].concat(this.states);
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'usersGridPageSize',
          'users',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'usersGridSortActive',
        'users',
        'UpdateDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'usersGridSortDirection',
        'users',
        'desc'
      ),
    };
    this.advanceFilters = '';
    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'usersGridAdvanceFilter',
      'users'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'usersGridAdvanceFilterForm',
      'users'
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this.loadData(
      this.userName?.toString(),
      this.filter?.toString(),
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  AddUser() {
    this.router.navigate(['add-user']);
  }

  deleteBasedOnSelection(status: any) {
    let type = status ? 'Activate' : 'Deactivate';
    let customerIdList = this.selection['_selected'].map(
      ({ customerId }) => customerId
    );
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            customerIds: customerIdList,
            status: status,
          };
          // this.customerService
          //   .deactivateCustomer(statusUpdate)
          //   .subscribe((data) => {
          //     this.isLoading = false;
          //     if (data.code == 200) {
          //       this._toastrService.success('Customer status updated successfully!');
          //       this.loadData(this.userName,this.filter, this.sortBy.active, this.sortBy.direction, this.page.pageIndex, this.page.pageSize);
          //     }
          //   });
        }
      });
    }
  }

  customerEdit(data: any) {
    this.router.navigate(['edit-user', data]);
  }

  ngOnInit() {
    this.loadData(
      null,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        null,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.deletedFilterEvent.subscribe(this.onDeletedFilter.bind(this));
    this.recurringFilterEvent.subscribe(this.onRecurringFilter.bind(this));

    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData?.['USA'];
    this.states = [
      { state: 'All', abbreviation: 'All', postalCode: 'All' },
    ].concat(this.states);

    // this.filterFormGroup.patchValue({ type: 'All', state: 'All' });
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!this.router.url.includes('user'))
    localStorage.removeItem('users');  
  }
}
