<div class="laptop">
    <div class="content">
        <ng-container *ngIf="pageIndex == '1'">
            <app-hosted-payment-page-order [formData]="formData" [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
                [isSmallView]="isSmallView"></app-hosted-payment-page-order>
        </ng-container>
        <ng-container *ngIf="pageIndex == '2'">
            <app-hosted-payment-page-information [changesIsLoading]="changesIsLoading"  [finzeoPaymentOption]="finzeoPaymentOption" [formData]="formData" [deviceType]="deviceType"
                [isSmallView]="isSmallView"></app-hosted-payment-page-information>
        </ng-container>
        <ng-container *ngIf="pageIndex == '3'">
            <app-hosted-payment-page-success [changesIsLoading]="changesIsLoading" [formData]="formData" [deviceType]="deviceType"
                [isSmallView]="isSmallView"></app-hosted-payment-page-success>
        </ng-container>
    </div>
</div>