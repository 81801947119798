import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerGroupService } from 'src/app/blockModules/services/customer-group.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { PageChanged, SortChanged } from 'src/app/sharedModules/models/pagination.model';

@Component({
  selector: 'app-view-customer-group',
  templateUrl: './view-customer-group.component.html',
  styleUrls: ['./view-customer-group.component.scss']
})
export class ViewCustomerGroupComponent implements OnInit {
  isLoading:boolean =false;
  loadMessage:string ="Please wait ...."
  groupName: string = ''; 
  transactionType: string = ''; 
  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTOMER NAME', name: 'nameWithoutEmail', width: '20%' },
    { displayName: 'EMAIL ADDRESS', name: 'email', width: '20%' },
    { displayName: 'BANK ACCT', name: 'checkSignBank', width: '20%' },
    { displayName: 'CREDIT/DEBIT', name: 'checkSignCredit', width: '20%' },
    { displayName: 'ACH TRXN TYPE', name: 'achTransactionType', width: '20%' },
    { displayName: 'DEFAULT AMOUNT', name: 'defaultAmount', width: '20%' }
  ];
  data: any;
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole :any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };
  datatablemargin : string ="0px";
  customerGroupId: string;
  selectedCustomerList: any[] = [];
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerGroupService: CustomerGroupService,
    private _toastrService: ToastrService) {
      this.allColumns = this.customerGrpscolumn;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
     }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerGroupId = params.get('customerGroupId');
    });
    this.loadData();
  }
  navigateToCustomerGroupPage(selectedIndex: any = 1){
    this.router.navigate(['customers', selectedIndex]);
  }

  loadData(){
    if (this.customerGroupId) {
      this.getAllCustomerByGroupId(this.customerGroupId);
    }
  }
  getAllCustomerByGroupId(customerGroupId: any){
    this.isLoading = true;
    this.customerGroupService.getAllCustomerByGroupId(customerGroupId)
    .subscribe((response) => {
      this.isLoading = false;
      if (response.data != null) {
        this.groupName = response.data.groupName;
        this.transactionType = response.data.transactionType;
        this.data = response.data.customerGroupDetails;
        // this.data = response.data.customerGroupDetails.map((item) => {
        //   return {
        //     ...item,
        //     defaultAmount: `$ ${item.defaultAmount}`,
        //   };
        // });;
        this.selectedCustomerList = this.data;
        this.page.length = this.data.length;
        this.onPageChanged(this.page);
      }
      else{
        this.data = [];
        this._toastrService.error(
          response.errorMessage
        );
      }
    }),
      (error) => {
        this.data = [];
        this.isLoading = false;
        this._toastrService.error(
          error.errorMessage
        );
      };
  }

  customerGroupEdit() {
    this.router.navigate(['customer-group', this.customerGroupId]);
  }

  onPageChanged(event: PageChanged) {
    if (this.page.pageIndex !== event.pageIndex || this.page.pageSize !== event.pageSize || this.groupName != '') {
      this.page = event;
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      this.data = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  onSortChanged(event: SortChanged) {
    if (this.sortBy.active !== event.active || this.sortBy.direction !== event.direction) {
        this.sortBy = event;

        if (this.data) {
            const { active, direction } = this.sortBy;

            const sortedList = [...this.data].sort((a, b) => {
                let valueA = a[active];
                let valueB = b[active];

                if (active === 'name') {
                    valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
                    valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
                }

                if (valueA === undefined || valueA === null || valueA === '') return direction === 'asc' ? -1 : 1;
                if (valueB === undefined || valueB === null || valueB === '') return direction === 'asc' ? 1 : -1;

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else {
                    const strA = String(valueA);
                    const strB = String(valueB);
                    return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
                }
            });

            this.data = sortedList;
        }
    }
  }
}
