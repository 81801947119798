import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { BatchFilterModel } from 'src/app/sharedModules/models/batch-filter.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CustomerService } from '../../services/customers.service';
import { BatchTransactionsService } from '../../services/batch-transactions.service';
import { CreateBatchModel } from 'src/app/sharedModules/models/create-batch.model';
import { CloneBatchModel } from '../../../sharedModules/models/clone-batch.model';
import { CustomerGroupService } from '../../services/customer-group.service';
import { ProcessBatchModel } from 'src/app/sharedModules/models/process-batch-model';
import { AchInternalService } from 'src/app/featureModules/services/ach.internal.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-batch-transactions',
  templateUrl: './batch-transactions.component.html',
  styleUrls: ['./batch-transactions.component.scss'],
})
export class BatchTransactionsComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter();

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  EffectiveDate: string = getSafeIsoDateString(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  @Input()
  noRecordMessage: string;

  isLoading = false;
  data: any;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  createBatchModel: CreateBatchModel = new CreateBatchModel();
  cloneBatchModel: CloneBatchModel;
  processBatchModel: ProcessBatchModel;
  createCustomerGrpFormGroup: FormGroup;
  cloneCustomerGrpFormGroup: FormGroup;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Batch Name', name: 'batchName', width: '15%' },
    { displayName: 'Batch ID', name: 'batchId', width: '12%' },
    { displayName: '# of Trxn', name: 'noOfTransactions', width: '12%' },
    { displayName: 'Effective Date', name: 'effectiveDate', width: '16%' },
    { displayName: 'Cutoff Time', name: 'cutOffTime', width: '14%' },
    { displayName: 'BATCH STATUS', name: 'batchStatus', width: '15%' },
    { displayName: 'STATUS', name: 'isActive', width: '8%' },
    { displayName: 'ACTION', name: 'batchTrxnAction', width: '8%' },
  ];
  transactionTypes = [
    { value: 'Credit', displayName: 'Credit Only' },
    { value: 'Debit', displayName: 'Debit Only' },
    { value: 'Credit & Debit', displayName: 'Credit & Debit' },
  ];
  allColumns: TableColumn[];

  filterFormGroup: FormGroup;

  typeList = [
    { key: 'All', value: 'All' },
    { key: 'New', value: 'New' },
    { key: 'Pending', value: 'Queued' },
    { key: 'Sent', value: 'Sent' },
    { key: 'Failed', value: 'Failed' }
  ];
  states: any[] = [];
  advanceFilters: string = '';
  advanceFilterCount: number;
  advanceFiltersList: any;
  userRole: any;
  companyId: string;
  companyName: string;
  groupName: string = '';
  @ViewChild('customerGroupDialog') customerGroupDialog!: TemplateRef<any>;
  @ViewChild('cloneBatchDialog') cloneBatchDialog!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;

  @ViewChild('drawer') drawer: MatDrawer;

  filteredCustomerGroup: any[] = [];
  isShowNoFound: boolean = false;
  minLengthTerm = 3;
  minDate = new Date();
  isDisabledTransactionType: boolean = false;
  constructor(
    private router: Router,
    private customerService: CustomerService,
    private batchTransactionsService: BatchTransactionsService,
    private customerGroupService: CustomerGroupService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _achInternalService: AchInternalService,
    private cdr: ChangeDetectorRef
  ) {
    this.allColumns = this.columns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    if (!this.router.url.includes('batchTransactions'))
      localStorage.removeItem('batchTransactions');

    this.filter = this._commonService.getListDataFromLocalStorage(
      'batchesGridFilter',
      'batchTransactions',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'batchesGridPageIndex',
          'batchTransactions',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'batchesGridPageSize',
          'batchTransactions',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'batchesGridSortActive',
        'batchTransactions',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'batchesGridSortDirection',
        'batchTransactions',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'batchesAdvanceFilter',
      'batchTransactions',
      ''
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'batchesAdvanceFilterForm',
        'batchTransactions',
        JSON.stringify(new BatchFilterModel())
      )
    );

    let filterModel = new BatchFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'batchesGridPageIndex',
          'batchTransactions',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'batchesGridPageSize',
          'batchTransactions',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };
  }

  onDelete(id: string) { }

  onRefresh(id: string) { }

  onError(message: string) { }

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.GetAllBatches(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  clearSelection() {
    this.createCustomerGrpFormGroup.get('customerGroupName').setValue(null);
    this.createCustomerGrpFormGroup.get('customerGroupId').setValue(null);
    this.createCustomerGrpFormGroup.get('TransactionType').setValue(null);
    this.isDisabledTransactionType = false;
    this.transactionTypes = [
      { value: 'Credit', displayName: 'Credit Only' },
      { value: 'Debit', displayName: 'Debit Only' },
      { value: 'Credit & Debit', displayName: 'Credit & Debit' },
    ];
    this.filteredCustomerGroup = [];
  }

  protected onRecurringFilter(event: boolean = false) {
    this.recurringFilter = !!event;
    this.page.pageIndex = 0;
    this.GetAllBatches(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;

      this.GetAllBatches(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'batchesGridFilter',
        'batchTransactions'
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.GetAllBatches(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'batchesGridPageSize',
        'batchTransactions'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'batchesGridPageIndex',
        'batchTransactions'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.GetAllBatches(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
      this._commonService.setListDataToLocalStorage(
        event.active,
        'batchesGridSortActive',
        'batchTransactions'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'batchesGridSortDirection',
        'batchTransactions'
      );
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'batchName':
        this.sortBy.active = 'processingFileName';
        break;
      case 'batchId':
        this.sortBy.active = 'fileTrackingId';
        break;
      case 'noOfTransactions':
        this.sortBy.active = 'TotalTransactions';
        break;
      case 'effectiveDate':
        this.sortBy.active = 'filePickedupDate';
        break;
      case 'cutOffTime':
        this.sortBy.active = 'filePickedupDate';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      let filterModel = new BatchFilterModel();
      filterModel.batchStatus = 'All';
      this.filterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;

    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'batchesGridPageSize',
          'batchTransactions',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'batchesGridSortActive',
        'batchTransactions',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'batchesGridSortDirection',
        'batchTransactions',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if (data.batchStatus && data.batchStatus != 'All') {
      this.advanceFilters += 'batchStatus:' + data.batchStatus + '|';
    }

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'batchesAdvanceFilter',
      'batchTransactions'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'batchesAdvanceFilterForm',
      'batchTransactions'
    );

    this.GetAllBatches(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  onBatchTransactionDeleteEvent(batchId: any) {
    const batchAction = this.data.find(
      (batch: any) => batch.batchId === batchId
    );
    if (batchAction) {
      let type: "Activate" | "Deactivate" = batchAction.isActive ? "Deactivate" : "Activate";
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: true,
          buttonName: type.toUpperCase(),
          successMessage: 'Are you sure you want to ' + type + ' this Batch?',
          subSuccessMessage:
            'Clicking on ' + type + ' button will ' + type.toLowerCase() + ' this Batch',
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.batchTransactionsService
            .activateOrDeactivateBatchById(batchId, type)
            .subscribe((response) => {
              this.isLoading = false;
              if (response && response.data != null) {
                this._toastrService.success(`Batch ${type}d Successfully`);
                this.GetAllBatches(
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize,
                  this.advanceFilters
                );
              } else {
                this.data = [];
                this._toastrService.error(response.errorMessage);
              }
            }),
            (error) => {
              this.data = [];
              this.isLoading = false;
              this._toastrService.error(error.errorMessage);
            };
        }
      });
    }
  }

  onBatchTransactionCloneEvent(batchId: any) {
    let cloneSelectedData = this.data.filter((x) => x.batchId == batchId)[0];
    let cloneBatchModel = new CloneBatchModel();
    cloneBatchModel.BatchId = batchId;
    cloneBatchModel.BatchName = cloneSelectedData?.batchName + ' (1)';
    cloneBatchModel.EffectiveDate = new Date().toISOString();
    this.cloneCustomerGrpFormGroup =
      this.formBuilder.formGroup(cloneBatchModel);
    this.dialogRef = this.dialog.open(this.cloneBatchDialog, {
      width: '40%',
    });
  }

  onBatchTransactionProcessNowEvent(batchId: any) {
    let type: string = 'Submit';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: type.toUpperCase(),
        successMessage: 'Are you sure you want to ' + type + ' this Batch?',
        subSuccessMessage:
          'Clicking on ' + type + ' button will submit this batch for processing',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;

        if (!this.companyName?.trim()) {
          this._toastrService.error('Client not found');
          return false;
        }

        if (!batchId?.trim()) {
          this._toastrService.error('BatchId is not found');
          return false;
        }

        let processBatchModel = new ProcessBatchModel();
        processBatchModel.fileTrackingId = batchId;
        processBatchModel.transactionMode = "BATCHPROCESS";

        this._achInternalService.ProcessBatchByFileTrackingId(processBatchModel)
          .subscribe((response) => {
            this.isLoading = false;
            if (response.data != null) {
              this._toastrService.success(response.data.message);
              this.GetAllBatches(
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize,
                this.advanceFilters
              );
            } else {
              this.data = [];
              this._toastrService.error(response.errorMessage);
            }
          }),
          (error) => {
            this.data = [];
            this.isLoading = false;
            this._toastrService.error(error.errorMessage);
          };
      }
    });
  }

  AddBatchTransactions() {

    if (this.createCustomerGrpFormGroup.valid) {
      let requestData = { ...this.createCustomerGrpFormGroup.value };
      delete requestData.customerGroupName;
      this.batchTransactionsService.CreateBatch(requestData).subscribe(
        (response) => {
          this.isLoading = false;

          if (response.data != null) {
            this.dialogRef.close();
            this.router.navigate([
              'add-batch-transactions',
              response.data.batchId,
            ]);
            this._toastrService.success(response.data.message);
          } else {
            this._toastrService.error(response.errorMessage);
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
    } else {
      this.createCustomerGrpFormGroup.markAllAsTouched();
    }
  }

  CloneBatchTransactions() {
    this.isLoading = true;
    if (this.cloneCustomerGrpFormGroup.valid) {
      this.batchTransactionsService
        .CloneBatch(this.cloneCustomerGrpFormGroup.value)
        .subscribe(
          (response) => {
            this.isLoading = false;
            if (response.data != null) {
              this.dialogRef.close();
              this.router.navigate([
                'add-batch-transactions',
                response.data.batchId,
              ]);
              this._toastrService.success(response.data.message);
            } else {
              this._toastrService.error(response.errorMessage);
            }
          },
          (error) => {
            this.isLoading = false;
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        );
    } else {
      this.createCustomerGrpFormGroup.markAllAsTouched();
    }
  }

  OpenDialogForCustomerGrp() {
    this.createCustomerGrpFormGroup.reset();
    this.createCustomerGrpFormGroup.markAsUntouched();
    this.isDisabledTransactionType = false;
    this.transactionTypes = [
      { value: 'Credit', displayName: 'Credit Only' },
      { value: 'Debit', displayName: 'Debit Only' },
      { value: 'Credit & Debit', displayName: 'Credit & Debit' },
    ];
    this.dialogRef = this.dialog.open(this.customerGroupDialog, {
      width: '50%',
    });
  }

  GetAllBatches(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    if (sortBy == '') {
      sortDirection = 'desc';
    }
    this.batchTransactionsService
      .getAllActiveBatches(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (response) => {
          this.isLoading = false;
          this.drawer?.close();
          if (response && response.data != null) {
            response.data.batches?.forEach(b => {
              b.cutOffTime = b.effectiveDate;
            });
            this.data = response.data.batches;
            this.page.length = response.data.totalRecords;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onCancel(): void {
    this.createCustomerGrpFormGroup.reset();
    this.createCustomerGrpFormGroup.markAsUntouched();
    this.filteredCustomerGroup = [];
    this.dialogRef.close();
  }

  deleteBasedOnSelection(status: any) {
    let type = status ? 'Activate' : 'Deactivate';
    let customerIdList = this.selection['_selected'].map(
      ({ customerId }) => customerId
    );
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: status ? false : true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            customerIds: customerIdList,
            status: status,
          };
          this.customerService
            .deactivateCustomer(statusUpdate)
            .subscribe((data) => {
              this.isLoading = false;
              if (data.code == 200) {
                this._toastrService.success(
                  'Customer status updated successfully!'
                );
                this.GetAllBatches(
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize,
                  this.advanceFilters
                );
              }
            });
        }
      });
    }
  }

  viewBatchTransactions(batchId: any) {
    this.router.navigate(['view-batch-transactions', batchId]);
  }

  ngOnInit() {
    this.GetAllBatches(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.GetAllBatches(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });

    this.filterEvent.subscribe(() => {
      this.onFilter.bind(this);
    });

    this.deletedFilterEvent.subscribe(() => {
      this.onDeletedFilter.bind(this);
    });

    this.recurringFilterEvent.subscribe(() => {
      this.onRecurringFilter.bind(this);
    });

    this.createBatchModel = new CreateBatchModel();
    this.createBatchModel.EffectiveDate = new Date().toISOString();
    this.createCustomerGrpFormGroup = this.formBuilder.formGroup(
      this.createBatchModel
    );
    this.cloneBatchModel = new CloneBatchModel();
    this.cloneCustomerGrpFormGroup = this.formBuilder.formGroup(
      this.cloneBatchModel
    );
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData?.['USA'];
    this.states = [
      { state: 'All', abbreviation: 'All', postalCode: 'All' },
    ].concat(this.states);

    this.createCustomerGrpFormGroup.controls['customerGroupName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.createCustomerGrpFormGroup.controls['customerGroupName']
                .valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomerGroup = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomerGroup = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.customerGroupService.SearchCustomersGroup(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.isShowNoFound = true;
          this.filteredCustomerGroup = [];
        } else {
          this.isShowNoFound = false;
          this.filteredCustomerGroup = data.data;
        }
      });

    this.companyId = localStorage.getItem('CompanyId') || '';
    this.companyName = localStorage.getItem('CompanyName') || '';
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if (!this.router.url.includes('batchTransactions'))
      localStorage.removeItem('batchTransactions');
  }

  onSelected() {
    var selected = (this.filteredCustomerGroup =
      this.filteredCustomerGroup.filter(
        (customer: any) =>
          this.createCustomerGrpFormGroup.get('customerGroupName').value ==
          customer.groupName?.trim()
      ));

    if (selected && selected[0])
      this.createCustomerGrpFormGroup
        .get('customerGroupId')
        .setValue(selected[0].id);
    this.isDisabledTransactionType = true;
    if (selected[0].achTransactionType.toLowerCase() === 'credit') {
      this.transactionTypes = [{ value: 'Credit', displayName: 'Credit Only' }];
      this.createCustomerGrpFormGroup.get('TransactionType').setValue('Credit');
    } else if (selected[0].achTransactionType.toLowerCase() === 'debit') {
      this.transactionTypes = [{ value: 'Debit', displayName: 'Debit Only' }];
      this.createCustomerGrpFormGroup.get('TransactionType').setValue('Debit');
    } else if (selected[0].achTransactionType.toLowerCase() === 'credit & debit' || selected[0].achTransactionType.toLowerCase() === 'credit,debit') {
      this.isDisabledTransactionType = false;
      this.transactionTypes = [
        { value: 'Credit & Debit', displayName: 'Credit & Debit' },
        { value: 'Credit', displayName: 'Credit Only' },
        { value: 'Debit', displayName: 'Debit Only' }
      ];
      this.createCustomerGrpFormGroup
        .get('TransactionType')
        .setValue('Credit & Debit');
    } else {
      this.transactionTypes = [];
      this.createCustomerGrpFormGroup.controls['TransactionType'].reset();
    }
    this.cdr.detectChanges();
  }

  selectedCustomerGroupName(customerGroup: any) {
    return customerGroup.groupName?.trim();
  }
}
