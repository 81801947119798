<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="Paid">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-invoice-dashboard-total-amount *ngIf="loadPaid" [period]="period" [paymentStatus]="'Paid'">
                </app-invoice-dashboard-total-amount>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Un-Paid">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-invoice-dashboard-total-amount *ngIf="loadUpaid" [period]="period" [paymentStatus]="'Failed'">
                </app-invoice-dashboard-total-amount>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Past Due">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-invoice-dashboard-total-amount *ngIf="loadPastDue" [period]="period" [paymentStatus]="'PastDue'">
                </app-invoice-dashboard-total-amount>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Returned">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-invoice-dashboard-total-amount *ngIf="loadReturned" [period]="period" [paymentStatus]="'Returned'">
                </app-invoice-dashboard-total-amount>
            </div>
        </div>
    </mat-tab>
    <mat-tab disabled></mat-tab>
</mat-tab-group>