import {
  minNumber,
  prop,
  pattern,
  required,
  maxLength,
  minLength,
  propArray,
  maxNumber,
} from '@rxweb/reactive-form-validators';

export class InvoiceProductModel {

  @prop()
  inventoryId:string;

  @prop()
  categoryId:string;

  @prop()
  isActive:boolean;

  @prop()
  @required() 
  @pattern({ expression: { Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,}})  
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @minLength({ value: 3 })
  @maxLength({ value: 50 })
  productName: string;

  @prop()
  @pattern({
  expression: { Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,}})
  @pattern({ expression: { No_Space: /.*\S.*/ }} )
  @maxLength({ value: 11})
  sku: string;

  @prop()
  @required()
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @minNumber({ value: 1})
  @maxNumber({ value:99999})
  quantity: number;

  @prop()
  availableQuantity: number;

  @prop()
  @required()
  @minNumber({ value: 0.01})
  @maxNumber({ value: 99999})  
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  basePrice: number;

  @prop()
  @required()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00  })
  @maxNumber({ value:100})
  salesTax: number;

  @prop()
  salesTaxAmount: number;

  @prop()
  @required()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00  })
  discount: number;

  @prop()
  discountAmount: number;

  @prop()
  @required()   
  discountType: string ='Percentage';

  @prop()
  productTotal: number;

  @prop()
  @required()
  totalPrice: number;
}
export class InvoiceMainModel {
  @required()
  @maxLength({ value: 30 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  customerInvoiceProduct: any;

  //recurring section
  @prop()
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @maxNumber({ value: 11 })  
  recurringCount: number;

  @prop()
  isRecurring: boolean = false;

  @prop()
  transactionFrequency: string;

  //total Section
  @prop()
  isDiscountApplied: boolean = false;

  @prop()
  subTotalAmount: number;

  // @prop()
  // appliedServiceTaxPercentage: number;

  @prop()
  serviceTaxAmount: number;


  // @prop()
  // @required()
  // appliedDiscountType: string;
  
  // @prop()
  // @required()
  // @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  // appliedDiscountValue: number = 0;

  @prop()
  appliedDiscountAmount: number;

  @prop()
  totalAmount: number;

  @prop()
  returnPolicyText: string;

  //Data Other than Invoice Products and Calculations

  @prop()
  invoiceNumber: number;

  @prop()
  fromDate: string;

  @prop()
  toDate: string;

  @prop()
  createdDate: Date;

  @prop()
  clientLogo: string;

  @prop()
  companyId: string;

  @prop()
  address: string;

  @prop()
  email: string;

  @prop()
  phone: number;

  @prop()
  customerId: string;

  @prop()
  customerName: string;

  @prop()
  customerBillToAddress: string;

  //SendInvoice Component

  @prop()
  scheduleDate: string;

  @prop()
  invoiceDueDate: string;

  @prop()
  sendBy: string;

  @prop()
  registeredNumber: string;

  @prop()
  registeredEmail: string;

  @prop()
  emailList: any;

  @prop()
  mobileNoList: any;

  @prop()
  additionalEmail: any;

  @prop()
  additionalNumber: any;

  @prop()
  enteredBy: string;

  @prop()
  dueDateDays: number;

  @prop()
  maxPayments: string;

  @propArray(InvoiceProductModel)
  invoiceProductModelList: InvoiceProductModel[];
}
