<mat-card class="billing-details-card" fxLayout="column" fxFlex="100%">
  <mat-toolbar class="header-toolbar" [style.--active-bg-color]="pageSettingModel?.themeColor">
    <img
      src="assets/payment-widget-icons/billingDetails.png"
      alt="logo"
      class="billing-logo"
      [style.--active-bg-color]="pageSettingModel?.themeColor"
    />
    <mat-label class="billing-details" 
    [style.--active-text-color]="pageSettingModel?.themeTextColor">Billing Details</mat-label>
  </mat-toolbar>
  <br />

  <div class="billingDetailsForm">
    <form [formGroup]="billingDetailsFormGroup">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
        *ngIf="!this.isAchMode || (this.isAchMode && !this.isCustomer)"
      >
        <mat-form-field
          appearance="outline"
          [style.--active-bg-color]="pageSettingModel?.themeColor"
        >
          <mat-label [ngClass]="getCompanyClass()">Company Name</mat-label>
          <input
            matInput
            formControlName="companyName"
            class="form-control"
            name="companyName"
            placeholder="Company Name"
            autocomplete="additional-name"
            maxlength="50"
          />
          <mat-error>
            {{
              billingDetailsFormGroup.controls["companyName"]["_errorMessage"]
            }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
        *ngIf="!this.isAchMode || (this.isAchMode && this.isCustomer)"
      >
        <div
          class="custom-field-space"
          fxLayout="column"
          fxLayoutWrap
          fxFlex="47.5%"
        >
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">First Name</mat-label>
            <input
              matInput
              formControlName="firstName"
              class="form-control"
              name="firstName"
              placeholder="First Name"
              autocomplete="name"
              maxlength="50"
            />
            <mat-error>
              {{
                billingDetailsFormGroup.controls["firstName"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">Last Name</mat-label>
            <input
              matInput
              formControlName="lastName"
              class="form-control"
              name="lastName"
              placeholder="Last Name"
              autocomplete="name"
              maxlength="50"
            />
            <mat-error>
              {{
                billingDetailsFormGroup.controls["lastName"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayout.sm="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutGap.sm="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <ngx-intl-tel-input
            [ngClass]="{
              'phone-invaid':
                billingDetailsFormGroup.controls['phone'].invalid &&
                billingDetailsFormGroup.controls['phone'].touched,
              'phone-valid': billingDetailsFormGroup.controls['phone'].valid
            }"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
            [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [customPlaceholder]="'Contact Number*'"
            [searchCountryFlag]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.UnitedStates"
            [phoneValidation]="true"
            [separateDialCode]="separateDialCode"
            [numberFormat]="PhoneNumberFormat.National"
            [value]="phoneNumber"
            name="phone"
            formControlName="phone"
            [maxLength]="maxLength"
            (countryChange)="changePhone($event)"
          >
          </ngx-intl-tel-input>
          <mat-error
            style="font-size: 9px"
            *ngIf="billingDetailsFormGroup.controls['phone'].touched"
            >{{
              billingDetailsFormGroup.controls["phone"]["_errorMessage"]
            }}</mat-error
          >
          <div *ngIf="phonehint" class="phonehint-text">
            eg.: {{ phonehint }}
          </div>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">Email Address</mat-label>
            <input
              matInput
              class="form-control"
              formControlName="email"
              name="email"
              placeholder="Email Address"
              autocomplete="email"
              maxlength="50"
              style="padding: 2px"
            />
            <mat-error>
              {{ billingDetailsFormGroup.controls["email"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        style="margin-top: 2%"
        class="form-group"
      >
        <mat-form-field
          fxFlex="100%"
          appearance="outline"
          [style.--active-bg-color]="pageSettingModel?.themeColor"
        >
          <mat-label class="required">Address 1</mat-label>
          <textarea
            matInput
            placeholder="Address Line 1"
            class="form-control"
            formControlName="address1"
            rows="3"
            name="address1"
            autocomplete="address-level1"
            maxlength="150"
          ></textarea>
          <mat-error>
            {{ billingDetailsFormGroup.controls["address1"]["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <mat-form-field
          fxFlex="100%"
          appearance="outline"
          [style.--active-bg-color]="pageSettingModel?.themeColor"
        >
          <mat-label>Address 2</mat-label>
          <textarea
            matInput
            placeholder="Address Line 2"
            class="form-control"
            formControlName="address2"
            rows="3"
            name="address2"
            autocomplete="address-level2"
            maxlength="150"
          ></textarea>
          <mat-error>
            {{ billingDetailsFormGroup.controls["address2"]["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <div fxLayout="column" fxLayoutWrap fxFlex="50%">
            <mat-form-field
              appearance="outline"
              [style.--active-bg-color]="pageSettingModel?.themeColor"
            >
              <mat-label class="required">Country</mat-label>
              <mat-select formControlName="country" class="form-control" name="country" (selectionChange)="onCountryChange($event)">
                <mat-option *ngFor="let country of countries" [value]="country.code">
                  <mat-label [style.--active-fontFamily]="pageSettingModel?.themeFont">{{ country.name }}</mat-label>
                </mat-option>
              </mat-select>          
              <mat-error>
                {{
                  billingDetailsFormGroup.controls["country"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">State/Province</mat-label>
            <mat-select
              class="form-control"
              formControlName="state"
              name="state"
            >
              <mat-option *ngFor="let stateOpt of states" [value]="stateOpt.postalCode">
                <mat-label [style.--active-fontFamily]="pageSettingModel?.themeFont">{{ stateOpt.state }}</mat-label>
              </mat-option>
            </mat-select>
            <mat-error>
              {{ billingDetailsFormGroup.controls["state"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="5%"
        fxLayoutGap.xs="0%"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">City</mat-label>
            <input
              matInput
              formControlName="city"
              class="form-control"
              name="city"
              placeholder="City"
              autocomplete="city"
              maxlength="50"
            />
            <mat-error>
              {{ billingDetailsFormGroup.controls["city"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
          <mat-form-field
            appearance="outline"
            [style.--active-bg-color]="pageSettingModel?.themeColor"
          >
            <mat-label class="required">Postal Code</mat-label>
            <input
              matInput
              class="form-control"
              formControlName="zip"
              name="zip"
              placeholder="Postal Code"
              autocomplete="postal-code"
              (focusout)="onPostalCodeFocusOut($event)"
            />
            <mat-error>
              {{ billingDetailsFormGroup.controls["zip"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-card>
