import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CustomerFilterModel } from 'src/app/sharedModules/models/customer-filter.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CustomerService } from '../../services/customers.service';
import { CustomerGroupService } from '../../services/customer-group.service';
import { CreateCustomerGroupModel } from 'src/app/sharedModules/models/create-customer-group.model';
import { CustomerGroupFilterModel } from 'src/app/sharedModules/models/customer-group-filter';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  customerGroupdata: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  createCustomerGrpModel: CreateCustomerGroupModel;
  createCustomerGrpFormGroup: FormGroup;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Select', name: 'select', width: '3%' },
    { displayName: '', name: 'icon', width: '4%' },
    { displayName: 'Name', name: 'name', width: '20%' },
    { displayName: 'Type', name: 'type', width: '10%' },
    { displayName: 'ADDRESS', name: 'address1', width: '24%' },
    { displayName: 'CREATED', name: 'entryDate', width: '15%' },
    { displayName: 'STATUS', name: 'isActive', width: '10%' },
    { displayName: 'ACTION', name: 'action', width: '4%' },
  ];

  CGroupcolumns: TableColumn[] = [
    { displayName: 'Group Name', name: 'groupName', width: '15%' },
    { displayName: 'Group ID', name: 'groupId', width: '15%' },
    { displayName: 'Customers', name: 'customerCount', width: '10%' },
    { displayName: 'ACH', name: 'achTransactionType', width: '10%' },
    { displayName: 'STATUS', name: 'customerGroupStatus', width: '10%' },
    { displayName: 'Created By', name: 'enteredBy', width: '20%' },
    { displayName: 'ACTION', name: 'customerGroupAction', width: '10%' }
  ];

  allColumns: TableColumn[];

  filterFormGroup: FormGroup;

  typeList = [
    { key: 'All', value: 'All' },
    { key: 0, value: 'Individual' },
    { key: 1, value: 'Company' },
  ];
  states: any[] = [];
  advanceFilters: string = '';
  advanceFilterCount: number;
  advanceFiltersList: any;
  userRole: any;

  groupName: string = '';
  @ViewChild('customerGroupDialog') customerGroupDialog!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;

  @ViewChild('drawer') drawer: MatDrawer;
  @ViewChild('drawerCustomerGroup') drawerCustomerGroup: MatDrawer;

  achTransactionTypeList = [
    { key: 'All', value: 'All' },
    { key: 0, value: 'Credit' },
    { key: 1, value: 'Debit' },
    { key: 2, value: 'Credit & Debit' }
  ];

  customerGroupStatusList = [
    { key: 'All', value: 'All' },
    { key: 0, value: 'Active' },
    { key: 1, value: 'Draft' }
  ];

  customerGroupFilterFormGroup: FormGroup;
  advanceCustomerGroupFilters: string = '';
  advanceCustomerGroupFilterCount: number;
  advanceCustomerGroupFiltersList: any;
  filterCustomerGroup: ListFilter = '';
  deletedFilterCustomerGroup = false;
  recurringFilterCustomerGroup = false;
  allCustomerGroupColumns: TableColumn[];

  pageCustomerGroup: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortByCustomerGroup: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  selectedIndex: any = 0;

  constructor(
    private router: Router,
    private customerService: CustomerService,
    private customerGroupService: CustomerGroupService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.allColumns = this.columns;
    this.allCustomerGroupColumns = this.CGroupcolumns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    if (!this.router.url.includes('customers'))
      localStorage.removeItem('customers');
    localStorage.removeItem('customerGroups');

    this.filter = this._commonService.getListDataFromLocalStorage(
      'customersGridFilter',
      'customers',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customersGridPageIndex',
          'customers',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customersGridPageSize',
          'customers',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'customersGridSortActive',
        'customers',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'customersGridSortDirection',
        'customers',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'customersAdvanceFilter',
      'customers',
      ''
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'customersAdvanceFilterForm',
        'customers',
        JSON.stringify(new CustomerFilterModel())
      )
    );

    let filterModel = new CustomerFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);

    this.filterCustomerGroup = this._commonService.getListDataFromLocalStorage(
      'customerGroupsGridFilter',
      'customerGroups',
      ''
    );

    this.pageCustomerGroup = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customerGroupsGridPageIndex',
          'customerGroups',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customerGroupsGridPageSize',
          'customerGroups',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortByCustomerGroup = {
      active: this._commonService.getListDataFromLocalStorage(
        'customerGroupsGridSortActive',
        'customerGroups',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'customerGroupsGridSortDirection',
        'customerGroups',
        'desc'
      ),
    };

    this.advanceCustomerGroupFilters = this._commonService.getListDataFromLocalStorage(
      'customerGroupsAdvanceFilter',
      'customerGroups',
      ''
    );
    this.advanceCustomerGroupFiltersList = this.advanceCustomerGroupFilters.split('|');
    this.advanceCustomerGroupFilterCount = this.advanceCustomerGroupFiltersList.length - 1;

    let filterCustomerGroupFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'customerGroupsAdvanceFilterForm',
        'customerGroups',
        JSON.stringify(new CustomerGroupFilterModel())
      )
    );

    let filterCustomerGroupModel = new CustomerGroupFilterModel();
    Object.assign(filterCustomerGroupModel, filterCustomerGroupFormData);
    this.customerGroupFilterFormGroup = this.formBuilder.formGroup(filterCustomerGroupModel);
  }

  onDelete(id: string) { }

  onRefresh(id: string) { }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    //this.restForm();
    this.selection.clear();
    if (sortBy == '') {
      sortDirection = 'desc'
    }
    this.customerService
      .GetAllCustomers(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();

          if (data.data != null) {
            this.data = data.data.customers;
            this.data.forEach((element) => {
              if (element.address2)
                element.address1 = element.address1 + ', ' + element.address2;
              if (element.city)
                element.address1 = element.address1 + ', ' + element.city;
              if (element.state)
                element.address1 = element.address1 + ', ' + element.state;
              if (element.zip)
                element.address1 = element.address1 + ', ' + element.zip;
            });
            this.page.length = data.data.totalRecords;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) { }

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  protected onRecurringFilter(event: boolean = false) {
    this.recurringFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      // if (typeof this.filter === 'string' && this.filter) {
      //   this.filter = this.filter.replace("'", "''");
      // }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'customersGridFilter',
        'customers'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'customersGridPageSize',
        'customers'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'customersGridPageIndex',
        'customers'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'customersGridSortActive',
        'customers'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'customersGridSortDirection',
        'customers'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  public onRowClick(customer: any) {
    this.router.navigate(['customers/customer-profile', customer.customerId]);
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      let filterModel = new CustomerFilterModel();
      filterModel.type = 'All';
      filterModel.state = 'All';
      this.filterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData?.['USA'];
    this.states = [
      { state: 'All', abbreviation: 'All', postalCode: 'All' },
    ].concat(this.states);
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customersGridPageSize',
          'customers',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'customersGridSortActive',
        'customers',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'customersGridSortDirection',
        'customers',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if ((data.type == 0 || data.type == 1) && data.type != 'All') {
      this.advanceFilters += 'type:' + data.type + '|';
    }
    if (data.state && data.state != 'All') {
      this.advanceFilters += 'state:' + data.state + '|';
    }
    if (data.city) {
      this.advanceFilters += 'city:' + data.city + '|';
    }
    if (data.zip) {
      this.advanceFilters += 'zip:' + data.zip + '|';
    }

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'customersAdvanceFilter',
      'customers'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'customersAdvanceFilterForm',
      'customers'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  AddCustomer() {
    this.router.navigate(['customers/manage-customer']);
  }

  AddCustomerGroup() {
    if (this.createCustomerGrpFormGroup.valid) {
      this.customerGroupService.CreateCustomerGroup(this.createCustomerGrpFormGroup.value).subscribe(
        (data) => {
          this.isLoading = false;

          if (data.data != null) {
            this.router.navigate(['customer-group', data.data.id, data.data.groupName]);
            this.dialogRef.close();
            // this._toastrService.success(
            //   'Customer Group created! with Draft status'
            // );
          } else {
            this._toastrService.error(
              data.errorMessage
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
    } else {
      this.createCustomerGrpFormGroup.markAllAsTouched();
    }
  }

  OpenDialogForCustomerGrp() {
    this.dialogRef = this.dialog.open(this.customerGroupDialog, {
      width: '35%',
    });;
  }

  GetAllCustomerGroup(filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string) {
    this.isLoading = true;
    this.drawerCustomerGroup?.close();
    if (sortBy == '') {
      sortDirection = 'desc'
    }

    this.customerGroupService
      .getAllCustomerGroup(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceCustomerGroupFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawerCustomerGroup?.close();
          if (data.data != null) {
            this.customerGroupdata = data.data.customers;
            this.pageCustomerGroup.length = data.data.totalRecords;
          } else {
            this.customerGroupdata = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onCancel(): void {
    this.createCustomerGrpFormGroup.reset();
    this.createCustomerGrpFormGroup.markAsPristine();
    this.createCustomerGrpFormGroup.markAsUntouched();
    this.dialogRef.close();
  }

  deleteBasedOnSelection(status: any) {
    let type = status ? 'Activate' : 'Deactivate';
    let customerIdList = this.selection['_selected'].map(
      ({ customerId }) => customerId
    );
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: status ? false : true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            customerIds: customerIdList,
            status: status,
          };
          this.customerService
            .deactivateCustomer(statusUpdate)
            .subscribe((data) => {
              this.isLoading = false;
              if (data.code == 200) {
                this._toastrService.success(
                  'Customer status updated successfully!'
                );
                this.loadData(
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize,
                  this.advanceFilters
                );
              }
            });
        }
      });
    }
  }

  customerEdit(customerId: any) {
    this.router.navigate(['customers/manage-customer', customerId]);
  }

  customerGroupView(customerGroupId: any) {
    this.router.navigate(['view-customer-group', customerGroupId]);
  }

  customerGroupEdit(customerGroupId: any) {
    this.router.navigate(['customer-group', customerGroupId]);
  }

  // customerGroupDelete(customerGroupId: any, isActive: boolean) {
  //   let type = isActive ? "Deactivate" : "Activate";
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     height: '200px',
  //     data: {
  //       delete: true,
  //       buttonName: type.toUpperCase(),
  //       successMessage: 'Are you sure you want to ' + type + ' this Customer Group?',
  //       subSuccessMessage:
  //         'Clicking on ' + type + ' button will ' + type.toLowerCase() + ' this customer group',
  //     },
  //   });
    
  //   dialogRef.afterClosed().subscribe((confirmed: boolean) => {
  //     if (confirmed) {
  //       this.customerGroupService.deleteCustomerGroupByGroupId(customerGroupId, !isActive)
  //         .subscribe((response) => {
  //           this.isLoading = false;
  //           if (response.data != null) {
  //             this._toastrService.success(
  //               //response.errorMessage
  //               "Customer Group Deleted Successfully"
  //             );
  //             this.GetAllCustomerGroup(this.filterCustomerGroup,
  //               this.sortByCustomerGroup.active,
  //               this.sortByCustomerGroup.direction,
  //               this.pageCustomerGroup.pageIndex,
  //               this.pageCustomerGroup.pageSize,
  //               this.advanceCustomerGroupFilters);
  //           }
  //           else {
  //             this.data = [];
  //             this._toastrService.error(
  //               response.errorMessage
  //             );
  //           }
  //         }),
  //         (error) => {
  //           this.data = [];
  //           this.isLoading = false;
  //           this._toastrService.error(
  //             error.errorMessage
  //           );
  //         };
  //     }
  //   });
  // }

  ngOnInit() {
    this.GetAllCustomerGroup(this.filterCustomerGroup,
      this.sortByCustomerGroup.active,
      this.sortByCustomerGroup.direction,
      this.pageCustomerGroup.pageIndex,
      this.pageCustomerGroup.pageSize,
      this.advanceCustomerGroupFilters);
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this.GetAllCustomerGroup(
        this.filterCustomerGroup,
        this.sortByCustomerGroup.active,
        this.sortByCustomerGroup.direction,
        this.pageCustomerGroup.pageIndex,
        this.pageCustomerGroup.pageSize,
        this.advanceCustomerGroupFilters
      );
    });

    this.filterEvent.subscribe(() => {
      this.onFilter.bind(this);
      this.onFilterCustomerGroup.bind(this);
    });

    this.deletedFilterEvent.subscribe(() => {
      this.onDeletedFilter.bind(this);
      this.onDeletedFilterCustomerGroup.bind(this);
    });

    this.recurringFilterEvent.subscribe(() => {
      this.onRecurringFilter.bind(this);
      this.onRecurringFilterCustomerGroup.bind(this);
    });

    this.createCustomerGrpModel = new CreateCustomerGroupModel();
    this.createCustomerGrpFormGroup = this.formBuilder.formGroup(this.createCustomerGrpModel);
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
    this.states = this._commonService._constantData?.['USA'];
    this.states = [
      { state: 'All', abbreviation: 'All', postalCode: 'All' },
    ].concat(this.states);

    // this.filterFormGroup.patchValue({ type: 'All', state: 'All' });

    this.activatedRoute.paramMap.subscribe((params) => {
      this.selectedIndex = params.get('selectedIndex') || 0;
    });
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if (!this.router.url.includes('customers'))
      localStorage.removeItem('customers');
    localStorage.removeItem('customerGroups');
  }

  restCustomerGroupFilterForm() {
    if (this.customerGroupFilterFormGroup) {
      this.customerGroupFilterFormGroup.reset();
      let customerGroupFilterModel = new CustomerGroupFilterModel();
      customerGroupFilterModel.achTransactionType = 'All';
      customerGroupFilterModel.status = 'All';
      this.customerGroupFilterFormGroup = this.formBuilder.formGroup(customerGroupFilterModel);
    }
  }

  applyCustomerGroupAdvanceFilter() {
    let data = this.customerGroupFilterFormGroup.value;
    this.pageCustomerGroup = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'customerGroupsGridPageSize',
          'customerGroups',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortByCustomerGroup = {
      active: this._commonService.getListDataFromLocalStorage(
        'customerGroupsGridSortActive',
        'customerGroups',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'customerGroupsGridSortDirection',
        'customerGroups',
        'desc'
      ),
    };

    this.advanceCustomerGroupFilters = '';
    if ((data.achTransactionType == 'Credit' || data.achTransactionType == 'Debit' || data.achTransactionType == 'Credit & Debit') && data.achTransactionType != 'All') {
      this.advanceCustomerGroupFilters += 'achTransactionType:' +
        (data.achTransactionType == 'Credit & Debit' ? 'Credit,Debit' : data.achTransactionType)
        + '|';
    }
    if ((data.status == 'Active' || data.status == 'Draft') && data.status != 'All') {
      this.advanceCustomerGroupFilters += 'status:' + data.status + '|';
    }

    this.advanceCustomerGroupFiltersList = this.advanceCustomerGroupFilters.split('|');
    this.advanceCustomerGroupFilterCount = this.advanceCustomerGroupFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      this.advanceCustomerGroupFilters.toString(),
      'customerGroupsAdvanceFilter',
      'customerGroups'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.customerGroupFilterFormGroup.value),
      'customerGroupsAdvanceFilterForm',
      'customerGroups'
    );

    this.GetAllCustomerGroup(
      this.filterCustomerGroup,
      this.sortByCustomerGroup.active,
      this.sortByCustomerGroup.direction,
      this.pageCustomerGroup.pageIndex,
      this.pageCustomerGroup.pageSize,
      this.advanceCustomerGroupFilters
    );
  }

  onFilterCustomerGroup(event: ListFilter = '') {
    if (this.filterCustomerGroup !== event) {
      this.pageCustomerGroup.pageIndex = 0;
      this.filterCustomerGroup = event;
      this.GetAllCustomerGroup(
        this.filterCustomerGroup,
        this.sortByCustomerGroup.active,
        this.sortByCustomerGroup.direction,
        this.pageCustomerGroup.pageIndex,
        this.pageCustomerGroup.pageSize,
        this.advanceCustomerGroupFilters
      );
      this._commonService.setListDataToLocalStorage(
        this.filterCustomerGroup.toString(),
        'customerGroupsGridFilter',
        'customerGroups'
      );
    }
  }

  protected onDeletedFilterCustomerGroup(event: boolean = false) {
    this.deletedFilterCustomerGroup = !!event;
    this.pageCustomerGroup.pageIndex = 0;
    this.GetAllCustomerGroup(
      this.filterCustomerGroup,
      this.sortByCustomerGroup.active,
      this.sortByCustomerGroup.direction,
      this.pageCustomerGroup.pageIndex,
      this.pageCustomerGroup.pageSize,
      this.advanceCustomerGroupFilters
    );
  }

  protected onRecurringFilterCustomerGroup(event: boolean = false) {
    this.recurringFilterCustomerGroup = !!event;
    this.pageCustomerGroup.pageIndex = 0;
    this.GetAllCustomerGroup(
      this.filterCustomerGroup,
      this.sortByCustomerGroup.active,
      this.sortByCustomerGroup.direction,
      this.pageCustomerGroup.pageIndex,
      this.pageCustomerGroup.pageSize,
      this.advanceCustomerGroupFilters
    );
  }

  onPageChangedCustomerGroup(event: PageChanged) {
    if (
      this.pageCustomerGroup.pageIndex !== event.pageIndex ||
      this.pageCustomerGroup.pageSize !== event.pageSize
    ) {
      this.pageCustomerGroup = event;
      this.GetAllCustomerGroup(
        this.filterCustomerGroup,
        this.sortByCustomerGroup.active,
        this.sortByCustomerGroup.direction,
        this.pageCustomerGroup.pageIndex,
        this.pageCustomerGroup.pageSize,
        this.advanceCustomerGroupFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'customerGroupsGridPageSize',
        'customerGroups'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'customerGroupsGridPageIndex',
        'customerGroups'
      );
    }
  }

  onSortChangedCustomerGroup(event: SortChanged) {
    const column1: TableColumn = this.CGroupcolumns[event.active];
    var column = this.CGroupcolumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortByCustomerGroup.active !== event.active ||
      this.sortByCustomerGroup.direction !== event.direction
    ) {
      this.sortByCustomerGroup = event;
      this.sortByMappingCustomerGroup();
      this.GetAllCustomerGroup(
        this.filterCustomerGroup,
        this.sortByCustomerGroup.active,
        this.sortByCustomerGroup.direction,
        this.pageCustomerGroup.pageIndex,
        this.pageCustomerGroup.pageSize,
        this.advanceCustomerGroupFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'customerGroupsGridSortActive',
        'customerGroups'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'customerGroupsGridSortDirection',
        'customerGroups'
      );
    }
  }

  sortByMappingCustomerGroup() {
    switch (this.sortByCustomerGroup.active) {
      case 'achTransactionType':
        this.sortByCustomerGroup.active = 'achTransactionType';
        break;
      case 'status':
        this.sortByCustomerGroup.active = 'status';
        break;
    }
  }
}
