declare var require: any;
const pdfMake = require('pdfmake/build/pdfmake.js');
import { formatDate } from '@angular/common';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getValueWithComma(valueToConvert): string {
  return valueToConvert.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function generatedDashboardPDF(
  content: any,
  fileName: string,
  data: any,
  type: string = ''
) {
  pdfMake.fonts = getFonts();

  var docDefinition = {
    pageSize: 'A4',
    content: content,
    pageBreakBefore: (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) => {
      if (
        currentNode.hasOwnProperty('id') &&
        currentNode.id.includes('breakPage_')
      ) {
        //Change these indexes depending on change in text/column/table addition in future
        return true;
      }
      return false;
    },
    styles: getStyles(),
    footer: getFooter(),
  };
  pdfMake.createPdf(docDefinition).download(fileName + '.pdf');
}

function getTransactionBasedOnListType(
  listToIterate: any,
  totalAmount: any,
  tableLayout: any,
  title: string,
  noRecordMessage: string
) {
  let content = [];
  if (listToIterate != null && listToIterate.length > 0) {
    content.push({
      text: title + ': ',
      style: 'infoHeader',
    });
    content.push('\n');

    let summary = {
      id: 'transactionSummaryHeader',
      columns: [
        {
          width: '50%',
          text: [
            'Total:  ',
            {
              text: listToIterate.length,
              style: 'sumHeaderValue',
            },
          ],
          style: 'sumHeader',
        },
        {
          width: '50%',
          text: [
            'Amount:  ',
            {
              text:
                '$' +
                getValueWithComma(
                  parseFloat(totalAmount.toString()).toFixed(2)
                ),
              style: 'sumHeaderValue',
            },
          ],
          style: 'sumHeader',
        },
      ],
      columnGap: 10,
    };
    content.push(summary);
    content.push('\n');

    let tableWidths = [];
    let tableHeader = [];
    let columnGap = 0;

    switch (title) {
      case 'Recurring Debits':
        tableWidths = ['15%', '21%', '35%', '17%', '12%'];
        tableHeader = [
          {
            text: 'CATEGORY',
            width: '15%',
            style: 'transactionTableHeader',
          },
          {
            text: 'SUB CATEGORY',
            width: '17%',
            style: 'transactionTableHeader',
          },
          {
            text: 'DESCRIPTION',
            width: '35%',
            style: 'transactionTableHeader',
          },
          { text: 'DATE', width: '17%', style: 'transactionTableHeader' },
          { text: 'AMOUNT', width: '12%', style: 'transactionTableHeader' },
        ];
        columnGap = 3;
        break;
      case 'Deposits':
        tableWidths = ['70%', '15%', '15%'];
        tableHeader = [
          {
            text: 'DESCRIPTION',
            width: '70%',
            style: 'transactionTableHeader',
          },
          { text: 'DATE', width: '15%', style: 'transactionTableHeader' },
          { text: 'AMOUNT', width: '15%', style: 'transactionTableHeader' },
        ];
        columnGap = 3;
        break;
      case 'Recurring Withdrawals':
        tableWidths = ['58%', '12%', '15%', '15%'];
        tableHeader = [
          {
            text: 'DESCRIPTION',
            width: '58%',
            style: 'transactionTableHeader',
          },
          { text: 'FREQUENCY', width: '12%', style: 'transactionTableHeader' },
          { text: 'DATE', width: '15%', style: 'transactionTableHeader' },
          { text: 'AMOUNT', width: '15%', style: 'transactionTableHeader' },
        ];
        columnGap = 4;
        break;
      case 'Transactions':
      tableWidths = ['20%', '20%', '30%', '15%' ,'15%'];
      tableHeader = [
        {
          text: 'TYPE',
          width: '20%',
          style: 'transactionTableHeader',
        },
        { text: 'RECURRING', width: '20%', style: 'transactionTableHeader' },
        { text: 'DESCRIPTION', width: '35%', style: 'transactionTableHeader' },
        { text: 'DATE', width: '15%', style: 'transactionTableHeader' },
        { text: 'AMOUNT', width: '15%', style: 'transactionTableHeader' },
      ];
      columnGap = 4;
      break;
    }

    let listContent = {
      layout: tableLayout,
      table: {
        widths: tableWidths,
        headerRows: 1,
        body: [tableHeader],
        columnGap: 3,
      },
    };

    listToIterate.forEach((data) => {
      switch (title) {
        case 'Recurring Debits':
          listContent.table.body.push([
            {
              text: data.category,
              width: '15%',
              style: 'transactionTableData',
            },
            {
              text: data.subCategory,
              width: '21%',
              style: 'transactionTableData',
            },
            {
              text: data.memo,
              width: '35%',
              style: 'transactionTableData',
            },
            {
              text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
              width: '17%',
              style: 'transactionTableData',
            },
            {
              text:
                '$' +
                getValueWithComma(
                  parseFloat(data.amount.toString()).toFixed(2)
                ),
              width: '12%',
              style: 'transactionTableData',
            },
          ]);
          break;
        case 'Deposits':
          listContent.table.body.push([
            {
              text: data.memo,
              width: '70%',
              style: 'transactionTableData',
            },
            {
              text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
              width: '15%',
              style: 'transactionTableData',
            },
            {
              text:
                '$' +
                getValueWithComma(
                  parseFloat(data.amount.toString()).toFixed(2)
                ),
              width: '15%',
              style: 'transactionTableData',
            },
          ]);
          break;
        case 'Recurring Withdrawals':
          listContent.table.body.push([
            {
              text: data.memo,
              width: '58%',
              style: 'transactionTableData',
            },
            {
              text: data.frequency,
              width: '12%',
              style: 'transactionTableData',
            },
            {
              text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
              width: '15%',
              style: 'transactionTableData',
            },
            {
              text:
                '$' +
                getValueWithComma(
                  parseFloat(data.amount.toString()).toFixed(2)
                ),
              width: '15%',
              style: 'transactionTableData',
            },
          ]);
          break;
        case 'Transactions':
          listContent.table.body.push([
            {
              text: data.memo,
              width: '58%',
              style: 'transactionTableData',
            },
            {
              text: data.frequency,
              width: '12%',
              style: 'transactionTableData',
            },
            {
              text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
              width: '15%',
              style: 'transactionTableData',
            },
            {
              text:
                '$' +
                getValueWithComma(
                  parseFloat(data.amount.toString()).toFixed(2)
                ),
              width: '15%',
              style: 'transactionTableData',
            },
          ]);
          break;
      }
    });

    content.push(listContent);
    content.push('\n');
  } else {
    content.push({
      text: noRecordMessage,
      style: 'infoHeader',
    });
    content.push('\n');
  }

  return content;
}

export function generatedIbvPdf(
  content: any,
  fileName: string,
  data: any,
  accountType: string = ''
) {
  pdfMake.fonts = getFonts();
  let tableLayout = getTableLayout();

  if (accountType == 'DDA' || accountType == 'SDA') {
    // content.push(
    //   getTransactionBasedOnListType(
    //     data.recurringDebitsList,
    //     data.totalRecurringDebitsAmount,
    //     tableLayout,
    //     'Recurring Debits',
    //     'No Deposit Found!'
    //   )
    // );

    // content.push(
    //   getTransactionBasedOnListType(
    //     data.depositList,
    //     data.totalDepositAmount,
    //     tableLayout,
    //     'Deposits',
    //     'No Deposit Found!'
    //   )
    // );

    // content.push(
    //   getTransactionBasedOnListType(
    //     data.recurringWithdrawalsList,
    //     data.totalRecurringWithdrawalAmount,
    //     tableLayout,
    //     'Recurring Withdrawals',
    //     'No Recurring Withdrawal Found!'
    //   )
    // );

    content.push(
      getTransactionBasedOnListType(
        data.recurringWithdrawalsList,
        data.totalRecurringWithdrawalAmount,
        tableLayout,
        'Transactions',
        'No Transactions Found!'
      )
    );

    
  }

  //For All Accounts
  if (
    data.transactionByCategoryList != null &&
    data.transactionByCategoryList.length > 0
  ) {
    content.push({
      text: 'Transactions by Categories: ',
      style: 'infoHeader',
    });
    content.push('\n');

    data.transactionByCategoryList.forEach((category, index) => {
      let categorySummary = {
        id: 'transactionSummaryHeader',
        columns: [
          {
            width: '35%',
            text: category.categoryName,
            style: 'sumHeader',
          },
          {
            width: '31%',
            text: [
              'Transactions:  ',
              {
                text: getValueWithComma(category.transactionCount),
                style: 'sumHeaderValue',
              },
            ],
            style: 'sumHeader',
          },
          {
            width: '31%',
            text: [
              accountType == 'DDA' ||
              accountType == 'SDA' ||
              accountType == 'CCA'
                ? 'Withdrawal Amount:  '
                : 'Amount:  ',
              {
                text:
                  '$ ' +
                  getValueWithComma(
                    parseFloat(
                      category.totalCateogoryAmount.toString()
                    ).toFixed(2)
                  ),
                style: 'sumHeaderValue',
              },
            ],
            style: 'sumHeader',
          },
        ],
        columnGap: 10,
      };
      content.push(categorySummary);
      content.push('\n');

      let tableHeader;
      let noTransactionColSpan;
      let tableWidths;

      switch (accountType) {
        case 'CCA':
          noTransactionColSpan = 5;
          tableWidths = ['15%', '10%', '45%', '10%', '20%'];
          tableHeader = [
            {
              text: 'SUB CATEGORY',
              width: '15%',
              style: 'transactionTableHeader',
            },
            { text: 'DATE', width: '10%', style: 'transactionTableHeader' },
            {
              text: 'DESCRIPTION',
              width: '45%',
              style: 'transactionTableHeader',
            },
            { text: 'TYPE', width: '10%', style: 'transactionTableHeader' },
            { text: 'AMOUNT', width: '20%', style: 'transactionTableHeader' },
          ];
          break;
        default:
          noTransactionColSpan = 4;
          tableWidths = ['20%', '10%', '50%', '20%'];
          tableHeader = [
            {
              text: 'SUB CATEGORY',
              width: '20%',
              style: 'transactionTableHeader',
            },
            { text: 'DATE', width: '10%', style: 'transactionTableHeader' },
            {
              text: 'DESCRIPTION',
              width: '50%',
              style: 'transactionTableHeader',
            },
            { text: 'AMOUNT', width: '20%', style: 'transactionTableHeader' },
          ];
          break;
      }

      if (category.categoryTransList.length > 0) {
        let transactionContent = {
          layout: tableLayout,
          table: {
            widths: tableWidths,
            headerRows: 1,
            body: [tableHeader],
            columnGap: noTransactionColSpan,
          },
        };

        category.categoryTransList.forEach((data) => {
          switch (accountType) {
            case 'CCA':
              transactionContent.table.body.push([
                {
                  text: data.subCategory,
                  width: '15%',
                  style: 'transactionTableData',
                },
                {
                  text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
                  width: '10%',
                  style: 'transactionTableData',
                },
                {
                  text: data.memo,
                  width: '45%',
                  style: 'transactionTableData',
                },
                {
                  text: data.transactionType,
                  width: '10%',
                  style: 'transactionTableData',
                },
                {
                  text:
                    '$' +
                    getValueWithComma(
                      parseFloat(data.amount.toString()).toFixed(2)
                    ),
                  width: '20%',
                  style: 'transactionTableData',
                },
              ]);
              break;
            default:
              transactionContent.table.body.push([
                {
                  text: data.subCategory,
                  width: '20%',
                  style: 'transactionTableData',
                },
                {
                  text: formatDate(data.postedDate, 'MM/dd/yyyy', 'en-US'),
                  width: '10%',
                  style: 'transactionTableData',
                },
                {
                  text: data.memo,
                  width: '50%',
                  style: 'transactionTableData',
                },
                {
                  text:
                    '$' +
                    getValueWithComma(
                      parseFloat(data.amount.toString()).toFixed(2)
                    ),
                  width: '20%',
                  style: 'transactionTableData',
                },
              ]);
              break;
          }
        });

        content.push(transactionContent);
      } else {
        let transactionContent = {
          layout: tableLayout,
          table: {
            headerRows: 1,
            widths: tableWidths,
            body: [
              tableHeader,
              [
                {
                  text: 'No Transactions Found!',
                  width: '100%',
                  style: 'notransactionTableData',
                  colSpan: noTransactionColSpan,
                },
              ],
            ],
          },
        };
        content.push(transactionContent);
      }

      if (index != data.length - 1) {
        content.push({ text: '\n', id: 'breakCheck_' + index });
      }
    });
  } else {
    content.push({
      text: 'No Transactions Found in any Category!',
      style: 'infoHeader',
    });
    content.push('\n');
  }

  var docDefinition = {
    pageSize: 'A4',
    content: content,
    pageBreakBefore: (
      currentNode,
      followingNodesOnPage,
      nodesOnNextPage,
      previousNodesOnPage
    ) => {
      if (
        currentNode.hasOwnProperty('id') &&
        currentNode.id.includes('breakCheck_')
      ) {
        //Change these indexes depending on change in text/column/table addition in future
        var logotext = localStorage.getItem('logotext');
        if (
          followingNodesOnPage[0] != null &&
          followingNodesOnPage[5] != null &&
          followingNodesOnPage[0].id == 'transactionSummaryHeader' &&
          followingNodesOnPage[5].text == 'Powered by '+ logotext + ' ,an Elavon payments partner & registered MSP/ISO of Elavon.'
        ) {
          return true;
        } else if (
          accountType == '' &&
          followingNodesOnPage[0] != null &&
          followingNodesOnPage[5] != null &&
          followingNodesOnPage[12] != null &&
          followingNodesOnPage[0].id == 'transactionSummaryHeader' &&
          followingNodesOnPage[5].pageNumbers.length > 1 &&
          followingNodesOnPage[12].text == 'Powered by '+ logotext + + ' ,an Elavon payments partner & registered MSP/ISO of Elavon.'
        ) {
          return true;
        } else if (
          accountType == 'INV' &&
          followingNodesOnPage[0] != null &&
          followingNodesOnPage[5] != null &&
          followingNodesOnPage[12] != null &&
          followingNodesOnPage[0].id == 'transactionSummaryHeader' &&
          followingNodesOnPage[5].pageNumbers.length > 1 &&
          followingNodesOnPage[14].text == 'Powered by ' + logotext + + ' ,an Elavon payments partner & registered MSP/ISO of Elavon.'
        ) {
          return true;
        }
      } else if (
        currentNode.hasOwnProperty('id') &&
        currentNode.id.includes('breakPage_')
      ) {
        return true;
      }
      return false;
    },
    styles: getStyles(),
    footer: getFooter(),
  };
  pdfMake.createPdf(docDefinition).download(fileName + '.pdf');
}

function getTableLayout() {
  return {
    hLineWidth: function (i, node) {
      if (i == 1 || i == 0) return 0.4;
      else if (node.table.body.length == i) return 0.4;
      else return 0;
    },
    hLineColor: function (i, node) {
      return 'gray';
    },
    vLineWidth: function (i, node) {
      if (i == 0) return 0.4;
      else if (node.table.widths.length == i) return 0.4;
      else return 0;
    },
    vLineColor: function (i, node) {
      return 'gray';
    },
    fillColor: function (rowIndex, node, columnIndex) {
      return rowIndex == 0 ? '#0db9f0' : 'white';
    },
  };
}

function getFonts() {
  return {
    // Default font should still be available
    Roboto: {
      normal: 'Roboto-Regular.ttf',
      bold: 'Roboto-Medium.ttf',
      italics: 'Roboto-Italic.ttf',
      bolditalics: 'Roboto-Italic.ttf',
    },
    Poppins: {
      normal: `${window.location.origin}/assets/fonts/poppinsFont/Poppins-Regular.ttf`,
      bold: `${window.location.origin}/assets/fonts/poppinsFont/Poppins-Bold.ttf`,
      italics: `${window.location.origin}/assets/fonts/poppinsFont/Poppins-Italic.ttf`,
    },
  };
}

function getStyles() {
  return {
    infoHeader: {
      font: 'Poppins',
      fontSize: 10,
      color: '#148F77',
      bold: true,
    },
    sumHeader: {
      font: 'Poppins',
      fontSize: 8,
      bold: true,
      color: 'black',
    },
    sumHeaderValue: {
      font: 'Poppins',
      fontSize: 8,
      color: '#148F77',
      bold: true,
    },
    transactionTableHeader: {
      font: 'Poppins',
      fontSize: 7,
      color: 'white',
      bold: true,
    },
    transactionTableData: {
      font: 'Poppins',
      fontSize: 7,
      color: '#696969',
      normal: true,
    },
    notransactionTableData: {
      font: 'Poppins',
      fontSize: 7,
      color: '#696969',
      normal: true,
      alignment: 'center',
    },
    verified: {
      font: 'Poppins',
      fontSize: 7,
      color: '#4CAF50',
      bold: true,
    },
    nonVerified: {
      font: 'Poppins',
      fontSize: 7,
      color: '#FB8C00',
      bold: true,
    },
    footer: {
      font: 'Poppins',
      fontSize: 6,
      color: '#A9A9A9',
      normal: true,
      margin: [500, 10, 0, 0],
    },
    defaultStyle: {
      font: 'Poppins',
      fontSize: 7,
      bold: false,
    },
  };
}

function getFooter() {
  var columnslogotext = 'Powered by ' + localStorage.getItem('logotext');
  return {
    columns: [{ text: columnslogotext, style: 'footer' }],
  };
}

export function getDetailsWithStatus(
  name: string,
  status: string,
  title: string,
  infoType: string
) {
  let verified: string = 'VERIFIED';
  let detailsWithStatus: any;

  if (infoType != 'Customer' && infoType != 'Bank') {
    detailsWithStatus = {
      id: 'detailsWithStatus' + infoType,
      columns: [
        {
          width: '100%',
          text: [
            title + ':  ',
            {
              text: name + ' ',
              style: 'transactionTableData',
            },
          ],
          style: 'sumHeader',
        },
      ],
    };
  } else {
    detailsWithStatus = {
      id: 'detailsWithStatus' + infoType,
      columns: [
        {
          width: '100%',
          text: [
            title + ':  ',
            {
              text: name + ' ',
              style: 'transactionTableData',
            },
            {
              text: '(' + status + ')',
              style: status == verified ? 'verified' : 'nonVerified',
            },
          ],
          style: 'sumHeader',
        },
      ],
    };
  }
  return detailsWithStatus;
}
