<mat-card class="transactions-card">
  <mat-spinner
    *ngIf="isLoading"
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
  <div *ngIf="!isLoading">

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
    >
      <div fxLayout="column" fxFlex="20%" class="transaction-column">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="start stretch"
          style="padding: 0px 16px"
        >
          <mat-form-field appearance="outline">
            <span matPrefix>
              <mat-icon style="color: gray">search</mat-icon>
            </span>
            <input
              type="text"
              placeholder="Category Name"
              matInput
              [formControl]="myControl"
              [matAutocomplete]="auto"
              style="font-size: 13px"
            />
            <button
              mat-button
              *ngIf="myControl.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
            >
              <mat-icon (click)="onClearText()">close</mat-icon>
            </button>

            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              (optionSelected)="onSelectionChange($event)"
            >
              <mat-option
                *ngFor="let option of filteredOptions | async"
                [value]="option"
              >
                <span class="option-text">{{ option }}</span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-accordion multi style="width: 100%">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="left-pane-icon">star_rate</mat-icon> &nbsp;
                  FAVORITE CATEGORY
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="favoriteCategoryList.length !== 0">
                <mat-nav-list *ngFor="let favCategory of favoriteCategoryList">
                  <mat-list-item
                    [ngClass]="getClassCategoryName(favCategory.categoryId)"
                    (click)="
                      openSelectedCategoryDetails(favCategory.categoryId)
                    "
                  >
                    <div
                      fxLayout="row"
                      fxLayout.xs="column"
                      fxLayoutGap="16px"
                      fxLayoutAlign="center center"
                    >
                      <div fxLayout="column">
                        <img
                          class="category-icon"
                          src="{{
                            getCategoryIconSrc(favCategory.categoryIcon)
                          }}"
                        />
                      </div>
                      <div fxLayout="column" fxLayoutWrap>
                        {{ favCategory.categoryName }}
                      </div>
                    </div>
                  </mat-list-item>
                </mat-nav-list>
              </div>

              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="start stretch"
                *ngIf="favoriteCategoryList.length === 0"
              >
                <span class="noStarCategory"
                  >No Favorite Categories Available!</span
                >
              </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <img
                    class="category-icon"
                    src="/assets/ibv-report-icons/categories/category.png"
                  />
                  &nbsp; CATEGORIES
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngIf="categoryList.length !== 0">
                <mat-nav-list *ngFor="let category of categoryList">
                  <mat-list-item
                    [ngClass]="getClassCategoryName(category.categoryId)"
                    (click)="openSelectedCategoryDetails(category.categoryId)"
                  >
                    <div
                      fxLayout="row"
                      fxLayout.xs="column"
                      fxLayoutGap="16px"
                      fxLayoutAlign="center center"
                    >
                      <div fxLayout="column">
                        <img
                          class="category-icon"
                          src="{{ getCategoryIconSrc(category.categoryIcon) }}"
                        />
                      </div>
                      <div fxLayout="column" fxLayoutWrap>
                        {{ category.categoryName }}
                      </div>
                    </div>
                  </mat-list-item>
                </mat-nav-list>
              </div>

              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="start stretch"
                *ngIf="categoryList.length === 0"
              >
                <span class="noStarCategory">No Categories Available!</span>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div
        fxLayout="column"
        fxFlex="80%"
        class="transaction-column"
        *ngIf="selectedCategory !== null"
      >
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          style="margin-top: 2%; padding: 16px"
        >
          <div fxLayout="column">
            <span class="transactions-text">{{
              selectedCategory?.categoryName
            }}</span>
          </div>

          <span class="spacer"></span>

          <div fxLayout="column" fxFlex="20%">
            <mat-card
              class="transaction-details-card"
              fxLayout="column"
              fxLayoutAlign="center stretch"
            >
              <div fxLayout="column" fxLayoutWrap>
                <div
                  fxLayout="row"
                  class="amount-value"
                  fxLayoutAlign="center center"
                  fxLayoutWrap
                >
                  {{ selectedCategory?.transactionCount }}
                </div>
                <div
                  fxLayout="row"
                  class="amount-name"
                  fxLayoutAlign="center center"
                  fxLayoutWrap
                >
                  Transactions
                </div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column" fxFlex="20%">
            <mat-card
              class="transaction-details-card"
              fxLayout="column"
              fxLayoutAlign="center stretch"
            >
              <div fxLayout="column" fxLayoutWrap>
                <div
                  fxLayout="row"
                  class="amount-value"
                  fxLayoutAlign="center center"
                  fxLayoutWrap
                >
                  ${{
                    selectedCategory?.totalCateogoryAmount | number : "1.2-2"
                  }}
                </div>
                <div
                  fxLayout="row"
                  class="amount-name"
                  fxLayoutAlign="center center"
                  fxLayoutWrap
                >
                  {{ amountLabel ?? "Amount" }}
                </div>
              </div>
            </mat-card>
          </div>
          <div fxLayout="column">
            <mat-divider [vertical]="true" class="divider-style"></mat-divider>
          </div>
          <div fxLayout="column" fxLayoutAlign="center end">
            <button
              mat-icon-button
              aria-label="Add Favorite"
              [matTooltip]="getFavoriteToolTip(selectedCategory)"
              (click)="addOrRemoveFavorite(selectedCategory)"
            >
              <mat-icon [ngStyle]="getSelectedCategoryColor(selectedCategory)"
                >star_rate</mat-icon
              >
            </button>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start stretch" class="grid-box"
        >
          <mat-spinner
            *ngIf="isTransactinTableLoading"
            class="transaction-table-spinner"
            mode="indeterminate"
            [diameter]="50"
            [strokeWidth]="3"
          >
          </mat-spinner>

          <app-data-table
            *ngIf="!isTransactinTableLoading"
            [columns]="columns"
            [data]="selectedCategory?.categoryTransList"
            [isLoading]="isLoading"
            [page]="
              pageCalc(
                selectedCategory?.transactionCount,
                selectedCategory?.categoryId
              )
            "
            [sortBy]="sortBy"
            [pageSizeOptions]="pageSizeOptions"
            [showMoreMenu]="true"
            [allColumns]="allColumns"
            [selection]="selection"
            [isExport]="false"
            [isFilter]="false"
            [isSearch]="true"
            (pageChangedEvent)="
              onPageChanged($event, selectedCategory?.categoryId)
            "
            (rowClickedEvent)="onRowClick($event)"
            (onFilterEvent)="onFilter($event,selectedCategory?.categoryId)"
            [filterText]="this.filter?.toString() ?? ''"
            (sortChangedEvent)="
              onSortChanged($event, selectedCategory?.categoryId)
            "
            [isInstantBankAnalysis]="true"
            [noRecordMessage]="noRecordsMessage"
          >
          </app-data-table>
        </div>
      </div>

      <div
        fxLayout="column"
        fxFlex="80%"
        class="transaction-column"
        *ngIf="selectedCategory === null"
      >
        <span
          ><i
            >Please select Categories from left pane to view Transaction
            details.</i
          ></span
        >
      </div>
    </div>
  </div>
</mat-card>
