<div fxLayout="row" fxLayoutAlign="start stretch">
    <div fxLayout="column" class="w-100" fxLayoutAlign="start stretch">
        <div class="preview-header" fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutWrap fxLayoutAlign="center start">
                <label class="header-label">Preview</label>
            </div>
            <div fxLayout="column" fxLayoutWrap fxLayoutAlign="center end">
                <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
                    <a mat-button [class]="{'hovers': viewType === webPageView}" class="button-text filter-color"
                        (click)="changeView(webPageView)">
                        <mat-icon>desktop_windows</mat-icon>
                    </a>
                    <a mat-button [class]="{'hovers': viewType === tabPageView}" class="button-text filter-color"
                        (click)="changeView(tabPageView)">
                        <mat-icon>tablet_mac</mat-icon>
                    </a>
                    <a mat-button [class]="{'hovers': viewType === mobilePageView}" class="button-text filter-color"
                        (click)="changeView(mobilePageView)">
                        <mat-icon>phone_iphone</mat-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-divider class="mat-divider-preview" style="top:62"></mat-divider>
<div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutWrap>
    <ng-container *ngIf="viewType == webPageView">
        <app-hosted-payment-page-web-preview [finzeoPaymentOption]="finzeoPaymentOption" [changesIsLoading]="changesIsLoading" [formData]="formData" [pageIndex]="pageIndex"
            class="w-100"></app-hosted-payment-page-web-preview>
    </ng-container>
    <ng-container *ngIf="viewType == tabPageView">
        <app-hosted-payment-page-tab-preview [finzeoPaymentOption]="finzeoPaymentOption" [changesIsLoading]="changesIsLoading" [formData]="formData" [pageIndex]="pageIndex"
            class="w-100"></app-hosted-payment-page-tab-preview>
    </ng-container>
    <ng-container *ngIf="viewType == mobilePageView">
        <app-hosted-payment-page-mobile-preview [finzeoPaymentOption]="finzeoPaymentOption" [changesIsLoading]="changesIsLoading" [formData]="formData" [pageIndex]="pageIndex"
            class="w-100"></app-hosted-payment-page-mobile-preview>
    </ng-container>
</div>
<div fxLayout="row" class="m-t-5">
    <div fxLayout="column" fxFlex="100%">
        <mat-card class="payment-page-preview payment-page-bottom-box ">
            <ng-container *ngIf="viewType == webPageView">
                <app-hosted-payment-page-btm-preview-web [finzeoPaymentOption]="finzeoPaymentOption" [formData]="formData"
                    (onPageChangeEvent)="onPageChange($event)" [changesIsLoading]="changesIsLoading"></app-hosted-payment-page-btm-preview-web>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="33%">
                        <img src=" {{ getIndexImage(1) }}" alt="done"
                            class="overlay-image-upper-1 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="33%">
                        <img src="{{getIndexImage(2)}}" alt="done"
                            class="overlay-image-upper-2 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="30%">
                        <img src="{{getIndexImage(3)}}" alt="done"
                            class="overlay-image-upper-3 payment-cards-index-logo" />
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="viewType == tabPageView">
                <app-hosted-payment-page-btm-preview-tab [finzeoPaymentOption]="finzeoPaymentOption" [formData]="formData"
                    (onPageChangeEvent)="onPageChange($event)" [changesIsLoading]="changesIsLoading"></app-hosted-payment-page-btm-preview-tab>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="23%">
                        <img src="{{getIndexImage(1)}}" alt="done"
                            class="overlay-image-upper-1 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="23%">
                        <img src="{{getIndexImage(2)}}" alt="done"
                            class="overlay-image-upper-2 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="30%">
                        <img src="{{getIndexImage(3)}}" alt="done"
                            class="overlay-image-upper-3 payment-cards-index-logo" />
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="viewType == mobilePageView">
                <app-hosted-payment-page-btm-preview-mobile [finzeoPaymentOption]="finzeoPaymentOption" [formData]="formData"
                    (onPageChangeEvent)="onPageChange($event)" [changesIsLoading]="changesIsLoading"></app-hosted-payment-page-btm-preview-mobile>
                <div fxLayout="row">
                    <div fxLayout="column" fxFlex="18%">
                        <img src="{{getIndexImage(1)}}" alt="done"
                            class="overlay-image-upper-1 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="18%">
                        <img src="{{getIndexImage(2)}}" alt="done"
                            class="overlay-image-upper-2 payment-cards-index-logo" />
                    </div>
                    <div fxLayout="column" fxFlex="30%">
                        <img src="{{getIndexImage(3)}}" alt="done"
                            class="overlay-image-upper-3 payment-cards-index-logo" />
                    </div>
                </div>
            </ng-container>
        </mat-card>

    </div>
</div>