import { SelectionModel } from '@angular/cdk/collections';
import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { AchTransactionsService } from 'src/app/featureModules/services/ach-transactions.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ExportDialogComponent } from 'src/app/sharedModules/components/dialogs/export-dialog/export-dialog.component';
import { ImportDialogComponent } from 'src/app/sharedModules/components/dialogs/import-dialog/import-dialog.component';
import { achTransactionsFilterModel } from 'src/app/sharedModules/models/ach-transaction-filter.model';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

interface DrowpDown {
  key: string;
  value: string;
}
const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customer-ach-transaction',
  templateUrl: './customer-ach-transaction.component.html',
  styleUrls: ['./customer-ach-transaction.component.scss'],
})
export class CustomerAchTransactionComponent implements OnInit, OnDestroy {
  customerId;
  isLoading = false;
  filter: ListFilter = '';
  customerModel: AddCustomerModel;
  customerInitial: string = 'P';
  customerTransactionsData;
  private timeout: number;

  entryTypeList: DrowpDown[] = [
    { key: 'All', value: 'All' },
    { key: 'Credit', value: 'Credit' },
    { key: 'Debit', value: 'Debit' },
  ];

  dateRangeList: DrowpDown[] = [
    { key: 'All', value: 'All' },
    { key: 'Last3Months', value: 'Last 3 Months' },
    { key: 'Today', value: 'Today' },
    { key: 'Yesterday', value: 'Yesterday' },
    { key: 'LastWeek', value: 'Last 7 Days' },
    { key: 'LastMonth', value: 'Last Month' },
  ];

  amountRangeList: DrowpDown[] = [
    { key: 'All', value: 'All' },
    { key: '1to100', value: '1 to 100' },
    { key: '100to500', value: '100 to 500' },
    { key: '500to1000', value: '500 to 1000' },
    { key: '1000to1500', value: '1000 to 1500' },
    { key: '1500to2000', value: '1500 to 2000' },
    { key: '2000to2500', value: '2000 to 2500' },
    { key: '3000to3500', value: '3000 to 3500' },
    { key: '4000to4500', value: '4000 to 4500' },
    { key: '5000to5500', value: '5000 to 5500' },
    { key: '6000to6500', value: '6000 to 6500' },
  ];

  statusList = [
    { key: 'All', value: 'All' },
    { key: 'Successful', value: 'Successful' },
    { key: 'Pending', value: 'Pending'},  
    { key: 'Failed', value: 'Failed' },
    { key: 'Returned', value: 'Returned' },
    { key: 'Cancelled', value: 'Cancelled' },
    { key: 'UNDER REVIEW', value: 'UNDER REVIEW'}  
  ];
  columns: TableColumn[] = [
    { displayName: 'TRACKING ID', name: 'TrackingId', width: '15%' },
    { displayName: 'CODE', name: 'EntryCode', width: '6%' },
    { displayName: 'CREDIT/ DEBIT', name: 'PaymentType', width: '9%' },
    { displayName: 'BANK ACCT.', name: 'BankAccountNumber', width: '11%' },
    { displayName: 'CREATED', name: 'createdDate', width: '9%' },
    { displayName: 'EFFECTIVE', name: 'EffectiveDate', width: '9%' },
    { displayName: 'CONVEN. FEE', name: 'surchargeAmount', width: '15%' },
    { displayName: 'AMOUNT', name: 'Amount', width: '8%' },
    { displayName: 'STATUS', name: 'Status', width: '10%' },
    { displayName: 'RECURRING', name: 'HasRecurring', width: '11%' ,className:'centerAlign'},
    {
      displayName: 'ACTION',
      name: 'achAction',
      width: '7%',
      className: 'centerAlign',
    },
  ];

  recurringColumns: TableColumn[] = [
    { displayName: 'Select', name: 'select', width: '3%' },
    { displayName: 'TRACKING ID', name: 'TrackingId', width: '15%' },
    { displayName: 'CODE', name: 'EntryCode', width: '6%' },
    {
      displayName: 'CREDIT/ DEBIT',
      name: 'PaymentType',
      width: '9%',
    },
    {
      displayName: 'EFFECTIVE',
      name: 'EffectiveDate',
      width: '9%',
    },
    {
      displayName: 'INSTALLMENTS',
      name: 'Installments',
      width: '11%',
    },
    { displayName: 'FREQUENCY', name: 'Frequency', width: '9%' },
    { displayName: 'CONVEN. FEE', name: 'surchargeAmount', width: '10%' },
    { displayName: 'AMOUNT', name: 'Amount', width: '10%' },
    { displayName: 'TOTAL', name: 'Total', width: '10%' },
    { displayName: 'STATUS', name: 'Status', width: '10%' },
    {
      displayName: 'ACTION',
      name: 'achAction',
      width: '7%',
      className: 'centerAlign',
    },
  ];

  allColumns: TableColumn[];

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'EffectiveDate',
    direction: 'asc',
  };

  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  loadMessage: string = '';
  isLoadingPaging = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  isCustomerDataLoaded = false;
  isCustomerStatsLoaded = false;
  isOtherDataLoaded = false;
  isAccess = false;
  userRole: any;
  totalTransactions: number = 0;
  totalAmount: number = 0;
  successfulCount: number = 0;
  pendingCount: number = 0;
  failedCount: number = 0;
  returnCount: number = 0;
  sentCount: number = 0;
  cancelledCount: number = 0;
  transactionFilterFormGroup: FormGroup;
  showRecurring;
  boolean = false;
  recurringGridData = [];

  @ViewChild('drawer') drawer: MatDrawer;

  @Input()
  showTransctionHeader: boolean = true;
  advanceFilters: string = '';
  advanceFiltersList: any;
  advanceFilterCount: number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private notifyService: NotificationService,
    private _achTransactionService: AchTransactionsService,
    private dialog: MatDialog,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private _customerService: CustomerService
  ) {
    this.allColumns = this.columns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.filter = this._commonService.getListDataFromLocalStorage(
      'achCustomerGridFilter',
      'ach',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achCustomerGridPageIndex',
          'ach',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achCustomerGridPageSize',
          'ach',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortActive',
        'ach',
        'EffectiveDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortDirection',
        'ach',
        'asc'
      ),
    };
    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'achCustomerAdvanceFilter',
      'ach',
      ''
    );
   
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'achCustomerAdvanceFilterForm',
        'ach',
        JSON.stringify(new achTransactionsFilterModel())
      )
    );

    let filterModel = new achTransactionsFilterModel();
    Object.assign(filterModel, filterFormData);
    this.transactionFilterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
    if (this.customerId) {
      this.isCustomerDataLoaded = false;
      this.isCustomerStatsLoaded = false;
      this.isOtherDataLoaded = false;
      this.isLoading = true;
      this.getCustomerDetailsById();
      this.getCustomerAchTransactionStats(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );
    }
  }

  onExport(event: any) {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      height: '65%',
      width: '50%',
    });
  }
  onImport(event: any) {
    const dialogRef = this.dialog.open(ImportDialogComponent);
  }
  onRefresh(id: string) {}

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'achCustomerGridFilter',
        'ach'
      );
    }
  }

  loadData(
    customerId,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    showRecurring: boolean,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this._achTransactionService
      .getCustomerAchTransaction(
        customerId,
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters,
        this.showRecurring
      )
      .subscribe(
        (data) => {
          
          this.isAccess = true;
          this.drawer?.close();
          if (data.data != null) {
            var list = [];
            data.data?.items.map((item) => {
              let record = {
                surchargeAmount: item.surchargeAmount,
                Amount: item.displayAmount,
                PaymentType: item.entryType,
                Frequency: item.frequency,
                EffectiveDate: item.effectiveDate,
                Installments: item.installments,
                Total: item.total,
                TrackingId: item.trackingId,
                EntryCode: item.code,
                Status: item.status,
                CompletedDate: item.completedDate,
                createdDate: item.createdDate,
                ReturnStatusCode: item.returnStatusCode,
                ReturnMessage: item.returnMessage,
                HasRecurring: item.hasRecurring,
                achAction: item.achAction,
                BankAccountNumber: this.maskBankAccountNumber(item.bankAccountNumber),
                AccountType : item.accountType.toUpperCase(),
              };
              list.push(record);
            });
            if (this.showRecurring) {
              this.recurringGridData = list;
            } else {
              this.gridData = list;
            }
            this.page.length = data.data.totalItems;
          } else {
            this.gridData = [];
          }
          this.isOtherDataLoaded = true;
          if (this.isCustomerDataLoaded && this.isCustomerStatsLoaded)
            this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.isAccess = false;
          if (err.status == 401) {
            this.notifyService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');
            this.userRole == '1'
              ? window.location.replace('csr')
              : window.location.replace('/');
          } else if (err.status == 403) {
            this.isAccess = false;
          } else {
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        }
      );
  }

  maskBankAccountNumber = (accountNumber) => 
    accountNumber.length > 4 
      ? accountNumber.replace(/.(?=.{4})/g, '*') 
      : accountNumber.replace(/.(?=.{2})/g, '*');

  getCustomerAchTransactionStats(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    customerType?: boolean
  ) {
    this.isLoading = true;
    this._achTransactionService
      .getCustomerAchSummaryTransactions(
        this.customerId,
        filter.toString(),
        customerType,
        'TransactionCount',
        sortDirection,
        page,
        pageSize
      )
      .subscribe(
        (data) => {
          if (data.data != null) {
            var item = data.data.items[0];
            // this.customerModel = {
            //   firstName: item.customerName?.trim().split(' ')?.[0],
            //   lastName: item.customerName?.trim().split(' ')?.[1],
            //   email: item.email,
            // };
            if(item != undefined || item != null){
            this.totalTransactions = item.transactionCount;
            this.totalAmount = item.totalAmount;
            this.successfulCount = item.successCount;
            this.pendingCount = item.pendingCount;
            this.failedCount = item.failedCount;
            this.returnCount = item.returnCount;
            this.sentCount = item.sentCount;
            this.cancelledCount = item.canceledCount;
          }
          else{
            this.totalTransactions = 0;
            this.totalAmount = 0;
            this.successfulCount = 0;
            this.pendingCount = 0;
            this.failedCount = 0;
            this.returnCount = 0;
            this.sentCount = 0;
            this.cancelledCount = 0;
          }
        }
          this.isCustomerStatsLoaded = true;
          if (this.isCustomerDataLoaded && this.isOtherDataLoaded)
            this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
  getCustomerDetailsById() {
    this._customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        if (data.data != null) {
          this.customerModel = data.data;
        }
        this.isCustomerDataLoaded = true;
        if (this.isCustomerStatsLoaded && this.isOtherDataLoaded)
          this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0))?.trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1))?.trim().toUpperCase();
    }
    return null;
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'achCustomerGridPageSize',
        'ach'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'achCustomerGridPageIndex',
        'ach'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.selection.clear();
      this.loadData(
        this.customerId,
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.showRecurring,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'achCustomerGridSortActive',
        'ach'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'achCustomerGridSortDirection',
        'ach'
      );
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {
    // this.router.navigate([
    //   'customer-refresh-details',
    //   { customerId: customerId },
    // ]);
  }

  onAddClick() {
    this.router.navigate(['add-ach', this.customerId], {
      state: {
        url: '/customer-ach/' + this.customerId,
      },
    });
  }

  navigateToACHTransactionsPage() {
    this.router.navigate(['/transactions']);
  }

  navigationToPage() {}

  public restForm() {
    if (this.transactionFilterFormGroup) {
      this.transactionFilterFormGroup.reset();
      let filterModel = new achTransactionsFilterModel();
      filterModel.status = 'All';
      this.transactionFilterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public applyAdvanceFilter() {
    let data = this.transactionFilterFormGroup.value;
    data.scheduledStartDate?.setHours(12, 0, 0);
    data.scheduledEndDate?.setHours(12, 0, 0);
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achCustomerGridPageSize',
          'ach',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortActive',
        'ach',
        'EffectiveDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortDirection',
        'ach',
        'asc'
      ),
    };
    this.advanceFilters = '';
    if (data.status && data.status != 'All') {
      this.advanceFilters += 'status:' + data.status + '|';
    }
    if (data.scheduledStartDate && data.scheduledEndDate) {
      this.advanceFilters +=
        'scheduledDate:' +
        getSafeIsoDateString(data.scheduledStartDate) +
        'to' +
        getSafeIsoDateString(data.scheduledEndDate) +
        '|';
    }
    if (data.dateRange && data.dateRange != 'All') {
      let dateConvert: string;
      dateConvert = this.convertDate(data.dateRange);
      this.advanceFilters +=
        'dateRange:' +
        dateConvert +
        'to' +
        formatDate(new Date(), 'yyyy/MM/dd', 'en') +
        '|';
    }
    if (data.amountRange && data.amountRange != 'All') {
      this.advanceFilters += 'amountRange:' + data.amountRange + '|';
    }

    if (data.entryType && data.entryType != 'All') {
      this.advanceFilters += 'entryType:' + data.entryType + '|';
    }

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'achCustomerAdvanceFilter',
      'ach'
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.transactionFilterFormGroup.value),
      'achCustomerAdvanceFilterForm',
      'ach'
    );

    this.loadData(
      this.customerId,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.showRecurring,
      this.advanceFilters
    );
  }

  recurringTransaction(event) {
    this.showRecurring = event.checked;
    this.selection.clear();
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achCustomerGridPageSize',
          'ach',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortActive',
        'ach',
        'EffectiveDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'achCustomerGridSortDirection',
        'ach',
        'asc'
      ),
    };
    this.loadData(
      this.customerId,
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.showRecurring,
      this.advanceFilters
    );
  }

  convertDate(dateRange: string) {
    let dateConvert: string;
    let d = new Date();
    if (dateRange == 'Last3Months') {
      d.setMonth(d.getMonth() - 3);
      dateConvert = formatDate(d, 'yyyy/MM/dd', 'en');
    } else if (dateRange == 'Today') {
      dateConvert = formatDate(d, 'yyyy/MM/dd', 'en');
    } else if (dateRange == 'Yesterday') {
      d.setDate(d.getDate() - 1);
      dateConvert = formatDate(d, 'yyyy/MM/dd', 'en');
    } else if (dateRange == 'LastWeek') {
      d.setDate(d.getDate() - 7);
      dateConvert = formatDate(d, 'yyyy/MM/dd', 'en');
    } else if (dateRange == 'LastMonth') {
      d.setMonth(d.getMonth() - 1);
      dateConvert = formatDate(d, 'yyyy/MM/dd', 'en');
    }
    return dateConvert;
  }

  deleteAchRecurringTransaction(trackingId: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '155px',
      data: {
        delete: true,
        buttonName: 'YES',
        successMessage:
          'Are you sure you want to cancel this recurring transaction?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;

        this._achTransactionService
          .DeleteAchRecurringTransaction(trackingId)
          .subscribe((data) => {            
            if (data.code === 200) {
              this._toastrService.success('Transaction cancelled successfully!');
            } else if (data.code === 400) {
              this._toastrService.error(data.errorMessage);
            }
            this.loadData(
              this.customerId,
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize,
              this.showRecurring,
              this.advanceFilters
            );
          });
      }
    });
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if (
      !(
        this.router.url.includes('transactions') ||
        this.router.url.includes('customer-ach') ||
        this.router.url.includes('add-ach')
      )
    ) {
      localStorage.removeItem('ach');
    }
  }
}
