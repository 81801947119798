<div class="page-header-bottom">
    <div class="backHeader">
        <mat-label>
            <button mat-icon-button (click)="navigateToPage()">
                <mat-icon>arrow_back </mat-icon>
            </button>
            Settings
        </mat-label>
    </div>
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Hosted Payment Page Customization</div>
                <div class="page-sub-header-text-1">
                    All Hosted Payment page setup related data can be managed from this page
                </div>
            </div>
        </div>
    </div>
</div>
<app-hosted-payment-page-setting-layout></app-hosted-payment-page-setting-layout>