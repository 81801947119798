import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  minNumber,
  maxNumber,
} from '@rxweb/reactive-form-validators';

export class CreditDebitTransactionModel {
  @prop()
  customerId: string;

  // @prop()
  // processorId:string

  @prop()
  customerCardId: string;

  @prop()
  @required()
  @minNumber({ value: 0.01 })
  @maxNumber({ value: 5000 })
  amount: number;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  scheduledDate: string;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  payFrequency: string;

  @prop()
  IsRecurring: boolean = false;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.IsRecurring == false ? false : true;
    },
  })
  @pattern({ expression: { Number: /^[1-9]*$/ }, message: 'Invalid Installments*' })
  installments: number;

  @prop()
  surchargeAmount: number;

  @prop()
  totalAmount: number;

  @prop()  
  cvv :string;
}
