import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private readonly snackBar: MatSnackBar, private readonly zone: NgZone) {
  }

  showWarn(message: string, duration: number = 3000): void {
    this.snackBar.open(message, 'X', { duration: duration, panelClass: ['warn'] });
  }

  showError(message: string,  duration: number = 5000): void {
    this.snackBar.open(message, 'X', { duration: duration, panelClass: ['error'] });
  }

  showSuccess(message: string,  duration: number = 3000): void {
    this.snackBar.open(message, "", { duration: duration, panelClass: ['success'] });
  }
}
