import { Inject, InjectionToken, Optional } from '@angular/core';
import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/sharedModules/services/authentication/authentication.service';
import { ChangeNotification } from '../../models/change-notification.model';

const SIGNALR_BASE_URL = new InjectionToken<string>('SIGNALR_BASE_URL');
// export const API_BASE_URL = new InjectionToken<string>("API_BASE_URL");
// export const COMPANY_IMG_URL = new InjectionToken<string>("COMPANY_IMG_URL");

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private readonly signalRbaseUrl: string = '';
  public Returndatainf: BehaviorSubject<ChangeNotification> =
    new BehaviorSubject<ChangeNotification>(null);
  public Returndataach: BehaviorSubject<ChangeNotification> =
    new BehaviorSubject<ChangeNotification>(null);
  public Returndatadcc: BehaviorSubject<ChangeNotification> =
    new BehaviorSubject<ChangeNotification>(null);
  public company: string = '';
  constructor(
    private authService: AuthService,
    @Optional() @Inject(SIGNALR_BASE_URL) signalRbaseUrl?: string
  ) {
    this.signalRbaseUrl = this.signalRbaseUrl == '' ? this.signalRbaseUrl : '';
  }
  private hubConnection: signalR.HubConnection;
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.signalRbaseUrl, {})
      .build();
    this.hubConnection
      .start()
      .then(() => console.log('Connection established'))
      .catch(function (err: any) {
        return console.log('Error while starting connection: ' + err);
      });
  };

  public Disconnected() {
    if (this.hubConnection != undefined) this.hubConnection.stop();
  }

  public performTransactionRefresh() {
    this.hubConnection.on('refreshTransactionsinf', (data: any) => {
      this.Returndatainf.next(data);
    });
    this.hubConnection.on('refreshTransactionsach', (data: any) => {
      this.Returndataach.next(data);
    });
    this.hubConnection.on('refreshTransactionsdcc', (data: any) => {
      this.Returndatadcc.next(data);
    });
  }
}
