<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="bank-and-card-details tabStyle">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
      <mat-card fxFlex="100%" class="mat-elevation-z0 main-card">
        <mat-card-content>
          <mat-tab-group animationDuration="0ms" headerPosition="above" [disableRipple]="true"
            (selectedTabChange)="selectedTabChangeEvent($event)">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-radio-group [(ngModel)]="banktabselected">
                  <mat-radio-button [value]="true">Bank Accounts
                  </mat-radio-button>
                </mat-radio-group>
              </ng-template>
              <div xLayout="row" fxLayout.xs="row" fxLayoutWrap>
                <!-- Bank Accounts Body -->
                <app-bank-accounts [bankList]="bankList" (editBankEvent)="editBankDetails($event)"></app-bank-accounts>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-radio-group [(ngModel)]="cardtabselected">
                  <mat-radio-button [value]="true">Debit & Credit Cards
                  </mat-radio-button>
                </mat-radio-group>
              </ng-template>
              <div xLayout="row" fxLayout.xs="row" fxLayoutWrap>
                <!-- DEBIT/CREDIT CARD Body -->
                <app-debit-credit-cards [customerId]="customerId" [debitCreditCardsList]="debitCreditCardsList"
                  (editCardEvent)="editCardDetails($event)"></app-debit-credit-cards>
              </div>
            </mat-tab>
            <mat-tab disabled></mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>