import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
})
export class InvoicePreviewComponent implements OnInit {
  @Input()
  salesTax: any;

  @Input()
  returnPolicy: any;

  salesTaxAmount: any;
  totalAmount: any;
  subTotalAmount = 3958;
  discountAmount = 316.6;

  invoiceNo: any;
  isLoading = false;
  loadMessage: string = 'Loading Please Wait';
  clientlogo_const = 'logo';
  email_const = 'email';
  location_const = 'location';
  mobile_const = 'mobile';
  delete_const = 'delete';
  add_const = 'add';
  today = new Date();

  customerInvoiceProductsList = [
    {
      productName: 'FinZeo Pay - ACH Payment',
      sku: 'AHCGDF908762',
      quantity: 2,
      basePrice: 10,
      totalAmount: 200,
    },
    {
      productName: 'FinZeo Pay - E Check',
      sku: 'AHECDF908762',
      quantity: 3,
      basePrice: 900,
      totalAmount: 2700,
    },
    {
      productName: 'FinZeo Pay - Credit Debit',
      sku: 'AHCCDF908762',
      quantity: 5,
      basePrice: 150,
      totalAmount: 750,
    },
    {
      productName: 'FinZeo Pay - Instant Fund',
      sku: 'AHIGDF908762',
      quantity: 2,
      basePrice: 49,
      totalAmount: 98,
    },
    {
      productName: 'FinZeo Pay - IBV',
      sku: 'AIBVDF908762',
      quantity: 3,
      basePrice: 70,
      totalAmount: 210,
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.salesTaxAmount = (this.subTotalAmount * this.salesTax) / 100;
    this.totalAmount =
      this.salesTaxAmount + this.subTotalAmount - this.discountAmount;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.salesTaxAmount =
      (this.subTotalAmount * changes['salesTax']?.currentValue) / 100;
    this.totalAmount =
      this.salesTaxAmount + this.subTotalAmount - this.discountAmount;
  }

  getIconSrc(iconName: string) {
    return 'assets/invoices-icons/' + iconName + '.png';
  }

  getLogoSrc(iconName: string) {
    return 'assets/invoices-icons/' + iconName + '.svg';
  }

  getClientLogoSrc(iconName: string) {
    return 'assets/' + iconName + '.png';
  }
}
