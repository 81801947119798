import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { ManageNotificationDetailsModel,SendReminderModel } from 'src/app/featureModules/models/manage-notification-details.model';
import { SettingsModel } from 'src/app/featureModules/models/settings.model';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';

@Component({
  selector: 'app-manage-notification',
  templateUrl: './manage-notification.component.html',
  styleUrls: ['./manage-notification.component.scss']
})
export class ManageNotificationComponent implements OnInit {

  @Input()
  settingsModel: SettingsModel;
  
  isLoading:boolean;
  delete_const = 'delete';
  isByEmail:boolean=false;
  addButtonClick:boolean = false;
  manageNotificationFormGroup: FormGroup;
  manageNotificationDetailsModel:ManageNotificationDetailsModel ;
  clientId: any;
  manageNotificationData: any;


  constructor(private formBuilder: RxFormBuilder, private fb : FormBuilder,
    private _toastrService: ToastrService,
    private _invoiceService: InvoiceService,
    private router: Router
    ) { }

  ngOnInit(): void {
   
    this.manageNotificationDetailsModel=new ManageNotificationDetailsModel();
    this.manageNotificationDetailsModel.sendReminderList = new Array<SendReminderModel>();
    this.addButtonClick=true;
    this.manageNotificationFormGroup = this.formBuilder.formGroup(
      this.manageNotificationDetailsModel
    );

    this.getInvoiceSetupSettings();
    
  }

  getInvoiceSetupSettings() { 
    this.isLoading = true;
    this._invoiceService.getInvoiceSetupDetails().subscribe(
      (data) => {
        if(data.code == 200){
          this.settingsModel = data.data;             
          this.loadNotificaitonSetupData();                    
        }
        else{
          this.settingsModel = new SettingsModel();
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;                
      },
      (error) => {
        if (error.error.message != null || error.error.message != undefined) {
          this._toastrService.warning(error.error.message);
        } else {
          this._toastrService.warning(error.error.content);
        }
        this.isLoading = false;
      }
    );
  }

  loadNotificaitonSetupData() {
    this.manageNotificationDetailsModel.paymentReminder = this.settingsModel.paymentReminder;
    this.manageNotificationDetailsModel.sendNotificationBy= this.settingsModel.sendNotificationBy;
    this.manageNotificationDetailsModel.sendReminder = this.settingsModel.sendReminder;
    this.manageNotificationDetailsModel.dueDate = this.settingsModel.dueDate;

    let formControlList = this.manageNotificationFormGroup.controls[
      'sendReminderList'
    ] as FormArray;
    //2:1:5
    formControlList.clear();
    let dataList= this.settingsModel.sendReminder?.split(':');
    if (dataList?.length==0 ||  dataList == null) {      
      formControlList.push(this.newControlGeneration(0));
    } else {      
      dataList?.forEach((item: any) => {          
        formControlList.push(this.newControlGeneration(item));
      });
    }
    this.manageNotificationFormGroup.patchValue(this.manageNotificationDetailsModel);
  }

  //1:2
  stringToModelList(sendReminder: string): SendReminderModel[] {
    let sendReminderM: any = [];
    if (sendReminder) {
      let c1 = sendReminder.split(':');      
      c1.map((col) => {
        sendReminderM.push({
          days: parseInt(col),          
        });
      });
      return sendReminderM;
    } else {
      sendReminderM = [{ days: 0 }];
      return sendReminderM;
    }
  }

  //1:2:2:Test|3:4:2:Test
  modelListTostring(_sendReminderM: SendReminderModel[]): string {
    let str = '';
    if (_sendReminderM) {
      _sendReminderM.map((srm) => {
        str =
          str +
          srm.days +
          ':' ;
      });
      str = str.slice(0, -1);
      return str;
    }
    return str;
  }

  addSendReminderField() {
    let formControlList = this.manageNotificationFormGroup.controls[
      'sendReminderList'
    ] as FormArray;
    if (formControlList.length > 4) {
      this._toastrService.warning('You can not add more than 5 Options !!');
      return;
    }
    formControlList.push(this.newControlGeneration(0));  
  }

  deleteButtonCondition() {
    let formControlList = this.manageNotificationFormGroup.controls[
      'sendReminderList'
    ] as FormArray;
    if (formControlList.length == 1) {
      return false;
    }
    return true;
  }

  newControlGeneration(days){
    var newControl = this.formBuilder.formGroup(SendReminderModel);
      var newItem = new SendReminderModel(); 
      newItem.days = days;      
      newControl.patchValue(newItem);
      return newControl; 
  }

  removeSendReminderField(index: number): void {
    let formControlList = this.manageNotificationFormGroup.controls[
      'sendReminderList'
    ] as FormArray;
    if(formControlList.length >= 1) {
      formControlList.removeAt(index);      
    } else {      
      formControlList.push(this.newControlGeneration(0));            
  }
  }

  saveSettings() {
    this.isLoading = true;   
    let result = this.modelListTostring(
      this.manageNotificationFormGroup.controls['sendReminderList'].value
    );
    this.manageNotificationFormGroup.controls['sendReminder'].setValue(result);
    if (this.manageNotificationFormGroup.valid) {
        this._invoiceService
          .updateManageNotifcationSetupDetails(            
            this.manageNotificationFormGroup.value
          )
          .subscribe(
            (data) => {
              if (data.code == 200) {
                this._toastrService.success(
                  'Manage Notification Details updated successfully!'
                );
                this.isLoading = false;                                
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } 
      else 
      {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }    
  }


  resetManageNotificationForm() {    
    this.manageNotificationFormGroup.reset();
    this.manageNotificationDetailsModel=new ManageNotificationDetailsModel();
    Object.keys(this.manageNotificationFormGroup.controls).forEach((key) => {
      const control = this.manageNotificationFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  getIconSrc(iconName: string) {
    return 'assets/settings-icons/' + iconName + '.png';
  }

  resetForm() {
    this.manageNotificationFormGroup.reset();
    this.manageNotificationDetailsModel = new ManageNotificationDetailsModel();
    Object.keys(this.manageNotificationFormGroup.controls).forEach((key) => {
      const control = this.manageNotificationFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }
  onReminderMediumChanged(event: any) {
    this.manageNotificationFormGroup.controls['paymentReminder'].setValue(event.value);
  }

  onNotificationMediumChanged(event: any) {
    this.manageNotificationFormGroup.controls['sendNotificationBy'].setValue(event.value);
  }

  getStyleForFormGroup(index) {
    return this.manageNotificationFormGroup.controls['sendReminderList'][
      'controls'
    ][index].valid
      ? { 'margin-bottom': '0%;' }
      : { 'margin-bottom': '2%;' };
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }
}
