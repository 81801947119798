import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SubmitReviewDialogComponent } from 'src/app/sharedModules/components/dialogs/submit-review-dialog/submit-review-dialog.component';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from 'src/environments/environment';

const headers = new HttpHeaders({ Accept: '*' });
@Injectable({
  providedIn: 'root',
})
export class BillingService extends CrudService<any, any> {
  dialogRef: MatDialogRef<SubmitReviewDialogComponent, any>;

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}FinzeoBilling`);
  }
  downloadFile(invoiceId: any): Observable<any> {
    return this._http.post<any>(
      this._base + '/downloadBillingDetailsByInvoice/'+invoiceId,
      {
        responseType: 'blob',   
        headers: { Accept: '*' },
      }
    );
  }
  downloadFile2(invoiceId: any): Observable<any> {
    return this._http.get(this._base + '/downloadBillingDetailsByInvoice/'+invoiceId , { responseType:'blob' ,headers: { Accept: '*' }});    
  }
  getAllBillingStats() {
    return this._http.get<any>(this._base + '/getAllBillingStats/');
  }
  getBillingInvoiceByInvoiceId(invoiceId: any) {
    return this._http.get<any>(
      this._base + '/getBillingInvoiceByClientId/' + invoiceId
    );
  }
  getAllBillingInvoices(
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ): Observable<any> {
    headers.set('Access-Control-Allow-Origin', '*');
    let queryParams = new HttpParams();

    if (sortDirection)
      queryParams = queryParams.append('sortDirection', sortDirection);
    if (page) queryParams = queryParams.append('page', page);
    if (pageSize) queryParams = queryParams.append('pageSize', pageSize);
    if (filterBy) queryParams = queryParams.append('filterBy', filterBy);
    if (sortBy) queryParams = queryParams.append('sortBy', sortBy);
    if (advanceFilters)
      queryParams = queryParams.append('advanceFilters', advanceFilters);

    const baseUrl = `${this._base}/getAllBillingInvoices`;
    return this._http.get<any>(baseUrl, { params: queryParams });
  }
}
