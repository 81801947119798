<div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" class="card-label-block">
  <mat-label class="sub-header"><b>Card Details</b></mat-label>
</div>

<div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image-container" *ngIf="cardType.toLowerCase() == 'visa'">
      <img src="assets/payment-widget-icons/visaCard.png" alt="visa-card-logo" class="visa-card-logo highlighted"
        [style.border-color]="pageSettingModel?.themeColor" />
      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
        class="overlay-image done-check-on-small-cards-logo" />
    </div>
    <div *ngIf="cardType.toLowerCase() !== 'visa'">
      <img src="assets/payment-widget-icons/visaCard.png" alt="visa-card-logo" class="visa-card-logo" />
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image-container" *ngIf="cardType.toLowerCase() == 'americanexpress'">
      <img src="assets/payment-widget-icons/americalExpressCard.png" alt="American Express Logo"
        class="other-card-logo highlighted" [style.border-color]="pageSettingModel?.themeColor" />
      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
        class="overlay-image done-check-on-small-cards-logo" />
    </div>
    <div *ngIf="cardType.toLowerCase() !== 'americanexpress'">
      <img src="assets/payment-widget-icons/americalExpressCard.png" alt="American Express Logo"
        class="other-card-logo" />
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image-container" *ngIf="cardType.toLowerCase() == 'mastercard'">
      <img src="assets/payment-widget-icons/mastercard.png" alt="MasterCard Logo" class="other-card-logo highlighted"
        [style.border-color]="pageSettingModel?.themeColor" />
      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
        class="overlay-image done-check-on-small-cards-logo" />
    </div>
    <div *ngIf="cardType.toLowerCase() !== 'mastercard'">
      <img src="assets/payment-widget-icons/mastercard.png" alt="MasterCard Logo" class="other-card-logo" />
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image-container" *ngIf="cardType.toLowerCase() == 'discover'">
      <img src="assets/payment-widget-icons/discoverCard.png" alt="Discover Logo" class="other-card-logo highlighted"
        [style.border-color]="pageSettingModel?.themeColor" />
      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
        class="overlay-image done-check-on-small-cards-logo" />
    </div>
    <div *ngIf="cardType.toLowerCase() !== 'discover'">
      <img src="assets/payment-widget-icons/discoverCard.png" alt="Discover Logo" class="other-card-logo" />
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
    <div class="image-container" *ngIf="cardType.toLowerCase() == 'unknown'">
      <img src="assets/payment-widget-icons/unknownCard.png" alt="Unknown Logo" class="other-card-logo highlighted"
        [style.border-color]="pageSettingModel?.themeColor" />
      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
        class="overlay-image done-check-on-small-cards-logo" />
    </div>
    <div *ngIf="cardType.toLowerCase() !== 'unknown'">
      <img src="assets/payment-widget-icons/unknownCard.png" alt="Unknown Logo" class="other-card-logo" />
    </div>
  </div>
</div>

<div class="cardDetailsForm">
  <form [formGroup]="cardDetailsFormGroup">
    <div fxLayout="column" fxLayoutGap="5%">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group">
        <div fxLayout="column" fxLayoutWrap fxFlex="45%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label class="required">Card Number</mat-label>
            <input matInput name="cardNumber" formControlName="cardNumber" inputmode="numeric" [type]="'text'"
              maxlength="23" autocomplete="cc-number" appDebitCreditFormat class="form-control"
              placeholder="Card Number"  (focusout)="onFocusOutEvent($event)" (focusin)="onFocusInEvent($event)" />
            <mat-error>
              {{ cardDetailsFormGroup.controls["cardNumber"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="45%">
          <div fxLayout="row" fxLayoutGap="10%" fxLayoutGap.sm="5%" fxLayoutGap.xs="5%">
            <div fxLayout="column" fxLayoutWrap fxFlex="50%">
              <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
                <mat-label class="required">Expiration Date</mat-label>
                <input matInput formControlName="expirationDate" class="form-control" name="expirationDate"
                  placeholder="Expiration Date" autocomplete="cc-exp" maxlength="5" appCardExpirationDateFormat
                  (focusout)="onFocusOutEvent($event)" (focusin)="onFocusInEvent($event)" />
                <mat-error>
                  {{
                  cardDetailsFormGroup.controls["expirationDate"][
                  "_errorMessage"
                  ]
                  }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutWrap fxFlex="35%">
              <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
                <mat-label class="required">CVV</mat-label>
                <input matInput formControlName="cvv" class="form-control" name="cvv" placeholder="CVV"
                  autocomplete="cc-csc" maxlength="4" type="password" />
                <mat-error>
                  {{ cardDetailsFormGroup.controls["cvv"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutWrap fxFlex="5%" fxLayoutAlign="center center">
              <mat-icon [style.color]="pageSettingModel?.themeColor"
                matTooltip="Card Verification Value is an additional security value printed on the physical credit card. Visa, MasterCard, Discover: a 3-digit number shown on the back of the card. American Express: a 4-digit number printed on the front of the card.">help_outline</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="!isRecurringDisabled" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start stretch"
        class="form-group">
        <section class="example-section">
          <mat-checkbox [style.--active-bg-color]="pageSettingModel?.themeColor" (change)="recurringChecked($event)"
            formControlName="isRecurring">
            Recurring Transaction
          </mat-checkbox>
          <mat-error>
            {{ cardDetailsFormGroup.controls["isRecurring"]["_errorMessage"] }}
          </mat-error>
        </section>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group m-b-0" *ngIf="checkIfRecurring()">
        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label>Frequency*</mat-label>
            <mat-select name="frequency" formControlName="frequency" (selectionChange)="onFrequencyChanged($event.value)">
              <mat-option *ngFor="let frequency of frequencies" [value]="frequency.value"
                [disabled]="checkIfFrequencyDisabled(frequency.value)">
                <mat-label [style.--active-fontFamily]="pageSettingModel?.themeFont">{{ frequency.viewValue }}</mat-label>                
              </mat-option>
            </mat-select>
            <mat-error>
              {{ cardDetailsFormGroup.controls["frequency"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label>Schedule date</mat-label>
            <input matInput (dateChange)="onscheduledDateChanged($event)" [matDatepicker]="datepicker"
              formControlName="scheduledDate" [min]="defaultScheduledDate" [max]="maxDate" autocomplete="scheduledDate"
              [disabled]="true" />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker [startAt]="defaultScheduledDate" [disabled]="false" [dateClass]="dateClass"
              (closed)="onCalendarClose()" (cancelled)="onCalendarCancel()">
              <mat-datepicker-actions>
                <button [style.--active-bg-color]="pageSettingModel?.themeColor" 
                class="secondaryBtn" mat-button matDatepickerCancel>Cancel</button>
                <button class="primaryBtn" mat-raised-button [style.--active-text-color]="pageSettingModel?.themeTextColor"
                  [style.--active-bg-color]="pageSettingModel?.themeColor" matDatepickerApply>
                  Apply
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <mat-error>
              {{
              cardDetailsFormGroup.controls["scheduledDate"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%" fxLayoutAlign.sm="start stretch"
          fxLayoutAlign.xs="start stretch" fxLayoutAlign="center center">
          <section class="example-section">
            <mat-checkbox [style.--active-bg-color]="pageSettingModel?.themeColor" (change)="endOfMonthCheck($event)"
              formControlName="isEndOfMonth">
              At end of the month
            </mat-checkbox>
            <mat-error>
              {{
              cardDetailsFormGroup.controls["isEndOfMonth"]["_errorMessage"]
              }}
            </mat-error>
          </section>
        </div>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group" *ngIf="checkIfRecurring()">
        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label class="">Number of Payments</mat-label>
            <input matInput formControlName="installment" class="form-control" name="installment"
              placeholder="Number of Payments" autocomplete="cc-csc" maxlength="2" type="text" />
            <mat-error>
              {{ cardDetailsFormGroup.controls["installment"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="70%" fxLayoutAlign.sm="start stretch"
          fxLayoutAlign.xs="start stretch" fxLayoutAlign="center center" class="notice-text">
          <label *ngIf="cardDetailsFormGroup.controls['scheduledDate'].value">
            For recurring payments, the initial payment made today is not counted towards the total number of scheduled recurring payments that begin on {{cardDetailsFormGroup.controls["scheduledDate"].value | date:'MM/dd/yyyy'}}.
          </label>
        </div>
      </div>
    </div>
  </form>
</div>