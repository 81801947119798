import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { GenerateReportModel } from 'src/app/sharedModules/models/generate-report.model';
import { ReportSearchModel } from 'src/app/sharedModules/models/report-search.model';
import { ToastrService } from 'ngx-toastr';
import FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends CrudService<any, any> {
  generateReportModel: GenerateReportModel;
  moreFilterList: any;

  constructor(
    protected override _http: HttpClient,
    private _toastrService: ToastrService
  ) {
    super(_http, `${environment.API_REPORT_URL}Reports`);
  }

  GetAllCustomersStats(startDate: any, endDate: any) {
    return this._http.get<any>(
      this._base +
      '/getAllCustomersStats?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate
    );
  }

  GetRecurringTransactions(startDate: any, endDate: any) {
    return this._http.get<any>(
      this._base +
      '/GetRecurringTransactions?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate
    );
  }

  GetReportStatsBasedOnFilter(
    customerId: any,
    typeOfService: string,
    processor: string,
    startDate: any,
    endDate: any
  ) {
    let baseUrl =
      this._base +
      '/GetReportStatsBasedOnFilter?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate;
    baseUrl = customerId ? baseUrl + '&CustomerId=' + customerId : baseUrl;
    baseUrl = typeOfService
      ? baseUrl + '&TypeOfService=' + typeOfService
      : baseUrl;
    baseUrl = processor ? baseUrl + '&Processor=' + processor : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  GetTransactionTotalAmount(
    customerId: any,
    typeOfService: string,
    processor: string,
    startDate: any,
    endDate: any
  ) {
    let baseUrl =
      this._base +
      '/GetTransactionTotalAmount?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate;
    baseUrl = customerId ? baseUrl + '&CustomerId=' + customerId : baseUrl;
    baseUrl = typeOfService
      ? baseUrl + '&TypeOfService=' + typeOfService
      : baseUrl;
    baseUrl = processor ? baseUrl + '&Processor=' + processor : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  GetTotalTransactionAndAmount(reportSearchModel: ReportSearchModel) {
    let baseUrl =
      this._base +
      '/GetTotalTransactionAndAmount?StartDate=' +
      reportSearchModel.startDate +
      '&EndDate=' +
      reportSearchModel.endDate;
    baseUrl = reportSearchModel.customerId
      ? baseUrl + '&CustomerId=' + reportSearchModel.customerId
      : baseUrl;
    baseUrl = reportSearchModel.typeOfService
      ? baseUrl + '&TypeOfService=' + reportSearchModel.typeOfService
      : baseUrl;
    // baseUrl = reportSearchModel.processor
    //   ? baseUrl + '&Processor=' + reportSearchModel.processor
    //   : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  GetTotalTransactionsStatusWiseForAllServices(startDate: any, endDate: any) {
    return this._http.get<any>(
      this._base +
      '/GetTotalTransactionsStatusWiseForAllServices?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate
    );
  }

  GetTotalTransactionAmountForAllServices(startDate: any, endDate: any) {
    return this._http.get<any>(
      this._base +
      '/GetTotalTransactionAmountForAllServices?StartDate=' +
      startDate +
      '&EndDate=' +
      endDate
    );
  }

  GenerateReportForAch(model: any) {
    let baseUrl = this._base + '/GenerateReportForAch';
    return this._http.post<any>(baseUrl, model);
  }

  GenerateReportForCreditDebit(model: any) {
    let baseUrl = this._base + '/GenerateReportForCreditDebit';
    return this._http.post<any>(baseUrl, model);
  }

  GenerateReportForEcheck(model: any) {
    let baseUrl = this._base + '/GenerateReportForEcheck';
    return this._http.post<any>(baseUrl, model);
  }

  DownloadReportForAch(model: any) {
    this._toastrService.info('Please wait, we are downloading report for you.');
    this._http
      .post(this._base + '/DownloadReportForAch', model, {
        responseType: 'blob',
        headers: { Accept: 'text/xlsx' },
      })
      .subscribe(
        (data) => {
          if (data != null) {
            FileSaver.saveAs(
              data,
              model.heading
                ? model.heading
                : 'Reports'
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded');
          }
        },
        (error) => {
          this._toastrService.clear();
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  DownloadReportForCreditDebit(model: any) {
    this._toastrService.info('Please wait, we are downloading report for you.');
    this._http
      .post(
        this._base + '/DownloadReportForCreditDebit',
        model,
        {
          responseType: 'blob',
          headers: { Accept: 'text/xlsx' },
        }
      )
      .subscribe(
        (data) => {
          if (data != null) {
            FileSaver.saveAs(
              data,
              model.heading
                ? model.heading
                : 'Reports'
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded');
          }
        },
        (error) => {
          this._toastrService.clear();
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  DownloadReportForEcheck(model: any) {
    this._toastrService.info('Please wait, we are downloading report for you.');
    this._http
      .post(this._base + '/DownloadReportForEcheck', model, {
        responseType: 'blob',
        headers: { Accept: 'text/xlsx' },
      })
      .subscribe(
        (data) => {
          if (data != null) {
            FileSaver.saveAs(
              data,
              model.heading
                ? model.heading
                : 'Reports'
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded.');
          }
        },
        (error) => {
          this._toastrService.clear();
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  DownloadReportForAllTransaction(model: any) {
    this._toastrService.info('Please wait, we are downloading report for you.');
    this._http
      .post(this._base + '/downloadReportForAllTransaction', model, {
        responseType: 'blob',
        headers: { Accept: 'text/xlsx' },
      })
      .subscribe(
        (data) => {
          if (data != null) {
            FileSaver.saveAs(
              data,
              model.heading
                ? model.heading
                : 'Reports'
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded');
          }
        },
        (error) => {
          this._toastrService.clear();
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
