import { prop } from "@rxweb/reactive-form-validators";

export class InventoryStatsModel {

  @prop()
  totalServicesCount: number;

  @prop()
  totalQuantity: number;

  @prop()
  totalUnitPrice: number;

  @prop()
  activeStatus: number;

  @prop()
  inactiveStatus: number;
  
}
