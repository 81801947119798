<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>
<div class="transactions-overview">
<div class="sideMargin">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="30px"
    class="topContainer"
  >
    <div fxLayout="column" fxFlex="18%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="17%">
        <div fxLayout="column" fxFlex="24%">
          <img
            matTooltip="Total Invoices"
            class="summaryIcons"
            src="assets/grid-icons/total_invoices.png"
          />
        </div>
        <div fxLayout="column" fxFlex="59%" class="mt7px">
          <span class="amountStatcls">{{ totalInvoices | number : "1.0-0" }}</span>
          <span class="amountStatLblCls">Total Invoices</span>
        </div>
      </div>
    </div>

    <div class="verticalLine"></div>
    <span class="spacer"></span>
    <div fxLayout="column" fxFlex="16%">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="17%"
        fxLayoutAlign="center center"
      >
        <div fxLayout="column" fxFlex="24">
          <img
            matTooltip="Total Paid Amount"
            class="summaryIcons"
            src="assets/grid-icons/amount.png"
          />
        </div>
        <div fxLayout="column" fxFlex="59%" class="mt7px">
          <span class="amountCls"
            >${{ totalPaidAmount | number : "1.0-0" }}</span
          >
          <span class="amountLblCls">Total Paid Amount</span>
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxFlex="16%">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="17%"
        fxLayoutAlign="center center"
      >
        <div fxLayout="column" fxFlex="24%">
          <img
            matTooltip="Total Due Amount"
            class="summaryIcons"
            src="assets/grid-icons/dueAmount.png"
          />
        </div>
        <div fxLayout="column" fxFlex="59%" class="mt7px">
          <span class="amountCls"
            >${{ totalDueAmount | number : "1.0-0" }}</span
          >
          <span class="amountLblCls">Total Due Amount</span>
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div class="verticalLine"></div>

    <div fxLayout="column" fxFlex="16%">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="15%"
        fxLayoutAlign="center center"
      >
        <div fxLayout="column" fxFlex="24%">
          <img
            matTooltip="Overdue Payment"
            class="summaryIcons"
            src="assets/others-icon/inactive.png"
          />
        </div>
        <div fxLayout="column" fxFlex="59%" class="mt7px">
          <span class="amountCls" style="border: 0ch">{{
            failedPayment | number : "1.0-0"
          }}</span>
          <span class="amountLblCls">Failed Payment</span>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Scheduled Status</mat-label>
              <mat-select
                class="form-control"
                formControlName="invoiceStatus"
                name="invoiceStatus"
              >
                <mat-option
                  *ngFor="let status of scheduledStats"
                  [value]="status.value"
                  >{{ status.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label>Scheduled Date</mat-label>
              <mat-date-range-input [rangePicker]="scheduledPicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="scheduleStartDate"
                  name="scheduleStartDate"
                  readonly
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="scheduleEndDate"
                  name="scheduleEndDate"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="scheduledPicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #scheduledPicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label>Bill Amount From</mat-label>
              <input
                type="text"
                matInput
                formControlName="invoiceBillFromAmount"
                placeholder="Bill Amount Range From"
                appTwoDigitDecimalNumber
              />
              <span matPrefix class="material-icons">attach_money</span>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label> Bill Amount To</mat-label>
              <input
                type="text"
                matInput
                formControlName="invoiceBillToAmount"
                placeholder="Bill Amount Range To"
                appTwoDigitDecimalNumber
              />
              <span matPrefix class="material-icons">attach_money</span>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end" class="marginTop">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">RESET</button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            [disabled]="!filterFormGroup.valid"
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <app-data-table
      [columns]="column"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [selection]="selection"
      (pageChangedEvent)="onPageChanged($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      (invoiceNumberClickedEvent) ="onInvoiceNumberClick($event)"
      (raiseTicketClickedEvent) ="onRaiseATicketClick($event)"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      [isExport]="false"
      (rowClickedEvent)="onRowClick($event)"
      [filterText]="this.filter?.toString() ?? ''"
      [advanceFilterCount]="advanceFilterCount"
      [role]="this.userRole"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
