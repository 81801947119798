import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';

@Component({
  selector: 'app-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrls: ['./search-customer.component.scss'],
})
export class SearchCustomerComponent implements OnInit {
  constructor(private _customerService: CustomerService) {}

  searchCustomerCtrl = new FormControl();
  filteredCustomer: any[] = [];
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selectedCustomer: any = '';
  selectedCustomerChip: any = '';
  isShowNoFound: boolean = false;

  @Input()
  onlyIndividual = false;

  @Output()
  onCustomerSelectionEvent = new EventEmitter();

  ngOnInit(): void {
    this.isShowNoFound = false;
    this.searchCustomerCtrl.valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return this.searchCustomerCtrl.valid && res !== null && res.length >= this.minLengthTerm;
          } else {
            this.selectedCustomer = '';
            this.filteredCustomer = [];
            this.selectedCustomerChip = '';
            this.onCustomerSelectionEvent.emit(null);
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.errorMsg = '';
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this._customerService.SearchCustomers(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.errorMsg = data['Error'];
          this.filteredCustomer = [];
          this.isShowNoFound = true;
        } else {
          this.errorMsg = '';
          this.isShowNoFound = false;
          if (this.onlyIndividual) {
            this.filteredCustomer = data.data.filter((obj: any) => !obj.type);
          } else {
            this.filteredCustomer = data.data;
          }
        }
      });
  }

  onSelected() {
    this.filteredCustomer = [];
    this.selectedCustomerChip = this.selectedCustomer;
    this.onCustomerSelectionEvent.emit(this.selectedCustomerChip);
  }

  displayWith(value: any) {
    return value?.Title;
  }

  clearSelection() {
    this.isShowNoFound = false;
    this.selectedCustomer = '';
    this.filteredCustomer = [];
    this.selectedCustomerChip = '';
    this.onCustomerSelectionEvent.emit(null);
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }
}
