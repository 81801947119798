import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { Pattern } from 'src/app/sharedModules/constants/pattern.const';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import { ConfirmedValidator } from './confirmed.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  changePassForm: FormGroup = new FormGroup({});

  selectedPattern: any;
  errorMgs: string;
  newPassword!: string;
  lablePassword!: string;
  titlePassword!: string;
  isAdminLogin: boolean = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService
  ) {
    if (this.router.url.includes('isAdminLogin')) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.isAdminLogin = JSON.parse(params['isAdminLogin']?.toLowerCase()) ?? false;
        localStorage.setItem('isAdminLogin', this.isAdminLogin.toString());
      });
    }

    this.changePassForm = fb.group(
      {
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: ConfirmedValidator('newPassword', 'confirmPassword'),
      }
    );
  }

  get f() {
    return this.changePassForm.controls;
  }

  cancelEvent() {
    if (this.router.url.includes('token')) {
      // this.router.navigate(['/']);
      this.isAdminLogin
        ? this.router.navigate(['/csr'])
        : this.router.navigate(['/']);
    } else {
      localStorage.setItem('validUser', 'true');
      // this.router.navigate(['/']);
      this.isAdminLogin
        ? this.router.navigate(['/csr'])
        : this.router.navigate(['/']);
    }
  }

  submit(data: any) {
    let token: string;
    let tokenExpired: boolean;
    this.newPassword = data.newPassword;

    if (this.router.url.includes('token')) {
      this.activatedRoute.queryParams.subscribe((params) => {
        token = params['token'];
      });
      //token="eyJFeHBpcmVkVGltZSI6IjIwMjItMDctMDFUMDc6MjU6NDEuMDkyNjc4OVoiLCJFbmNyeXB0ZWRUZW1wb3JhcnlQYXNzd29yZFRva2VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SnFkR2tpT2lJd016WmpZamcyWVMweFptVTRMVFJtTnpZdFlUQTRaQzFpTlRGbE1ESTJOR1kyTVRZaUxDSkRiR2xsYm5SSlpDSTZJa1pKVGpBd01TSXNJbFZ6WlhKRmJXRnBiQ0k2SW5KcFkyaGhjbVF1ZEhKaGJrQm1hVzU2Wlc4dVkyOXRJaXdpVkcxd1VHRnpjM2R2Y21RaU9pSkZNa0l4T0VJMU1rWTFSRGcwT1VVMlFVWkROVGc1TnpJNFJUSTVNa0ZGUVRkRlF6RkJOMEUxUWprM09FUXlSVGd6UVRaRE56Y3hPVE5DUlRJeU1qQXdJaXdpU1dSbGJuUnBkSGxTWlhObGRGQmhjM04zYjNKa1ZHOXJaVzRpT2lKRFprUktPRWhJWlRkMlYxWXJTRlpGY1RjME9HZEhiR1pVU1Vrdk9HeDRVelpyVEVaRFJ6TnZkbE16TVZacE16TXpTRmxqTDBVM1VTdDZRbmgxTkRCdVJVNW5OVk00ZEZsR1QzRlNVRXh1ZVN0ckwyWjZlazFKT1dka1ExUnZWRTFxYkhoVVpGSlFkSFp3UmpOSVlrZFBSVTFYWWtGeWIyTndiV3RLY1hWSU5ESjJVbVZXV0RaaVpsVkxhREZOYjFodFNsUjJRa2xyUkhSMGVrbENkWGcyYWtwV1MzZ3pWbGxpTTFkSVpFc3lRVVY2UzNwRVJTOUlSVll5UmpKNFdGbE1TemxsZVU4dmVXTlBWbXM0UkhCRWIyMHhlbEZMWTNBcmN6RnFVMUZyYmtwWFZYaG5OakI2VEVkWFkwUjNWMElpTENKdVltWWlPakUyTlRZMU56TTVOREVzSW1WNGNDSTZNVFkxTmpZMk1ETTBNU3dpYVdGMElqb3hOalUyTlRjek9UUXhmUS5Wd3BnUXJmc2xwRVNaeWxYeDJtNnRtNVdWbzRwcUVBUVVPMGVlZTF1VmY0In0=";
      const expiry = JSON.parse(atob(token.split('.')[0])).ExpiredTime;
      tokenExpired = new Date() >= new Date(expiry.toString());

      if (tokenExpired) {
        this.notifyService.showError(
          'Password reset link is expired. Please issue a new forgot password request.'
        );
        // this.router.navigate(['/']);
        this.isAdminLogin
          ? this.router.navigate(['/csr'])
          : this.router.navigate(['/']);
      } else {
        // token valid
        this.loginService.submitForgetPassword(
          token,
          this.newPassword,
          this.isAdminLogin
        );
      }
    } else {
      this.loginService.changePassword(
        sessionStorage.getItem('currentPassword'),
        this.newPassword,
        JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
      );
    }
  }

  ngOnInit(): void {
    if (this.router.url.includes('token')) {
      this.lablePassword = 'Temporary Password';
      this.titlePassword = 'Set New Password';
    } else {
      this.lablePassword = 'Current Password';
      this.titlePassword = 'Set New Password';
    }

    this.selectedPattern = Pattern.password; //will change based on user preference
    if (this.selectedPattern === Pattern.password) {
      this.errorMgs =
        'Minimum eight characters, at least one uppercase letter, one lowercase letter, one special character and one number';
    }
  }
}
