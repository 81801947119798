<mat-card class="order-details-card" [style.--active-fontFamily]="pageSettingModel?.themeFont" fxLayout="column"
  fxFlex="100%">
  <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="60%" fxLayoutGap="16px" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center stretch">
        <mat-label class="order-details">Order Details</mat-label>
      </div>

      <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="center stretch"
        *ngIf="displayFields(pageSettingModel?.isInvoiceNumberView)">
        <div fxLayout="column" fxLayout.xs="row">
          <mat-label class="theme-label">Confirmation Number -</mat-label>
        </div>
        <div fxLayout="column" fxLayout.xs="row">
          <mat-label class="theme-label">{{
            invoiceNumber
            }}</mat-label>
        </div>
      </div>
      <br />

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxFlex="65%" class="sub-text">Amount</div>
        <div fxLayout="column" fxLayoutAlign="end end" fxFlex="35%" class="sub-text word-wra">
          ${{ amount ?? 0 | number : "1.2-2" }}
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch" *ngIf="agreesToSurcharge">
        <div fxLayout="column" fxFlex="65%" class="sub-text word-wra">
          Surcharge Fees ({{ surchargePercent }}%)
        </div>
        <div fxLayout="column" fxFlex="35%" class="sub-text" fxLayoutAlign="end end">
          ${{ surchargeFees ?? 0 | number : "1.2-2" }}
        </div>
      </div>

      <ng-container *ngIf="this.pageSettingModel?.isAvailConvenienceFee">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch" *ngIf="agreesToConvenienceFees">
          <div fxLayout="column" fxFlex="65%" class="sub-text word-wra">
            Convenience Fees
          </div>
          <div fxLayout="column" fxFlex="35%" class="sub-text" fxLayoutAlign="end end">
            ${{ convenienceFees ?? 0 | number : "1.2-2" }}
          </div>
        </div>
      </ng-container>

      <div *ngIf="displayFields(pageSettingModel?.isDescriptionView)">
        <br />
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch" class="sub-text">
          <b>Description</b>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch" class="sub-text" style="padding-top: 1%">
          {{ description }}
        </div>
      </div>
    </div>

    <mat-card class="total-amount-card" fxLayout="column" fxFlex="35%" fxLayoutAlign="center space-around">
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <img src="assets/payment-widget-icons/orderAmount.png" alt="logo" class="order-logo" />
      </div>
      <br />

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="total-amount">
        ${{ totalAmount ?? amount | number : "1.2-2" }}
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="theme-label">
        Total of all charges & fees
      </div>
    </mat-card>
  </div>
</mat-card>