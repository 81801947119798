import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReportTransactionTable } from 'src/app/featureModules/models/report-transaction-model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { SelectionModel } from '@angular/cdk/collections';
import { IbvAccountTypeShort } from 'src/app/featureModules/enums/ibvAccountType';
import { getSafeIsoDateTimeStringToLocal } from 'src/app/sharedModules/utils/dates';
import { IbvFavoriteService } from 'src/app/featureModules/services/ibv-favorite.service';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-report-transactions-card',
  templateUrl: './report-transactions-card.component.html',
  styleUrls: ['./report-transactions-card.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('isExpanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'isExpanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

export class ReportTransactionsComponent implements OnChanges {
  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Input()
  amountLabel: string;

  @Input()
  transactionType: string;

  selection = new SelectionModel<TableColumn>(true, []);
  searchOptionList: any = [];
  isLoading = false;
  categoryList = [];
  localCategoryList = [];
  categoryPageDetails = [];
  filter: ListFilter = '';

  @Output()
  sortChangedEvent = new EventEmitter();

  @Input()
  filterEvent = new EventEmitter();

  isTransactinTableLoading = false;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 50,
    length: 500,
    previousPageIndex: 0,
  };

  pageSizeOptions = [50, 100, 250, 500];

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [];

  allColumns: TableColumn[];

  transactionDataSource = new MatTableDataSource<ReportTransactionTable>();

  transactionColumnsList: TableColumn[] = [
    { displayName: 'CATEGORY', name: 'categoryName', width: '30%' },
    { displayName: 'TRANSACTIONS', name: 'transactionCount', width: '30%' },
    { displayName: 'AMOUNT', name: 'totalCateogoryAmount', width: '30%' },
    { displayName: '', name: 'symbol', width: '10%' },
  ];

  noRecordsMessage = 'No Transactions Found!';
  myControl = new FormControl('');
  filterText: string;
  filteredOptions: Observable<string[]>;
  selectedCategory;
  favoriteCategoryList = [];
  userRole: any;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _ibvFavoriteService: IbvFavoriteService,
    private _commonService: CommonService,
  ) {

    this.filter = this._commonService.getListDataFromLocalStorage(
      'ibaCategoryTransactionGridFilter',
      'instantBankAnalysis',
      ''
    );

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  pageCalc(total: any, categoryId): any {
    let page: any;

    if (
      this.categoryPageDetails.findIndex((x) => x.categoryId == categoryId) !=
      -1
    ) {
      this.categoryPageDetails.forEach((element, index) => {
        if (element.categoryId == categoryId) {
          page = {
            pageIndex: element.page.pageIndex,
            pageSize: element.page.pageSize,
            length: element.page.length,
            previousPageIndex: element.page.previousPageIndex,
          };
        }
      });
      return page;
    } else {
      return {
        pageIndex: 0,
        pageSize: 50,
        length: total,
        previousPageIndex: 0,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.transactionDataSource = new MatTableDataSource(this.categoryList);
  }

  getColumnForTransactionGrid() {
    if (this.accountType != IbvAccountTypeShort.INV) {
      this.columns = [
        { displayName: 'SUB CATEGORY', name: 'subCategory', width: '25%' },
        {
          displayName: 'DATE',
          name: 'postedDate',
          width: '15%',
          converter: (value) => getSafeIsoDateTimeStringToLocal(value),
        },
        { displayName: 'DESCRIPTION', name: 'memo', width: '45%' },
        { displayName: 'AMOUNT', name: 'amount', width: '15%' },
      ];
    } else {
      this.columns = [
        { displayName: 'SUB CATEGORY', name: 'subCategory', width: '18%' },
        {
          displayName: 'DATE',
          name: 'transactionDate',
          width: '10%',
          converter: (value) => getSafeIsoDateTimeStringToLocal(value),
        },
        { displayName: 'CODE', name: 'transactionCode', width: '12%' },
        { displayName: 'DESCRIPTION', name: 'description', width: '20%' },
        { displayName: 'ACTIONS', name: 'transactionAction', width: '10%' },
        { displayName: 'AMOUNT', name: 'amount', width: '10%' },
        { displayName: 'UNITS', name: 'units', width: '9%' },
        { displayName: 'UNIT PRICE', name: 'unitPrice', width: '11%' },
      ];
    }

    this.allColumns = this.columns;
  }

  AddInitialPagePerCategoryInfoInList() {
    this.categoryPageDetails = [];
    this.localCategoryList.forEach((category, index) => {
      this.categoryPageDetails.push({
        categoryId: category.categoryId,
        page: {
          pageIndex: 0,
          pageSize: 50,
          length: this.localCategoryList[index].transactionCount,
          previousPageIndex: 0,
        },
      });
    });
  }

  setCategoryPageDetails(length: number, categoryId: any) {
    this.categoryPageDetails.forEach((element, index) => {
      if (element.categoryId == categoryId) {
        element.page.pageIndex = this.page.pageIndex;
        element.page.pageSize = this.page.pageSize;
        element.page.length = length;
        element.page.previousPageIndex = this.page.previousPageIndex;
      }
    });
  }

  @Output() loadTransactionCardChange = new EventEmitter<boolean>();
  @Input('loadTransactionCard')
  set loadTransactionCard(loadTransactionCard: boolean) {
    if (loadTransactionCard) {
      this.getColumnForTransactionGrid();

      this.isLoading = true;
      this.favoriteCategoryList = [];
      this._instantVerifyReportsService
        .GetAllCategoryTransactionListBasedOnTrnType(
          this.ibvBankVerificationId,
          this.accountType,
          this.startDate,
          this.endDate,
          this.transactionType
        )
        .subscribe(
          (data) => {
            if (data.data != null) {
              this.localCategoryList = data.data;

              this.AddInitialPagePerCategoryInfoInList();
              this._ibvFavoriteService
                .GetAllCategoryFavorite(this.accountType)
                .subscribe(
                  (favoriteData) => {
                    let favCategoryIdList =
                      favoriteData.data.favoriteCategoryList;

                    if (favoriteData?.data?.favoriteCategoryList != null) {
                      this.localCategoryList.forEach((category, index) => {
                        if (favCategoryIdList.includes(category.categoryId)) {
                          this.localCategoryList[index].isFavorite = true;

                          this.favoriteCategoryList.push({
                            categoryId: category.categoryId,
                            categoryName: category.categoryName,
                            categoryIcon: category.categoryIcon,
                          });
                        } else {
                          this.localCategoryList[index].isFavorite = false;
                        }
                      });
                    }

                    this.selectedCategory =
                      this.localCategoryList.length > 0
                        ? this.localCategoryList[0]
                        : [];
                    this.onClearText();

                    this.searchOptionList = this.categoryList.map(
                      (column) => column.categoryName
                    );
                    this.filteredOptions = this.myControl.valueChanges.pipe(
                      startWith(''),
                      map((value) => this._filter(value || ''))
                    );

                    this.isLoading = false;
                  },
                  (error) => {
                    this.isLoading = false;
                  }
                );
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }

    this.loadTransactionCardChange.emit(false);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.searchOptionList.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  public onRowClick(customer: any) {}

  onSelectionChange(event: any) {
    if (event.option.value) {
      this.categoryList = this.localCategoryList.filter(
        (obj) => obj.categoryName == event.option.value
      );
    } else {
      this.categoryList = this.localCategoryList;
    }
    this.transactionDataSource = new MatTableDataSource(this.categoryList);
  }

  getColumnNames(columns: TableColumn[] = []) {
    return columns.map((column) => column.name);
  }

  // Server side Pagination
  onPageChanged(event: PageChanged, categoryId: string) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        categoryId,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.filter,
      );
    }
  }

  public onFilter(event: ListFilter = '',categoryId: string) {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        categoryId,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.filter
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'ibaCategoryTransactionGridFilter',
        'instantBankAnalysis'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged, categoryId: string) {
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.loadData(
        categoryId,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.filter
      );
    }
  }

  loadData(
    categoryId: any,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    filter: ListFilter,
  ) {
    this.isTransactinTableLoading = true;
    this._instantVerifyReportsService
      .GetSingleCategoryTransactionListBasedOnTrnType(
        this.ibvBankVerificationId,
        categoryId,
        this.accountType,
        this.startDate,
        this.endDate,
        this.transactionType,        
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
      )
      .subscribe(
        (data) => {
          this.isTransactinTableLoading = false;
          if (data.data != null) {
            this.localCategoryList.forEach((element) => {
              if (element.categoryId == categoryId) {
                element.categoryTransList = data.data.categoryTransList;
                this.selectedCategory.categoryTransList =
                  data.data.categoryTransList;
              }
            });

            this.onClearText();
            this.setCategoryPageDetails(data.data.totalRecords, categoryId);
            this.page.length = data.data.totalRecords;
          }
        },
        (error) => {
          this.isTransactinTableLoading = false;
        }
      );
  }

  onClearText() {
    this.myControl.setValue('');
    this.categoryList = this.localCategoryList;
    this.transactionDataSource = new MatTableDataSource(this.categoryList);
  }

  sortTranscationData(event: any) {
    this.isLoading = true;
    this.categoryList = this.localCategoryList;
    this.categoryList = this.categoryList.sort((a, b) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'categoryName':
          return this.compare(
            a.categoryName.toLowerCase(),
            b.categoryName.toLowerCase(),
            isAsc
          );
        case 'transactionCount':
          return this.compare(a.transactionCount, b.transactionCount, isAsc);
        case 'totalCateogoryAmount':
          return this.compare(
            a.totalCateogoryAmount,
            b.totalCateogoryAmount,
            isAsc
          );
        default:
          return 0;
      }
    });

    this.transactionDataSource = new MatTableDataSource([]);
    setTimeout(() => {
      this.transactionDataSource = new MatTableDataSource(this.categoryList);
      this.isLoading = false;
    }, 100);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getSelectedCategoryColor(selectedCategory: any) {
    return selectedCategory?.isFavorite
      ? { color: '#1E4ED8;', cursor: 'pointer;' }
      : { color: 'gray;', cursor: 'pointer;' };
  }

  getFavoriteToolTip(selectedCategory: any) {
    return !(selectedCategory?.isFavorite ?? false)
      ? 'Add to favorites'
      : 'Remove from favorites';
  }
  addOrRemoveFavorite(selectedCategory: any) {
    let action = !selectedCategory.isFavorite;

    this._ibvFavoriteService
      .AddAndRemoveFavoriteCategory(
        this.selectedCategory.categoryId,
        this.accountType,
        action
      )
      .subscribe(
        (data) => {
          if (data.data) {
            this.selectedCategory.isFavorite = action;
            let localCatIndex = this.localCategoryList.findIndex(
              (x) => x.categoryId == this.selectedCategory.categoryId
            );
            if (localCatIndex > -1) {
              this.localCategoryList[localCatIndex].isFavorite = action;
            }

            let favCatIndex = this.favoriteCategoryList.findIndex(
              (x) => x.categoryId == this.selectedCategory.categoryId
            );
            if (favCatIndex == -1) {
              this.favoriteCategoryList.push({
                categoryId: selectedCategory.categoryId,
                categoryName: selectedCategory.categoryName,
                categoryIcon: selectedCategory.categoryIcon,
              });
            } else {
              this.favoriteCategoryList.splice(favCatIndex, 1);
            }
          }
        },
        (error) => {}
      );
  }

  openSelectedCategoryDetails(categoryId) {
    this.selectedCategory = this.localCategoryList.find(
      (x) => x.categoryId == categoryId
    );
  }

  getClassCategoryName(categoryId) {
    return this.selectedCategory.categoryId == categoryId
      ? 'sectionNameActive'
      : 'sectionNameNonActive';
  }
  getCategoryIconSrc(categoryIcon: string) {
    return 'assets/ibv-report-icons/categories/' + categoryIcon + '.png';
  }

  getActiveCategoryBackground(categoryId) {
    return this.selectedCategory.categoryId == categoryId
      ? { 'background-color': 'rgba(17, 81, 243, 0.16);' }
      : { 'background-color': '#FFFFFF;' };
  }
}
