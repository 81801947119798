<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div *ngIf="isAccess && !isLoading">
  <mat-drawer-container>
    <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        class="apply-filter"
      >
        <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
          APPLY FILTER
        </div>
        <div fxLayout="column" fxFlex="10%">
          <mat-icon (click)="drawer.close()">close</mat-icon>
        </div>
      </div>

      <div class="apply-filter-body">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <form [formGroup]="transactionFilterFormGroup">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
              class="form-group form-field-bottom"
            >
              <mat-form-field fxFlex="100%" appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select
                  class="form-control"
                  formControlName="status"
                  name="status"
                >
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status.key"
                    >{{ status.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="form-field-bottom"
            >
              <mat-form-field appearance="outline">
                <mat-label>Effective Date</mat-label>
                <mat-date-range-input [rangePicker]="scheduledPicker">
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="scheduledStartDate"
                    name="scheduledStartDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="scheduledEndDate"
                    name="scheduledEndDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="scheduledPicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #scheduledPicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="form-field-bottom"
              *ngIf="!showRecurring"
            >
              <mat-form-field appearance="outline">
                <mat-label>Sent Date</mat-label>
                <mat-date-range-input [rangePicker]="processedPicker">
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="processedStartDate"
                    name="processedStartDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="processedEndDate"
                    name="processedEndDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="processedPicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #processedPicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="form-field-bottom"
            >
              <mat-form-field appearance="outline">
                <mat-label>Amount Range From</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="amountRangeFrom"
                  placeholder="Amount Range From"
                  appTwoDigitDecimalNumber
                />
                <span matPrefix>$&nbsp;&nbsp;</span>
              </mat-form-field>
            </div>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="form-field-bottom"
            >
              <mat-form-field appearance="outline">
                <mat-label>Amount Range To</mat-label>
                <input
                  type="text"
                  matInput
                  formControlName="amountRangeTo"
                  placeholder="Amount Range To"
                  appTwoDigitDecimalNumber
                />
                <span matPrefix>$&nbsp;&nbsp;</span>
              </mat-form-field>
            </div>
          </form>
        </div>

        <div fxLayout="column" fxLayoutAlign="center end">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <button mat-button class="white-button-style" (click)="restForm()">
              RESET
            </button>
            &nbsp;&nbsp;
            <button
              mat-raised-button
              color="primary"
              class="page-header-button-style"
              (click)="applyAdvanceFilter()"
            >
              APPLY
            </button>
          </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="backHeader" *ngIf="showTransctionHeader">
        <mat-label>
          <button mat-icon-button (click)="navigateToECheckTransactionsPage()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          E-Check Transactions Summary
        </mat-label>
      </div>

      <div class="page-header-customer-details" *ngIf="showTransctionHeader">
        <div fxLayout="column" fxLayoutGap="16px">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="30px"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column">
              <mat-label class="page-header-userNameCircle">{{
                getNameInitials(
                  customerModel?.firstName,
                  customerModel?.lastName
                )
              }}</mat-label>
            </div>

            <div fxLayout="column" fxLayoutGap="5px">
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="start stretch"
              >
                <mat-label class="page-header-customerName">
                  {{ customerModel?.firstName }} {{ customerModel?.lastName }}
                </mat-label>
              </div>
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="start stretch"
              >
                <mat-label class="page-header-customerEmail">
                  Email/Unique id: &nbsp;&nbsp;
                </mat-label>
                <mat-chip-list>
                  <mat-chip class="page-header-customerEmail">
                    {{ customerModel?.email }}
                  </mat-chip>
                </mat-chip-list>
              </div>
            </div>

            <span class="spacer"></span>
            <div fxLayout="column" fxLayoutAlign="center end">
              <div
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="start stretch"
              >
                <button
                  *ngIf = "this.userRole != '1'"
                  mat-raised-button
                  color="primary"
                  class="page-header-button-style"
                  (click)="onAddClick()"
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="transactions-overview">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="20px"
          fxLayoutAlign="start stretch"
          class="topContainer"
        >
          <div fxLayout="column" fxFlex="28%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
              <div fxLayout="column" fxFlex="35%">
                <img
                  matTooltip="Total Transactions"
                  class="summaryIcons"
                  src="assets/grid-icons/totalTransactions.png"
                />
              </div>
              <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{
                  totalTransactions | number : "1.0-0"
                }}</span>
                <span class="amountLblCls">Total Transactions</span>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxFlex="18%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
              <div fxLayout="column" fxFlex="35%">
                <img
                  matTooltip="Total Amount"
                  class="summaryIcons"
                  src="assets/grid-icons/totalAmount.png"
                />
              </div>
              <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls"
                  >${{ totalAmount | number : "1.2-2" }}</span
                >
                <span class="amountLblCls">Total Amount</span>
              </div>
            </div>
          </div>

          <div class="verticalLine"></div>

          <div fxLayout="column" fxFlex="18%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
              <div fxLayout="column" fxFlex="35%">
                <img
                  matTooltip="Success"
                  class="summaryIcons"
                  src="assets/grid-icons/success.png"
                />
              </div>
              <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{
                  successfulCount | number : "1.0-0"
                }}</span>
                <span class="amountLblCls">Success</span>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxFlex="18%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
              <div fxLayout="column" fxFlex="35%">
                <img
                  matTooltip="Pending"
                  class="summaryIcons"
                  src="assets/grid-icons/pending.png"
                />
              </div>
              <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{
                  pendingCount | number : "1.0-0"
                }}</span>
                <span class="amountLblCls">Pending</span>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxFlex="18%">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px">
              <div fxLayout="column" fxFlex="35%">
                <img
                  matTooltip="Failed"
                  class="summaryIcons"
                  src="assets/grid-icons/returned.png"
                />
              </div>
              <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{
                  failedCount | number : "1.0-0"
                }}</span>
                <span class="amountLblCls">Failed</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutAlign="end end">
        <div
          class="button-padding"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="start stretch"
        >
          <mat-slide-toggle
            class="form-control recurringSlide"
            (change)="recurringTransaction($event)"
            [(ngModel)] = "showRecurring"
            >Recurring
          </mat-slide-toggle>
        </div>
      </div>

      <div *ngIf="!showRecurring">
        <app-data-table
          [columns]="columns"
          [data]="gridData"
          [isLoading]="isLoading"
          [isLoadingPaging]="isLoadingPaging"
          [page]="page"
          [sortBy]="sortBy"
          [noRecordMessage]="noRecordMessage"
          [showMoreMenu]="true"
          [allColumns]="allColumns"
          [selection]="selection"
          [actionRefresh]="actionRefresh"
          (actionRefreshEvent)="onRefresh($event)"
          (pageChangedEvent)="onPageChanged($event)"
          (rowClickedEvent)="onRowClick($event)"
          (sortChangedEvent)="onSortChanged($event)"
          (onFilterEvent)="onFilter($event)"
          [isFilter]="true"
          [isExport]="false"
          [isAddBtn]="false"
          [isImportBtn]="false"
          (navigationToPageEvent)="navigationToPage()"
          (advanceFilterEvent)="drawer.toggle()"
          [filterText]="this.filter?.toString() ?? ''"
          [advanceFilterCount]="advanceFilterCount"
        >
        </app-data-table>
      </div>
      <div *ngIf="showRecurring">
        <app-data-table
          [columns]="recurringColumns"
          [data]="recurringGridData"
          [isLoading]="isLoading"
          [isLoadingPaging]="isLoadingPaging"
          [page]="page"
          [sortBy]="sortBy"
          [noRecordMessage]="noRecordMessage"
          [showMoreMenu]="true"
          [allColumns]="allColumns"
          [selection]="selection"
          [actionRefresh]="actionRefresh"
          (actionRefreshEvent)="onRefresh($event)"
          (pageChangedEvent)="onPageChanged($event)"
          (rowClickedEvent)="onRowClick($event)"
          (sortChangedEvent)="onSortChanged($event)"
          (onFilterEvent)="onFilter($event)"
          [isFilter]="true"
          [isExport]="false"
          [isAddBtn]="false"
          [isImportBtn]="false"
          (navigationToPageEvent)="navigationToPage()"
          (advanceFilterEvent)="drawer.toggle()"
          [filterText]="this.filter?.toString() ?? ''"
          [advanceFilterCount]="advanceFilterCount"
        >
        </app-data-table>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
<div *ngIf="!isAccess && !isLoading">
  <div class="page-header-text noaccess">
    You don't have access to this feature.
  </div>
</div>
