import { IGradeSettingItem } from "src/app/sharedModules/services/ibv/ibv.proxies";

export interface ICustomerInfo {
    customerId: string;
    email: string;
    firstName: string;
    lastName: string;
}

export class CustomerScoreStatsFilter implements CustomerScoreStatsFilter {
    customer: ICustomerInfo;
    fromDate: Date;
    toDate: Date;
    
    public get fromDateKey() : number {
        // TODO
        return 0;
    }

    public get toDateKey() : number {
        // TODO
        return 0;
    }
    
}

export interface IScoreGradeInfo {
    name: string | undefined;
    score: number;
    grade: string | undefined;
}

export interface IGradeScoreStatsModel {
    label: string;
    color: string;
    from: number;
    to: number
}
export class GradeScoreStatsModel implements IGradeScoreStatsModel {
    public label: string;
    public color: string;
    public from: number;
    public to: number
 
     constructor(label: string, from: number, to: number, color: string) {
         this.label = label;
         this.color = color;
         this.from = from;
         this.to = to;
     }
     public static assignFrom(data: IGradeSettingItem) {
        return new GradeScoreStatsModel(data.grade, data.from, data.to, data.color);
     }
 }
 