import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  minLength,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';
import { AchEntryCodeKeyConstants } from '../constants/ach-entry-code.const';

export class AchDetailsModel {
  @prop()
  id: number;

  @prop()
  convenienceFees: any;

  @prop()
  agreesToConvenienceFees: boolean = false;

  @prop()
  totalAmount: number;

  @prop()
  @required()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @minLength({ value: 6 })
  @maxLength({ value: 17 })
  accountNumber: string;

  @prop()
  @required()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @maxLength({ value: 12 })
  @minLength({ value: 9 })
  routingNumber: string;

  @prop()
  @required()
  @maxLength({ value: 3 })
  @minLength({ value: 3 })
  entryCode: string = AchEntryCodeKeyConstants.PPD;

  @prop()
  isRecurring: boolean;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  frequency: string;

  @prop()
  isEndOfMonth: boolean;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  scheduledDate: string;

  @prop()
  @maxLength({ value: 2, message: 'Invalid installment count' })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number: /^[0-9]*$/ } })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.isRecurring == false ? false : true;
    },
  })
  installment: number;

  @prop()
  isInstallmentTransaction: boolean = false;

  @prop()
  skipPayment: boolean = false;
}
