import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pricing-tab',
  templateUrl: './pricing-tab.component.html',
  styleUrls: ['./pricing-tab.component.scss']
})
export class PricingTabComponent implements OnInit {


  @Input()
  paymentLinkFormGroup : FormGroup;
  
  constructor() { }

  customer:boolean=false;
  logotext: string;

  ngOnInit(): void {     
    this.onInvoiceNoEntryMethodChanged();
    this.logotext = localStorage.getItem('logotext');
  }

  onInvoiceNoEntryMethodChanged(){  
    switch (this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['invoiceNoEntryMethod'].value) {
      case 'SelfInvoice':
        this.customer = false;
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['suggestedInvoiceNumber'].setValue('');
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['priceEntryMethod'].setValue('FixedPrice');
        break;
      case 'FinzeoInvoice':
        this.customer = false;
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['suggestedInvoiceNumber'].setValue('');
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['priceEntryMethod'].setValue('FixedPrice');
        break;
      case 'CustomerInvoice':
        this.customer = true;
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['priceEntryMethod'].setValue('BuyerPrice');
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['shipping'].setValue(0);
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['tax'].setValue(0); 
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['totalAmount'].setValue(this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount'].value);          
        break;
    }
  }

  onPriceEntryMethodChange(){
    switch (this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['priceEntryMethod'].value) {
      case 'FixedPrice':
        this.customer = false;
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['invoiceNoEntryMethod'].setValue('SelfInvoice');
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['suggestedInvoiceNumber'].setValue('');
        break;     
      case 'BuyerPrice':
        this.customer = true;
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['invoiceNoEntryMethod'].setValue('CustomerInvoice');
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['shipping'].setValue(0);
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['tax'].setValue(0);  
        this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['totalAmount'].setValue(this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount'].value);        
        break;
    }
  }

  setAmount(event:any){
    this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount'].setValue(event.target.value);
    this.calculateTotal();
  }

  setTax(event:any){
    this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['tax'].setValue(event.target.value);
    this.calculateTotal();
  }

  setShipping(event:any){
    this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['shipping'].setValue(event.target.value);
    this.calculateTotal();
  }

  calculateTotal(){

      let amount = +this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount'].value;
      let tax = +this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['tax'].value;
      let shipping = +this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['shipping'].value;

      let taxAmount = (tax * amount)/100;
      let shippingAmount = (shipping * amount)/100;
      let totalAmount = (amount + taxAmount + shippingAmount).toFixed(2);

      this.paymentLinkFormGroup.controls['pricingTabForm']['controls']['totalAmount'].setValue(totalAmount);
  }

}
