import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { ViewInvoiceFilterModel } from 'src/app/sharedModules/models/view-invoice-filter.model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { ListFilter } from '../../../dashboard-related/dashboard-report-total-transactions/dashboard-report-total-transactions.component';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-detail-list',
  templateUrl: './invoice-detail-list.component.html',
  styleUrls: ['./invoice-detail-list.component.scss'],
})
export class InvoiceDetailListComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  @Input()
  tabIndex: number;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  isFilter: boolean = true;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };
  columns: TableColumn[] = [];

  invoiceColumns: TableColumn[] = [
    { displayName: 'INVOICE NO.', name: 'invoiceNumber', width: '15%' },
    { displayName: 'CREATED BY', name: 'enteredBy', width: '20%' },
    { displayName: 'CREATED ON', name: 'entryDate', width: '15%' },
    { displayName: 'PRODUCTS', name: 'totalInvoieProduces', width: '10%' },
    { displayName: 'DUE DATE', name: 'invoiceDueDate', width: '15%' },
    { displayName: 'AMOUNT', name: 'totalAmount', width: '10%' },
    { displayName: 'STATUS', name: 'invoiceStatus', width: '10%' },
  ];

  recurringColumns: TableColumn[] = [
    { displayName: 'INVOICE NO.', name: 'invoiceNumber', width: '10%' },
    { displayName: 'CREATED BY', name: 'enteredBy', width: '10%' },
    { displayName: 'CREATED ON', name: 'entryDate', width: '10%' },
    { displayName: 'PRODUCTS', name: 'totalInvoieProduces', width: '10%' },
    { displayName: 'DUE DATE', name: 'invoiceDueDate', width: '10%' },
    { displayName: 'INSTALLMENTS', name: 'recurringCount', width: '12%' },
    { displayName: 'FREQUENCY', name: 'frequency', width: '12%' },
    { displayName: 'AMOUNT', name: 'totalAmount', width: '8%' },
    // { displayName: 'TOTAL', name: 'totalAmount', width: '8%' },
    { displayName: 'STATUS', name: 'invoiceStatus', width: '10%' },
  ];

  allColumns: TableColumn[];
  advanceFilters: string = '';
  advanceFiltersList:any;
  advanceFilterCount:number;
  filterFormGroup: FormGroup;
  @ViewChild('drawer') drawer: MatDrawer;
  showRecurring: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService,
    private _invoiceDashboardService: InvoiceDashboardService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns = this.invoiceColumns;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'invoiceDetailGridFilter',
      'invoice',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'invoiceDetailGridPageIndex',
          'invoice',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'invoiceDetailGridPageSize',
          'invoice',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortActive',
        'invoice',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortDirection',
        'invoice',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'invoiceDetailAdvanceFilter',
      'invoice',
      ''
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'invoiceDetailAdvanceFilterForm',
        'invoice',
        JSON.stringify(new ViewInvoiceFilterModel())
      )
    );

    let filterModel = new ViewInvoiceFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  ngOnInit(): void {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
  }

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();

    this._invoiceDashboardService
      .getAllViewInvoiceGrid(
        this.showRecurring,
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.gridData = data.data.transactions;
            this.page.length = data.data.totalRecords;
          } else {
            this.gridData = [];
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'invoiceDetailGridFilter',
        'invoice'
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'invoiceDetailGridPageSize',
        'invoice'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'invoiceDetailGridPageIndex',
        'invoice'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'invoiceDetailGridSortActive',
        'invoice'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'invoiceDetailGridSortDirection',
        'invoice'
      );
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    data.createdStartDate?.setHours(12, 0, 0);
    data.createdEndDate?.setHours(12, 0, 0);
    data.periodStartDate?.setHours(12, 0, 0);
    data.periodEndDate?.setHours(12, 0, 0);
    data.dueStartDate?.setHours(12, 0, 0);
    data.dueEndDate?.setHours(12, 0, 0);

    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'invoiceDetailGridPageSize',
          'invoice',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortActive',
        'invoice',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortDirection',
        'invoice',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if (!data.statusAll && this.setStatusField(data)) {
      this.advanceFilters += 'status:' + this.setStatusField(data) + '|';
    }
    if (data.createdStartDate && data.createdEndDate) {
      this.advanceFilters +=
        'created:' +
        getSafeIsoDateString(data.createdStartDate) +
        'to' +
        getSafeIsoDateString(data.createdEndDate) +
        '|';
    }
    if (data.periodStartDate && data.periodEndDate) {
      this.advanceFilters +=
        'period:' +
        getSafeIsoDateString(data.periodStartDate) +
        'to' +
        getSafeIsoDateString(data.periodEndDate) +
        '|';
    }
    if (data.dueStartDate && data.dueEndDate) {
      this.advanceFilters +=
        'due:' +
        getSafeIsoDateString(data.dueStartDate) +
        'to' +
        getSafeIsoDateString(data.dueEndDate) +
        '|';
    }
    if (data.amountRangeFrom && data.amountRangeTo) {
      if (parseFloat(data.amountRangeFrom) > parseFloat(data.amountRangeTo)) {
        this._toastrService.warning(
          'Amount From cannot be greater than Amount To'
        );
        return;
      } else {
        this.advanceFilters +=
          'amount:' + data.amountRangeFrom + 'to' + data.amountRangeTo + '|';
      }
    }
    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'invoiceDetailAdvanceFilter',
      'invoice'
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'invoiceDetailAdvanceFilterForm',
      'invoice'
    );

    this.isLoading = true;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  statusChangeEvent(status: string) {
    switch (status) {
      case 'all':
        this.filterFormGroup
          .get('statusPaid')
          .setValue(this.filterFormGroup.value.statusAll);
        this.filterFormGroup
          .get('statusUnpaid')
          .setValue(this.filterFormGroup.value.statusAll);
        this.filterFormGroup
          .get('statusSent')
          .setValue(this.filterFormGroup.value.statusAll);
        this.filterFormGroup
          .get('statusOverdue')
          .setValue(this.filterFormGroup.value.statusAll);
        this.filterFormGroup
          .get('statusPending')
          .setValue(this.filterFormGroup.value.statusAll);
        break;
      default:
        this.filterFormGroup.get('statusAll').setValue(false);
        if (
          this.filterFormGroup.value.statusPaid &&
          this.filterFormGroup.value.statusUnpaid &&
          this.filterFormGroup.value.statusSent &&
          this.filterFormGroup.value.statusOverdue &&
          this.filterFormGroup.value.statusPending
        )
          this.filterFormGroup.get('statusAll').setValue(true);
        break;
    }
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      this.filterFormGroup = this.formBuilder.formGroup(
        new ViewInvoiceFilterModel()
      );
    }
  }

  setStatusField(data: any) {
    var status: string = '';
    if (data.statusPaid) status += 'paidand';
    if (data.statusUnpaid) status += 'unpaidand';
    if (data.statusSent) status += 'sentand';
    if (data.statusOverdue) status += 'overdueand';
    if (data.statusPending) status += 'pendingand';
    return status;
  }

  viewInvoiceChange(event: any) {
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'invoiceDetailGridPageSize',
          'invoice',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortActive',
        'invoice',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'invoiceDetailGridSortDirection',
        'invoice',
        'desc'
      ),
    };
    this.advanceFilters = '';

    switch (event.status) {
      case 'unpaid':
        this.showRecurring = false;
        this.allColumns = this.columns = this.invoiceColumns;
        if (event.value) this.advanceFilters += 'status:' + 'unpaid' + '|';
        break;
      case 'overdue':
        this.showRecurring = false;
        this.allColumns = this.columns = this.invoiceColumns;
        if (event.value) this.advanceFilters += 'status:' + 'overdue' + '|';
        break;
      case 'recurring':
        if (event.value) {
          this.showRecurring = true;
          this.allColumns = this.columns = this.recurringColumns;
          this.isFilter = false;
        } else {
          this.showRecurring = false;
          this.allColumns = this.columns = this.invoiceColumns;
          this.isFilter = true;
          this.advanceFilters = this._commonService.getListDataFromLocalStorage(
            'invoiceDetailAdvanceFilter',
            'invoice',
            ''
          );
        }
        break;
    }

    this.isLoading = true;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    if (!this.router.url.includes('invoices'))
      localStorage.removeItem('invoice');
  }
}
