import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, of } from 'rxjs';
import { HolidayUtils } from 'src/app/featureModules/helper/holiday.utils';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { SendInvoiceModel } from 'src/app/sharedModules/models/send-invoice.model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Component({
  selector: 'app-send-invoice-dialog',
  templateUrl: './send-invoice-dialog.component.html',
  styleUrls: ['./send-invoice-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SendInvoiceDialogComponent implements OnInit {
  loadEmailComponent: boolean = true;
  loadSmsComponent: boolean = false;
  loadBothComponent: boolean = false;
  sendInvoiceFormGroup: FormGroup;
  sendInvoiceModel: SendInvoiceModel;
  newEmailList: string;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  defaultScheduledDate = new Date();
  dueDate: Date = new Date();
  isLoading: boolean = false;
  loadMessage: 'Please wait...';
  dueDatedays: number;
  constructor(
    private formBuilder: RxFormBuilder,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService,
    private router: Router,
    private _holidayUtils: HolidayUtils
  ) {}

  ngOnInit(): void {
    this.sendInvoiceModel = new SendInvoiceModel();

    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    this.sendInvoiceModel.emailList = [];

    this.dueDatedays = this._invoiceService.invoiceServiceMainModel.dueDateDays;

    this.sendInvoiceModel.registeredNumber =
      this._invoiceService.invoiceServiceMainModel.registeredNumber;
    this.sendInvoiceModel.registeredEmail =
      this._invoiceService.invoiceServiceMainModel.registeredEmail;
    this.sendInvoiceModel.sendBy = 'Email';

    this.sendInvoiceFormGroup = this.formBuilder.group({
      scheduleDate: this.sendInvoiceModel.scheduleDate,
      dueDate: this.sendInvoiceModel.invoiceDueDate,
      sendBy: this.sendInvoiceModel.sendBy,
      registeredEmail: this.sendInvoiceModel.registeredEmail,
      registeredNumber: this.sendInvoiceModel.registeredNumber,
      // emailList:this.sendInvoiceModel.emailList,
      // mobileNumberList:this.sendInvoiceModel.mobileNumberList,
    });

    this.setScheduledDateAndHolidayFilter();
  }

  prepareFormBuilder() {
    this.sendInvoiceFormGroup = this.formBuilder.group({
      scheduleDate: this.sendInvoiceModel.scheduleDate,
      dueDate: this.sendInvoiceModel.invoiceDueDate,
      sendBy: this.sendInvoiceModel.sendBy,
      // emailList:this.sendInvoiceModel.emailList,
      // mobileNumberList:this.sendInvoiceModel.mobileNumberList,
      registeredEmail: this.sendInvoiceModel.registeredEmail,
      registeredNumber: this.sendInvoiceModel.registeredNumber,
    });
  }

  getDetails(data) {
    this.sendInvoiceModel.registeredNumber = data.registeredNumber;
    this.sendInvoiceModel.registeredNumber = data.registeredNumber;
    // this.sendInvoiceModel.sendBy="SMS";
    this.prepareFormBuilder();
  }

  getEmailList(event) {
    this.sendInvoiceModel.emailList = event;
  }

  getMobileNoList(event) {
    this.sendInvoiceModel.mobileNumberList = event;
  }

  dueDateCalc(event: any) {
    this.sendInvoiceModel.invoiceDueDate = null;
    this.sendInvoiceModel.scheduleDate =
      this._invoiceService.invoiceServiceMainModel.scheduleDate =
      this.defaultScheduledDate =
        this.sendInvoiceFormGroup.controls['scheduleDate'].value;

    this.dueDate = new Date(this.sendInvoiceFormGroup.value.scheduleDate);

    this.dueDate.setDate(
      this.sendInvoiceFormGroup.value.scheduleDate.getDate() + this.dueDatedays
    );    

    if (this.dueDate.getDay() == 0) {
      this.dueDate = this._holidayUtils.addDays(this.dueDate, 1);
    }
    if (this.dueDate.getDay() == 6) {
      this.dueDate = this._holidayUtils.addDays(this.dueDate, 2);
    }

    this.dueDate =
      this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
        this.dueDate,
        this.holidays
      );

    this.defaultScheduledDate = event.value;

    setTimeout(() => {
      this.sendInvoiceModel.invoiceDueDate = this.dueDate;
    }, 10);
  }

  getEmailValue(value: string) {
    this.newEmailList = value;
  }

  currentYear = new Date().getFullYear();
  myDateFilter = (d: Date | null): boolean => {
    const year = (d || new Date()).getFullYear();
    return year >= this.currentYear - 1;
  };

  onSendByChanged(event: any) {
    this.sendInvoiceFormGroup.controls['sendBy'].setValue(event.value);
    this.sendInvoiceModel.sendBy =
      this.sendInvoiceFormGroup.controls['sendBy'].value;
    this._invoiceService.invoiceServiceMainModel.sendBy =
      this.sendInvoiceFormGroup.value.sendBy;
    switch (this.sendInvoiceFormGroup.value.sendBy) {
      case 'Email':
        this.loadEmailComponent = true;
        this.loadSmsComponent = false;
        break;
      case 'SMS':
        this.loadSmsComponent = true;
        this.loadEmailComponent = false;
        break;
      case 'Both':
        this.loadEmailComponent = true;
        this.loadSmsComponent = true;
        break;
    }
  }

  sendInvoice() {
    let elist: string[];
    let mlist: string[];
    if (
      this.sendInvoiceModel.sendBy == 'Email' ||
      this.sendInvoiceModel.sendBy == 'Both'
    ) {
      elist = this.sendInvoiceModel.emailList;
    }
    if (
      this.sendInvoiceModel.sendBy == 'SMS' ||
      this.sendInvoiceModel.sendBy == 'Both'
    ) {
      mlist = this.sendInvoiceModel.mobileNumberList;
    }
    let client = JSON.parse(localStorage.getItem('userProfile'));
    this.sendInvoiceFormGroup.value.scheduleDate?.setHours(12, 0, 0);
    this.sendInvoiceModel.invoiceDueDate?.setHours(12, 0, 0);
    this._invoiceService.invoiceServiceMainModel.scheduleDate =
      getSafeIsoDateString(this.sendInvoiceModel.scheduleDate);
    this._invoiceService.invoiceServiceMainModel.invoiceDueDate =
      getSafeIsoDateString(this.sendInvoiceModel.invoiceDueDate);
    this._invoiceService.invoiceServiceMainModel.sendBy =
      this.sendInvoiceModel.sendBy;
    this._invoiceService.invoiceServiceMainModel.registeredEmail =
      this.sendInvoiceModel.registeredEmail;
    this._invoiceService.invoiceServiceMainModel.registeredNumber =
      this.sendInvoiceModel.registeredNumber;
    this._invoiceService.invoiceServiceMainModel.additionalEmail = elist;
    this._invoiceService.invoiceServiceMainModel.additionalNumber = mlist;
    this._invoiceService.invoiceServiceMainModel.enteredBy =
      client['firstName'] + ' ' + client['lastName'];
    if (this.sendInvoiceFormGroup.controls['scheduleDate'].value == null) {
      this._toastrService.warning('Please Add Schedule Date details!');
      return false;
    }
    if (this.sendInvoiceFormGroup.valid) {
      this.isLoading = true;
      this._invoiceService
        .saveInvoiceDetails(this._invoiceService.invoiceServiceMainModel)
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data == true && data.code == 200) {
              this._invoiceService.dialogRef.close();
              this._toastrService.success('Invoice Sent successfully!');
              this.router.navigate(['invoices']);
            } else if (data.code == 400) {
              this.isLoading = false;                            
              this._toastrService.error(data.errorMessage);
            } else {
              this.isLoading = false;
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.error("Something went wrong ,Please contact your administrator");
              this._invoiceService.dialogRef.close();
              this.router.navigate(['invoices']);
            }
            this.isLoading = false;
          }
        );
    }
  }

  holidays: any;
  setScheduledDateAndHolidayFilter() {
    const onSuccess = (data: any) => {
      this.holidays = data.data;
    };
    const onError = (e) => {
      return of([]);
    };

    this._invoiceService
      .getUpcomingHolidays(this.minDate.getFullYear())
      .pipe(map(onSuccess), catchError(onError))
      .subscribe(() => {
        this.defaultScheduledDate =
          this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
            this.defaultScheduledDate,
            this.holidays
          );
      });
  }

  holidayFilter = (date: Date): boolean => {
    return this._holidayUtils.getHolidaysFilter(this.holidays, date);
  };

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date, view) => {
    return this._holidayUtils.getDateClass(this.holidays, date, view);
  };
}
