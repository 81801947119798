<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div fxLayout="column" class="container" 
[style.--active-fontFamily]="pageSettingModel?.themeFont" 
fxLayoutWrap>
  <div
    class="payment-header-dv"
    fxLayout="row"
    fxFlex="50%"
    fxLayoutWrap
    [style.background-color]="pageSettingModel?.themeColor"
    fxLayoutAlign="center center"
  >
    <img
      class="company-logo"
      *ngIf="!isLoading"
      [src]="pageSettingModel?.logo"
      alt="company logo"
    />
  </div>
  <div class="payment-div">
    <div
      *ngIf="!isLoading"
      class="payment-order-container w100"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="5%"
    >
      <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
        <img
          class="img-invalid-token"
          src="/assets/payment-widget-icons/msg_failed.svg"
          alt="{{ errorMessage }}"
          title="{{ errorMessage }}"
        />
      </div>

      <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
        <span class="header-label"> {{ errorMessage }} </span>
      </div>
    </div>
  </div>
  <div
    fxLayout="row"
    fxFlex="50%"
    fxLayoutWrap
    class="payment-footer-dv"
    fxLayoutAlign="center center"
  >        
      <div fxLayout="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center center" class="payment-footer-div-col">
      <img  class="secure-payment-logo" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
          <label style="text-align:center"> Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
      </div>    
  </div>
</div>
