import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class CloneBatchModel {
  @prop()
  BatchId: string;

  @required()
  @maxLength({ value: 60 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ()]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  BatchName: string;

  @prop()
  @required()
  EffectiveDate: string;
}
