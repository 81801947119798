import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';

@Component({
  selector: 'app-customer-profile',
  templateUrl: './customer-profile.component.html',
  styleUrls: ['./customer-profile.component.scss'],
})
export class CustomerProfileComponent implements OnInit {
  customerModel: AddCustomerModel;
  isViewCustomerDetails = true;
  isEditCustomerDetails = false;
  selectedTabIndex: number = 0;
  selectedTransactionTabIndex: number;
  isLoading = false;
  customerId: any;
  isActive: Boolean;
  loadBasicDetails: boolean = true;
  loadBankAndCard: boolean;
  loadTransactions: boolean;
  userRole: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService
  ) {
    const navigation = this.router.getCurrentNavigation();

    this.selectedTransactionTabIndex = navigation?.extras?.state
      ? navigation.extras.state['selectedTransactionTabIndex']
      : 0;

    this.selectedTabIndex = navigation?.extras?.state
      ? navigation.extras.state['transactionIndex']
      : 0;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.selectedTabChangeEvent();

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  ngOnInit(): void {
    this.customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
    if (this.customerId) {
      this.getCustomerById();
    }
  }

  getCustomerById() {
    this.isLoading = true;
    this.customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.customerModel = data.data;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  onContactCustomerClick() {}

  onEditClick() {
    this.router.navigate(['customers/manage-customer', this.customerId]);
  }

  selectedTabChangeEvent() {
    switch (this.selectedTabIndex) {
      case 0:
        this.loadBasicDetails = true;
        break;
      case 1:
        this.loadBankAndCard = true;
        break;
      case 2:
        this.loadTransactions = true;
        break;
    }
  }

  navigateToCustomersPage() {
    this.router.navigate(['customers']);
  }
}
