import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { OriginationReportFilterModel } from 'src/app/featureModules/models/origination-report-filter-model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Component({
  selector: 'app-origination-report-filter',
  templateUrl: './origination-report-filter.component.html',
  styleUrls: ['./origination-report-filter.component.scss']
})
export class OriginationReportFilterComponent {

  isLoading: boolean;
  loadMessage: string = 'Please wait...';  
  maxDate: Date = new Date();
  todaysbatchDate :Date = new Date();
  selectedBactDate :string;
  originationFormGroup: FormGroup;
  originationReportFilterModel: OriginationReportFilterModel;

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
  ) {    
  }


  ngOnInit(): void {
    this.originationReportFilterModel = new OriginationReportFilterModel();
    this.originationFormGroup = this.formBuilder.formGroup(
      this.originationReportFilterModel
    );
    this.originationFormGroup.controls['batchDate'].setValue(this.todaysbatchDate);
  }

  navigateToReportPage(){
    this.router.navigate(['reports']);
  }

  onApplyClick(){
    this.selectedBactDate = getSafeIsoDateString(this.originationFormGroup.controls['batchDate'].value);
    this.router.navigate(['reports/origination-report/' + this.selectedBactDate]);
  }
}
