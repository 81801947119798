<mat-dialog-content class="content">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="center stretch"
    class="success-message"
    [innerHtml]="data.successMessage"
  ></div>
  <div
    *ngIf="data.subSuccessMessage"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="center stretch"
    class="sub-success-message"
    [innerHtml]="data.subSuccessMessage"
  ></div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button
    *ngIf="data.delete && data.buttonName === 'ACTIVATE'"
    class="btnNoClass button"
    mat-button
    [mat-dialog-close]="true"
  >
    {{ data.buttonName | uppercase }}
  </button>
  <button
    *ngIf="data.delete && data.buttonName !== 'ACTIVATE' && data.buttonName !== 'SUBMIT'"
    class="btnYesClass button"
    mat-button
    [mat-dialog-close]="true"
  >
    {{ data.buttonName | uppercase }}
  </button>
  <button
    *ngIf="data.delete && data.buttonName == 'SUBMIT'"
    class="btnSubmitClass button"
    mat-button
    [mat-dialog-close]="true"
  >
    {{ data.buttonName | uppercase }}
  </button>
  <button
  *ngIf="!data.delete && (data.buttonName === 'Yes' || data.buttonName === 'WITHDRAW')"
  class="btnBlueClass button"
  mat-button
  [mat-dialog-close]="true"
>
  {{ data.buttonName | uppercase }}
</button>  
  <button
    *ngIf="!data.delete && !(data.buttonName === 'Yes' || data.buttonName === 'WITHDRAW')"
    class="btnNoClass button"
    mat-button
    [mat-dialog-close]="true"
  >
    {{ data.buttonName | titlecase }}
  </button>
  <button class="button btnCancelClass" mat-button [mat-dialog-close]="false">
    <span *ngIf="!data.buttonCancel">CANCEL</span>
    <span *ngIf="data.buttonCancel">{{data.buttonCancel | titlecase}}</span>
  </button>
</mat-dialog-actions>
