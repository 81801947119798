<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
    <div class="backHeader">
        <mat-label>
          <button mat-icon-button (click)="navigateToBackPage()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          Inventory Product Management
        </mat-label>
      </div>
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text" *ngIf="!isEdit">Add Item</div>
                <div class="page-header-text" *ngIf="isEdit">Edit Item</div>
                <div class="page-sub-header-text-1">
                    All Invoice Inventory Management related data can be managed from this page
                </div>
            </div>
        </div>
    </div>
</div>


<div fxLayout="row" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <div class="form-AddUserv" class="mainDiv">
            <div class="info-AddUser">
                <div *ngIf="!isEdit">
                    <p class="title">Add Item Details</p>
                </div>
                <div *ngIf="isEdit">
                    <p class="title">Update Item Details</p>
                </div>

                <form [formGroup]="addInventoryFormGroup" autocomplete="off">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap
                            fxLayoutAlign="start stretch">
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Item Name</mat-label>
                                    <input type="text" matInput formControlName="itemName" maxlength="50">
                                    <mat-error>
                                        {{ addInventoryFormGroup.controls["itemName"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>

                            <div fxFlex="22%" class="margin-top" *ngIf="!isEdit">
                                <ng-multiselect-dropdown
                                    name="categoryIdList"
                                    placeholder="Select Category"
                                    [settings]="dropdownSettings"
                                    [data]="categoryList"
                                    formControlName="categoryIdList"                                                                       
                                    (onSelect)="onItemSelect($event)">
                                </ng-multiselect-dropdown>
                                <mat-error>
                                    {{ addInventoryFormGroup.controls["categoryIdList"]["_errorMessage"] }}
                                </mat-error>                           
                            </div>
                            
                            <div fxFlex="22%" *ngIf="isEdit">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <input type="text"  placeholder="Select Category" formControlName="categoryId" name="categoryId" aria-label="Category" matInput [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCategoryName.bind(this)">
                                  <mat-option *ngFor="let category of categoryList" [value]="category.id">
                                    {{ category.categoryName }}
                                  </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            </div>

                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>SKU</mat-label>
                                    <input type="text" matInput formControlName="sku" maxlength="11"
                                    (change)="setSku($event)">
                                    <mat-error>
                                        {{ addInventoryFormGroup.controls["sku"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Quantity</mat-label>
                                    <input type="number" matInput formControlName="quantity" (input)="setQuantity($event)" required>
                                    <mat-error>
                                        {{ addInventoryFormGroup.controls["quantity"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                                <div *ngIf="addInventoryFormGroup.controls['quantity'].valid && isEdit" class="availableQuantity">
                                    Available Quantity : {{ addInventoryModel.availableQuantity }} (Update based on current warehouse stock).                                   
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    &nbsp;
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap
                            fxLayoutAlign="start center">                           
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Unit Price</mat-label>
                                    <input type="number" matInput formControlName="unitPrice">
                                    <span matPrefix class="material-icons">attach_money</span>
                                    <mat-error>
                                        {{ addInventoryFormGroup.controls["unitPrice"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="10%">
                                    <div fxLayout="column" >
                                      Status
                                    </div>
                                    <div fxLayout="column" >
                                      <mat-slide-toggle formControlName="isActive" name="isActive" class="form-control">
                                        <mat-label
                                        class="showDetailsValue"
                                        [ngStyle]="{
                                          ' color': addInventoryFormGroup.controls['isActive'].value
                                            ? 'green'
                                            : 'red'
                                        }"
                                            >
                                        {{
                                            addInventoryFormGroup.controls["isActive"].value
                                            ? "Active"
                                            : "Inactive"
                                        }}
                                      </mat-label>
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                            </div>                           
                        </div>
                    </div>
                </form>
            </div>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="float: left;">
                <div style="padding-top: 20px; float: right;">
                    <button mat-button class="white-button-style" (click)="cancel()">CANCEL</button>
                    &nbsp;&nbsp;
                    <button *ngIf="!isEdit" mat-raised-button  color="primary" class="page-header-button-style" (click)="addInventory()">ADD</button>                    
                    <button *ngIf="isEdit" mat-raised-button  color="primary" class="page-header-button-style" (click)="updateInventory()">UPDATE</button>
                    &nbsp;&nbsp;
                </div>
            </div>
        </div>
    </div>

</div>