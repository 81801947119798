import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hosted-payment-page-btm-preview-mobile',
  templateUrl: './hosted-payment-page-btm-preview-mobile.component.html',
  styleUrls: ['./hosted-payment-page-btm-preview-mobile.component.scss']
})
export class HostedPaymentPageBtmPreviewMobileComponent implements OnInit {

  isSmallView: boolean;
  deviceType: string;
  
  @Input()
  changesIsLoading: boolean;

  @Input()
  finzeoPaymentOption: string;
  @Input()
  formData: any;
  @Output()
  onPageChangeEvent = new EventEmitter<string>()

  constructor() { }

  ngOnInit(): void {
    this.isSmallView = true;
    this.deviceType = "mobile";
  }

  onPageChange(pageIndex: string){
    this.onPageChangeEvent.emit(pageIndex);
   }

}
