import { Component, EventEmitter, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChartConfiguration, ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-dashboard-report-transaction-status',
  templateUrl: './dashboard-report-transaction-status.component.html',
  styleUrls: ['./dashboard-report-transaction-status.component.scss']
})
export class DashboardReportTransactionStatusComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  transactionStatusGridData;
  transactionType: string = '';
  private timeout: number;
  tableLabel: string = ""
  period: string = "yearly"

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: '',
    direction: 'asc',
  };

  transactionStatuscolumns: TableColumn[] = [
    { displayName: 'MONTH', name: 'month', width: '11%' },
    { displayName: 'RETURNED (LY)', name: 'lastYearReturned', width: '10%' },
    { displayName: 'RETURNED (TY)', name: 'currentYearReturned', width: '10%' },
    { displayName: 'SUCCESS (LY)', name: 'lastYearSuccessful', width: '10%' },
    { displayName: 'SUCCESS (TY)', name: 'currentYearSuccessful', width: '10%' },
    { displayName: 'PENDING (LY)', name: 'lastYearPending', width: '10%' },
    { displayName: 'PENDING (TY)', name: 'currentYearPending', width: '10%' },
    { displayName: 'FAILED (LY)', name: 'lastYearFailed', width: '10%' },
    { displayName: 'FAILED (TY)', name: 'currentYearFailed', width: '10%' },
    { displayName: 'CANCELLED (LY)', name: 'lastYearCancelled', width: '10%' },
    { displayName: 'CANCELLED (TY)', name: 'currentYearCancelled', width: '10%' }
  ];

  cardTransactionStatuscolumns: TableColumn[] = [
    { displayName: 'MONTH', name: 'month', width: '11%' },
    { displayName: 'RETURNED (LY)', name: 'lastYearReturned', width: '10%' },
    { displayName: 'RETURNED (TY)', name: 'currentYearReturned', width: '10%' },
    { displayName: 'SUCCESS (LY)', name: 'lastYearSuccessful', width: '10%' },
    { displayName: 'SUCCESS (TY)', name: 'currentYearSuccessful', width: '10%' },
    { displayName: 'PENDING (LY)', name: 'lastYearPending', width: '10%' },
    { displayName: 'PENDING (TY)', name: 'currentYearPending', width: '10%' },
    { displayName: 'FAILED (LY)', name: 'lastYearFailed', width: '10%' },
    { displayName: 'FAILED (TY)', name: 'currentYearFailed', width: '10%' },
  ];

  totalAllColumns: TableColumn[];
  cardTotalAllColumns: TableColumn[];
  periodCurrentLabel: string = '';
  periodLastLabel: string = '';

  isReturnDataAvailable: boolean = false;
  isSuccessDataAvailable: boolean = false;
  isFailedDataAvailable: boolean = false;
  isPendingDataAvailable: boolean = false;
  isCancelledDataAvailable:boolean=false;

  constructor(private router: Router,
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute, private _toastrService: ToastrService) {
    this.totalAllColumns = this.transactionStatuscolumns;
    this.cardTotalAllColumns = this.cardTransactionStatuscolumns;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.periodCurrentLabel = 'This Year';
    this.periodLastLabel = 'Last Year';
    this.GetTransactionStatusPercentage('yearly');
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.transactionType = params.get('product');
      this.tableLabel = "Transaction Status"
    });
    this.loadData(this.transactionType, this.sortBy.active,
      this.sortBy.direction);
    this.periodCurrentLabel = 'This Year';
    this.periodLastLabel = 'Last Year';
    this.GetTransactionStatusPercentage('yearly');
  }

  navigateToDashboardPage() {
    this.router.navigate(['dashboard']);
  }

  onRefresh(id: string) { }

  loadData(transactionType, sortBy: string,
    sortDirection: string) {
    this.isLoading = true;
    this._dashboardService.GetTransactionStatusData(transactionType, sortBy, sortDirection).subscribe((data) => {
      this.isLoading = false;
      if (data.data != null) {
        this.transactionStatusGridData = data.data;
        this.page.length = data.data.totalRecords;
      }
      else {
        this.transactionStatusGridData = [];
      }
    },
    (error) => {
      this.isLoading = false;
    });
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(this.transactionType, this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(this.transactionType, this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.totalAllColumns[event.active];
    var column = this.totalAllColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(this.transactionType, this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onCardGridSortChanged(event: SortChanged) {
    const column1: TableColumn = this.cardTotalAllColumns[event.active];
    var column = this.cardTotalAllColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(this.transactionType, this.sortBy.active,
        this.sortBy.direction);
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'month':
        this.sortBy.active = 'month';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {
  }

  GetTransactionStatusPercentage(period: string) {
    this.isLoading = true;
    this.doughnutChartLabels = [this.periodLastLabel, this.periodCurrentLabel];
    this.doughnutChartData.labels = this.doughnutChartLabels;
    this.doughnutChartReturnedData.labels = this.doughnutChartLabels;
    this.doughnutChartSuccessData.labels = this.doughnutChartLabels;
    this.doughnutChartPendingData.labels = this.doughnutChartLabels;
    this.doughnutChartFailedData.labels = this.doughnutChartLabels;
    this.doughnutChartCancelledData.labels = this.doughnutChartLabels;

    this._dashboardService.GetTransactionStatusPercentage(this.transactionType, period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          
          this.doughnutChartReturnedData.datasets[0].data =
            [data.data.returned.previous, data.data.returned.current];

          this.doughnutChartSuccessData.datasets[0].data =
            [data.data.success.previous, data.data.success.current];

          this.doughnutChartPendingData.datasets[0].data =
            [data.data.pending.previous, data.data.pending.current];

          this.doughnutChartFailedData.datasets[0].data =
            [data.data.failed.previous, data.data.failed.current];

          this.doughnutChartCancelledData.datasets[0].data =
            [data.data.cancelled.previous, data.data.cancelled.current];

          this.isReturnDataAvailable = data.data.returned.previous == 0 && data.data.returned.current == 0 ? false : true;
          this.isSuccessDataAvailable = data.data.success.previous == 0 && data.data.success.current == 0 ? false : true;
          this.isFailedDataAvailable = data.data.failed.previous == 0 && data.data.failed.current == 0 ? false : true;
          this.isPendingDataAvailable = data.data.pending.previous == 0 && data.data.pending.current == 0 ? false : true;
          this.isCancelledDataAvailable = data.data.cancelled.previous == 0 && data.data.cancelled.current == 0 ? false : true;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  public doughnutChartLabels: string[] = [this.periodLastLabel, this.periodCurrentLabel];
  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [80, 20],
        backgroundColor: ['#2196F3', '#1E4ED8'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartReturnedData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Returned',
        data: [80, 20],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartSuccessData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Success',
        data: [20, 80],
        backgroundColor: ['#1E4ED8', '#4CAF50'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartPendingData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Pending',
        data: [60, 40],
        backgroundColor: ['#1E4ED8', '#FB8C00'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartFailedData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Failed',
        data: [50, 50],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartCancelledData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Cancelled',
        data: [50, 50],
        backgroundColor: ['#1E4ED8', '#525252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartType: ChartType = 'doughnut';
  
  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    cutout: 50,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + "%";
          }
        }
      }
    },
    radius: 60
  }

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }
}
