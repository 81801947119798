import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { Pattern } from 'src/app/sharedModules/constants/pattern.const';

@Component({
  selector: 'app-forgot-password-step1',
  templateUrl: './forgot-password-step1.component.html',
  styleUrls: ['./forgot-password-step1.component.scss'],
})
export class ForgotPasswordStep1Component implements OnInit {
  public forgotPassForm: FormGroup;
  isAdminLogin: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService
  ) {
    this.isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
  }

  ngOnInit(): void {
    this.forgotPassForm = this.formBuilder.group({
      company: [null, this.isAdminLogin ? [] : [Validators.required]],
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern(Pattern.email),
        ],
      ],
    });
  }

  cancelEvent() {
    this.isAdminLogin
      ? this.router.navigate(['/csr'])
      : this.router.navigate(['/']);
  }

  submit(data: any) {
    this.loginService.submitMail(data.company, data.email, this.isAdminLogin);
  }
}
