import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeContext, LabelType, Options } from 'ng5-slider';
import { CustomerService } from 'src/app/blockModules/services/customers.service';

@Component({
  selector: 'app-calender-slider-card',
  templateUrl: './calender-slider-card.component.html',
  styleUrls: ['./calender-slider-card.component.scss'],
})
export class CalenderSliderCardComponent implements OnInit {
  customerId: any;

  //ng5-slider start
  pipe = new DatePipe('en-US');
  minValue: number = 0;
  maxValue: number = 3;
  options: Options = {
    floor: 0,
    ceil: 25,
    rightToLeft: true,
    showTicks: true,
    touchEventsInterval: 5,
    ticksTooltip: (value) => {
      let Val = value !== null ? parseInt(value.toString()) : 0;
      var d = new Date();
      d.setDate(1);
      if (Val != 0) d.setMonth(d.getMonth() - (Val - 1));
      else d = new Date();
      return `${this.pipe.transform(d, 'MMM d, y')}`;
    },
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low: {
          let Val = value !== null ? parseInt(value.toString()) : 0;
          var d = new Date();
          d.setDate(1);
          if (Val != 0) d.setMonth(d.getMonth() - (Val - 1));
          else d = new Date();
          this.minValue = value;
          return '' + this.pipe.transform(d, 'MMM d, y');
        }
        case LabelType.High: {
          let Val = value !== null ? parseInt(value.toString()) : 0;
          var d = new Date();
          d.setDate(1);
          if (Val != 0) d.setMonth(d.getMonth() - (Val - 1));
          else d = new Date();
          this.maxValue = value;
          return '' + this.pipe.transform(d, 'MMM d, y');
        }
        default: {
          let Val = value !== null ? parseInt(value.toString()) : 0;
          var d = new Date();
          d.setDate(1);
          if (Val != 0) d.setMonth(d.getMonth() - (Val - 1));
          else d = new Date();
          this.maxValue = value;
          return '' + this.pipe.transform(d, 'MMM y');
        }
      }
    },
  };
  //ng5-slider end

  //Slider Section starts
  localFromDate: Date;
  localToDate: Date;
  startDate: Date;
  sliderHighVal!: number;
  sliderLowVal: number;
  initialValueOfSlider: number;
  formatLabel: string;
  panelOpenState = false;
  //Slider Section ends

  @Output()
  calenderSlideEvent = new EventEmitter();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService
  ) {}

  ngOnInit(): void {}

  onInputChange(changeContext: ChangeContext) {
    this.sliderHighVal =
      changeContext.highValue !== null
        ? parseInt(changeContext.highValue.toString())
        : 0;

    this.startDate = new Date();
    this.startDate.setDate(1);
    if (this.sliderHighVal != 0)
      this.startDate.setMonth(
        this.startDate.getMonth() - (this.sliderHighVal - 1)
      );
    else this.startDate = new Date();
    this.localFromDate = this.startDate;

    this.sliderLowVal = changeContext.value !== null ? changeContext.value : 0;
    let endDate = new Date();
    if (this.sliderLowVal != 0 && this.sliderLowVal != 1) {
      endDate.setDate(1);
      endDate.setMonth(endDate.getMonth() - (this.sliderLowVal - 1));
    } else {
      if (this.sliderLowVal == 0) endDate = new Date();
      else if (this.sliderLowVal == 1) endDate.setDate(1);
    }

    this.calenderSlideEvent.emit({
      startDate: this.startDate,
      endDate: endDate,
    });
  }
}
