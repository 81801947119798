<div *ngIf="isDisplay">
    <div class="header-label">
        <p>Challenger Deduct Score vs Client's Average Deduct Score  by Rules </p>
    </div>
    <div *ngIf="isLoading" fxLayoutAlign="center stretch"  class="stats-chart-loading">
        <mat-spinner [diameter]="100" [strokeWidth]="3"></mat-spinner>
    </div>
    <div class="stats-chart-body" *ngIf="!isLoading">
        <canvas baseChart width="400px" height="160px"
            [datasets]="barChartData"
            [labels]="mbarChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [type]="'bar'">
        </canvas>
    </div>
</div>



