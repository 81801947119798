
export function  AddError(ruleItemId: string, error: string) {
    var errors  = [];
    var hasErrors : boolean;
    var _errors = errors;

    if(!_errors.includes(ruleItemId)){
        _errors.push({
            key: ruleItemId,
            value : error
        });
    }
    hasErrors = true;
    return _errors;
}
