import { prop } from "@rxweb/reactive-form-validators";

export class InvoiceStatsModel {

  @prop()
  totalInvoices: number;

  @prop()
  totalAmount: number;

  @prop()
  paidStatus: number;

  @prop()
  sentStatus: number;

  @prop()
  unpaidStatus: number;

  @prop()
  overdueStatus: number;
  
}
