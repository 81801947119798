import { required, prop, maxLength, pattern, minLength } from '@rxweb/reactive-form-validators';

export class SubmitReviewModel {

  @prop()  
  ticketNumber: string;

  @prop()
  @required()
  @maxLength({ value: 120 ,message: 'Maximum 120 characters can be added'})
  review: string;

  @prop()
  rating: number;

}
