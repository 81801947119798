<div class="page-design">
  <form [formGroup]="paymentLinkFormGroup">
    <div class="form-group" formGroupName="designTabForm">
      <div fxLayout="row" fxLayout.xs="column" class="note-text">
        <mat-label fxFlex="12%">Note:</mat-label>
        <mat-label>All Buy Buttons Link Duplicated for new link creation.</mat-label>
      </div>
      <div fxLayout="row" fxLayout.xs="column" class="label-text">
        <mat-label>Type</mat-label>
      </div>
      <mat-radio-group
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-control"
        name="linkType"
        formControlName="linkType"
        (change)="onTypeChanged()"
      >
        <mat-radio-button value="Button">Button</mat-radio-button>
        <mat-radio-button value="Link">Hyper Link</mat-radio-button>
      </mat-radio-group>

      <div class="label-text">
        <mat-label *ngIf="button">Button Text</mat-label>
        <mat-label *ngIf="!button">Link Text</mat-label>
      </div>
      <div fxLayout="column" class="input-box">
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="linkText"
            class="form-control"
            name="linkText"
            autocomplete="off"
          />
          <mat-error>
                {{ paymentLinkFormGroup.controls['designTabForm']['controls']['linkText']["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="label-text">
        <mat-label *ngIf="button">Button Purpose</mat-label>
        <mat-label *ngIf="!button">Link Purpose</mat-label>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        class="textArea-input-box"
      >
        <mat-form-field appearance="outline">
          <textarea
            autosize
            matInput
            name="linkPurpose"
            rows="4"
            formControlName="linkPurpose"
            autocomplete="off"
          >
          </textarea>
          <mat-error>
            {{ paymentLinkFormGroup.controls['designTabForm']['controls']['linkPurpose']["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start center"
      >
        <mat-label fxFlex="10%" class="label-text">Font:</mat-label>
        <mat-form-field fxFlex="30%" appearance="outline">
          <mat-select
            class="form-control"
            formControlName="fontFamily"
            name="fontFamily"
          >
            <mat-option *ngFor="let font of fontFamilyArray" [value]="font.key">
              <mat-label style="font-family: '{{ font.value }}'">{{ font.value }}</mat-label>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="20%" appearance="outline">
          <mat-select
            class="form-control"
            formControlName="fontSize"
            name="fontSize"
          >
            <mat-option
              *ngFor="let fontSize of fontSizeArray"
              [value]="fontSize.key"
            >
              {{ fontSize.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="25%" appearance="outline">
          <mat-select
            class="form-control"
            formControlName="fontStyle"
            name="fontStyle"
          >
            <mat-option
              *ngFor="let fontStyle of fontStyleArray"
              [value]="fontStyle.key"
            >
              {{ fontStyle.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="button">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text"
            >Button Text Color:</mat-label
          >
          <input
            [(colorPicker)]="buttonTextColor"
            name="buttonTextColor"
            (colorPickerChange)="onButtonTextColorChange($event)"
            formControlName="buttonTextColor"                     
            [cpOutputFormat]="'hex'"
            [cpPosition]="'bottom'" 
            [style.color]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonTextColor'].value"           
            [style.background]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonTextColor'].value"
            class="color-picker-box"
          />
          <mat-label class="color-code">{{ this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonTextColor'].value }}</mat-label>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
          class="top-margin"
        >
          <mat-label fxFlex="35%" class="label-text"
            >Background Color:</mat-label
          >
          <input
            [(colorPicker)]="buttonBgColor"
            name="buttonBgColor"
            formControlName="buttonBgColor"
            (colorPickerChange)="onButtonBgColorChange($event)"
            [cpOutputFormat]="'hex'"
            [cpPosition]="'bottom'"
            [style.color]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonBgColor'].value" 
            [style.background]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonBgColor'].value"
            class="color-picker-box"
          />
          <mat-label class="color-code">{{ this.paymentLinkFormGroup.controls['designTabForm']['controls']['buttonBgColor'].value }}</mat-label>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text">Shadow:</mat-label>
          <mat-form-field fxFlex="20%" appearance="outline">
            <mat-select
              class="form-control"
              placeholder="On"
              formControlName="shadow"
              name="shadow"
            >
              <mat-option
                *ngFor="let shadow of shadowType"
                [value]="shadow.key"
              >
                {{ shadow.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text">Border Color:</mat-label>
          <input
            [(colorPicker)]="borderColor"
            name="borderColor"
            formControlName="borderColor"
            (colorPickerChange)="onBorderColorChange($event)"
            [cpOutputFormat]="'hex'"
            [cpPosition]="'bottom'"
            [style.color]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderColor'].value"
            [style.background]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderColor'].value"
            class="color-picker-box"            
          /> 
          <mat-label class="color-code">{{ this.paymentLinkFormGroup.controls['designTabForm']['controls']['borderColor'].value }}</mat-label>         
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text">Radius:</mat-label>
          <mat-form-field fxFlex="20%" appearance="outline">
            <mat-select
              class="form-control"
              formControlName="borderRadius"
              name="borderRadius"
            >
              <mat-option
                *ngFor="let radius of radiusCount"
                [value]="radius.key"
              >
                {{ radius.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="!button">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text"
            >Link Text Color:</mat-label
          >
          <input
            [(colorPicker)]="linkTextColor"
            name="linkTextColor"
            formControlName="linkTextColor"
            (colorPickerChange)="onLinkTextColorChange($event)"
            [cpOutputFormat]="'hex'"
            [cpPosition]="'top'"
            [style.color]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkTextColor'].value"
            [style.background]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkTextColor'].value"
            class="color-picker-box"
          />
          <mat-label class="color-code">{{ this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkTextColor'].value }}</mat-label>         
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
          class="top-margin"
        >
          <mat-label fxFlex="35%" class="label-text"
            >Background Color:</mat-label
          >
          <input
            [(colorPicker)]="linkBgColor"
            name="linkBgColor"
            formControlName="linkBgColor"
            (colorPickerChange)="onLinkBgColorChange($event)"
            [cpOutputFormat]="'hex'"
            [cpPosition]="'top'"
            [style.color]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkBgColor'].value"
            [style.background]="this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkBgColor'].value"
            class="color-picker-box"
          />
          <mat-label class="color-code">{{ this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkBgColor'].value }}</mat-label>         
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start center"
        >
          <mat-label fxFlex="35%" class="label-text">Target:</mat-label>
          <div class="top-margin">
            <mat-radio-group
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
              class="form-control"
              name="target"  
              formControlName="target"            
            >
              <mat-radio-button value="NewWindow">New Window</mat-radio-button>
              <mat-radio-button value="SameWindow">Same Window</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
