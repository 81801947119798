import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import {
  IbvAccountTypeShort,
  IbvAccountTypeFull,
} from 'src/app/featureModules/enums/ibvAccountType';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-instant-verify-report',
  templateUrl: './instant-verify-report.component.html',
  styleUrls: ['./instant-verify-report.component.scss'],
})
export class InstantVerifyReportComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasContainer_Page0_1') canvasContainer: ElementRef;
  currentDate = new Date();
  accountDetails: any;
  isLoading = false;
  customerId: any;
  bankVerificationId: any;
  selectedTab: any;
  ibvPdfReport: any;
  previousUrl: string = '';
  loadMessage: string = '';
  FinZeoGradeAndScoreDetails: any;
  balanceDetails: any;
  employerVerificationDetails: any;
  na: string = 'N/A';

  foundAnyTabToShow: boolean = false;

  printReport_DDA: boolean = false;
  printReport_SDA: boolean = false;
  printReport_CCA: boolean = false;

  showDDAAccountReport: boolean = false;
  showSDAAccountReport: boolean = false;
  showCCAAccountReport: boolean = false;
  showINVAccountReport: boolean = false;
  showBPAAccountReport: boolean = false;

  loadDDAAccountReport: boolean = false;
  loadSDAAccountReport: boolean = false;
  loadCCAAccountReport: boolean = false;
  loadINVAccountReport: boolean = false;
  loadBPAAccountReport: boolean = false;
  backHeaderLabel = '';
  accountStatusDetails: string;
  loanDetails: any;
  insufficientData: boolean;
  flagDataLoading: boolean = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.previousUrl = navigation?.extras?.state
      ? navigation.extras.state['url']
      : '';
    if (this.previousUrl != '')
      localStorage.setItem('previousUrl', this.previousUrl);
    else this.previousUrl = localStorage.getItem('previousUrl');

    this.backHeaderLabel = navigation?.extras?.state
      ? navigation.extras.state['backHeaderLabel']
      : 'Banking / Instant Bank Analysis';
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.generateCanvas();
    }, 2000);
  }

  generateCanvas() {
    var page0_1 = this.canvasContainer.nativeElement;
    let profilecontent = [];
    html2canvas(page0_1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    })
      .then((page0_1) => {
        var p01_imgWidth = 520;
        var p01_imgHeight = (page0_1.height * p01_imgWidth) / page0_1.width;

        profilecontent.push({
          image: page0_1.toDataURL('image/png', 0),
          margin: [0, 0, 0, 0],
          width: p01_imgWidth,
          height: p01_imgHeight,
        });
        profilecontent.push({ text: '\n' });
        this._instantVerifyReportsService.printIbaReport.emit(profilecontent);
      })
      .catch((error) => {
        console.error('Error generating canvas:', error);
      });
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
      this.bankVerificationId = params.get('bankVerificationId');
    });

    this._instantVerifyReportsService.FinzeoGradeCardAndScoreChange.subscribe(
      (data) => {
        if (data) {
          this.FinZeoGradeAndScoreDetails = data;
        } else {
          this.FinZeoGradeAndScoreDetails.score = '0';
          this.FinZeoGradeAndScoreDetails.grade = 'F';
        }
      }
    );

    this._instantVerifyReportsService.BankQuickViewDataChange.subscribe(
      (data) => {
        if (data) {
          this.balanceDetails = data;
        } else {
          this.balanceDetails = null;
        }
      }
    );

    this._instantVerifyReportsService.EmployerVerificationChange.subscribe(
      (data) => {
        if (data) {
          this.employerVerificationDetails = data;
        } else {
          this.employerVerificationDetails = null;
        }
      }
    );

    this._instantVerifyReportsService.FlagAndLoanDataChange.subscribe(
      (data) => {
        if (data) {
          this.loanDetails = data;
          this.insufficientData =
            this.loanDetails?.insufficientHistory === 'No' ? true : false;
          this.flagDataLoading = false;
        } else {
          this.loanDetails = null;
          this.flagDataLoading = false;
        }
      }
    );

    this.loadMessage =
      "<span>Please wait.<br />We're loading transaction(s) of associated account. This might take few seconds.<span>";
    this.isLoading = true;
    this.CheckAndSetAccountFlags();
  }

  getCustomerAccountDetails(accountType: string) {
    this._instantVerifyReportsService
      .GetAccountDetailsForReport(this.bankVerificationId, accountType)
      .subscribe(
        (data) => {
          if (data.data != null) {
            this.accountDetails = data.data;
            this.accountStatusDetails =
              this.accountDetails.accountNameStatus == 'VERIFIED'
                ? 'Verified User'
                : 'Non Verified User';
          }
        },
        (error) => {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return 'NA';
  }

  getStatusStyle(status: string) {
    return status?.toUpperCase() == 'VERIFIED'
      ? { 'background-color': 'rgb(90 235 97 / 38%);' }
      : { 'background-color': '#FCBA66;' };
  }

  getImageSrc(status: string) {
    return status?.toUpperCase() == 'VERIFIED'
      ? 'assets/ibv-report-icons/Valid.png'
      : 'assets/ibv-report-icons/Invalid.png';
  }

  navigateToBackPage() {
    this.router.navigateByUrl(this.previousUrl);
  }

  CheckAndSetAccountFlags() {
    this._instantVerifyReportsService
      .GetVerifiedAccountTypesForReport(this.bankVerificationId)
      .subscribe(
        (response) => {
          if (response.code == 200) {
            response.data.forEach((account) => {
              switch (account) {
                case IbvAccountTypeShort.DDA:
                  this.showDDAAccountReport = true;
                  this.foundAnyTabToShow = true;
                  break;
                case IbvAccountTypeShort.SDA:
                  this.showSDAAccountReport = true;
                  this.foundAnyTabToShow = true;
                  break;
                case IbvAccountTypeShort.CCA:
                  // this.showCCAAccountReport = true;
                  // this.foundAnyTabToShow = true;
                  break;
              }
            });
            if (!this.foundAnyTabToShow) {
              this._toastrService.error(
                'IBA account(s) information not available for this request. Please try again by creating a new request.'
              );
              this.router.navigateByUrl(this.previousUrl);
            }
          } else if (
            response.code == 404 &&
            response.errorMessage.includes(
              'IBA request is not fully verified for this customer'
            )
          ) {
            this._toastrService.info(response.errorMessage);
            this.router.navigateByUrl(this.previousUrl);
          } else if (response.errorMessage != null) {
            this._toastrService.error(response.errorMessage);
            this.router.navigateByUrl(this.previousUrl);
          }
          this.loadMessage = '';
          this.isLoading = false;
        },
        (error) => {
          this.loadMessage = '';
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onTabChange(event: MatTabChangeEvent) {
    switch (event.tab.textLabel) {
      case IbvAccountTypeFull.DDA:
        this.loadDDAAccountReport = true;
        this.getCustomerAccountDetails(IbvAccountTypeShort.DDA);
        break;
      case IbvAccountTypeFull.SDA:
        this.loadSDAAccountReport = true;
        this.getCustomerAccountDetails(IbvAccountTypeShort.SDA);
        break;
      case IbvAccountTypeFull.CCA:
        this.loadCCAAccountReport = true;
        this.getCustomerAccountDetails(IbvAccountTypeShort.CCA);
        break;
    }

    this.selectedTab = event.tab.textLabel;
  }

  getTooltipText() {
    return `A: 850 - 1000
    B: 710 - 850
    C: 550 - 710
    D: 410 - 550
    F: 0 - 410 `;
  }

  printClicked() {
    switch (this.selectedTab) {
      case IbvAccountTypeFull.DDA:
        this.printReport_DDA = true;
        break;
      case IbvAccountTypeFull.SDA:
        this.printReport_SDA = true;
        break;
      case IbvAccountTypeFull.CCA:
        this.printReport_CCA = true;
        break;
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
}
