<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>
<form [formGroup]="sendInvoiceFormGroup">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="90%">
      <h1>Send Invoice</h1>
    </div>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="35%">
      <mat-form-field appearance="outline">
        <mat-label>Schedule Date</mat-label>
        <input
          class="form-control"
          matInput
          [matDatepicker]="picker"
          formControlName="scheduleDate"
          [min]="minDate"
          [max]="maxDate"
          readonly
          [matDatepickerFilter]="holidayFilter"
          placholder="MM/DD/YYYY"
          (dateChange)="dueDateCalc($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker
          #picker
          [startAt]="defaultScheduledDate"
          [dateClass]="dateClass"
        ></mat-datepicker>
        <mat-error>
          {{ sendInvoiceFormGroup.controls["scheduleDate"]["_errorMessage"] }}
        </mat-error>
      </mat-form-field>
      <div class="dueDate-color">
        Due Date : {{ sendInvoiceModel.invoiceDueDate | date : "MM/dd/yyyy" }}
      </div>
    </div>
  </div>

  <div mat-dialog-content>
    <h4>Send By:</h4>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="5%"
      fxLayoutAlign="start stretch"
    >
      <!-- <mat-radio-group fxFlex="50%" class="form-control" name="sendBy" (change)="onSendByChanged($event)">
          <mat-radio-button *ngFor="let type of sendMedium" [value]="type.value" [checked]="type.checked">
            {{ type.name }}
          </mat-radio-button>
          <mat-radio-button [value]="1" [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 1">By Email</mat-radio-button>
          <mat-radio-button [value]="2" [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 2">By SMS</mat-radio-button>
          <mat-radio-button [value]="3" [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 3">By Both</mat-radio-button>
        </mat-radio-group> -->
      <mat-radio-group
        fxLayoutWrap
        fxLayoutGap="7%"
        fxLayoutAlign="start center"
        class="form-control"
        name="sendBy"
        (change)="onSendByChanged($event)"
      >
        <mat-radio-button
          value="Email"
          [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 'Email'"
          >Email</mat-radio-button
        >
        <mat-radio-button
          value="SMS"
          [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 'SMS'"
          >SMS</mat-radio-button
        >
        <mat-radio-button
          value="Both"
          [checked]="sendInvoiceFormGroup.controls['sendBy'].value == 'Both'"
          >Both</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>

  <mat-card class="top-space">
    <app-send-by-email
      (EmailListEvent)="getEmailList($event)"
      *ngIf="loadEmailComponent"
    ></app-send-by-email>
    <!-- </mat-card> -->

    <!-- <mat-card> -->
    <app-send-by-sms
      (MobileNoListEvent)="getMobileNoList($event)"
      *ngIf="loadSmsComponent"
    ></app-send-by-sms>
  </mat-card>

  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end"
    class="action-button-padding"
  >
    <button mat-stroked-button color="primary" [mat-dialog-close]>
      Cancel
    </button>

    <button mat-raised-button color="primary" (click)="sendInvoice()">
      SEND
    </button>
  </div>
</form>
