import { pattern, prop, required } from "@rxweb/reactive-form-validators";


export class EmailSetupDetailsModel {

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    subject:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    mailContent:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    header:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    footer:string ;

    @prop()
    companyId:string;
}