import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { AddCustomerBankDetailsModel } from 'src/app/sharedModules/models/add-customer-bank-details.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { ToastrService } from 'ngx-toastr';
import { AddCustomerCardDetailsModel } from 'src/app/sharedModules/models/add-customer-card-details.model';
import { CustomerCardDetailsService } from 'src/app/featureModules/services/customer-card-details.service';
import { ConfigurationOptions, OutputOptionsEnum } from 'intl-input-phone';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { findInvalidControls } from 'src/app/sharedModules/utils/dev-utils';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  Observable,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SearchBankService } from 'src/app/blockModules/services/search-bank.service';
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from 'credit-card-type';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false },
    },
  ],
})
export class AddCustomerComponent implements OnInit {
  states: any;
  accountType = [
    { value: 'saving', display: 'Saving' },
    { value: 'checking', display: 'Checking' },
  ];

  
  basicDetailsFormGroup: FormGroup;
  bankDetailsFormGroup: FormGroup;
  bankDetailsModel: AddCustomerBankDetailsModel;
  basicDetailsModel: AddCustomerModel;
  cardDetailsFormGroup: FormGroup;
  cardDetailsModel: AddCustomerCardDetailsModel;
  minDate = new Date(1900, 0, 1);
  maxDate = new Date();
  error: string;
  isCompany: boolean = false;
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';
  bankList: any;
  debitCreditCardsList: any;
  customerId: string;
  customerModel: AddCustomerModel;
  isAddCustomer: boolean = true;
  bankAndCardId: string;
  isAddCustomerCard: boolean = true;
  isAddCustomerBank: boolean = true;
  configOption: ConfigurationOptions;
  ipListEdited : boolean =false ;
  yearList = [];
  monthList = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];

  @ViewChild('stepper') stepper: MatStepper;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneNumber;
  maxLength: string = '15';
  isBankReset: boolean = true;
  isCardReset: boolean = true;
  oldNumber: any;
  phonehint: any = '(201) 555-0123';
  minLengthTerm = 3;
  bankNameList: any[] = [];
  cardImageName: any = 'assets/grid-icons/default-card.png';
  isSearchLoading: boolean = false;
  isShowNoFound: boolean = false;
  isLinearStepper: boolean = true;
  invoiceId: string ='0';

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private _customerService: CustomerService,
    private _commonService: CommonService,
    private _customerBankDetailsService: CustomerBankDetailsService,
    private _toastrService: ToastrService,
    private _customerCardDetailsService: CustomerCardDetailsService,
    private activatedRoute: ActivatedRoute,
    private searchBankService: SearchBankService,
    private dialog: MatDialog,private _invoiceService:InvoiceService
  ) {
    this.configOption = new ConfigurationOptions();
    this.configOption.OutputFormat = OutputOptionsEnum.Number;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
      this.bankAndCardId = params.get('cardId');
      this.invoiceId =params.get('invoiceId');
      if (params.get('step')) {
        this.isLinearStepper = false;
        setTimeout(() => {
          this.stepper.selectedIndex = parseInt(params.get('step'));
        });
      }
    });

    localStorage.removeItem('customerId');
    this.loadData();
    
    this.basicDetailsModel = new AddCustomerModel();    
    this.basicDetailsModel.ipAddressList = [];    
    this.basicDetailsFormGroup = this.formBuilder.formGroup(
      this.basicDetailsModel
    );
    this.bankDetailsModel = new AddCustomerBankDetailsModel();
    this.bankDetailsFormGroup = this.formBuilder.formGroup(
      this.bankDetailsModel
    );
    this.cardDetailsModel = new AddCustomerCardDetailsModel();
    this.cardDetailsFormGroup = this.formBuilder.formGroup(
      this.cardDetailsModel
    );
    this.getYearList();
    this.bankDetailsFormGroup.valueChanges.subscribe((x) => {
      if (x.bankName || x.bankAccountNumber || x.accountType || x.routingNumber)
        this.isBankReset = false;
    });
    this.cardDetailsFormGroup.valueChanges.subscribe((x) => {
      if (
        x.nameOnCard ||
        x.cardNumber ||
        x.expMonth ||
        x.expYear ||
        x.address ||
        x.city ||
        x.state ||
        x.zip
      )
        this.isCardReset = false;
    });

    // Bank Name autocomplete
    this.isShowNoFound = false;
    this.bankDetailsFormGroup
      .get('bankName')
      .valueChanges.pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return res !== 'N/A' && res !== null && res.length >= this.minLengthTerm;
          } else {
            this.bankNameList = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.bankNameList = [];
          this.isSearchLoading = true;
        }),
        switchMap((value) =>
          this.searchBankService.SearchByBankName(value).pipe(
            finalize(() => {
              this.isSearchLoading = false;
            })
          )
        )
      )
      .subscribe(
        (data: any) => {
          if (data.data == undefined) {
            this.isSearchLoading = false;
            this.isShowNoFound = true;
            this.bankNameList = [];
          } else {
            this.isShowNoFound = false;
            this.bankNameList = data.data;
          }
        },
        (error) => {
          this.isSearchLoading = false;
        }
      );
  }

  clearSelection() {
    this.bankNameList = [];
    this.bankDetailsFormGroup.get('bankName').setValue(null);
  }

  onZipFocusOut(event: any) {
    let zipCode = event.target.value.toUpperCase();
    this.basicDetailsFormGroup.controls['zip'].setValue(zipCode);
  }

  onCardZipFocusOut(event: any) {
    let cardZipCode = event.target.value.toUpperCase();
    this.cardDetailsFormGroup.controls['zip'].setValue(cardZipCode);
  }

  addCustomerBasicDetails(stepper: MatStepper) {
    this.isLoading = true;
    findInvalidControls(this.basicDetailsFormGroup);
    if (this.isAddCustomer) {
      this.basicDetailsFormGroup.controls['isCompany'].setValue(this.isCompany);
      if (this.basicDetailsFormGroup.controls['phone'].value) {
        this.basicDetailsFormGroup.controls['mobileNumber'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.e164Number
        );
        this.basicDetailsFormGroup.controls['isoCode'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.countryCode
        );
      }

      if (this.basicDetailsFormGroup.valid) {
        let customerData: AddCustomerModel = this.basicDetailsFormGroup.value;

        this._customerService.AddCustomerBasicDetails(customerData).subscribe(
          (data) => {
            if (data.code == 201 && data.data) {
              this.customerModel = data.data;
              this.customerModel.customerId = data.data.id;
              this._invoiceService.cName = data.data.firstName +" "+data.data.lastName;     
              this._invoiceService.cId =   data.data.id;
              localStorage.setItem('customerId', data.data.id);
              this.customerId = data.data.id;
              this.getCustomerById();
              this.bankList = [];
              this.debitCreditCardsList = [];
              this._toastrService.success('Customer added successfully!');
              this.isLoading = false;

              this.basicDetailsFormGroup.markAsPristine();
              this.basicDetailsFormGroup.markAsUntouched();
              stepper.next();
            } else if (data.code == 400) {
              this.isLoading = false;
              this._toastrService.error(data.errorMessage);
            } else {
              this.isLoading = false;
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.warning(error.error.content);
            }
            this.isLoading = false;
          }
        );
      } else {
        this.basicDetailsFormGroup.markAllAsTouched();
        this._toastrService.error('Please fill all the required details!');
        this.isLoading = false;
      }
    } else {
      if (this.basicDetailsFormGroup.controls['phone'].value) {
        this.basicDetailsFormGroup.controls['mobileNumber'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.e164Number
        );
        this.basicDetailsFormGroup.controls['isoCode'].setValue(
          this.basicDetailsFormGroup.controls['phone'].value.countryCode
        );
      }
      if (
        (this.basicDetailsFormGroup.touched || this.ipListEdited) &&
        this.basicDetailsFormGroup.dirty
      ) {
        if (this.basicDetailsFormGroup.valid) {
          if (this.basicDetailsFormGroup.controls['type'].value) {
            this.basicDetailsFormGroup.controls['lastName'].setValue('');
          }
          let customerData: AddCustomerModel = this.basicDetailsFormGroup.value;

          this._customerService
            .EditCustomerBasicDetails(this.customerId, customerData)
            .subscribe(
              (data) => {
                if (data.code == 200 && data.data) {
                  this.customerModel = data.data;
                  this.getCustomerById();
                  this.getAllBanks(this.customerId);
                  this.getAllCards(this.customerId, false);
                  this.isLoading = false;
                  this._toastrService.success('Customer updated successfully!');

                  this.basicDetailsFormGroup.markAsPristine();
                  this.basicDetailsFormGroup.markAsUntouched();
                  stepper.next();
                } else {
                  this.isLoading = false;
                  this._toastrService.error(data.errorMessage);
                }
              },
              (error) => {
                this.isLoading = false;
              }
            );
        } else {
          this.basicDetailsFormGroup.markAllAsTouched();
          this._toastrService.error('Please fill all the required details!');
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
        stepper.next();
      }
    }
  }

  navigateToCustomersPage() {
    if(!this.invoiceId)
    {
      this.router.navigate(['customers']);
    }else if(this.invoiceId == '1'){      
      this.router.navigate(['invoices/generate-invoice',1]);
    }
  }

  submitCustomer() {   
    if (this.isAddCustomer) {
      this._toastrService.success('Customer created successfully!');
    } else {
      this._toastrService.success('Customer updated successfully!');
    }
    if(!this.invoiceId)
    {
      this.router.navigate(['customers']);
    }
    else if(this.invoiceId == '1'){      
      this._commonService.navigationEvent.emit('invoices');
      this.router.navigate(['invoices/generate-invoice',1]);
    }
    
  }

  loadData() {
    if (this.customerId) {
      this.getCustomerById();
      this.getAllBanks(this.customerId);
      this.getAllCards(this.customerId, false);
    }

    // if (
    //   this._commonService._constantData == undefined ||
    //   this._commonService._constantData == null
    // ) {
    //   this._commonService.getJSON('constants');
    // }

    this._customerService.getStates().subscribe(
      (response) => {
        this.states = response['USA'];
      }); 
  }

  onTypeSelected(event: any) {
    if (event.value) {
      this.isCompany = true;
    } else {
      this.isCompany = false;
    }
  }

  changePhone(event: any) {
    if (
      event.iso2.toLowerCase() == this.customerModel?.isoCode?.toLowerCase()
    ) {
      this.basicDetailsFormGroup.controls['phone'].setValue(
        this.customerModel.mobileNumber
      );
    } else {
      this.basicDetailsFormGroup.controls['phone'].setValue('');
    }
    this.phonehint = event.placeHolder;
  }

  getCustomerById() {
    this.isLoading = true;
    this.separateDialCode = true;
    this._customerService.getCustomerById(this.customerId).subscribe((data) => {
      this.isLoading = false;
      if (data.data != null) {
        this.separateDialCode = false;
        this.isAddCustomer = false;
        this.customerModel = data.data;        
        this.customerModel.ipAddressList = this.getIpAddressList(data.data.ipAddressList);
        this.basicDetailsFormGroup.patchValue(this.customerModel);
        this.phoneNumber = this.customerModel.mobileNumber;
        this.basicDetailsFormGroup.controls['phone'].setValue(this.phoneNumber);
        this.phonehint = null;
        this.isLinearStepper = false;
        this.basicDetailsFormGroup.markAsPristine();
        this.basicDetailsFormGroup.markAsUntouched();

        this.customerModel.type
          ? (this.isCompany = true)
          : (this.isCompany = false);
      }
    }),
      (error) => {
        this.isLoading = false;
      };
  }

  getIpAddressList(data:any){
    if(data && typeof data == "string")
    { return data?.split(',') }
    else if (!data){
      return [];
    }else{
      return data;
    }
  }

  addCustomerBankDetails() {
    this.isLoading = true;

    // selected bank name
    if (this.bankNameList?.length) {
      let selectedBank = this.bankNameList.filter(
        (obj) => obj === this.bankDetailsFormGroup.get('bankName').value
      );
      if (!selectedBank?.length) {
        this.bankNameList = [];
        this.bankDetailsFormGroup.get('bankName').setValue(null);
      }
    } else {
      this.bankNameList = [];
      this.bankDetailsFormGroup.get('bankName').setValue(null);
    }

    if (this.isAddCustomerBank) {
      this.bankDetailsFormGroup.controls['customerId'].setValue(
        this.customerId
      );
      if (this.bankDetailsFormGroup.valid) {
        this._customerBankDetailsService
          .AddCustomerBankDetails(this.bankDetailsFormGroup.value)
          .subscribe(
            (data) => {
              if (data.code == 201) {
                this.bankDetailsFormGroup.reset();
                this.bankDetailsFormGroup.markAsPristine();
                this.bankDetailsFormGroup.markAsUntouched();
                this.bankNameList = [];
                this._toastrService.success('Bank Details added successfully!');
                this.getAllBanks(this.customerId);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    } else {
      if (this.bankDetailsFormGroup.valid) {
        this._customerBankDetailsService
          .updateCustomerBank(
            this.bankDetailsFormGroup.value.customerBankAccountId,
            this.bankDetailsFormGroup.value
          )
          .subscribe(
            (data) => {
              if (data.code == 200) {
                this._toastrService.success(
                  'Bank details updated successfully!'
                );
                this.bankAndCardId = null;
                this.resetBankForm();
                this.getAllBanks(this.customerId);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    }
  }

  getAllBanks(cutotmerId: any) {
    this.isLoading = true;
    this._customerBankDetailsService
      .GetAllBankAccounts(cutotmerId, false)
      .subscribe(
        (data) => {
          this.bankList = data.data.listOfBankAccountsToReturn;
          this.isLoading = false;
          if (this.bankAndCardId) {
            let selectedBank = this.bankList.filter(
              (obj: any) => obj.customerBankAccountId == this.bankAndCardId
            );
            if (selectedBank && selectedBank[0]) {
              this.isAddCustomerBank = false;
              this.bankDetailsFormGroup.patchValue(selectedBank[0]);
              this.bankDetailsModel = selectedBank[0];
              this.bankDetailsFormGroup.markAsPristine();
              this.bankDetailsFormGroup.markAsUntouched();
            }
          }
        },
        (error) => {
          if (error.error.message != null || error.error.message != undefined) {
            this._toastrService.warning(error.error.message);
          } else {
            this._toastrService.warning(error.error.content);
          }
          this.isLoading = false;
        }
      );
  }

  editBankDetails(bank: any) {
    if (bank) {
      this.isLoading = true;
      this.bankAndCardId = bank.customerBankAccountId;
      this.stepper.selectedIndex = 1;
      this.isAddCustomerBank = false;
      this.bankDetailsFormGroup.patchValue(bank);
      this.bankNameList = [this.bankDetailsFormGroup.get('bankName').value];
      this.bankDetailsModel = bank;
      this.bankDetailsFormGroup.markAsPristine();
      this.bankDetailsFormGroup.markAsUntouched();
      this.isLoading = false;
    }
  }

  resetBankForm() {
    this.bankNameList = [];
    this.bankAndCardId = null;
    this.isBankReset = true;
    this.isAddCustomerBank = true;
    this.bankDetailsFormGroup.reset();
    this.bankDetailsModel = new AddCustomerBankDetailsModel();
    Object.keys(this.bankDetailsFormGroup.controls).forEach((key) => {
      const control = this.bankDetailsFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  addCustomerCardDetails() {
    this.isLoading = true;
    let expiryMonth = this.cardDetailsFormGroup.controls['expMonth'].value;
    let expiryYear = this.cardDetailsFormGroup.controls['expYear'].value;
    let currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();
    if (parseInt(expiryMonth) < currentMonth && currentYear == expiryYear) {
      this._toastrService.warning('Invalid Exp Month and Exp Year selected.');
      this.isLoading = false;
      return;
    }
    if (this.isAddCustomerCard) {
      this.cardDetailsFormGroup.controls['customerId'].setValue(
        this.customerId
      );
      if (this.cardDetailsFormGroup.valid) {
        this._customerCardDetailsService
          .AddCustomerCardDetails(this.cardDetailsFormGroup.value)
          .subscribe(
            (data) => {
              if (data.code == 201) {
                this.cardDetailsFormGroup.reset();
                this.cardDetailsFormGroup.markAsPristine();
                this.cardDetailsFormGroup.markAsUntouched();
                this.cardImageName = 'assets/grid-icons/default-card.png';
                this._toastrService.success('Card details added successfully!');
                this.getAllCards(this.customerId, false);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              if (
                error.error.message != null ||
                error.error.message != undefined
              ) {
                this._toastrService.warning(error.error.message);
              } else {
                this._toastrService.warning(error.error.content);
              }
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    } else {
      if (this.cardDetailsFormGroup.valid) {
        this._customerCardDetailsService
          .updateCustomerCard(
            this.cardDetailsFormGroup.value.customerCardId,
            this.cardDetailsFormGroup.value
          )
          .subscribe(
            (data) => {
              if (data.code == 200) {
                this.bankAndCardId = null;
                this.resetCardForm();
                this.cardImageName = 'assets/grid-icons/default-card.png';
                this._toastrService.success(
                  'Card details updated successfully!'
                );
                this.getAllCards(this.customerId, false);
                this.isLoading = false;
              } else {
                this.isLoading = false;
                this._toastrService.warning(data.errorMessage);
              }
            },
            (error) => {
              this.isLoading = false;
            }
          );
      } else {
        this._toastrService.warning('Please fill all the required details');
        this.isLoading = false;
      }
    }
  }

  getAllCards(cutotmerId: any, onlyActive: boolean) {
    this.isLoading = true;
    this._customerCardDetailsService
      .GetAllCards(cutotmerId, onlyActive)
      .subscribe(
        (data) => {
          this.debitCreditCardsList = data.data;
          this.isLoading = false;
          if (this.bankAndCardId) {
            let selectedCard = this.debitCreditCardsList.filter(
              (obj: any) => obj.customerCardId == this.bankAndCardId
            );
            if (selectedCard && selectedCard[0]) {
              this.isAddCustomerCard = false;
              this.cardDetailsFormGroup.patchValue(selectedCard[0]);
              selectedCard[0].expMonth = selectedCard[0].expMonth.toString();
              if (selectedCard[0].expMonth.length < 2) {
                selectedCard[0].expMonth = '0' + selectedCard[0].expMonth;
              }      
             this.cardDetailsFormGroup.controls['expMonth'].setValue(selectedCard[0].expMonth);
              this.getCardImage();
              this.cardDetailsModel = selectedCard[0];
              this.cardDetailsFormGroup.markAsPristine();
              this.cardDetailsFormGroup.markAsUntouched();
            }
          }
        },
        (error) => {
          if (error.error.message != null || error.error.message != undefined) {
            this._toastrService.warning(error.error.message);
          } else {
            this._toastrService.warning(error.error.content);
          }
          this.isLoading = false;
        }
      );
  }

  editCardDetails(card: any) {
    if (card) {
      this.isLoading = true;
      this.bankAndCardId = card.customerCardId;
      this.stepper.selectedIndex = 2;
      this.isAddCustomerCard = false;
      this.cardDetailsFormGroup.patchValue(card);
      card.expMonth = card.expMonth.toString();
      if (card.expMonth.length < 2) {
        card.expMonth = '0' + card.expMonth;
      }
      let expiryDayYear = new Date();
      if (card.expYear.toString().length == 2 ) {
        card.expYear = parseInt(expiryDayYear.getFullYear().toString().substring(0,2) + card.expYear);
      }
      this.cardDetailsFormGroup.controls['expMonth'].setValue(card.expMonth);
      this.cardDetailsFormGroup.controls['expYear'].setValue(parseInt(card.expYear));
      this.getCardImage();
      this.cardDetailsModel = card;
      this.cardDetailsFormGroup.markAsPristine();
      this.cardDetailsFormGroup.markAsUntouched();
      this.isLoading = false;
    }
  }

  addNewIps() {
    this.basicDetailsModel.allowedIpAddress = this.basicDetailsFormGroup.controls['allowedIpAddress'].value;


    if (this.basicDetailsFormGroup.controls['allowedIpAddress'].valid && !this.basicDetailsModel.allowedIpAddress) 
    {
      this._toastrService.warning('Please enter valid IP Address !');
      this.basicDetailsFormGroup.controls['allowedIpAddress'].setValue('');
      this.basicDetailsModel.allowedIpAddress = '';
      return false;
    }
    
    if (!this.basicDetailsFormGroup.controls['allowedIpAddress'].valid)
    {      
      this._toastrService.warning('Please enter valid IP Address !');
      this.basicDetailsFormGroup.controls['allowedIpAddress'].setValue('');
      this.basicDetailsModel.allowedIpAddress = '';
      return false;
    }
        
    if (this.basicDetailsModel.ipAddressList.indexOf(this.basicDetailsModel.allowedIpAddress) < 0) 
    {
      if (this.basicDetailsModel.ipAddressList.length > 9) 
      {
        this._toastrService.warning('Only 10 IP Addresses are allowed !');
        return;
      }
      this.basicDetailsModel.ipAddressList.push(this.basicDetailsModel.allowedIpAddress);
      this.basicDetailsFormGroup.controls['allowedIpAddress'].setValue('');
      this.basicDetailsModel.allowedIpAddress = '';
    } 
    else 
    {
      this._toastrService.warning('IP Address already exists in the list !');
      this.basicDetailsFormGroup.controls['allowedIpAddress'].setValue('');
      this.basicDetailsModel.allowedIpAddress = '';
      return false;
    }   
  }

  removeNewIps(index: number): void {
    this.basicDetailsModel.ipAddressList.splice(index, 1);
    this.ipListEdited = true ;
  }

  resetCardForm() {
    this.cardImageName = 'assets/grid-icons/default-card.png';
    this.bankAndCardId = null;
    this.isCardReset = true;
    this.cardDetailsFormGroup.reset();
    this.cardDetailsModel = new AddCustomerCardDetailsModel();
    this.isAddCustomerCard = true;
    Object.keys(this.cardDetailsFormGroup.controls).forEach((key) => {
      const control = this.cardDetailsFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  saveAndNext(stepper: MatStepper) {
    stepper.next();
  }

  previousStep(stepper: MatStepper) {
    stepper.previous();
  }

  editCustomerDetailsSummary() {
    this.stepper.selectedIndex = 0;
  }

  editCardDetailsSummary() {
    this.stepper.selectedIndex = 2;
  }

  editBankDetailsSummary() {
    this.stepper.selectedIndex = 1;
  }

  getYearList() {
    var currentYear = new Date().getFullYear();
    for (var i = 0; i < 30; i++) {
      this.yearList.push(currentYear + i);
    }
  }

  getCardImage() {
    this.cardImageName = 'assets/grid-icons/default-card.png';
    if (this.cardDetailsFormGroup.controls['cardNumber'].value) {
      var visaCards = creditCardType(
        this.cardDetailsFormGroup.controls['cardNumber'].value
      );
      if (visaCards && visaCards[0] && visaCards[0].type)
        this.cardImageName = 'assets/grid-icons/' + visaCards[0].type + '.png';
    }
    return this.cardImageName;
  }

  stepperChange(event: any) {
    switch (event.selectedIndex) {
      case 0:
        if (this.checkingValidation(event.previouslySelectedIndex))
          this.stepperChangeConfirmation(event.previouslySelectedIndex);
        break;
      case 1:
        if (this.checkingValidation(event.previouslySelectedIndex))
          this.stepperChangeConfirmation(event.previouslySelectedIndex);
        break;
      case 2:
        if (this.checkingValidation(event.previouslySelectedIndex))
          this.stepperChangeConfirmation(event.previouslySelectedIndex);
        break;
      case 3:
        if (this.checkingValidation(event.previouslySelectedIndex))
          this.stepperChangeConfirmation(event.previouslySelectedIndex);
    }
  }

  checkingValidation(previousIndex: number) {
    let flag = false;
    switch (previousIndex) {
      case 0:
        flag =
          this.basicDetailsFormGroup.touched &&
          this.basicDetailsFormGroup.dirty;
        break;
      case 1:
        flag =
          this.bankDetailsFormGroup.touched && this.bankDetailsFormGroup.dirty;
        break;
      case 2:
        flag =
          this.cardDetailsFormGroup.touched && this.cardDetailsFormGroup.dirty;
        break;
    }
    return flag;
  }

  stepperChangeConfirmation(previousIndex: number) {
    let stepLabel =
      previousIndex == 0
        ? 'Basic Details'
        : previousIndex == 1
        ? 'Bank Details'
        : previousIndex == 2
        ? 'Card Details'
        : '';

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        buttonName: 'YES',
        buttonCancel: 'NO',
        successMessage: "You have unsaved changes on '" + stepLabel + "' page.",
        subSuccessMessage:
          "Click 'Yes' to discard changes or 'No' to go back to '" +
          stepLabel +
          "' page.",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!confirmed) {
        this.stepper.selectedIndex = previousIndex;
      } else {
        switch (previousIndex) {
          case 0:
            this.basicDetailsFormGroup.patchValue(this.customerModel);
            this.basicDetailsFormGroup.controls['phone'].setValue(
              this.phoneNumber
            );
            this.basicDetailsFormGroup.markAsPristine();
            this.basicDetailsFormGroup.markAsUntouched();
            break;
          case 1:
            if (this.isAddCustomerBank)
              this.bankDetailsModel = new AddCustomerBankDetailsModel();
            this.bankDetailsFormGroup.reset();
            this.bankDetailsFormGroup.patchValue(this.bankDetailsModel);
            this.bankDetailsFormGroup.markAsPristine();
            this.bankDetailsFormGroup.markAsUntouched();
            break;
          case 2:
            if (this.isAddCustomerCard)
              this.cardDetailsModel = new AddCustomerCardDetailsModel();
            this.cardDetailsFormGroup.reset();
            this.cardDetailsFormGroup.patchValue(this.cardDetailsModel);
            this.cardDetailsFormGroup.markAsPristine();
            this.cardDetailsFormGroup.markAsUntouched();
            break;
        }
      }
    });
  }
}
