<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="100%">
    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [allColumns]="allColumns"
      [isExport]="false"
      [isFilter]="false"
      [allColumns]="allColumns"
      [isSearch]="false"
      (pageChangedEvent)="onPageChanged($event)"
      (sortChangedEvent)="onSortChanged($event)"
      [isTableHeading]="true"
      [isTableHeadingText]="'Total Amount and Total Transactions'"
      [showPaginator]="false"
    >
    </app-data-table>
  </div>
</div>
