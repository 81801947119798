import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { HostedPaymentTabModel } from 'src/app/sharedModules/models/hosted-payment-tab.model';
import {
  paymentOptionTypes,
  ACH,
  Card,
} from 'src/app/sharedModules/models/hosted-payment-send.model';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
  @Input()
  hostedPaymentpageFormGroup: FormGroup;

  @Input()
  finzeoPaymentOption: string;
  /*  hostedPaymentpageFormGroup: FormGroup; */
  paymentOptionTypes: paymentOptionTypes;
  optionsData: any;
  hostedPaymentTabModel = new HostedPaymentTabModel();
  emailList: string[];
  mobileList: string[];
  email: string;
  mobileNo: string;
  isAvailRecuringAch: boolean = false;
  isAvailRecuringCard: boolean = false;
  isPaymentOptionAch: boolean = false;
  isPaymentOptionCard: boolean = false;
  availConvenienceFee: string;
  convenienceFeeAmount: number;
  isConvenienceAllowed: boolean;
  timePick: boolean = false;
  isNotificationEmail: boolean = true;
  isNotificationSMS: boolean = true;
  isNotificationToOwnerEmail: boolean = true;
  isNotificationToOwnerSMS: boolean = true;
  @ViewChild('myCheckbox') private myCheckbox: MatCheckbox;

  isDisplayShippingDetail: boolean = false; //TODO: need to be removed

  constructor(
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService
  ) {}

  onExpirationChanged(event: any) {
    if (event.value == 'Specific') {
      this.timePick = true;
    }
  }

  newEmailList: string;

  @Input()
  RegEmailId: string;

  @Output()
  EmailListEvent = new EventEmitter();

  ngOnInit(): void {
    this.paymentOptionTypes = new paymentOptionTypes();
    this.bindPaymentOptions();
    this.paymentOptionTypes =
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionTypes'].value;
    this.onConvenienceFeeChangeEvent();
  }
  addNewEmails() {
    this.email =
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].value;

    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].valid &&
      !this.email
    ) {
      this._toastrService.warning('Please enter valid additional email id !');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].setValue('');
      this.email = '';
      return false;
    }

    if (
      !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].valid
    ) {
      //this._toastrService.warning('Please enter valid email id !');
      //this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['email'].setValue('');
      return false;
    }

    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['emailList'].value.indexOf(this.email) < 0
    ) {
      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['emailList'].value.length > 4
      ) {
        this._toastrService.warning('Only 5 additional emails are allowed !');
        return;
      }
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['emailList'].value.push(this.email);
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].setValue('');
      this.email = '';
    } else {
      this._toastrService.warning('Email id already exists in the list !');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['email'].setValue('');
      this.email = '';
      return false;
    }
  }

  removeNewEmails(index: number): void {
    this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
      'controls'
    ]['emailList'].value.splice(index, 1);
  }

  @Input()
  RegMobileNo: string;

  @Output()
  MobileNoListEvent = new EventEmitter();

  additonalNumber: string;

  addNewMobileNos() {
    this.mobileNo =
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].value;

    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].valid &&
      !this.mobileNo
    ) {
      this._toastrService.warning('Please enter additional mobile number !');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].setValue('');
      this.mobileNo = '';
      return false;
    }
    if (
      !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].valid
    ) {
      // this._toastrService.warning('Please enter valid mobile number !');
      return false;
    }

    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileList'].value.indexOf(this.mobileNo) < 0
    ) {
      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['mobileList'].value.length > 4
      ) {
        this._toastrService.warning(
          'Only 5 additional mobile numbers are allowed !'
        );
        return;
      }
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileList'].value.push(this.mobileNo);
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].setValue('');
      this.mobileNo = '';
    } else {
      this._toastrService.warning('Mobile number already exists in the list !');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['mobileNo'].setValue('');
      this.mobileNo = '';
      return false;
    }
    this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
      'controls'
    ]['mobileNo'].setValue('');
    this.mobileNo = ' ';
  }

  removeNewMobileNos(index: number): void {
    this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
      'controls'
    ]['mobileList'].value.splice(index, 1);
  }

  onPaymentOptionAchChange(event: any) {
    if (event.checked) {
      this.isAvailRecuringAch = true;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionAch'].setValue(true);
      //this.setDataOnPaymentOptionChange("ACH", event.checked);
    } else {
      if (
        !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['paymentOptionCard'].value
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['paymentOptionCard'].setValue(true);
        this.isAvailRecuringCard = true;
      }
      this.isAvailRecuringAch = false;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionAch'].setValue(false);
      // this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availConvenienceFee'].setValue("No");
      // this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].setValue("0.0");
      //this.setDataOnPaymentOptionChange("ACH", event.checked);
    }
  }

  onPaymentOptionCardChange(event: any) {
    if (event.checked) {
      //this.setDataOnPaymentOptionChange("Card", event.checked);
      this.isAvailRecuringCard = true;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionCard'].setValue(true);
    } else {
      //this.setDataOnPaymentOptionChange("Card", event.checked);
      if (
        !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['paymentOptionAch'].value
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['paymentOptionAch'].setValue(true);
        this.isAvailRecuringAch = true;
      }
      this.isAvailRecuringCard = false;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionCard'].setValue(false);
    }
  }

  onAvailRecuringAchChange(event: any) {
    if (event.checked) {
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availRecuringAch'].setValue(true);
    } else {
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availRecuringAch'].setValue(false);
    }
  }

  onAvailRecuringCardChange(event: any) {
    if (event.checked) {
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availRecuringCard'].setValue(true);
    } else {
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availRecuringCard'].setValue(false);
    }
  }

  onConvenienceFeeChangeEvent() {
    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availConvenienceFee'].value == 'Yes'
    ) {
      this.isConvenienceAllowed = true;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availConvenienceFee'].setValue('Yes');
    } else {
      this.isConvenienceAllowed = false;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['availConvenienceFee'].setValue('No');
      // this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].setValue("0.0");
    }
  }

  onCustomerOnboardEvent() {
    if (
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['customerPortalOnBoarding'].value == 'Yes'
    ) {      
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['customerPortalOnBoarding'].setValue('Yes');
    } else {
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['customerPortalOnBoarding'].setValue('No');      
    }
  }

  onChangeConvenienceFee() {
    this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
      'controls'
    ]['convenienceFeeAmount'].setValue(
      parseFloat(
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['convenienceFeeAmount'].value
      ).toFixed(2)
    );
    this.convenienceFeeAmount = parseFloat(
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['convenienceFeeAmount'].value.toFixed(2)
    );
  }

  onSendNotificationEmailChange(event: any) {
    if (event.checked) {
      this.isNotificationEmail = true;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['sendNotificationOn'].value.push('Email');
    } else {
      if (
        !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMS'].value
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.push('SMS');
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMS'].setValue(true);
        this.isNotificationSMS = true;
      }
      this.isNotificationEmail = false;
      let index =
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.indexOf('Email');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['sendNotificationOn'].value.splice(index, 1);
    }
  }

  onSendNotificationToOwnerEmailChange(event: any) {
    const configForm = this.hostedPaymentpageFormGroup.get(
      'configurationTabForm'
    );
    const notificationArray = configForm.get('sendNotificationToOwnerOn').value;

    const toggleNotification = (method: string, add: boolean) => {
      if (add && !notificationArray.includes(method)) {
        notificationArray.push(method);
      } else if (!add && notificationArray.includes(method)) {
        const index = notificationArray.indexOf(method);
        notificationArray.splice(index, 1);
      }
    };

    if (event.checked) {
      this.isNotificationToOwnerEmail = true;
      toggleNotification('Email', true);
    } else {
      if (!configForm.get('sendNotificationSMSOwner').value) {
        toggleNotification('SMS', true);
        configForm.get('sendNotificationSMSOwner').setValue(true);
        this.isNotificationToOwnerSMS = true;
      }
      this.isNotificationToOwnerEmail = false;
      toggleNotification('Email', false);
    }

    configForm
      .get('sendNotificationToOwnerOn')
      .setValue([...notificationArray]);
  }

  onSendNotificationSMSCardChange(event: any) {
    if (event.checked) {
      this.isNotificationSMS = true;
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['sendNotificationOn'].value.push('SMS');
    } else {
      if (
        !this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmail'].value
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.push('Email');
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmail'].setValue(true);
        this.isNotificationEmail = true;
      }
      this.isNotificationSMS = false;
      let index =
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.indexOf('SMS');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['sendNotificationOn'].value.splice(index, 1);
    }
  }

  onSendNotificationToOwnerSMSChange(event: any) {
    const configForm = this.hostedPaymentpageFormGroup.get(
      'configurationTabForm'
    );
    const notificationArray = configForm.get('sendNotificationToOwnerOn').value;

    const toggleNotification = (method: string, add: boolean) => {
      if (add && !notificationArray.includes(method)) {
        notificationArray.push(method);
      } else if (!add && notificationArray.includes(method)) {
        const index = notificationArray.indexOf(method);
        notificationArray.splice(index, 1);
      }
    };

    if (event.checked) {
      this.isNotificationToOwnerSMS = true;
      toggleNotification('SMS', true);
    } else {
      if (!configForm.get('sendNotificationEmailOwner').value) {
        toggleNotification('Email', true);
        this.isNotificationToOwnerEmail = true;
        configForm.get('sendNotificationEmailOwner').setValue(true);
      }
      this.isNotificationToOwnerSMS = false;
      toggleNotification('SMS', false);
    }

    configForm
      .get('sendNotificationToOwnerOn')
      .setValue([...notificationArray]);
  }

  bindPaymentOptions() {
    this.optionsData =
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
        'controls'
      ]['paymentOptionTypes'].value;
    if (this.optionsData && this.finzeoPaymentOption) {
      if (this.finzeoPaymentOption.match('ACH')) {
        this.isPaymentOptionAch = true;
        if (this.optionsData.ACH) {
          this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
            'controls'
          ]['paymentOptionAch'].setValue(true);
          this.isAvailRecuringAch = true;
          if (this.optionsData.ACH.isRecurring) {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availRecuringAch'].setValue(true);
          } else {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availRecuringAch'].setValue(false);
          }
          this.availConvenienceFee = 'Yes';
          if (this.optionsData.ACH.availConvenienceFee == 'Yes') {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availConvenienceFee'].setValue('Yes');
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['convenienceFeeAmount'].setValue(
              this.optionsData.ACH.convenienceFeeAmount
            );
          } else {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availConvenienceFee'].setValue('No');
          }
        } else {
          this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
            'controls'
          ]['paymentOptionAch'].setValue(false);
          this.isAvailRecuringAch = false;
          this.availConvenienceFee = 'No';
        }
      } else {
        this.isPaymentOptionAch = false;
      }

      if (this.finzeoPaymentOption.match('Card')) {
        this.isPaymentOptionCard = true;
        if (this.optionsData.Card) {
          this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
            'controls'
          ]['paymentOptionCard'].setValue(true);
          this.isAvailRecuringCard = true;
          if (this.optionsData.Card.isRecurring) {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availRecuringCard'].setValue(true);
          } else {
            this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
              'controls'
            ]['availRecuringCard'].setValue(false);
          }
        } else {
          this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
            'controls'
          ]['paymentOptionCard'].setValue(false);
        }
      } else {
        this.isPaymentOptionCard = false;
      }

      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.indexOf('SMS') > -1
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMS'].setValue(true);
        this.isNotificationSMS = true;
      } else {
        this.isNotificationSMS = false;
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMS'].setValue(false);
      }

      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationOn'].value.indexOf('Email') > -1
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmail'].setValue(true);
        this.isNotificationEmail = true;
      } else {
        this.isNotificationEmail = false;
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmail'].setValue(false);
      }

      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationToOwnerOn'].value.indexOf('SMS') > -1
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMSOwner'].setValue(true);
        this.isNotificationToOwnerSMS = true;
      } else {
        this.isNotificationToOwnerSMS = false;
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationSMSOwner'].setValue(false);
      }

      if (
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationToOwnerOn'].value.indexOf('Email') > -1
      ) {
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmailOwner'].setValue(true);
        this.isNotificationToOwnerEmail = true;
      } else {
        this.isNotificationToOwnerEmail = false;
        this.hostedPaymentpageFormGroup.controls['configurationTabForm'][
          'controls'
        ]['sendNotificationEmailOwner'].setValue(false);
      }

      if(!this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['customerPortalOnBoarding'].value){
        this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['customerPortalOnBoarding'].setValue("No");
      }
    }
  }
}
