import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
} from '@rxweb/reactive-form-validators';

export class UpdateBatchModel {
  @prop()
  batchId: string;

  @required()
  @maxLength({ value: 60 })
  @minLength({ value: 3 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ()]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  batchName: string;

  @prop()
  @required()
  effectiveDate: Date;

  @prop()
  batchStatus: string;

  @prop()
  isActive: boolean = true;

  @prop()
  batchTransactionDetails: BatchTransactionDetails[];
}

class BatchTransactionDetails {
  @prop()
  uniqueId: string;

  @prop()
  customerId: string;

  @prop()
  eventType: string;

  @prop()
  amount: number;

  @prop()
  batchTransactionStatus: string;

  @prop()
  notes: string;

  @prop()
  isInclude: boolean;
}
