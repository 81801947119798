import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { MatStepper } from '@angular/material/stepper';
import { ECheckTransactionModel } from 'src/app/featureModules/models/echeck-transactions-model';
import { ECheckTransactionService } from 'src/app/featureModules/services/echeck-transaction.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import {
  MatCalendarCellClassFunction,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import * as moment from 'moment';
import 'moment-timezone';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { HolidayUtils } from 'src/app/featureModules/helper/holiday.utils';
import { catchError, map, of } from 'rxjs';

interface DrowpDown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-echeck-transaction',
  templateUrl: './add-echeck-transaction.component.html',
  styleUrls: ['./add-echeck-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddEcheckTransactionComponent implements OnInit {
  customerFormGroup: FormGroup;
  bankFormGroup: FormGroup;
  amountFormGroup: FormGroup;

  isLoading: boolean = false;
  bankList = [];

  selectedValue: string;
  selectedCar: string;
  isRecurring: boolean = false;
  model: ECheckTransactionModel;
  customerBankAccountId;
  customerId;
  processorId;
  echecklist: DrowpDown[] = [
    // { value: 'BaseCommerce', viewValue: 'BaseCommerce' },
    { value: 'ECheck', viewValue: 'E-Check' },
  ];
  frequencies: DrowpDown[] = [
    { value: 'weekly', viewValue: 'Weekly' },
    { value: 'biweekly', viewValue: 'Bi-Weekly' },
    { value: 'monthly', viewValue: 'Monthly' },
    { value: 'yearly', viewValue: 'Yearly' },
  ];
  customerModel: AddCustomerModel;
  existingCustomer: boolean = false;
  userName: string = '';
  userEmail: string = '';
  currentESTTime: string;
  startWindowTime: string;
  endWindowTime: string;
  minDate = new Date();
  maxDate = new Date();
  // defaultScheduledDate = new Date();
  defaultScheduledDate = this.addDays(new Date(), 1);

  isSameDayAllowed: boolean;
  startingHours = 7;
  startingMinutes = 0;
  endingHours = 15; //TODO: Once Company Settings are ready for production, We will make this dyanmic
  endingMinutes = 0; //TODO: Once Company Settings are ready for production, We will make this dyanmic
  @ViewChild('stepper') stepper: MatStepper;
  todaydate = new Date(new Date());
  bankName: string = '';
  addECheckModel: ECheckTransactionModel;
  processorName: string;

  //routing params
  previousUrl: string = '';
  selectedTransactionTabIndex: number;
  transactionIndex: number;
  tabIndex: number;

  holidays: any;

  ngAfterViewInit() {
    if (this.existingCustomer) {
      this.customerFormGroup.controls['firstCtrl'].setValue('test');
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      });
    }
  }

  constructor(
    private _formBuilder: FormBuilder,
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _customerBankDetailsService: CustomerBankDetailsService,
    private _toastrService: ToastrService,
    private _echeckTransactionService: ECheckTransactionService,
    private _customerService: CustomerService,
    private _holidayUtils: HolidayUtils
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.previousUrl = navigation?.extras?.state
      ? navigation.extras.state['url']
      : '';
    if (this.previousUrl != '')
      localStorage.setItem('previousUrl', this.previousUrl);
    else this.previousUrl = localStorage.getItem('previousUrl');

    this.selectedTransactionTabIndex = navigation?.extras?.state
      ? navigation.extras.state['selectedTransactionTabIndex']
      : 0;

    this.transactionIndex = navigation?.extras?.state
      ? navigation.extras.state['transactionIndex']
      : 0;
    this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
    });
    if (this.customerId) {
      this.getCustomerById();
      this.existingCustomer = true;
    }
    this.customerFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.bankFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
      processorCtrl: [''],
    });
    this.addECheckModel = new ECheckTransactionModel();
    this.amountFormGroup = this.formBuilder.formGroup(this.addECheckModel);

    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    this.setScheduledDateAndHolidayFilter();
  }

  navigateToECheckPage() {
    this.router.navigateByUrl(this.previousUrl, {
      state: {
        selectedTransactionTabIndex: this.selectedTransactionTabIndex,
        transactionIndex: this.transactionIndex,
        tabIndex: this.tabIndex,
      },
    });
  }

  conditionallyRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('recurringTransaction').value) {
      return Validators.required(formControl);
    }
    return null;
  }

  onCustomerSelection(customer: any) {
    if (customer != null) {
      this.customerFormGroup.patchValue({ firstCtrl: customer.firstName });
      this.userName = customer.firstName;
      this.userName += customer.lastName ? ' ' + customer.lastName : '';
      this.userEmail = customer.email;
      this.getBankDetails(customer.customerId);
      this.bankFormGroup.patchValue({ secondCtrl: '', processorCtrl: null });
      this.bankName = '';
    } else {
      this.customerFormGroup.patchValue({ firstCtrl: '' });
      this.userName = '';
      this.userEmail = '';
    }
    this.processorName = '';
    this.bankName = '';
  }

  getBankDetails(customerId: any) {
    this.isLoading = true;
    this.bankList = [];
    this._customerBankDetailsService
      .GetAllBankAccounts(customerId, true)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.bankList = data.data.listOfBankAccountsToReturn;
          }
          if (this.bankList?.length == 0) {
            this.customerFormGroup.patchValue({ bankAccount: false });
            //this.bankFormGroup.patchValue({ secondCtrl: 'selected' });
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  getCustomerById() {
    this.isLoading = true;
    this._customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.customerModel = data.data;
          this.userName = this.customerModel.firstName;
          this.userName += this.customerModel.lastName
            ? ' ' + this.customerModel.lastName
            : '';
          this.userEmail = this.customerModel.email;
          this.getBankDetails(this.customerId);
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  addECheckTransaction() {
    this.isLoading = true;
    this.model = new ECheckTransactionModel();
    this.model.customerId = this.customerId;
    this.model.bankAccountId = this.customerBankAccountId;
    //this.model.companyProcessorId = this.customerBankAccountId;
    this.model.isRecurring = this.amountFormGroup.controls['isRecurring'].value;
    this.model.amount = this.amountFormGroup.controls['amount'].value;
    this.model.memoText = this.amountFormGroup.controls['memoText'].value;
    this.model.frequency = this.amountFormGroup.controls['frequency'].value;
    this.model.installments = this.amountFormGroup.controls['installments']
      .value
      ? this.amountFormGroup.controls['installments'].value
      : 0;
    this.model.scheduledDate =
      this.amountFormGroup.controls['scheduledDate'].value;

    if (this.amountFormGroup.valid) {
      this._echeckTransactionService
        .CreateCardTransaction(this.model)
        .subscribe(
          (data) => {
            this.isLoading = false;
            this._toastrService.success(
              'Transaction Details Added Successfully'
            );

            this.router.navigateByUrl(this.previousUrl, {
              state: {
                selectedTransactionTabIndex: this.selectedTransactionTabIndex,
                transactionIndex: this.transactionIndex,
                tabIndex: this.tabIndex,
              },
            });
          },
          (error) => {
            this.isLoading = false;
          }
        );
    } else {
      this.isLoading = false;
      this._toastrService.warning('Please enter required fields');
    }
  }

  onBankSelected(event: any, bank: any) {
    this.bankList.forEach((ob) => (ob.bankSelection = false));
    bank.bankSelection = event.value;
    this.customerBankAccountId = bank.customerBankAccountId;
    this.customerId = bank.customerId;
    this.bankFormGroup.patchValue({
      secondCtrl: bank.bankSelection,
      processorCtrl: this.processorName,
    });
    this.bankName = bank.bankName;
  }

  recurringChecked(event) {
    console.log(event);
    this.isRecurring = event.checked;
  }

  calculateTotalAmount() {
    if (this.amountFormGroup.controls['installments'].value) {
      let totalAmount =
        parseFloat(this.amountFormGroup.controls['amount'].value) *
        parseFloat(this.amountFormGroup.controls['installments'].value);
      this.amountFormGroup.controls['totalAmount'].setValue(
        totalAmount.toFixed(2).toString()
      );
    }
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  is_weekend = function (date1) {
    var dt = new Date(date1);
    if (dt.getDay() == 6 || dt.getDay() == 0) {
      return 'weekend';
    }
    return '';
  };

  checkIsSameDayAllowed() {
    var startingDateTime = new Date();
    var endingDateTime = new Date();

    var utcStartDateToValidate = new Date();
    utcStartDateToValidate.setUTCDate(utcStartDateToValidate.getUTCDate());
    utcStartDateToValidate.setUTCMonth(utcStartDateToValidate.getUTCMonth());
    utcStartDateToValidate.setUTCFullYear(
      utcStartDateToValidate.getUTCFullYear()
    );
    utcStartDateToValidate.setUTCHours(this.startingHours);
    utcStartDateToValidate.setUTCMinutes(this.startingMinutes);
    utcStartDateToValidate.setUTCSeconds(0);

    var utcEndDateToValidate = new Date();
    utcEndDateToValidate.setUTCDate(utcEndDateToValidate.getUTCDate());
    utcEndDateToValidate.setUTCMonth(utcEndDateToValidate.getUTCMonth());
    utcEndDateToValidate.setUTCFullYear(utcEndDateToValidate.getUTCFullYear());
    utcEndDateToValidate.setUTCHours(this.endingHours);
    utcEndDateToValidate.setUTCMinutes(this.endingMinutes);
    utcEndDateToValidate.setUTCSeconds(0);

    if (
      startingDateTime.getDate() == utcStartDateToValidate.getUTCDate() &&
      startingDateTime.getMonth() == utcStartDateToValidate.getUTCMonth() &&
      startingDateTime.getFullYear() == utcStartDateToValidate.getUTCFullYear()
    ) {
      if (
        startingDateTime > utcStartDateToValidate &&
        endingDateTime < utcEndDateToValidate
      ) {
        this.isSameDayAllowed = true;
        this.minDate = this.addDays(new Date(), 0);
        this.defaultScheduledDate = this.minDate;
        this.amountFormGroup.controls['isSameDay'].setValue(true);
      } else {
        this.isSameDayAllowed = false;
        this.minDate = this.addDays(new Date(), 1);
        this.todaydate = this.defaultScheduledDate;
        this.amountFormGroup.controls['isSameDay'].setValue(false);
      }
    } else {
      this.isSameDayAllowed = false;
      this.minDate = this.addDays(new Date(), 1);
      this.todaydate = this.defaultScheduledDate;
      this.amountFormGroup.controls['isSameDay'].setValue(false);
    }

    var timeZone = moment.tz.guess();
    var timeZoneOffset = startingDateTime.getTimezoneOffset();
    var timeZoneAbbr = moment.tz.zone(timeZone).abbr(timeZoneOffset);

    this.startWindowTime =
      this._holidayUtils.formatTime(utcStartDateToValidate) +
      ' ' +
      timeZoneAbbr;
    this.endWindowTime =
      this._holidayUtils.formatTime(utcEndDateToValidate) + ' ' + timeZoneAbbr;

    // this.model.scheduledDate = getSafeIsoDateString(this.defaultScheduledDate);
    this.amountFormGroup.controls['scheduledDate'].setValue(
      getSafeIsoDateString(this.defaultScheduledDate)
    );
  }

  onSameDayChanged(event: MatCheckboxChange) {
    if (event.checked) {
      this.checkIsSameDayAllowed();
      this.defaultScheduledDate = new Date();
      this.amountFormGroup.controls['scheduledDate'].setValue(
        getSafeIsoDateString(this.defaultScheduledDate)
      );
    } else {
      this.defaultScheduledDate = this.addDays(new Date(), 1);
      do {
        if (this.is_weekend(this.defaultScheduledDate)) {
          let updatedDate = this.addDays(this.defaultScheduledDate, 1);
          this.defaultScheduledDate = updatedDate;
        }
      } while (this.is_weekend(this.defaultScheduledDate));
      this.amountFormGroup.controls['scheduledDate'].setValue(
        getSafeIsoDateString(this.defaultScheduledDate)
      );
    }
  }

  onscheduledDateChanged(event: MatDatepickerInputEvent<Date>) {
    const selectedDate = event.value;
    const date = getSafeIsoDateString(selectedDate);
    this.amountFormGroup.controls['scheduledDate'].setValue(date);
  }

  processorSelection(value) {
    this.processorName = value;
    this.bankFormGroup.patchValue({ processorCtrl: value });
  }

  bankEncreption(bankAccountNumber: any) {
    if (bankAccountNumber) {
      bankAccountNumber =
        '**** **** **** ' +
        bankAccountNumber.slice(bankAccountNumber.length - 4);
    }
    return bankAccountNumber;
  }

  setScheduledDateAndHolidayFilter() {
    const onSuccess = (data: any) => {
      this.holidays = data.data;
    };
    const onError = (e) => {
      return of([]);
    };

    this._echeckTransactionService
      .GetUpcomingHolidays(this.minDate.getFullYear())
      .pipe(map(onSuccess), catchError(onError))
      .subscribe(() => {
        this.defaultScheduledDate =
          this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
            this.defaultScheduledDate,
            this.holidays
          );

        this.amountFormGroup.controls['scheduledDate'].setValue(
          this.defaultScheduledDate
        );
      });
  }

  holidayFilter = (date: Date): boolean => {
    return this._holidayUtils.getHolidaysFilter(this.holidays, date);
  };

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date, view) => {
    return this._holidayUtils.getDateClass(this.holidays, date, view);
  };
}
