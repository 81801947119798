import { AbstractControl } from "@angular/forms";
import { maxLength, maxNumber, minNumber, pattern, prop, propObject, required } from "@rxweb/reactive-form-validators";

export class DesignTabControlModel {

  @prop()
  @required()
  linkType: string = "Button";

  @prop()
  @required()
  @maxLength({ value: 50 })
  @pattern({ expression: { Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,}, message: 'Only letters and digits are allowed'}) 
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  linkText: string = 'BUY BUTTON';

  @prop()
  @required()
  @maxLength({ value: 250 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  linkPurpose: string;

  @prop()
  @required()
  fontFamily: string = "Times New Roman";

  @prop()
  @required()
  fontSize: string = "13px";

  @prop()
  @required()
  fontStyle: string = "normal";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Button' ? true : false;
    },
  })
  buttonTextColor: string = "#F6F2F2";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Button' ? true : false;
    },
  })
  buttonBgColor: string = "#2658DD";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Button' ? true : false;
    },
  })
  shadow: string = "OFF";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Button' ? true : false;
    },
  })
  borderColor: string = "#FFFFFF";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Button' ? true : false;
    },
  })
  borderRadius: string = 'none';

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Link' ? true : false;
    },
  })
  linkTextColor: string = "#2848D3";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Link' ? true : false;
    },
  })
  linkBgColor: string = "#FEFEFE";

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['linkType'] == 'Link' ? true : false;
    },
  })
  target: string = "NewWindow";

}
export class SettingTabControlModel {

  @prop()
  @required()
  dateExpiring: string = "Never";

  @prop()  
  @required({        
      conditionalExpression: function (control: AbstractControl) {                        
        return control['dateExpiring'] == 'Time Bound' ? true :false;
      },
    })  
  specificTime:string;

  @prop()  
  @required({        
      conditionalExpression: function (control: AbstractControl) {                        
        return control['dateExpiring'] == 'DateTime Bound' ? true :false;
      },
    })
  specificDateTime:string;

  @prop() 
  isRecurringAllowed:string = "false";
  
}
export class PricingTabControlModel {

  @prop()
  @required()
  invoiceNoEntryMethod: string= 'SelfInvoice';

  // @prop()
  // @maxLength({ value: 50 })
  // @pattern({ expression: { No_Space: /.*\S.*/ } })
  // @required({
  //   conditionalExpression: function (control: AbstractControl) {
  //     if (control['invoiceNoEntryMethod'] == 'SelfInvoice' || control['invoiceNoEntryMethod'] == 'FinzeoInvoice') {
  //       return true;
  //     }
  //     return false;
  //   },
  // })
  // invoiceNumber: string;

  @prop()
  @maxLength({ value: 50 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return control['invoiceNoEntryMethod'] == 'CustomerInvoice' ? true : false;
    },
  })
  suggestedInvoiceNumber: string = "SelfInvoice";

  @prop()
  @required()
  priceEntryMethod: string = "FixedPrice";

  @prop()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @required()
  amount: number;

  @prop()
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00 })
  @maxNumber({ value: 100 })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      if (control['invoiceNoEntryMethod'] == 'SelfInvoice' || control['invoiceNoEntryMethod'] == 'FinzeoInvoice') {
        return true;
      }
      return false;
    },
  })
  tax: number;

  @prop()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })
  @minNumber({ value: 0.00 })
  @maxNumber({ value: 100 })
  @required({
    conditionalExpression: function (control: AbstractControl) {
      if (control['invoiceNoEntryMethod'] == 'SelfInvoice' || control['invoiceNoEntryMethod'] == 'FinzeoInvoice') {
        return true;
      }
      return false;
    },
  })
  shipping: number;

  @prop()
  @pattern({ expression: { Number_Decimal: /^[0-9]+(.[0-9]{0,2})?$/ } })  
  totalAmount: number;
}

export class PaymentLinkCreationModel {

  @propObject(DesignTabControlModel)
  designTabForm: DesignTabControlModel;

  @propObject(SettingTabControlModel)
  settingTabForm: SettingTabControlModel;

  @propObject(PricingTabControlModel)
  pricingTabForm: PricingTabControlModel;
}



