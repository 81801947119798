<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>
<form [formGroup]="submitReviewFormGroup">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" class="heading">
    <mat-label>Write a Review</mat-label>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" class="ticket-Id">    
    <!-- <mat-label>Ticket ID :{{ submitReviewModel.ticketNumber }}</mat-label>  -->
    <mat-label>Ticket ID : {{ data.ticketNumber }}</mat-label>    
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayout.xs="column" fxFlex="100%" >
        <mat-label class="subheader-style">Review :</mat-label>      
        <div class="textarea-spacing">
            <mat-form-field appearance="outline">              
              <textarea
                autosize
                matInput
                name="review"
                rows="3"
                maxlength="120"
                placeholder="Enter Text*"
                formControlName="review"
              >
              </textarea>
              <mat-error>
                {{
                    submitReviewFormGroup.controls["review"]["_errorMessage"]
                }}
              </mat-error>
              <mat-hint class="hint-style">Maximun 120 charcters can be added.</mat-hint>
            </mat-form-field>
        </div> 
    </div>              
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2%" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayout.xs="column" >
        <mat-label class="subheader-style">Rating :</mat-label>              
        <mat-star-rating [rating]="rating"  [starCount]="starCount" [color]="starColor" (ratingUpdated)="onRatingChanged($event)">                
        </mat-star-rating>         
    </div> 
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="end"
    class="action-button-padding"
  >
    <button mat-stroked-button color="primary" [mat-dialog-close]>
      CANCEL
    </button>

    <button
      mat-raised-button
      color="primary"
      (click)="submitReview()"
    >
      SUBMIT
    </button>
  </div>
</form>