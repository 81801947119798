import { maxLength, maxNumber, minNumber, pattern, prop, propObject, required } from "@rxweb/reactive-form-validators";

export class DesignCustomisation {
  logo: string;
  themeTextColor: string;
  themeColor: string;
  themeFont: string;
}

export class DesignConfiguration {
  isInvoiceNumberView: string = "hide";
  isDescriptionView: string = "hide";
  paymentOptionTypes: paymentOptionTypes;
  shippingDetails: string = "hide";
  sendNotificationOn: Array<string> = [];
  sendNotificationToOwnerOn: Array<string> = [];
  emailList: Array<string> = [];
  mobileList: Array<string> = [];
  customerPortalOnBoarding:string="No";
}

export class paymentOptionTypes {
  ACH: ACH;
  Card: Card;
}

export class ACH {
  isRecurring: boolean = true;
  availConvenienceFee: string = "Yes";
  convenienceFeeAmount: number = parseFloat("0");
}

export class Card {
  isRecurring: boolean = true;
}

export class HostedPaymentSendModel {
  @propObject(DesignCustomisation)
  customisationTabForm: DesignCustomisation;

  @propObject(DesignConfiguration)
  configurationTabForm: DesignConfiguration;
}
