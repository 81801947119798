<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToReportPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Reports
    </mat-label>
  </div>
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Origination Report</div>
        <div class="page-sub-header-text-1">
          All Origination Report related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div class="origination-gererate-form">
      <form [formGroup]="originationFormGroup">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
        class="applyfilterheader">
          <mat-label>Apply Filter</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="form-group">             
          <div fxLayout="column" fxFlex="25%"> 
            <mat-form-field appearance="outline">
                <mat-label>Date</mat-label>
                <input                  
                  matInput
                  class="form-control"                  
                  formControlName="batchDate"
                  [max]="maxDate"
                  readonly
                  placholder="MM/DD/YYYY"
                  [matDatepicker]="picker"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [startAt]="maxDate"></mat-datepicker>
                <mat-error>
                  {{ originationFormGroup.controls["batchDate"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>           
          </div>                        
        </div>
      </form>
    </div>
</div>
<div fxLayout="column" fxLayoutAlign="center end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="applyButton">        
        <button mat-raised-button color="primary" class="page-header-button-style" (click)="onApplyClick()">
            GENERATE REPORT
        </button>
    </div>
</div>
