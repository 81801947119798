<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  class="apply-filter"
>
  <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
    APPLY FILTER
  </div>
  <div fxLayout="column" fxFlex="10%">
    <mat-icon (click)="drawerClose()">close</mat-icon>
  </div>
</div>
<div class="apply-filter-body">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <form [formGroup]="filterFormGroup" class="wd-96">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Status</mat-label>
          <mat-select
            class="form-control"
            formControlName="status"
            name="status"
          >
            <mat-option *ngFor="let status of statusList" [value]="status.key"
              >{{ status.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group form-field-bottom"
      >
        <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select class="form-control" formControlName="type" name="type">
            <mat-option *ngFor="let type of typeList" [value]="type.key"
              >{{ type.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group form-field-bottom"
      >
        <mat-form-field fxFlex="100%" appearance="outline">
          <mat-label>Link Expiry</mat-label>
          <mat-select class="form-control" formControlName="linkExpiry" name="linkExpiry">
            <mat-option *ngFor="let expiryType of expiryList" [value]="expiryType.key"
              >{{ expiryType.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="5%"
        class="form-group form-field-bottom"
      >
        <div fxLayout="column" fxLayoutAlign="center start">Amount</div>
        <div fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <mat-label>From</mat-label>
            <input
              title="From"
              matInput
              class="form-control"
              formControlName="fromAmount"
              type="text"
              name="fromAmount"
              placholder="From"
              autocomplete="off"
              appTwoDigitDecimalNumber
            />
            <span matPrefix class="material-icons">attach_money</span>
          </mat-form-field>
        </div>
        <div fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-width">
            <mat-label>To</mat-label>
            <input
              title="To Amount"
              matInput
              class="form-control"
              formControlName="toAmount"
              type="text"
              name="toAmount"
              placholder="To"
              autocomplete="off"
              appTwoDigitDecimalNumber
            />
            <span matPrefix class="material-icons">attach_money</span>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <br />
  <div fxLayout="column" fxLayoutAlign="center end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="applyResetStyle">
      <button mat-button class="white-button-style" (click)="restForm()">
        RESET
      </button>
      &nbsp;&nbsp;
      <button
        mat-raised-button
        color="primary"
        class="page-header-button-style"
        (click)="applyAdvanceFilter()"
      >
        APPLY
      </button>
    </div>
  </div>
</div>
