import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root',
})

export class HostedPaymentService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_HOSTED_PAYMENT}HostedPaymentLinkGeneration`);
  }

  getAllHostedPaymentLink(filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/getAllHostedPaymentLink?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  addPaymentLinkCreationDetails(model: any): Observable<any> {
    return this._http.post<any>(this._base + "/addHostedPaymentLinkDetails", model);
  }

  getHostedPaymentLinkById(linkId: any): Observable<any> {
    return this._http.get<any>(this._base + "/getHostedPaymentLinkById/" + linkId);
  }

  updatePaymentLinkStatus(id: any, status): Observable<any> {
    return this._http.put<any>(this._base + "/updatePaymentLinkStatus/" + id + "/" + status, null);
  }
}
