import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatFileSize',
  pure : true
})
export class FormatFileSizePipe implements PipeTransform {

  transform(size: number): string {
    let iSize = (size / 1024); 
    let showSize ="";

      if (iSize / 1024 > 1) 
      { 
        if (((iSize / 1024) / 1024) > 1) 
        { 
            iSize = (Math.round(((iSize / 1024) / 1024) * 100) / 100);
            showSize = iSize + " GB"; 
        }
        else
        { 
            iSize = (Math.round((iSize / 1024) * 100) / 100);
            showSize = iSize + " MB"; 
        } 
      } 
      else 
      {
        iSize = (Math.round(iSize * 100) / 100)
        showSize = iSize + " KB"; 
      }

      return showSize;
  }

}
