import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentErrorConstants } from 'src/app/externalModules/paymentWidget/constants/payment-error.const';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';

@Component({
  selector: 'app-non-mfa-login-failure',
  templateUrl: './non-mfa-login-failure.component.html',
  styleUrls: ['./non-mfa-login-failure.component.scss']
})
export class NonMfaLoginFailureComponent implements OnInit {

  isLoading = false;
  token: string;
  pageSettingModel: PageSettingModel;

  errorMessageTitle: string;
  errorMessage: string;
  senderEmail: string;
  paymentPageSettingData: string;
  logotext : string ;

  constructor(
    private router: Router
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.errorMessage =
      navigation?.extras?.state['errorMessage'] ??
      PaymentErrorConstants.InvalidToken;

    this.logotext = navigation?.extras?.state['logotext'] ?? "FinZeo";

    this.paymentPageSettingData =
      navigation?.extras?.state['paymentPageSettingData'] ??
      "";
  }

  ngOnInit(): void {
    this.errorMessageTitle = 'Invalid Token';
    this.errorMessage = this.errorMessage ?? this.errorMessageTitle;
  }

  getThemeFromResponse() {
    if (this.paymentPageSettingData) {
      this.pageSettingModel = new PageSettingModel(null);
    } else {
      this.getThemeFromLocalStorage();
    }
  }

  getThemeFromLocalStorage() {
    this.pageSettingModel = new PageSettingModel(null);
  }
}
