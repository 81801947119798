import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/sharedModules/services/local-storage/local-storage.service';
import { PaymentErrorConstants } from '../../constants/payment-error.const';
import { PaymentWidgetCardService } from '../../services/payment-widget-card.service';
import { PageSettingModel } from '../../models/payment-page-customization.model';

@Component({
  selector: 'app-payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss'],
})
export class PaymentOrderComponent implements OnInit {
  isLoading = false;

  token: string;

  companyLogo: string;
  paymentHeaderBGColor: string;

  invoiceNumber: any;
  amount: any;
  description: any;
  displayDescription: any;
  primaryButtonBGColor: string;
  labelColor: string;
  subscription: Subscription;
  widgetInfo: any;
  pageSettingModel: PageSettingModel;
  logotext :string ;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    public paymentWidgetCardService: PaymentWidgetCardService,
    public localStorageService: LocalStorageService,
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    this.localStorageService.setItem('UniqueToken', this.token);

    if (this.token) {
      this.getPaymentPageInformation();
    } else {
      this.navigateToErrorPage(PaymentErrorConstants.InvalidToken);
    }
  }

  getPaymentPageInformation() {
    this.isLoading = true;
    this.paymentWidgetCardService
      .GetPaymentPageInformation(this.token)
      .subscribe(
        (data) => {
          if (data && data.code === 200 && data.data) {
            this.widgetInfo = data.data;
            this.invoiceNumber = this.widgetInfo.invoiceNumber;
            this.amount = this.widgetInfo.amount;
            this.description = this.widgetInfo.description;
            this.displayDescription = this.description;
            this.logotext = this.widgetInfo.clientName;
            let requiredLength: number = 125;
            if (this.description?.length > requiredLength) {
              this.displayDescription =
                this.description?.substring(0, requiredLength) + ' ..';
            }

            //Theme based changes
            this.setThemeInLocalStorage();
            this.getThemeFromLocalStorage();
            this.isLoading = false;
          } else {
            this.isLoading = false;
            this.navigateToErrorPage(PaymentErrorConstants.InvalidPaymentLink);
          }
          this.isLoading = false;
        }
      );
  }

  doMakePayment() {
    this.subscription = this.recaptchaV3Service
      .execute('doMakePayment')
      .subscribe((recaptcha) => {
        if (recaptcha) {
          this.router.navigate(['payment/details/' + this.token], {
            state: {
              widgetInfo: this.widgetInfo,
            },
          });
        }
      });
  }

  setThemeInLocalStorage() {
    this.localStorageService.setPaymentPageCustomizationBasedObjectByKey(
      this.token,
      this.widgetInfo.pageCustomizationSettings,
      this.widgetInfo.allowPaymentOptions
    );
  }

  getThemeFromLocalStorage() {
    let key = this.token;
    this.pageSettingModel = new PageSettingModel(
      this.localStorageService.getPaymentPageCustomizationBasedObjectByKey(key));
  }

  displayFields(conditionType: string): boolean {
    if (conditionType.toLowerCase() === "show") {
      return true;
    }
    if (conditionType.toLowerCase() === "hide") {
      return false
    }
  }

  navigateToErrorPage(errorMessage: string) {
    this.router.navigate(['payment/failure/' + this.token], {
      state: {
        errorMessage: errorMessage,
        paymentPageSettingData: this.widgetInfo.pageCustomizationSettings,
      },
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
