<mat-form-field
  appearance="outline"
  *ngIf="!selectedCustomerChip"
>
  <input
    matInput
    placeholder="Search Customer"
    [(ngModel)]="selectedCustomer"
    [matAutocomplete]="auto"
    [formControl]="searchCustomerCtrl"
    placeholder="Min 3 Characters"
    style="padding-bottom: 0.5%;"
    pattern="^[a-zA-Z ]*$"
  />
  <button
    *ngIf="selectedCustomer"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="clearSelection()"
  >
    <mat-icon>close</mat-icon>
  </button>
  <mat-icon matPrefix>search</mat-icon>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelected()"
  >
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading && filteredCustomer.length > 0">
      <mat-option *ngFor="let customer of filteredCustomer" [value]="customer">
        <div class="name-email-style">
          {{ customer.firstName?.trim() }}&nbsp;{{ customer.lastName?.trim() }}
        </div>
        <div class="name-email-style sub-text">{{ customer.email }}</div>
      </mat-option>
    </ng-container>
    <ng-container *ngIf="isShowNoFound && !isLoading && !filteredCustomer.length">
      <mat-option>
        <div class="name-email-style">
          <b>No Records Found</b>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<br />

<div class="chip-box-shadow" *ngIf="selectedCustomerChip">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxFlex="90%">
      <div fxLayout="row" style="margin-top: 14px" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="8%">
          <mat-label class="userNameCircle">
            {{
              getNameInitials(
                selectedCustomerChip["firstName"],
                selectedCustomerChip["lastName"]
              )
            }}
          </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap fxFlex="92%">
          <span class="ellip" style="padding-left: 10px">
            <div>
              {{ selectedCustomerChip["firstName"] }}
              {{ selectedCustomerChip["lastName"] }}
            </div>
            <div class="sub-text">{{ selectedCustomerChip["email"] }}</div>
          </span>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%">
      <button class="chip-close" (click)="clearSelection()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<br />
