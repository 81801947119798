import { Component, Input, OnInit } from '@angular/core';
import { CustomerChallengerDeductPointsComparisonRecord, IbvReportGenerationServiceProxy } from 'src/app/sharedModules/services/ibv/ibv.proxies';
import { CustomerScoreStatsFilter } from '../models/customer-score-stats.models';

@Component({
  selector: 'customer-score-stats-card2',
  templateUrl: './customer-score-stats-card2.component.html',
  styleUrls: ['./customer-score-stats-card2.component.scss']
})
export class CustomerScoreStatsCard2Component implements OnInit {

  @Input() public filter: CustomerScoreStatsFilter;

  isLoading: boolean = true;
  isDisplay: boolean = false;

  public barChartOptions: any = {
    scaleShowVerticalLines: true,
    responsive: true,
    indexAxis: 'y',
    plugins: { legend: { position: 'bottom' } }
  };

  public mbarChartLabels: string[];
  public barChartLegend: boolean = true;
  public barChartData: any[];

  constructor(private _scoreService: IbvReportGenerationServiceProxy) {
  }

  ngOnInit(): void {
  }

  bindingChart(customerName: string, clientName: string, dataClient: number[], dataCustomer:  number[], dataSystem:  number[], labels:  string[]) {

    this.mbarChartLabels = labels;
    this.barChartData = [
      {
        data: dataCustomer,
        label: customerName,
        backgroundColor: 'rgba(112,173,71,255)',
        hoverBackgroundColor: 'rgba(112,173,71,255)'
      },
      {
        data: dataClient,
        label: clientName,
        backgroundColor: 'rgba(5,99,193,255)',
        hoverBackgroundColor: 'rgba(5,99,193,255)'
      },
      {
        data: dataSystem,
        label: 'System',
        backgroundColor: 'rgba(255,192,0,255)',
        hoverBackgroundColor: 'rgba(255,192,0,255)'
      },
    ];
  }

  hide() {
    this.isDisplay = false;
  }

  show() {
    this.isDisplay = true;
  }

  fetchData() {
    this.show();
    this.isLoading = true;
    if (!this.filter?.customer?.customerId) {
      return;
    }

    this._scoreService.getCustomerChallengerDeductPointsByRules(this.filter?.customer?.customerId, this.filter?.fromDateKey, this.filter?.toDateKey).subscribe({
      next: (resp: any) => {
        if (resp.code == 200) {
          const record = resp.data as CustomerChallengerDeductPointsComparisonRecord;
          let labels = record.ruleDeductInfos.map(r => r.ruleDescription);
          let dataCustomer = record.ruleDeductInfos.map(r => Math.trunc(r.customerValue));
          let dataClient = record.ruleDeductInfos.map(r => Math.trunc(r.clientValue));
          let dataSystem = record.ruleDeductInfos.map(r => Math.trunc(r.allClientsValue));
          this.bindingChart(record.customerName, record.clientName, dataCustomer, dataClient, dataSystem, labels);
        }
        else {
          this.hide();
        }
      },
      error: (error: any) => {
        this.hide();
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

}
