<div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" class="card-label-block">
  <mat-label class="sub-header"><b>ACH Details</b></mat-label>
</div>

<div class="achDetailsForm">
  <form [formGroup]="achDetailsFormGroup">
    <div fxLayout="column" fxLayoutGap="5%">
      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="5%" fxLayoutGap="0%"
        fxLayoutGap.xs="5%" fxLayoutAlign="start center" fxLayoutAlign.sm="start stretch"
        fxLayoutAlign.xs="start stretch" class="form-group">
        <div fxLayout="column" fxFlex="30%" fxLayout.sm="row" fxLayout.xs="row">
          <label>Account Type</label>
        </div>
        <div fxLayout="column" fxFlex="70%" fxLayout.sm="row" fxLayout.xs="row">
          <mat-radio-group formControlName="entryCode" class="form-control w-100" name="entryCode"
            (change)="onEntryCodeSelected($event)">
            <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="space-evenly center"
              fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch" fxLayoutGap.sm="15%"
              fxLayoutGap.xs="15%">
              <div fxLayout="column" fxFLex="50%" fxLayoutWrap fxLayoutGap="5%"
                *ngFor="let entryCodeItem of entryCodes">
                <mat-radio-button [value]="entryCodeItem.key" [style.--active-bg-color]="pageSettingModel?.themeColor">
                  {{ entryCodeItem.value }}
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group">
        <div fxLayout="column" fxLayoutWrap fxFlex="45%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label class="required">Account Number</mat-label>
            <input matInput name="accountNumber" formControlName="accountNumber" maxlength="17" autocomplete="cc-number"
              class="form-control" placeholder="Account Number" (focusin)="onFocusInEvent($event)"
              (focusout)="onFocusOutEvent($event)" />
            <mat-error>
              {{
              achDetailsFormGroup.controls["accountNumber"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="45%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label class="required">Routing Number</mat-label>
            <input matInput name="routingNumber" formControlName="routingNumber" maxlength="12" autocomplete="cc-number"
              class="form-control" placeholder="Routing Number" (focusin)="onFocusInEvent($event)"
              (focusout)="onFocusOutEvent($event)" />
            <mat-error>
              {{
              achDetailsFormGroup.controls["routingNumber"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="!isRecurringDisabled" fxLayout="row" fxLayout.xs="column" fxLayoutWrap
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group">
        <section class="example-section">
          <mat-checkbox [style.--active-bg-color]="pageSettingModel?.themeColor" (change)="recurringChecked($event)"
            formControlName="isRecurring">
            Recurring Transaction
          </mat-checkbox>
          <mat-error>
            {{ achDetailsFormGroup.controls["isRecurring"]["_errorMessage"] }}
          </mat-error>
        </section>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group m-b-0" *ngIf="checkIfRecurring()">
        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label>Frequency*</mat-label>
            <mat-select name="frequency" formControlName="frequency" (selectionChange)="onFrequencyChanged($event.value)">
              <mat-option *ngFor="let frequency of frequencies" [value]="frequency.value"
                [disabled]="checkIfFrequencyDisabled(frequency.value)">
                <mat-label [style.--active-fontFamily]="pageSettingModel?.themeFont">{{ frequency.viewValue }}</mat-label> 
              </mat-option>
            </mat-select>
            <mat-error>
              {{ achDetailsFormGroup.controls["frequency"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label>Schedule date</mat-label>
            <input matInput [disabled]="true" [matDatepicker]="datepicker" formControlName="scheduledDate"
              [min]="defaultScheduledDate" [max]="maxDate" (dateChange)="onscheduledDateChanged($event)"
              [matDatepickerFilter]="holidayFilter" />
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker [startAt]="defaultScheduledDate" [disabled]="false" [dateClass]="dateClass"
              (closed)="onCalendarClose()" (cancelled)="onCalendarCancel()">
              <mat-datepicker-actions>
                <button [style.--active-bg-color]="pageSettingModel?.themeColor" class="secondaryBtn" mat-button
                  matDatepickerCancel>
                  Cancel
                </button>
                <button class="primaryBtn" mat-raised-button
                  [style.--active-text-color]="pageSettingModel?.themeTextColor"
                  [style.--active-bg-color]="pageSettingModel?.themeColor" matDatepickerApply>
                  Apply
                </button>
              </mat-datepicker-actions>
            </mat-datepicker>
            <mat-error>
              {{
              achDetailsFormGroup.controls["scheduledDate"]["_errorMessage"]
              }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%" fxLayoutAlign.sm="start stretch"
          fxLayoutAlign.xs="start stretch" fxLayoutAlign="center center">
          <section class="example-section">
            <mat-checkbox [style.--active-bg-color]="pageSettingModel?.themeColor" (change)="endOfMonthCheck($event)"
              formControlName="isEndOfMonth">
              At end of the month
            </mat-checkbox>
            <mat-error>
              {{
              achDetailsFormGroup.controls["isEndOfMonth"]["_errorMessage"]
              }}
            </mat-error>
          </section>
        </div>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group" *ngIf="checkIfRecurring()">
        <div fxLayout="column" fxLayoutWrap fxFlex="30%">
          <mat-form-field appearance="outline" [style.--active-bg-color]="pageSettingModel?.themeColor">
            <mat-label class="">Number of Payments</mat-label>
            <input matInput formControlName="installment" class="form-control" name="installment"
              placeholder="installment" autocomplete="cc-csc" maxlength="2" type="text" />
            <mat-error>
              {{ achDetailsFormGroup.controls["installment"]["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%"></div>

        <div fxLayout="column" fxLayoutWrap fxFlex="30%" fxLayoutAlign.sm="start stretch"
          fxLayoutAlign.xs="start stretch" fxLayoutAlign="center center"></div>
      </div>

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%"
        fxLayoutGap="5%" fxLayoutAlign="start stretch" class="form-group" *ngIf="checkIfRecurring() && achDetailsFormGroup.controls['scheduledDate'].value">
        <div fxLayout="column" fxLayoutWrap fxFlex="100%" class="notice-text">
          Note: For recurring payments, the initial payment made today is not counted towards the total number of scheduled recurring payments that begin on {{achDetailsFormGroup.controls["scheduledDate"].value | date:'MM/dd/yyyy'}} 
                

        </div>
      </div>
    </div>
  </form>
</div>