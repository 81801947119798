<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<div fxLayout="row"   fxLayoutAlign="start stretch"  >
    <div fxLayout="column">
        <div class="User-Header">
            <mat-label>
                <button mat-icon-button (click) = "navigateToUserPage()">
                    <mat-icon>arrow_back </mat-icon>
                </button>
                Users 
            </mat-label>
        </div>
        <br />
        <div class="Header-Title">
            Edit User
        </div>
        <div class="Header-Description">
            All Users related data can be updated from this page
        </div>
    </div>

</div>


<div fxLayout="row"   fxLayoutAlign="start stretch" >
    <div fxLayout="column" fxFlex="100%" >
        <div class="form-AddUser">
            <div class="info-AddUser">
                <div>
                    <p class="title">User Information</p>
                </div>
    
                <form [formGroup]="addUser"  autocomplete="off">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" >
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input type="text"  matInput formControlName="firstName" [pattern]="firstNamePattern" maxlength="50">
                                    <mat-error *ngIf="f['firstName'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                    <mat-error *ngIf="!f['firstName'].errors?.['required']">{{errorFirstName}}</mat-error>
                                </mat-form-field>
            
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input type="text" matInput formControlName="lastName" [pattern]="lastNamePattern" maxlength="50">
                                    <mat-error *ngIf="f['lastName'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                    <mat-error *ngIf="!f['lastName'].errors?.['required']">{{errorLastName}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>E-mail ID</mat-label>
                                    <input type="text" matInput formControlName ="emailId" maxlength="50">
                                    <mat-error *ngIf="f['emailId'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                    <mat-error *ngIf="!f['emailId'].errors?.['required']" >{{emailMgs}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Job Title</mat-label>
                                    <input type="text" matInput formControlName ="jobTitle" [pattern]="jobTitlePattern" maxlength="50">
                                    <mat-error *ngIf="f['jobTitle'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                    <mat-error *ngIf="!f['jobTitle'].errors?.['required']">{{errorJobTitle}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div  fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Role</mat-label>
                                    <mat-select name="role" formControlName ="role"  >
                                        <mat-option *ngFor="let role of RoleList" [value]="role.key">
                                            {{role.value}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="f['role'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                </mat-form-field>
                            </div>
                            
                            <div fxFlex="2%"></div>
                            <div 
                                fxLayout="row"
                                fxLayout.xs="column"
                                fxLayoutWrap
                                fxLayoutGap="16px"
                                fxLayoutAlign="start stretch"
                                class="form-group"
                                fxFlex="48%"
                                >
                                <div
                                    fxFlex="48%"
                                    appearance="outline"
                                    class="phoneNumberCls"
                                    style="margin-top: 1.3em"
                                >
                                    <ngx-intl-tel-input
                                    [ngClass]="{
                                        'phone-invaid':
                                        f['phone'].invalid &&
                                        f['phone'].touched,
                                        'phone-valid': f['phone'].valid
                                    }"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [customPlaceholder]="'Contact Number *'"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[
                                        SearchCountryField.Iso2,
                                        SearchCountryField.Name
                                    ]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [phoneValidation]="true"
                                    [numberFormat]="PhoneNumberFormat.National"
                                    [value]="phoneNumber"
                                    name="phone"
                                    formControlName="phone"
                                    [maxLength]="maxLength"
                                    (countryChange)="changePhone($event)"
                                    >
                                    </ngx-intl-tel-input>
                                    <mat-error  style="font-size:10px ; margin-top: 3px; padding-left:9px ;" *ngIf="f['phone'].errors?.['required'] && f['phone']?.['touched'] " >{{requiredMgs}}</mat-error>
                                    <mat-error  style="font-size:10px ; margin-top: 3px; padding-left:9px ;" *ngIf="!f['phone'].errors?.['required'] && f['phone']?.['touched'] && f['phone']?.['invalid']" >{{invalidPhoneMgs}}</mat-error>
                                    <div *ngIf="phonehint" class="phonehint-text">
                                    eg.: {{ phonehint }}
                                    </div>
                                </div>
                                </div>
                            </div>
                    </div> 

                    <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="start stretch">
                        <div fxFlex="22%" style="margin-bottom: 5px;">
                            <section >
                                <mat-checkbox  style="font-weight: 500; font-size: 13px;" (change)="checkUpdatePassword($event)" formControlName="isUpdatePassword">
                                    Update Password
                                </mat-checkbox>
                            </section>
                        </div>
                    </div>

                    <div *ngIf ="isUpdatePassword" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input [type]="showPassword ? 'text' : 'password'" matInput name="password" formControlName="password" required [pattern]="passwordPattern"  maxlength="50">
                                    <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>
                                    <mat-error *ngIf="f['password'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Confirm password</mat-label>
                                    <input [type]="showPasswordConfirm ? 'text' : 'password'" matInput name="confirmPassword" formControlName="confirmPassword" required  maxlength="50">
                                    <mat-icon matSuffix (click)="togglePasswordConfirmVisibility()">{{showPasswordConfirm?'visibility_off':'visibility'}}</mat-icon>
                                    <mat-error *ngIf="f['confirmPassword'].errors?.['required']" >{{requiredMgs}}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <mat-error *ngIf="f['password'].errors  && f['password'].touched && f['password'].invalid" class="invalid-feedback">{{errorMgs}}</mat-error>

                    <div *ngIf="f['confirmPassword'].touched && f['confirmPassword'].invalid" class="invalid-feedback">
                        <div *ngIf="f['confirmPassword'].errors['confirmedValidator']">Password and Confirm Password must be match.</div>
                    </div> 

                    <!-- <div fxLayout="row" fxLayout.xs="column"  fxLayoutAlign="start stretch">
                        <div fxFlex="22%" style="margin-bottom: 5px;">
                            <section >
                                <mat-checkbox  style="font-weight: 500; font-size: 13px;" formControlName="isSubscribeReceivingTransactionEmail">
                                    User receives transaction summary email
                                </mat-checkbox>
                            </section>
                        </div>
                    </div> -->
                    
                </form>
            </div>
        </div>
    </div>

</div>

<div fxLayout="row"   fxLayoutAlign="start stretch" style="float: right;" >
    <div style="padding-top: 20px; float: right;">
        <button mat-button  class="button-style-back" (click) = "navigateToUserPage()">CANCEL</button>
        &nbsp;&nbsp;
        <button mat-stroked-button  class="button-style"  (click)="openDialog()" >SUBMIT</button>
        &nbsp;&nbsp;
    </div>
</div>

<ng-template #callAPIDialog>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
          

        <div class="dialog-confirm">
            <h2>Are you sure you want to update this?</h2> 
            <!-- <span>  We will notify you when there is an update. </span> -->
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="EditUserManagement()">Yes</button>
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>
