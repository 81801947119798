<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage" class="loadSpinner">
</app-loading-spinner>
<div fxLayout="row" class="layout-setting">
  <!-- <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center center"> -->
  <mat-spinner class="loadSpinner" mode="indeterminate"  *ngIf="changesIsLoading"  diameter="40">
  </mat-spinner>
  <!-- </div> -->
  <div fxLayout="column" fxFlex="30%">
    <mat-card  *ngIf="!isLoading && formData" class="payment-page-preview payment-page-config">    
      <app-hosted-payment-page-config-setting
        [hostedPaymentpageFormGroup]="hostedPaymentpageFormGroup" [finzeoPaymentOption]="finzeoPaymentOption"></app-hosted-payment-page-config-setting>
    </mat-card>
  </div>
  <div fxLayout="column" fxFlex="70%">
    <mat-card *ngIf="!isLoading && formData" class="payment-page-preview payment-page-main-preview">      
      <app-hosted-payment-page-preview [changesIsLoading]="changesIsLoading" [finzeoPaymentOption]="finzeoPaymentOption" [formData]="formData"></app-hosted-payment-page-preview>      
    </mat-card>
    <br />
    <div div fxLayout="row" *ngIf="!isLoading" fxLayoutGap="1%" fxLayoutAlign="end" style="margin: 1%;">
      <div fxLayout="column" fxFlex="20%">
        <button mat-raised-button color="white" (click)="getDefaultHostedPaymentSettings()">RESET TO
          DEFAULT </button>
      </div>
      <div div fxLayout="column" fxFlex="10%">
        <button mat-raised-button color="primary" (click)="updatePaymentSettings()">SAVE </button>
      </div>
    </div>
  </div>
</div>