<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Instant Bank Analysis</div>
        <div class="page-sub-header-text-1">
          All individual related bank analysis can be managed from this page
        </div>
      </div>
    </div>

    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end" *ngIf="isAccess">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
          *ngIf = "this.userRole != '1'"
          mat-raised-button
          color="primary"
          class="page-header-button-style"
          (click)="AddInstantBankVerifyRequest()"
        >
          ADD
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAccess && !this.isLoading">
  <app-data-table
    [columns]="columns"
    [data]="data"
    [isLoading]="isLoading"
    [isLoadingPaging]="isLoadingPaging"
    [page]="page"
    [sortBy]="sortBy"
    [noRecordMessage]="noRecordMessage"
    [showMoreMenu]="true"
    [allColumns]="allColumns"
    [selection]="selection"
    [actionRefresh]="actionRefresh"
    [isExport]="false"
    [isFilter]="false"
    (actionRefreshEvent)="onRefresh($event)"
    (pageChangedEvent)="onPageChanged($event)"
    (rowClickedEvent)="navigateToCustomerRefresh($event)"
    (sortChangedEvent)="onSortChanged($event)"
    (onFilterEvent)="onFilter($event)"
    (ibvResendEvent)="ibvResend($event)"
    (ibvViewEvent)="ibvReportView($event)"
    [isInstantBankAnalysis]="true"
    [filterText]="this.filter?.toString() ?? ''"
    [role]="this.userRole"
  >
  </app-data-table>
</div>
<div *ngIf="!isAccess && !this.isLoading">
  <div class="page-header-text noaccess">
    You don't have access to this feature.
  </div>
</div>
