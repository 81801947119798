import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ViewChild,
  OnInit,
} from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
import { GradeScoreChartComponent } from 'src/app/sharedModules/components/feature-related/grade-score-chart/grade-score-chart.component';
import { GradeScoreChartModel } from 'src/app/sharedModules/components/feature-related/grade-score-chart/models/grade-score-chart.model';
import { GradeSettingsModel } from 'src/app/featureModules/components/settings-related/challenger-score/grade-setting/models/grade-settings.model';
import { CustomerChallengerScoreServiceProxy } from 'src/app/sharedModules/services/customer/customer.proxies';

@Component({
  selector: 'app-finzeo-grade',
  templateUrl: './finzeo-grade.component.html',
  styleUrls: ['./finzeo-grade.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FinzeoGradeComponent implements OnInit {
  isLoading = false;
  finzeoGradeDetails: any;
  gradeSettingsModel: GradeSettingsModel;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  private _gradeScoreChartModel: GradeScoreChartModel;
  public get gradeScoreChartModel(): GradeScoreChartModel {
    return this._gradeScoreChartModel;
  }
  public set gradeScoreChartModel(value: GradeScoreChartModel) {
    this._gradeScoreChartModel = value;
  }

  @ViewChild("gradeScoreChart", { static: true }) public gradeScoreChart: GradeScoreChartComponent;
  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _scoreSettingService: CustomerChallengerScoreServiceProxy
  ) { }

  ngOnInit(): void {
    this.buildGaugeChartModel();
  }

  @Output() loadFinzeoGradeCardChange = new EventEmitter<boolean>();
  @Input('loadFinzeoGradeCard')
  set loadFinzeoGradeCard(loadFinzeoGradeCard: boolean) {
    if (loadFinzeoGradeCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .FinZeoGradeDetails(
          this.ibvBankVerificationId,
          this.accountType,
        )
        .subscribe(
          (data) => {
            this.isLoading = false;

            if (data.data != null) {
              this.finzeoGradeDetails = data.data;
              this.finzeoGradeDetails.score =
                this.finzeoGradeDetails.score < 0
                  ? 0
                  : this.finzeoGradeDetails.score > 1000
                    ? 1000
                    : this.finzeoGradeDetails.score;
              this.buildGaugeChartModel();
              this._instantVerifyReportsService.FinzeoGradeCardAndScoreChange.emit(this.finzeoGradeDetails);
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );


    }

    this.loadFinzeoGradeCardChange.emit(false);
  }

  private buildGaugeChartModel() {
    this._scoreSettingService.getChallengerGradeSettings().subscribe({
      next: (resp: any) => {
        if (resp.code === 200) {
          this.gradeSettingsModel = resp.data as GradeSettingsModel;
          if (this.finzeoGradeDetails) {
            // build with score from record
            this.bindingGaugeChartModel(this.finzeoGradeDetails.score, this.finzeoGradeDetails.grade, this.gradeSettingsModel);
          } else {
            // build with score Zero
            this.bindingGaugeChartModel(0, 'F', this.gradeSettingsModel);
          }
        }
        else {
        }
      },
      error: (error: any) => {
      },
      complete: () => {
      }
    });
  }
  private bindingGaugeChartModel(score: number, grade: string, settings: GradeSettingsModel) {
    if (settings) {
      this.gradeScoreChartModel = new GradeScoreChartModel(settings.gradeSettings, score, grade);
    }
  }
}
