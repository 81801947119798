import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { maxNumber, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { InventoryCategoryService } from 'src/app/featureModules/services/inventory-category.service';
import { InventoryService } from 'src/app/featureModules/services/inventory.service';
import { AddInventoryCategoryModel } from 'src/app/sharedModules/models/add-Inventory-Category.model';
import { findInvalidControls } from 'src/app/sharedModules/utils/dev-utils';

@Component({
  selector: 'app-add-update-inventory-category',
  templateUrl: './add-update-inventory-category.component.html',
  styleUrls: ['./add-update-inventory-category.component.scss']
})
export class AddUpdateInventoryCategoryComponent implements OnInit {

  isLoading = false;
  loadMessage: string = '';
  addCategoryFormGroup: FormGroup;
  addInventoryCategoryModel: AddInventoryCategoryModel = new AddInventoryCategoryModel();
  categoryId: any;
  isEdit: boolean = false;
  
  discounttype = [
    { value: 'Percent', key: 'Percentage' },
    { value: 'Flat', key: 'Flat' },
  ];
  
  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private categoryService: InventoryCategoryService
  ) { 
    this.addCategoryFormGroup = this.formBuilder.formGroup(
      this.addInventoryCategoryModel
    );
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.categoryId = params.get('id');
    });
    this.getCategoryById();
  }

//   this.addCategoryFormGroup.get('discountType').valueChanges.subscribe(val => {
//     if (this.addCategoryFormGroup.get('discountType').value == 'Percentage') { // for setting validations
//       this.addCategoryFormGroup.get('discount').setValidators(maxNumber({ value:100}));
//     } 
//     else { // for clearing validations
//       this.addCategoryFormGroup.get('discount').clearValidators();
//     }
//     this.addCategoryFormGroup.get('discount').updateValueAndValidity();
// });

  getCategoryById() {
    if (!this.categoryId) return;
    this.categoryService
      .getCategoryById(this.categoryId)
      .subscribe((data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.isEdit = true;
          this.addInventoryCategoryModel = data.data;
          this.addCategoryFormGroup.patchValue(this.addInventoryCategoryModel);
          this.addCategoryFormGroup.markAsPristine();
          this.addCategoryFormGroup.markAsUntouched();
        }
      }, 
      (error) => this.showError(error));
  }

  addInventoryCategory() {
    this.isLoading = true;
    let client = JSON.parse(localStorage.getItem('userProfile')); 
    this.addInventoryCategoryModel.enteredby =
    client['firstName'] + ' ' + client['lastName'];

    findInvalidControls(this.addCategoryFormGroup);
    if (this.addCategoryFormGroup.valid) {
      this.categoryService.addInventoryCategory(this.addInventoryCategoryModel).subscribe(
        (data) => {
          if (data.code == 201 && data.data) {
            this.toastrService.success('Category added into inventory successfully!');
            this.isLoading = false;
            this.reset();
            this.navigateToBackPage();
          } else if (data.code == 400) {
            this.isLoading = false;
            this.toastrService.error(data.errorMessage);
          } else {
            this.isLoading = false;
          }
        },
      (error) => this.showError(error));
    } else {
      this.addCategoryFormGroup.markAllAsTouched();
      this.toastrService.error('Please fill all the required details!');
      this.isLoading = false;
    }
  }

  updateInventoryCategory() {
    this.isLoading = true;
    findInvalidControls(this.addCategoryFormGroup);
    if (this.addCategoryFormGroup.valid) {
      this.categoryService.updateInventoryCategory(this.categoryId,this.addCategoryFormGroup.value).subscribe(
        (data) => {
          if (data.code == 200) {
            this.toastrService.success('Inventory category updated successfully!');
            this.isLoading = false;
            this.reset();
            this.navigateToBackPage();
          } else if (data.code == 400) {
            this.isLoading = false;
            this.toastrService.error(data.errorMessage);
          } else {
            this.isLoading = false;
          }
        },
        (error) => this.showError(error));
    } else {
      this.addCategoryFormGroup.markAllAsTouched();
      this.toastrService.error('Please fill all the required details!');
      this.isLoading = false;
    }
    
  }

  selectDiscountType(event:any) {
    this.addCategoryFormGroup.controls['discountType'].setValue(event.value);
    this.addInventoryCategoryModel.discountType =  this.addCategoryFormGroup.value.discountType;
   }

  private showError(error: any) {
    if (error.error.message != null || error.error.message != undefined) {
      this.toastrService.warning(error.error.message);
    } else {
      this.toastrService.warning(error.error.content);
    }
    this.isLoading = false;
  }

  reset() {
    this.addInventoryCategoryModel = new AddInventoryCategoryModel();
    this.addCategoryFormGroup.reset();
    this.addCategoryFormGroup.markAsPristine();
    this.addCategoryFormGroup.markAsUntouched();
  }

  navigateToBackPage(){
    this.router.navigate(['other/',1]);
  }

  ngOnDestroy() {
    this.categoryId = null;
    this.addInventoryCategoryModel = null;
    this.isEdit = false;
    this.isLoading = false;
  }
}
