<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
        <button mat-icon-button (click)="navigateToPage()">
            <mat-icon>arrow_back </mat-icon>
        </button>
        Settings
    </mat-label>
</div>
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Invoice Email Setup</div>
        <div class="page-sub-header-text-1">
          All Invoice Email Setup related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>
<div class="email-setup-container">
  <div class="setting-inner-container">
    <form [formGroup]="emailSetupFormGroup">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="email-setup-form">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxFlex="65%" fxLayoutAlign="start stretch"
              class="emailTemplate">
              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch" class="email-setup-background">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                  <div fxLayout="column" fxFlex="50%">
                    <mat-label class="invoice-lbl">Invoice</mat-label>
                  </div>
                  <div fxLayout="column" fxFlex="50%" fxLayoutAlign="end end">
                    <div fxLayout="row">
                      <mat-label class="finzeo-lbl">CLIENT LOGO</mat-label>
                    </div>
                    <div fxLayout="row">
                      <mat-label class="inv-lbl">Invoice</mat-label>
                    </div>
                  </div>
                </div>
              </div>
              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="headerSection">
                  {{ emailSetupFormGroup.value.header }}
                </mat-label>
              </div>
              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="nameSection">
                  Dear First Name/Company Name,
                </mat-label>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start center">
                <div class="contentSection">
                  {{ emailSetupFormGroup.value.mailContent }}
                </div>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="invoiceDetailsSection">Invoice Details</mat-label>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <div fxLayout="row" class="infoSection" fxLayout.xs="column" fxLayoutAlign="start stretch"
                  fxLayoutGap="16px">
                  <div fxLayout="column" fxFlex="40%">
                    <mat-label class="infoLeftSection">Billed To</mat-label>
                  </div>
                  <div fxLayout="column">
                    <mat-label class="infoRightSection">: CustomerFullName</mat-label>
                  </div>
                </div>
                <div fxLayout="row" class="infoSection" fxLayout.xs="column" fxLayoutAlign="start stretch"
                  fxLayoutGap="16px">
                  <div fxLayout="column" fxFlex="40%">
                    <mat-label class="infoLeftSection">Invoice Number</mat-label>
                  </div>
                  <div fxLayout="column">
                    <mat-label class="infoRightSection">: Invoice Number</mat-label>
                  </div>
                </div>
                <div fxLayout="row" class="infoSection" fxLayout.xs="column" fxLayoutAlign="start stretch"
                  fxLayoutGap="16px">
                  <div fxLayout="column" fxFlex="40%">
                    <mat-label class="infoLeftSection">Invoice Amount</mat-label>
                  </div>
                  <div fxLayout="column">
                    <mat-label class="infoRightSection">: Invoice Amount</mat-label>
                  </div>
                </div>
                <div fxLayout="row" class="infoSection" fxLayout.xs="column" fxLayoutAlign="start stretch"
                  fxLayoutGap="16px">
                  <div fxLayout="column" fxFlex="40%">
                    <mat-label class="infoLeftSection">Due Date</mat-label>
                  </div>
                  <div fxLayout="column">
                    <mat-label class="infoRightSection">: Invoice Due Date</mat-label>
                  </div>
                </div>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <button mat-raised-button color="primary" class="buttonSection">
                  CLICK HERE TO PAY
                </button>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="thanksSection thanks-bottom">Thanks,</mat-label>
              </div>
              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="thanksSection">The Client Team</mat-label>
              </div>
              <br />

              <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-label class="footerSection">{{
                  emailSetupFormGroup.value.footer
                  }}</mat-label>
              </div>
              <br />
              <br />
            </div>
            <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxFlex="35%" fxLayoutAlign="start stretch ">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline">
                  <mat-label>Email Subject Line</mat-label>
                  <input matInput name="subject" class="form-control" formControlName="subject" />
                  <mat-error>
                    {{ emailSetupFormGroup.controls["subject"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline">
                  <mat-label>Content</mat-label>
                  <textarea autosize matInput name="mailContent" rows="4" formControlName="mailContent">
              </textarea>
                  <mat-error>
                    {{
                    emailSetupFormGroup.controls["mailContent"]["_errorMessage"]
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline">
                  <mat-label>Header Text</mat-label>
                  <input matInput name="header" class="form-control" formControlName="header" />
                  <mat-error>
                    {{ emailSetupFormGroup.controls["header"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline">
                  <mat-label>Footer Text</mat-label>
                  <input matInput name="footer" class="form-control" formControlName="footer" required />
                  <mat-error>
                    {{ emailSetupFormGroup.controls["footer"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end end" fxLayoutGap="16px">
                <div fxLayout="column">
                  <button mat-stroked-button color="primary" class="white-button-style" (click)="resetEmailSetupForm()">
                    RESET
                  </button>
                </div>
                <div fxLayout="column">
                  <button mat-raised-button color="primary" class="page-header-button-style"
                    (click)="saveEmailSetupSettings()">
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>