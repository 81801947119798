<!-- <button mat-flat-button color="primary" (click)="navigateToAch()">Login</button> -->
<div style="min-height: 100%;">
    <div fxLayout="column" fxFlex="40%" fxLayout.xs="column" fxLayoutAlign="start stretch" style="background-color: #032859; text-align: center;">
        <app-left-image></app-left-image>
    </div>

    <div fxLayout="column" fxFlex="60%" >
        <div fxLayout="column" fxFlex="30%"  >
            
            <div class="form-Signin">

                <div>
                    <div>
                        <p class="title">{{titlePassword}}</p>
                    </div>

                    <form [formGroup]="changePassForm"  (ngSubmit)="submit(changePassForm.value)">

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                            <mat-form-field appearance="outline">
                                <mat-label>New Password</mat-label>
                                <input
                                name="newPassword"
                                class="form-control"
                                formControlName="newPassword"
                                matInput
                                type="password"
                                required
                                [pattern]="selectedPattern"
                                />
                            </mat-form-field>
                        </div>

                        <mat-error *ngIf="f['newPassword'].errors  && f['newPassword'].touched && f['newPassword'].invalid" class="invalid-feedback">{{errorMgs}}</mat-error>

                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        
                            <mat-form-field appearance="outline" >
                                <mat-label>Confirm Password</mat-label>
                                <input
                                name="confirmPassword"
                                class="form-control"
                                formControlName="confirmPassword"
                                matInput
                                type="password"
                                required
                                />
                            </mat-form-field>
                        </div>
                        <div *ngIf="f['confirmPassword'].touched && f['confirmPassword'].invalid" class="invalid-feedback">
                            <div *ngIf="f['confirmPassword'].errors['confirmedValidator']">Password do not match.</div>
                        </div>
        
                        <div >
                            <button mat-flat-button color="primary" [disabled]="!changePassForm.valid"   style="width: 100%; height: 50px;" >SUBMIT</button>
                        </div> 
                    </form>
                    <button mat-button  class="btn-cancel"   (click)="cancelEvent()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>