import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {
  listSelection=[
    {name:'Individual',value:'Individual',checked:true},
    {name:'Company',value:'Company',checked:false},
    {name:'All',value:'All',checked:false}
  ]
    public hitDateTo:string;
    public hitDateFrom:string;
    public settleDateTo:string;
    public settleDateFrom:string;

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }
  export(){
    console.log('1',this.hitDateFrom)
    console.log('2',this.hitDateTo)
    console.log('1',this.settleDateFrom)
    console.log('2',this.settleDateTo)

  }
  closeDialog() {
    this.dialogRef.close('Cancel');
  }
}
