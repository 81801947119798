import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { MatStepper } from '@angular/material/stepper';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { InstantFundTransactionService } from 'src/app/featureModules/services/instantFund-transaction.service';
import { InstantFundTransactionModel } from 'src/app/featureModules/models/addinstantfund-transaction.model';

@Component({
  selector: 'app-add-instantfund-transaction',
  templateUrl: './add-instantfund-transaction.component.html',
  styleUrls: ['./add-instantfund-transaction.component.scss'],
})
export class AddInstantFundTransactionComponent
  implements OnInit, AfterViewInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  isLoading: boolean = false;
  selectedValue: string;
  selectedCar: string;
  isRecurring: boolean = false;
  customerId: any;
  customerModel: AddCustomerModel;
  existingCustomer: boolean = false;
  userName: string = '';
  userEmail: string = '';
  todaydate = new Date(new Date());
  defaultDate = new Date(new Date());
  
  //routing params
  previousUrl: string = '';
  selectedTransactionTabIndex: number;
  transactionIndex: number;
  tabIndex :number;

  @ViewChild('stepper') stepper: MatStepper;

  ngAfterViewInit() {
    if (this.existingCustomer) {
      this.firstFormGroup.controls['firstCtrl'].setValue('test');
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      });
    }
  }

  constructor(
    private _formBuilder: FormBuilder,
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _instantFundTransaction: InstantFundTransactionService,
    private _customerService: CustomerService
  ) {
    this.defaultDate.setDate(this.todaydate.getDate() + 1);

    const navigation = this.router.getCurrentNavigation();
    this.previousUrl = navigation?.extras?.state
      ? navigation.extras.state['url']
      : '';
    if (this.previousUrl != '')
      localStorage.setItem('previousUrl', this.previousUrl);
    else this.previousUrl = localStorage.getItem('previousUrl');

    this.selectedTransactionTabIndex = navigation?.extras?.state
      ? navigation.extras.state['selectedTransactionTabIndex']
      : 0;

    this.transactionIndex = navigation?.extras?.state
      ? navigation.extras.state['transactionIndex']
      : 0;

      this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
    });
    if (this.customerId) {
      this.getCustomerById();
      this.existingCustomer = true;
    }
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });

    let isModel = new InstantFundTransactionModel();
    this.secondFormGroup = this.formBuilder.formGroup(isModel);
  }

  navigateToTransactionsPage() {
    this.router.navigateByUrl(this.previousUrl, {
      state: {
        selectedTransactionTabIndex: this.selectedTransactionTabIndex,
        transactionIndex: this.transactionIndex,
        tabIndex: this.tabIndex
      }
    });
  }

  onCustomerSelection(customer: any) {
    if (customer != null) {
      this.customerId = customer.customerId;
      this.firstFormGroup.patchValue({ firstCtrl: customer.firstName });
      this.userName = customer.firstName;
      this.userName += customer.lastName ? ' ' + customer.lastName : '';
      this.userEmail = customer.email;
    } else {
      this.firstFormGroup.patchValue({ firstCtrl: '' });
      this.userName = '';
      this.userEmail = '';
    }
  }

  getCustomerById() {
    this.isLoading = true;
    this._customerService.getCustomerById(this.customerId).subscribe((data) => {
      this.isLoading = false;
      if (data.data != null) {
        this.customerModel = data.data;
        this.userName = this.customerModel.firstName;
        this.userName += this.customerModel.lastName ? ' ' + this.customerModel.lastName : '';
        this.userEmail = this.customerModel.email;
      }
    },
    (error) => {
      this.isLoading = false;
    });
  }

  addInstantFundTransaction() {
    this.isLoading = true;
    this.secondFormGroup.controls['customerId'].setValue(this.customerId);

    if (this.secondFormGroup.valid) {
      this._instantFundTransaction.CreateInstantFundTransaction(this.secondFormGroup.value).subscribe((data) => {
        this.isLoading = false;
        this._toastrService.success('Transaction Details Added Successfully');
        
        this.router.navigateByUrl(this.previousUrl, {
          state: {
            selectedTransactionTabIndex: this.selectedTransactionTabIndex,
            transactionIndex: this.transactionIndex,
            tabIndex: this.tabIndex
          }
        });
      },
      (error) => {
        this.isLoading = false;
      });
    }
    else {
      this.isLoading = false;
      this._toastrService.warning("Please enter the required fields!");
    }
  }

  onBlur() {
    if (+this.secondFormGroup.value.amount >= 0) {
      let val = this.secondFormGroup.value.amount ? (+this.secondFormGroup.value.amount).toFixed(2) : (0).toFixed(2);
      this.secondFormGroup.controls['amount'].setValue(val);
    }
  }
}
