<mat-card class="recurring-debits-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>

    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="transactionFilterFormGroup" style="width:100%">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
            class="form-group form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Recurring</mat-label>
              <mat-select
                class="form-control"
                formControlName="transaction"
                name="transaction"
              >
                <mat-option
                  *ngFor="let transaction of transactionList"
                  [value]="transaction.key"
                  >{{ transaction.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Credits Or Debits</mat-label>
              <mat-select name="transactionType" formControlName="transactionType">
                <mat-option
                  *ngFor="let transactionType of transactionTypeList"
                  [value]="transactionType.key"
                >
                  {{ transactionType.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>   
  <div
    fxLayout="row"
    fxLayoutAlign="start stretch"
    fxLayoutGap="16px"
    fxLayoutWrap
  >
    <app-data-table
      [columns]="allColumns"
      [data]="transactionDetails"
      [isLoading]="isLoading"
      [page]="page"
      [sortBy]="sortBy"
      [pageSizeOptions]="pageSizeOptions"
      [showMoreMenu]="false"
      [allColumns]="allColumns"
      [isExport]="false"
      [isFilter]="true"
      [isSearch]="true"
      (pageChangedEvent)="onPageChanged($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (advanceFilterEvent)="drawer.toggle()"
      (onFilterEvent)="onFilter($event)"
      [filterText]="this.filter?.toString() ?? ''"
      [advanceFilterCount]="advanceFilterCount"
      [isInstantBankAnalysis]="true"
      [noRecordMessage]="noRecordsMessage"
      [searchboxpadding]="seachboxPadding"
      [datatablemargin]="dataTableMargin"
    >
    </app-data-table>
  </div>
</mat-drawer-container>
</div>
