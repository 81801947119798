
import { FormGroup } from '@angular/forms';
import { GradeSettingItem } from '../grade-setting/models/grade-settings.model';
import { AddError } from './validateRuleErrors';

export function validateGradeSettings(gradeSettingsModel: GradeSettingItem[]){
    var validateErrors = [];
    if (gradeSettingsModel == null)
    {
        // return validateErrors.AddGeneralError("Invalid grade setting data.");
    }
    for (let i = 0; i < 5; i++)
    {
        if (gradeSettingsModel[i].from == null || gradeSettingsModel[i].to == null)
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have '0 <= from score <= to score'`));
            continue;
        }

        else if ("A" == gradeSettingsModel[i].grade && gradeSettingsModel[0].to != 1000)
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have 'to score' equal 1000`));
            continue;
        }
        else if ("F" == gradeSettingsModel[i].grade && gradeSettingsModel[i].from != 0)
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have 'from score' equals 0`));
            continue;
        }
        else if (gradeSettingsModel[i].from < 0 || gradeSettingsModel[i].to < 0 || gradeSettingsModel[i].from > gradeSettingsModel[i].to)
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have 'from score ' less than 'to score'`));
            continue;
        }

        else if (gradeSettingsModel[i].from == gradeSettingsModel[i].to)
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have 'from score' less than 'to score'`));
            continue;
        }

        else if (i > 0 && (gradeSettingsModel[i].to + 1) != gradeSettingsModel[i-1].from )
        {
            validateErrors.push(AddError(gradeSettingsModel[i].grade, `Grade ${gradeSettingsModel[i].grade} must have 'to score' less than 1 point of grade '` + gradeSettingsModel[i-1].grade + `' from score'`));
            continue;
        }
        
    }
    return validateErrors;
}
