import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { SendInvoiceModel } from 'src/app/sharedModules/models/send-invoice.model';

@Component({
  selector: 'app-send-by-sms',
  templateUrl: './send-by-sms.component.html',
  styleUrls: ['./send-by-sms.component.scss'],
})
export class SendBySmsComponent implements OnInit {
  @Input()
  RegMobileNo: string;

  @Output()
  MobileNoListEvent = new EventEmitter();

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneNumber;
  maxLength: string = '15';
  phonehint: any = '(201) 555-0123';
  sendInvoiceFormGroup: FormGroup;
  sendInvoiceModel: SendInvoiceModel;
  additonalNumber: string;
  
  constructor(
    private formBuilder: RxFormBuilder,
    private fb: FormBuilder,
    private _invoiceService: InvoiceService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {    
    this.sendInvoiceModel = new SendInvoiceModel();

    this.sendInvoiceModel.mobileNumberList = [];

    this.sendInvoiceModel.registeredNumber =
      this._invoiceService.invoiceServiceMainModel.registeredNumber;

      this.sendInvoiceFormGroup = this.formBuilder.formGroup(this.sendInvoiceModel); 
  }

  changePhone(event: any) {
    if ( event.iso2.toLowerCase() == this.sendInvoiceModel?.isoCode?.toLowerCase()
    ) {
      this.sendInvoiceFormGroup.controls['mobileNo'].setValue(
        this.sendInvoiceModel.mobileNumber
      );
    } 
    if (this.sendInvoiceFormGroup.controls['mobileNo'].value) {
      this.sendInvoiceFormGroup.controls['mobileNumber'].setValue(
        this.sendInvoiceFormGroup.controls['mobileNo'].value.e164Number
      );
      this.sendInvoiceFormGroup.controls['isoCode'].setValue(
        this.sendInvoiceFormGroup.controls['mobileNo'].value.countryCode
      );
    }
    else {
      this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
    }
    this.phonehint = event.placeHolder;
  }

  addNewMobileNos() {
    this.sendInvoiceModel.mobileNo =this.sendInvoiceFormGroup.controls['mobileNo'].value.e164Number;
    if (this.sendInvoiceFormGroup.controls['mobileNo'].value) {
      this.sendInvoiceFormGroup.controls['mobileNumber'].setValue(
        this.sendInvoiceFormGroup.controls['mobileNo'].value.e164Number
      );
      this.sendInvoiceFormGroup.controls['isoCode'].setValue(
        this.sendInvoiceFormGroup.controls['mobileNo'].value.countryCode
      );
    }
    else {
      this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
    }
    if (this.sendInvoiceFormGroup.controls['mobileNo'].valid && !this.sendInvoiceModel.mobileNo) 
    {
      this._toastrService.warning('Please enter additional mobile number !');
      this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
      this.sendInvoiceModel.mobileNo = '';
      return false;
    }
    if (!this.sendInvoiceFormGroup.controls['mobileNo'].valid) 
    {      
      return false;
    }
    if (this.sendInvoiceModel.mobileNo == this.sendInvoiceModel.registeredNumber) 
    {
      this._toastrService.warning('Please enter mobile number other than registered mobile number !');
      this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
      this.sendInvoiceModel.mobileNo = '';
      return false;
    }
    if (this.sendInvoiceModel.mobileNumberList.indexOf(this.sendInvoiceModel.mobileNo) < 0) 
    {
        if (this.sendInvoiceModel.mobileNumberList.length > 4) 
        {
          this._toastrService.warning('Only 5 additional mobile numbers are allowed !');
          return;        
        }
        this.sendInvoiceModel.mobileNumberList.push(this.sendInvoiceModel.mobileNo);
        this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
        this.sendInvoiceModel.mobileNo = '';
    }
    else 
    {
        this._toastrService.warning('Mobile number already exists in the list !');
        this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
        this.sendInvoiceModel.mobileNo = '';
        return false;
    }          
    this.MobileNoListEvent.emit(this.sendInvoiceModel.mobileNumberList);
    this._invoiceService.invoiceServiceMainModel.mobileNoList = this.sendInvoiceModel.mobileNumberList;
    this.sendInvoiceFormGroup.controls['mobileNo'].setValue('');
    this.sendInvoiceModel.mobileNo = ' ';
  }

  removeNewMobileNos(index: number): void {
    this.sendInvoiceModel.mobileNumberList.splice(index, 1);
  }
}
