import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import {
  DisplayBillingInvoiceModel,
  InvoiceBillingFilter,
} from 'src/app/sharedModules/models/billing-filter-model';
import { BillingService } from 'src/app/featureModules/services/billing-details.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;
@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.scss'],
})
export class BillingListComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  bill = new DisplayBillingInvoiceModel();
  isLoading = false;
  loadMessage: string = 'Please Wait ...';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  IsActive = null;
  countFilter: ListFilter;
  gotDataResponse = false;
  filteredCustomer: any[] = [];
  minLengthTerm = 3;
  isShowNoFound: boolean = false;
  customerId: string;
  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';
  userRole: any;

  private timeout: number;
  totalInvoices: number = 0;
  totalPaidAmount: number = 0;
  totalDueAmount: number = 0;
  failedPayment: number = 0;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  column: TableColumn[] = [
    { displayName: 'INVOICE NO.', name: 'invoiceNo', width: '13%' },
    { displayName: 'SCHEDULED DATE', name: 'scheduleDate', width: '28%' },
    {
      displayName: 'PERIOD',
      name: 'period',
      width: '22%',
    },
    { displayName: 'FREQUENCY', name: 'frequency', width: '18%' },
    { displayName: 'BILL AMOUNT', name: 'invoiceBillAmount', width: '18%' },
    { displayName: 'PAID AMOUNT', name: 'invoiceBillAmountPaid', width: '15%' },
    {
      displayName: 'DUE AMOUNT',
      name: 'billingDueAmount',
      width: '17%',
    },
    {
      displayName: 'NO. OF PRODUCTS',
      name: 'noOfProducts',
      width: '17%',
    },
    {
      displayName: 'PAYMENT STATUS',
      name: 'invoiceStatus',
      width: '17%',
    },
    {
      displayName: 'ACTION',
      name: 'raiseAction',
      width: '17%',
    },
  ];
  scheduledStats = [
    { key: 'All', value: 'ALL' },
    { key: 'FAILED', value: 'FAILED' },
    { key: 'ON HOLD', value: 'ONHOLD' },
    { key: 'SUCCESSFUL', value: 'SUCCESSFUL' },
    { key: 'PENDING', value: 'PENDING'},
    { key: 'INCOMPLETE', value: 'INCOMPLETE'},
    { key: 'SENT', value: 'SENT'}
  ];

  allColumns: TableColumn[];

  filterFormGroup: FormGroup;

  advanceFilters: string;
  advanceFiltersList: any;
  advanceFiltersList1: any;
  advanceFilterCount: number;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private router: Router,
    private _billingService: BillingService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private _toastrService: ToastrService
  ) {
    this.allColumns = this.column;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'billingListGridFilter',
      'billing',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'billingListGridPageIndex',
          'billing',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'billingListGridPageSize',
          'billing',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'billingListGridSortActive',
        'billing',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'billingListGridSortDirection',
        'billing',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'billingListAdvanceFilter',
      'billing',
      ''
    );
    if (this.advanceFilters !== '') {
      this.advanceFiltersList = this.advanceFilters.split('|');
      this.advanceFilterCount = this.advanceFiltersList.length;
    } else {
      this.advanceFilterCount = 0;
    }

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'billingListAdvanceFilterForm',
        'billing',
        JSON.stringify(new InvoiceBillingFilter())
      )
    );

    let filterModel = new InvoiceBillingFilter();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    this.selection.clear();
    this._billingService
      .getAllBillingInvoices(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();
          if (data.data != null) {
            this.data = data.data.invoices;
            this.page.length = data.data.totalRecords;
            var list = [];

            this.data?.map((item) => {
              let record = {
                id:item.clientInvoiceId,
                invoiceNo: item.invoiceNumber,
                scheduleDate: item.scheduleDate,
                period: item.fromDate + '-' + item.toDate,
                frequency: item.frequency,
                invoiceBillAmount: item.billAmount,
                invoiceBillAmountPaid: item.paidAmount,
                billingDueAmount: item.dueAmount,
                noOfProducts: item.noOfProducts,
                invoiceStatus: item.invoiceStatus,
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'billingListGridFilter',
        'billing'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'billingListGridPageSize',
        'billing'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'billingListGridPageIndex',
        'billing'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.column[event.active];
    var column = this.column.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'billingListGridSortActive',
        'billing'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'billingListGridSortDirection',
        'billing'
      );
    }
  }  

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      this.filterFormGroup = this.formBuilder.formGroup(
        new InvoiceBillingFilter()
      );
      this.filterFormGroup.patchValue({
        invoiceStatus: 'ALL'
      });
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'billingListGridPageSize',
          'billing',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'billingListGridSortActive',
        'billing',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'billingListGridSortDirection',
        'billing',
        'desc'
      ),
    };
    this.advanceFilters = '';

    if (data.scheduleStartDate && data.scheduleEndDate) {
      this.advanceFilters +=
        'sdate:' +
        getSafeIsoDateString(data.scheduleStartDate) +
        'to' +
        getSafeIsoDateString(data.scheduleEndDate) +
        '|';
    }

    if (data.invoiceBillFromAmount && data.invoiceBillToAmount) {
      if (
        parseInt(data.invoiceBillFromAmount) >=
        parseInt(data.invoiceBillToAmount)
      ) {
        this._toastrService.warning(
          'From Bill Amount Must be Less than To Bill Amount'
        );
        return;
      } else {
        this.advanceFilters +=
          'billAmount:' +
          data.invoiceBillFromAmount +
          'to' +
          data.invoiceBillToAmount +
          '|';
      }
    }

    if (data.invoiceStatus && data.invoiceStatus.toLowerCase() !== 'all') {
      this.advanceFilters += 'invoiceStatus:' + data.invoiceStatus + '|';
    }

   
    if (this.advanceFilters !== '') {
      this.advanceFilters = this.advanceFilters.slice(0, -1);
      this.advanceFiltersList = this.advanceFilters.split('|');
      this.advanceFilterCount = this.advanceFiltersList.length;
    } else {
      this.advanceFilterCount = 0;
    }

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'billingListAdvanceFilter',
      'billing'
    );

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'billingListAdvanceFilterForm',
      'billing'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  onInvoiceNumberClick(event :any ){
    this.router.navigate(['billing/billing-profile/', event.id]);
  }

  onRaiseATicketClick(event :any ){
    this.router.navigate(['ticket/create-ticket'],{
      state : {
        invoiceNumber : event.invoiceNo,
        categoryType : 'Billing'
      }
    });    
  }

  ngOnInit() {
      this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.GetAllBillingStats();
    this.filterEvent.subscribe(this.onFilter.bind(this));
  }

  GetAllBillingStats() {
    this._billingService
      .getAllBillingStats()
      .subscribe((data) => {
        if (data != null) {
          this.isLoading = false;
          this.initializeDisplayInvoiceDetailModel(data.data.billingStatsRecord);
        }
      });
  }
  
  initializeDisplayInvoiceDetailModel(response: any) {
    this.totalInvoices = response.totalInvoices;
    this.totalPaidAmount = response.totalPaidAmount;
    this.totalDueAmount = response.totalDueAmount;
    this.failedPayment = response.failedPayment;
  }
  
  clearSelection() {
    this.filterFormGroup.get('createdBy').setValue(null);
    this.filterFormGroup.get('createdById').setValue(null);
    this.filteredCustomer = [];
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if (!this.router.url.includes('billing'))
      localStorage.removeItem('billing');
  }

  public onRowClick(customer: any) {
    this.router.navigate(['billing/billing-profile', customer.customerId]);
  }
}
