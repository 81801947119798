import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from "rxjs";
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root',
})

export class HostedPaymentCustomizationService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_HOSTED_PAYMENT}HostedPaymentCustomization`);
  }

  getHostedPaymentCustomizationSettings(isDefault: any): Observable<any> {
    let baseUrl = this._base + "/getHostedPaymentCustomizationSettings/" + isDefault;
    return this._http.get<any>(baseUrl);
  }

  updatePaymentCustomizationSettings(model: any, status): Observable<any> {
   return this._http.put<any>(this._base + "/updatePaymentCustomizationSettings", model);
  }

  downloadUploadedFile(filePath: any, fileName: any, status): Observable<any> {
    let baseUrl = this._base + "/downloadUploadedFile?filePath=" + filePath + "&fileName=" + fileName;
    return this._http.post<any>(baseUrl, status);
  }

  uploadFile(file: File): Observable<any> {
    const payload = new FormData();
    payload.append('file', file);

    return this._http.post<any>(this._base + '/uploadAttachment', payload);
  }

  deleteFile(path: string): Observable<any> {
    return this._http.delete<any>(
      this._base + '/deleteUploadedLogo?filePath=' + path
    );
  }

}
