
<!-- <button mat-flat-button color="primary" (click)="navigateToAch()">Login</button> -->
<div style="min-height: 100%;">
    <div fxLayout="column" fxFlex="40%" fxLayout.xs="column" fxLayoutAlign="start stretch" style="background-color: #032859; text-align: center;">
        <app-left-image></app-left-image>
    </div>

    <div fxLayout="column" fxFlex="60%" >
        <div fxLayout="column" fxFlex="30%"  >
            
            <div class="form-Signin">

                <div>
                    <div>
                        <p class="title">Forgot Password</p>
                        <p >Provide your accounts email to receive a link to set a new password</p>
                        
                    </div>

                    <form [formGroup]="forgotPassForm" (ngSubmit)="submit(forgotPassForm.value)" >

                        <div *ngIf="!isAdminLogin" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                            <mat-form-field appearance="outline" >
                                <mat-label>Company Id</mat-label>
                                <input
                                name="company"
                                class="form-control"
                                formControlName="company"
                                matInput
                                maxlength="9"
                                required
                                />
                            </mat-form-field>
                        </div>
        
                        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                            <mat-form-field appearance="outline">
                                <mat-label>Email ID</mat-label>
                                <input
                                name="email"
                                class="form-control"
                                formControlName="email"
                                matInput
                                type="email"
                                required
                                />
                            </mat-form-field>
                        </div>

                        <div >
                            <button mat-flat-button color="primary" [disabled]="!forgotPassForm.valid"   style="width: 100%; height: 50px;" >SUBMIT</button>

                            <button mat-button  class="btn-cancel"   (click)="cancelEvent()">Cancel</button>
                        </div> 
                    </form>
                    
                </div>
            </div>
        </div>
    </div>
</div>