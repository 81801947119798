<mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
    <mat-tab label="ACH">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-dashboard-ach-transaction-report *ngIf="loadAchTransaction" [period]="period">
                </app-dashboard-ach-transaction-report>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="CREDIT/DEBIT">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-dashboard-creditdebit-transaction-report *ngIf="loadCreditDebit" [period]="period">
                </app-dashboard-creditdebit-transaction-report>
            </div>
        </div>
    </mat-tab>
    <!-- hiding the INF feature -->
    <!-- <mat-tab label="INSTANT FUND">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-dashboard-instantfund-transaction-report *ngIf="loadInstantFund" [period]="period">
                </app-dashboard-instantfund-transaction-report>
            </div>
        </div>
    </mat-tab> -->
    <mat-tab label="E-CHECK">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="container-div">
            <div fxLayout="column" fxFlex="100%">
                <app-dashboard-echeck-transaction-report *ngIf="loadLoadEcheck" [period]="period">
                </app-dashboard-echeck-transaction-report>
            </div>
        </div>
    </mat-tab>
    <mat-tab disabled></mat-tab>
</mat-tab-group>