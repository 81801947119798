import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-invoice-dashboard-total-amount-tabs',
  templateUrl: './invoice-dashboard-total-amount-tabs.component.html',
  styleUrls: ['./invoice-dashboard-total-amount-tabs.component.scss']
})
export class InvoiceDashboardTotalAmountTabsComponent implements OnInit {
  loadPaid: boolean = true;
  loadUpaid: boolean;
  loadPastDue: boolean;
  loadReturned: boolean;

  @Input()
  period : string;

  constructor() { }

  ngOnInit(): void { }

  onTabChange(event: MatTabChangeEvent) {
    switch (event.tab.textLabel) {
      case 'Paid':
        this.loadPaid = true;
        break;
      case 'Un-Paid':
        this.loadUpaid = true;
        break;
      case 'Past Due':
        this.loadPastDue = true;
        break;
      case 'Returned':
        this.loadReturned = true;
        break;
    }
  }
}
