<mat-tab-group fxFlex="100%" animationDuration="0ms">

    <mat-tab label="DESIGN CUSTOMIZATION">
      <app-design-customisation  [hostedPaymentpageFormGroup]="hostedPaymentpageFormGroup"></app-design-customisation>
    </mat-tab>

    <mat-tab label="CONFIGURATION">
      <app-configuration  [finzeoPaymentOption]="finzeoPaymentOption"  [hostedPaymentpageFormGroup]="hostedPaymentpageFormGroup" ></app-configuration>
    </mat-tab>
    <!-- <mat-tab disabled></mat-tab> -->
  </mat-tab-group>
