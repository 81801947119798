import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-non-mfa-login-frame',
  templateUrl: './non-mfa-login-frame.component.html',
  styleUrls: ['./non-mfa-login-frame.component.scss']
})
export class NonMfaLoginFrameComponent implements OnInit {

  uniqueToken: string;
  clientId: string;
  emailId: string;
  isLoading = false;
  src: string;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.emailId = this.activatedRoute.snapshot.paramMap.get('emailId');
    this.uniqueToken = this.activatedRoute.snapshot.paramMap.get('token');
    this.src = "https://finzeo-qa-v2-web-frontend.azurewebsites.net/non-mfa/"+this.clientId+"/"+this.emailId+"/"+this.uniqueToken;
  }

}
