import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {

  isLoading:boolean =false;
  loadMessage:string ="Please wait ...."
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToInvoiceDashboardPage(){
    this.router.navigate(['invoices']);
  }

  onPrintClicked(){

  }

}
