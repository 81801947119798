<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>
<form [formGroup]="form" class="form-group" >
    <div fxLayout="column" fxLayoutAlign="start stretch">
        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start stretch">
            <div fxLayout="column">
                <div class="Header-Title">
                    Numerical Score to Alphabetical Grade Settings
                </div>
            </div>
        </div>

        <div fxLayout="row" fxLayoutAlign="start stretch" class="gradeSetting">
            <div fxLayout="column" fxFlex="65%" >
                <div *ngFor="let field of this.formTemplate; let i = index">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutWrap fxLayoutAlign="start center" >
                        <label>From score</label>
                        <mat-form-field fxFlex="100px" appearance="outline">
                            <input type="number" min="0" max="1000" matInput [formControlName]="field.From" title="" placeholder="" (change)="onValueChanged()"/>
                        </mat-form-field>
                        <label>to score</label>
                        <mat-form-field fxFlex="100px" appearance="outline">
                            <input type="number" min="0" max="1000" matInput [formControlName]="field.To" title="" placeholder="" (change)="onValueChanged()"/>
                        </mat-form-field>
                        <label>then grade</label>
                        <mat-form-field fxFlex="100px" appearance="outline" class="fieldGrade">
                            <input type="text" matInput [formControlName]="field.Grade" title="" placeholder="" readonly/>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" *ngIf="this.validateErrors != null">
                        <div *ngFor="let error of this.validateErrors;">
                            <mat-error class="invalid-feedback" *ngIf = "lstGrade[i] == error[0].key" > {{error[0].value}} </mat-error>
                        </div>
                        
                    </div>
                </div>
                
            </div>


            <div fxLayout="column" fxFlex="35%" class="chart" *ngIf = "flagChart">
                <grade-score-gauge-chart 
                    [model]="gradeScoreChartModel">
                </grade-score-gauge-chart>
            </div>

        </div>

        <div class="span" fxFlex="100%" fxLayout="row">
            <div fxFlex="50%" fxLayoutAlign="start">
                <button type="button" mat-stroked-button class="button-style" (click)="openDialog()">Restore</button>
                <div fxFlex="10px"></div>
                <button type="button" mat-stroked-button class="button-style" (click)="openDialogReset()">Reset</button>
            </div>
            <div fxFlex="50%" fxLayoutAlign="end">
                <button type="button" mat-stroked-button class="button-style" [disabled]="!canSave" (click)="saveRules()">Save</button>
            </div>
        </div>
    </div>
</form>

<ng-template #callAPIDialog>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
          

        <div >
            <h3>We will restore your settings to Global Settings as default.</h3> 
            <h3>Continue to restore?</h3> 
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="restoreRules()">Yes</button> 
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>

<ng-template #callAPIDialogReset>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
          

        <div >
            <h3>We will reset Grade Settings to the values before edit.</h3> 
            <h3>Continue to Reset?</h3> 
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="resetRules()">Yes</button> 
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>