<div  fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
<mat-card fxFlex="50%" class="invoice-overview">
    <mat-spinner *ngIf="isLoading" style="margin:0 auto;" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
        {{loadMessage}}
    </mat-spinner>

    <!-- <div *ngIf="!isLoading" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch"> -->
        <div fxLayout="column" fxLayoutWrap>
            <span class="compareYearLabel">{{periodLastLabel}}</span>
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px" class="topContainer">
                <div fxLayout="column" fxFlex="49%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Invoices" class="summaryIcons"
                                src="assets/grid-icons/totalTransactions.png" />
                        </div>
                        <div fxLayout="column" class="center-values">
                            <span class="amountCls">{{totalInvoiceCount}} 
                                <!-- <div *ngIf="isLastYearGreaterThanCurrent" class="triangle triangle-4"></div>
                                <div *ngIf="!isLastYearGreaterThanCurrent" class="triangle triangle-5"></div> -->
                            </span>
                            <span class="amountLblCls">Total Invoices</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="49%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Invoices Amount" class="summaryIcons"
                            src="assets/grid-icons/totalAmount.png" />
                        </div>
                        <div fxLayout="column" class="center-values">
                            <span class="amountCls">${{(totalInvoiceAmount | number: "1.0-2")}}</span>
                            <span class="amountLblCls">Total Amount</span>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    <!-- </div> -->
</mat-card>

<!-- <mat-divider [vertical]="true" class="divider-style"></mat-divider> -->

<mat-card fxFlex="50%" class="invoice-overview">
            <mat-spinner *ngIf="isLoading" style="margin:0 auto;"mode="indeterminate" [diameter]="50" [strokeWidth]="3">
                {{loadMessage}}
            </mat-spinner>
        
    
        <div fxLayout="column"  fxLayoutWrap>
            <span class="currentYearLabel">{{periodCurrentLabel}}</span>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start center"
                class="topContainer">
                <div fxLayout="column" fxFlex="49%" fxLayoutWrap>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Invoices Count" class="summaryIcons"
                            src="assets/grid-icons/totalTransactions.png" />
                        </div>
                        <div fxLayout="column" class="center-values">
                            <span class="amountCls">{{currentTotalInvoiceCount}} 
                                <!-- <div *ngIf="!isLastYearGreaterThanCurrent" class="triangle triangle-4"></div> -->
                                <!-- <div *ngIf="isLastYearGreaterThanCurrent" class="triangle triangle-5"></div> -->
                            </span>
                            <span class="amountLblCls">Total Invoices</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="49%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Invoices Amount" class="summaryIcons"
                            src="assets/grid-icons/totalAmount.png" />
                        </div>
                        <div fxLayout="column" class="center-values">
                            <span class="amountCls">${{(currentTotalInvoiceAmount | number: "1.0-2")}}</span>
                            <span class="amountLblCls">Total Amount</span>
                        </div>
                    </div>
                </div>                
            </div>
       </div>
</mat-card>
</div>
