import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';
import { DesignTabControlModel, PaymentLinkCreationModel, PricingTabControlModel, SettingTabControlModel } from 'src/app/featureModules/models/payment-link-creation.model';
import { HostedPaymentService } from 'src/app/featureModules/services/hosted-payment.service';

@Component({
  selector: 'app-hosted-payment-link-configuration',
  templateUrl: './hosted-payment-link-configuration.component.html',
  styleUrls: ['./hosted-payment-link-configuration.component.scss']
})
export class HostedPaymentLinkConfigurationComponent implements OnInit {

  isLoading: boolean = false;
  loadMessage: string = 'Please Wait...'
  public paymentLinkFormGroup: FormGroup;
  public paymentLinkCreationModel: PaymentLinkCreationModel
  linkId: any;
  buttonIsLoading: boolean;

  constructor(private router: Router,
    private formBuilder: RxFormBuilder,
    private activatedRoute: ActivatedRoute,
    private _hostedPaymentService: HostedPaymentService,
    private _toastrService: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.linkId = params.get('id');
    });

    let paymentLinkCreationModel = new PaymentLinkCreationModel();
    paymentLinkCreationModel.designTabForm = new DesignTabControlModel();
    paymentLinkCreationModel.settingTabForm = new SettingTabControlModel();
    paymentLinkCreationModel.pricingTabForm = new PricingTabControlModel();

    this.paymentLinkFormGroup = this.formBuilder.formGroup(paymentLinkCreationModel);

    Object.keys(paymentLinkCreationModel).forEach(formName => {
      Object.keys(paymentLinkCreationModel[formName]).forEach(formKey => {
        this.paymentLinkFormGroup.get(formName).get(formKey).valueChanges.
          pipe(
            distinctUntilChanged(),
            filter(() => {
              if(formName != 'settingTabForm' && formName != 'pricingTabForm')
              this.buttonIsLoading = true;
              return true;
            }),
            distinctUntilChanged(),
            debounceTime(1000),
            tap(() => {
              this.buttonIsLoading = false;
            })).
          subscribe(() => {
            this.buttonIsLoading = false;
          });
      });
    });

    this.paymentLinkDetails();
  }
  
  public paymentLinkDetails() {
    this.isLoading = true;
    if (!this.linkId) {
      this.isLoading = false;
      this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkType'].setValue('Button');
      return;
    }
    this.isLoading = true;
    this._hostedPaymentService
      .getHostedPaymentLinkById(this.linkId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null && data.data.linkDetails != null) {
            this.paymentLinkFormGroup.patchValue(JSON.parse(data.data.linkDetails));
          }
          else {
            this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkType'].setValue('Button');
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  navigateToBackPage() {
    this.router.navigate(['setting/hosted-payment-link']);
  }
}
