<div class="row" fxLayoutGap="3%">
    <div fxLayout="column" fxFlex="15%" class="container" fxLayoutWrap (click)="onPageChange('1')">
        <app-hosted-payment-page-order [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
            [isSmallView]="isSmallView"  [formData]="formData"></app-hosted-payment-page-order>
    </div>
    <div fxLayout="column" fxFlex="15%" class="container" fxLayoutWrap (click)="onPageChange('2')">
        <app-hosted-payment-page-information [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
            [isSmallView]="isSmallView"  [formData]="formData"></app-hosted-payment-page-information>
    </div>
    <div fxLayout="column" fxFlex="15%" class="container" fxLayoutWrap (click)="onPageChange('3')">
        <app-hosted-payment-page-success [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
            [isSmallView]="isSmallView"  [formData]="formData"></app-hosted-payment-page-success>
    </div>
</div>