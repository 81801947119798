import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-challenger-score-settings',
  templateUrl: './challenger-score-settings.component.html',
  styleUrls: ['./challenger-score-settings.component.scss']
})
export class ChallengerScoreSettingsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }

}
