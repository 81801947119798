import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ChartConfiguration,
  ChartData,
  ChartEvent,
  ChartType,
  ChartOptions,
  ChartDataset,
} from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-labels';
import { BaseChartDirective } from 'ng2-charts';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-total-transactions',
  templateUrl: './dashboard-total-transactions.component.html',
  styleUrls: ['./dashboard-total-transactions.component.scss'],
})
export class DashboardTotalTransactionsComponent implements OnInit, OnChanges {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';
  chartData: any = [];
  chartLabelCurrent: string = 'This Year';
  chartLabelLast: string = 'Last Year';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() period;

  constructor(
    private _dashboardService: DashboardService,
    private _toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.getTotalTransactionsAmountByService(this.period);
  }

  ngOnInit(): void {}

  getTotalTransactionsAmountByService(period) {
    this.isLoading = true;

    this._dashboardService.GetAmountByService(period).subscribe(
      (data) => {
        this.isLoading = false;
        if (period.toLowerCase() == 'weekly') {
          this.chartLabelCurrent = 'This Week';
          this.chartLabelLast = 'Last Week';
        } else if (period.toLowerCase() == 'monthly') {
          this.chartLabelCurrent = 'This Month';
          this.chartLabelLast = 'Last Month';
        } else {
          this.chartLabelCurrent = 'This Year';
          this.chartLabelLast = 'Last Year';
        }
        if (data.data != null) {
          this.chartData = [];
          this.chartData.push(
            {
              data: [
                data.data.last.ach,
                data.data.last.cards,
                data.data.last.eCheck,
                //data.data.last.instantFund,
              ],
              label: this.chartLabelLast,
            },
            {
              data: [
                data.data.current.ach,
                data.data.current.cards,
                data.data.current.eCheck,
                //data.data.current.instantFund,
              ],
              label: this.chartLabelCurrent,
            }
          );
          this.barChartData.datasets = this.chartData;
          this.chart?.update();
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
        max: 100,
        ticks: {
          // Include a percentrage sign in the ticks
          callback: function (value, index, ticks) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              tooltipItem.dataset.label +
              ': ' +
              tooltipItem.formattedValue +
              '%'
            );
          },
        },
      },
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData<'bar'> = {
     // hiding the INF feature
    //labels: ['ACH', 'Credit/Debit', 'E-Check', 'Instant Fund'],
    labels: ['ACH', 'CREDIT/DEBIT', 'E-CHECK'],
    datasets: this.chartData,
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    // console.log(event, active);
  }

  navigateToReportPage() {
    this.router.navigate(['dashboard/dashboard-report']);
  }
}
