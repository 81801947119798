<div class="page-design">
    <form [formGroup]="hostedPaymentpageFormGroup">
        <div class="form-group" formGroupName="customisationTabForm" class="full-height">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
                class="description-top">
                <div fxLayout="column" class="inner-stepper-text" fxLayoutAlign="center start">
                    Logo :
                </div>
                <div fxLayout="column" class="upload-box">
                    <label color="primary" class="file-select">
                        UPLOAD LOGO
                        <input id="logo" name="logo" id="logo" type="file" class="file-input"
                            (change)="onFileSelected($event)" #fileUpload placeholder="Upload Attachment"
                            accept=".jpg,.jpeg,.png">
                    </label>
                </div>                
            </div>
            <div class="m-l-70" >
                <mat-hint class="hint-style">Size- W430*H134px;Images must be either jpg,jpeg,png</mat-hint>
            </div>         

            <div fxLayout="row" fxLayout.xs="column" *ngFor="let file of this.uploadedFilelist"
                fxLayoutAlign="center stretch" class="description-top deleteBox">
                <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    fxFlex="65%" class="file-upload-box">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px"
                        fxLayoutAlign="start stretch" class="div-padding">
                        <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px"
                            fxLayoutAlign="start stretch" fxFlex="80%">
                            <div class="file-name">
                                <span> {{ file.name }}</span>
                            </div>
                            <div class="file-size">
                                <span> {{ file.size | formatFileSize}}</span>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="end end" fxFlex="20%">
                            <img class="deleteImg" matTooltip="Remove Attachment" alt="deleteimg"
                                src="{{ delete_const | showIcon }}" (click)="onFileDeleted(file)" />
                        </div>
                    </div>
                   <!--  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxFlex="100%"
                        fxLayoutAlign="start stretch" class="file-progress-bar">
                    </div> -->
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
                class="description-top">
                <div fxLayout="column" fxFlex="10%" class="inner-stepper-text">
                    Theme:
                </div>
                <div fxLayout="column">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="40%">Color :</div>
                        <div fxLayout="column" fxFlex="10%">
                            <input [(colorPicker)]="themeColor" name="themeColor" [cpOutputFormat]="'hex'"
                                [cpPosition]="'bottom'" formControlName="themeColor"
                                [style.background]="this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeColor'].value"
                                class="color-picker-box" (colorPickerChange)="onThemeColorChange($event)"
                                [style.color]="this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeColor'].value" />
                        </div>
                        <div fxLayout="column">
                            <mat-label class="color-code">{{
                                this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeColor'].value
                                }}</mat-label>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" class="top-margin-gap">
                        <div fxLayout="column" fxFlex="40%">Text Color :</div>
                        <div fxLayout="column" fxFlex="10%">
                            <input [(colorPicker)]="themeTextColor" name="themeTextColor"
                                formControlName="themeTextColor" [cpOutputFormat]="'hex'" [cpPosition]="'bottom'"
                                (colorPickerChange)="onTextColorChange($event)"
                                [style.background]="this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeTextColor'].value"
                                [style.color]="this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeTextColor'].value"
                                class="color-picker-box" />
                        </div>
                        <div fxLayout="column">
                            <mat-label class="color-code">{{
                                this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['themeTextColor'].value
                                }}</mat-label>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
                        <div fxLayout="column" fxFlex="40%" fxLayoutAlign="center start">Font :</div>
                        <div fxLayout="column" fxFlex="50%">
                            <mat-form-field appearance="outline">
                                <mat-select class="form-control" formControlName="themeFont" name="themeFont">
                                    <mat-option *ngFor="let font of fontFamilyArray" [value]="font.key">
                                        <mat-label style="font-family: '{{ font.value }}'">{{ font.value }}</mat-label>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>