import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss'],
})
export class FlagsComponent {
  isLoading = false;
  flagsDetails: any;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  isPotentialLoansListPresent = false;
  isPotentialEmployerListPresent = false;
  isPotentialLoansAmountListPresent = false;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  @Output() loadFlagsCardChange = new EventEmitter<boolean>();
  @Input('loadFlagsCard')
  set loadFlagsCard(loadFlagsCard: boolean) {
    if (loadFlagsCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .FlagsDetails(
          this.ibvBankVerificationId,
          this.accountType,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data != null) {
              this.flagsDetails = data.data;
              this._instantVerifyReportsService.FlagAndLoanDataChange.emit(this.flagsDetails);
              this.isPotentialEmployerListPresent =
                this.flagsDetails?.potentialEmployerList?.length == 0
                  ? false
                  : true;

              this.isPotentialLoansListPresent =
                this.flagsDetails?.potentialLoansList?.length == 0
                  ? false
                  : true;

              this.isPotentialLoansAmountListPresent =
                this.flagsDetails?.potentialLoansAmountList?.length == 0
                  ? false
                  : true;
            }
          },
          (error) => {
            this.isLoading = false;
            this._instantVerifyReportsService.FlagAndLoanDataChange.emit(null);
          }
        );
    }

    this.loadFlagsCardChange.emit(false);
  }
}
