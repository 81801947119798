<span mat-dialog-title>{{ data.title }}</span>
<mat-dialog-content class="content">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="start stretch"
    class="main-message"
    [innerHtml]="data.mainMessage"
  ></div>
  <div
    *ngIf="data.subMessage"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="start stretch"
    class="sub-message"
    [innerHtml]="data.subMessage"
  ></div>
</mat-dialog-content>
<mat-dialog-actions align="end" >
  <button
    *ngIf="!data.showDisagree"
    class="btnDisAgree"
    mat-button
    [style.color]="data.color"
    [style.border-color]="data.color"
    [mat-dialog-close]="false"
  >
    DECLINE
  </button>
  <button
    class="btnAgree"
    mat-button
    [style.background-color]="data.color"
    [mat-dialog-close]="true"
  >
    ACCEPT
  </button>
</mat-dialog-actions>
