import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-preview',
  templateUrl: './button-preview.component.html',
  styleUrls: ['./button-preview.component.scss']
})
export class ButtonPreviewComponent implements OnInit {

  @Input()
  formData: any;

  @Input()
  isLoading: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.isLoading = false;
  }
}
