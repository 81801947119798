<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>

    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
          >
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusAll"
                (change)="statusChangeEvent('all')"
                >All</mat-checkbox
              >
            </section>
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusPaid"
                (change)="statusChangeEvent('paid')"
                >Paid</mat-checkbox
              >
            </section>
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusUnpaid"
                (change)="statusChangeEvent('unpaid')"
                >Unpaid</mat-checkbox
              >
            </section>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
          >
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusSent"
                (change)="statusChangeEvent('sent')"
                >Sent</mat-checkbox
              >
            </section>
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusOverdue"
                (change)="statusChangeEvent('overdue')"
                >PastDue</mat-checkbox
              >
            </section>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
          >
            <section style="font-size: 15px" fxFlex="33%">
              <mat-checkbox
                formControlName="statusPending"
                (change)="statusChangeEvent('pending')"
                >Pending</mat-checkbox
              >
            </section>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label>Created Date</mat-label>
              <mat-date-range-input [rangePicker]="createdPicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="createdStartDate"
                  name="createdStartDate"
                  readonly
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="createdEndDate"
                  name="createdEndDate"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="createdPicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #createdPicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label>Period</mat-label>
              <mat-date-range-input [rangePicker]="periodsPicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="periodStartDate"
                  name="periodStartDate"
                  readonly
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="periodEndDate"
                  name="periodEndDate"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="periodsPicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #periodsPicker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field appearance="outline">
              <mat-label>Due Date</mat-label>
              <mat-date-range-input [rangePicker]="duedPicker">
                <input
                  matStartDate
                  placeholder="Start date"
                  formControlName="dueStartDate"
                  name="dueStartDate"
                  readonly
                />
                <input
                  matEndDate
                  placeholder="End date"
                  formControlName="dueEndDate"
                  name="dueEndDate"
                  readonly
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="duedPicker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #duedPicker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field class="doller-icon-position" appearance="outline">
              <mat-label>From Amount</mat-label>
              <input
                type="text"
                matInput
                formControlName="amountRangeFrom"
                placeholder="Amount Range From"
                appTwoDigitDecimalNumber
              />
              <span matPrefix>$&nbsp;&nbsp;</span>
            </mat-form-field>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            class="form-field-bottom"
          >
            <mat-form-field class="doller-icon-position" appearance="outline">
              <mat-label>To Amount</mat-label>
              <input
                type="text"
                matInput
                formControlName="amountRangeTo"
                placeholder="Amount Range To"
                appTwoDigitDecimalNumber
              />
              <span matPrefix>$&nbsp;&nbsp;</span>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div>
      <app-data-table
        [columns]="columns"
        [data]="gridData"
        [isLoading]="isLoading"
        [isLoadingPaging]="isLoadingPaging"
        [page]="page"
        [sortBy]="sortBy"
        [noRecordMessage]="noRecordMessage"
        [showMoreMenu]="true"
        [allColumns]="allColumns"
        [selection]="selection"
        [actionRefresh]="actionRefresh"
        (actionRefreshEvent)="onRefresh($event)"
        (pageChangedEvent)="onPageChanged($event)"
        (sortChangedEvent)="onSortChanged($event)"
        (onFilterEvent)="onFilter($event)"
        [isFilter]="isFilter"
        [isExport]="false"
        [isAddBtn]="false"
        [isImportBtn]="false"
        (advanceFilterEvent)="drawer.toggle()"
        [isViewInvoicePage]="true"
        (viewInvoiceChangeEvent)="viewInvoiceChange($event)"
        [filterText]="this.filter?.toString() ?? ''"
        [advanceFilterCount]="advanceFilterCount"
      >
      </app-data-table>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
