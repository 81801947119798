import { maxLength, pattern, prop } from "@rxweb/reactive-form-validators";

export class achTransactionsFilterModel {
  @prop()
  status: string;

  @prop()
  scheduledStartDate: string;

  @prop()
  scheduledEndDate: string;

  @prop()  
  processedStartDate: string;

  @prop()  
  processedEndDate: string;

  @prop()  
  dateRange: string;

  @prop()  
  amountRange: string;
  @prop()  
  entryType: string;
  
}
