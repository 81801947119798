import { maxLength, pattern, prop } from "@rxweb/reactive-form-validators";

export class CustomerFilterModel {
  @prop()
  type: string;

  @prop()
  state: string;

  @prop()
  @maxLength({ value: 29 })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  city: string;

  @prop()
  @maxLength({ value: 5 })
  zip: number;
  
}
