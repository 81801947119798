import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddCustomerCardDetailsModel } from 'src/app/sharedModules/models/add-customer-card-details.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerRefreshDetailsService extends CrudService<
  AddCustomerCardDetailsModel,
  any
> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_IBV}IBVCustomerProfile`);
  }

  GetIBVRefreshReceiptListForCustomer(
    customerId: any,
    ibvReqibvBankVerificationId: any,
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    let baseUrl =
      this._base +
      '/GetIBVRefreshReceiptListForCustomer?customerId=' +
      customerId +
      '&ibvBankVerificationId=' +
      ibvReqibvBankVerificationId +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize;
    baseUrl = filterBy ? baseUrl + '&filterBy=' + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + '&sortBy=' + sortBy : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  RefreshTransactions(ibvVerificationId: any): Observable<any> {
    return this._http.post<any>(
      this._base + '/RefreshTransactions/' + ibvVerificationId,
      null
    );
  }

  RefreshBankBalance(ibvVerificationId: any): Observable<any> {
    return this._http.post<any>(
      this._base + '/RefreshBankBalance/' + ibvVerificationId,
      null
    );
  }

  RefreshAll(ibvVerificationId: any): Observable<any> {
    return this._http.post<any>(
      this._base + '/RefreshAll/' + ibvVerificationId,
      null
    );
  }
}
