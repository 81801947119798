<!-- <button mat-flat-button color="primary" (click)="navigateToAch()">Login</button> -->
<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>
<div style="min-height: 100%">
  <div
    fxLayout="column"
    fxFlex="40%"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    style="background-color: #032859; text-align: center"
  >
    <app-left-image></app-left-image>
  </div>

  <div fxLayout="column" fxFlex="60%">
    <div fxLayout="column" fxFlex="30%">
      <div class="form-Signin">
        <div>
          <div>
            <p class="title">Sign In</p>
          </div>

          <form [formGroup]="signInForm" (ngSubmit)="doLogin(signInForm.value)">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
            >
              <mat-form-field appearance="outline">
                <mat-label>Email ID</mat-label>
                <input
                  name="email"
                  class="form-control"
                  formControlName="email"
                  matInput
                  type="email"
                  value=""
                  autocomplete="new-password"
                  required
                />
              </mat-form-field>
              <!-- [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"  -->
            </div>

            <!-- An error message will appear, "Invalid Password" -->
            <mat-error
              class="invalid-feedback"
              *ngIf="
                signInForm.errors ? signInForm.errors['unauthenticated'] : false
              "
            >
              <p>Invalid Email ID or Password entered. Please try again.</p>
            </mat-error>

            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              style="padding-bottom: 0px !important"
            >
              <mat-form-field
                appearance="outline"
                style="padding-bottom: 0px !important"
              >
                <mat-label>Password</mat-label>
                <input
                  style="padding-bottom: 0px !important"
                  name="password"
                  class="password-input custom-password-input"
                  formControlName="password"
                  matInput
                  [type]="showPassword ? 'text' : 'password'"
                  value=""
                  autocomplete="new-password"
                  required
                />
                <mat-icon *ngIf="!enableResetCredentialButton" matSuffix (click)="togglePasswordVisibility()">{{
                  showPassword ? "visibility_off" : "visibility"
                }}</mat-icon>
              </mat-form-field>
            </div>

            <div
              *ngIf="isAdmin"
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
            >
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  placeholder="Select Company"
                  matInput
                  formControlName="companyName"
                  [matAutocomplete]="auto"
                />
                <mat-icon matSuffix>arrow_drop_down</mat-icon>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredCompanyOptions | async"
                    [value]="option.companyName"
                  >
                    {{ option.companyName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

            <div>
              <button mat-button class="btn-Forget" (click)="forgotPassword()">
                Forgot Password ?
              </button>
              <button
                mat-flat-button
                color="primary"
                [disabled]="
                  !signInForm.valid ||
                  (isAdmin && !this.signInForm.controls['companyName'].value)
                "
                style="width: 100%; height: 50px"
              >
                SIGN IN
              </button>
            </div>

            <div *ngIf="enableResetCredentialButton" style="padding-top: 3%;">
              <button
                mat-flat-button
                color="primary"
                (click)="reloadLoginScreen()"
                style="width: 100%; height: 50px;"
              >
                RESET CREDENTIALS
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
