import { maxLength, minLength, minNumber, pattern, prop, propArray, required } from "@rxweb/reactive-form-validators";

export class MaxNoPaymentsModel {

    @prop()  
    @required() 
    @pattern({ expression: { 'Number': /^[0-9]*$/ } })   
    min:number;

    @prop()  
    @required() 
    @pattern({ expression: { 'Number': /^[0-9]*$/ } })
    max:number;

    @prop()  
    @required()
    @pattern({ expression: { 'Number': /^[0-9]*$/ } })
    installments:number;

    @prop()  
    @required()  
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    label:string;

}

export class SettingsModel {
    @prop()
    companyId: any; 

    @prop()
    @required()
    @pattern({ expression: { 'Number_Decimal': /^[0-9]+(.[0-9]{0,2})?$/ } })
    saleTax: number;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    policyText:string;

    @prop()
    @required()    
    invoiceNumberLabelType:number;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    customInvoiceNumberLabel:string

    @prop()
    @required()
    interestFreePayments:number;

    @prop()
    @required() 
    @pattern({ expression: { 'Number_Decimal': /^[0-9]+(.[0-9]{0,2})?$/ } })     
    invoiceSum: number;

    @prop()
    @required()
    @pattern({ expression: { 'Number': /^[0-9]*$/ } })
    interestPaymentCount: number;

    @prop()
    @required()
    @pattern({ expression: { 'Number_Decimal': /^[0-9]+(.[0-9]{0,2})?$/ } }) 
    applyInterest: number;

    @prop()
    @required()
    //@pattern({ expression: { 'Number_Decimal': /^[0-9]+(.[0-9]{0,2})?$/ } }) 
    paymentAmount: number;

    @prop()
    maxNoOfPayments: any ;

    @prop()
    @required()
    maxPayments: string ;

    @prop()
    @required()
    isLightTheme:boolean=true ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    subject:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    mailContent:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    header:string ;

    @prop()
    @required()
    @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/ } })
    @pattern({ expression: { 'No_Space': /.*\S.*/ } })
    footer:string ;

    @prop()
    @required()
    paymentReminder:number ;

    @prop()
    @required()
    sendNotificationBy :number ;

    @prop()
    sendReminderM: any ; 

    @prop()
    @required()
    sendReminder:string ;

    @prop()
    @required()
    dueDate:number ;

    @propArray(MaxNoPaymentsModel)
    maxNoofPaymentsList: MaxNoPaymentsModel[];

}