import { Component, Input, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-dashboard-products-reports',
  templateUrl: './dashboard-products-reports.component.html',
  styleUrls: ['./dashboard-products-reports.component.scss']
})
export class DashboardProductsReportsComponent implements OnInit {
  loadAchTransaction: boolean = true;
  loadCreditDebit: boolean;
  loadInstantFund: boolean;
  loadLoadEcheck: boolean;

  @Input()
  period : string;

  constructor() { }

  ngOnInit(): void {
  }

  onTabChange(event: MatTabChangeEvent) {
    switch (event.tab.textLabel) {
      case 'ACH TRANSACTION':
        this.loadAchTransaction = true;
        break;
      case 'CREDIT/DEBIT':
        this.loadCreditDebit = true;
        break;
      case 'INSTANT FUND':
        this.loadInstantFund = true;
        break;
      case 'E-CHECK':
        this.loadLoadEcheck = true;
        break;
    }
  }
}
