<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Dashboard</div>
                <div class="page-sub-header-text-1">
                    All transactions, amount, individual, company related data can be seen from this page
                </div>
            </div>
        </div>
        <span class="spacer"></span>
        <div fxLayout="column" fxLayoutAlign="center end">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <button mat-raised-button color="primary" (click)="onPrintClicked()" class="page-header-button-style">
                    PRINT
                </button>
            </div>
        </div>
    </div>
</div>

<div class="compare-data-container">
    <div class="compare-data-inner-div" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch"
        style="float: right;">
        <div fxLayout="column" fxLayoutAlign="end end">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <mat-form-field appearance="outline">
                    <mat-label>Select to Compare Data</mat-label>
                    <mat-select [(value)]="period">
                        <mat-option value="weekly">Current VS Last Week</mat-option>
                        <mat-option value="monthly">Current VS Last Month</mat-option>
                        <mat-option value="yearly">Current VS Last Year</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<div #canvasContainer_Page1 id="canvasContainer_Page1">
    <app-dashboard-stats [period]="period"></app-dashboard-stats>
    <div class="transactions-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" class="totalTransactionsDiv" fxLayoutGap="5px" fxFlex="70%">
            <app-dashboard-total-transactions [period]="period"></app-dashboard-total-transactions>
        </div>
        <div fxLayout="column" class="recurringTransactionsDiv" fxFlex="30%">
            <app-dashboard-recurring-transactions [period]="period"></app-dashboard-recurring-transactions>
        </div>
    </div>
</div>
<div #canvasContainer_Page2 id="canvasContainer_Page2">
    <div class="transactions-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" class="totalTransactionsDiv" fxLayoutGap="5px" fxFlex="100%">
            <app-dashboard-products-reports [period]="period"></app-dashboard-products-reports>
        </div>
    </div>
</div>