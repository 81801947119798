<div class="tablet">
  <div class="content">
    <ng-container *ngIf="pageIndex == '1'">
      <app-hosted-payment-page-order [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
        [isSmallView]="isSmallView" [formData]="formData"></app-hosted-payment-page-order>
    </ng-container>
    <ng-container *ngIf="pageIndex == '2'">
      <app-hosted-payment-page-information [changesIsLoading]="changesIsLoading"  [finzeoPaymentOption]="finzeoPaymentOption" [deviceType]="deviceType"
        [isSmallView]="isSmallView" [formData]="formData"></app-hosted-payment-page-information>
    </ng-container>
    <ng-container *ngIf="pageIndex == '3'">
      <app-hosted-payment-page-success [changesIsLoading]="changesIsLoading" [deviceType]="deviceType"
        [isSmallView]="isSmallView" [formData]="formData"></app-hosted-payment-page-success>
    </ng-container>
  </div>
</div>