import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showIcon',
  pure : true
})
export class ShowIconPipe implements PipeTransform {

  transform(iconName: string): string {
    return 'assets/invoices-icons/' + iconName + '.png';
  }

}
