import { required, prop } from '@rxweb/reactive-form-validators';

export class IbvPdfReport {
  @required()
  ibvBankVerificationId: any;

  @prop()
  accountType: string;

  @prop()
  startDate: Date;

  @prop()
  endDate: Date;

  @prop()
  nsfDays: number;
}
