import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  ChartConfiguration,
  ChartData,
  ChartType,
} from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-labels';
import { BaseChartDirective } from 'ng2-charts';
import { Options } from 'ng5-slider';
import { ToastrService } from 'ngx-toastr';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
@Component({
  selector: 'app-cash-flow-analysis',
  templateUrl: './cash-flow-analysis.component.html',
  styleUrls: ['./cash-flow-analysis.component.scss'],
})
export class CashFlowAnalysisComponent {

  chartData: any = [];

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  isLoading = false;
  
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;  
  noDataforGraph: boolean = false;
  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _toastrService: ToastrService
  ) {}

  public barChartType: ChartType = 'bar';

  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData = {
    labels: [],
    datasets: [],
  };

  @Output() loadCashFlowAnalysisGraphChange = new EventEmitter<boolean>();
  @Input('loadCashFlowAnalysisGraph')
  set loadCashFlowAnalysisGraph(loadCashFlowAnalysisGraph: boolean) {
    if (loadCashFlowAnalysisGraph) {
      this.isLoading = true;
      this._instantVerifyReportsService
      .CashFlowAnalysisData(
        this.ibvBankVerificationId,
        this.accountType,
        this.startDate,
        this.endDate
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
              if (data.data.length == 0) {
                this.noDataforGraph = true;
              } else {
                this.noDataforGraph = false;
            this.chartData = [];
            const labels = data.data.map(item => item.month);
            const totalDebits = data.data.map(item => item.totalDebit);
            const totalCredits = data.data.map(item => item.totalCredit);
            const eomBalances = data.data.map(item =>  item.eomBalance);
            const nsfs = data.data.map(item =>item.nsf);

            this.chartData.push(
              {
                data: totalDebits,
                label: "Debits",
                type: 'bar',
                backgroundColor: '#b7c7ff',
                borderColor: '#b7c7ff',
                hoverBackgroundColor:'#b7c7ff',
                order: 4,
                barPercentage: 1.0,
              },
              {
                data: totalCredits,
                label: "Credits",
                type: 'bar',
                //tooltip: { 
                  //callbacks: {
                   //label: function (context) {
                    //  return context.label + ': ' + context.formattedValue ;
                  // }
                // }
              // },
                backgroundColor: '#1e4ed8',
                borderColor: '#1e4ed8',
                hoverBackgroundColor:'#1e4ed8',
                order: 3,
                barPercentage: 1.0
              },
              {
                data: nsfs,
                label: "NSF",
                type: 'scatter',
                parsing: {
                  yAxisKey:  'totalAmount'
              },
                tooltip: { 
                  callbacks: {
                  label: function (context) {
                    let tooltipArr=[];
                    tooltipArr.push('TotalAmount' + ' : ' + context.formattedValue);

                    let dateAmount=context.raw.nsfData.map(item=>item.postedDate +' : '+  Math.abs(item.amount));

                    dateAmount.forEach(element => {
                      tooltipArr.push(element);  
                    });                                     

                      return tooltipArr ;
                   },
                   
              }
               },
                pointBorderColor: '#a31005',
                borderColor: '#a31005',
                backgroundColor: '#a31005',
                hoverBackgroundColor:'#a31005',
                order: 1
              },
              {
                data: eomBalances,
                label: "EOM Balance",
                 type: 'line',
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#c99017',
                backgroundColor: '#c99017',
                pointBorderColor: '#c99017',
                pointBackgroundColor: '#c99017',
                order: 2
              }
            );
            
            // Update barChartData with new labels and datasets
            this.barChartData.labels = labels;
            this.barChartData.datasets = this.chartData;
              }
            this.chart?.update();

          }
            //this.refresh_chart();
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
        }
      );
    }
    this.loadCashFlowAnalysisGraphChange.emit(false);
  }
  public barChartOptions: ChartConfiguration['options'] = {

    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        
      },
      y: {  
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          usePointStyle: true,
          boxPadding: 5, 
          boxHeight:7,
          boxWidth:7                   
        },
        align:'end',
      },    
    },
  }
  refresh_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = this.barChartData.labels;
        this.chart.chart.config.data.datasets = this.barChartData.datasets;
        this.chart.chart.update();
      }
    });
  }
}
