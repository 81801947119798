<div class="page-design">
    <form [formGroup]="hostedPaymentpageFormGroup">
        <div class="form-group" formGroupName="configurationTabForm">
            <div class="inner-stepper-text">
                Confirmation Number
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <mat-radio-group name="isInvoiceNumberView" formControlName="isInvoiceNumberView" fxFlex="100%"
                        class="form-control" style="font-size: 12px">
                        <mat-radio-button class="add-page-radio-button" value="Show">Show
                        </mat-radio-button>
                        <mat-radio-button class="add-page-radio-button" value="Hide">Hide
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="inner-stepper-text">
                Description
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <mat-radio-group name="isDescriptionView" formControlName="isDescriptionView" fxFlex="100%"
                        class="form-control" style="font-size: 12px">
                        <mat-radio-button class="add-page-radio-button" value="Show">Show
                        </mat-radio-button>
                        <mat-radio-button class="add-page-radio-button" value="Hide">Hide
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="inner-stepper-text">
                Payment Option
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <div fxLayout="column" fxFlex="26%" fxLayout.xs="column" *ngIf="isPaymentOptionAch">
                        <mat-checkbox name="paymentOptionAch" formControlName="paymentOptionAch"
                            style="font-weight: 500; font-size: 13px;" (change)='onPaymentOptionAchChange($event)'>
                            ACH
                        </mat-checkbox>
                    </div>
                    <div fxLayout="column" fxFlex="50%" fxLayout.xs="column" *ngIf="isPaymentOptionCard">
                        <mat-checkbox name="paymentOptionCard" formControlName="paymentOptionCard"
                            style="font-weight: 500; font-size: 13px;" (change)='onPaymentOptionCardChange($event)'>
                            Card
                        </mat-checkbox>
                    </div>
                </div>
            </div>


            <div class="inner-stepper-text" *ngIf="this.isAvailRecuringAch || this.isAvailRecuringCard">
                Avail Recurring
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <div *ngIf="isAvailRecuringAch && isPaymentOptionAch" fxLayout="column" fxFlex="26%"
                        fxLayout.xs="column">
                        <mat-checkbox name="availRecuringAch" formControlName="availRecuringAch"
                            style="font-weight: 500; font-size: 13px;" (change)='onAvailRecuringAchChange($event)'>
                            ACH
                        </mat-checkbox>
                    </div>
                    <div *ngIf="isAvailRecuringCard && isPaymentOptionCard" fxLayout="column" fxFlex="50%"
                        fxLayout.xs="column">
                        <mat-checkbox name="availRecuringCard" formControlName="availRecuringCard"
                            style="font-weight: 500; font-size: 13px;" (change)='onAvailRecuringCardChange($event)'>
                            Card
                        </mat-checkbox>
                    </div>
                </div>
            </div>

            <div *ngIf = "this.isAvailRecuringAch">
                <div class="inner-stepper-text" *ngIf="isPaymentOptionAch">
                    Avail Convenience Fees For ACH
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                        class="form-group" style="padding: 10px 0px 20px 0px">
                        <mat-radio-group name="availConvenienceFee" formControlName="availConvenienceFee" fxFlex="100%"
                            class="form-control" style="font-size: 12px" (change)="onConvenienceFeeChangeEvent()">
                            <mat-radio-button class="add-page-radio-button" value="Yes">Yes
                            </mat-radio-button>
                            <mat-radio-button class="add-page-radio-button" value="No">No
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="inner-stepper-text" *ngIf="isPaymentOptionAch &&  isConvenienceAllowed">
                    Add Convenience Fees Amount
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                        class="form-group" style="padding: 5px 0px 10px 0px">
                        <mat-form-field appearance="outline" style="width:77%">
                            <mat-label class="required">Enter Amount</mat-label>
                            <input matInput name="convenienceFeeAmount" class="form-control" formControlName="convenienceFeeAmount"
                                autocomplete="doNotAutoComplete" (change)="onChangeConvenienceFee()"/>
                            <mat-error>
                                {{
                                hostedPaymentpageFormGroup.controls["configurationTabForm"]["controls"]["convenienceFeeAmount"]["_errorMessage"]
                                }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="inner-stepper-text" *ngIf="isDisplayShippingDetail">
                Shipping Details
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <mat-radio-group name="shippingDetails" formControlName="shippingDetails" fxFlex="100%"
                        class="form-control" style="font-size: 12px">
                        <mat-radio-button class="add-page-radio-button" value="Show">Show
                        </mat-radio-button>
                        <mat-radio-button class="add-page-radio-button" value="Hide">Hide
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="inner-stepper-text">
                Onboard Customer From Hosted Payment Page
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <mat-radio-group name="customerPortalOnBoarding" formControlName="customerPortalOnBoarding" fxFlex="100%"
                        class="form-control" style="font-size: 12px" (change)="onCustomerOnboardEvent()">
                        <mat-radio-button class="add-page-radio-button" value="Yes">Yes
                        </mat-radio-button>
                        <mat-radio-button class="add-page-radio-button" value="No">No
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div class="inner-stepper-text">
                Send Notification on (Payer)
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <div fxLayout="column" fxFlex="26%" fxLayout.xs="column">
                        <mat-checkbox name="sendNotificationEmail" formControlName="sendNotificationEmail"
                            style="font-weight: 500; font-size: 13px;" (change)='onSendNotificationEmailChange($event)'>
                            Email
                        </mat-checkbox>
                    </div>
                    <div fxLayout="column" fxFlex="50%" fxLayout.xs="column">
                        <mat-checkbox name="sendNotificationSMS" formControlName="sendNotificationSMS"
                            style="font-weight: 500; font-size: 13px;"
                            (change)='onSendNotificationSMSCardChange($event)'>
                            SMS
                        </mat-checkbox>
                    </div>
                    <!-- <div fxLayout="column" fxFlex="50%" fxLayout.xs="column">
                    <mat-checkbox style="font-weight: 500; font-size: 13px;">
                        Both
                    </mat-checkbox>
                </div> -->
                </div>
            </div>

            <div class="inner-stepper-text">
                Send Notification on (Owner)
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
                    class="form-group" style="padding: 10px 0px 20px 0px">
                    <div fxLayout="column" fxFlex="26%" fxLayout.xs="column">
                        <mat-checkbox name="sendNotificationEmailOwner" formControlName="sendNotificationEmailOwner"
                            style="font-weight: 500; font-size: 13px;" (change)='onSendNotificationToOwnerEmailChange($event)'>
                            Email
                        </mat-checkbox>
                    </div>
                    <div fxLayout="column" fxFlex="50%" fxLayout.xs="column">
                        <mat-checkbox name="sendNotificationSMSOwner" formControlName="sendNotificationSMSOwner"
                            style="font-weight: 500; font-size: 13px;"
                            (change)='onSendNotificationToOwnerSMSChange($event)'>
                            SMS
                        </mat-checkbox>
                    </div>                    
                </div>
            </div>
            <label class="additional-text inner-stepper-text" *ngIf="isNotificationToOwnerEmail">Add New Email:</label>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3%" fxLayoutAlign="start center"
                *ngIf="isNotificationToOwnerEmail">
                <div fxLayout="column" fxFlex="77%">
                    <mat-form-field appearance="outline">
                        <mat-label>Enter Email</mat-label>
                        <input matInput name="email" class="form-control" formControlName="email"
                            autocomplete="doNotAutoComplete" />
                        <mat-error>
                            {{
                            hostedPaymentpageFormGroup.controls["configurationTabForm"]["controls"]["email"]["_errorMessage"]
                            }}
                        </mat-error>
                        <mat-hint class="hint">Maximum of 5 email id's can be added</mat-hint>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="5%">
                    <button mat-raised-button color="primary" (click)="addNewEmails()" style="margin-bottom: 21px;">
                        ADD
                    </button>
                </div>
            </div>
            <div class="bottom-padding-text" *ngIf="isNotificationToOwnerEmail">
                <label
                    *ngIf="this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['emailList'].value?.length"
                    class="additional-text">Additional Email:</label>
            </div>
            <mat-chip-list *ngIf="isNotificationToOwnerEmail">
                <mat-chip class="custom-chip-style listboxes"
                    *ngFor="let email of this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['emailList'].value; index as i">
                    <mat-label> {{email}}</mat-label>
                    <mat-icon class="cancelIcon" (click)="removeNewEmails(i)">cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
            <div class="top-padding-text" *ngIf="isNotificationToOwnerSMS">
                <label class="additional-text inner-stepper-text">Add New Mobile No:</label>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3%" fxLayoutAlign="start center"
                *ngIf="isNotificationToOwnerSMS">
                <div fxLayout="column" fxFlex="77%">
                    <mat-form-field appearance="outline">
                        <mat-label>Enter Mobile Number</mat-label>
                        <input matInput name="mobileNo" formControlName="mobileNo" class="form-control"
                            autocomplete="doNotAutoComplete" />
                        <mat-error>
                            {{
                            hostedPaymentpageFormGroup.controls["configurationTabForm"]["controls"]["mobileNo"]["_errorMessage"]
                            }}
                        </mat-error>
                        <mat-hint class="hint">Maximum of 5 numbers can be added</mat-hint>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="5%">
                    <button mat-raised-button color="primary" (click)="addNewMobileNos()" style="margin-bottom: 21px;">
                        ADD
                    </button>
                </div>
            </div>
            <div class="bottom-padding-text" *ngIf="isNotificationToOwnerSMS">
                <label
                    *ngIf="this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['mobileList'].value?.length"
                    class="additional-text">Additional Mobile No:</label>
            </div>
            <mat-chip-list *ngIf="isNotificationToOwnerSMS">
                <mat-chip class="custom-chip-style listboxes"
                    *ngFor="let mn of this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['mobileList'].value; index as i">
                    <mat-label>{{mn}}</mat-label>
                    <mat-icon class="cancelIcon" (click)="removeNewMobileNos(i)">cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
    </form>
</div>