import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { ChangeNotification } from '../models/change-notification.model';
import { AuthService } from '../services/authentication/authentication.service';
import { NotificationService } from '../services/notification/notification.service';
import { SignalRService } from '../services/signalR/signal-r.service';

@Injectable()
export class AuthGuard implements CanActivate {
  isLoggin: Boolean = false;
  constructor(
    private authService: LoginService,
    private router: Router,
    private notificationService: NotificationService,
    public signalRService: SignalRService,
    private userProfileService: UserManagementService
  ) {}
  private tokenExpired(token: string) {
    if (token) {
      const expiry = JSON.parse(atob(token.split('.')[1])).exp;
      return (
        Math.floor(new Date(new Date().toUTCString()).getTime() / 1000) >=
        expiry - 240
      );
    } else return false;
  }
  private timeUseExpired(lastTimeCallAPI: string) {
    const expiry = new Date(lastTimeCallAPI);
    return (
      Math.floor(
        new Date(new Date().toUTCString()).getTime() - 1000 * 60 * 15
      ) >= expiry.getTime()
    );
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isLoggedIn.pipe(
      take(1),
      map((isLoggedIn: boolean) => {
        var isAdminLogin =
          JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;

        if (this.isLoggin === false) {
          this.userProfileService.getUserProfile(isAdminLogin);
          this.isLoggin = true;
        }

        if (
          this.tokenExpired(localStorage.getItem('accessToken')) &&
          !this.timeUseExpired(sessionStorage.getItem('latestCallAPI'))
        ) {
          this.authService.refreshToken('', isAdminLogin);
        }

        if (this.authService.isValidUser != 'true') {
          if (!this.authService.currentUser || !isLoggedIn) {
            isAdminLogin
              ? this.router.navigate(['/csr'])
              : this.router.navigate(['/']);
            localStorage.removeItem('validUser');
            localStorage.removeItem('userProfile');

            this.notificationService.showError(
              'Please login to use the system.'
            );
            return false;
          }
        }
        let obj = new ChangeNotification();
        this.signalRService.Returndataach.next(obj);
        this.signalRService.Returndatadcc.next(obj);
        this.signalRService.Returndatainf.next(obj);
        return true;
      })
    );
  }
}
