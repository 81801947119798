<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
        <button mat-icon-button (click)="navigateToPage()">
            <mat-icon>arrow_back </mat-icon>
        </button>
        Settings
    </mat-label>
</div>
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Invoice Setup</div>
        <div class="page-sub-header-text-1">
          All Invoice Setup related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>
<div class="invoice-setup-container">
  <div class="invoice-setup-inner-container">
    <form [formGroup]="invoiceSetupFormGroup">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="invoice-setup-form inside-tab-form">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Sales Tax</mat-label>
            </div>
            <div fxLayout="column" fxFlex="10%">
              <mat-form-field appearance="outline">
                <mat-label>Tax</mat-label>
                <input matInput formControlName="saleTax" class="form-control" name="saletax"
                  appTwoDigitDecimalNumber />
                <mat-error class="error-style">
                  {{ invoiceSetupFormGroup.controls["saleTax"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="column">%</div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Return Policy</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <mat-form-field appearance="outline" fxFlex="75%">
                <mat-label>Return Policy</mat-label>
                <input matInput formControlName="policyText" class="form-control" name="policyText" />
                <mat-error>
                  {{
                  invoiceSetupFormGroup.controls["policyText"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Invoice Number Label</mat-label>
            </div>
            <div fxLayout="column" fxFlex="90%">
              <mat-radio-group fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="2%" class="form-control"
                name="invoiceNumberLabelType" (change)="onLabelTypeSelected($event)">
                <mat-radio-button fxFlex="38%" [value]="1" [checked]="
                invoiceSetupFormGroup.controls['invoiceNumberLabelType']
                  .value === 1
              ">Default Label (FIN)</mat-radio-button>
                <mat-radio-button fxFlex="50%" [value]="2" [checked]="
                invoiceSetupFormGroup.controls['invoiceNumberLabelType']
                  .value === 2
              ">
                  <mat-form-field appearance="outline" >
                    <mat-label>Custom Invoice Number Label</mat-label>
                    <input matInput formControlName="customInvoiceNumberLabel" class="form-control"
                      name="customInvoiceNumberLabel" />
                    <mat-error>
                      {{
                      invoiceSetupFormGroup.controls["customInvoiceNumberLabel"][
                      "_errorMessage"
                      ]
                      }}
                    </mat-error>
                  </mat-form-field>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Interest Free Payments</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <mat-radio-group fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="start center" class="form-control"
                name="interestFreePayments">
                <mat-radio-button fxFlex="18%" [value]="1" (change)="onInterestFreePaymentTypeSelected($event)"
                  [checked]="
                invoiceSetupFormGroup.controls['interestFreePayments'].value ===
                1
              ">Invoice Sum</mat-radio-button>
                <mat-form-field appearance="outline" fxFlex="18%">
                  <mat-label>Sum</mat-label>
                  <input matInput formControlName="invoiceSum" class="form-control" name="invoiceSum"
                    appTwoDigitDecimalNumber #invoiceSum (input)="getInvoiceSumValue(invoiceSum.value)" />
                  <span matPrefix class="material-icons">attach_money</span>
                  <mat-error class="error-style">
                    {{
                    invoiceSetupFormGroup.controls["invoiceSum"]["_errorMessage"]
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-radio-button fxFlex="20%" [value]="0" (change)="onInterestFreePaymentTypeSelected($event)"
                  [checked]="
                invoiceSetupFormGroup.controls['interestFreePayments'].value ===
                0
              "># of Payments</mat-radio-button>
                <mat-form-field appearance="outline" fxFlex="17%">
                  <mat-label>Counts</mat-label>
                  <input matInput formControlName="interestPaymentCount" class="form-control"
                    name="interestPaymentCount" appTwoDigitDecimalNumber #interestPaymentCount
                    (blur)="getPaymentCountValue(interestPaymentCount.value)" />
                  <mat-error class="error-style">
                    {{
                    invoiceSetupFormGroup.controls["interestPaymentCount"][
                    "_errorMessage"
                    ]
                    }}
                  </mat-error>
                </mat-form-field>
              </mat-radio-group>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="row" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Add Interest to Payment Amount</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutWrap fxLayoutGap="15px">
                <div fxLayout="column" fxFlex="18%">Apply Interest of</div>
                <div fxLayout="column" fxFlex="18%">
                  <mat-form-field appearance="outline">
                    <mat-label>Interest</mat-label>
                    <input matInput formControlName="applyInterest" class="form-control" name="applyInterest"
                      appTwoDigitDecimalNumber />
                    <mat-error>
                      {{
                      invoiceSetupFormGroup.controls["applyInterest"][
                      "_errorMessage"
                      ]
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="column">%</div>
                <div fxLayout="column" *ngIf="isSum">
                  <mat-label>On total payment above
                    <span class="installmentvalue">${{ invoiceSetupFormGroup.value.paymentAmount }}</span></mat-label>
                </div>
                <div fxLayout="column" *ngIf="!isSum">
                  <mat-label>No of installments above
                    <span class="installmentvalue">{{
                      invoiceSetupFormGroup.value.paymentAmount
                      }}</span></mat-label>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px" class="m-top-1">
            <div fxLayout="column" fxFlex="25%">
              <mat-label class="required fontBold">Max # of Payments</mat-label>
            </div>
            <div fxLayout="column" fxFlex="75%">
              <div formArrayName="maxNoofPaymentsList" *ngFor="
              let payments of invoiceSetupFormGroup.get('maxNoofPaymentsList')[
                'controls'
              ];
              let i = index
            ">
                <div [formGroupName]="i">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center">
                    <div fxLayout="column">
                      <mat-label>Min</mat-label>
                    </div>
                    <div fxLayout="column" fxFlex="12%">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Sum</mat-label>
                        <input matInput formControlName="min" class="form-control doller-alignment" name="min" />
                        <span matPrefix class="material-icons">attach_money</span>
                        <mat-error>
                          {{
                          invoiceSetupFormGroup.controls["maxNoofPaymentsList"][
                          "controls"
                          ][i].controls.min["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column">
                      <mat-label>Max</mat-label>
                    </div>
                    <div fxLayout="column" fxFlex="12%">
                      <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>Sum</mat-label>
                        <input matInput formControlName="max" class="form-control doller-alignment" name="max" />
                        <span matPrefix class="material-icons">attach_money</span>
                        <mat-error>
                          {{
                          invoiceSetupFormGroup.controls["maxNoofPaymentsList"][
                          "controls"
                          ][i].controls.max["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column">
                      <mat-label>Installments</mat-label>
                    </div>
                    <div fxLayout="column" fxFlex="12%">
                      <mat-form-field appearance="outline">
                        <mat-label>Count</mat-label>
                        <input matInput formControlName="installments" class="form-control" name="installments" />
                        <mat-error>
                          {{
                          invoiceSetupFormGroup.controls["maxNoofPaymentsList"][
                          "controls"
                          ][i].controls.installments["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxLayout="column">
                      <mat-label>Label</mat-label>
                    </div>
                    <div fxLayout="column" fxFlex="22%">
                      <mat-form-field appearance="outline">
                        <mat-label>label For Payment Option</mat-label>
                        <input matInput formControlName="label" class="form-control" name="label" />
                        <mat-error>
                          {{
                          invoiceSetupFormGroup.controls["maxNoofPaymentsList"][
                          "controls"
                          ][i].controls.label["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div *ngIf="deleteButtonCondition()" fxLayout="column" fxFlex="4%">
                      <img class="deleteImg" src="{{ getIconSrc(delete_const) }}" (click)="removeMaxPaymentField(i)" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="25%"></div>
            <div fxLayout="column" fxFlex="18%">
              <button type="button" mat-button color="primary" class="white-button-style"
                (click)="addMaxPaymentField()">
                ADD MORE OPTIONS
              </button>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center" fxLayoutGap="10px"
            class="row-height-50">
            <div fxLayout="column" fxFlex="24%">
              <mat-label class="required fontBold">Select Theme</mat-label>
            </div>
            <div fxLayout="column" fxFlex="17%">
              <mat-radio-group fxFlex="12%" fxLayoutWrap fxLayoutGap="32px" fxLayoutAlign="start center"
                class="form-control" name="isLightTheme" (change)="onThemeSelected($event)">
                <mat-radio-button [value]="true" [checked]="
                invoiceSetupFormGroup.controls['isLightTheme'].value == true
              ">Light</mat-radio-button>
                <mat-radio-button [value]="false" [checked]="
                invoiceSetupFormGroup.controls['isLightTheme'].value == false
              ">Dark</mat-radio-button>
              </mat-radio-group>
            </div>
            <div fxLayout="column">
              <button type="button" mat-button color="primary" class="white-button-style" (click)="onPreviewClick()">
                PREVIEW
              </button>
            </div>
            <div fxLayout="column" *ngIf="loadInvoicePreview || loadInvoicePreviewDark">
              <button type="button" mat-button color="primary" class="white-button-style" (click)="onCloseClick()">
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="invoice-setup-form inside-light-theme" *ngIf="loadInvoicePreview">
        <app-invoice-preview [salesTax]="this.invoiceSetupFormGroup?.controls['saleTax']?.value ?? 0" [returnPolicy]="
        this.invoiceSetupFormGroup?.controls['policyText']?.value ?? ''
      "></app-invoice-preview>
      </div>

      <div class="invoice-setup-form inside-dark-theme" *ngIf="loadInvoicePreviewDark">
        <app-invoice-preview-dark [salesTax]="this.invoiceSetupFormGroup?.controls['saleTax']?.value ?? 0"
          [returnPolicy]="
        this.invoiceSetupFormGroup?.controls['policyText']?.value ?? ''
      "></app-invoice-preview-dark>
      </div>
      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="saveandcancel">
          <button mat-stroked-button color="primary" class="white-button-style" (click)="resetInvoiceSetupForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button color="primary" class="page-header-button-style" (click)="saveInvoiceSettings()">
            SAVE
          </button>
        </div>
      </div>
    </form>
  </div>
</div>