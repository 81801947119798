<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div *ngIf="!isLoading">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    class="apply-filter"
    fxLayoutGap="5%"
  >
    <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
      Button Details
    </div>
    <div fxLayout="column" fxFlex="5%">
      <mat-icon (click)="drawerClose()">close</mat-icon>
    </div>
  </div>
  <div class="apply-filter-body">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="heading-text"
    >
      {{ this.formData?.designTabForm?.linkText ?? "Buy Button" }}
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="sub-heading-text"
    >
      {{
        this.formData?.designTabForm?.linkPurpose ??
          "Default Purpose for Button"
      }}
    </div>
    <hr />
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="heading-text"
    >
      Design
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="center center"
      class="sub-heading-text"
    >
      <div
        *ngIf="this.formData?.designTabForm?.linkType == 'Button'"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="center center"
        class="link-box-content"
      >
        <button
          class="button-style"
          [style.--active-color-buttonBgColor]="
            this.formData.designTabForm.buttonBgColor ?? '#1e4ed8'
          "
          [style.--active-color-buttonTextColor]="
            this.formData.designTabForm.buttonTextColor ?? '#FFFFFF'
          "
          [style.--active-color-borderRadius]="
            this.formData.designTabForm.borderRadius ?? '4px'
          "
          [style.--active-color-borderColor]="
            this.formData.designTabForm.borderColor
          "
          [style.--active-color-borderShadow]="
            this.formData.designTabForm.shadow == 'ON'
              ? '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
              : 'None'
          "
          [style.--active-color-fontFamily]="
            this.formData.designTabForm.fontFamily
          "
          [style.--active-color-fontSize]="this.formData.designTabForm.fontSize"
          [style.--active-color-fontStyle]="
            this.formData.designTabForm.fontStyle
          "
        >
          {{ this.formData?.designTabForm?.linkText ?? "BUY NOW" }}
        </button>
      </div>

      <div
        *ngIf="this.formData?.designTabForm?.linkType == 'Link'"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="center center"
        class="link-box-content"
      >
        <a
          class="link-style"
          [style.--active-color-linkBgColor]="
            this.formData.designTabForm.linkBgColor ?? '#1e4ed8'
          "
          [style.--active-color-linkTextColor]="
            this.formData.designTabForm.linkTextColor ?? '#1e4ed8'
          "
          [style.--active-color-fontFamily]="
            this.formData.designTabForm.fontFamily
          "
          [style.--active-color-fontSize]="this.formData.designTabForm.fontSize"
          [style.--active-color-fontStyle]="
            this.formData.designTabForm.fontStyle
          "
          >{{ this.formData?.designTabForm?.linkText ?? "BUY NOW" }}</a
        >
      </div>
    </div>
    <hr />
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="heading-text"
    >
      <div
        fxLayout="column"
        fxFlex="40%"
        fxLayoutAlign="center start"
        class="html-code-text"
      >
        Html Code
      </div>
      <div fxLayout="column" fxFlex="60%" fxLayoutAlign="center end">
        <button
          (click)="copyHtmlCode()"
          [cdkCopyToClipboard]="this.paymentLink"
          class="copyButtonStyle"
        >
          <img
            class="img-copyhtmlcode"
            src="/assets/payment-widget-icons/copy-html-code.png"
            alt="Copy-Code"
            title="Copy Code"
          />
        </button>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="sub-heading-text"
    >
      <div class="code-box-content">
        <mat-label class="link-style-code">
          <pre>{{ paymentLink }}</pre>
        </mat-label>
      </div>
    </div>
    <hr />
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="heading-text"
    >
      Details
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="sub-heading-text"
    >
      <table style="width: 100%">
        <tr>
          <td>Amount</td>
          <td>${{ formData?.pricingTabForm.amount }}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{{ formData?.designTabForm.linkType }}</td>
        </tr>
        <tr>
          <td>Date Created</td>
          <td>{{ entryDate | date : "MMMM d, y, hh:mm a" }}</td>
        </tr>
        <tr>
          <td>Link Expiration Type</td>
          <td>{{ expiration }}</td>
        </tr>
        <tr *ngIf="linkExpiryDateAndTime">
          <td>Link Expiration On</td>
          <td>{{ linkExpiryDateAndTime | date : "MMMM d, y, hh:mm a" }}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{{ status ? "Active" : "InActive" }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
