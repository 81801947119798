
export interface IGradeItem {
    from: number | undefined;
    to: number | undefined;
    grade: string | undefined;
    color: string | undefined;
}
export class GradeScoreChartLegend {
    label: string;
    fromScore: number;
    toScore: number;
    color: string;
    constructor(_label: string, _fromScore: number, _toScore: number, _color: string) {
        const colorMap = GradeScoreColorMap.getColorMap();
        this.label = _label;
        this.fromScore = _fromScore;
        this.toScore = _toScore;
        this.color = _color || colorMap[_label];
    }
    
}

export class GradeScoreColorMap<T> {
    [Key: string]: T;
    public static getColorMap() {
        const colorMap = new GradeScoreColorMap<string>();
        colorMap['A'] = 'rgba(76, 176, 80, 255)';
        colorMap['B'] = 'rgba(0, 183, 210, 255)';
        colorMap['C'] = 'rgba(29, 76, 214, 255)';
        colorMap['D'] = 'rgba(253, 183, 73, 255)';
        colorMap['F'] = 'rgba(255, 83, 83, 255)';
        return colorMap;
    }
}

export interface IGradeScoreResult {
    score : number;
    grade : string;
}

export class GradeScoreChartModel implements IGradeScoreResult {
    minValue : number;
    maxValue : number;
    interval: number;
    score : number;
    grade : string;
    legends: GradeScoreChartLegend[];

    constructor(items: IGradeItem[], score: number, grade: string) {
        this.minValue = 0;
        this.maxValue = 1000;
        this.interval = 200;
        this.score = score;
        this.grade = GradeScoreChartModel.findGrade(items, score, grade);
        this.legends = [];
        items?.forEach(rule => {
            this.legends.push(new GradeScoreChartLegend(rule.grade, rule.from, rule.to, rule.color));
        });
    }

    public static findGrade(items: IGradeItem[], score: number, grade: string){
        if(!items){
            return grade;
        }

        for(var i = 0; i < items.length; i++){
            if(items[i].from <= score && score <= items[i].to){
                return items[i].grade;
            }
        }
        return grade;
    }

    public static example(score: number, grade: string) {
        const g = new GradeScoreChartModel(null, score, grade);
        g.legends = [];
        g.legends.push(new GradeScoreChartLegend('A', 850, 1000, null));
        g.legends.push(new GradeScoreChartLegend('B', 750, 849, null));
        g.legends.push(new GradeScoreChartLegend('C', 550, 699, null));
        g.legends.push(new GradeScoreChartLegend('D', 400, 549, null));
        g.legends.push(new GradeScoreChartLegend('F', 0, 399, null));
        return g;
    }
}