<div class="card-style" >
<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="space-between stretch"> 
  <div fxLayout="column" fxFlex="30%" *ngIf="isSuccessDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartSuccessData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Success</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isPendingDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartPendingData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Pending</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isFailedDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartFailedData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Failed</div>
  </div>
</div>
<div *ngIf= "isReturnDataAvailable || isCancelledDataAvailable" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="5%" > 
  <div fxLayout="column" fxFlex="30%" *ngIf="isCancelledDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartCancelledData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Cancelled</div>
  </div>
  <div fxLayout="column" fxFlex="30%" *ngIf="isReturnDataAvailable">
    <app-dashboard-pie-chart
      [doughnutChartData]="doughnutChartReturnedData"
      [doughnutChartType]="doughnutChartType"
      [doughnutChartOptions]="doughnutChartOptions"
      [period]="period"
    >
    </app-dashboard-pie-chart>
    <div class="label-style">Returned</div>
  </div>
</div>
</div>

<app-data-table
  *ngIf="transactionType == 'ach'"
  [columns]="transactionStatuscolumns"
  [data]="transactionStatusGridData"
  [isLoading]="isLoading"
  [isLoadingPaging]="isLoadingPaging"
  [page]="page"
  [sortBy]="sortBy"
  [noRecordMessage]="noRecordMessage"
  [showMoreMenu]="true"
  [allColumns]="totalAllColumns"
  [selection]="selection"
  [actionRefresh]="actionRefresh"
  (actionRefreshEvent)="onRefresh($event)"
  (pageChangedEvent)="onPageChanged($event)"
  (rowClickedEvent)="onRowClick($event)"
  (sortChangedEvent)="onSortChanged($event)"
  (onFilterEvent)="onFilter($event)"
  [isFilter]="false"
  [isExport]="false"
  [isAddBtn]="false"
  [isCreditDebitSummary]="false"
  [isTotalTransactionLbl]="true"
  [isSearch]="false"
  [isTableHeading]="true"
  [isTableHeadingText]="tableLabel"
  [showPaginator]="false"
>
</app-data-table>

<app-data-table
  *ngIf="transactionType == 'creditdebit' || transactionType == 'echeck'"
  [columns]="cardTransactionStatuscolumns"
  [data]="transactionStatusGridData"
  [isLoading]="isLoading"
  [isLoadingPaging]="isLoadingPaging"
  [page]="page"
  [sortBy]="sortBy"
  [noRecordMessage]="noRecordMessage"
  [showMoreMenu]="true"
  [allColumns]="cardTotalAllColumns"
  [selection]="selection"
  [actionRefresh]="actionRefresh"
  (actionRefreshEvent)="onRefresh($event)"
  (pageChangedEvent)="onPageChanged($event)"
  (rowClickedEvent)="onRowClick($event)"
  (sortChangedEvent)="onCardGridSortChanged($event)"
  (onFilterEvent)="onFilter($event)"
  [isFilter]="false"
  [isExport]="false"
  [isAddBtn]="false"
  [isCreditDebitSummary]="false"
  [isTotalTransactionLbl]="true"
  [isSearch]="false"
  [isTableHeading]="true"
  [isTableHeadingText]="tableLabel"
  [showPaginator]="false"
>
</app-data-table>
