import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/sharedModules/guards/auth.guard';
import { CCustomerScoreStatsComponent as CustomerScoreStatsComponent } from './customer-score-stats.component';


const routes: Routes = [
  { path: 'customerScoreStats', component: CustomerScoreStatsComponent, pathMatch: 'full', canActivate:[AuthGuard] },
  { path: 'customerScoreStats/:customerId', component: CustomerScoreStatsComponent, pathMatch: 'full', canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomerScoreStatsRoutingModule { }
