import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-hosted-payment-page-setting-master-layout',
  templateUrl: './hosted-payment-page-setting-master-layout.component.html',
  styleUrls: ['./hosted-payment-page-setting-master-layout.component.scss']
})
export class HostedPaymentPageSettingMasterLayoutComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }

}
