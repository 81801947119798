<mat-card class="payment-details-card" fxLayout="column" fxFlex="100%">
  <div fxLayout="column" fxLayoutGap="4%" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="5%" fxLayoutAlign.sm="start stretch"
      fxLayoutAlign.xs="start stretch" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="35%" fxLayoutGap="10px" fxLayoutAlign="center start">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
          <mat-label class="payment">Payment</mat-label>
        </div>

        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
          <mat-label class="sub-header">Choose any Payment method</mat-label>
        </div>
      </div>

      <div fxLayout="column" fxLayout.xs="row" fxFlex="60%" fxLayoutGap="10px">
        <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="22px" fxLayoutAlign="start stretch">
          <div fxLayout="column" *ngIf="pageSettingModel?.isPaymentOptionCard">
            <div class="image-container" *ngIf="isPayWithCard">
              <ng-container *ngIf="isOnlyCard">
                <img src="assets/payment-widget-icons/payWithCard.png" alt="payWith-logo"
                  class="payWith-logo highlighted" [style.--active-bg-color]="pageSettingModel?.themeColor" />
              </ng-container>
              <ng-container *ngIf="!isOnlyCard">
                <img src="assets/payment-widget-icons/payWithCard.png" alt="payWith-logo"
                  class="payWith-logo highlighted" [style.--active-bg-color]="pageSettingModel?.themeColor"
                  (click)="enablePaymentType('payWithCard')" />
              </ng-container>
              <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                class="overlay-image done-check-on-payment-cards-logo" />
            </div>
            <div *ngIf="!isPayWithCard">
              <img src="assets/payment-widget-icons/payWithCard.png" alt="payWith-logo" class="payWith-logo highlighted"
                (click)="enablePaymentType('payWithCard')" />
            </div>
          </div>

          <div fxLayout="column" *ngIf="pageSettingModel?.isPaymentOptionAch">
            <div class="image-container" *ngIf="isPayWithAch">
              <ng-container *ngIf="isOnlyAch">
                <img src="assets/payment-widget-icons/payWithAch.png" alt="payWith-logo"
                  class="payWith-logo highlighted" [style.--active-bg-color]="pageSettingModel?.themeColor" />
              </ng-container>
              <ng-container *ngIf="!isOnlyAch">
                <img src="assets/payment-widget-icons/payWithAch.png" alt="payWith-logo"
                  class="payWith-logo highlighted" [style.--active-bg-color]="pageSettingModel?.themeColor"
                  (click)="enablePaymentType('payWithAch')" />
              </ng-container>
              <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                class="overlay-image done-check-on-payment-cards-logo" />
            </div>
            <div *ngIf="!isPayWithAch">
              <img src="assets/payment-widget-icons/payWithAch.png" alt="payWith-logo" class="payWith-logo highlighted"
                (click)="enablePaymentType('payWithAch')" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch">
      <mat-divider></mat-divider>
    </div>

    <div [hidden]="isPayWithAch || isNoProductAssigned" fxLayoutGap="5%">
      <app-card-payment-info [uniqueToken]="uniqueToken" [amount]="amount" [pageSettingModel]="pageSettingModel"
        [cardDetailsFormGroup]="cardDetailsFormGroup" (showLoaderEvent)="getLoaderEvent($event)"
        (cardDetailsFormGroupChange)="getCardDetailsFormGroup($event)"
        (surchargeDisAgree)="enablePaymentType('payWithAch')" [isRecurringDisabled]="isRecurringDisabled"></app-card-payment-info>
    </div>

    <div [hidden]="isPayWithCard || isNoProductAssigned" fxLayoutGap="5%">
      <app-ach-payment-info [pageSettingModel]="pageSettingModel" [amount]="amount"
        [achDetailsFormGroup]="achDetailsFormGroup" (achDetailsFormGroupChange)="getAchDetailsFormGroup($event)"
        (paymentMethodEvent)="enablePaymentType('payWithCard')" [isRecurringDisabled]="isRecurringDisabled"></app-ach-payment-info>
    </div>

    <div [hidden]="!isNoProductAssigned" fxLayoutGap="5%">
      <h2 style="text-align: center;">No Payment Service is assigned to this client</h2>
    </div>
  </div>
</mat-card>