import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { IbaTransactionFilterModel } from 'src/app/featureModules/models/Iba-Transactions-Filter-Model';
import { ReportDepositsTable } from 'src/app/featureModules/models/report-transaction-model';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { getSafeIsoDateTimeStringToLocal } from 'src/app/sharedModules/utils/dates';

interface DrowpDown {
  key: string;
  value: string;
}
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'recurring-debits-deposits',
  templateUrl: './recurring-debits.component.html',
  styleUrls: ['./recurring-debits.component.scss'],
})
export class RecurringDebitsComponent implements OnDestroy {
  isLoading = false;
  transactionDetails: any;
  transactionFilterFormGroup: FormGroup;
  filter: ListFilter = '';

  transactionTypeList: DrowpDown[] = [
    { key: 'All', value: 'All' },
    { key: 'Credit', value: 'Credit' },
    { key: 'Debit', value: 'Debit' },
  ];

  transactionList: DrowpDown[] = [
    { key: 'All', value: 'All' },
    { key: 'Recurring', value: 'Recurring' },
    { key: 'Non-Recurring', value: 'Non-Recurring' },
  ];

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  @Output()
  sortChangedEvent = new EventEmitter();

  isTransactinTableLoading = false;
  seachboxPadding = '0px';
  dataTableMargin = '0px'

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 25,
    length: 500,
    previousPageIndex: 0,
  };

  pageSizeOptions = [25, 50, 100, 200];

  sortBy: SortChanged = {
    active: 'postedDate',
    direction: 'desc',
  };

  @Input()
  listRefreshEvent = new EventEmitter();

  @Input()
  filterEvent = new EventEmitter();

  transactionDataSource = new MatTableDataSource<ReportDepositsTable>();

  allColumns: TableColumn[] = [
    { displayName: 'TYPE', name: 'transactionType', width: '15%' },
    { displayName: 'RECURRING', name: 'frequency', width: '21%' },
    { displayName: 'DESCRIPTION', name: 'memo', width: '35%' },
    {
      displayName: 'DATE',
      name: 'postedDate',
      width: '17%',
      converter: (value) => getSafeIsoDateTimeStringToLocal(value),
    },
    { displayName: 'AMOUNT', name: 'amount', width: '12%' },
  ];

  noRecordsMessage = 'No Transactions Found!';
  advanceFilters: string = '';
  advanceFiltersList: any;
  advanceFilterCount: number;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService,
    private _commonService: CommonService,
    private _router: Router,
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService
  ) {
    this.filter = this._commonService.getListDataFromLocalStorage(
      'ibaTransactionGridFilter',
      'instantBankAnalysis',
      ''
    );
    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaReportTransactionsGridPageIndex',
          'instantBankAnalysis',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaReportTransactionsGridPageSize',
          'instantBankAnalysis',
          50
        )
      ),
      length: 500,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ibaReportTransactionsGridSortActive',
        'instantBankAnalysis',
        'postedDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ibaReportTransactionsGridSortDirection',
        'instantBankAnalysis',
        'desc'
      ),
    };
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'ibaReportTransactionsAdvanceFilterForm',
        'instantBankAnalysis',
        JSON.stringify(new IbaTransactionFilterModel())
      )
    );

    let filterModel = new IbaTransactionFilterModel();
    Object.assign(filterModel, filterFormData);
    this.transactionFilterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  @Output() loadRecurringDebitsCardChange = new EventEmitter<boolean>();
  @Input('loadRecurringDebitsCard')
  set loadRecurringDebitsCard(loadRecurringDebitsCard: boolean) {
    if (loadRecurringDebitsCard) {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    }

    this.loadRecurringDebitsCardChange.emit(false);
  }

  getColumnNames(columns: TableColumn[] = []) {
    return columns.map((column) => column.name);
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'ibaReportRecurringDebitsGridPageSize',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'ibaReportRecurringDebitsGridPageIndex',
        'instantBankAnalysis'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    var column = this.allColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'ibaReportRecurringDebitsGridSortActive',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'ibaReportRecurringDebitsGridSortDirection',
        'instantBankAnalysis'
      );
    }
  }

  onRefresh(id: string) {}

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'ibaTransactionGridFilter',
        'instantBankAnalysis'
      );
    }
  }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;

    const transactionsArray = advanceFilters.split('|');

    // Initialize variables to hold the transaction type and value
    let transactionType: string = '';
    let transaction: string = '';

    transactionsArray.forEach(filter => {
        const filterParts = filter.split(':');
        if (filterParts[0] === 'transactionType') {
            transactionType = filterParts[1];
        }
        else if (filterParts[0] === 'transaction') {
            transaction = filterParts[1];
        }
    });

    if(transaction === "Recurring"){
      this._instantVerifyReportsService
      .GetRecurringTransactionsBasedOnTrnType(
        this.ibvBankVerificationId,
        this.accountType,
        this.startDate,
        this.endDate,
        transactionType,        
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {            
            this.transactionDetails = data.data.recurringTransList;
            this.page.length = data.data?.totalRecords;
            this.transactionDetails.forEach((element) => {
              element.transactionType = element.transactionType;
              element.frequency = element.frequency ? `YES (Frequency: ${element.frequency})` : '';
              element.date = element.date;
              element.memo = element.memo;
              element.amount = element.amount;              
            });          
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong while fetching recurring transactions, Please contact administrator!'
          );
        }
      );
    }
    else if(transaction === "Non-Recurring"){
      this._instantVerifyReportsService
      .GetNonRecurringTransactionBasedOnTrnType(
        this.ibvBankVerificationId,
        this.accountType,
        this.startDate,
        this.endDate,
        transactionType,        
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.transactionDetails = data.data.categoryTransList;
            this.page.length = data.data.totalRecords;
            this.transactionDetails.forEach((element) => {
              element.transactionType = element.transactionType;
              element.frequency = '';
              element.date = element.date;
              element.memo = element.memo;
              element.amount = element.amount;              
            }); 
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong while fetching non-recurring transactions, Please contact administrator!'
          );
        }
      );
    }else{
      this._instantVerifyReportsService
      .GetAllTransactionBasedOnTrnType(
        this.ibvBankVerificationId,
        this.accountType,
        this.startDate,
        this.endDate,
        transactionType,        
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.transactionDetails = data.data.categoryTransList;
            this.page.length = data.data.totalRecords;
            this.transactionDetails.forEach((element) => {
              element.transactionType = element.transactionType;
              element.frequency = '';
              element.date = element.date;
              element.memo = element.memo;
              element.amount = element.amount;              
            }); 
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong while fetching transactions, Please contact administrator!'
          );
        }
      );
    }    
  }

  public restForm() {
    if (this.transactionFilterFormGroup) {
      this.transactionFilterFormGroup.reset();
      let filterModel = new IbaTransactionFilterModel();
      filterModel.transaction = 'All';
      filterModel.transactionType = 'All';
      this.transactionFilterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public applyAdvanceFilter() {
    let data = this.transactionFilterFormGroup.value;
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaReportTransactionsGridPageSize',
          'instantBankAnalysis',
          25
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ibaReportTransactionsGridSortActive',
        'instantBankAnalysis',
        'postedDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ibaReportTransactionsGridSortDirection',
        'instantBankAnalysis',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if (data.transaction && data.transaction != 'All') {
      this.advanceFilters += 'transaction:' + data.transaction + '|';
    }

    if (data.transactionType && data.transactionType != 'All') {
      this.advanceFilters += 'transactionType:' + data.transactionType + '|';
    }

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'ibaTranscationAdvanceFilter',
      'instantBankAnalysis'
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.transactionFilterFormGroup.value),
      'ibaTranscationAdvanceFilterForm',
      'instantBankAnalysis'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    if (!this._router.url.includes('instantBankAnalysis'))
      localStorage.removeItem('instantBankAnalysis');
  }
}
