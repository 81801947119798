import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddCustomerCardDetailsModel } from 'src/app/sharedModules/models/add-customer-card-details.model';

@Injectable({
  providedIn: 'root',
})

export class CustomerCardDetailsService extends CrudService<AddCustomerCardDetailsModel, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}CustomerCards`);
  }

  AddCustomerCardDetails(model: AddCustomerCardDetailsModel): Observable<any> {
    return this._http.post<any>(this._base + "/addCustomerCard", model);
  }

  GetAllCards(customerID: string,onlyActive:boolean=true): Observable<any> {
    return this._http.get<any>(this._base + "/getAllCards/" + customerID + "/"+ onlyActive);
  }

  deactivateCustomerCard(customerCardId: any, status: boolean): Observable<any> {
    return this._http.put<any>(this._base + "/deactivateCustomerCard/" + customerCardId + "/" + status, null);
  }

  updateCustomerCard(customerCardId: any, model: AddCustomerCardDetailsModel): Observable<any> {
    return this._http.put<any>(this._base + "/updateCustomerCard/" + customerCardId, model);
  }
}
