
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { InstantFundTransactionModel } from '../models/addinstantfund-transaction.model';

@Injectable({
  providedIn: 'root',
})

export class InstantFundTransactionService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_INSTANTFUND}Transactions`);
  }

  CreateInstantFundTransaction(model: InstantFundTransactionModel): Observable<any> {
    return this._http.post<any>(this._base + "/create", model);
  }


  GetCustomerInstantFundTransactions(customerId: string, filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/getCustomerInstantFundTransactions/"+customerId+"?customerId=" +customerId+"&sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  GetCustomerInstantFundTransactionStats(customerId: string){
    return this._http.get<any>(this._base + "/getCustomerInstantFundTransactionStats/"+customerId);
  }

  GetTransactionSummary(filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number): Observable<any> {
      let baseUrl = this._base + "/getTransactionSummary?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
      baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
      baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
      return this._http.get<any>(baseUrl);
    }

}
