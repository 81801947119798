import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EulaDialogComponent } from 'src/app/sharedModules/components/dialogs/eula-dialog/eula-dialog.component';
import {
  AuthenticationServiceProxy,
  ClientValidationRequest,
  GetEulaApprovalRequest,
  UpdateEulaApprovalRequest,
} from 'src/app/sharedModules/services/login/login.proxies';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import { LoginService } from '../../services/login.service';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ReportTransactionTable } from 'src/app/featureModules/models/report-transaction-model';
import { Observable, map, startWith } from 'rxjs';

interface Company {
  companyName: string;
  companyId: number;
}

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
})
export class AdminLoginComponent implements OnInit {
  @Input()
  data: String;

  isLoading = false;
  loadMessage: string = 'Please Wait ...';
  public signInForm: FormGroup;
  submitted = false;
  filterFormGroup: FormGroup;
  password!: string;
  company!: string;
  email!: string;
  key: any;
  public updateEulaApprovalRequest: UpdateEulaApprovalRequest;
  public getEulaApprovalRequest: GetEulaApprovalRequest;
  public clientValidationRequest: ClientValidationRequest;
  isEulaApproved: boolean = false;
  public showPassword: boolean = false;

  companyList: Company[] = [];
  filteredCompanyOptions: Observable<Company[]>;

  //Super-Admin related things
  isAdmin: boolean = false;
  isAdminLogin: boolean = true;
  showUpIcon = false;
  enableResetCredentialButton = false;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthenticationServiceProxy,
    private notifyService: NotificationService
  ) {}
  ngOnInit(): void {
    this.logout();
    localStorage.setItem('isAdminLogin', 'true');
    this.signInForm = this.formBuilder.group({
      companyName: [null, this.conditionalRequired(this.isAdmin == true)],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });

    this.filteredCompanyOptions = this.signInForm
      .get('companyName')
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filter(value || ''))
      );

    if (localStorage.getItem('callCompanyList') == 'true') {
      let userEmail = localStorage.getItem('userEmail');
      this.signInForm.controls['email'].setValue(userEmail);
      this.signInForm.controls['password'].setValue('Test@1999');

      this.signInForm.controls['email'].disable();
      this.signInForm.controls['password'].disable();
      this.enableResetCredentialButton = true;

      this.GetCompanyList();
    }
  }

  reloadLoginScreen() {
    localStorage.removeItem('callCompanyList');
    this.signInForm.controls['email'].setValue(null);
    this.signInForm.controls['password'].setValue(null);

    this.signInForm.controls['email'].enable();
    this.signInForm.controls['password'].enable();
    this.signInForm.controls['email'].markAsUntouched();
    this.signInForm.controls['password'].markAsUntouched();
    this.signInForm.controls['email'].markAsPristine();
    this.signInForm.controls['password'].markAsPristine();
    this.isAdmin = false;
    this.companyList = [];
    this.signInForm.controls['companyName'].setValue(null);
    this.enableResetCredentialButton = false;
  }

  private _filter(value: string): Company[] {
    const filterValue = value.toLowerCase();

    return this.companyList.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.key = event.key;
  }

  forgotPassword() {
    if (this.key != 'Enter') {
      this.router.navigate(['/forgotPasswordEmail']);
    }
  }

  toggleDropdown() {
    this.showUpIcon = !this.showUpIcon;
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public conditionalRequired(condition: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (condition && !control.value) {
        return { required: true };
      }
      return null;
    };
  }

  doLogin(data: any) {
    if (!this.signInForm.valid) {
      this.key = null;
      return;
    }
    sessionStorage.setItem('isLoggingIn', 'true');
    this.password = data.password;
    this.company = data.companyName
      ? this.companyList
          .find((x) => x.companyName == data.companyName)
          ?.companyId.toString()
      : '';
    this.email = data.email;

    if (!this.isAdmin) {
      this.confirmEulaApproved();
    } else {
      this.loginService.refreshToken(this.company, this.isAdminLogin, true);
    }
  }

  confirmEulaApproved() {
    this.getEulaApprovalRequest = new GetEulaApprovalRequest({
      company: this.company,
      email: this.email,
      isAdminLogin: this.isAdminLogin,
    });

    this.authService
      .getEulaApprovalStatus(this.getEulaApprovalRequest)
      .subscribe(
        (res) => {
          if (res.code == 200 && res.data == 'Approved') {
            sessionStorage.setItem('isLoggingIn', 'false');
            this.loginService.doSignIn(
              this.company,
              this.email,
              this.password,
              this.signInForm,
              this.isAdminLogin
            );
          } else if (res.code == 200 && res.data == 'UnApproved') {
            this.OpenEulaDialog();
          }
        },
        (err) => {
          sessionStorage.setItem('isLoggingIn', 'false');
          this.notifyService.showError(err.errorMessage);
        }
      );
  }

  GetCompanyList() {
    this.authService.getCompanyListForUser().subscribe(
      (res) => {
        if (res.code == 200) {
          this.isAdmin = true;
          this.companyList = res.data;
        }
      },
      (err) => {
        this.isAdmin = false;
        this.notifyService.showError(err.errorMessage);
      }
    );
  }

  updateEulaApprovalDateAndStatus() {
    this.updateEulaApprovalRequest = new UpdateEulaApprovalRequest({
      status: true,
      company: this.company,
      email: this.email,
      password: this.password,
      isAdminLogin: this.isAdminLogin,
    });

    this.authService
      .updateEulaApprovalStatus(this.updateEulaApprovalRequest)
      .subscribe(
        (res) => {
          if (res.code == 201) {
            sessionStorage.setItem('isLoggingIn', 'false');
            this.loginService.doSignIn(
              this.company,
              this.email,
              this.password,
              this.signInForm,
              this.isAdminLogin
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.errorMessage);
        }
      );
  }

  OpenEulaDialog() {
    const dialogRef = this.dialog.open(EulaDialogComponent, {
      height: '550px',
      width: '700px',
      data: {
        buttonName: 'ACCEPT',
        actionButton: true,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        sessionStorage.setItem('isLoggingIn', 'false');
        this.updateEulaApprovalDateAndStatus();
      }
    });
  }

  logout() {    
    if(!localStorage.getItem('accessToken')) return;    
    const isLoggingIn = sessionStorage.getItem('isLoggingIn') === 'false';
    if (isLoggingIn) return;
    let userEmail = sessionStorage.getItem("userEmail");
    if (userEmail) {
      this.authService.doLogout({ "emailId": userEmail }).subscribe(
        (res) => {
          if (res.code == 200 || res.code == 400) {
            this.removeAccess();
          }
        },
        (error) => {
          this.removeAccess();
        });
    } else {
      this.removeAccess();
    }
  }

  removeAccess() {
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('validUser');
    localStorage.removeItem('userEmail');

    localStorage.removeItem('inventory');
    localStorage.removeItem('invoice');
    localStorage.removeItem('users');
    localStorage.removeItem('dashboard');
    localStorage.removeItem('reports');
    localStorage.removeItem('hostedLink');
    localStorage.removeItem('customers');
    localStorage.removeItem('instantBankAnalysis');
    localStorage.removeItem('statements');
    localStorage.removeItem('ticket');
    localStorage.removeItem('ach');
    localStorage.removeItem('cards');
    localStorage.removeItem('echeck');
    localStorage.removeItem('instantFund');
    localStorage.removeItem('callCompanyList');
    localStorage.removeItem('billing');

    this.isAdminLogin
      ? window.location.replace('csr')
      : window.location.replace('/');
    localStorage.removeItem('isAdminLogin');
  }

}
