import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InventoryStatsModel } from 'src/app/featureModules/models/inventory-stats.model';
import { InventoryService } from 'src/app/featureModules/services/inventory.service';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-inventory-item-stats',
  templateUrl: './inventory-item-stats.component.html',
  styleUrls: ['./inventory-item-stats.component.scss']
})
export class InventoryItemStatsComponent implements OnInit {

  loadMessage: string = 'Please wait..';
  isLoading: boolean;
  inventoryStatsModel: InventoryStatsModel;
  totalUnitPrice:number =0;

  constructor(private _toastrService: ToastrService,    
    private _inventoryService: InventoryService) { }

  ngOnInit(): void {
    this.inventoryStatsModel = new InventoryStatsModel();
    this.getAllInventoryStats();
  }

  getAllInventoryStats() {
    this.isLoading = true;
    this._inventoryService.getInventoryStats()
      .subscribe(
        (data) => {
          this.isLoading = false;
          if(data.data.serviceRecords){
            this.initializeInventoryStats(data.data.serviceRecords);
          } else {
            this._toastrService.error('Something went wrong, Please contact administrator!');
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }

  initializeInventoryStats(response: any){
    this.inventoryStatsModel.totalServicesCount = response.totalServicesCount;
    this.inventoryStatsModel.totalQuantity = response.totalQuantity;
    this.totalUnitPrice = response.totalUnitPrice;
    this.inventoryStatsModel.activeStatus = response.activeStatus;
    this.inventoryStatsModel.inactiveStatus = response.inactiveStatus; 
  }

  getIconSrc(iconName: string) {
    return 'assets/invoices-icons/' + iconName + '.png';
  }
}
