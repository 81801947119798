import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  loadMessage: string = 'Please wait..';
  isLoading: boolean;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigate(navigate:any){
    this.router.navigate([navigate]);    
  }

}
