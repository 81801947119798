import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
} from '@rxweb/reactive-form-validators';

export class SendInvoiceModel {
  @prop()
  @required()
  scheduleDate: Date;

  @prop()
  invoiceDueDate: Date;

  @prop()
  registeredEmail: string;

  @prop()
  @maxLength({ value: 50 })
  @pattern({
    expression: {
      Email: /^[\w%\+\-]+(\.[\w%\+\-]+)*@\w+((-\w+)|(\w*))\.[a-z]{3,3}$/,
    },
    message: 'Please enter valid email',
  })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  email: string;

  @prop()
  registeredNumber: string;

  @prop()
  @maxLength({ value: 30 })
  mobileNumber: string;

  @prop()
  mobileNo: any;

  @prop()
  emailList: string[];

  @prop()
  mobileNumberList: string[];

  @prop()
  sendBy: string;

  @prop()
  additonalEmail: string;

  @prop()
  additonalNumber: string;

  @prop()
  isoCode: string;
}
