<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToBackPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Hosted Payment Page Link Generation
    </mat-label>
  </div>
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Create Payment Link</div>
        <div class="page-sub-header-text-1">
          All hosted payment page link generation related changes can be managed
          from this page
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="payment-link-parent-page"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
  fxLayoutWrap
  fxLayoutGap="1%"
>
  <div fxLayout="column" fxFlex="32%" class="right-border">
    <app-tab-control
      *ngIf="paymentLinkFormGroup.value && !this.isLoading"
      [paymentLinkFormGroup]="paymentLinkFormGroup"
    ></app-tab-control>
  </div>

  <div fxLayout="column" fxFlex="67%">
    <app-button-preview-and-code
      *ngIf="paymentLinkFormGroup.value"
      [buttonIsLoading]="buttonIsLoading"
      [paymentLinkFormGroup]="paymentLinkFormGroup"
    ></app-button-preview-and-code>
  </div>
</div>
