import { Component, OnInit, ViewChild  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CustomerScoreStatsCard1Component } from 'src/app/featureModules/components/customer-score-stats-related/customer-score-stats-card1/customer-score-stats-card1.component';
import { CustomerScoreStatsCard2Component } from 'src/app/featureModules/components/customer-score-stats-related/customer-score-stats-card2/customer-score-stats-card2.component';
import { CustomerScoreStatsFilter, ICustomerInfo } from 'src/app/featureModules/components/customer-score-stats-related/models/customer-score-stats.models';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';
import { CustomerService } from '../../services/customers.service';

@Component({
  selector: 'app-customer-score-stats',
  templateUrl: './customer-score-stats.component.html',
  styleUrls: ['./customer-score-stats.component.scss']
})
export class CCustomerScoreStatsComponent implements OnInit {
  isLoading = false;
  formFilter: FormGroup;
  fromDate: FormControl;
  toDate: FormControl;
  isDownloadLoading: boolean;
  selectedCustomer: ICustomerInfo;
  filter: CustomerScoreStatsFilter = new CustomerScoreStatsFilter();
  isPrintReport: boolean;

  @ViewChild(CustomerScoreStatsCard1Component, {static: true}) card1: CustomerScoreStatsCard1Component;
  @ViewChild(CustomerScoreStatsCard2Component, {static: true}) card2: CustomerScoreStatsCard2Component;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _toastrService: ToastrService,
    private _customerService: CustomerService) { 
    this.fromDate  = new FormControl();
    this.toDate  = new FormControl();
    this.formFilter = this.formBuilder.group({
      fromDate: this.fromDate,
      toDate: this.toDate,
    });
}

  ngOnInit(): void {
    this.isLoading = false;
    this.toDate.setValue(new Date(new Date()));
    const customerId = this.route.snapshot.paramMap.get('customerId');
    if (customerId) {
      this.getCustomerById(customerId)
    }
  }

  getCustomerById(customerId: string) {
    this.isLoading = true;

    
    this._customerService.getCustomerById(customerId).subscribe({
      next: (resp: any) => {
        if (resp.code == 200) {
          this.selectedCustomer =  resp.data;
          this.filter.customer = resp.data;
        }
        else {
        }
      },
      error: (error: any) => {
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  
  }

  changeFromDate(){
    const _ = moment();
    var dateformat = new Date(this.fromDate.value);
    dateformat.setHours(0, 0, 0);   
    const date = moment(dateformat).add({hours: _.hour(), minutes:_.minute() , seconds:_.second()})

    this.fromDate.setValue(date.toDate());
    this.filter.fromDate = this.fromDate.value;

  }

  changeToDate(){
    const _ = moment();
    var dateformat = new Date(this.toDate.value);
    dateformat.setHours(0, 0, 0);   
    const date = moment(dateformat).add({hours: _.hour(), minutes:_.minute() , seconds:_.second()})
    this.toDate.setValue(date.toDate());
    this.filter.toDate = this.toDate.value;

  }

  onCustomerSelection(customer: any) {
    if (customer != null){
      this.filter.customer = customer;
      // this.card1.show();
      // this.card2.show();

    }else{
      this.filter.customer = null;
      this.isPrintReport = false;
      this.canGenerateReport()
      this.card1.hide();
      this.card2.hide();
    }
  }

  reset(){
    this.fromDate.setValue('');
    this.toDate.setValue('');
    this.filter.fromDate = this.fromDate.value;
    this.filter.toDate = this.toDate.value;
  }

  canGenerateReport() {
    if (this.filter.customer) {
      return true;
    }
    return false;
  }



  generateReport() {
    if (this.formFilter.invalid)
    {
      this.fromDate.markAsTouched();
      this.toDate.markAsTouched();
      this.isPrintReport = false;
    } else {
      this.card1.fetchData();
      this.card2.fetchData();
      this.isPrintReport = true;
    }
  }

  printReport() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isDownloadLoading = true;
    let fileName = 'Score Stats Report';
    var page1 = document.getElementById('canvasContainer_Page1');

    let content = [];

    content.push({ text: 'Reports', style: 'infoHeader' });
    content.push({
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: '#148F77',
        },
      ],
    });
    content.push('\n');

    html2canvas(page1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL('image/png', 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });

      generatedDashboardPDF(content, fileName, null);
        this.isDownloadLoading = false;
        this._toastrService.clear();
        this._toastrService.success('PDF downloaded successfully.');

    });
  }
}
