import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class AddCustomerModel {
  @prop()
  id: number;

  @prop()
  customerId: any;

  @required()
  @maxLength({ value: 35 })
  @pattern({ expression: { Alpha: /^[a-zA-Z ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  firstName: string;

  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.type == false ? true : false;
    },
  })
  @maxLength({ value: 30 })
  @pattern({ expression: { Alpha: /^[a-zA-Z ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  lastName: string;

  @required()
  type: boolean = false;

  @required()
  @maxLength({ value: 50 })
  @pattern({
    expression: { Email: /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/ },
  })
  email: string;

  @required()
  @maxLength({ value: 30 })
  mobileNumber: string;

  @prop()
  @required()
  phone: any;

  @prop()
  @maxLength({ value: 500 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Alpha_Numbers: /^[a-zA-Z0-9 ]*$/ } })
  address1: string;

  @prop()
  @maxLength({ value: 500 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { Alpha_Numbers: /^[a-zA-Z0-9 ]*$/ } })
  address2: string;

  @prop()
  @maxLength({ value: 30 })
  @pattern({ expression: { Alpha: /^[a-zA-Z ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  city: string;

  @maxLength({ value: 3 })
  state: string;

  @prop()
  @maxLength({ value: 10 })
  @pattern({ expression: { 'Alpha_Numbers': /^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/ }, message :'Enter valid postal code (e.g. 12345, 12345-6789, A1B 2C3)' })
  zip: string;

  @prop()
  @maxLength({ value: 50 })
  @pattern({
    expression: {
      IPV46:
        /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/,
    },
  })
  allowedIpAddress: string;

  @prop()
  ipAddressList: string[];

  @prop()
  isActive: boolean = true;

  @prop()
  isCompany: boolean;

  @prop()
  isoCode: string;

  @prop()
  entryDate: Date;
}
