import { Component, OnInit } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-potential-loans',
  templateUrl: './potential-loans.component.html',
  styleUrls: ['./potential-loans.component.scss'],
})
export class PotentialLoansComponent implements OnInit{
  isLoading = false;
  isLoanDetailsPresent = false;
  loanDetails: any;
  na:string = "N/A";

  
  isPotentialLoansListPresent = false;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this._instantVerifyReportsService.FlagAndLoanDataChange.subscribe(
      (data) => {
        if (data) {
          this.isLoading = false;
          this.loanDetails = data;
          this.isPotentialLoansListPresent = this.loanDetails?.potentialLoansList?.length == 0
          ? false
          : true;
        } else {
          this.isLoading = false;
          this.loanDetails = null;
        }
      }
    );
  }
}
