import { FormGroup } from "@angular/forms";

export function findInvalidControls(formGroup: FormGroup) {
  const invalid = [];
  const controls = formGroup.controls;
  for (const name in controls) {
    if (controls[name].invalid) {
      invalid.push(name);
    }
  }
  if (invalid.length > 0) { console.log(JSON.stringify(invalid)); }
}

export function wrapString(messageText: string, length: number): string {
  if (messageText.length > length) {
    messageText = messageText.slice(0, length) + "...";
  }
  return messageText;
}