import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appDebitCreditFormat]'
})
export class DebitCreditFormatDirective {
  constructor(private el: ElementRef) {}
  @HostListener('input', ['$event'])
  onInput(event: any) {    
    const input = event.target;
    let trimmed = input.value.replace(/\s+/g, '');

    if (trimmed.length > 19) {
      trimmed = trimmed.substr(0, 19);
    }

    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }
    input.value = numbers.join(' ');
  }
}