import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-html-code',
  templateUrl: './html-code.component.html',
  styleUrls: ['./html-code.component.scss']
})
export class HtmlCodeComponent implements OnInit, OnChanges {

  @Input()
  paymentLink: string

  constructor(private _toastrService: ToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
  }

  copyHtmlCode() {
    if (this.paymentLink)
      this._toastrService.success(
        'HTML Code Link Copied successfully!'
      );
  }
}
