<mat-tab-group
  mat-align-tabs="start"
  animationDuration="0ms"
  [(selectedIndex)]="selectedTabIndex"
  (selectedTabChange)="onTabChange()"
>
  <mat-tab label="Account Summary">
    <app-current-statement
      *ngIf="loadCurrent"
      [tabIndex]="selectedTabIndex"
    ></app-current-statement>
  </mat-tab>
  <mat-tab label="Statements">
    <app-statement-history
      *ngIf="loadHistory"
      [tabIndex]="selectedTabIndex"
    ></app-statement-history>
  </mat-tab>
  <mat-tab disabled></mat-tab>
</mat-tab-group>
