import { pattern, prop, propArray, required } from "@rxweb/reactive-form-validators";
export class SendReminderModel {

    @prop()
    @required()
    @pattern({ expression: { 'Number': /^[0-9]*$/ } })
    days:number ;
}


export class ManageNotificationDetailsModel {
    
    @prop()
    companyId:any ;

    @prop()
    @required()
    paymentReminder:number ;

    @prop()
    @required()
    sendNotificationBy :number ;

    @prop()
    sendReminderM:any ;

    @prop()
    @required()
    sendReminder:string ;

    @prop()
    @required()
    dueDate:number ;

    @propArray(SendReminderModel)
    sendReminderList: SendReminderModel[];
}