<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxFlex="100%" fxLayoutAlign="start stretch">
        <div fxLayout="column">
            <div class="Header-Title">
                Challenger Score Settings
            </div>
        </div>
    </div>
    
    <div fxLayout="column" fxFlex="100%" fxLayoutGap="20px">
        <div *ngFor="let settingRule of this.dataSource?.settingRules; let j = index" class="span">
            <ng-container *ngIf="settingRule.ruleName!=='INCOME_VS_EXPENSES'">
                <app-range-deduct-card [dataSource]="settingRule" [ruleIndex]="j"  (onModelChanged)="onSettingRuleChanged($event)"></app-range-deduct-card>
            </ng-container>
            <ng-container *ngIf="settingRule.ruleName==='INCOME_VS_EXPENSES'">
                <app-ratio-max-point-card [dataSource]="settingRule" [ruleIndex]="j" (onModelChanged)="onSettingRuleChanged($event)"></app-ratio-max-point-card>
            </ng-container>
        </div>
        <div class="span" fxFlex="100%" fxLayout="row">
            <div fxFlex="50%" fxLayoutAlign="start">
                <button type="button" mat-stroked-button class="button-style" (click)="openDialog()">Restore</button>
                <div fxFlex="10px"></div>
                <button type="button" mat-stroked-button class="button-style" (click)="openDialogReset()">Reset</button>

            </div>
            
            <div fxFlex="50%" fxLayoutAlign="end">
                <button type="button" mat-stroked-button class="button-style" [disabled]="!canSave" (click)="saveRules()">Save</button>
            </div>
        </div>
    </div>    
</div>

<ng-template #callAPIDialog>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
          

        <div >
            <h3>We will restore your settings to Global Settings as default.</h3> 
            <h3>Continue to restore?</h3> 
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="restoreRules()">Yes</button> 
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>


<ng-template #callAPIDialogReset>
    <form #userForm="ngForm" >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="dialog-confirm">
            <div fxLayout="column" fxFlex="90%">
                <h2 style="padding-bottom: 3%;">Confirmation</h2> 
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              fxLayoutAlign="end stretch"
              style="margin-bottom: 25px"
            >
              <mat-icon [mat-dialog-close]>close</mat-icon>
            </div>
          </div>
          

        <div >
            <h3>We will reset your Finzeo Global Settings to values before edit.</h3> 
            <h3>Continue to Reset?</h3> 
        </div>
        <mat-dialog-actions align="center">
            <button mat-button  class="button-style" (click)="resetRules()">Yes</button> 
            <button mat-button matDialogClose="no" class="button-style-back">Cancel</button>
        </mat-dialog-actions>
    </form>
</ng-template>
