export enum TicketStatusConstant {
  Open = 'OPEN',
  Assigned = 'ASSIGNED',
  InProcess = 'IN PROCESS',
  Withdrawn = 'WITHDRAWN',
  Reopened = "REOPENED",
  Closed ="CLOSED",
  InformationRequired ="INFO REQ"
}

export enum InvoiceStatusConstant {
  Failed = 'FAILED',
  Paid = 'PAID',
  OnHold = 'ONHOLD',
  Pending = 'PENDING',
  Sent='SENT',
  Completed='COMPLETED',
  Successful='SUCCESSFUL',
  Incomplete='INCOMPLETE'
}
