import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TicketDetailsModel } from 'src/app/featureModules/models/ticket-details.model';
import { FileUploadService } from 'src/app/featureModules/services/file-upload.service';
import { TicketService } from 'src/app/featureModules/services/ticket.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {

  commentsList =[];
  attachmentsList :any[] =[];
  isLoading: boolean;
  loadMessage: string = 'Please wait..'; 
  ticketDetailsModel: TicketDetailsModel;
  ticketId:string;
  download_const ='DownloadImage';
  fileDownloadSubscription$: Subscription;

  constructor( private router: Router,
    private _ticketService: TicketService,
    private _toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private _fileUploadService : FileUploadService,
    private dialog: MatDialog) {
   }

  ngOnInit(): void {
    this.ticketDetailsModel = new TicketDetailsModel();
    this.ticketId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.ticketId) {
      this.getAllTicketDetails(this.ticketId);
    }    
  }

  navigateToBackPage(){
    this.router.navigate(['ticket']);
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  getAllTicketDetails(ticketId:string) {
    this.isLoading = true;
    this._ticketService.getTicketDetails(ticketId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if(data.data){
            this.initializeTicketDetails(data.data);
          } else {
            this._toastrService.error('Something went wrong, Please contact administrator!');
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }

  initializeTicketDetails(response: any){
    this.ticketDetailsModel.ticketNumber = response.ticketNumber;
    this.ticketDetailsModel.subject = response.subject;
    this.ticketDetailsModel.description = response.description;
    this.ticketDetailsModel.firstName = response.firstName;
    this.ticketDetailsModel.lastName = response.lastName;
    this.ticketDetailsModel.enteredBy = response.enteredBy;
    this.ticketDetailsModel.priority = response.priority;
    this.ticketDetailsModel.ticketStatus = response.ticketStatus;
    this.ticketDetailsModel.category = response.category;
    this.ticketDetailsModel.createdOn = response.createdOn;
    this.ticketDetailsModel.assignedTo = response.assignedTo;
    this.ticketDetailsModel.review = response.review;
    this.ticketDetailsModel.rating = response.rating;
    this.commentsList= response.comments;
    this.attachmentsList = response.attachmentList;
  }
  

  verifyAttachment(list:any){
    if(list && list.length > 0){
      return false;      
    }
    return true;
  }

  getGivenStarCount(data: any) {
    if (data > 0) {
      return parseInt(data);
    }
    return 0
  }
  
  getRemainStarCount(data: any) {
    if (data > 0) {
      return 5 - parseInt(data);
    }
    return 0
  } 

  formatFileSize(size : number){
    let iSize = (size / 1024); 
    let showSize ="";

      if (iSize / 1024 > 1) 
      { 
        if (((iSize / 1024) / 1024) > 1) 
        { 
            iSize = (Math.round(((iSize / 1024) / 1024) * 100) / 100);
            showSize = iSize + " GB"; 
        }
        else
        { 
            iSize = (Math.round((iSize / 1024) * 100) / 100);
            showSize = iSize + " MB"; 
        } 
      } 
      else 
      {
        iSize = (Math.round(iSize * 100) / 100)
        showSize = iSize + " KB"; 
      }

      return showSize;
  }

  onFileDownload(filePath: any,fileName:any) { 
    this._toastrService.info('Please wait.. Attachment is getting downloaded.');    
    this.fileDownloadSubscription$ = this._fileUploadService
    .downloadFile(filePath,fileName)
    .subscribe(
      (data) => {
        if (data != null) {                       
          FileSaver.saveAs( data ,fileName);                  
        } else {
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
}
  
  reopenTicket() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: 'REOPEN',
        successMessage: 'Are you sure you want to Reopen this ticket?',
        subSuccessMessage:
          'Clicking on Reopen button will Reopen ticket Again',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._ticketService.reOpenTicket(this.ticketId).subscribe((data) => {
          this.isLoading = false;
          if (data.code == 200) {
            this._toastrService.success('Ticket Reopened Successfully!'); 
            this.router.navigate(['ticket']);          
          }
        });
      }
    });
  }
}
