<!-- <button mat-flat-button color="primary" (click)="navigateToAch()">Login</button> -->
<div style="min-height: 100%;">
<div fxLayout="column" fxFlex="40%" fxLayout.xs="column" fxLayoutAlign="start stretch" style="background-color: #032859; text-align: center;">
    <app-left-image></app-left-image>
</div>

<div fxLayout="column" fxFlex="60%" >
    <div fxLayout="column" fxFlex="30%"  >
        
        <div class="form-Signin">
            <div>
                <div>
                    <p class="title">Sign In</p>
                </div>

                <form [formGroup]="signInForm"  (ngSubmit)="doLogin(signInForm.value)">

                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <mat-form-field appearance="outline" >
                            <mat-label>Company Id</mat-label>
                            <input
                              name="company"
                              class="form-control"
                              formControlName="company"
                              matInput
                              maxlength="9"
                              value ="" autocomplete="new-password"
                              required
                            />
                          </mat-form-field>
                    </div>
    
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                        <mat-form-field appearance="outline">
                            <mat-label>Email ID</mat-label>
                            <input
                              name="email"
                              class="form-control"
                              formControlName="email"
                              matInput
                              type="email"
                              value ="" autocomplete="new-password"
                              required
                            />
                          </mat-form-field>
                          <!-- [ngClass]="{ 'is-invalid': submitted && f['email'].errors }"  -->
                    </div>
    
                    <!-- An error message will appear, "Invalid Password" -->
                    <mat-error class="invalid-feedback" *ngIf="signInForm.errors?signInForm.errors['unauthenticated']:false">
                        <p> Invalid Email ID or Password entered. Please try again. </p>
                    </mat-error> 
                    
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" style="padding-bottom: 0px!important;">
    
                        <mat-form-field appearance="outline" style="padding-bottom: 0px!important;" >
                            <mat-label>Password</mat-label>
                            <input
                              style="padding-bottom: 0px!important;"
                              name="password"
                              class="password-input custom-password-input"
                              formControlName="password"
                              matInput
                              [type]="showPassword ? 'text' : 'password'"
                              value ="" autocomplete="new-password"
                              required
                            />  
                            <mat-icon matSuffix (click)="togglePasswordVisibility()">{{showPassword?'visibility_off':'visibility'}}</mat-icon>                          
                          </mat-form-field>
                    </div>
    
                    <div >
                        <button mat-button class="btn-Forget" (click)="forgotPassword()">Forgot Password ?</button>

                        <button mat-flat-button color="primary" [disabled]="!signInForm.valid"   style="width: 100%; height: 50px;" >SIGN IN</button>

                        <!-- <button mat-button class="btn-Reset"  >Reset Password</button> -->
                    </div> 
                </form>
                
            </div>

            
        </div>
    </div>
</div>

</div>