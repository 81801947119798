import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hosted-payment-page-tab-preview',
  templateUrl: './hosted-payment-page-tab-preview.component.html',
  styleUrls: ['./hosted-payment-page-tab-preview.component.scss']
})
export class HostedPaymentPageTabPreviewComponent implements OnInit {

  isSmallView: boolean;
  deviceType: string;

  @Input()
  changesIsLoading: boolean;
  
  @Input()
  finzeoPaymentOption:string;

  @Input()
  pageIndex: string;

  @Input()
  formData: any;
  
  constructor() { }

  ngOnInit(): void {
    this.isSmallView= false;
    this.deviceType = "tab";
  }

}
