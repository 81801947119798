import { pattern, prop, required } from '@rxweb/reactive-form-validators';

export class TicketDetailsModel {

  @prop()
  ticketNumber: string;
  
  @prop()
  subject: string;

  @prop()
  description: string;

  @prop()
  firstName:string;

  @prop()
  lastName:string;

  @prop()
  enteredBy:string;

  @prop()
  priority:string;

  @prop()
  ticketStatus:string;

  @prop()
  category: string;

  @prop()
  createdOn:Date;

  @prop()
  assignedTo:string;

  @prop()
  comments:string[];

  @prop()
  review:string

  @prop()
  rating:number

  @prop()
  attachments:[];

}