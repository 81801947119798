import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-invoice-dashboard-stats',
  templateUrl: './invoice-dashboard-stats.component.html',
  styleUrls: ['./invoice-dashboard-stats.component.scss']
})
export class InvoiceDashboardStatsComponent implements OnInit, OnChanges {

  @Input() 
  period:string;

  periodLastLabel:string='';
  periodCurrentLabel:string='';
  totalInvoiceCount: number = 0;
  totalInvoiceAmount: number = 0;
  currentTotalInvoiceCount: number = 0;
  currentTotalInvoiceAmount: number = 0;
  isLoading: boolean = true;
  loadMessage: string = 'Please wait..'
  isLastYearGreaterThanCurrent:boolean=false;
  
  constructor(
    private _toastrService: ToastrService,
    private _invoiceDashboardService:InvoiceDashboardService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.GetInvoiceCounts(this.period);
    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'Current Year';
      this.periodLastLabel = 'Last Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'Current Month';
      this.periodLastLabel = 'Last Month';
    }
    else {
      this.periodCurrentLabel = 'Current Week';
      this.periodLastLabel = 'Last Week';
    }
  }


  ngOnInit(): void {
  }

  GetInvoiceCounts(period) {
    this.isLoading = true;

    this._invoiceDashboardService.getInvoiceStats(period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.totalInvoiceCount = data.data.last.totalInvoiceCount;
          this.totalInvoiceAmount = data.data.last.totalInvoiceAmount;
          this.currentTotalInvoiceCount=data.data.current.totalInvoiceCount;
          this.currentTotalInvoiceAmount=data.data.current.totalInvoiceAmount;
          
          if(this.totalInvoiceCount > this.currentTotalInvoiceCount)
          {
            this.isLastYearGreaterThanCurrent = true;
          }
          else{
            this.isLastYearGreaterThanCurrent = false;
          }
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }
}
