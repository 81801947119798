import { Component, OnInit } from '@angular/core';
import { NonMfaLoginService } from '../../../services/non-mfa-login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayWhiteLabellingClientSettings } from 'src/app/sharedModules/models/display-whitelabellingclient-settings.model';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from 'src/app/blockModules/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { FaviconService } from 'src/app/sharedModules/services/favicon/favicon.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-non-mfa-login',
  templateUrl: './non-mfa-login.component.html',
  styleUrls: ['./non-mfa-login.component.scss']
})
export class NonMfaLoginComponent implements OnInit {

  private loggedIn = new BehaviorSubject<boolean>(false);
  uniqueToken: string;
  clientId: string;
  emailId: string;
  isLoading = false;

  constructor(
    private router: Router,
    private userManagementService: UserManagementService,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private nonMfaLoginService: NonMfaLoginService,
    private favicon: FaviconService,
    private storageService: StorageService,
    private jwtHelper: JwtHelperService) { }

  ngOnInit(): void {
    this.clientId = this.activatedRoute.snapshot.paramMap.get('clientId');
    this.emailId = this.activatedRoute.snapshot.paramMap.get('emailId');
    this.uniqueToken = this.activatedRoute.snapshot.paramMap.get('token');
    this.getMfaToken();
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  // Get the json value from local storage
  getJsonValue(key: string) {
    return this.storageService.secureStorage.getItem(key);
  }

  getMfaToken() {
    this.isLoading = true;
    this.nonMfaLoginService.getNonMfaToken(this.clientId, this.emailId, this.uniqueToken).subscribe(
      (res) => {
        this.isLoading = false;
        if (res.code == 200) {
          // debugger;
          const accessToken = this.jwtHelper.decodeToken(res.data['accessToken']);
          const parentOrigin = window.parent?.frames?.length > 0 ? window.parent[0]?.location : window.parent?.location;
          const ancestorOrigins = window.location.ancestorOrigins.length > 0 ? window.location.ancestorOrigins[0] : parentOrigin.origin; 
          
          let ancestorUrl = (new URL(ancestorOrigins));
          // console.log("window.location.ancestorOrigins: "+ ancestorUrl);
          let hostName = ancestorUrl.hostname;
          let parentHostname = parentOrigin.hostname;
          
          console.log("hostName: "+ hostName);
          console.log("accessToken?.allowedOrigins: "+ accessToken?.allowedOrigins);
          console.log("parentHostname: "+ parentHostname);

          if (hostName != null && hostName != "" && parentHostname != null && parentHostname != "" &&
            accessToken?.allowedOrigins.includes(parentHostname) && accessToken?.allowedOrigins.includes(hostName)) {
            if (res.data['themeSettings']) {
              var foundSettingsData = res.data['themeSettings'];
              localStorage.setItem('ClientThemeSettings', foundSettingsData);

              let displayWhiteLabellingClientSettings = new DisplayWhiteLabellingClientSettings();
              displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings = JSON.parse(res.data['themeSettings']);
              localStorage.setItem('logoUrl', displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoUrl']);
              localStorage.setItem('logotext', displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['LogoText']);
              localStorage.setItem('themecolor', displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeColor']);
              localStorage.setItem('themenaccolor', displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['ThemeNavColor']);
              localStorage.setItem('favicon', displayWhiteLabellingClientSettings.displayWhiteLabelClientWiseSettings['ClientPortalSettings']['FavIcon']);
            }
            let company = this.clientId;
            localStorage.setItem('CompanyId', company);

            let userEmail = this.emailId;
            sessionStorage.setItem('userEmail', this.emailId);
            sessionStorage.setItem(
              'isPasswordChangeRequired',
              res.data['isPasswordChangeRequired']
            );
            sessionStorage.setItem(
              'currentPassword',
              "password" + userEmail
            );

            localStorage.setItem('accessToken', res.data['accessToken']);
            let isAdminLogin = false;
            this.userManagementService.getUserProfile(isAdminLogin);
            this.loggedIn.next(true);
            localStorage.setItem('validUser', 'true');
            this._toastrService.success('Log in successfully');
            this.router.navigate(['/dashboard']);
            this.changeFavicon();
          } else {
            this.showErrorPage("UnAuthorized Access");
          }
        } else {
          this.showErrorPage("Invalid Token");
        }
      },
      (error) => {
        this.isLoading = false;
        this.showErrorPage("Invalid Token");
      });

  }

  changeFavicon() {
    if (localStorage.getItem('favicon')) {
      this.favicon.setFavicon(localStorage.getItem('favicon'));
    }

    if (localStorage.getItem('logotext')) {
      this.favicon.setTitle(localStorage.getItem('logotext'));
    }
  }

  showErrorPage(errorMessage: string) {
    this.router.navigate(['non-mfa/failure/' + this.clientId + '/' + this.emailId + '/' + this.uniqueToken], {
      state: {
        errorMessage:errorMessage
      },
    });
  }
}
