import { Component, Input, Output, EventEmitter } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-potential-payment-amounts',
  templateUrl: './potential-payment-amounts.component.html',
  styleUrls: ['./potential-payment-amounts.component.scss'],
})
export class PotentialPaymentAmountsComponent {
  isLoading = false;
  potentialPaymentAmount: any = 0;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  @Output() loadPotentialPaymentAmountsCardChange = new EventEmitter<boolean>();
  @Input('loadPotentialPaymentAmountsCard')
  set loadPotentialPaymentAmountsCard(
    loadPotentialPaymentAmountsCard: boolean
  ) {
    if (loadPotentialPaymentAmountsCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .PotentialPaymentAmountDetails(
          this.ibvBankVerificationId,
          this.accountType
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data != null) {
              this.potentialPaymentAmount = data.data;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }

    this.loadPotentialPaymentAmountsCardChange.emit(false);
  }

  resultPosition;
  getResultPosition(event: any) {
    this.resultPosition =
      this.potentialPaymentAmount > 3000 ? event - 90 : event - 10;
  }
}
