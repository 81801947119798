import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DBHelperService } from '../helper/db-helper.service';
import { API_BASE_URL } from '../../constants/url.const';
import { User } from '../../models/user.model';
import { UserLoginModel } from '../../models/user-login-model';

@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  public user: User;
  public userId: Number = 0;
  public userRoleId: Number = 0;
  public counter = 0;
  public isOverTimes:boolean = false
  public temporary_access_token:string='';

  baseUrl: string;
  // jwtHelper = new JwtHelperService();
  decodedToken: any;

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get currentUser() {
    return this.user;
  }

  // get currentUserId() {
  //   return this.userId;
  // }

  // get currentUserRoleId() {
  //   return this.userRoleId;
  // }

  get company() {
    return this.decodedToken[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata'
    ];
  }

  // get isAllowsCompanyCustomers(){
  //   return localStorage.getItem('acceptCompanyCustomer') == 'true';
  // }

  // //With respect to nre changes we are storing Selected CompanyId at the time of login in to JWT
  // //CompanyId is stored as Actor Schema and we are decoding it here for further usages.
  // get loggedInCompanyId()
  // {
  //   return this.decodedToken != undefined ? this.decodedToken['actort']: 0;
  // }

  constructor(
    private http: HttpClient,
    private router: Router,
    private dbHelperService: DBHelperService,
    @Inject(API_BASE_URL) baseUrl?: string
  ) {
    this.baseUrl = baseUrl + 'auth';

    const currentLogin = JSON.parse(
      localStorage.getItem('user') || '{}'
    ) as User;
    const currentToken = localStorage.getItem('token');
    if (currentLogin && currentToken) {
      // this.user = currentLogin;
      // this.userId=this.user.id;
      // this.userRoleId=this.user.roleId;
      // this.decodedToken = this.jwtHelper.decodeToken(currentToken);
      // this.loggedIn.next(true);
      // this.dbHelperService.loadAll();
    }
  }
  

  login(data: UserLoginModel) {
    return this.http.post(this.baseUrl + '/login', data).pipe(
      map((response: any) => {
        if (response) {
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.user));
          localStorage.setItem(
            'acceptCompanyCustomer',
            response.allowCompanyCustomers
          );
          this.user = response.user;
          this.userId = this.user.id;
          this.userRoleId = this.user.roleId;
          this.temporary_access_token = response.temporary_access_token
          // this.decodedToken = this.jwtHelper.decodeToken(response.token);
          this.loggedIn.next(true);
          this.dbHelperService.loadAll();
        }
      })
    );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('acceptCompanyCustomer');
    this.decodedToken = null;
    this.user = null;
    this.userId = 0;
    this.dbHelperService.clearCache();
    this.loggedIn.next(false);
  }

  // getRoles() {
  //   return this.http.get<RoleModel[]>(this.baseUrl + '/getroles');
  // }

  // roleMatch(...allowedRoles: number[]) {
  //   return allowedRoles.indexOf(this.currentUser.roleId) > -1;
  // }

  // validatePassword(p: string) {
  //   const regex = /[$-/:-?{-~!"^_@`\[\]]/g;
  //   const lowerLetters = /[a-z]+/.test(p);
  //   const upperLetters = /[A-Z]+/.test(p);
  //   const numbers = /[0-9]+/.test(p);
  //   const symbols = regex.test(p);

  //   const flags = [lowerLetters, upperLetters, numbers, symbols];

  //   let passedMatches = 0;
  //   for (const flag of flags) {
  //     passedMatches += flag === true ? 1 : 0;
  //   }

  //   return passedMatches >= 3;
  // }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  // navigateToLogin() {
  //   this.router.navigate(['/']);
  // }
}
