<mat-dialog-content class="content">
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutWrap
    fxLayoutGap="16px"
    fxLayoutAlign="start stretch"
    class="main-message"
    [innerHtml]="data.message"
  ></div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="btnYes"
    mat-button
    [style.--active-border-color]="data.bgcolor"
    [style.--active-background-color]="data.bgcolor"
    [style.--active-color]="data.color"
    [mat-dialog-close]="true"
  >
    YES
  </button>
  <button
    class="btnNo"
    mat-button
    [style.--active-color]="data.bgcolor"
    [style.--active-border-color]="data.bgcolor"
    [mat-dialog-close]="false"
  >
    NO
  </button>
</mat-dialog-actions>
