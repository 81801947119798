import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum CacheKeys {
  banks = 'banks',
  bankAccountTypes = 'bankAccountTypes',
  holidays = 'holidays',
  transactionStatus = 'transactionStatus',
  transactionType = 'TransactionType',
  transactionEntryTypes = 'transactionEntryTypes',
  transactionEntryCodes = 'transactionEntryCodes',
  transactionFrequencies = 'transactionFrequencies',
}

interface CacheItem {
  obj: any;
  objO: any;
  obj$: any;
}

@Injectable()
export class DBHelperService {
  private isLoading: { [key: string]: boolean } = {};
  private cacheItems: { [key: string]: CacheItem } = {};
  public transactioncount: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);

  constructor() // private transactionFrequenciesClient: TransactionFrequenciesClient, // private transactionTypesClient: TransactionTypesClient, // private transactionStatusClient: TransactionStatusClient, // private holidaysClient: HolidaysClient, // private banksClient: BanksClient,
  {}

  //Add as per need hence keeping raw class only

  clearCache(key?: string) {
    if (key) {
      if (this.cacheItems[key]) {
        delete this.cacheItems[key];
      }
    } else {
      this.cacheItems = {};
    }
  }

  loadAll() {
    // this.loadBankAccountTypes();
    // this.loadBanks();
    // this.loadHolidays();
    // this.loadTransactionStatus();
    // this.loadTransactionTypes();
    // this.loadTransactionEntryTypes()
  }
}
