<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToCustomersPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Customers
  </mat-label>
</div>

<div class="page-header-customer-details">
  <div fxLayout="column" fxLayoutGap="16px">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="30px"
      fxLayoutAlign="start stretch"
    >
      <div fxLayout="column">
        <mat-label class="page-header-userNameCircle">{{
          getNameInitials(customerModel?.firstName, customerModel?.lastName)
        }}</mat-label>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerName">
            {{ customerModel?.firstName }} {{ customerModel?.lastName }}
          </mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerEmail">
            Email/Unique id: &nbsp;&nbsp;
          </mat-label>
          <mat-chip-list>
            <mat-chip class="page-header-customerEmailChip">
              {{ customerModel?.email }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <span class="spacer"></span>
      <div *ngIf = "this.userRole != '1'" fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="onEditClick()"
          >
            EDIT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutWrap
  fxLayoutGap="16px"
  fxLayoutAlign="start stretch"
  style="padding-left: 1%"
>
  <mat-tab-group
    fxFlex="100%"
    animationDuration="0ms"
    [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="selectedTabChangeEvent()"
  >
    <mat-tab label="Details">
      <app-customer-details
        *ngIf="loadBasicDetails && customerModel"
        [isViewCustomerDetails]="isViewCustomerDetails"
        [isEditCustomerDetails]="isEditCustomerDetails"
        [customerModel]="customerModel"
        [userRole]="this.userRole"
      ></app-customer-details>
    </mat-tab>

    <mat-tab label="Bank & Card Details">
      <app-bank-and-card-details
        *ngIf="loadBankAndCard && customerModel?.customerId"
        [customerId]="customerModel?.customerId"
      >
      </app-bank-and-card-details>
    </mat-tab>

    <mat-tab label="Transactions">
      <app-customer-transactions
        *ngIf="loadTransactions&& customerModel?.customerId"
        [customerId]="customerModel?.customerId"
        [customerType]="customerModel?.type"
        [isActive]="customerModel?.isActive"
        [userRole]="this.userRole"
        [selectedTabIndex]="selectedTransactionTabIndex"
      >
      </app-customer-transactions>
    </mat-tab>

    <mat-tab disabled></mat-tab>
  </mat-tab-group>
</div>
