import { Component, Input, OnInit } from '@angular/core';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  InvoiceSetupDetailsModel,
  MaxNoPaymentsModel,
} from 'src/app/featureModules/models/invoice-setup-details.model';
import { FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingsModel } from 'src/app/featureModules/models/settings.model';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-invoice-setup',
  templateUrl: './invoice-setup.component.html',
  styleUrls: ['./invoice-setup.component.scss'],
})
export class InvoiceSetupComponent implements OnInit {
  @Input()
  settingsModel: SettingsModel;

  clientId: any;
  invoiceSetupData: any;
  isLoading: boolean = false;
  delete_const = 'delete';
  isDefaultLabel: boolean = false;
  addButtonClick: boolean = false;
  invoiceSetupFormGroup: FormGroup;
  invoiceSetupDetailsModel: InvoiceSetupDetailsModel;
  form: any;
  loadInvoicePreview: boolean = false;
  loadInvoicePreviewDark: boolean = false;
  invoiceSumValue: string;
  isSum: boolean = true;
  paymentCountValue: string;

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService,
    private _invoiceService: InvoiceService
  ) {}

  ngOnInit(): void {
    this.invoiceSetupDetailsModel = new InvoiceSetupDetailsModel();
    this.invoiceSetupDetailsModel.maxNoofPaymentsList =
      new Array<MaxNoPaymentsModel>();

    this.invoiceSetupFormGroup = this.formBuilder.formGroup(
      this.invoiceSetupDetailsModel
    );
    this.getInvoiceSetupSettings();
    this.addButtonClick = true;
  }

  getInvoiceSetupSettings() { 
    this.isLoading = true;
    this._invoiceService.getInvoiceSetupDetails().subscribe(
      (data) => {
        if(data.code == 200){
          this.settingsModel = data.data;             
          this.loadInvoiceSetupData();                    
        }
        else{
          this.settingsModel = new SettingsModel();
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;                
      },
      (error) => {
        if (error.error.message != null || error.error.message != undefined) {
          this._toastrService.warning(error.error.message);
        } else {
          this._toastrService.warning(error.error.content);
        }
        this.isLoading = false;
      }
    );
  }

  loadInvoiceSetupData() {
    this.invoiceSetupDetailsModel.saleTax = this.settingsModel.saleTax;
    this.invoiceSetupDetailsModel.policyText = this.settingsModel.policyText;
    this.invoiceSetupDetailsModel.invoiceNumberLabelType =
      this.settingsModel.invoiceNumberLabelType;
    this.invoiceSetupDetailsModel.customInvoiceNumberLabel =
      this.settingsModel.customInvoiceNumberLabel;
    this.invoiceSetupDetailsModel.invoiceSum = this.settingsModel.invoiceSum;
    this.invoiceSetupDetailsModel.interestPaymentCount =
      this.settingsModel.interestPaymentCount;
    this.invoiceSetupDetailsModel.interestFreePayments =
      this.settingsModel.interestFreePayments;
    this.invoiceSetupDetailsModel.applyInterest =
      this.settingsModel.applyInterest;
    this.invoiceSetupDetailsModel.paymentAmount =
      this.settingsModel.paymentAmount;
    this.invoiceSetupDetailsModel.maxPayments = this.settingsModel.maxPayments;
    this.invoiceSetupDetailsModel.isLightTheme =
      this.settingsModel.isLightTheme;
    if (this.settingsModel.interestFreePayments == 1) {
      this.isSum = true;
    } else if (this.settingsModel.interestFreePayments == 0) {
      this.isSum = false;
    }
    // 0:999:3:3 Months|1000:4999:6:6 Months|5000:9999:9:9 Months|10000:14999:11:11 Months
    let formControlList = this.invoiceSetupFormGroup.controls[
      'maxNoofPaymentsList'
    ] as FormArray;
    formControlList.clear();
    let dataList = this.settingsModel.maxPayments?.split('|');
    if (dataList?.length == 0 ||  dataList == null) {
      formControlList.push(this.newControlGeneration(0, 0, 0, ''));
    } else {
      dataList?.forEach((item: any) => {
        let row = item.split(':');
        formControlList.push(
          this.newControlGeneration(row[0], row[1], row[2], row[3])
        );
      });
    }
    this.invoiceSetupFormGroup.patchValue(this.invoiceSetupDetailsModel);
  }

  saveInvoiceSettings() {
    let result = this.modelListTostring(
      this.invoiceSetupFormGroup.controls['maxNoofPaymentsList'].value
    );
    this.invoiceSetupFormGroup.controls['maxPayments'].setValue(result);
    if (this.invoiceSetupFormGroup.valid) {
      this.isLoading = true;
      this._invoiceService
        .updateInvoiceSetupDetails(this.invoiceSetupFormGroup.value)
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.code == 200) {
              this._toastrService.success(
                'Invoice Setup Details updated successfully!'
              );
            } else {
              this._toastrService.warning(data.errorMessage);
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.warning(error.error.content);
            }
            this.isLoading = false;
          }
        );
    } else {
      this._toastrService.warning('Please fill all the required details');
      this.isLoading = false;
    }
  }

  resetInvoiceSetupForm() {
    this.invoiceSetupFormGroup.reset();
    this.invoiceSetupDetailsModel = new InvoiceSetupDetailsModel();
    Object.keys(this.invoiceSetupFormGroup.controls).forEach((key) => {
      const control = this.invoiceSetupFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  //1:2:2:Test|3:4:2:Test
  stringToModelList(maxPayments: string): MaxNoPaymentsModel[] {
    let maxNoOfPayments: any = [];
    if (maxPayments) {
      let c1 = maxPayments.split('|');
      c1.map((col) => {
        let row = col.split(':');
        maxNoOfPayments.push({
          min: parseInt(row[0]),
          max: parseInt(row[1]),
          installments: parseInt(row[2]),
          label: row[3],
        });
      });
      return maxNoOfPayments;
    } else {
      maxNoOfPayments = [{ min: 0, max: 0, installments: 0, label: '' }];
      return maxNoOfPayments;
    }
  }

  //1:2:2:Test|3:4:2:Test
  modelListTostring(_maxNoOfPayments: MaxNoPaymentsModel[]): string {
    let str = '';
    if (_maxNoOfPayments) {
      _maxNoOfPayments.map((mnp) => {
        str =
          str +
          mnp.min +
          ':' +
          mnp.max +
          ':' +
          mnp.installments +
          ':' +
          mnp.label +
          '|';
      });
      str = str.slice(0, -1);
      return str;
    }
    return str;
  }

  addMaxPaymentField() {
    let formControlList = this.invoiceSetupFormGroup.controls[
      'maxNoofPaymentsList'
    ] as FormArray;
    if (formControlList.length > 4) {
      this._toastrService.warning('You can not add more than 5 Options !!');
      return;
    }
    formControlList.push(this.newControlGeneration(0, 0, 0, ''));
  }

  removeMaxPaymentField(index: number): void {
    let formControlList = this.invoiceSetupFormGroup.controls[
      'maxNoofPaymentsList'
    ] as FormArray;
    if (formControlList.length >= 1) {
      formControlList.removeAt(index);
    } else {
      formControlList.push(this.newControlGeneration(0, 0, 0, ''));
    }
  }

  deleteButtonCondition() {
    let formControlList = this.invoiceSetupFormGroup.controls[
      'maxNoofPaymentsList'
    ] as FormArray;
    if (formControlList.length == 1) {
      return false;
    }
    return true;
  }

  onThemeSelected(event: any) {
    this.invoiceSetupFormGroup.controls['isLightTheme'].setValue(event.value);
  }

  onPreviewClick() {
    if (this.invoiceSetupFormGroup.controls['isLightTheme'].value == true) {
      this.loadInvoicePreview = true;
      this.loadInvoicePreviewDark = false;
    } else if (
      this.invoiceSetupFormGroup.controls['isLightTheme'].value == false
    ) {
      this.loadInvoicePreviewDark = true;
      this.loadInvoicePreview = false;
    }
  }

  onCloseClick() {
    this.loadInvoicePreview = false;
    this.loadInvoicePreviewDark = false;
  }


  onLabelTypeSelected(event: any) {
    this.invoiceSetupFormGroup.controls['invoiceNumberLabelType'].setValue(event.value);
    if (event.value === 1) {
      this.invoiceSetupFormGroup.controls['customInvoiceNumberLabel'].setValue('');
    }
    if(event.value == undefined) {
    this.invoiceSetupFormGroup.controls['invoiceNumberLabelType'].setValue(2);    
    }    
  }

  onInterestFreePaymentTypeSelected(event: any) {
    this.invoiceSetupFormGroup.controls['interestFreePayments'].setValue(
      event.value
    );
    this.invoiceSetupFormGroup.controls['paymentAmount'].setValue(0);
    if (event.value === 1) {
      this.invoiceSetupFormGroup.controls['interestPaymentCount'].setValue(0);
      this.invoiceSumValue = '0';
      this.isSum = true;
    } else if (event.value === 0) {
      this.invoiceSetupFormGroup.controls['invoiceSum'].setValue(0);
      this.paymentCountValue = '0';
      this.isSum = false;
    }
  }

  newControlGeneration(min, max, installments, label) {
    var newControl = this.formBuilder.formGroup(MaxNoPaymentsModel);
    var newItem = new MaxNoPaymentsModel();
    newItem.min = min;
    newItem.max = max;
    newItem.installments = installments;
    newItem.label = label;
    newControl.patchValue(newItem);
    return newControl;
    //formControlList.push(newControl);
  }

  getStyleForFormGroup(index) {
    return this.invoiceSetupFormGroup.controls['maxNoofPaymentsList'][
      'controls'
    ][index].valid
      ? { 'margin-bottom': '0%;' }
      : { 'margin-bottom': '2%;' };
  }

  getInvoiceSumValue(value: any) {
    this.invoiceSumValue = value;
    this.invoiceSetupFormGroup.controls['paymentAmount'].setValue(value);
  }

  getPaymentCountValue(value: any) {
    this.paymentCountValue = value;
    this.invoiceSetupFormGroup.controls['paymentAmount'].setValue(value);
  }

  getIconSrc(iconName: string) {
    return 'assets/settings-icons/' + iconName + '.png';
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }
}
