import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { ReportSearchModel } from 'src/app/sharedModules/models/report-search.model';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import html2canvas from 'html2canvas';
import { generatedDashboardPDF } from 'src/app/sharedModules/utils/pdf-utils';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  reportSearchFormGroup: FormGroup;
  isLoading: boolean;
  loadMessage: string = 'Please wait..';
  downloadAs: string;
  isMoreFilters: boolean = false;

  typeList = [
    { key: 'ACH', value: 'ACH' },
    { key: 'CREDITDEBIT', value: 'Credit/Debit' },
    // { key: 'INSTANTFUND', value: 'Instant Fund' }, // hiding the INF feature
    { key: 'ECHECK', value: 'E-Check' },
  ];

  processorList = [
    { key: 'All', value: 'All' },
    { key: 'BaseCommerce', value: 'Base Commerce' },
    { key: 'FirstNational', value: 'First National' },
  ];

  data: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Month', name: 'month', width: '10%' },
    { displayName: 'PENDING', name: 'pendingTotal', width: '8%' },
    { displayName: 'PENDING AMOUNT', name: 'pendingAmount', width: '7%' },
    { displayName: 'RETURNED', name: 'returnedTotal', width: '8%' },
    { displayName: 'RETURNED AMOUNT', name: 'returnedAmount', width: '7%' },
    { displayName: 'SUCCESS', name: 'successTotal', width: '8%' },
    { displayName: 'SUCCESS AMOUNT', name: 'successAmount', width: '7%' },
    { displayName: 'RECURRING', name: 'recurringTotal', width: '8%' },
    { displayName: 'RECURRING AMOUNT', name: 'recurringAmount', width: '7%' },
    { displayName: 'FAILED', name: 'failedTotal', width: '8%' },
    { displayName: 'FAILED AMOUNT', name: 'failedAmount', width: '7%' },
    { displayName: 'CANCELLED', name: 'cancelledTotal', width: '8%' },
    { displayName: 'CANCELLED AMOUNT', name: 'cancelledAmount', width: '7%' },
  ];

  cardColumns: TableColumn[] = [
    { displayName: 'Month', name: 'month', width: '10%' },
    { displayName: 'PENDING', name: 'pendingTotal', width: '8%' },
    { displayName: 'PENDING AMOUNT', name: 'pendingAmount', width: '7%' },
    { displayName: 'RETURNED', name: 'returnedTotal', width: '8%' },
    { displayName: 'RETURNED AMOUNT', name: 'returnedAmount', width: '7%' },
    { displayName: 'SUCCESS', name: 'successTotal', width: '8%' },
    { displayName: 'SUCCESS AMOUNT', name: 'successAmount', width: '7%' },
    { displayName: 'RECURRING', name: 'recurringTotal', width: '8%' },
    { displayName: 'RECURRING AMOUNT', name: 'recurringAmount', width: '7%' },
    { displayName: 'FAILED', name: 'failedTotal', width: '8%' },
    { displayName: 'FAILED AMOUNT', name: 'failedAmount', width: '7%' },    
  ];

  allColumns: TableColumn[];
  allCardColumns: TableColumn[];
  transactionGrid: boolean = false;
  minLengthTerm = 3;
  filteredCustomer: any[] = [];
  reportSearchModel: ReportSearchModel;
  preHeadingLabel: string = 'ACH';
  isShowNoFound: boolean = false;
  isDownloadLoading: boolean;
  minDate = new Date();
  maxDate = new Date();

  constructor(
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _customerService: CustomerService,
    private reportsService: ReportsService
  ) {
    this.allColumns = this.columns;
    this.allCardColumns = this.cardColumns;
    this.minDate.setFullYear(this.minDate.getFullYear() - 2);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
  }

  ngOnInit(): void {
    this.reportsService.generateReportModel = null;
    this.reportsService.moreFilterList = null;

    this.isShowNoFound = false;
    this.activatedRoute.paramMap.subscribe((params) => { });

    this.reportSearchModel = new ReportSearchModel();
    this.reportSearchModel.typeOfService = 'ACH';
    //this.reportSearchModel.processor = 'All';

    let startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 3);
    this.reportSearchModel.startDate = startDate;
    this.reportSearchModel.endDate = new Date();

    this.reportSearchFormGroup = this.formBuilder.formGroup(
      this.reportSearchModel
    );
    this.selectedServiceLabel();

    this.reportSearchFormGroup.controls['customerName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.reportSearchFormGroup.controls['customerName'].valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomer = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this._customerService.SearchCustomers(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.isShowNoFound = true;
          this.filteredCustomer = [];
        } else {
          this.isShowNoFound = false;
          this.filteredCustomer = data.data;
        }
      });
  }

  clearSelection() {
    this.reportSearchFormGroup.get('customerName').setValue(null);
    this.reportSearchFormGroup.get('customerId').setValue(null);
    this.filteredCustomer = [];
  }

  onSelected() {
    var selected = (this.filteredCustomer = this.filteredCustomer.filter(
      (customer: any) =>
        this.reportSearchFormGroup.get('customerName').value ==
        customer.firstName?.trim() + ' ' + customer.lastName?.trim()
    ));
    if (selected && selected[0])
      this.reportSearchFormGroup
        .get('customerId')
        .setValue(selected[0].customerId);
  }

  selectedCustomerName(customer: any) {
    let lastName  = customer.lastName?.trim() == undefined ? '' : customer.lastName?.trim()
    return customer.firstName?.trim() + ' ' + lastName;    
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
    }
    this.GetTotalTransactionAndAmount(this.reportSearchModel);
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    this.isLoading = true;
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
    }

    let locData = this.data;
    this.data = [];
    locData = locData.sort((a: any, b: any) => {
      const isAsc = event.direction === 'asc';
      return this.compare(a[event.active], b[event.active], isAsc);
    });

    setTimeout(() => {
      this.data = locData;
      this.isLoading = false;
    }, 100);
  }

  onCardSortChanged(event: SortChanged) {
    this.isLoading = true;
    var column = this.cardColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
    }

    let locData = this.data;
    this.data = [];
    locData = locData.sort((a: any, b: any) => {
      const isAsc = event.direction === 'asc';
      return this.compare(a[event.active], b[event.active], isAsc);
    });

    setTimeout(() => {
      this.data = locData;
      this.isLoading = false;
    }, 100);
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  searchReport() {
    if (this.isMoreFilters) {
      this.reportSearchModel = this.reportSearchFormGroup.value;
      this.transactionGrid = true;
      this.selectedServiceLabel();
      this.GetTotalTransactionAndAmount(this.reportSearchModel);
    } else {
      this.transactionGrid = false;
      this.reportSearchModel = this.reportSearchFormGroup.value;
    }
  }

  dateRangeChange() {
    if (
      this.reportSearchFormGroup.value.startDate &&
      this.reportSearchFormGroup.value.endDate
    )
      this.reportSearchModel = this.reportSearchFormGroup.value;
  }

  resetForm() {
    this.reportSearchFormGroup.reset();
    this.reportSearchFormGroup.markAsPristine();
    this.searchReport();
  }

  generateReport() {
    this.router.navigate(['reports/generate-report']);
  }

  originationReport() {
    this.router.navigate(['reports/origination-report-filter']);
  }

  GetTotalTransactionAndAmount(searchFields: any) {
    this.isLoading = true;

    searchFields.startDate = new Date(searchFields.startDate);
    searchFields.endDate = new Date(searchFields.endDate);
    searchFields.startDate?.setHours(12, 0, 0);
    searchFields.endDate?.setHours(12, 0, 0);
    searchFields.startDate = getSafeIsoDateString(searchFields.startDate);
    searchFields.endDate = getSafeIsoDateString(searchFields.endDate);

    this.reportsService.GetTotalTransactionAndAmount(searchFields).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.data = data.data.transctionData;
          this.page.length = data.data.totalRecords;
        } else {
          this.data = [];
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  selectedServiceLabel() {
    let filterData = this.typeList.filter(
      (obj) => obj.key == this.reportSearchModel.typeOfService
    );
    if (filterData && filterData[0]) this.preHeadingLabel = filterData[0].value;
    return this.preHeadingLabel;
  }

  moreFilters() {
    this.isMoreFilters = !this.isMoreFilters;
    if (!this.isMoreFilters) {
      this.transactionGrid = false;
      this.reportSearchFormGroup.reset();
    }
  }

  printReport() {
    this._toastrService.info('Please wait..PDF report is getting generated.');
    this.isDownloadLoading = true;
    let fileName = 'Reports';
    var page1 = document.getElementById('canvasContainer_Page1');
    var page2 = document.getElementById('canvasContainer_Page2');

    let content = [];

    content.push({ text: 'Reports', style: 'infoHeader' });
    content.push({
      canvas: [
        {
          type: 'line',
          x1: 0,
          y1: 2,
          x2: 515,
          y2: 2,
          lineWidth: 1,
          lineColor: '#148F77',
        },
      ],
    });
    content.push('\n');

    html2canvas(page1, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 2,
      allowTaint: true,
    }).then((canvasPage1) => {
      var imgWidth = 520;
      var imgHeight = (canvasPage1.height * imgWidth) / canvasPage1.width;

      content.push({
        image: canvasPage1.toDataURL('image/png', 0),
        margin: [0, 0, 0, 0],
        width: imgWidth,
        height: imgHeight,
      });
      content.push({ text: '\n', id: 'breakPage_1' });

      //To capture Tabs canvas separately on separate page
      html2canvas(page2, {
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        scale: 2,
        allowTaint: true,
      }).then((canvasPage2) => {
        var imgWidth = 520;
        var imgHeight = (canvasPage2.height * 480) / canvasPage2.width;

        content.push({
          image: canvasPage2.toDataURL('image/png', 0),
          margin: [0, 0, 0, 0],
          width: imgWidth,
          height: imgHeight,
        });

        generatedDashboardPDF(content, fileName, null);
        this.isDownloadLoading = false;
        this._toastrService.clear();
        this._toastrService.success('PDF downloaded successfully.');
      });
    });
  }

  showReset() {    
    return this.reportSearchFormGroup.dirty ? true : false;
  }
}
