import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  ChartConfiguration,
  ChartData,
  ChartEvent,
  ChartType,
  ChartOptions,
  ChartDataset,
} from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-labels';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseChartDirective } from 'ng2-charts';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-invoice-dashboard-total-invoices',
  templateUrl: './invoice-dashboard-total-invoices.component.html',
  styleUrls: ['./invoice-dashboard-total-invoices.component.scss']
})
export class InvoiceDashboardTotalInvoicesComponent implements OnInit ,OnChanges {

  @Input() 
  period:string;

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';
  chartData: any = [];
  chartLabelCurrent: string = 'This Year';
  chartLabelLast: string = 'Last Year';
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;


  constructor(
    private _toastrService: ToastrService,
    private router: Router,
    private _invoiceDashboardService:InvoiceDashboardService) { }

    ngOnChanges(changes: SimpleChanges): void {
       this.getTotalInvoiceCounts(this.period);
     }

    ngOnInit(): void {
    }

    getTotalInvoiceCounts(period) {
      this.isLoading = true;
  
      this._invoiceDashboardService.getTotalInvoiceCounts(period).subscribe(
        (data) => {
          this.isLoading = false;
          if (period.toLowerCase() == 'weekly') {
            this.chartLabelCurrent = 'This Week';
            this.chartLabelLast = 'Last Week';
          } else if (period.toLowerCase() == 'monthly') {
            this.chartLabelCurrent = 'This Month';
            this.chartLabelLast = 'Last Month';
          } else {
            this.chartLabelCurrent = 'This Year';
            this.chartLabelLast = 'Last Year';
          }
          if (data.data != null) {
            this.chartData = [];
            this.chartData.push(
              {
                data: [
                  data.data.last.paid,
                  data.data.last.unpaid,
                  data.data.last.pastDue,
                  data.data.last.returned,
                ],
                backgroundColor: ['#283593'],
                hoverBackgroundColor :['#283593'],                
                label: this.chartLabelLast,
              },
              {
                data: [
                  data.data.current.paid,
                  data.data.current.unpaid,
                  data.data.current.pastDue,
                  data.data.current.returned,
                ],
                backgroundColor: ['#1976D2'],
                hoverBackgroundColor : ['#1976D2'],
                label: this.chartLabelCurrent,
              }
            );
            this.barChartData.datasets = this.chartData;
            this.chart?.update();
          }
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
    }


   public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
        max:100,        
        ticks: {
          // Include a doller sign in the ticks
          callback: function (value, index, ticks) {
            return value;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom"        
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              tooltipItem.dataset.label +
              ': ' 
              +
              tooltipItem.formattedValue              
            );
          },
        },
      },
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData<'bar'> = {
    labels: ['Paid', 'Un-Paid', 'Past Due','Returned'],
    datasets: this.chartData,
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {
    // console.log(event, active);
  }

  navigateToInvoiceDetailsPage() {
    this.router.navigate(['invoices/invoices-details']);
  }
  

}
