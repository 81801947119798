<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToBackPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Invoice Dashboard
    </mat-label>
  </div>
  <div  class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Invoice</div>
        <div class="page-sub-header-text-1">
          All Invoice related data can be managed from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">        
        <button mat-raised-button color="primary" class="page-header-button-style" (click)="addNewCustomer()">
          ADD NEW CUSTOMER
        </button>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div class="invoice-gererate-form">
      <form [formGroup]="invoiceGenerateFormGroup">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
        class="applyfilterheader">
          <mat-label>Apply Filters</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch"
            class="form-group">          
          <div fxLayout="column" fxFlex="25%">
              <mat-form-field appearance="outline">
                <mat-label class="required">Search Customer Name</mat-label>
              <input matInput formControlName="customerName" name="customerName" class="required" [matAutocomplete]="auto"
                  placeholder="Customer Name (min 3 characters)" style="padding-bottom: 1%" pattern="{{ searchTextPattern }}" />
              <button *ngIf="this.invoiceGenerateFormGroup.get('customerName').value"
                  matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection()" >
                  <mat-icon>close</mat-icon>
              </button>
              <button matSuffix mat-icon-button>
                  <mat-icon>search</mat-icon>
              </button>
              <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="onSelected($event)" [displayWith] ="selectedCustomerName">                 
                  <mat-option *ngIf="isLoading" class="is-loading"
                  >Loading...</mat-option>
                  <ng-container *ngIf="!isLoading && filteredCustomer.length > 0">
                  <mat-option *ngFor="let customer of filteredCustomer"
                      [value]="customer">
                      <div class="name-email-style">
                      {{ customer.firstName?.trim() }}&nbsp;{{
                          customer.lastName?.trim()
                      }}
                      </div>
                      <div class="name-email-style sub-text">
                      {{ customer.email }}
                      </div>
                  </mat-option>
                  </ng-container>
                  <ng-container
                  *ngIf="isShowNoFound && !isLoading && !filteredCustomer.length">
                  <mat-option>
                      <div class="name-email-style">
                      <b>No Records Found</b>
                      </div>
                  </mat-option>
                  </ng-container>
              </mat-autocomplete>             
              </mat-form-field>
              <mat-error>
                {{ invoiceGenerateFormGroup.controls["customerName"]["_errorMessage"] }}
              </mat-error>
          </div>             
          <div fxLayout="column" fxFlex="25%"> 
              <mat-form-field appearance="outline">
              <mat-label class="required">From Date - To Date</mat-label>
              <mat-date-range-input
                  [rangePicker]="dateRangePicker"
                  [min]="minDate"
                  [max]="maxDate">
                  <input matStartDate placeholder="Start date" formControlName="startDate" name="startDate" readonly/>
                  <input matEndDate placeholder="End date" formControlName="endDate" name="endDate" readonly/>
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
              </mat-form-field>
              <mat-error>
                {{ invoiceGenerateFormGroup.controls["startDate"]["_errorMessage"] }}
              </mat-error>             
          </div>              
          <span class="spacer"></span>
          <div fxLayout="column" fxLayoutAlign="center end">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">        
                      <button mat-raised-button color="primary" class="page-header-button-style" (click)="onApplyClick()">
                          APPLY
                      </button>
                  </div>
          </div>
        </div>
      </form>
    </div>
</div>

<app-dynamic-invoice *ngIf= "dynamicInvoice" [startDate]="startDate" [endDate]="endDate" [customerId] ="customerId" 

[data]="invoiceData"></app-dynamic-invoice>




