<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Invoice Settings</div>
        <div class="page-sub-header-text-1">
          All Invoice and Email Setup related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start"
  style="padding-left: 1%" >

  <mat-tab-group fxFlex="100%" animationDuration="0ms">

    <mat-tab label="Invoice Setup">
      <app-invoice-setup  *ngIf="settingsModel" [settingsModel]="settingsModel"></app-invoice-setup>
    </mat-tab>

    <mat-tab label="Email Setup">
      <app-email-setup  *ngIf="settingsModel" [settingsModel]="settingsModel"></app-email-setup>
    </mat-tab>

    <mat-tab label="Manage Notifications">
      <app-manage-notification *ngIf="settingsModel" [settingsModel]="settingsModel"></app-manage-notification>
    </mat-tab>

    <!-- <mat-tab disabled></mat-tab> -->
  </mat-tab-group>
</div>

