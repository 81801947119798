<div
  fxLayout="column"
  class="loading-spinner"
  style="padding-top: 6%"
  *ngIf="isLoading"
  fxLayoutGap="10px"
>
  <div fxLayout="row">
    <mat-spinner [diameter]="50" [strokeWidth]="3"></mat-spinner>
  </div>
  <div
    fxLayout="row"
    *ngIf="loadMessage !== ''"
    [innerHtml]="loadMessage"
  ></div>
</div>
