import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { PaymentWidgetAchService } from '../../services/payment-widget-ach.service';
import { PaymentWidgetCardService } from '../../services/payment-widget-card.service';
import { Subscription, take, timer } from 'rxjs';
import { ConfirmationThemeDialogComponent } from '../../dialogs/confirmation-theme-dialog/confirmation-theme-dialog.component';
import { SessionTimeOutDialogComponent } from '../../dialogs/session-time-out-dialog/session-time-out-dialog.component';
import { FormGroup } from '@angular/forms';
import { EncryptDecryptService } from '../../services/encrypt-decrypt.service';
import { BillingDetailsModel } from '../../models/billing-details.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { CardDetailsModel } from '../../models/card-details.model';
import { AchDetailsModel } from '../../models/ach-details.model';
import { PaymentErrorConstants } from '../../constants/payment-error.const';
import { PaymentThemeModel } from '../../models/payment-theme.model';
import { LocalStorageService } from 'src/app/sharedModules/services/local-storage/local-storage.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { ToastrService } from 'ngx-toastr';
import { PageSettingModel } from '../../models/payment-page-customization.model';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss'],
})
export class PaymentInformationComponent implements OnInit {
  isLoading = false;
  amount: any;
  invoiceNumber: any;

  subscription: Subscription;
  logotext: string ;

  //For Timeout dialog
  countDown: Subscription;
  counter: number = 60 * 7;
  tick = 1000;
  widgetInfo: any;
  token: string;
  isRecurringDisabled: boolean;

  billingDetailsFormGroup: FormGroup;
  cardDetailsFormGroup: FormGroup;
  achDetailsFormGroup: FormGroup;
  paymentMethod: string = 'Card';

  //Theme and configurations based model
  pageSettingModel: PageSettingModel;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler($event: BeforeUnloadEvent) {
    $event.preventDefault();
    return confirm(
      'You have some unsaved form data. Are you sure, you want to leave this page ? '
    );
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service,
    private dialog: MatDialog,
    private paymentWidgetCardService: PaymentWidgetCardService,
    private paymentWidgetAchService: PaymentWidgetAchService,
    private encryptDecryptService: EncryptDecryptService,
    private formBuilder: RxFormBuilder,
    public localStorageService: LocalStorageService,
    private _toastrService: ToastrService
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.widgetInfo = navigation?.extras?.state['widgetInfo'];
    this.logotext = this.widgetInfo?.clientName ?? "FinZeo";
    this.invoiceNumber = this.widgetInfo?.invoiceNumber;
    this.amount = this.widgetInfo?.amount;
    this.isRecurringDisabled = this.widgetInfo?.isRecurringDisabled;
    // Use this once expiration update feature is developed, don't delete related code for same
    // this.counter = 60 * 7;
    // this.countdownTime();
  }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');
    if (this.token && !this.widgetInfo) {
      this.router.navigate(['/payment/' + this.token]);
    }
    
    this.getThemeFromLocalStorage();

    this.billingDetailsFormGroup = this.formBuilder.formGroup(
      new BillingDetailsModel()
    );

    this.billingDetailsFormGroup.controls['paymentType'].setValue('Card');

    this.cardDetailsFormGroup = this.formBuilder.formGroup(
      new CardDetailsModel()
    );
    this.achDetailsFormGroup = this.formBuilder.formGroup(
      new AchDetailsModel()
    );

    this.achDetailsFormGroup.controls['convenienceFees'].setValue(
      this.pageSettingModel?.convenienceFeeAmount
    );
  }
  
  getThemeFromLocalStorage() {
    let key = this.token;
    this.pageSettingModel = new PageSettingModel(
      this.localStorageService.getPaymentPageCustomizationBasedObjectByKey(key));
  }

  countdownTime() {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.openTimeOutDialog();
        }
      });
  }

  openTimeOutDialog() {
    const dialogRef = this.dialog.open(SessionTimeOutDialogComponent, {
      width: '450px',
      data: {
        color: this.pageSettingModel.themeTextColor,
        message:
          'Your secure session is about to expire. If you need more time, click Allow More Time.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.counter = 60 * 7;
        this.countdownTime();
      } else {
        window.location.replace('https://www.finzeo.com/');
        this.countDown.unsubscribe();
      }
    });
  }

  callCardPaymentApi() {     
    var requestData = {
      uniqueToken: this.token,
      companyName: this.billingDetailsFormGroup.value.companyName,
      firstName: this.billingDetailsFormGroup.value.firstName,
      lastName: this.billingDetailsFormGroup.value.lastName,
      mobileNumber: this.billingDetailsFormGroup.value.phone.e164Number,
      email: this.billingDetailsFormGroup.value.email,
      address1: this.billingDetailsFormGroup.value.address1,
      address2: this.billingDetailsFormGroup.value.address2,
      cardNumber: this.cardDetailsFormGroup.value.cardNumber,
      expDate: this.cardDetailsFormGroup.value.expirationDate,
      cvv: this.cardDetailsFormGroup.value.cvv,
      city: this.billingDetailsFormGroup.value.city,
      state: this.billingDetailsFormGroup.value.state,
      country: 'USA',
      zip: this.billingDetailsFormGroup.value.zip,
      nextPaymentDate: getSafeIsoDateString(
        this.cardDetailsFormGroup.controls['scheduledDate'].value
      ),
      billingCycle: this.cardDetailsFormGroup.value.isRecurring
        ? this.cardDetailsFormGroup.value.frequency
        : '',
      processOnEndOfMonth:
        this.cardDetailsFormGroup.value.isEndOfMonth ?? false,
      agreesToSurcharge:
        this.cardDetailsFormGroup.value.agreesToSurcharge ?? false,
      isRecurringTransaction: false,
      isInstallmentTransaction: this.cardDetailsFormGroup.value.isRecurring ?? false,
      totalInstallments: (this.cardDetailsFormGroup.value.isRecurring && this.cardDetailsFormGroup.value.installment)
        ? parseInt(this.cardDetailsFormGroup.value.installment) : 0,
      skipPayment: false
    };
    this.isLoading = true;
    this.paymentWidgetCardService.Sale(requestData).subscribe(
      (data) => {
        this.isLoading = false;
        this.router.navigate(['payment/confirmation/' + this.token], {
          state: {
            code: data.code,
            amount: this.amount,
            totalAmount: data.data.totalAmount,
            invoiceNumber: this.invoiceNumber,
            description: this.widgetInfo.description,
            logotext :this.widgetInfo.clientName,
            transactionDate: new Date(),
            paymentMethod: this.cardDetailsFormGroup.value.cardType,
            agreesToSurcharge:
              this.cardDetailsFormGroup.value.agreesToSurcharge ?? false,
            surchargeFee: data.data.surchargeAmount ?? 0,
            surchargePercentage: data.data.surchargePercent ?? 0,
            transactionId: data.data.transactionId,
            agreesToConvenienceFees: false,
            convenienceFees: 0,
            paymentMessage: 'Thank you. Your payment was successful.'
          },
        });
      },
      (error) => {
        this.isLoading = false;
        this.router.navigate(['payment/failure/' + this.token], {
          state: {
            errorMessage: PaymentErrorConstants.Exception,
            logotext : this.widgetInfo.clientName,
          },
        });
      }
    );
  }

  callAchPaymentApi() {
    var requestData = {
      widgetToken: this.token,
      customerType: !this.billingDetailsFormGroup.value.customerType,
      companyName: this.billingDetailsFormGroup.value.customerType
        ? null
        : this.billingDetailsFormGroup.value.companyName,
      firstName: this.billingDetailsFormGroup.value.customerType
        ? this.billingDetailsFormGroup.value.firstName
        : this.billingDetailsFormGroup.value.companyName,
      mobileNumber: this.billingDetailsFormGroup.value.phone.e164Number,
      lastName: this.billingDetailsFormGroup.value.customerType
        ? this.billingDetailsFormGroup.value.lastName
        : null,
      email: this.billingDetailsFormGroup.value.email,
      address1: this.billingDetailsFormGroup.value.address1,
      address2: this.billingDetailsFormGroup.value.address2,
      city: this.billingDetailsFormGroup.value.city,
      state: this.billingDetailsFormGroup.value.state,
      country: 'USA',
      zip: this.billingDetailsFormGroup.value.zip,
      amount: this.widgetInfo.amount,
      description: this.widgetInfo.description,
      isProcessOnEndOfMonth:
        this.achDetailsFormGroup.value.isEndOfMonth ?? false,
      scheduledDate: getSafeIsoDateString(
        this.achDetailsFormGroup.controls['scheduledDate'].value ?? new Date()
      ),
      frequency: this.achDetailsFormGroup.value.frequency,
      accountNumber: this.achDetailsFormGroup.value.accountNumber,
      routingNumber: this.achDetailsFormGroup.value.routingNumber,
      entryCode: this.achDetailsFormGroup.value.entryCode,
      agreesToConvenienceFees:
        this.achDetailsFormGroup.value.agreesToConvenienceFees ?? false,
      isRecurringTransaction: this.achDetailsFormGroup.value.isRecurring ?? false,
      isInstallmentTransaction: this.achDetailsFormGroup.value.isRecurring ?? false,
      installments: (this.achDetailsFormGroup.value.isRecurring && this.achDetailsFormGroup.value.installment)
        ? parseInt(this.achDetailsFormGroup.value.installment) : 0,
      skipPayment: false
    };

    this.isLoading = true;

    this.paymentWidgetAchService.SubmitAchPaymentWidget(requestData).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.code == 204) {
          this._toastrService.error(data.errorMessage);
        } 
        else if(data.code == 400 && data.errorMessage){
          this.isLoading = false;
          this._toastrService.error(data.errorMessage);
          this.router.navigate(['payment/confirmation/' + this.token], {
            state: {
              errorMessage: data.errorMessage,
              code: data.code,
              logotext : this.widgetInfo.clientName             
            },
          });
        }       
          else {
          this.router.navigate(['payment/confirmation/' + this.token], {
            state: {
              code: data.code,
              invoiceNumber: this.invoiceNumber,
              amount: this.amount,
              totalAmount:
                this.achDetailsFormGroup?.value?.totalAmount ?? this.amount,
              transactionDate: new Date(),
              paymentMethod: this.paymentMethod,
              agreesToSurcharge: false,
              surchargeFee: 0,
              surchargePercentage: 0,
              description: this.widgetInfo.description,
              logotext :this.widgetInfo.clientName,
              transactionId: data.data.transactionId,
              agreesToConvenienceFees:
                this.achDetailsFormGroup.value.agreesToConvenienceFees ?? false,
              convenienceFees:
                this.achDetailsFormGroup?.value?.convenienceFees ?? 0,
              paymentMessage: 'Thank you. Your transaction has been processed.'
            },
          });
        }
      },
      (error) => {
        this.isLoading = false;
        this.router.navigate(['payment/failure/' + this.token], {
          state: {
            errorMessage: PaymentErrorConstants.Exception,
            logotext : this.widgetInfo.clientName,
          },
        });
      }
    );
  }

  makePayment() {
    let condition =
      this.paymentMethod == 'Card'
        ? this.cardDetailsFormGroup.valid
        : this.achDetailsFormGroup.valid;
    if (condition && this.billingDetailsFormGroup.valid) {
      this.subscription = this.recaptchaV3Service
        .execute('makePayment')
        .subscribe((recaptcha) => {
          if (recaptcha) {
            this.paymentMethod == 'Card'
              ? this.callCardPaymentApi()
              : this.callAchPaymentApi();
          }
        });
    } else {
      this.billingDetailsFormGroup.markAllAsTouched();
      this.paymentMethod == 'Card'
        ? this.cardDetailsFormGroup.markAllAsTouched()
        : this.achDetailsFormGroup.markAllAsTouched();
    }
  }

  navigateToOrderSection() {
    this.router.navigate(['/payment/' + this.token]);
  }

  onCancelClick() {
    const dialogRef = this.dialog.open(ConfirmationThemeDialogComponent, {
      data: {
        color: this.pageSettingModel.themeTextColor,
        bgcolor: this.pageSettingModel.themeColor,
        message: 'Are you sure you want to cancel this payment?',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        window.location.replace('https://www.finzeo.com/');
      }
    });
  }

  getLoaderEvent(event) {
    this.isLoading = event;
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  billingDetailsFormGroupChange(event: any) {
    this.billingDetailsFormGroup = event;
  }

  getCardDetailsFormGroup(event: any) {
    this.cardDetailsFormGroup = event;
  }

  getAchDetailsFormGroup(event: any) {
    this.achDetailsFormGroup = event;
  }

  getPaymentMethod(event: any) {
    this.paymentMethod = event == 'payWithCard' ? 'Card' : 'ACH';
    this.billingDetailsFormGroup.controls['paymentType'].setValue(
      this.paymentMethod
    );
  }
}
