<mat-spinner *ngIf="isLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{loadMessage}}
</mat-spinner>
<div class="invocies-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="80%">
        <span class="totalInvoicesLbl">Total Invoices</span>
    </div>
    <div fxLayout="column" fxFlex="20%">
        <button data-html2canvas-ignore="true" mat-stroked-button color="primary" class="white-button-style reportBtn" (click)="navigateToInvoiceDetailsPage()">
            VIEW INVOICES
        </button>
    </div>
</div>
<div class="invoices-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="6%">
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
            <mat-label data-html2canvas-ignore="true" class="invoices-amount-style"> Number of Invoices </mat-label>
        </div>
    </div>
    <div fxLayout="column" fxFlex="94%">
        <canvas baseChart [data]="barChartData" [options]="barChartOptions" [plugins]="barChartPlugins"
            [type]="barChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"
            class="bar-chartcss">
        </canvas>
    </div>
</div>