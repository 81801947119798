import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-invoice-dashboard-total-amount',
  templateUrl: './invoice-dashboard-total-amount.component.html',
  styleUrls: ['./invoice-dashboard-total-amount.component.scss']
})
export class InvoiceDashboardTotalAmountComponent implements OnInit, OnChanges {

  
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'

  @Input()
  paymentStatus: string;

  @Input()
  period: string;

  periodCurrentLabel: string = '';
  periodLastLabel: string = '';

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor(private dashboardService: InvoiceDashboardService,
    private _toastrService: ToastrService) {
     // this.paymentStatus = "Paid";
     }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'Current Year';
      this.periodLastLabel = 'Last Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'Current Month';
      this.periodLastLabel = 'Last Month';
    }
    else {
      this.periodCurrentLabel = 'Current Week';
      this.periodLastLabel = 'Last Week';
    }
    this.GetInvoiceTransactionAmount(this.period);
  }

  GetInvoiceTransactionAmount(period: string) {
    this.isLoading = true;
    this.barChartData.datasets[0].label = this.periodLastLabel;
    this.barChartData.datasets[1].label = this.periodCurrentLabel;

    this.dashboardService.getInvoiceTransactionAmount(this.paymentStatus, period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          if (data.data.previous?.length == 7) { //weekly
            this.barChartData.labels = this.barChartWeeklyLabels;
            // last year
            this.barChartData.datasets[0].data = data.data.previous;
            this.barChartData.datasets[0].label = this.periodLastLabel;
           
            //this year
            let currentList = [];
            data.data.previous.forEach((element, index) => {
              if (data.data.current[index])
                currentList.push(data.data.current[index]);
              else
                currentList.push(0);
            });
            this.barChartData.datasets[1].data = currentList;
            this.barChartData.datasets[1].label = this.periodCurrentLabel;
            
            

          } else if (data.data.previous?.length == 5 ||
            data.data.previous?.length == 6) { // monthly
            this.barChartData.labels = this.barChartMonthlyLabels;
            // last year
            this.barChartData.datasets[0].data = data.data.previous;
            this.barChartData.datasets[0].label = this.periodLastLabel;
            //this year
            let currentList = [];
            data.data.previous.forEach((element, index) => {
              if (data.data.current[index])
                currentList.push(data.data.current[index]);
              else
                currentList.push(0);
            });
            this.barChartData.datasets[1].data = currentList;
            this.barChartData.datasets[1].label = this.periodCurrentLabel;

          } else if (data.data.previous?.length == 12) { // yearly
            this.barChartData.labels = this.barChartYearlyLabels;
            // last year
            this.barChartData.datasets[0].data = data.data.previous;
            this.barChartData.datasets[0].label = this.periodLastLabel;
            //this year
            let currentList = [];
            data.data.previous.forEach((element, index) => {
              if (data.data.current[index])
                currentList.push(data.data.current[index]);
              else
                currentList.push(0);
            });
            this.barChartData.datasets[1].data = currentList;
            this.barChartData.datasets[1].label = this.periodCurrentLabel;
          }
          
          this.barChartData.datasets[0].backgroundColor ='#283593';
          this.barChartData.datasets[0].borderColor ='#283593';
          this.barChartData.datasets[0].fill ='#283593';
          this.barChartData.datasets[0].pointHoverBackgroundColor ='#283593';
          this.barChartData.datasets[0].pointBackgroundColor ='#283593';
          this.barChartData.datasets[1].backgroundColor ='#1976D2';
          this.barChartData.datasets[1].borderColor ='#1976D2';
          this.barChartData.datasets[1].fill ='#1976D2';          
          this.barChartData.datasets[1].pointHoverBackgroundColor ='#1976D2';          
          this.barChartData.datasets[1].pointBackgroundColor ='#1976D2';
          
        }
        this.refresh_chart();
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  public barChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {}
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
        ticks: {
          // Include a $ sign in the ticks
          callback: function (value, index, ticks) {
            return '$' + value;
          },
        },
      },
    },
    plugins: {
      legend: { display: true, position: 'bottom', maxHeight: 45 },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return '$' + (+tooltipItem.formattedValue ? 
              (+tooltipItem.formattedValue)?.toFixed(2) : 
                (+tooltipItem.formattedValue == 0 ? (+tooltipItem.formattedValue).toFixed(2) : tooltipItem.formattedValue));
          }
        }
      }
    }
  };
  public barChartWeeklyLabels: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  public barChartMonthlyLabels: string[] = ['5', '10', '15', '20', '25', '30'];
  public barChartYearlyLabels: string[] = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  public barChartType: ChartType = 'line';

  public barChartData: ChartData<'line'> = {
    labels: this.barChartYearlyLabels,
    datasets: [
      { data: [], label: this.periodLastLabel },
      { data: [], label: this.periodCurrentLabel }
    ]
  };

  refresh_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = this.barChartData.labels;
        this.chart.chart.config.data.datasets = this.barChartData.datasets;
        this.chart.chart.update();
      }
    });
  }

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

}
