
import { ScoreSettingRuleCardModel, ScoreSettingRuleItemCardModel } from '../score-settings/models/score-settings-model';
import { AddError } from './validateRuleErrors';

export function validateScoreSettings(scoreSettingRule: ScoreSettingRuleCardModel) {
    if ('INCOME_VS_EXPENSES' !== scoreSettingRule.ruleName) {
        return validateRangeDeductScoreRule(scoreSettingRule);
    }
    return validateRatioOfMaxPointDeductScoreRule(scoreSettingRule)
}

function validateRangeDeductScoreRule(scoreSettingRule: ScoreSettingRuleCardModel) {
    var validateErrors = [];
    var validRanges = [];
    if (scoreSettingRule.scoreRules == null) {
        return validateErrors;
    }

    for (var i = 0; i < scoreSettingRule.scoreRules.length; i++) {
        var item = scoreSettingRule.scoreRules[i];
        validRanges[i] = true;
        if (item.from == null || item.to == null) {
            validateErrors.push(AddError(i.toString(), "Missing field settings"));
            validRanges[i] = false;
        }

        else if (item.from != null && item.to != null && item.from > item.to) {
            validateErrors.push(AddError(i.toString(), "The input range is invalid"));
            validRanges[i] = false;
        }

        else if (item.deduct < 0 || item.deduct > 1000) {
            validateErrors.push(AddError(i.toString(), "'Deduct score' must be greater than or equal to 0 and less than or equal to 1000"));
        }

        
        else if (item.from == item.to) {
            validateErrors.push(AddError(i.toString(), "The input range is invalid"));
            validRanges[i] = false;
        }

        
    }

    for (var i = 0; i < scoreSettingRule.scoreRules.length; i++) {
        var item1 = scoreSettingRule.scoreRules[i];

        for (var j = 0; j < i; j++) {
            if (validRanges[i] && validRanges[j]) {
                var item2 = scoreSettingRule.scoreRules[j];
                if (isOverlap(item1, item2) || isOverlap(item2, item1)) {
                    validateErrors.push(AddError(i.toString(), "Range of values in " + itemPosittion(i) + " line overlap range or duplicate with " + itemPosittion(j) + " line"));
                    validateErrors.push(AddError(j.toString(), "Range of values in " + itemPosittion(j) + " line overlap range or duplicate with " + itemPosittion(i) + " line"));
                }
            }
        }

        if (i > 0 && ((scoreSettingRule.scoreRules[i].from - scoreSettingRule.scoreRules[i-1].to > 1)
                        || scoreSettingRule.scoreRules[i].from == scoreSettingRule.scoreRules[i-1].to ))
            {
                validateErrors.push(AddError((i-1).toString(), "Item " + itemPosittion(i-1) + " must have 'to value' less than 1 point of 'from value' with item "+ itemPosittion(i)));
                validateErrors.push(AddError((i).toString(), "Item " + itemPosittion(i) + " must have 'from value' less than 1 point of 'to value' with item "+ itemPosittion(i-1)));
            }
    }

    return validateErrors;
}

function validateRatioOfMaxPointDeductScoreRule(scoreSettingRule: ScoreSettingRuleCardModel) {
    var validateErrors = [];

    if (scoreSettingRule.scoreRules == null) {
        return validateErrors;
    }

    for (var i = 0; i < scoreSettingRule.scoreRules.length; i++) {
        var item = scoreSettingRule.scoreRules[i];

        if (item.deduct < 0 || item.deduct > 1000) {
            validateErrors.push(AddError(i.toString(), "'Deduct' must greater than or equal 0 and less than or equal 1000"));
        }

        if (item.deduct == null) {
            validateErrors.push(AddError(i.toString(), "Missing field settings"));
        }
    }
    return validateErrors;
}

function isOverlap(item: ScoreSettingRuleItemCardModel, anotherItem: ScoreSettingRuleItemCardModel) {
    return (anotherItem.from ?? 0) <= (item.from ?? 0) && (item.from ?? 0) < (anotherItem.to ?? 1000);
}

function itemPosittion(position: number) {
    var item = "";
    position++;
    if (position == 1) {
        item = position + "st";
    }
    else if (position == 2) {
        item = position + "nd";
    }
    else if (position == 3) {
        item = position + "rd";
    }
    else {
        item = position + "th";
    }

    return item;
}