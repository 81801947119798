import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HostedPaymentService } from 'src/app/featureModules/services/hosted-payment.service';

@Component({
  selector: 'app-button-preview-and-code',
  templateUrl: './button-preview-and-code.component.html',
  styleUrls: ['./button-preview-and-code.component.scss']
})
export class ButtonPreviewAndCodeComponent implements OnInit, OnChanges {

  formData: any;

  @Input()
  paymentLinkFormGroup: any;

  @Input()
  buttonIsLoading: boolean;

  isLoading: boolean;
  isLinkGenerated: boolean = false;
  paymentLink: any;

  constructor(private _hostedPaymentService: HostedPaymentService,
    private _toastrService: ToastrService, private router: Router) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.formData = this.paymentLinkFormGroup.value;
  }

  ngOnInit(): void {
    this.formData = this.paymentLinkFormGroup.value;
  }

  msToHMS(ms: any) {
    let seconds = ms / 1000;
    let hours = (seconds / 3600);
    seconds = seconds % 3600;
    let minutes = (seconds / 60);
    seconds = seconds % 60;
    return hours.toFixed() + ":" + minutes.toFixed() + ":" + (seconds).toFixed();
  }

  GenerateButton() {
    this.formData = this.paymentLinkFormGroup.value;
    this.isLoading = true;
     
    if (this.formData.settingTabForm.specificDateTime) {
      if(this.formData.settingTabForm.specificDateTime < new Date().toISOString){ 
        this._toastrService.warning('Invalid Specific Date and Time !!');
        this.isLoading = false;
        return;
      } 
      var selectedDate = new Date(this.formData.settingTabForm.specificDateTime).getTime();
      var currentDate = new Date().getTime();
      let timeDifference = selectedDate - currentDate;
      this.formData.settingTabForm.specificTime = this.msToHMS(timeDifference);     
    }

    var requestModel = {
      linkType: this.formData.designTabForm.linkType,
      linkText: this.formData.designTabForm.linkText,
      linkPurpose: this.formData.designTabForm.linkPurpose,
      dateExpiring: this.formData.settingTabForm.dateExpiring,
      amount: +this.formData.pricingTabForm.totalAmount == 0 ? +this.formData.pricingTabForm.amount : +this.formData.pricingTabForm.totalAmount,
      linkDetails: JSON.stringify(this.formData),
      isActive: true
    }
    if (this.paymentLinkFormGroup.valid) {
      this._hostedPaymentService.addPaymentLinkCreationDetails(requestModel)
        .subscribe(
          (data) => {
            if (data.code == 201) {
              this.paymentLink = data.data.paymentLinkHtmlCode;
              this._toastrService.success(
                'Payment Link Generated successfully!'
              );
              this.isLinkGenerated = true;
              this.isLoading = false;
              this.paymentLinkFormGroup.markAsUntouched();
              this.paymentLinkFormGroup.disable();
            } else {
              this.isLoading = false;
              this._toastrService.warning(data.errorMessage);
            }
          },
          (error) => {
            if (
              error.error.message != null ||
              error.error.message != undefined
            ) {
              this._toastrService.warning(error.error.message);
            } else {
              this._toastrService.warning(error.error.content);
            }
            this.isLoading = false;
          }
        );
    } else {
      this.paymentLinkFormGroup.markAllAsTouched();
      this._toastrService.warning('Please fill all the required details');
      this.isLoading = false;
    }
  }

  resetEmailSetupForm() {
    this.paymentLinkFormGroup.reset();
    this.paymentLinkFormGroup.controls['designTabForm']['controls']['linkType'].setValue('Button');
    Object.keys(this.paymentLinkFormGroup.controls).forEach((key) => {
      const control = this.paymentLinkFormGroup.controls[key];
      control.markAsPristine();
      control.markAsUntouched();
    });
  }

  navigateToLinkGrid() {
    this.router.navigate(['setting/hosted-payment-link']);
  }

}
