import { Component, Inject,OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { SubmitReviewModel } from 'src/app/sharedModules/models/submit-review.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TicketService } from 'src/app/featureModules/services/ticket.service';


@Component({
  selector: 'app-submit-review-dialog',
  templateUrl: './submit-review-dialog.component.html',
  styleUrls: ['./submit-review-dialog.component.scss']
})
export class SubmitReviewDialogComponent implements OnInit {
  submitReviewFormGroup: FormGroup;
  submitReviewModel: SubmitReviewModel;
  isLoading: boolean = false;
  loadMessage :'Please wait...'
  rating : number = 2;
  starCount : number = 5;
  starColor : string = "primary";

  @Output()
  model:SubmitReviewModel;

  constructor(public dialogRef: MatDialogRef<SubmitReviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: RxFormBuilder,
    private _ticketService:TicketService,
    private _toastrService:ToastrService) { }

  ngOnInit(): void {

    this.submitReviewModel = new SubmitReviewModel();

    this.submitReviewFormGroup = this.formBuilder.formGroup(this.submitReviewModel);
  }

  onRatingChanged(event:any){
    this.submitReviewModel.rating = event;
  }

  submitReview(){
    this._ticketService
          .submitReview(this.data.id, this.submitReviewModel)
          .subscribe((data) => {
            this.isLoading = false;
            if (data.data != null) {
              this._ticketService.dialogRef.close();
              this._toastrService.success('Review Submited Successfully!');
            }
    });
  }
}
