<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="page-header-customer-details">
    <div class="User-Header">
        <mat-label>
            <button mat-icon-button (click) = "navigateToPage()">
                <mat-icon>arrow_back </mat-icon>
            </button>
            Statements 
        </mat-label>
    </div>
    <br />
    <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="30px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="5px">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <mat-label class="page-header-customerName">
                    </mat-label>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <mat-label class="title">
                        Statement: &nbsp;&nbsp;
                    </mat-label>
                    <mat-chip-list>
                        <mat-chip class="page-header-customerEmailChip">
                            From {{ fromDate }} to {{toDate}}
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <span class="spacer"></span>
            <div fxLayout="column" fxLayoutAlign="center end">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
                    <button mat-raised-button color="primary" class="page-header-button-style" (click)="onPrintClicked()">
                        GENERATE REPORT
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" style="padding-left: 1%;max-height:200%" id="print">

    <mat-card>
        <div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-label class="section-header">Account Summary</mat-label>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Company Name: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{ response?.companyName }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Account Number: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{ response?.accountNumber }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Routing Number: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{ response?.routingNumber }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>
            <div fxLayout="row" fxLayout.xs="column" style="margin-top:2%" fxLayoutWrap>
                <mat-label class="section-header">Activity Summary</mat-label>
            </div>
            <mat-divider></mat-divider>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Ending Account Balance As of {{response?.endDate | date: 'MM/dd/YY'}}: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{
                            dataShow(response?.endingAccountBalance)
                        }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Pending Deposits/ Debits: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{
                           dataShow(response?.pendingDebits)
                        }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Pending Withdrawals/ Credits: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{
                            dataShow(response?.pendingCredits)
                        }}
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>


            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <div fxFlex="30%">
                    <mat-label class="showDetailsName"> Available Balance: </mat-label>
                </div>
                <div fxFlex="60%">
                    <mat-label class="showDetailsValue">
                        {{
                            dataShow(response?.availableBalance)
                        }}                   
                    </mat-label>
                </div>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                <mat-divider></mat-divider>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <div fxFlex="100%">
                <app-data-table [columns]="columns" [data]="data" [isLoading]="isLoading"
                    [isLoadingPaging]="isLoadingPaging" [page]="page" [sortBy]="sortBy"
                    [noRecordMessage]="noRecordMessage" [actionDelete]="actionDelete" [showMoreMenu]="false"
                    [allColumns]="allColumns" [selection]="selection" [deletedFilter]="deletedFilter"
                    [actionRefresh]="actionRefresh" [isFilter]="false" [isExport]="false" [isActive]="false"
                    [isSearch]="false" [showPaginator]="false">
                </app-data-table>
            </div>
        </div>
    </mat-card>
</div>