import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchBankService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_IBV}Search`);
  }

  SearchByBankName(bankName: string): Observable<any> {
    return this._http.get<any>(this._base + '/SearchByBankName/' + bankName);
  }
}
