import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaviconService {
  setFavicon(newFavicon: string) {
    const linkElement = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (linkElement) {
      linkElement.href = newFavicon;
    }
  }

  setTitle(title: string) {
    title = title + ' Client';
    document.getElementById("favTitle").innerHTML = title;
  }

}