import { prop } from "@rxweb/reactive-form-validators";
import { HostedPaymentSendModel } from "src/app/sharedModules/models/hosted-payment-send.model";
import { environment } from 'src/environments/environment';

export class PaymentPageCustomizationModel {
    constructor() { }
    pageCustomizationSettings: HostedPaymentSendModel;
    allowPaymentOptions: string;
}

export class PageSettingModel {

    constructor(settingModel: PaymentPageCustomizationModel) {
        if (settingModel) {
            this.toViewModel(settingModel, `${environment.ADMIN_CONTAINER_PATH}`);
        }
    }

    @prop()
    logo: string;

    @prop()
    themeTextColor: string;

    @prop()
    themeColor: string;

    @prop()
    themeFont: string;

    @prop()
    isInvoiceNumberView: string = "hide";

    @prop()
    isDescriptionView: string = "hide";

    @prop()
    isPaymentOptionAch: boolean;

    @prop()
    isPaymentOptionCard: boolean;

    @prop()
    allowPaymentOptions: Array<string> = [];

    @prop()
    availRecurringForAch: boolean;

    @prop()
    availRecurringForCard: boolean;

    @prop()
    isShippingDetail: string = "hide";

    @prop()
    isAvailConvenienceFee: boolean = false;

    @prop()
    convenienceFeeAmount: number = parseFloat("0.00");

    toViewModel(settingModel: PaymentPageCustomizationModel, logoBaseUrl: string) {
        let configurationTabForm = settingModel?.pageCustomizationSettings?.configurationTabForm;
        let customisationTabForm = settingModel?.pageCustomizationSettings?.customisationTabForm;

        this.logo = customisationTabForm?.logo ? (logoBaseUrl + settingModel?.pageCustomizationSettings?.customisationTabForm?.logo) : "/assets/logo.png";
        this.themeTextColor = customisationTabForm?.themeTextColor ?? "#FFFFFF";
        this.themeColor = customisationTabForm?.themeColor ?? '#1A237E';
        this.themeFont = customisationTabForm?.themeFont ?? "Arial";
        this.isInvoiceNumberView = configurationTabForm?.isInvoiceNumberView ?? "hide";
        this.isDescriptionView = configurationTabForm?.isDescriptionView ?? "hide";
        this.isPaymentOptionAch = (configurationTabForm?.paymentOptionTypes?.ACH) ? true : false;
        this.isPaymentOptionCard = (configurationTabForm?.paymentOptionTypes?.Card) ? true : false;
        this.availRecurringForAch = (configurationTabForm?.paymentOptionTypes?.ACH?.isRecurring) ? true : false;
        this.availRecurringForCard = (configurationTabForm?.paymentOptionTypes?.Card?.isRecurring) ? true : false;
        this.isShippingDetail = configurationTabForm?.shippingDetails ?? "hide";

        this.isAvailConvenienceFee = configurationTabForm?.paymentOptionTypes?.ACH?.availConvenienceFee == "Yes" ?? true;
        this.convenienceFeeAmount = configurationTabForm?.paymentOptionTypes?.ACH?.convenienceFeeAmount ?? parseFloat("2.50");



        if(typeof(configurationTabForm?.paymentOptionTypes?.ACH?.availConvenienceFee) === "undefined"){
            this.isAvailConvenienceFee = false;
        }


        if(typeof(configurationTabForm?.paymentOptionTypes?.ACH?.convenienceFeeAmount) === "undefined"){
            this.convenienceFeeAmount = parseFloat("0.00");
        }
    }
}