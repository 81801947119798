import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';

@Component({
  selector: 'app-dashboard-pie-chart-reports',
  templateUrl: './dashboard-pie-chart-reports.component.html',
  styleUrls: ['./dashboard-pie-chart-reports.component.scss']
})
export class DashboardPieChartReportsComponent implements OnInit, OnChanges {

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'

  @Input()
  transactionType: string;

  @Input()
  period: string;

  @Input()
  showReturn: boolean = true;

  periodCurrentLabel: string = '';
  periodLastLabel: string = '';

  isReturnDataAvailable: boolean = false;
  isSuccessDataAvailable: boolean = false;
  isFailedDataAvailable: boolean = false;
  isPendingDataAvailable: boolean = false;
  isCancelledDataAvailable: boolean = false;

  isAdminDataAvailable: boolean = false;
  isHostileDataAvailable: boolean = false;
  isNonHostileDataAvailable: boolean = false;

  constructor(private dashboardService: DashboardService,
    private _toastrService: ToastrService, private router: Router) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    // added for change event event when changing the Compare data
    this.isReturnDataAvailable = false;
    this.isSuccessDataAvailable = false;
    this.isFailedDataAvailable = false;
    this.isPendingDataAvailable = false;
    this.isCancelledDataAvailable = false;

    this.isAdminDataAvailable = false;
    this.isHostileDataAvailable = false;
    this.isNonHostileDataAvailable = false;

    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'This Year';
      this.periodLastLabel = 'Last Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'This Month';
      this.periodLastLabel = 'Last Month';
    }
    else {
      this.periodCurrentLabel = 'This Week';
      this.periodLastLabel = 'Last Week';
    }
    if (this.transactionType == 'ach') {
      this.GetReturnRateData();
    }
    this.GetTransactionStatusPercentage(this.period);
  }

  GetReturnRateData() {
    this.isLoading = true;
    this.doughnutChartLabels = [this.periodLastLabel, this.periodCurrentLabel];
    this.doughnutChartAdminData.labels = this.doughnutChartLabels;
    this.doughnutChartHostileData.labels = this.doughnutChartLabels;
    this.doughnutChartNonHostileData.labels = this.doughnutChartLabels;

    this.dashboardService.GetTransactionsReturnedRatePercentage(this.period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.doughnutChartAdminData.datasets[0].data =
            [data.data.admin.previous, data.data.admin.current]

          this.doughnutChartHostileData.datasets[0].data =
            [data.data.hostile.previous, data.data.hostile.current];

          this.doughnutChartNonHostileData.datasets[0].data =
            [data.data.nonHostile.previous, data.data.nonHostile.current];

          this.isAdminDataAvailable = data.data.admin.previous == 0 && data.data.admin.current == 0 ? false : true;
          this.isHostileDataAvailable = data.data.hostile.previous == 0 && data.data.hostile.current == 0 ? false : true;
          this.isNonHostileDataAvailable = data.data.nonHostile.previous == 0 && data.data.nonHostile.current == 0 ? false : true;

          this.showReturn = (!this.isAdminDataAvailable && !this.isHostileDataAvailable && !this.isNonHostileDataAvailable) ? false : true;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  GetTransactionStatusPercentage(period: string) {
    this.isLoading = true;
    this.doughnutChartLabels = [this.periodLastLabel, this.periodCurrentLabel];
    this.doughnutChartAdminData.labels = this.doughnutChartLabels;
    this.doughnutChartHostileData.labels = this.doughnutChartLabels;
    this.doughnutChartNonHostileData.labels = this.doughnutChartLabels;
    this.doughnutChartReturnedData.labels = this.doughnutChartLabels;
    this.doughnutChartSuccessData.labels = this.doughnutChartLabels;
    this.doughnutChartPendingData.labels = this.doughnutChartLabels;
    this.doughnutChartFailedData.labels = this.doughnutChartLabels;
    this.doughnutChartCancelledData.labels = this.doughnutChartLabels;

    this.dashboardService.GetTransactionStatusPercentage(this.transactionType, period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.doughnutChartReturnedData.datasets[0].data =
            [data.data.returned.previous, data.data.returned.current]

          this.doughnutChartSuccessData.datasets[0].data =
            [data.data.success.previous, data.data.success.current];

          this.doughnutChartPendingData.datasets[0].data =
            [data.data.pending.previous, data.data.pending.current];

          this.doughnutChartFailedData.datasets[0].data =
            [data.data.failed.previous, data.data.failed.current];

          this.doughnutChartCancelledData.datasets[0].data =
            [data.data.cancelled.previous, data.data.cancelled.current];

          this.isReturnDataAvailable = data.data.returned.previous == 0 && data.data.returned.current == 0 ? false : true;
          this.isSuccessDataAvailable = data.data.success.previous == 0 && data.data.success.current == 0 ? false : true;
          this.isFailedDataAvailable = data.data.failed.previous == 0 && data.data.failed.current == 0 ? false : true;
          this.isPendingDataAvailable = data.data.pending.previous == 0 && data.data.pending.current == 0 ? false : true;
          this.isCancelledDataAvailable = data.data.cancelled.previous == 0 && data.data.cancelled.current == 0 ? false : true;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  public doughnutChartLabels: string[] = [this.periodLastLabel, this.periodCurrentLabel];
  public doughnutChartAdminData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#2196F3'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartHostileData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#FB8C00'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartNonHostileData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartReturnedData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Returned',
        data: [80, 20],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartSuccessData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Success',
        data: [20, 80],
        backgroundColor: ['#1E4ED8', '#4CAF50'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ]
  };

  public doughnutChartPendingData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Pending',
        data: [60, 40],
        backgroundColor: ['#1E4ED8', '#FB8C00'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartFailedData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Failed',
        data: [50, 50],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartCancelledData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Cancelled',
        data: [50, 50],
        backgroundColor: ['#1E4ED8', '#525252'],
        borderJoinStyle: 'round',
        borderWidth: 0
      }
    ],
  };

  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartReturnRateOptions: ChartOptions<'doughnut'> = {
    cutout: 50,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      }
    },
    radius: 60
  }
  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    cutout: 50,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right'
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + "%";
          }
        }
      }
    },
    radius: 60
  }

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  navigateToReportPage() {
    this.router.navigate(['dashboard/dashboard-report-service-based/' + this.transactionType])
  }

}
