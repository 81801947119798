import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { InstantBankVerifyModel } from 'src/app/featureModules/models/add-instant-bank-verify';

@Injectable({
  providedIn: 'root',
})
export class InstantVerifyService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_IBV}IBVMain`);
  }

  GetAllIbvBankVerificationRequests(
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    let baseUrl =
      this._base +
      '/GetAllIbvBankVerificationRequests?sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize;
    baseUrl = filterBy ? baseUrl + '&filterBy=' + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + '&sortBy=' + sortBy : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  SendInstantVerifyRequest(model: InstantBankVerifyModel): Observable<any> {
    return this._http.post<any>(
      this._base + '/SendInstantVerifyRequest',
      model
    );
  }

  ResendInstantVerifyRequest(ibvRequestId: any): Observable<any> {
    return this._http.get<any>(
      this._base + '/ResendInstantVerifyRequest/' + ibvRequestId
    );
  }
}
