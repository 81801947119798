import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
} from '@rxweb/reactive-form-validators';

export class DisplayBillingInvoiceModel {
  @prop()
  invoiceNo: string;

  @prop()
  clientInvoiceId: string;
  @prop()
  toDate: any;
  @prop()
  fromDate: any;
  @prop()
  scheduledDate: any;

  @prop()
  invoiceLogo : any;

  @prop()
  period: string;

  @prop()
  frequency: string;

  @prop()
  billAmount: string;

  @prop()
  paidAmount: string;

  @prop()
  dueAmount: string;

  @prop()
  noOfProducts: number;

  @prop()
  paymentStatus: any;

  @prop()
  action: any;
 @prop()
  parentProductDetails:ParentProductDetails[];
  @prop()
  clientInvoiceLineItem: InvoiceLineItemsModel[];
  @prop()
  subtotal: any;

  @prop()
  discount: any;

  @prop()
  salesTax: any;

  @prop()
  total: any;

  @prop()
  name: string;

  @prop()
  clientAddress: string;
  @prop()
  phone: string;
  @prop()
  email: string;
  @prop()
  trackingId: string;
}
export class ParentProductDetails
{
  @prop()
  ProductName: string;

  @prop()
  parentUnitPrice: any;

  @prop()
  parentTotalPrice: any;
  
  @prop()
  clientInvoiceLineItems: InvoiceLineItemsModel[];
  
}
export class InvoiceLineItemsModel {
  @prop()
  itemName: string;

  @prop()
  unitPrice: any;

  @prop()
  quantity: string;

  @prop()
  totalPrice: any;  
}
export class BillingInvoiceStats {
  @prop()
  totalInvoices: any;

  @prop()
  totalPaidAmount: any;

  @prop()
  totalDueAmount: any;

  @prop()
  failedpayment: any;
}

export class InvoiceBillingFilter {
  @prop()
  invoiceStatus: any;

  @prop()
  invoiceBillFromAmount: number;

  @prop()
  invoiceBillToAmount: number;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.scheduleEndDate != null ? true : false;
    },
  })
  scheduleStartDate: any;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {
      return this.scheduleStartDate != null ? true : false;
    },
  })
  scheduleEndDate: any;
}
