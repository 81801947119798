import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { CategoryFilterModel } from 'src/app/sharedModules/models/category-filter.model';
import { InventoryCategoryService } from 'src/app/featureModules/services/inventory-category.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-inventory-category-list',
  templateUrl: './inventory-category-list.component.html',
  styleUrls: ['./inventory-category-list.component.scss'],
})
export class InventoryCategoryListComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  categoryCountEvent = new EventEmitter<any>();

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  IsActive = null;
  countFilter: ListFilter;
  gotDataResponse = false;
  filteredCustomer: any[] = [];
  minLengthTerm = 3;
  isShowNoFound: boolean = false;
  customerId: string;
  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';

  advanceFilters: string = '';
  advanceFilterCount: number;
  advanceFiltersList: any;
  userRole :any;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Select', name: 'select', width: '3%' },
    { displayName: 'CATEGORY NAME', name: 'categoryName', width: '18%' },
    { displayName: 'PRODUCT COUNT', name: 'productCount', width: '15%' },
    { displayName: 'DISCOUNT', name: 'discount', width: '3%' },
    { displayName: '', name: 'discountType', width: '5%' },
    { displayName: 'SALES TAX(%)', name: 'salesTax', width: '13%' },
    { displayName: 'CREATED BY', name: 'enteredBy', width: '14%' },
    { displayName: 'CREATED ON', name: 'entryDate', width: '14%' },
    { displayName: 'STATUS', name: 'isActive', width: '8%' },
    { displayName: 'ACTION', name: 'action', width: '7%' },
  ];

  allColumns: TableColumn[];

  typeList = [
    { key: 'All', value: 'All Status' },
    { key: true, value: 'Active' },
    { key: false, value: 'Inactive' },
  ];

  filterFormGroup: FormGroup;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private dialog: MatDialog,
    private _categoryService: InventoryCategoryService,
    private _commonService: CommonService,
    private _toastrService: ToastrService
  ) {
    this.allColumns = this.columns;
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.filter = this._commonService.getListDataFromLocalStorage(
      'inventoryCategoryGridFilter',
      'inventory',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryCategoryGridPageIndex',
          'inventory',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryCategoryGridPageSize',
          'inventory',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'inventoryCategoryGridSortActive',
        'inventory',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'inventoryCategoryGridSortDirection',
        'inventory',
        'desc'
      ),
    };
    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'inventoryAdvanceFilter',
      'inventory',
      ''
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;
    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'inventoryAdvanceFilterForm',
        'inventory',
        JSON.stringify(new CategoryFilterModel())
      )
    );

    let filterModel = new CategoryFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  onDelete(id: string) {}

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    this.selection.clear();
    this._categoryService
      .getAllInventoryCategoriesGrid(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();
          if (data.data != null) {
            this.data = data.data.categories;
            this.page.length = data.data.totalRecords;
            var list = [];
            this.data?.map((item) => {
              let record = {
                id: item.id,
                categoryName: item.categoryName,
                productCount: item.itemCount,
                discount: item.discount,
                discountType: item.discountType,
                salesTax: item.salesTax,
                enteredBy: item.enteredBy,
                entryDate: item.entryDate,
                isActive: item.isActive,
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'inventoryCategoryGridFilter',
        'inventory'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'inventoryCategoryGridPageSize',
        'inventory'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'inventoryCategoryGridPageIndex',
        'inventory'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'inventoryCategoryGridSortActive',
        'inventory'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'inventoryCategoryGridSortDirection',
        'inventory'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'status':
        this.sortBy.active = 'isActive';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  productCountClick(event: any) {
    this._categoryService.categoryCountEvent.emit(event);
  }

  onDeleteClick(service: any) {
    let type = service.isActive ? 'Deactivate' : 'Activate';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: type.toUpperCase(),
        successMessage: "Are you sure you want to '" + type + "' this ?",
        subSuccessMessage:
          "Clicking on '" + type + "' button will update the status",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._categoryService
          .deactivateCategories(service.id, !service.isActive)
          .subscribe((data) => {
            this.isLoading = false;
            if (data.data != null) {
              this._toastrService.success(
                'Category status updated successfully!'
              );
              this.loadData(
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize,
                this.advanceFilters
              );
            }
          });
      }
    });
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      let filterModel = new CategoryFilterModel();
      filterModel.type = 'All';
      this.filterFormGroup = this.formBuilder.formGroup(filterModel);
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'inventoryCategoryGridPageSize',
          'inventory',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'inventoryCategoryGridSortActive',
        'inventory',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'inventoryCategoryGridSortDirection',
        'inventory',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if(data.type !='All')
    this.advanceFilters += 'isactive:' + data.type + '|';

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'inventoryAdvanceFilter',
      'inventory'
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'inventoryAdvanceFilterForm',
      'inventory'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  AddService() {
    this.router.navigate(['customers/manage-customer']);
  }

  deleteBasedOnSelection(status: any) {
    let type = status ? 'Activate' : 'Deactivate';
    let serviceIdList = this.selection['_selected'].map(({ id }) => id);
    if (this.selection.hasValue()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: true,
          buttonName: type,
          successMessage: "Are you sure you want to '" + type + "' this?",
          subSuccessMessage:
            "Clicking on '" + type + "' button will update the status",
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          let statusUpdate = {
            serviceIds: serviceIdList,
            status: status,
          };
          this._categoryService
            .bulkDeactivateCategories(statusUpdate)
            .subscribe((data) => {
              this.isLoading = false;
              if (data.code == 200) {
                this._toastrService.success(
                  'Multiple category status updated successfully!'
                );
                this.loadData(
                  this.filter,
                  this.sortBy.active,
                  this.sortBy.direction,
                  this.page.pageIndex,
                  this.page.pageSize,
                  this.advanceFilters
                );
              }
            });
        }
      });
    }
  }

  categoryEdit(categoryId: any) {
    this.router.navigate(['other/inventory/update-Category', categoryId]);
  }

  ngOnInit() {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.deletedFilterEvent.subscribe(this.onDeletedFilter.bind(this));
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!(this.router.url.includes('others') || this.router.url.includes('inventory'))){
      localStorage.removeItem('inventory');
    }  
  }
}
