import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { CustomerRefreshDetailsService } from 'src/app/featureModules/services/customer-profile-details.service';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customer-refresh-details',
  templateUrl: './customer-refresh-details.component.html',
  styleUrls: ['./customer-refresh-details.component.scss'],
})
export class CustomerRefreshDetailsComponent implements OnInit {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'REFRESH DATE', name: 'entryDate', width: '20%' },
    { displayName: 'CREATED BY', name: 'enteredBy', width: '20%' },
    { displayName: 'BANK NAME', name: 'bankName', width: '20%' },
    { displayName: 'REFRESH BY ', name: 'refreshType', width: '20%' },
    { displayName: 'ACTION', name: 'ibvaction', width: '20%' },
  ];

  allColumns: TableColumn[];
  customerModel: AddCustomerModel;

  @Input()
  customerId: any;
  @Input()
  bankVerificationId: any;

  @Input()
  instantVerifyId: any;

  userRole: any;
  
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private customerRefreshDetailsService: CustomerRefreshDetailsService,
    private _toastrService: ToastrService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'ibaRefreshGridFilter',
      'instantBankAnalysis',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaRefreshGridPageIndex',
          'instantBankAnalysis',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ibaRefreshGridPageSize',
          'instantBankAnalysis',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ibaRefreshGridSortActive',
        'instantBankAnalysis',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ibaRefreshGridSortDirection',
        'instantBankAnalysis',
        'desc'
      ),
    };

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  ngOnInit(): void {
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
    this.bankVerificationId =
      this.activatedRoute.snapshot.paramMap.get('bankVerificationId');

    this.instantVerifyId =
      this.activatedRoute.snapshot.paramMap.get('instantVerifyId');
    if (this.customerId) {
      this.getCustomerById();
    }

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
  }

  getCustomerById() {
    this.isLoading = true;
    this.customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.customerModel = data.data;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  onRefresh(id: string) {}

  onViewReportClick() {
    this.router.navigate(
      [
        'instantBankAnalysis/ibv-report',
        this.customerId,
        this.bankVerificationId,
      ],
      {
        state: {
          url:
            '/instantBankAnalysis/customer-refresh-details/' +
            this.customerId +
            '/' +
            this.bankVerificationId,
          backHeaderLabel: 'Banking / Instant Bank Analysis',
        },
      }
    );
  }

  navigateToInstantVerifyPage() {
    this.router.navigate(['instantBankAnalysis']);
  }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    this.isLoading = true;
    this.selection.clear();

    this.customerRefreshDetailsService
      .GetIBVRefreshReceiptListForCustomer(
        this.customerId,
        this.bankVerificationId,
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize
      )
      .subscribe(
        (data) => {
          if (data.data != null) {
            this.data = data.data.ibvRefreshReceiptRecordList;
            this.page.length = data.data.totalRecords;
            this.isLoading = false;
          } else {
            this.data = [];
            this.isLoading = false;
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'ibaRefreshGridFilter',
        'instantBankAnalysis'
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'ibaRefreshGridPageSize',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'ibaRefreshGridPageIndex',
        'instantBankAnalysis'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'ibaRefreshGridSortActive',
        'instantBankAnalysis'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'ibaRefreshGridSortDirection',
        'instantBankAnalysis'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }
  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  refreshBankTransaction(type: string) {
    switch (type) {
      case 'RefreshBanKTransaction':
        this.RefreshTransactions();
        break;
      case 'RefreshBanKBalance':
        this.RefreshBankBalance();
        break;
      case 'RefreshAll':
        this.RefreshAll();
        break;
    }
  }

  RefreshTransactions() {
    this.loadMessage =
      "<span>Please wait.<br />We're refreshing transaction details of associated account(s). This might take few moments.<span>";
    this.isLoading = true;
    this.customerRefreshDetailsService
      .RefreshTransactions(this.bankVerificationId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.loadMessage = '';

          if (data.code == 201) {
            this.sortBy = {
              active: 'entryDate',
              direction: 'desc',
            };
            this.loadData(
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize
            );
          } else if (
            data.code == 404 &&
            data.errorMessage.includes(
              'IBA request is not fully verified for this customer'
            )
          ) {
            this._toastrService.info(data.errorMessage);
          } else if (data.errorMessage != undefined) {
            this._toastrService.error(data.errorMessage);
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  RefreshBankBalance() {
    this.loadMessage =
      "<span>Please wait.<br />We're refreshing bank balance of associated account(s). This might take few moments.<span>";
    this.isLoading = true;
    this.customerRefreshDetailsService
      .RefreshBankBalance(this.bankVerificationId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.loadMessage = '';

          if (data.code == 201) {
            this.sortBy = {
              active: 'entryDate',
              direction: 'desc',
            };

            this.loadData(
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize
            );
          } else if (
            data.code == 404 &&
            data.errorMessage.includes(
              'IBA request is not fully verified for this customer'
            )
          ) {
            this._toastrService.info(data.errorMessage);
          } else if (data.errorMessage != undefined) {
            this._toastrService.error(data.errorMessage);
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  RefreshAll() {
    this.loadMessage =
      "<span>Please wait.<br />We're refreshing bank information of associated account(s). This might take few moments.<span>";
    this.isLoading = true;
    this.customerRefreshDetailsService
      .RefreshAll(this.bankVerificationId)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.loadMessage = '';

          if (data.code == 201) {
            this.sortBy = {
              active: 'entryDate',
              direction: 'desc',
            };
            this.loadData(
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize
            );
          } else if (
            data.code == 404 &&
            data.errorMessage.includes(
              'IBA request is not fully verified for this customer'
            )
          ) {
            this._toastrService.info(data.errorMessage);
          } else if (data.errorMessage != undefined) {
            this._toastrService.error(data.errorMessage);
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
