<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container class="margin">
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup" class="wd-100">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column" fxFlex="100%">
              <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select
                  class="form-control"
                  formControlName="type"
                  name="type"
                >
                  <mat-option *ngFor="let type of typeList" [value]="type.key">
                    {{ type.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [actionDelete]="actionDelete"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [selection]="selection"
      (actionDeleteEvent)="onDelete($event)"
      [deletedFilter]="deletedFilter"
      [actionRefresh]="actionRefresh"
      (actionRefreshEvent)="onRefresh($event)"
      (pageChangedEvent)="onPageChanged($event)"
      (serviceDeleteEvent)="onDeleteClick($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      (productCountClickedEvent)="productCountClick($event)"
      (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)"
      (serviceEditEvent)="categoryEdit($event)"
      [isCategory]="true"
      [isExport]="false"
      [advanceFilterCount]="advanceFilterCount"
      [filterText]="this.filter?.toString() ?? ''"
      [advanceFilterCount]="advanceFilterCount"
      [role]="this.userRole"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
