<mat-card class="dti-quick-view-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="dti-quick-view-card" fxLayout="column" fxFlex="100%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      fxFlex="100%"
      style="margin-bottom: 1%"
    >
    <div fxLayout="column" fxFlex="51%" fxLayoutGap="5%">
      <div fxLayout="row" fxLayout.xs="column"
      fxLayoutAlign="start stretch">
        <div fxLayout="column" >
          <img
            class="icons"
            src="assets/ibv-report-icons/checking_savings/sectionIcons/dtiview.png"
          />
        </div>
        <div fxLayout="column" fxLayoutWrap fxLayoutAlign="start start">
          <span class="section-header">DTI Quick Review</span>
        </div>
      </div>
      <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      >
        <div fxLayout="column">
          <span class="dti-percentage"
            >{{ dtiPercentage | number : "1.2-2" }}%</span
          >
      </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="49%">
    <div
     *ngIf="isChartDataAvailable"
      fxLayout="row"
      fxLayoutAlign="start stretch"
      fxLayoutGap="16px"
      fxLayoutWrap
    >
      <canvas
        height="166px"
        width="279px"
        baseChart
        [data]="dtiChartData"
        [type]="dtiChartType"
        [options]="dtiChartOptions"
      >
      </canvas>
      </div>
      <div
      fxLayout="row"
      *ngIf="!isChartDataAvailable"
      fxLayoutAlign="start stretch"
      >
      <span style="font-size: 15px; font-weight: 600;margin-top:40%;">No data found !</span>
       </div>
    </div>
    </div>
  </mat-card>
</div>
