export enum IbvAccountTypeShort {
  DDA = 'DDA',
  SDA = 'SDA',
  CCA = 'CCA',
  INV = 'INV',
  BPA = 'BPA',
}

export enum IbvAccountTypeFull {
  DDA = 'CHECKING',
  SDA = 'SAVINGS',
  CCA = 'CREDIT CARD',
  INV = 'INVESTMENT',
  BPA = 'OTHERS',
  CHECKING = 'DDA',
  SAVINGS = 'SDA',
  CREDIT_CARD = 'CCA',
  INVESTMENT = 'INV',
  OTHERS = 'BPA'
}