<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer
    #drawer
    position="end"
    class="apply-filter-mat-drawer-height"
    [ngClass]="{ 'mat-drawer-width-for-link': !advanceFilterOrViewDetails }"
  >
    <div *ngIf="advanceFilterOrViewDetails">
      <app-payment-link-advance-filter
        [filterFormGroup]="filterFormGroup"
        (drawerCloseEvent)="drawer.close()"
        (applyAdvanceFilterEvent)="applyAdvanceFilter()"
        (restFormEvent)="restForm()"
      >
      </app-payment-link-advance-filter>
    </div>
    <div *ngIf="!advanceFilterOrViewDetails">
      <app-payment-link-view-details
        [linkId]="linkId"
        (drawerCloseEvent)="drawer.close()"
      >
      </app-payment-link-view-details>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <div class="page-header-bottom">
      <div class="backHeader">
        <mat-label>
          <button mat-icon-button (click)="navigateToPage()">
            <mat-icon>arrow_back </mat-icon>
          </button>
          Settings
        </mat-label>
      </div>
      <div
        class="page-header"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
      >
        <div fxLayout="column" fxLayoutGap="5px">
          <div
            fxLayout="column"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <div class="page-header-text">
              Hosted payment page link generation
            </div>
            <div class="page-sub-header-text-1">
              All Hosted payment page generated links can be viewed and managed
              from this page
            </div>
          </div>
        </div>

        <span class="spacer"></span>
        <div fxLayout="column" fxLayoutAlign="center end">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <button
              mat-raised-button
              color="primary"
              class="page-header-button-style"
              (click)="AddPaymentLinkGeneration()"
            >
              CREATE PAYMENT LINK
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-data-table
      [isExport]="false"
      [isCategory]="true"
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [deletedFilter]="deletedFilter"
      (pageChangedEvent)="onPageChanged($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      (serviceDeleteEvent)="deleteBasedOnSelection($event)"
      (paymentLinkViewEvent)="paymentLinkView($event); drawer.toggle()"
      (paymentDuplicateLinkEvent)="paymentDuplicateLink($event)"
      [filterText]="this.filter?.toString() ?? ''"
      [advanceFilterCount]="advanceFilterCount"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
