import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-bank-quick-view',
  templateUrl: './bank-quick-view.component.html',
  styleUrls: ['./bank-quick-view.component.scss'],
})
export class BankQuickViewComponent {
  isLoading = false;
  balanceDetails: any;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  @Output() loadBankQuickViewCardChange = new EventEmitter<boolean>();
  @Input('loadBankQuickViewCard')
  set loadBankQuickViewCard(loadBankQuickViewCard: boolean) {
    if (loadBankQuickViewCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .BankQuickViewDetails(
          this.ibvBankVerificationId,
          this.accountType,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            this.isLoading = false;

            if (data.data != null) {
              this.balanceDetails = data.data;
              this._instantVerifyReportsService.BankQuickViewDataChange.emit(this.balanceDetails);
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }

    this.loadBankQuickViewCardChange.emit(false);
  }
}
