import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  selectedTabIndex = 0;
  loadAchTransaction: boolean = true;
  loadCreditDebit: boolean;
  loadInstantFund: boolean;
  loadEcheck: boolean;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
    const navigation = this.router.getCurrentNavigation();

    this.selectedTabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;
   }

  ngOnInit(): void {
    this.onTabChange();
  }

  onTabChange() {
    switch (this.selectedTabIndex) {
      case 0:
        this.loadAchTransaction = true;
        break;
      case 1:
        this.loadCreditDebit = true;
        break;
      case 2:
        //this.loadInstantFund = true;
        this.loadEcheck = true;
        break;
      case 3:
        this.loadEcheck = true;
        break;
    }
  }
}
