import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';

@Component({
  selector: 'app-dashboard-stats',
  templateUrl: './dashboard-stats.component.html',
  styleUrls: ['./dashboard-stats.component.scss']
})
export class DashboardStatsComponent implements OnInit, OnChanges {

  periodLastLabel:string='';
  periodCurrentLabel:string='';
  constructor(private dashboardService: DashboardService,
    private _toastrService: ToastrService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.GetCustomerCounts(this.period);
    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'Current Year';
      this.periodLastLabel = 'Last Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'Current Month';
      this.periodLastLabel = 'Last Month';
    }
    else {
      this.periodCurrentLabel = 'Current Week';
      this.periodLastLabel = 'Last Week';
    }
  }

  totalCustomers: number = 0;
  activeCustomers: number = 0;
  inActiveCustomer: number = 0;
  currentInActiveCustomer: number = 0;
  currentTotalCustomers: number = 0;
  currentActiveCustomers: number = 0
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  isLastYearGreaterThanCurrent:boolean=false;

  @Input() period;

  ngOnInit(): void {    
  }

  GetCustomerCounts(period) {
    this.isLoading = true;

    this.dashboardService.GetCustomerCounts(period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.currentTotalCustomers = data.data.current.total;
          this.activeCustomers = data.data.last.active;
          this.inActiveCustomer = data.data.last.inActive;
          this.currentInActiveCustomer = data.data.current.inActive;
          this.currentActiveCustomers = data.data.current.active;
          this.totalCustomers = data.data.last.total;
          if(this.totalCustomers > this.currentTotalCustomers)
          {
            this.isLastYearGreaterThanCurrent = true;
          }
          else{
            this.isLastYearGreaterThanCurrent = false;
          }
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }
}
