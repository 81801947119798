import { Component, Input, OnInit } from '@angular/core';
import { CustomerChallengerScoreComparisonRecord, IbvReportGenerationServiceProxy } from 'src/app/sharedModules/services/ibv/ibv.proxies';
import { GradeScoreStatsModel, CustomerScoreStatsFilter, IScoreGradeInfo } from '../models/customer-score-stats.models';

@Component({
  selector: 'customer-score-stats-card1',
  templateUrl: './customer-score-stats-card1.component.html',
  styleUrls: ['./customer-score-stats-card1.component.scss']
})
export class CustomerScoreStatsCard1Component implements OnInit {

  @Input() public filter: CustomerScoreStatsFilter;
  @Input() public clientName: string = 'Finzeo'; // TODO Vincent
  @Input() public canvasWidth = 350;
  @Input() public centralLabel = '';
  @Input() public name = '';
  @Input() public optionsCustomer: any;
  @Input() public optionsClient: any;
  @Input() public optionsSystem: any;
  @Input() public options: any;

  // Customer
  public customerScoreSettings: GradeScoreStatsModel[];
  public customerScoreData: IScoreGradeInfo;
  public get bottomLabelCustomer() {
    return `${Math.trunc(this.customerScoreData.score)}`;
  }

  public get needleValueCustomer() {
    return this.customerScoreData.score / 10;
  }

  // Current Client
  public currentClientScoreSettings: GradeScoreStatsModel[];
  public currentClientScoreData: IScoreGradeInfo;
  public get bottomLabelClient() {
    return `${Math.trunc(this.currentClientScoreData.score)}`;
  }
  public get needleValueClient() {
    return this.currentClientScoreData.score / 10;
  }

  // System
  public allClientScoreSettings: GradeScoreStatsModel[];
  public allClientScoreData: IScoreGradeInfo;
  public get bottomLabelSystem() {
    return `${Math.trunc(this.allClientScoreData.score)}`;
  }
  public get needleValueSystem() {
    return this.allClientScoreData.score / 10;
  }

  public get colorsSettings(): GradeScoreStatsModel[] {
    return this.customerScoreSettings;
  }
  isLoading: boolean = true;
  isDisplay: boolean = false;
  
  constructor(private _scoreService: IbvReportGenerationServiceProxy) {
    const settings =  [
      new GradeScoreStatsModel("A", 0 , 20, "rgba(112,173,71,255)"),
      new GradeScoreStatsModel("B", 20 , 40, "rgba(5,99,193,255)"),
      new GradeScoreStatsModel("C", 40 , 60, "rgba(255,192,0,255)"),
      new GradeScoreStatsModel("D", 60 , 80, "rgba(133,192,251,255)"),
      new GradeScoreStatsModel("F", 80 , 100, "rgba(255,101,102,255)"),
    ];
    this.customerScoreSettings = settings;
    this.currentClientScoreSettings = settings;
    this.allClientScoreSettings = settings;
  }

  ngOnInit(): void {
    
  }

  hide() {
    this.isDisplay = false;
  }

  show() {
    this.isDisplay = true;
  }

  fetchData() {
    this.show();
    this.isLoading = true;
    if (!this.filter?.customer?.customerId) {
      return;
    }

    this._scoreService.getCustomerChallengerScoreComparison(this.filter?.customer?.customerId, this.filter?.fromDateKey, this.filter?.toDateKey).subscribe({
      next: (resp: any) => {
      console.log(resp)

        if (resp.code == 200) {
          const data = resp.data as CustomerChallengerScoreComparisonRecord;
          this.customerScoreData = data.customer;
          this.currentClientScoreData = data.currentClient;
          this.allClientScoreData = data.allClients;
          var clientSettings = data.clientSettingsModel.gradeSettings.map(s => GradeScoreStatsModel.assignFrom(s));
          var globalSettings = data.globalSettingsModel.gradeSettings.map(s => GradeScoreStatsModel.assignFrom(s));
          this.optionsCustomer = this.optionsClient = this.getChartOptions(clientSettings);
          this.optionsSystem = this.getChartOptions(globalSettings);
        }
        else {
          this.hide();
        }
      },
      error: (error: any) => {
        this.hide();
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  getChartOptions(modelSettings : GradeScoreStatsModel[]){
    const arcDelimiters = modelSettings.map(l => (l.to  * 100 / 1000).toFixed(0)).reverse().slice(0, 4);
    const arcLabels  = modelSettings.map(l => l.to).reverse().slice(0, 4);
    const arcColors  = modelSettings.map(l => l.color).reverse();
    this.options = {
      hasNeedle: true,
      needleColor: 'black',
      needleUpdateSpeed: 100,
      arcColors: arcColors,
      arcDelimiters: arcDelimiters,
      rangeLabel: ['0', "1000"],
      arcLabels: arcLabels,
    }
    return this.options;
  }

}
