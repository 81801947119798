<mat-tab-group mat-align-tabs="start" animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
    (selectedTabChange)="onTabChange()">
    <mat-tab label="ACH Transactions">
        <app-ach-transactions-summary *ngIf="loadAchTransaction"></app-ach-transactions-summary>
    </mat-tab>
    <mat-tab label="Credit/Debit">
        <app-creditdebit-transactions-summary *ngIf="loadCreditDebit" [tabIndex]="selectedTabIndex"></app-creditdebit-transactions-summary>
    </mat-tab>
    <!-- hiding the INF feature -->
    <!-- <mat-tab label="Instant Fund">
        <app-instantfund-transactions-summary *ngIf="loadInstantFund" [tabIndex]="selectedTabIndex"></app-instantfund-transactions-summary>
    </mat-tab> -->
    <mat-tab label="E - Check">
        <app-echeck-transactions-summary *ngIf="loadEcheck" [tabIndex]="selectedTabIndex"></app-echeck-transactions-summary>
    </mat-tab>
    <mat-tab disabled></mat-tab>
</mat-tab-group>