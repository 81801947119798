import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { ReportSearchModel } from 'src/app/sharedModules/models/report-search.model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
@Component({
  selector: 'app-reports-stats',
  templateUrl: './reports-stats.component.html',
  styleUrls: ['./reports-stats.component.scss'],
})
export class ReportsStatsComponent implements OnInit, OnChanges {
  @Input()
  transactionGrid: boolean = false;

  @Input()
  searchFields: any = new ReportSearchModel();

  reportStats: any;

  constructor(
    private _toastrService: ToastrService,
    private reportsService: ReportsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.reportStats = null;
    if (this.transactionGrid) {
      this.GetReportStatsBasedOnFilter();
    } else {
      this.GetAllCustomersStats();
    }
  }

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';

  ngOnInit(): void {}

  GetReportStatsBasedOnFilter() {
    this.isLoading = true;

    this.searchFields.startDate = new Date(this.searchFields.startDate);
    this.searchFields.endDate = new Date(this.searchFields.endDate);
    this.searchFields.startDate?.setHours(12, 0, 0);
    this.searchFields.endDate?.setHours(12, 0, 0);

    this.reportsService
      .GetReportStatsBasedOnFilter(
        this.searchFields.customerId,
        this.searchFields.typeOfService,
        this.searchFields.processor,
        getSafeIsoDateString(this.searchFields.startDate),
        getSafeIsoDateString(this.searchFields.endDate)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.reportStats = data.data;
          }
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  GetAllCustomersStats() {
    this.isLoading = true;

    this.searchFields.startDate = new Date(this.searchFields.startDate);
    this.searchFields.endDate = new Date(this.searchFields.endDate);
    this.searchFields.startDate?.setHours(12, 0, 0);
    this.searchFields.endDate?.setHours(12, 0, 0);

    this.reportsService
      .GetAllCustomersStats(
        getSafeIsoDateString(this.searchFields.startDate),
        getSafeIsoDateString(this.searchFields.endDate)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.reportStats = data.data.customeRecords;
          }
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }
}
