<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>
<div class="page-header-bottom">
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxFlex="60%">
      <div class="page-header-text">E-Check Transactions Summary</div>
      <div class="page-sub-header-text-1">
        All individual & company transactions related data can be created from
        this page
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAccess && !isLoading">
  <app-data-table
    [columns]="columns"
    [data]="gridData"
    [isLoading]="isLoading"
    [isLoadingPaging]="isLoadingPaging"
    [page]="page"
    [sortBy]="sortBy"
    [noRecordMessage]="noRecordMessage"
    [showMoreMenu]="true"
    [allColumns]="allColumns"
    [selection]="selection"
    [actionRefresh]="actionRefresh"
    (actionRefreshEvent)="onRefresh($event)"
    (pageChangedEvent)="onPageChanged($event)"
    (rowClickedEvent)="onRowClick($event)"
    (sortChangedEvent)="onSortChanged($event)"
    (onFilterEvent)="onFilter($event)"
    [isFilter]="false"
    [isExport]="false"
    [isAddBtn]="true"
    (navigationToPageEvent)="navigationToPage()"
    [filterText]="this.filter?.toString() ?? ''"
    [role]="this.userRole"
  >
  </app-data-table>
</div>
<div *ngIf="!isAccess && !isLoading">
  <div class="page-header-text noaccess">
    You don't have access to this feature.
  </div>
</div>
