<mat-card class="stats-overview">
  <mat-spinner *ngIf="isLoading" class="loadSpinner" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{ loadMessage }}
  </mat-spinner>

  <div *ngIf="!isLoading" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px">
    <div fxLayout="column" fxFlex="20%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="20%">
          <img *ngIf="!transactionGrid" matTooltip="Total Customers" class="summaryIcons"
            src="assets/grid-icons/blueperson.png" />
          <img *ngIf="transactionGrid" matTooltip="Total Transactions" class="summaryIcons"
            src="assets/grid-icons/blueperson.png" />
        </div>
        <div fxLayout="column" fxFlex="5%"></div>
        <div fxLayout="column" fxFlex="75%" class="mt7">
          <ng-container *ngIf="!transactionGrid">
            <span class="amountCls">{{ reportStats?.total }} </span>
            <span class="amountLblCls">Total Customers</span>
          </ng-container>
          <ng-container *ngIf="transactionGrid">
            <span class="amountCls">{{ reportStats?.totalTransaction }} </span>
            <span class="amountLblCls">Total Transactions</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="20%">
          <img *ngIf="!transactionGrid" matTooltip="Total Individuals" class="summaryIcons"
            src="assets/grid-icons/individualcustomer.png" />
          <img *ngIf="transactionGrid" matTooltip="Total Amount" class="summaryIcons"
            src="assets/grid-icons/greenperson.png" />
        </div>
        <div fxLayout="column" fxFlex="5%"></div>
        <div fxLayout="column" fxFlex="75%" class="mt7">
          <ng-container *ngIf="!transactionGrid">
            <span class="amountCls">{{ reportStats?.individual }}</span>
            <span class="amountLblCls">Total Individuals</span>
          </ng-container>
          <ng-container *ngIf="transactionGrid">
            <span class="amountCls">
              ${{ reportStats?.totalAmount | number: "1.2-2" }}
            </span>
            <span class="amountLblCls">Total Amount</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="transactionGrid" class="verticalLine"></div>

    <div fxLayout="column" fxFlex="20%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="20%">
          <img *ngIf="!transactionGrid" matTooltip="Total Companies" class="summaryIcons"
            src="assets/grid-icons/allcompany.png" />
          <img *ngIf="transactionGrid" matTooltip="Total Customers" class="summaryIcons"
            src="assets/grid-icons/blueperson.png" />
        </div>
        <div fxLayout="column" fxFlex="5%"></div>
        <div fxLayout="column" fxFlex="75%" class="mt7">
          <ng-container *ngIf="!transactionGrid">
            <span class="amountCls">{{ reportStats?.company }}</span>
            <span class="amountLblCls">Total Companies</span>
          </ng-container>
          <ng-container *ngIf="transactionGrid">
            <span class="amountCls">{{ reportStats?.totalCustomer }}</span>
            <span class="amountLblCls">Total Customers</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="!transactionGrid" class="verticalLine"></div>

    <div fxLayout="column" fxFlex="20%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="20%">
          <img matTooltip="Active Customers" class="summaryIcons" src="assets/grid-icons/greenperson.png" />
        </div>
        <div fxLayout="column" fxFlex="5%"></div>
        <div fxLayout="column" fxFlex="75%" class="mt7">
          <span class="amountCls" *ngIf="!transactionGrid">{{
            reportStats?.active
            }}</span>
          <span class="amountCls" *ngIf="transactionGrid">{{
            reportStats?.activeCustomer
            }}</span>
          <span class="amountLblCls">Active Customers</span>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlex="20%">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
        <div fxLayout="column" fxFlex="20%">
          <img matTooltip="Inactive Customers" class="summaryIcons" src="assets/grid-icons/redperson.png" />
        </div>
        <div fxLayout="column" fxFlex="5%"></div>
        <div fxLayout="column" fxFlex="75%" class="mt7">
          <span class="amountCls" *ngIf="!transactionGrid">{{
            reportStats?.inActive
            }}</span>
          <span class="amountCls" *ngIf="transactionGrid">{{
            reportStats?.inActiveCustomer
            }}</span>
          <span class="amountLblCls">Inactive Customers</span>
        </div>
      </div>
    </div>
  </div>
</mat-card>