<mat-spinner
  *ngIf="isLoading"
  class="loadSpinner"
  mode="indeterminate"
  [diameter]="50"
  [strokeWidth]="3"
>
  {{ loadMessage }}
</mat-spinner>

<div class="report-pia-container" *ngIf="!isLoading">
  <mat-label class="recurring-transaction">Recurring Transactions</mat-label>

  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
  >
    <div fxLayout="column" fxFlex="50%" style="padding: 4%">
      <div class="spinner-container">
        <div class="spinner-background">
          {{ reportRecurring?.ach?.percentage?.toFixed(2) }}%
        </div>
        <mat-progress-spinner
          #spinner
          [color]="color"
          [diameter]="diameter"
          [mode]="mode"
          [value]="reportRecurring?.ach?.percentage"
        >
        </mat-progress-spinner>
      </div>
    </div>
    <div fxLayout="column" fxFlex="50%">
      <div style="margin-top: 25% !important">
        <div class="transaction-value">
          {{ reportRecurring?.ach?.totalTransaction }}
        </div>
        <div class="transaction-label">ACH Transactions</div>
      </div>
    </div>
  </div>
  <br />
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
  >
    <div fxLayout="column" fxLayoutGap="15px" fxFlex="50%" style="padding: 4%">
      <div class="spinner-container">
        <div class="spinner-background">
          {{ reportRecurring?.cards?.percentage?.toFixed(2) }}%
        </div>
        <mat-progress-spinner
          #spinner
          [color]="color"
          [diameter]="diameter"
          [mode]="mode"
          [value]="reportRecurring?.cards?.percentage"
        >
        </mat-progress-spinner>
      </div>
    </div>
    <div fxLayout="column" fxFlex="50%">
      <div style="margin-top: 25% !important">
        <div class="transaction-value">
          {{ reportRecurring?.cards?.totalTransaction }}
        </div>
        <div class="transaction-label">Credit/Debit</div>
      </div>
    </div>
  </div>
  <br />
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
  >
    <div fxLayout="column" fxLayoutGap="15px" fxFlex="50%" style="padding: 4%">
      <div class="spinner-container">
        <div class="spinner-background">
          {{ reportRecurring?.eCheck?.percentage?.toFixed(2) }}%
        </div>
        <mat-progress-spinner
          #spinner
          [color]="color"
          [diameter]="diameter"
          [mode]="mode"
          [value]="reportRecurring?.eCheck?.percentage"
        >
        </mat-progress-spinner>
      </div>
    </div>
    <div fxLayout="column" fxFlex="50%">
      <div style="margin-top: 25% !important">
        <div class="transaction-value">
          {{ reportRecurring?.eCheck?.totalTransaction }}
        </div>
        <div class="transaction-label">E-Check</div>
      </div>
    </div>
  </div>
</div>
