
import { Component, Input, OnInit } from '@angular/core';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStep, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-hosted-payment-page-web-preview',
  templateUrl: './hosted-payment-page-web-preview.component.html',
  styleUrls: ['./hosted-payment-page-web-preview.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false },
    },
  ],
})
export class HostedPaymentPageWebPreviewComponent implements OnInit {

  isSmallView: boolean;
  deviceType: string;
  @Input()
  changesIsLoading: boolean;

  @Input()
  finzeoPaymentOption:string;
  
  @Input()
  pageIndex: string;

  @Input()
  formData: any;

  constructor() { }

  ngOnInit(): void {
    this.isSmallView = false;
    this.deviceType = "web";
  }
}

