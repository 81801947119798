import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { BillingService } from 'src/app/featureModules/services/billing-details.service';
import { InvoiceStatusConstant } from 'src/app/sharedModules/constants/ticket-status-constant';
import {
 
  ParentProductDetails, 
  DisplayBillingInvoiceModel,
  InvoiceLineItemsModel
  
} from 'src/app/sharedModules/models/billing-filter-model';

@Component({
  selector: 'app-billing-profile',
  templateUrl: './billing-profile.component.html',
  styleUrls: ['./billing-profile.component.scss'],
})
export class BillingProfileComponent implements OnInit {
  loadMessage: string = 'Please Wait...';
  isLoading = false;
  invoiceId: string;
  displayBillingInvoiceModel: DisplayBillingInvoiceModel;
  displayBillingInvoiceModel_childElements: DisplayBillingInvoiceModel;
  invoiceLineItemsModel: InvoiceLineItemsModel;
  parentProductDetails:ParentProductDetails;
  

  @ViewChild('drawer') drawer: MatDrawer;
  data: any;
  subTotal:number=0;
  salesTax:number=0;
  discount:number=0;
  total:number=0;
  footerText:string;

  isInvoiceFailed : boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private _billingService: BillingService
  ) {}

  ngOnInit(): void {
      this.invoiceId = this.activatedRoute.snapshot.paramMap.get('invoiceId'); ;

      this.displayBillingInvoiceModel = new DisplayBillingInvoiceModel();
      this.parentProductDetails =new ParentProductDetails();
      this.parentProductDetails.ProductName='';
      this.parentProductDetails.parentTotalPrice=0;
      this.parentProductDetails.parentUnitPrice=0;
      this.parentProductDetails.clientInvoiceLineItems=[];
      this.displayBillingInvoiceModel.parentProductDetails=[];
     
      this.displayBillingInvoiceModel.clientInvoiceLineItem=[] ;
     
      this.GetBillingInvoiceByClientId();    
  }
  navigateToBillingProfile() {
    this.router.navigate(['billing']);
  }

  onRaiseATicketClick(){
    this.router.navigate(['ticket/create-ticket'],{
      state : {
        invoiceNumber : this.displayBillingInvoiceModel.invoiceNo,
        categoryType : 'Billing'
      }
    });    
  }
  // server side data
  GetBillingInvoiceByClientId() {
    this.isLoading = true;

    this._billingService
      .getBillingInvoiceByInvoiceId(this.invoiceId)
      .subscribe(
        (data) => {
          if (data.data != null) {
            this.initializeDisplayInvoiceDetailModel(
              data.data
            );
            this.isLoading = false;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  initializeDisplayInvoiceDetailModel(data: any) {

    this.displayBillingInvoiceModel.invoiceNo = data.invoiceNumber;
    this.displayBillingInvoiceModel.name = data.name;
    this.displayBillingInvoiceModel.clientAddress = data.address;
    this.displayBillingInvoiceModel.phone = data.mobileNumber;
    this.displayBillingInvoiceModel.clientInvoiceId = data.clientInvoiceId;
    this.displayBillingInvoiceModel.scheduledDate = data.scheduleDate;
    this.displayBillingInvoiceModel.toDate=data.toDate;
    this.displayBillingInvoiceModel.fromDate=data.fromDate;
    this.displayBillingInvoiceModel.invoiceLogo = data.invoiceLogo;
    this.displayBillingInvoiceModel.email=data.email;
    this.displayBillingInvoiceModel.trackingId=data.trackingId;
    this.footerText=data.footerText;
    this.isInvoiceFailed = 
    data.invoiceStatus.toUpperCase() == InvoiceStatusConstant.Failed ? true : false;

   data.parentClientInvoiceLineItems.forEach(parentItem => {
    const parentProduct = {
      ProductName: parentItem.invoiceLineName,
      parentUnitPrice: parentItem.parentUnitPrice,
      parentTotalPrice: parentItem.parentTotalPrice,
      clientInvoiceLineItems: []
    };
  
    parentItem.clientInvoiceLineItems.forEach(childItem => {
      const clientInvoiceLineItem = {
        itemName: childItem.invoiceLineName,
        quantity: childItem.qty,
        unitPrice: childItem.unitPrice,
        totalPrice: childItem.totalPrice
      };
  
      parentProduct.clientInvoiceLineItems.push(clientInvoiceLineItem);
    });
  
    this.displayBillingInvoiceModel.parentProductDetails.push(parentProduct);
  });    
    this.displayBillingInvoiceModel.subtotal=data .invoiceSubTotal  ;
    this.displayBillingInvoiceModel.salesTax=data .invoiceTaxAmount  ;
    this.displayBillingInvoiceModel.discount=data .invoiceDiscountAmount  ;
    this.displayBillingInvoiceModel.total=data.totalAmount  ;
  }

  DownloadBillingDetailsFile() {

    this.isLoading = true;
  
    this._toastrService.info('Please wait.. Attachment is getting downloaded.');
   
    this._billingService
      .downloadFile2(this.invoiceId).subscribe((data) => {      
          if (data != null) {    
            FileSaver.saveAs(data,'Billing_Invoice_'+this.displayBillingInvoiceModel.invoiceNo+'.pdf');
            this._toastrService.success('File Successfully downloaded.');
          }

          else {         
            this._toastrService.warning(data.errorMessage);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }
}
