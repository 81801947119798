import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InstantVerifyReportsService } from 'src/app/featureModules/services/instant-verify.reports.service';

@Component({
  selector: 'app-cca-balance-card',
  templateUrl: './cca-balance-card.component.html',
  styleUrls: ['./cca-balance-card.component.scss'],
})
export class CCABalanceCardComponent {
  isLoading = false;
  balanceDetails: any;

  @Input()
  ibvBankVerificationId: any;

  @Input()
  accountType: any;

  @Input()
  startDate: any;

  @Input()
  endDate: any;

  constructor(
    private _instantVerifyReportsService: InstantVerifyReportsService
  ) {}

  @Output() loadBalanceCardChange = new EventEmitter<boolean>();
  @Input('loadBalanceCard')
  set loadBalanceCard(loadBalanceCard: boolean) {
    if (loadBalanceCard) {
      this.isLoading = true;
      this._instantVerifyReportsService
        .GetBalanceDetailsForReport(
          this.ibvBankVerificationId,
          this.accountType,
          this.startDate,
          this.endDate
        )
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.data != null) {
              this.balanceDetails = data.data;
            }
          },
          (error) => {
            this.isLoading = false;
          }
        );
    }
    this.loadBalanceCardChange.emit(false);
  }
}
