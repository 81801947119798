import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateTicketModel } from 'src/app/featureModules/models/create-ticket.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { FileUploadService } from 'src/app/featureModules/services/file-upload.service';
import { TicketService } from 'src/app/featureModules/services/ticket.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;
  isLoading: boolean;
  loadMessage: string = 'Please wait..';
  createTicketFormGroup: FormGroup;
  createticketModel: CreateTicketModel;
  fileName: string;
  fileAttr: string;
  uploadedFilelist: any[] = [];
  delete_const = 'Delete1';
  progress = 0;
  createTicketSubscription$: Subscription;
  fileUploadSubscription$: Subscription;
  fileDeleteSubscription$: Subscription;
  dropdown:string;
  currentFile?: File;
  message = '';
  selecteddropdown:string;
  invoiceNumber : string;

  categoryType = [
    { value: 'Invoice', key: 'Invoice' },
    { value: 'Payment', key: 'Payment' },
    { value: 'Analysis/Bug', key: 'Bug' },
    { value: 'Transaction', key: 'Transaction ' },
    { value: 'Statement', key: 'Statement' },
    { value: 'Billing', key: 'Billing' },
    { value: 'Other', key: 'Other' },
  ];

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private fileUploadService: FileUploadService,
    private _ticketService: TicketService,
    private _toastrService: ToastrService,
    private dialog: MatDialog,
    public activatedRoute: ActivatedRoute
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.invoiceNumber = navigation?.extras?.state
      ? navigation.extras.state['invoiceNumber']
        : '';     
  }

  ngOnInit(): void {
    this.createticketModel = new CreateTicketModel();
    this.createTicketFormGroup = this.formBuilder.formGroup(
      this.createticketModel
    );    
    if(this.invoiceNumber && this.categoryType){
      this.createTicketFormGroup.controls['subject'].setValue('Invoice Number : '+this.invoiceNumber);
      this.createTicketFormGroup.controls['category'].setValue('Billing');
    }
  }

  navigateToBackPage() {
    if(this.invoiceNumber && this.categoryType){
      this.router.navigate(['billing']);
    }
    else{
      this.router.navigate(['ticket']);
    }
    
  }
  // [ngStyle]="{ width: progress + '%' }"
  onFileSelected(selectedFile: any) {    
    let file: File = selectedFile.target.files[0];
    if (file) {
    const allowedTypes = ["application/pdf", "application/msword", "image/jpeg","image/png","image/gif","image/jpg",
    "application/rtf" ,"application/vnd.ms-excel" ,"text/csv","text/plain","application/x-zip-compressed",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
      if (!allowedTypes.includes(file.type)) {
        this._toastrService.warning('File type is not allowed');
        this.clearSelectedFile();
        return;
      }
    }
    if (this.uploadedFilelist.length == 5) {
      this._toastrService.warning('You can upload only 5 attachments');
      return;
    }
    let isFileAlreadyUploaded = false;   
    if (file.size > 5000000) {
      this._toastrService.warning('Max 5MB file can be uploaded');
      return;
    }
    this.uploadedFilelist.forEach((item: any) => {
      if (item.name == file.name) {
        this._toastrService.warning('File is already uploaded');
        isFileAlreadyUploaded = true;
      }
    });
    if (isFileAlreadyUploaded) return;

    this.fileUploadSubscription$ = this.fileUploadService
      .uploadFile(file)
      .subscribe(
        (response) => {
          if (response.code == 201) {
            this.uploadedFilelist.push({
              name: file.name,
              path: response.data,
              size: file.size,
            });
            this._toastrService.success('Attachment uploaded successfully!');
            this.clearSelectedFile();
          } else {
            this._toastrService.warning(response.errorMessage);
          }
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  onFileSelected1(selectedFile: any) {
    if (this.uploadedFilelist.length == 5) {
      this._toastrService.warning('You can upload only 5 attachments');
      return;
    }

    let file: File = selectedFile.target.files[0];
    if (file.size > 5000000) {
      this._toastrService.warning('Max 5MB file can be uploaded');
      return;
    }
    this.progress = 0;
    this.fileUploadSubscription$ = this.fileUploadService
      .uploadFile1(file)
      .subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round((100 * event.loaded) / event.total);
          } else if (event instanceof HttpResponse) {
            this.message = event.body;
          }
          this.uploadedFilelist.push({
            name: file.name,
            path: this.message,
            size: file.size,
          });
          this._toastrService.success('Attachment uploaded successfully!');
        },
        (err) => {
          this.progress = 0;
          this.message = 'Could not upload the file!';
          this.currentFile = undefined;
        }
      );
  }

  clearSelectedFile() {
    this.fileUpload.nativeElement.value = null;
  }

  onFileDeleted(file: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: 'DELETE',
        successMessage: 'Are you sure you want to delete this Attachment?',
        subSuccessMessage:
          'Clicking on delete button will remove attachment permanently',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this.fileDeleteSubscription$ = this.fileUploadService
          .deleteFile(file.path)
          .subscribe(
            (data) => {
              if (data.code == 201) {
                let index = this.uploadedFilelist.findIndex(
                  (x) => x.name == file.name
                );
                this.uploadedFilelist.splice(index, 1);
                this._toastrService.success('Attachment removed successfully!');
              } else {
                this._toastrService.warning(data.errorMessage);
              }
              this.isLoading = false;
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    });
  }

  submitTicket() {
    let client = JSON.parse(localStorage.getItem('userProfile'));
    this.createticketModel.firstName = client['firstName'];
    this.createticketModel.lastName = client['lastName'];
    this.createticketModel.attachments = this.uploadedFilelist;
    this.createticketModel.subject =
      this.createTicketFormGroup.controls['subject'].value;
    this.createticketModel.description =
      this.createTicketFormGroup.controls['description'].value;

    if (this.createTicketFormGroup.valid) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          delete: false,
          buttonName: 'Yes',
          buttonCancel: false,
          successMessage: 'Are you sure you want to raise this ticket?',
          subSuccessMessage: 'Clicking on yes will raise a new ticket',
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.isLoading = true;
          this.createTicketSubscription$ = this._ticketService
            .createTicket(this.createticketModel)
            .subscribe(
              (data) => {
                if (data.code == 201) {
                  this._toastrService.success('Ticket created successfully!');
                  this.navigateToBackPage();
                } else {
                  this._toastrService.warning(data.errorMessage);
                }
                this.isLoading = false;
              },
              (error) => {
                this.isLoading = false;
              }
            );
        } else {
          //this._toastrService.warning('Please fill all the required details');
          this.isLoading = false;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.uploadedFilelist = [];
    this.createticketModel = null;
    if (this.createTicketSubscription$) {
      this.createTicketSubscription$.unsubscribe();
      this.createTicketSubscription$ = null;
    }
    if (this.fileUploadSubscription$) {
      this.fileUploadSubscription$.unsubscribe();
      this.fileUploadSubscription$ = null;
    }
    if (this.fileDeleteSubscription$) {
      this.fileDeleteSubscription$.unsubscribe();
      this.fileDeleteSubscription$ = null;
    }
  }
}
