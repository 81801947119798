<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToBackPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    {{ backHeaderLabel }}
  </mat-label>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" >
  <div fxLayout="column" fxFlex="92%">
  <mat-label class="header-text">Instant Bank Analysis</mat-label>
  </div>
  <!-- <div fxLayout="column" fxFlex="7%" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <button
        mat-raised-button
        color="primary"
        class="page-header-button-style"
        (click)="printClicked()"
      >
        PRINT
      </button>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <span>{{ currentDate | date : "mediumDate" }}</span>
    </div>
  </div> -->
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutGap="2%"
  fxLayoutAlign="start stretch"
  style="margin: 2%"  
  id="canvasContainer_Page0_1"
  #canvasContainer_Page0_1
>
  <div fxLayout="column" fxFlex="49%" fxLayoutAlign="space-between stretch">
    <mat-card class="profile-view-card" *ngIf="isLoading">
      <mat-spinner
        class="loadSpinner"
        mode="indeterminate"
        [diameter]="50"
        [strokeWidth]="3"
      >
      </mat-spinner>
    </mat-card>
    <div
      *ngIf="!isLoading"
      fxLayout="row"
      fxLayoutGap="12px"
      fxLayoutAlign="start stretch"
      style="flex: 1 1 100%"
    >
      <mat-card class="profile-view-card" fxLayout="column" fxFlex="100%">
       <div fxLayout="row">
        <div fxLayout="column" style="width: 20%;margin-right:13px">
          <div fxLayout="column" fxLayoutAlign="start center">
            <mat-label class="grade-style">{{
              FinZeoGradeAndScoreDetails?.grade
            }}</mat-label>
            <mat-label>Grade</mat-label>
          <div style="display:inline-flex">
          <mat-label class="score-style">{{
              FinZeoGradeAndScoreDetails?.score
            }}</mat-label>
               <img
               [matTooltip]="getTooltipText()"
               class="statusImageInfo"                   
               src="assets/ibv-report-icons/info.png"
             />
             </div>
          </div>
        </div>
        <div fxLayout="column" style="width: 80%">
        <div fxlayout="row">
        <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutGap="12px"
                  fxLayoutAlign="start stretch" class="divspacing"
                >
                  <mat-label class="page-header-customerName">
                    {{ accountDetails?.accountName ?? "NA" }}
                  </mat-label>
                  &nbsp;&nbsp;
                  <button
                    mat-flat-button
                    class="status-button-styles"
                    [ngStyle]="
                      getStatusStyle(accountDetails?.accountNameStatus)
                    "
                  >
                    <img
                      matTooltip="UserStatus"
                      class="statusImageStyle"
                      style="margin-right: 5px"
                      src="{{ getImageSrc(accountDetails?.accountNameStatus) }}"
                    />
                    {{ accountStatusDetails ?? "NON VERIFIED USER" }}
                  </button>
                </div>
        </div>

         <div         fxLayout="row"
                  fxLayoutAlign="start center" class="divspacing"
                >
                  <div fxLayout="column" style="width:40%">
                    <mat-label
                      class="page-header-customerEmail bank-Details-Label"
                      style="width:100%">Bank Details: &nbsp;</mat-label
                    >
                  </div>

                  <div  style="width:60% !important;display:block !important">
                   
                    <mat-label class="page-header-customerEmail" style="margin-right: 12px;">
                      {{ accountDetails?.bankDetails ?? "N/A" }}
                    </mat-label>             
                    <img
                    matTooltip="AccountStatus"
                    class="statusImageStyle"
                    src="{{ getImageSrc(accountDetails?.bankDetailsStatus) }}"
                  />
                  </div>
                  
                
          </div>

 <div
                  fxLayout="row"
                  fxLayoutAlign="start center" class="divspacing"
                >
                  <div fxLayout="column" style="width:40%">
                    <mat-label
                      class="page-header-customerEmail bank-Details-Label"
                      >First Seen: &nbsp;</mat-label
                    >
                  </div>

                  <div fxLayout="column" style="width:60%">
                    <mat-label class="page-header-customerEmail">
                      {{
                        accountDetails?.firstSeen == undefined
                          ? "N/A"
                          : (accountDetails?.firstSeen
                            | date : "MM/dd/yyyy (HH:mm:ss)")
                      }}</mat-label
                    >
                  </div>
                </div>

                <div
                  fxLayout="row"
                  fxLayoutAlign="start center" class="divspacing"
                >
                  <div fxLayout="column" style="width:40%">
                    <mat-label
                      class="page-header-customerEmail"
                      style="color: rgba(128, 128, 128, 0.81)"
                      >Last Successful Update: &nbsp;</mat-label
                    >
                  </div>

                  <div fxLayout="column" style="width:60%">
                    <mat-label class="page-header-customerEmail">
                      {{
                        accountDetails?.lastSuccessfulUpdate == undefined
                          ? "N/A"
                          : (accountDetails?.lastSuccessfulUpdate
                            | date : "MM/dd/yyyy (HH:mm:ss)")
                      }}
                    </mat-label>
                  </div>
                </div>

        </div>
        </div>  
        <mat-divider class="divider"></mat-divider>

        <div
          fxLayout="column"
          fxFlex="100%"
          fxLayoutAlign="start stretch"
          fxLayoutWrap
          style="overflow-y: auto"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            style="margin: 1%"
            fxLayoutWrap
          >
            <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
              <div
                fxLayout="row"
                fxLayoutAlign="start space-between"
                fxLayoutGap="10px"
                class="balance-row"
                style="text-align: left"
                fxLayoutWrap
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="20%"
                  class="text-labels bold-style"
                ></div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="26%"
                  class="text-labels bold-style"
                >
                  EMPLOYER:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="18%"
                  class="text-labels bold-style"
                >
                  AVERAGE PAY:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="18%"
                  class="text-labels bold-style"
                >
                  FREQUENCY:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="18%"
                  class="text-labels bold-style"
                >
                  FIRST CREDIT:
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="start space-between"
                style="text-align: left"
                class="balance-row"
                fxLayoutGap="10px"
                fxLayoutWrap
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="20%"
                  class="text-labels bold-style"
                >
                  Submitted:
                </div>
                <div
                  fxLayout="column"
                  fxFlex="26%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                <div
                  fxLayout="row" fxLayoutGap="16px" fxLayout.xs="column" class="text-image-align">
                  <mat-label>
                  {{ employerVerificationDetails?.employerS ?? na }}
                  </mat-label>
                  <img                      
                      class="statusImageStyle"
                      src="{{ getImageSrc(employerVerificationDetails?.employerStatus) }}"
                    />
                  </div>
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                <div
                  fxLayout="row" fxLayoutGap="16px" fxLayout.xs="column" class="text-image-align">
                  <mat-label>
                  {{
                    employerVerificationDetails?.averageIncomeSubmitted ?? na
                  }}
                  </mat-label>
                  <img                      
                      class="statusImageStyle"
                      src="{{ getImageSrc(employerVerificationDetails?.averageIncomeStatus) }}"
                    />
                  </div>
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                ><div
                fxLayout="row" fxLayoutGap="16px" fxLayout.xs="column" class="text-image-align">
                  <mat-label>
                  {{ employerVerificationDetails?.payFrequencySubmitted ?? 0 }}
                  </mat-label>
                  <img                      
                      class="statusImageStyle"
                      src="{{ getImageSrc(employerVerificationDetails?.payFrequencyStatus) }}"
                    />
                  </div>
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >                  
                </div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start space-between"
                style="text-align: left"
                class="balance-row"
                fxLayoutGap="10px"
                fxLayoutWrap
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="20%"
                  class="text-labels bold-style"
                >
                  Received:
                </div>
                <div
                  fxLayout="column"
                  fxFlex="26%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                  {{ employerVerificationDetails?.employerR ?? na }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                  {{ employerVerificationDetails?.averageIncomeReceived ?? na }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                  {{ employerVerificationDetails?.payFrequencyReceived ?? 0 }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="18%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center start"
                >
                  {{ employerVerificationDetails?.firstCreditReceived ?? na }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div fxLayout="column" fxFlex="49%" fxLayoutAlign="space-between stretch">
    <mat-card class="profile-view-card" *ngIf="isLoading">
      <mat-spinner
        class="loadSpinner"
        mode="indeterminate"
        [diameter]="50"
        [strokeWidth]="3"
      >
      </mat-spinner>
    </mat-card>
    <div
      *ngIf="!isLoading"
      fxLayout="row"
      fxLayoutGap="12px"
      fxLayoutAlign="start stretch"
      style="flex: 1 1 100%"
    >
      <mat-card class="profile-view-card" fxLayout="column" fxFlex="100%">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="start center"
          fxLayoutGap="10px"
          style="padding-bottom: 2%"
        >
          <div fxLayout="column">
            <img
              class="icons"
              src="assets/ibv-report-icons/checking_savings/sectionIcons/bankQuickView.png"
            />
          </div>
          <div fxLayout="column" fxLayoutWrap>
            <span class="section-header">Bank Quick View</span>
          </div>
          <span class="spacer"></span>
          <div *ngIf="!insufficientData && !flagDataLoading" class="insufficient-data-box-shadow">
            <div
              fxLayout="row"
              fxFlex="100%"
              fxLayout.xs="column"
              fxLayoutAlign="start center"
              fxLayoutWrap
            >
              <div fxLayout="column" fxLayoutWrap>
                <mat-label class="bqv-text-labels">Insufficient History:</mat-label>
              </div>
              &nbsp;&nbsp;
              <div fxLayout="column" fxLayoutWrap>
                <mat-label style="color:#ee1111;font-weight: 700;">{{ "< 90 Days" }}</mat-label>
              </div>
            </div>
          </div>
        </div>
        <div
          fxLayout="column"
          fxFlex="100%"
          fxLayoutAlign="start stretch"
          fxLayoutWrap
          style="overflow-y: auto"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center start"
            style="margin: 1%"
            fxLayoutWrap
          >
            <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
              <div
                fxLayout="row"
                fxLayoutAlign="start space-between"
                fxLayoutGap="10px"
                class="balance-row box-shadow"
                fxLayoutWrap
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="31%"
                  class="bqv-text-labels"
                >
                  AVAILABLE BALANCE:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="23%"
                  class="bqv-text-labels"
                >
                  CURRENT BALANCE:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxFlex="23%"
                  class="bqv-text-labels"
                >
                  AVERAGE BALANCE:
                </div>
                <div
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="23%"
                  class="bqv-text-labels"
                >
                  TRANSACTIONS:
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="start space-between"
                class="balance-row"
                fxLayoutGap="10px"
                fxLayoutWrap
              >
                <div
                  fxLayout="column"
                  fxFlex="22%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center end"
                >
                  ${{
                    balanceDetails?.availableBalance ?? 0 | number : "1.2-2"
                  }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="24%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center end"
                >
                  ${{ balanceDetails?.currentBalance ?? 0 | number : "1.2-2" }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="23%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center end"
                >
                  ${{
                    balanceDetails?.averageDailyBalance ?? 0 | number : "1.2-2"
                  }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="23%"
                  class="text-labels normal-style"
                  fxLayoutAlign="center end"
                >
                  {{
                    balanceDetails?.totalNumberOfCredits +
                      balanceDetails?.totalNumberOfDebits ??
                      0
                  }}
                </div>
              </div>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div
            fxLayout="row"
            fxLayoutAlign="start stretch"
            style="padding-top: 2%; margin: 1%"
            fxLayoutGap="16px"
            fxLayoutWrap
          >
            <div fxLayout="column" fxFlex="50%" class="balance-row">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" fxFlex="10%" class="bqv-text-labels">
                  Credits
                </div>
                <div
                  fxLayout="column"
                  fxFlex="10%"
                  class="text-labels normal-style alignment"
                >
                  {{ balanceDetails?.totalNumberOfCredits ?? 0 }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="30%"
                  class="text-labels normal-style alignment"
                >
                  ${{
                    balanceDetails?.totalCreditsAmount ?? 0 | number : "1.2-2"
                  }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" fxFlex="10%" class="bqv-text-labels">
                  Debits
                </div>
                <div
                  fxLayout="column"
                  fxFlex="10%"
                  class="text-labels normal-style alignment"
                >
                  {{ balanceDetails?.totalNumberOfDebits ?? 0 }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="30%"
                  class="text-labels normal-style alignment"
                >
                  ${{
                    balanceDetails?.totalDebitsAmount ?? 0 | number : "1.2-2"
                  }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" fxFlex="10%" class="bqv-text-labels">
                  Recurring Debits
                </div>
                <div
                  fxLayout="column"
                  fxFlex="10%"
                  class="text-labels normal-style alignment"
                >
                  {{ balanceDetails?.totalNumberOfMonthlyDebits ?? 0 }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="30%"
                  class="text-labels normal-style alignment"
                >
                  ${{
                    balanceDetails?.totalMonthlyDebitsAmount ?? 0
                      | number : "1.2-2"
                  }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" fxFlex="10%" class="bqv-text-labels">
                  NSF
                </div>
                <div
                  fxLayout="column"
                  fxFlex="10%"
                  class="text-labels normal-style alignment"
                >
                  {{ balanceDetails?.totalNumberOfNsf ?? 0 }}
                </div>
                <div
                  fxLayout="column"
                  fxFlex="30%"
                  class="text-labels normal-style alignment"
                >
                  ${{ balanceDetails?.totalNsfAmount ?? 0 | number : "1.2-2" }}
                </div>
              </div>
            </div>
            <mat-divider vertical class="vertical-devider"></mat-divider>
            <div fxLayout="column" fxFlex="50%" class="balance-row">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" class="bqv-text-labels">
                  Avg. Credits
                </div>
                <div fxLayout="column" class="text-labels normal-style">
                  ${{ balanceDetails?.averageCredits ?? 0 | number : "1.2-2" }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" class="bqv-text-labels">Avg. Debits</div>
                <div fxLayout="column" class="text-labels normal-style">
                  ${{ balanceDetails?.averageDebits ?? 0 | number : "1.2-2" }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" class="bqv-text-labels">
                  Avg. Recurring Debits
                </div>
                <div fxLayout="column" class="text-labels normal-style">
                  ${{
                    balanceDetails?.averageMonthlyDebits ?? 0 | number : "1.2-2"
                  }}
                </div>
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                fxLayoutGap="10px"
                style="padding: 3% 0%"
              >
                <div fxLayout="column" class="bqv-text-labels">Avg. NSF</div>
                <div fxLayout="column" class="text-labels normal-style">
                  ${{ balanceDetails?.averageNsfs ?? 0 | number : "1.2-2" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>

<div
  fxLayout="row"
  fxLayoutWrap
  fxLayoutGap="16px"
  style="margin: 2%;"

>
  <mat-tab-group
    dynamicHeight
    class="remove-border-bottom"
    fxFlex="100%"
    animationDuration="0ms"
    (selectedTabChange)="onTabChange($event)"
  >
    <mat-tab label="CHECKING" *ngIf="showDDAAccountReport">
      <app-instant-verify-report-dda
        [(loadDDAAccountReport)]="loadDDAAccountReport"
        [(printReport)]="printReport_DDA"
        [customerName]="accountDetails?.accountName ?? 'NA'"
        [customerStatus]="accountDetails?.accountNameStatus ?? 'NON VERIFIED'"
        [bankDetails]="accountDetails?.bankDetails ?? 'NA'"
        [bankStatus]="accountDetails?.bankDetailsStatus ?? 'NON VERIFIED'"
        [reportSeen]="accountDetails?.timesSeen"
        [firstSeen]="accountDetails?.firstSeen"
        [lastSuccessfulUpdate]="accountDetails?.lastSuccessfulUpdate"        
      >
      </app-instant-verify-report-dda>
    </mat-tab>

    <mat-tab label="SAVINGS" *ngIf="showSDAAccountReport">
      <app-instant-verify-report-sda
        [(loadSDAAccountReport)]="loadSDAAccountReport"
        [(printReport)]="printReport_SDA"
        [customerName]="accountDetails?.accountName ?? 'NA'"
        [customerStatus]="accountDetails?.accountNameStatus ?? 'NON VERIFIED'"
        [bankDetails]="accountDetails?.bankDetails ?? 'NA'"
        [bankStatus]="accountDetails?.bankDetailsStatus ?? 'NON VERIFIED'"
        [reportSeen]="accountDetails?.timesSeen"
        [firstSeen]="accountDetails?.firstSeen"
        [lastSuccessfulUpdate]="accountDetails?.lastSuccessfulUpdate"
      >
      </app-instant-verify-report-sda>
    </mat-tab>

    <mat-tab label="CREDIT CARD" *ngIf="showCCAAccountReport">
      <app-instant-verify-report-cca
        [(loadCCAAccountReport)]="loadCCAAccountReport"
        [(printReport)]="printReport_CCA"
        [customerName]="accountDetails?.accountName ?? 'NA'"
        [customerStatus]="accountDetails?.accountNameStatus ?? 'NON VERIFIED'"
        [bankDetails]="accountDetails?.bankDetails ?? 'NA'"
        [bankStatus]="accountDetails?.bankDetailsStatus ?? 'NON VERIFIED'"
        [reportSeen]="accountDetails?.timesSeen"
        [firstSeen]="accountDetails?.firstSeen"
        [lastSuccessfulUpdate]="accountDetails?.lastSuccessfulUpdate"
      >
      </app-instant-verify-report-cca>
    </mat-tab>
  </mat-tab-group>
</div>
