<mat-card class="flags-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="flags-card" fxLayout="column" fxFlex="100%">
    <div
    fxLayout="column"
    fxFlex="100%"
    fxLayout.xs="column"
    fxLayoutGap="2%"
    fxLayoutWrap
    >    
    <div
    fxLayout="row"
    fxFlex="100%"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="2%"
    fxLayoutWrap
    >
      <div class="flags-internal-card" fxFlex="49%">
        <div
      fxLayout="column"
      fxFlex="100%"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutWrap
      >
      <mat-label class="flags-labels">NSF'S</mat-label>
      <mat-label class="flags-values">{{ flagsDetails?.nsfCount ?? 0 }}</mat-label>
      </div>
      </div>
      <div class="flags-internal-card" fxFlex="49%">
        <div
      fxLayout="column"
      fxFlex="100%"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutWrap
      >
      <mat-label class="flags-labels">DAYS IN NEGATIVE BALANCE</mat-label>
      <mat-label class="flags-values">{{ flagsDetails?.negativeDays ?? 0 }}</mat-label>
      </div>
      </div>
    </div>
    <div
    fxLayout="row"
    fxFlex="100%"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="2%"
    fxLayoutWrap
    >
      <div class="flags-internal-card">
        <div
      fxLayout="column"
      fxFlex="100%"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutWrap
      >
      <mat-label class="flags-labels">DAYS SINCE LAST NEGATIVE</mat-label>
      <mat-label class="flags-values">{{ flagsDetails?.daysSinceLastNegative ?? 0 }}</mat-label>
      </div>
      </div>
      <!-- <div class="flags-internal-card" fxFlex="49%">
        <div
      fxLayout="column"
      fxFlex="100%"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      fxLayoutWrap
      >
      <mat-label class="flags-labels">INSUFFICIENT HISTORY</mat-label>
      <mat-label class="flags-values">{{ flagsDetails?.insufficientHistory ?? "No" }}</mat-label>
      </div>
      </div> -->
    </div>
    </div>
  </mat-card>
</div>
