import { Injectable } from '@angular/core';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Injectable({
  providedIn: 'root',
})
export class HolidayUtils {
  getNextBusinessDate(date) {
    do {
      if (this.is_weekend(date)) {
        date.setDate(date.getDate() + 1);
      }
    } while (this.is_weekend(date));

    return date;
  }

  is_weekend = function (date) {
    var dt = new Date(date);
    if (dt.getDay() == 6 || dt.getDay() == 0) {
      return true;
    }
    return false;
  };

  // remove Z at end of date if any
  removeZ = function (date: string) {
    if (date.endsWith('Z')) {
      return new Date(date.substring(0, date.length - 1));
    }
    return date;
  };

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }

  formatTime(d, ampm = true) {
    var hour = d.getHours();

    if (ampm) {
      var a = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
    }

    hour = this.checkDigit(hour);
    var minute = this.checkDigit(d.getMinutes());
    var second = this.checkDigit(d.getSeconds());

    return ampm ? `${hour}:${minute} ${a}` : `${hour}:${minute}:${second}`;
  }

  checkDigit(t) {
    return t < 10 ? `0${t}` : t;
  }

  is_publicHoliday = function (safeScheduledDate: any, holidays: any) {
    let holidayExists = false;
    for (let i = 0; i < holidays.length; i++) {
      let dateFromApi = getSafeIsoDateString(holidays[i].holiday);

      if (dateFromApi == safeScheduledDate) {
        holidayExists = true;
        break;
      }
    }

    return holidayExists;
  };

  setDefaultScheduleDateUsingUpcomingHoliday(
    defaultScheduledDate: any,
    holidays: any
  ) {
    do {
      if (defaultScheduledDate == undefined) {
        defaultScheduledDate = this.addDays(new Date(defaultScheduledDate), 1);
      }
      if (
        this.is_publicHoliday(
          getSafeIsoDateString(defaultScheduledDate),
          holidays
        ) ||
        defaultScheduledDate.getDay() == 0 ||
        defaultScheduledDate.getDay() == 6
      ) {
        defaultScheduledDate = this.addDays(defaultScheduledDate, 1);
      }
    } while (
      this.is_publicHoliday(
        getSafeIsoDateString(defaultScheduledDate),
        holidays
      ) ||
      defaultScheduledDate.getDay() == 0 ||
      defaultScheduledDate.getDay() == 6
    );

    return defaultScheduledDate;
  }

  getLastBusinessDate(defaultScheduledDate: any, holidays: any) {    
    do {
      if (defaultScheduledDate == undefined) {
        defaultScheduledDate = this.addDays(new Date(defaultScheduledDate), -1);
      }
      if (
        this.is_publicHoliday(
          getSafeIsoDateString(defaultScheduledDate),
          holidays
        )||(
        defaultScheduledDate.getDay() == 0 ||
        defaultScheduledDate.getDay() == 6
      )
      ) {
        defaultScheduledDate = this.addDays(defaultScheduledDate, -1);
      }
    } while (
      this.is_publicHoliday(
        getSafeIsoDateString(defaultScheduledDate),
        holidays
      )||(
      defaultScheduledDate.getDay() == 0 ||
      defaultScheduledDate.getDay() == 6
    )
    );

    return defaultScheduledDate;
  }

  getHolidaysFilter(holidays: any, date: any) {
    const day = date?.getDay();
    return (
      day !== 0 &&
      day !== 6 &&
      holidays != undefined &&
      holidays.find(
        (x) =>
          new Date(x.holiday).toLocaleDateString() == date.toLocaleDateString()
      ) == undefined
    );
  }

  getDateClass(holidays: any, date: any, view: any) {
    if (view === 'month' && holidays != undefined && holidays.length > 0) {
      let foundHoliday = holidays.find(
        (x) =>
          new Date(x.holiday).toLocaleDateString() == date.toLocaleDateString()
      );

      if (
        foundHoliday != undefined &&
        new Date(foundHoliday.holiday).getDay() != 0 &&
        new Date(foundHoliday.holiday).getDay() != 6
      )
        return ['public-holiday-date'];
    }

    return [''];
  }
}
