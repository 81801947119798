<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Create A Ticket</div>
        <div class="page-sub-header-text-1">
          All ticket related information can be viewed from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">            
        <button *ngIf = "this.userRole != '1'" mat-raised-button color="primary" class="page-header-button-style" (click)="createTicket()">
            CREATE A NEW TICKET
        </button>
      </div>
    </div>
  </div>  
  <app-ticket-list> </app-ticket-list>
</div>
