
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class DashboardService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_DASHBOARD}Dashboard`);
  }

  GetCustomerCounts(period: string) {
    return this._http.get<any>(this._base + "/getCustomerCounts?Period=" + period);
  }

  GetRecurringTransactionsPercentage(period: string) {
    return this._http.get<any>(this._base + "/GetRecurringTransactionsPercentage?Period=" + period);
  }

  GetAmountByService(period: string) {
    return this._http.get<any>(this._base + "/getAmountByService?Period=" + period);
  }

  GetTransactionStatusPercentage(transactionType: string, period: string) {
    return this._http.get<any>(this._base + "/GetTransactionStatusPercentage?TransactionType=" + transactionType + "&Period=" + period);
  }

  GetTotalAmountPercentage(transactionType: string, period: string) {
    return this._http.get<any>(this._base + "/GetTotalAmountPercentage?TransactionType=" + transactionType + "&Period=" + period);
  }

  GetServicesBasedTransactionsCount(sortBy: string, sortDirection: string) {
    let baseUrl = this._base + "/serviceBasedTransactionsCount?sortDirection=" + sortDirection;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  //Transaction Return Rate Data Table
  GetTransactionsReturnedRateComparision(transactionType: string) {
    let baseUrl = this._base + "/transactionReturnRateCountComparision?TransactionType=" + transactionType;
    return this._http.get<any>(baseUrl);
  }

  GetTransactionsReturnedRatePercentage(period: string) {
    let baseUrl = this._base + "/transactionsReturnedRatePercentage?Period=" + period;
    return this._http.get<any>(baseUrl);
  }

  //Transaction Status Data Table 
  //GetACHReturnRateDate
  GetTransactionStatusData(transactionType: string, sortBy: string, sortDirection: string) {
    let baseUrl = this._base + "/transactionCountComparision?TransactionType=" + transactionType;
    baseUrl = sortDirection ? baseUrl + "&sortDirection=" + sortDirection : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  //Transaction Total Amount Data Table
  GetTransactionTotalAmountData(transactionType: string, sortBy: string, sortDirection: string) {
    let baseUrl = this._base + "/transactionAmountComparision?TransactionType=" + transactionType;
    baseUrl = sortDirection ? baseUrl + "&sortDirection=" + sortDirection : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    return this._http.get<any>(baseUrl);
  }
}
