<mat-card class="transactions-overview">
    <mat-spinner *ngIf="isLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50"
        [strokeWidth]="3">
        {{loadMessage}}
    </mat-spinner>

    <div *ngIf="!isLoading" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
            <span class="compareYearLabel">{{periodLastLabel}}</span>
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch" fxLayoutGap="10px" class="topContainer">
                <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Customers" class="summaryIcons"
                                src="assets/grid-icons/blueperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{totalCustomers}} <div *ngIf="isLastYearGreaterThanCurrent"
                                    class="triangle triangle-4"></div>
                                <div *ngIf="!isLastYearGreaterThanCurrent" class="triangle triangle-5"></div>
                            </span>
                            <span class="amountLblCls">Total Customers</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Active Customers" class="summaryIcons"
                                src="assets/grid-icons/greenperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{activeCustomers}}</span>
                            <span class="amountLblCls">Active Customers</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="34%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Inactive Customers" class="summaryIcons"
                                src="assets/grid-icons/redperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{inActiveCustomer}}</span>
                            <span class="amountLblCls">Inactive Customers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <mat-divider [vertical]="true" class="divider-style"></mat-divider>

        <div fxLayout="column" fxFlex="50%" fxLayoutWrap>
            <span class="currentYearLabel">{{periodCurrentLabel}}</span>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="10px" fxLayoutAlign="start stretch"
                class="topContainer">
                <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
                    <div fxLayout="row" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Total Customers" class="summaryIcons"
                                src="assets/grid-icons/blueperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{currentTotalCustomers}} <div *ngIf="!isLastYearGreaterThanCurrent"
                                    class="triangle triangle-4"></div>
                                <div *ngIf="isLastYearGreaterThanCurrent" class="triangle triangle-5"></div>
                            </span>
                            <span class="amountLblCls">Total Customers</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="33%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Active Customers" class="summaryIcons"
                                src="assets/grid-icons/greenperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{currentActiveCustomers}}</span>
                            <span class="amountLblCls">Active Customers</span>
                        </div>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="34%" fxLayoutWrap>
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
                        <div fxLayout="column">
                            <img matTooltip="Inactive Customers" class="summaryIcons"
                                src="assets/grid-icons/redperson.png" />
                        </div>
                        <div fxLayout="column">
                            <span class="amountCls">{{currentInActiveCustomer}}</span>
                            <span class="amountLblCls">Inactive Customers</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>