import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { CommonService } from './sharedModules/services/common/common.service';
import { FaviconService } from './sharedModules/services/favicon/favicon.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'FinZeo';
  isLoggedIn$: boolean; //: Observable<boolean>;
  themeColor:string;
  constructor(
    private commonService: CommonService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private favicon :FaviconService
  ) {
    // commonService.getJSON('constants');
    window.addEventListener('beforeunload', () => {
      this.clearLocalStorage();
    });
  }

  ngOnInit() {
    this.changeFavicon();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (
          (<NavigationEnd>event).url == '/' ||
          (<NavigationEnd>event).url == '/sendOTP' ||
          (<NavigationEnd>event).url == '/forgotPassword' ||
          (<NavigationEnd>event).url == '/forgotPasswordEmail' ||
          (<NavigationEnd>event).url.startsWith('/forgotPassword?token=') ||
          (<NavigationEnd>event).url == '/forgotPasswordFinish' ||
          (<NavigationEnd>event).url.startsWith('/payment') ||
          (<NavigationEnd>event).url.startsWith('/csr') ||
          (<NavigationEnd>event).url.startsWith('/non-mfa')
        ) {
          this.isLoggedIn$ = false;
        } else {
          this.isLoggedIn$ = true;
        }
      }
    });

    ReactiveFormConfig.set({
      validationMessage: {
        required: 'This field is required.',
        Number_Hyphen: 'Only numbers, hyphens are allowed.',
        Alpha_Hyphen_Period_Space:
          'Only characters, periods, hyphens and spaces are allowed.',
        Number_Hyphen_Plus_RoundBrackets:
          'Only numbers, hyphens, plus and round brackets are allowed.',
        No_Space: 'Only space is not allowed.',
        No_Space_At_All: 'No space is allowed',
        minLength: 'You have to enter minimum {{1}} characters.',
        maxLength: 'Maximum {{1}} characters are allowed.',
        Email:
          'Only letters, numbers, periods, underscores and hypens are allowed',
        number_with_2_decimal_places:
          'Only positive decimal numbers are allowed.',
        maxNumber: 'Value should not be greater than {{1}}.',
        minNumber: 'Value should not be less than {{1}}.',
        Alpha: 'Only alphabets are allowed',
        Number: 'Only numbers are allowed',
        numeric: 'Only numbers are allowed',
        GreaterThanZero: 'Only numbers greater than 0 is allowed',
        Alpha_Numbers_Hyphen: 'Only letters, numbers, and hypens are allowed',
        Alpha_Numbers:
          'Only alphabets and numbers are allowed. No special characters.',
        ExpirationDate: 'Please enter valid expiration date e.g. MM/YY',
        CardNumber: 'Card number is not valid.',
        CompanyName:
          'Only letters, numbers, space and special characters - , . ( ) are allowed',
        IPV46: 'Please enter valid IP Address',
        Alphanumeric_Dash_Space_Comma_Brackets_Star_Hash:
          'Only alphanumerics, space and special characters - , ( ) # * are allowed.'
      },
    });
  }

  clearLocalStorage() {
    localStorage.removeItem('inventory');
    localStorage.removeItem('invoice');
    localStorage.removeItem('users');
    localStorage.removeItem('dashboard');
    localStorage.removeItem('reports');
    localStorage.removeItem('hostedLink');
    localStorage.removeItem('customers');
    localStorage.removeItem('instantBankAnalysis');
    localStorage.removeItem('statements');
    localStorage.removeItem('ticket');
    localStorage.removeItem('ach');
    localStorage.removeItem('cards');
    localStorage.removeItem('echeck');
    localStorage.removeItem('instantFund');
    localStorage.removeItem('callCompanyList');
    localStorage.removeItem('billing');
  }

  changeFavicon() {
    if(localStorage.getItem('favicon')){
    this.favicon.setFavicon(localStorage.getItem('favicon'));   
    }
    if(localStorage.getItem('logotext')){
      this.favicon.setTitle(localStorage.getItem('logotext'));
    }
      if(localStorage.getItem('themenaccolor')){  
      this.themeColor= localStorage.getItem('themenaccolor').toString();
      }
  }
}
