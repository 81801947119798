import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';
import { PaymentWidgetCardService } from 'src/app/externalModules/paymentWidget/services/payment-widget-card.service';
import { AchPaymentInfoComponent } from './ach-payment-info/ach-payment-info.component';

@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent {
  isPayWithCard: boolean;
  isPayWithAch: boolean;
  isNoProductAssigned: boolean;

  @Output()
  paymentMethodEvent = new EventEmitter();

  @Input()
  amount: any;

  @Input()
  uniqueToken: any;

  @Input()
  isRecurringDisabled: boolean;

  @Input()
  pageSettingModel: PageSettingModel;

  @Output()
  showLoaderEvent = new EventEmitter();

  @Output()
  cardDetailsFormGroupChange = new EventEmitter<any>();

  @Output()
  achDetailsFormGroupChange = new EventEmitter<any>();

  @Input()
  cardDetailsFormGroup: FormGroup;

  @Input()
  achDetailsFormGroup: FormGroup;

  @ViewChild(AchPaymentInfoComponent) achPaymentInfoComponent: AchPaymentInfoComponent;

  isOnlyAch: boolean;
  isOnlyCard: boolean;

  constructor(private paymentWidgetCardService: PaymentWidgetCardService) { }

  ngOnInit(): void {
    this.isPayWithAch = this.pageSettingModel?.isPaymentOptionAch;
    this.isPayWithCard = this.pageSettingModel?.isPaymentOptionCard;
    this.isOnlyAch = this.pageSettingModel?.isPaymentOptionAch && !this.pageSettingModel?.isPaymentOptionCard;
    this.isOnlyCard = this.pageSettingModel?.isPaymentOptionCard && !this.pageSettingModel?.isPaymentOptionAch;    
    if (!this.isPayWithAch && !this.isPayWithCard) {
      this.enablePaymentType("");
    } else {
      let payVia = this.isPayWithCard ? 'payWithCard' : 'payWithAch';
      this.enablePaymentType(payVia);
    }
  }

  enablePaymentType(type: string) {
    this.isPayWithCard = (type == 'payWithCard');
    this.isPayWithAch = !this.isPayWithCard;
    if(type == ""){
      this.isPayWithCard = true;
      this.isPayWithAch = true;
      this.isNoProductAssigned = true;
    }
    else{
      this.isNoProductAssigned = false;
      if (this.isPayWithCard) {
        this.achDetailsFormGroup.reset();
        this.achDetailsFormGroup.markAsUntouched();
        this.achDetailsFormGroup.markAsPristine();
        this.achDetailsFormGroup.controls['isRecurring'].setValue(false);
        this.achDetailsFormGroup.controls['agreesToConvenienceFees'].setValue(
          false
        );
        this.achDetailsFormGroup.controls['convenienceFees'].setValue(
          this.pageSettingModel?.convenienceFeeAmount
        );
        this.achDetailsFormGroupChange.emit(this.achDetailsFormGroup);
      } else {
        if ((!this.isOnlyAch && this.achPaymentInfoComponent && this.pageSettingModel?.isAvailConvenienceFee)) {
          this.achPaymentInfoComponent.openConvenienceFeesDialog();
        }
        this.cardDetailsFormGroup.reset();
        this.cardDetailsFormGroup.markAsUntouched();
        this.cardDetailsFormGroup.markAsPristine();
        this.cardDetailsFormGroup.controls['isRecurring'].setValue(false);
        this.cardDetailsFormGroup.controls['surchargeAmount'].setValue(0);
        this.cardDetailsFormGroup.controls['surchargePercent'].setValue(0);
        this.cardDetailsFormGroupChange.emit(this.cardDetailsFormGroup);
      }
      this.paymentMethodEvent.emit(type);
      this.paymentWidgetCardService.entryCodeEmitter.emit(
        this.paymentWidgetCardService.getAchCustomerTypeSelection(
          !this.isPayWithCard,
          true
        )
      );
    }
  }

  getCardDetailsFormGroup(event) {
    this.cardDetailsFormGroupChange.emit(event);
  }
  getAchDetailsFormGroup(event) {
    this.achDetailsFormGroupChange.emit(event);
  }

  getLoaderEvent(event) {
    this.showLoaderEvent.emit(event);
  }
}
