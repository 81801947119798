import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './sharedModules/guards/auth.guard';
import { AuthService } from './sharedModules/services/authentication/authentication.service';
import { NotificationService } from './sharedModules/services/notification/notification.service';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './sharedModules/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './sharedModules/interceptors/error.interceptor';
import {
  ADMIN_CONTAINER_PATH,
  API_BASE_URL,
  COMPANY_IMG_URL,
  IS_DAILY_RECURRING_ENABLED,
  SIGNALR_BASE_URL,
} from './sharedModules/constants/url.const';
import { AppMaterialModule } from './app-material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { BlockModule } from './blockModules/block.module';
import { LeftSideNavComponent } from './sharedModules/components/left-side-nav/left-side-nav.component';
import { CardModule } from 'ngx-card';
import { ToastrModule } from 'ngx-toastr';
import { IntlInputPhoneModule } from 'intl-input-phone';
import { CommonService } from './sharedModules/services/common/common.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgChartsModule } from 'ng2-charts';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SharedService } from './blockModules/pages/login/sharedService';
import { TimeZoneInterceptorService } from './sharedModules/interceptors/timezone.interceptor';
import { API_AUTHENTICATION_PROXY_URL } from './sharedModules/services/login/login.proxies';
import { API_ACH_TRANSACTION_URL as API_ACH_TRANSACTION_PROXY_URL } from './sharedModules/services/ach-transactions/ach-transactions.proxies';
import { API_USER_MANAGEMENT_PROXY_URL } from './sharedModules/services/user-management/user-managament.proxies';
import { API_CUSTOMER_PROXY_URL } from './sharedModules/services/customer/customer.proxies';
import { PaymentWidgetModule } from './externalModules/paymentWidget/payment.widget.module';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { API_IBV_PROXY_URL } from './sharedModules/services/ibv/ibv.proxies';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  NgxMatDatetimePickerModule, 
  NgxMatNativeDateModule, 
  NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker';
import { NonMfaLoginModule } from './externalModules/nonMFA/non-mfa-login.module';
import { JwtResponseInterceptor } from './sharedModules/interceptors/jwt.response.interceptor';

export function tokenGetter() {
  return localStorage.getItem('accessToken');
}

export function getAuthScheme(request) {
  return 'Bearer ';
}

export function jwtOptionsFactory() {
  return {
    tokenGetter,
    authScheme: getAuthScheme,
  };
}
@NgModule({
  declarations: [AppComponent, LeftSideNavComponent],
  imports: [
    BrowserModule,
    FormsModule,
    RecaptchaV3Module,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    RxReactiveFormsModule,
    HttpClientModule,
    BlockModule,
    PaymentWidgetModule,
    
    CardModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 4000,
      progressBar: true,
    }),
    IntlInputPhoneModule,
    NgxIntlTelInputModule,
    NgChartsModule,
    NgxMaterialTimepickerModule,
    ColorPickerModule,
    ClipboardModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NonMfaLoginModule,
  ],
  providers: [
    SharedService,
    CommonService,
    LeftSideNavComponent,
    AuthGuard,
    AuthService,
    NotificationService,
    { provide: API_BASE_URL, useValue: environment.API_BASE_URL_CUSTOMER },
    { provide: API_BASE_URL, useValue: environment.API_BASE_URL_CREDITDEBIT },
    { provide: API_BASE_URL, useValue: environment.API_BASE_URL_ECHECK },
    { provide: API_BASE_URL, useValue: environment.API_BASE_URL_IBV },
    { provide: API_CUSTOMER_PROXY_URL,  useValue: environment.API_BASE_URL_CUSTOMER.replace('/api/', ''),},
    { provide: API_IBV_PROXY_URL,  useValue: environment.API_BASE_URL_IBV.replace('/api/', ''),},
    { provide: API_AUTHENTICATION_PROXY_URL, useValue: environment.API_AUTHENTICATION_URL},
    { provide: API_ACH_TRANSACTION_PROXY_URL, useValue: environment.API_ACH_TRANSACTION_URL},
    { provide: API_USER_MANAGEMENT_PROXY_URL, useValue: environment.API_USER_MANAGEMENT_URL},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneInterceptorService,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.siteKey },
    { provide: ADMIN_CONTAINER_PATH, useValue: environment.ADMIN_CONTAINER_PATH},
    { provide: IS_DAILY_RECURRING_ENABLED, useValue: environment.IS_DAILY_RECURRING_ENABLED},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
