import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LeftSideNavComponent } from 'src/app/sharedModules/components/left-side-nav/left-side-nav.component';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import moment from 'moment';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { ListFilter } from '../creditdebit/customer-creditdebit-transaction/customer-creditdebit-transaction.component';
import { StatementService } from 'src/app/featureModules/services/statement.service';
import { Utils } from 'src/app/featureModules/helper/utils';
@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent implements OnInit {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;
  @Input()
  tabIndex: number;
  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  filterText: string = '';
  userName: string = null;
  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 5,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'UpdateDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'ACTIVITY', name: 'Activity', width: '15%' },
    { displayName: 'DESCRIPTION', name: 'Description', width: '40%' },
    { displayName: 'DEPOSITS/ DEBITS', name: 'Debits', width: '15%'},
    { displayName: 'WITHDRAWALS/ CREDITS', name: 'Credits', width: '15%' },
    { displayName: 'BALANCE', name: 'Balance', width: '15%' },
  ];

  allColumns: TableColumn[];

  customerModel: AddCustomerModel;
  userModel: addUserModel;
  userId: any;
  response: {
    companyName: string;
    accountNumber: string;
    routingNumber: string;
    endingAccountBalance: number;
    pendingDebits: number;
    pendingCredits: number;
    availableBalance: number;
    endDate: string;
    galileoPrn: string;
  }
  firstName: string;
  statementId: string;
  fromDate;
  toDate;
  constructor(
    private router: Router,
    private leftNav: LeftSideNavComponent,
    private _toastrService: ToastrService,
    private statementService: StatementService,
    private activatedRoute: ActivatedRoute,
    private _utils: Utils
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : this.tabIndex;
  }

  ngOnInit(): void {
    this.userModel = new addUserModel();
    this.response = {
      accountNumber: '',
      availableBalance: 0,
      companyName: '',
      routingNumber: '',
      pendingCredits: 0,
      pendingDebits: 0,
      endingAccountBalance: 0,
      endDate: '',
      galileoPrn: ''
    }
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));

    this.userModel.firstName = userProfile['firstName'];
    this.userModel.lastName = userProfile['lastName'];
    this.userModel.email = userProfile['email'];
    this.userModel.jobTitle = userProfile['jobTitle'];
    this.userModel.role = userProfile['role'];
    if (this.userModel.role == '0') {
      this.userModel.role = 'FZAdmin';
    } else if (this.userModel.role == '1') {
      this.userModel.role = 'Admin';
    } else if (this.userModel.role == '2') {
      this.userModel.role = 'User';
    } else if (this.userModel.role == '3') {
      this.userModel.role = 'Viewer';
    }

    this.userModel.phone = userProfile['phone'];
    this.userId = userProfile['userId'];
    this.activatedRoute.paramMap.subscribe((params) => {
      this.statementId = params.get('id');
    });
    this.fromDate = moment(sessionStorage.getItem('fromDate')).format('MMM DD, yyyy')
    this.toDate = moment(sessionStorage.getItem('toDate')).format('MMM DD, yyyy')
    this.loadData(
      null,
      null,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }


  loadData(
    userName: string,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    this.isLoading = true;
    this.selection.clear();
    this.statementService
      .getStatementDetail(this.statementId)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.data != null) {
            this.page.length = res.data.totalItems;
            var list = [];
            this.response.accountNumber = res.data.accountNumber;
            this.response.availableBalance = res.data.availableBalance;
            this.response.routingNumber = res.data.routingNumber;
            this.response.galileoPrn = res.data.galileoPrn;
            this.response.endingAccountBalance = res.data.endingAccountBalance;
            this.response.pendingDebits = res.data.pendingDebits;
            this.response.pendingCredits = res.data.pendingCredits;
            this.response.companyName = res.data.companyName;
            this.response.endDate = this._utils.removeZ(res.data.endDate).toString();

            res.data?.transactions.map((item) => {
              let record = {
                Activity: item.date,
                Processor: item.processorName,
                Description: item.description,
                Debits: item.debitAmount,
                Credits: item.creditAmount,
                Balance: item.balance,
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = []
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }
  onPrintClicked() {
    this.statementService.generateStatementPDF(
      this.statementId,
      moment(sessionStorage.getItem('fromDate')).format('yyyyMMDD'),
      moment(sessionStorage.getItem('toDate')).format('yyyyMMDD'));
  }
  navigateToPage() {
    this.router.navigate(['statements'],
      {
        state: {
          tabIndex: this.tabIndex
        }
      });
  }

  dataShow(data: any) {
    if (data < 0 && data) {
      return '($' + Math.abs(data).toFixed(2) + ')'
    }
    else if (data > 0 && data) {
      return '$' + data.toFixed(2)
    }
    else return '-'
  }
}
