  <div style="min-height:100%">
    <div
    fxLayout="column"
    fxFlex="40%"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    style="background-color: #032859"
  >
    <img class="logo" src="/assets/logo.png" alt="image" />
    <br />
    <span class="font-Title-First">
      We Didn't invent payment processing. We</span
    >
    <span class="font-Title-After">just made it better</span>
  </div>
  <div fxLayout="column" fxFlex="60%" style="justify-content: center">
    <mat-card>
        <div class="header-text">OTP Verification</div>
        <div style="display: flex;flex-direction: row;justify-content: space-between;width: 100%;">
          <div style="width:80%">
            <div class="subheader-text">Enter OTP</div>
          </div>
          <div style="width:20%;margin-top: auto; cursor: pointer;">
            <button (click)="clearOtpCode()" mat-button style="color:blue;font-weight: 600;">Clear Code
            </button>
          </div>
        </div>
        <div class="otpInput">
            <ngx-otp-input
              #ngxotp
              class="a"
              style="justify-content: space-between"
              [config]="otpInputConfig"
              (otpChange)="handleOtpChange($event)"
            ></ngx-otp-input>
          </div>
          <div class="resend">
            <a mat-button class="btn" (click)="resendOtp()">
              <div fxLayout="row">
                <p class="resendText">Resend Code</p>
              </div>
            </a>
          </div>
          <div class="checkbox">
            <mat-checkbox color="primary" class="checkbox" (change)="checkCheckboxVakue($event)"
              >Remember me for next 60 days
            </mat-checkbox>
          </div>
          <div fxLayout="column" class="btn">
            <button mat-raised-button color="primary" (click)="verifyOtp()">
              VERIFY OTP
            </button>
            <button
              mat-button
              class="btn-back"
              (click)="backEvent()"
            >
              Back
            </button>
          </div>
        </mat-card>
      </div>

  </div>
  