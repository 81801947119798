import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SettingsModel } from 'src/app/featureModules/models/settings.model';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  loadMessage: string = 'Please wait..';
  isLoading: boolean;
  selectedTabIndex: number = 0;
  loadInvoiceSetup: boolean = true;
  loadEmailSetup: boolean;
  loadManageNotication: boolean;
  settingsModel: SettingsModel=null;


  constructor( private router: Router,
    private activatedRoute: ActivatedRoute,private _invoiceService: InvoiceService,
    private _toastrService: ToastrService,) { 
      const navigation = this.router.getCurrentNavigation();
    }

  ngOnInit(): void {
    this.getInvoiceSetupSettings()
  }

 

  getInvoiceSetupSettings() { 
    this.isLoading = true;
    this._invoiceService.getInvoiceSetupDetails().subscribe(
      (data) => {
        if(data.code == 200){
          this.settingsModel = data.data;                       
        }
        else{
          this.settingsModel = new SettingsModel();
          this._toastrService.warning(data.errorMessage);
        }
        this.isLoading = false;                
      },
      (error) => {
        if (error.error.message != null || error.error.message != undefined) {
          this._toastrService.warning(error.error.message);
        } else {
          this._toastrService.warning(error.error.content);
        }
        this.isLoading = false;
      }
    );
  }

}
