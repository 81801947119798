<div class="page-design">
  <form [formGroup]="paymentLinkFormGroup">
    <div class="form-group" formGroupName="pricingTabForm">
      <div fxLayout="row" fxLayout.xs="column" class="label-text">
        <mat-label>Confirmation Number Entry Method</mat-label>
      </div>
      <mat-radio-group
        fxLayout="column"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-control"
        name="invoiceNoEntryMethod"
        formControlName="invoiceNoEntryMethod"
        (change)="onInvoiceNoEntryMethodChanged()"
      >
        <mat-radio-button value="SelfInvoice">Self Invoice No.</mat-radio-button>
        <mat-radio-button value="FinzeoInvoice">{{logotext}} Invoice No.</mat-radio-button>
        <mat-radio-button value="CustomerInvoice">Customer Invoice No.</mat-radio-button
        >
      </mat-radio-group>
      <!-- <div *ngIf="!customer">
        <div class="label-text" >        
          <mat-label>Confirmation Number:</mat-label>
        </div>      
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="invoiceNumber"
              class="form-control"
              name="invoiceNumber"
              autocomplete="off"
            />
            <mat-error>
              {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['invoiceNumber']["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>       -->
      <div *ngIf="customer">
        <div class="label-text" >        
          <mat-label>Suggested Confirmation Number:</mat-label>
        </div>      
        <div fxLayout="column"class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="suggestedInvoiceNumber"
              class="form-control"
              name="suggestedInvoiceNumber"
              autocomplete="off"
            />
            <mat-error>
              {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['suggestedInvoiceNumber']["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" class="label-text">
        <mat-label>Price Entry Method</mat-label>
      </div>
      <mat-radio-group
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-control"
        name="priceEntryMethod"
        formControlName="priceEntryMethod" 
        (change)="onPriceEntryMethodChange()"       
      >
        <mat-radio-button value="FixedPrice">Self Fixed Price</mat-radio-button>
        <mat-radio-button value="BuyerPrice">Allow Buyer to Input Price</mat-radio-button
        >
      </mat-radio-group>
      <div *ngIf="!customer">
        <div class="label-text">
          <mat-label>$ Amount</mat-label>
        </div>
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="amount"
              class="form-control"
              name="amount"
              appTwoDigitDecimalNumber
              autocomplete="off"
              (change)="setAmount($event)"
            />
            <mat-error>
              {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount']["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="label-text">
          <mat-label>Tax %</mat-label>
        </div>
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="tax"
              class="form-control"
              name="tax"
              appTwoDigitDecimalNumber
              autocomplete="off"
              (change)="setTax($event)"
            />
            <mat-error>
                {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['tax']["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="label-text">
          <mat-label>Shipping %</mat-label>
        </div>
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="shipping"
              class="form-control"
              name="shipping"
              appTwoDigitDecimalNumber
              autocomplete="off"
              (change)="setShipping($event)"
            />
            <mat-error>
                {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['shipping']["_errorMessage"] }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="label-text">
          <mat-label>$ Total Amount</mat-label>
        </div>
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="totalAmount"
              class="form-control"
              name="totalAmount"
              appTwoDigitDecimalNumber
              autocomplete="off"
              readonly
            />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="customer">
        <div class="label-text">
          <mat-label>Suggested Amount $</mat-label>
        </div>
        <div fxLayout="column" class="input-box">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="amount"
              class="form-control"
              name="amount"
              appTwoDigitDecimalNumber
            />   
            <mat-error>
              {{ paymentLinkFormGroup.controls['pricingTabForm']['controls']['amount']["_errorMessage"] }}
            </mat-error>         
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
