import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { GradeScoreChartComponent } from 'src/app/sharedModules/components/feature-related/grade-score-chart/grade-score-chart.component';
import { GradeScoreChartModel } from 'src/app/sharedModules/components/feature-related/grade-score-chart/models/grade-score-chart.model';
import { CustomerChallengerScoreServiceProxy, GradeSettingItem, GradeSettingsModel } from 'src/app/sharedModules/services/customer/customer.proxies';
import { validateGradeSettings } from '../validation/validateGradeSettings';

@Component({
  selector: 'app-grade-setting',
  templateUrl: './grade-setting.component.html',
  styleUrls: ['./grade-setting.component.scss']
})
export class GradeSettingComponent implements OnInit {

  isLoading: boolean = false;
  flagChart : boolean = false;

  public lstGrade = [];
  flagError : boolean = false;
  public validateErrors : any;
  public canSave : boolean = true;
  
  @ViewChild("gradeScoreChart", { static: true }) public gradeScoreChart: GradeScoreChartComponent;
  @ViewChild(GradeScoreChartComponent) childComponent: GradeScoreChartComponent;
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  @ViewChild('callAPIDialogReset') callAPIDialogReset: TemplateRef<any>;
  dialogRef : MatDialogRef<any, any>;
  private _gradeScoreChartModel: GradeScoreChartModel;
  private EXAMPLE_SCORE: number = 725;
  public get gradeScoreChartModel(): GradeScoreChartModel {
    return this._gradeScoreChartModel;
  }
  public set gradeScoreChartModel(value: GradeScoreChartModel) {
    this._gradeScoreChartModel = value;
  }
  form = new FormGroup({});
  public formTemplate = [];
  dataSource: GradeSettingsModel;
    
  constructor(private _formBuilder: FormBuilder,
     private dialog : MatDialog,
     private _scoreService: CustomerChallengerScoreServiceProxy,
     private _toastrService: ToastrService,
     ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({});
    this.initRules();
  }

  public initRules() {
    this.isLoading = true;
    this._scoreService.getChallengerGradeSettings().subscribe({
      next: (resp: any) => {
        if(resp.code == 200)
        {
          this.dataSource = resp.data as GradeSettingsModel;
          this.bindingRules();
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.flagChart = true;
        this.isLoading = false;
      }
    });
  }

  private bindingRules() {
    this.formTemplate = [];
    this.lstGrade = [];
    this.form = this._formBuilder.group({});
    this.dataSource.gradeSettings?.forEach( (setting, index) => {
      this.formTemplate.push({fieldIndex: index, From: `from${index}`, To: `to${index}`, Grade: `grade${index}`});
      let fromControl = new FormControl('');
      let toControl = new FormControl('');
      let gradeControl = new FormControl('');
      this.form.addControl(`from${index}`, fromControl);
      this.form.addControl(`to${index}`, toControl);
      this.form.addControl(`grade${index}`, gradeControl);
      fromControl.setValue(setting.from);
      toControl.setValue(setting.to);
      gradeControl.setValue(setting.grade);
      this.lstGrade.push(setting.grade);
      this.extractGradeScoreModel(this.dataSource.gradeSettings);
    });
  }

  private extractGradeScoreModel(items: GradeSettingItem[]) {
    this.gradeScoreChartModel = new GradeScoreChartModel(items, this.EXAMPLE_SCORE, 'B');
  }

  public onValueChanged() {

    this.flagError = false;
    var items = this.dataSource.gradeSettings?.map( (setting, i) => {
      setting.from = parseInt(this.form.controls[`from${i}`].value);
      setting.to = parseInt(this.form.controls[`to${i}`].value);
      setting.grade = this.form.controls[`grade${i}`].value;

      return setting;
    });

    this.validateErrors = validateGradeSettings(items);
    if(this.validateErrors?.length > 0){
      this.canSave = false;
    }else{
      this.canSave = true;
    }
    this.extractGradeScoreModel(items);
  }

  public saveRules()
  {
    this.canSave = true;
    this.validateErrors = validateGradeSettings(this.dataSource.gradeSettings);
    if(this.validateErrors?.length > 0){
      this.canSave = false;
      this._toastrService.error('Failed to save Global Grade Settings');
      return;
    }

    this.isLoading = true;
    this.dataSource.gradeSettings?.forEach( (setting, i) => {
      setting.from = parseInt(this.form.controls[`from${i}`].value);
      setting.to = parseInt(this.form.controls[`to${i}`].value);
      setting.grade = this.form.controls[`grade${i}`].value;
    });
    this._scoreService.setChallengerGradeSettings(this.dataSource).subscribe({
      next: (resp: any) => {
        this.isLoading = false;
        if(resp.code == 200)
        {
          this.childComponent.ngOnInit();
          this._toastrService.success('Saved Grade setting successfully');
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.flagChart = true;
        this.isLoading = false;
      }
    });
  }

  openDialog() {
    this.dialogRef = this.dialog.open(this.callAPIDialog);
  }

  openDialogReset() {
    this.dialogRef = this.dialog.open(this.callAPIDialogReset);
  }

  public resetRules() {
    this.isLoading = true;
    this.dialogRef.close();
    this.flagChart = false;
    this._scoreService.getChallengerGradeSettings().subscribe({
      next: (resp: any) => {
        if(resp.code == 200)
        {
          this.validateErrors = null;
          this.canSave = true;
          this.dataSource = resp.data as GradeSettingsModel;
          this.bindingRules();
          this._toastrService.success('Reset Global Grade Settings to values before edit successfully');
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.flagChart = true;
        this.isLoading = false;
      }
    });
  }

  public restoreRules() {
    this.isLoading = true;
    this.dialogRef.close();
    this.flagChart = false;
    this._scoreService.resetChallengerGradeSettings().subscribe({
      next: (resp: any) => {
        if(resp.code == 200)
        {
          this.validateErrors = null;
          this.canSave = true;
          this.dataSource = resp.data as GradeSettingsModel;
          this.bindingRules();
          this._toastrService.success('Restore global grade settings succesfully');
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.flagChart = true;
        this.isLoading = false;
      }
    });
  }

}
