import { maxLength, pattern, prop } from "@rxweb/reactive-form-validators";

export class CreditDebitTransactionsFilterModel {
  @prop()
  status: string='All';

  @prop()
  scheduledStartDate: string;

  @prop()
  scheduledEndDate: string;

  @prop()  
  processedStartDate: string;

  @prop()  
  processedEndDate: string;

  @prop()  
  amountRangeFrom: string;

  @prop()  
  amountRangeTo: string;
  
}
