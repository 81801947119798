<div [class]="previewWiseStyle.inputControlClass" [style.height]="previewWiseStyle.divPreviewHeight"
  class="container-information-page" [style.--active-color-fontFamily]="this.formData?.customisationTabForm?.themeFont">
  <mat-spinner class="loadSpinner" *ngIf="changesIsLoading" color="accent" diameter="50"></mat-spinner>
  <form [formGroup]="informationFormGroup">
    <mat-toolbar [style.height]="previewWiseStyle.headerToolbarHeight" [class]="deviceWiseStyle.headerToolbarClass"
      class="header-toolbar"
      [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
      [style.--active-color-textColor]="this.formData?.customisationTabForm?.themeTextColor ?? '#1e4ed8'">
      <div class="w-100" [class]="deviceWiseStyle.headerToolbarLabelDivClass" fxLayout="row" fxLayout.xs="column"
        fxLayoutAlign="space-between stretch">
        <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxFlex="33.3%" fxFlex.xs="100%"
          fxLayoutAlign="center start" fxLayoutAlign.xs="stretch start">
          <mat-label [style.font-size]="previewWiseStyle.headerLableFontSize" class="headerBackButton">
            <button mat-icon-button>
              <mat-icon>arrow_back_ios</mat-icon>
            </button>
            Order Section
          </mat-label>
        </div>

        <div fxLayout="column" fxLayout.xs="row" [class]="deviceWiseStyle.companyLogoDivClass" fxLayoutWrap
          fxFlex="33.3%" fxFlex.xs="100%" fxLayoutAlign="center center">
          <img [style.height]="previewWiseStyle.logoImageHeight" class="company-logo" [src]="getLogoPath(this.formData)"
            alt="company logo" />
        </div>
        <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxFlex="33.3%" fxFlex.xs="0%"
          fxLayoutAlign="center start">
        </div>
      </div>
    </mat-toolbar>

    <div [class]="deviceWiseStyle.orderContainerMainClass" class="order-container" fxLayout="row" fxLayout.xs="column"
      fxLayoutGap="2%" fxLayoutAlign="start stretch">
      <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxFlex="49%"
        fxLayoutAlign.sm="start stretch" fxLayoutAlign="start stretch">

        <!-- order details -->
        <mat-card [class]="deviceWiseStyle.orderContainerMainInnerClass" class="order-details-card" fxLayout="column"
          fxLayoutGap="5%">
          <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" class="w-100">
            <div fxLayout="column" fxFlex="60%" fxLayoutGap="8%" fxLayoutAlign="start stretch">
              <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center stretch">
                <mat-label class="order-details" [style.font-size]="previewWiseStyle.headerLableFontSize">Order
                  Details</mat-label>
              </div>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxFlex="65%" fxLayoutGap="5%" class="sub-text">
              <div *ngIf="validateField(this.formData?.configurationTabForm?.isInvoiceNumberView)" fxLayout="row"
                fxLayoutGap="5%" fxLayoutAlign="center stretch">
                <div fxLayout="column" fxFlex="45%" class="sub-text"
                  [style.font-size]="previewWiseStyle.orderLableFontSize">Confirmation Number
                </div>
                <div fxLayout="column" fxLayoutAlign="end end" fxFlex="50%" class="sub-text word-wra"
                  [style.font-size]="previewWiseStyle.orderValueFontSize">
                  {{ invoiceNumber }}
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch">
                <div fxLayout="column" fxFlex="45%" class="sub-text"
                  [style.font-size]="previewWiseStyle.orderLableFontSize">Amount
                </div>
                <div fxLayout="column" fxLayoutAlign="end end" fxFlex="50%" class="sub-text word-wra"
                  [style.font-size]="previewWiseStyle.orderValueFontSize">
                  ${{ amount ?? 0 | number : "1.2-2" }}
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" *ngIf="agreesToSurcharge">
                <div fxLayout="column" fxFlex="45%" class="sub-text word-wra"
                  [style.font-size]="previewWiseStyle.orderValueFontSize">
                  Surcharge Fees ({{ surchargePercent }}%)
                </div>
                <div fxLayout="column" fxFlex="50%" class="sub-text" fxLayoutAlign="end end"
                  [style.font-size]="previewWiseStyle.orderValueFontSize">
                  ${{ surchargeFees ?? 0 | number : "1.2-2" }}
                </div>
              </div>

              <ng-container *ngIf="availConvenienceFee">
              <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" *ngIf="agreesToConvenienceFees">
                <div fxLayout="column" fxFlex="45%" class="sub-text word-wra"
                  [style.font-size]="previewWiseStyle.orderLableFontSize">
                  Convenience Fees
                </div>
                <div fxLayout="column" fxFlex="50%" class="sub-text" fxLayoutAlign="end end"
                  [style.font-size]="previewWiseStyle.orderValueFontSize">
                  ${{ convenienceFees ?? 0 | number : "1.2-2" }}
                </div>
              </div>
            </ng-container>

              <div *ngIf="validateField(this.formData?.configurationTabForm?.isDescriptionView)" fxLayout="row"
                fxLayoutGap="10px" fxLayoutAlign="center stretch">
                <div fxLayout="column" class="w-100">
                  <div fxLayout="row" class="sub-text" [style.font-size]="previewWiseStyle.orderLableFontSize">
                    Description
                  </div>
                  <div fxLayout="row" class="sub-text word-wra" [style.font-size]="previewWiseStyle.orderValueFontSize">
                    {{ description }}
                  </div>
                </div>
              </div>
            </div>

            <mat-card class="total-amount-card" fxLayout="column" fxFlex="30%" fxLayoutAlign="center space-around">
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
                <img src="assets/payment-widget-icons/orderAmount.png" alt="logo" class="order-logo"
                  [style.height]="previewWiseStyle.logoImageHeight" />
              </div>
              <br />

              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="total-amount"
                [style.color]="labelColor" [style.font-size]="previewWiseStyle.orderValueFontSize">
                ${{ totalAmount ?? amount | number : "1.2-2" }}
              </div>
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center" class="theme-label"
                [style.color]="labelColor" [style.font-size]="previewWiseStyle.orderLableFontSize">
                Total of all charges & fees
              </div>
            </mat-card>
          </div>
        </mat-card>

        <!-- order deails end -->
        <br />

        <!-- payment details -->
        <mat-card [class]="deviceWiseStyle.orderContainerMainInnerClass" class="payment-details-card" fxLayout="column">
          <div fxLayout="column" fxLayoutGap="4%" fxLayoutAlign="start stretch">
            <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap="5%"
              fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch" fxLayoutAlign="start center">
              <div fxLayout="column" fxFlex="55%" fxLayoutGap="10px" fxLayoutAlign="center start">
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
                  <mat-label class="payment"
                    [style.font-size]="previewWiseStyle.headerLableFontSize">Payment</mat-label>
                </div>

                <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
                  <mat-label class="sub-header" [style.font-size]="previewWiseStyle.orderLableFontSize">Choose any
                    Payment method</mat-label>
                </div>
              </div>

              <div fxLayout="column" fxLayout.xs="row" fxFlex="50%" fxLayoutGap="10px">
                <div fxLayout="row" fxLayout.xs="row" fxLayoutGap="10px" fxLayoutAlign="start stretch">
                  <div fxLayout="column" *ngIf="validateCard(this.formData)">
                    <div class="image-container" *ngIf="isPayWithCard">
                      <img src="assets/payment-widget-icons/payWithCard.png" alt="payWith-logo"
                        class="payWith-logo highlighted" [style.border-color]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
                        [style.height]="previewWiseStyle.logoPaymentType" (click)="enablePaymentType('payWithCard')" />
                      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                        class="overlay-image-upper done-check-on-payment-cards-logo-upper"
                        [style.height]="previewWiseStyle.logoPaymentTypeCheck" />
                    </div>
                    <div *ngIf="!isPayWithCard">
                      <img src="assets/payment-widget-icons/payWithCard.png" alt="payWith-logo"
                        class="payWith-logo highlighted" [style.height]="previewWiseStyle.logoPaymentType"
                        (click)="enablePaymentType('payWithCard')" />
                    </div>
                  </div>

                  <div fxLayout="column" *ngIf="validateAch(this.formData)">
                    <div class="image-container" *ngIf="isPayWithAch">
                      <img src="assets/payment-widget-icons/payWithAch.png" alt="payWith-logo"
                        class="payWith-logo highlighted" [style.border-color]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
                        [style.height]="previewWiseStyle.logoPaymentType" (click)="enablePaymentType('payWithAch')" />
                      <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                        class="overlay-image-upper done-check-on-payment-cards-logo-upper"
                        [style.height]="previewWiseStyle.logoPaymentTypeCheck" />
                    </div>
                    <div *ngIf="!isPayWithAch">
                      <img src="assets/payment-widget-icons/payWithAch.png" alt="payWith-logo"
                        class="payWith-logo highlighted" [style.height]="previewWiseStyle.logoPaymentType"
                        (click)="enablePaymentType('payWithAch')" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch">
              <mat-divider></mat-divider>
            </div>
            <!--ach payment  -->
            <div [hidden]="!isPayWithAch" fxLayoutGap="5%">

              <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" class="card-label-block">
                <mat-label class="sub-header" [style.font-size]="previewWiseStyle.headerLableFontSize"><b>ACH
                    Details</b></mat-label>
              </div>

              <div class="achDetailsForm">

                <div fxLayout="column" fxLayoutGap="5%">
                  <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="5%"
                    fxLayoutGap="0%" fxLayoutGap.xs="5%" fxLayoutAlign="start center" fxLayoutAlign.sm="start stretch"
                    fxLayoutAlign.xs="start stretch" class="form-group">
                    <div fxLayout="column" fxFlex="30%" fxLayout.sm="row" fxLayout.xs="row">
                      <label [style.font-size]="previewWiseStyle.orderLableFontSize">Account Type</label>
                    </div>
                    <div fxLayout="column" fxFlex="70%" fxLayout.sm="row" fxLayout.xs="row">
                      <mat-radio-group class="form-control w-100" name="entryCode" [disabled]="disableForm"
                        (change)="onEntryCodeSelected($event)">
                        <div fxLayout="row" fxLayout.sm="row" fxLayout.xs="row" fxLayoutAlign="space-evenly center"
                          fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch" fxLayoutGap.sm="15%"
                          fxLayoutGap.xs="15%">
                          <div fxLayout="column" fxFLex="50%" fxLayoutWrap fxLayoutGap="5%"
                            *ngFor="let entryCodeItem of entryCodes">
                            <mat-radio-button [value]="entryCodeItem.value" [style.--active-color]="borderColor"  [style.font-family]="this.formData?.customisationTabForm?.themeFont"
                              [style.font-size]="previewWiseStyle.orderLableFontSize">
                              {{ entryCodeItem.value }}
                            </mat-radio-button>
                          </div>
                        </div>
                      </mat-radio-group>
                    </div>
                  </div>

                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="45%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">Account
                          Number</mat-label>
                        <input matInput name="accountNumber" maxlength="17" autocomplete="cc-number"
                          class="form-control" placeholder="Account Number"
                          [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                        <mat-error>
                          {{
                          informationFormGroup.controls["accountNumber"]["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="45%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">Routing
                          Number</mat-label>
                        <input matInput name="routingNumber" maxlength="12" autocomplete="cc-number"
                          class="form-control" placeholder="Routing Number"
                          [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                        <mat-error>
                          {{
                          informationFormGroup.controls["routingNumber"]["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start stretch"
                    class="form-group">
                    <section class="example-section">
                      <mat-checkbox [style.--active-color]="borderColor" (change)="recurringChecked($event)"
                        [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                        Recurring Transaction
                      </mat-checkbox>
                      <mat-error [style.font-size]="previewWiseStyle.orderLableFontSize">
                        {{ informationFormGroup.controls["isRecurring"]["_errorMessage"] }}
                      </mat-error>
                    </section>
                  </div>

                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group m-b-0" *ngIf="checkIfRecurring()">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="27%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">Frequency*</mat-label>
                        <mat-select name="frequency" [disabled]="disableForm">
                          <mat-option *ngFor="let frequency of frequencies" [value]="frequency.value"
                            [style.font-size]="previewWiseStyle.orderValueFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                            {{ frequency.viewValue }}
                          </mat-option>
                        </mat-select>
                        <mat-error [style.font-size]="previewWiseStyle.orderLableFontSize">
                          {{ informationFormGroup.controls["frequency"]["_errorMessage"] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="27%">
                      <mat-form-field class="date-picker-field" appearance="outline"
                        [style.--active-color]="borderColor">
                        <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">Schedule date</mat-label>
                        <input [disabled]="disableForm" matInput [matDatepicker]="datepicker" formControlName="scheduledDate"
                          [min]="minDate" [max]="maxDate" />
                        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker [startAt]="defaultScheduledDate">
                          <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>
                              Apply
                            </button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error>
                          {{
                          informationFormGroup.controls["scheduledDate"]["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="36%" fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch"
                      fxLayoutAlign="start stretch">
                      <section class="example-section">
                        <mat-checkbox [style.--active-color]="borderColor" [disabled]="disableForm"
                          [style.font-size]="previewWiseStyle.orderLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                          At end of the month
                        </mat-checkbox>
                        <mat-error [style.font-size]="previewWiseStyle.orderLableFontSize">
                          {{
                          informationFormGroup.controls["isEndOfMonth"]["_errorMessage"]
                          }}
                        </mat-error>
                      </section>
                    </div>
                  </div>

                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group" *ngIf="checkIfRecurring()">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label class="" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Total
                          Installments</mat-label>
                        <input matInput class="form-control" name="installment" placeholder="installment"
                          autocomplete="cc-csc" maxlength="2" type="text"
                          [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                        <mat-error [style.font-size]="previewWiseStyle.orderLableFontSize">
                          {{ informationFormGroup.controls["installment"]["_errorMessage"] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%">

                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%" fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch"
                      fxLayoutAlign="center center">

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ach payment end -->
            <!-- card payment  -->
            <div [hidden]="!isPayWithCard" fxLayoutGap="5%">

              <div fxLayout="row" fxLayoutGap="5%" fxLayoutAlign="start stretch" class="card-label-block">
                <mat-label class="sub-header" [style.font-size]="previewWiseStyle.headerLableFontSize"  [style.font-family]="this.formData?.customisationTabForm?.themeFont"><b>Card
                    Details</b></mat-label>
              </div>

              <div fxLayout="row" fxLayoutGap="2.5%" fxLayoutAlign="start stretch">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div class="image-container">
                    <img src="assets/payment-widget-icons/visaCard.png" alt="visa-card-logo"
                      class="visa-card-logo highlighted" [style.border-color]="borderColor" />
                    <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                      class="overlay-image done-check-on-small-cards-logo" />
                  </div>
                  <!-- <div>
                  <img src="assets/payment-widget-icons/visaCard.png" alt="visa-card-logo" class="visa-card-logo" />
                </div> -->
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                  <!-- <div class="image-container">
                  <img src="assets/payment-widget-icons/americalExpressCard.png" alt="American Express Logo"
                    class="other-card-logo highlighted" [style.border-color]="borderColor" />
                  <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                    class="overlay-image done-check-on-small-cards-logo" />
                </div> -->
                  <div>
                    <img src="assets/payment-widget-icons/americalExpressCard.png" alt="American Express Logo"
                      class="other-card-logo" />
                  </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                  <!-- <div class="image-container">
                  <img src="assets/payment-widget-icons/mastercard.png" alt="MasterCard Logo"
                    class="other-card-logo highlighted" [style.border-color]="borderColor" />
                  <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                    class="overlay-image done-check-on-small-cards-logo" />
                </div> -->
                  <div>
                    <img src="assets/payment-widget-icons/mastercard.png" alt="MasterCard Logo"
                      class="other-card-logo" />
                  </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                  <!-- <div class="image-container">
                  <img src="assets/payment-widget-icons/discoverCard.png" alt="Discover Logo"
                    class="other-card-logo highlighted" [style.border-color]="borderColor" />
                  <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                    class="overlay-image done-check-on-small-cards-logo" />
                </div> -->
                  <div>
                    <img src="assets/payment-widget-icons/discoverCard.png" alt="Discover Logo"
                      class="other-card-logo" />
                  </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                  <!-- <div class="image-container">
                  <img src="assets/payment-widget-icons/unknownCard.png" alt="Unknown Logo"
                    class="other-card-logo highlighted" [style.border-color]="borderColor" />
                  <img src="assets/payment-widget-icons/doneCheckGreen.png" alt="done"
                    class="overlay-image done-check-on-small-cards-logo" />
                </div> -->
                  <div>
                    <img src="assets/payment-widget-icons/unknownCard.png" alt="Unknown Logo" class="other-card-logo" />
                  </div>
                </div>
              </div>

              <div class="cardDetailsForm">
                <div fxLayout="column" fxLayoutGap="2%">
                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="40%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Card
                          Number</mat-label>
                        <input [disabled]="disableForm" matInput name="cardNumber" formControlName="cardNumber"
                          inputmode="numeric" [type]="'text'" maxlength="23" autocomplete="cc-number"
                          appDebitCreditFormat class="form-control" placeholder="Card Number"
                          [style.font-size]="previewWiseStyle.orderLableFontSize" />
                        <mat-error>
                          {{ informationFormGroup.controls["cardNumber"]["_errorMessage"] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="55%">
                      <div fxLayout="row" fxLayoutGap="5%" fxLayoutGap.sm="5%" fxLayoutGap.xs="5%">
                        <div fxLayout="column" fxLayoutWrap fxFlex="45%">
                          <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                            <mat-label class="required"
                              [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Expiration
                              Date</mat-label>
                            <input [disabled]="disableForm" matInput formControlName="expirationDate" class="form-control"
                              name="lastName" placeholder="Expiration Date" autocomplete="cc-exp" maxlength="5"
                              appCardExpirationDateFormat [style.font-size]="previewWiseStyle.orderValueFontSize"
                              [disabled]="disableForm" />
                            <mat-error>
                              {{
                              informationFormGroup.controls["expirationDate"][
                              "_errorMessage"
                              ]
                              }}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayoutWrap fxFlex="35%">
                          <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                            <mat-label class="required"
                              [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">CVV</mat-label>
                            <input [disabled]="disableForm" matInput formControlName="cvv" class="form-control" name="cvv"
                              placeholder="CVV" autocomplete="cc-csc" maxlength="4" type="password"
                              [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                            <mat-error>
                              {{ informationFormGroup.controls["cvv"]["_errorMessage"] }}
                            </mat-error>
                          </mat-form-field>
                        </div>

                        <div fxLayout="column" fxLayoutWrap fxFlex="10%" fxLayoutAlign="center center">
                          <mat-icon class="tool-tip-icon" [style.color]="borderColor"
                            matTooltip="Card Verification Value is an additional security value printed on the physical credit card. Visa, MasterCard, Discover: a 3-digit number shown on the back of the card. American Express: a 4-digit number printed on the front of the card.">help_outline</mat-icon>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="5%" fxLayoutAlign="start stretch"
                    class="form-group">
                    <section class="example-section">
                      <mat-checkbox [style.--active-color]="borderColor" formControlName="isRecurring"
                        (change)="recurringChecked($event)" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                        Recurring Transaction
                      </mat-checkbox>
                      <mat-error>
                        {{ informationFormGroup.controls["isRecurring"]["_errorMessage"] }}
                      </mat-error>
                    </section>
                  </div>

                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group m-b-0" *ngIf="checkIfRecurring()">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="27%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Frequency*</mat-label>
                        <mat-select [disabled]="disableForm" name="frequency" formControlName="frequency">
                          <mat-option *ngFor="let frequency of frequencies" [value]="frequency.value"
                            [style.font-size]="previewWiseStyle.orderValueFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                            {{ frequency.viewValue }}
                          </mat-option>
                        </mat-select>
                        <mat-error>
                          {{ informationFormGroup.controls["frequency"]["_errorMessage"] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="27%">
                      <mat-form-field class="date-picker-field" appearance="outline"
                        [style.--active-color]="borderColor">
                        <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Schedule date</mat-label>
                        <input [disabled]="disableForm" matInput [matDatepicker]="datepicker" formControlName="scheduledDate"
                          [min]="defaultScheduledDate" [max]="maxDate" autocomplete="scheduledDate" [disabled]="disableForm" />
                        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker [startAt]="defaultScheduledDate" [disabled]="false">
                          <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>Cancel</button>
                            <button mat-raised-button color="primary" matDatepickerApply>
                              Apply
                            </button>
                          </mat-datepicker-actions>
                        </mat-datepicker>
                        <mat-error>
                          {{
                          informationFormGroup.controls["scheduledDate"]["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="36%" fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch"
                      fxLayoutAlign="start stretch">
                      <section class="example-section">
                        <mat-checkbox [disabled]="disableForm" [style.--active-color]="borderColor"
                          formControlName="isEndOfMonth" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                          At end of the month
                        </mat-checkbox>
                        <mat-error>
                          {{
                          informationFormGroup.controls["isEndOfMonth"]["_errorMessage"]
                          }}
                        </mat-error>
                      </section>
                    </div>
                  </div>

                  <div [class]="deviceWiseStyle.orderContainerMainClass" fxLayout="row" fxLayout.sm="column"
                    fxLayout.xs="column" fxLayoutWrap fxLayoutGap.sm="0%" fxLayoutGap.xs="0%" fxLayoutGap="5%"
                    fxLayoutAlign="start stretch" class="form-group" *ngIf="checkIfRecurring()">
                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%">
                      <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                        <mat-label class="" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Total
                          Installments</mat-label>
                        <input [disabled]="disableForm" matInput formControlName="installment" class="form-control"
                          name="installment" placeholder="installment" autocomplete="cc-csc" maxlength="2" type="text"
                          [style.font-size]="previewWiseStyle.orderValueFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont" />
                        <mat-error>
                          {{ informationFormGroup.controls["installment"]["_errorMessage"] }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%">

                    </div>

                    <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxLayoutWrap
                      fxFlex="30%" fxLayoutAlign.sm="start stretch" fxLayoutAlign.xs="start stretch"
                      fxLayoutAlign="center center">

                    </div>
                  </div>
                </div>
              </div>


            </div>
            <!--card payment end -->
          </div>
        </mat-card>
        <!-- payment details end-->

        <br />
      </div>

      <div [class]="deviceWiseStyle.orderContainerMainInnerClass" fxLayout="column" fxFlex="49%"
        fxLayoutAlign.sm="start stretch" fxLayoutAlign="space-between stretch">
        <mat-card class="billing-details-card" fxLayout="column">
          <mat-toolbar class="header-toolbar-bill"
            [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
            [style.--active-color-textColor]="this.formData?.customisationTabForm?.themeTextColor ?? '#1e4ed8'">
            <img src="assets/payment-widget-icons/billingDetails.png" alt="logo" class="billing-logo" />
            <mat-label class="billing-details" [style.font-size]="previewWiseStyle.headerLableFontSize">Billing
              Details</mat-label>
          </mat-toolbar>

          <div class="billingDetailsForm">
            <div fxLayout="column">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" class="form-group">
                <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                  <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Company Name</mat-label>
                  <input matInput class="form-control" name="companyName" placeholder="Company Name"
                    autocomplete="additional-name" maxlength="50"
                    [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                  <mat-error>
                    {{
                    informationFormGroup.controls["companyName"]["_errorMessage"]
                    }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" class="form-group">
                <div class="custom-field-space" fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">First
                      Name</mat-label>
                    <input matInput class="form-control" name="firstName" placeholder="First Name" autocomplete="name"
                      maxlength="50" [style.font-size]="previewWiseStyle.orderValueFontSize" [disabled]="disableForm" />
                    <mat-error>
                      {{
                      informationFormGroup.controls["firstName"]["_errorMessage"]
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Last
                      Name</mat-label>
                    <input [disabled]="disableForm" matInput class="form-control" name="lastName" placeholder="Last Name"
                      autocomplete="name" maxlength="50" [style.font-size]="previewWiseStyle.orderValueFontSize"
                      [disabled]="disableForm" />
                    <mat-error>
                      {{
                      informationFormGroup.controls["lastName"]["_errorMessage"]
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutWrap fxLayoutGap="1%"
                fxLayoutGap.xs="0%" fxLayoutGap.sm="0%" fxLayoutAlign="start stretch" class="form-group">
                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%"
                  [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">
                  <ngx-intl-tel-input [disabled]="disableForm" [ngClass]="{
                    'phone-invaid':
                    informationFormGroup.controls['phone'].invalid &&
                      informationFormGroup.controls['phone'].touched,
                    'phone-valid': informationFormGroup.controls['phone'].valid
                  }" [style.--active-color]="borderColor" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [customPlaceholder]="'Contact Number*'"
                    [searchCountryFlag]="true" [searchCountryField]="[
                    SearchCountryField.Iso2,
                    SearchCountryField.Name
                  ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates"
                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.National" [value]="phoneNumber" name="phone"
                    formControlName="phone" [maxLength]="maxLength">
                  </ngx-intl-tel-input>
                  <mat-error style="font-size: 9px">
                    {{
                    informationFormGroup.controls["phone"]["_errorMessage"]
                    }}
                  </mat-error>
                  <div class="phonehint-text" [style.font-size]="previewWiseStyle.orderLableFontSize">
                    eg.: {{ phonehint }}
                  </div>
                </div>

                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Email
                      Address</mat-label>
                    <input matInput [disabled]="disableForm" class="form-control" name="email" placeholder="Email Address"
                      autocomplete="email" maxlength="50" style="padding: 2px"
                      [style.font-size]="previewWiseStyle.orderValueFontSize" />
                    <mat-error>
                      {{ informationFormGroup.controls["email"]["_errorMessage"] }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" style="margin-top: 2%" class="form-group">
                <mat-form-field fxFlex="100%" appearance="outline" [style.--active-color]="borderColor">
                  <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Address
                    1</mat-label>
                  <textarea [disabled]="disableForm" matInput placeholder="Address Line 1" class="form-control" rows="3"
                    name="address1" autocomplete="address-level1" maxlength="150"
                    [style.font-size]="previewWiseStyle.orderValueFontSize"></textarea>
                  <mat-error>
                    {{ informationFormGroup.controls["address1"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" class="form-group">
                <mat-form-field fxFlex="100%" appearance="outline" [style.--active-color]="borderColor">
                  <mat-label [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Address 2</mat-label>
                  <textarea [disabled]="disableForm" matInput placeholder="Address Line 2" class="form-control" rows="3"
                    name="address2" autocomplete="address-level2" maxlength="150"
                    [style.font-size]="previewWiseStyle.orderValueFontSize"></textarea>
                  <mat-error>
                    {{ informationFormGroup.controls["address2"]["_errorMessage"] }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" class="form-group">
                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <div fxLayout="column" fxLayoutWrap fxFlex="50%">
                    <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                      <mat-label class="required"
                        [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Country</mat-label>
                      <input [disabled]="disableForm" matInput class="form-control" name="country" placeholder="Country"
                        autocomplete="country" maxlength="50" [style.font-size]="previewWiseStyle.orderValueFontSize" />
                      <mat-error>
                        {{
                        informationFormGroup.controls["country"]["_errorMessage"]
                        }}
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required"
                      [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">State/Province</mat-label>
                    <mat-select [disabled]="disableForm" class="form-control" name="state">
                      <mat-option [style.font-size]="previewWiseStyle.orderValueFontSize">data
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ informationFormGroup.controls["state"]["_errorMessage"] }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutGap.xs="0%"
                fxLayoutAlign="start stretch" class="form-group">
                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">City</mat-label>
                    <input [disabled]="disableForm" matInput class="form-control" name="city" placeholder="City"
                      autocomplete="city" maxlength="50" [style.font-size]="previewWiseStyle.orderValueFontSize" />
                    <mat-error>
                      {{ informationFormGroup.controls["city"]["_errorMessage"] }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxLayoutWrap fxFlex="47.5%">
                  <mat-form-field appearance="outline" [style.--active-color]="borderColor">
                    <mat-label class="required" [style.font-size]="previewWiseStyle.orderLableFontSize" [style.font-family]="this.formData?.customisationTabForm?.themeFont">Postal
                      Code</mat-label>
                    <input [disabled]="disableForm" matInput class="form-control" name="zip" placeholder="Postal Code"
                      autocomplete="postal-code" [mask]="'00000'" [showMaskTyped]="true"
                      [style.font-size]="previewWiseStyle.orderValueFontSize" />
                    <mat-error>
                      {{ informationFormGroup.controls["zip"]["_errorMessage"] }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>

    <div [class]="deviceWiseStyle.orderContainerFooterClass" class="order-container-footer" fxLayout="row"
      fxLayout.xs="column-reverse" fxLayoutAlign="center center">              
        <div fxLayout="column">
          <label style="text-align:center" 
          [style.font-family]="this.formData?.customisationTabForm?.themeFont"
          [style.font-size]="previewWiseStyle.buttonTextFontSize" > 
          Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
        </div>
      
        <div fxLayout="column">
          <img  [style.height]="previewWiseStyle.secureLogoImageHeight" class="securePayment" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
        </div>
        <div fxLayout="column" [class]="deviceWiseStyle.orderContainerFooterMarginClass">
        <div fxLayout="row">
          <div fxLayout="column">
            <button [disabled]="disableForm" mat-button class="secondaryBtn button-line-height"
              [style.font-size]="previewWiseStyle.buttonTextFontSize" [style.color]="borderColor"
              [style.--active-color-borderColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
              [style.min-width]="previewWiseStyle.buttonMinWidth"
              [style.font-family]="this.formData?.customisationTabForm?.themeFont">
              Cancel
            </button>
          </div>

          <div fxLayout="column">
            <button [disabled]="disableForm" mat-flat-button class="button-line-height"
              [style.font-size]="previewWiseStyle.buttonTextFontSize"
              [style.min-width]="previewWiseStyle.buttonMinWidth"
              [style.font-family]="this.formData?.customisationTabForm?.themeFont"
              [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
              [style.--active-color-textColor]="this.formData?.customisationTabForm?.themeTextColor ?? '#1e4ed8'">
              Make Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>