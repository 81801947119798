import { Component, Input, OnInit } from '@angular/core';
import { PageSettingModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  showSurchargeFees = false;

  @Input()
  invoiceNumber: any;

  @Input()
  amount: number;

  @Input()
  totalAmount: number;

  @Input()
  description: string;

  @Input()
  surchargePercent: any;

  @Input()
  surchargeFees: any;

  @Input()
  agreesToSurcharge: boolean;

  @Input()
  pageSettingModel: PageSettingModel;

  @Input()
  convenienceFees: any;

  @Input()
  agreesToConvenienceFees: boolean;

  constructor() { }

  ngOnInit(): void { }

  displayFields(conditionType: string): boolean {
    if (conditionType.toLowerCase() === "show") {
      return true;
    }
    if (conditionType.toLowerCase() === "hide") {
      return false
    }
  }
}
