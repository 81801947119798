import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class BillingDetailsModel {
  @prop()
  id: number;

  @prop()
  paymentType: string;

  @required()
  customerType: boolean = true;

  @required({
    conditionalExpression: function (control: AbstractControl) {
      if (this.paymentType == 'ACH') {
        return this.customerType == true ? false : true;
      }
      return false;
    },
  })
  @maxLength({ value: 50 })
  companyName: string;

  @required({
    conditionalExpression: function (control: AbstractControl) {
      if (this.paymentType == 'ACH') {
        return this.customerType == true ? true : false;
      }
      return true;
    },
  })
  @maxLength({ value: 50 })
  @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  firstName: string;

  @required({
    conditionalExpression: function (control: AbstractControl) {
      if (this.paymentType == 'ACH') {
        return this.customerType == true ? true : false;
      }
      return true;
    },
  })
  @maxLength({ value: 50 })
  @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  lastName: string;

  @required()
  phone: string;

  @required()
  @maxLength({ value: 50 })
  @pattern({
    expression: { Email: /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/ },
  })
  email: string;

  @required()
  @maxLength({ value: 150 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
  address1: string;

  @maxLength({ value: 150 })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  @pattern({ expression: { CompanyName: /^[a-zA-Z0-9 ,.\-\(\)]*$/ } })
  address2: string;

  @required()
  @maxLength({ value: 50 })
  @pattern({ expression: { Alpha: /^[a-zA-Z ]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  city: string;

  @required()
  @maxLength({ value: 3 })
  state: string;

  @required()
  country: string;

  @required()
  @maxLength({ value: 10 })
  @pattern({ expression: { 'Alpha_Numbers': /^\d{5}(-\d{4})?$|^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/ }, message :'Enter valid postal code (e.g. 12345, 12345-6789, A1B 2C3)' })
  zip: string;

  @prop()
  isoCode: string;
}
