<div class="transaction-amount-chart-container">
  <mat-spinner *ngIf="isLoading" class="loadSpinner" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{ loadMessage }}
  </mat-spinner>

  <div *ngIf="!isLoading">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="94%">
        <mat-label class="total-amount-text">{{ preHeadingLabel }} Total Amount</mat-label>
      </div>
      <div fxLayout="column" fxFlex="3%">
        <img src="assets/grid-icons/linechart.png" class="chart-icon" (click)="selectChartType('line')"
          matTooltip="Line Chart" [ngStyle]="{
          ' background': barChartType == 'line' ? 'antiquewhite' : ''
        }" />
      </div>
      <div fxLayout="column" fxFlex="3%">
        <img src="assets/grid-icons/barchart.png" class="chart-icon" (click)="selectChartType('bar')"
          matTooltip="Bar Chart" [ngStyle]="{
          ' background': barChartType == 'bar' ? 'antiquewhite' : ''
        }" />
      </div>
    </div>
    <br />

    <div *ngIf="this.searchFields.typeOfService != 'CREDITDEBIT'" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="6%">
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
          <mat-label data-html2canvas-ignore="true" class="ach-amount-style">Transaction Amount (in %)</mat-label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="94%">
        <div  style="display: block">
          <canvas  baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
        </div>       
      </div>
    </div>

    <div *ngIf="this.searchFields.typeOfService == 'CREDITDEBIT'" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
      <div fxLayout="column" fxFlex="6%">
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
          <mat-label data-html2canvas-ignore="true" class="ach-amount-style">Transaction Amount (in %)</mat-label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="94%">
        <div  style="display: block">
          <canvas  baseChart [data]="cardBarChartData" [options]="barChartOptions" [type]="barChartType"
            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
        </div>       
      </div>
    </div>
  </div>
</div>