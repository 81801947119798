import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-dashboard-report-total-amount',
  templateUrl: './dashboard-report-total-amount.component.html',
  styleUrls: ['./dashboard-report-total-amount.component.scss']
})
export class DashboardReportTotalAmountComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  totalAmountGridData;
  transactionType: string = '';
  private timeout: number;
  tableLabel:string=""
  period:string="yearly"

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: '',
    direction: '',
  };

  totalAmountcolumns: TableColumn[] = [
    { displayName: 'MONTH', name: 'month', width: '11%' },
    { displayName: 'TRANSACTION AMOUNT (LY)', name: 'lastYearAmount', width: '20%' },
    { displayName: 'TRANSACTION AMOUNT (TY)', name: 'currentYearAmount', width: '20%' }    
  ];

  totalAllColumns: TableColumn[];

  constructor(private router: Router,
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute) {
    this.totalAllColumns = this.totalAmountcolumns;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.transactionType = params.get('product');
      if(this.transactionType == 'ach')
      {
        this.tableLabel="ACH Transaction Amount"
      }
      if(this.transactionType == 'creditdebit')
      {
        this.tableLabel="Credit/Debit Transaction Amount"
      }
       // hiding the INF feature
      // if(this.transactionType == 'instantfund')
      // {
      //   this.tableLabel="Instant Fund Transaction Amount"
      // }
      if(this.transactionType == 'echeck')
      {
        this.tableLabel="E-Check Transaction Amount"
      }      
    });
    this.loadData(this.transactionType,this.sortBy.active,
      this.sortBy.direction);

  }

  navigateToDashboardPage() {
    this.router.navigate(['dashboard']);
  }

  onRefresh(id: string) { }

  loadData(transactionType,sortBy: string,
    sortDirection: string) {
    this.isLoading = true;
    this._dashboardService.GetTransactionTotalAmountData(transactionType,sortBy, sortDirection).subscribe((data) => {
      this.isLoading = false;
      if (data.data != null) {
        this.totalAmountGridData = data.data;
        this.page.length = data.data.totalRecords;
      }
      else {
        this.totalAmountGridData = [];
      }
    }),
    (error) => {
      this.isLoading = false;
    };
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(this.transactionType,this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(this.transactionType,this.sortBy.active,
        this.sortBy.direction);
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.totalAllColumns[event.active];
    var column = this.totalAllColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(this.transactionType,this.sortBy.active,
        this.sortBy.direction);
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'month':
        this.sortBy.active = 'month';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {    
  }

}
