import { Environment } from 'src/app/sharedModules/models/environment.model';

export const environment: Environment = {
  production: true,
  API_BASE_URL_CUSTOMER:
    'https://finzeo-v2-customers-backend.azurewebsites.net/api/',
  API_BASE_URL_CREDITDEBIT:
    'https://finzeo-v2-cards-backend.azurewebsites.net/api/',
  API_BASE_URL_IBV: 'https://finzeo-v2-dca-ibv-backend.azurewebsites.net/api/',
  API_BASE_URL_ECHECK:
    'https://finzeo-v2-echeck-backend.azurewebsites.net/api/',
  API_BASE_URL_DASHBOARD:
    'https://finzeo-v2-dashboard-backend.azurewebsites.net/api/',
  API_BASE_URL_INSTANTFUND:
    'https://finzeo-v2-instafund-backend.azurewebsites.net/api/',
  API_AUTHENTICATION_URL:
    'https://finzeo-v2-authentication-backend.azurewebsites.net',
  API_ACH_TRANSACTION_URL:
    'https://finzeo-v2-finzeopayapi-backend.azurewebsites.net',
  API_USER_MANAGEMENT_URL:
    'https://ase-dev-v2-finzeo-eus-be-usermanagement.azurewebsites.net',
  API_REPORT_URL: 'https://finzeo-v2-report-backend.azurewebsites.net/api/',
  API_BASE_URL_PAYMENTWIDGET_CARDS:
    'https://finzeo-v2-cards-backend.azurewebsites.net/api/internal/',
  API_BASE_URL_PAYMENTWIDGET_ACH:
    'https://finzeo-v2-finzeopayapi-backend.azurewebsites.net/api/',
  API_BASE_URL_HOSTED_PAYMENT:
    'https://finzeo-v2-hostedpaymentpage-backend.azurewebsites.net/api/',
  SHAREDKEY: '1203199320052021',
  recaptcha: {
    siteKey: '6LdxuS0lAAAAABaPo2nLC7NGn8H7XA1nD_F68xIY',
  },
  ADMIN_CONTAINER_PATH:
    'https://stdevv2finzeo.blob.core.windows.net:443/adminportal',
    IS_DAILY_RECURRING_ENABLED: true
};
