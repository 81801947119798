import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CreditDebitTransactionService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CREDITDEBIT}Transactions`);
  }

  CreateCardTransaction(model: any): Observable<any> {
    return this._http.post<any>(this._base + '/create', model);
  }

  GetCustomerCardTransactions(
    customerId: string,
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    onlyRecurring: boolean,
    advanceFilters: string
  ): Observable<any> {
    let baseUrl =
      this._base +
      '/getCustomerCardTransactions/' +
      customerId +
      '?customerId=' +
      customerId +
      '&sortDirection=' +
      sortDirection +
      '&page=' +
      page +
      '&pageSize=' +
      pageSize;
    baseUrl = filterBy ? baseUrl + '&filterBy=' + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + '&sortBy=' + sortBy : baseUrl;
    baseUrl = advanceFilters
      ? baseUrl + '&advanceFilters=' + advanceFilters
      : baseUrl;
    baseUrl = onlyRecurring
      ? baseUrl + '&onlyRecurring=' + onlyRecurring
      : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  GetCustomerCardTransactionStats(customerId: string) {
    return this._http.get<any>(
      this._base + '/getCustomerCardTransactionStats/' + customerId
    );
  }

  GetTransactionSummary(
    filterBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    let baseUrl = this._base + '/getTransactionSummary?'
    baseUrl = sortBy !== undefined ? baseUrl + 'sortBy=' + sortBy : baseUrl;
    baseUrl = baseUrl + '&sortDirection=' + sortDirection + '&page=' + page + '&pageSize=' + pageSize;
    baseUrl = filterBy ? baseUrl + '&filterBy=' + filterBy : baseUrl;    
    return this._http.get<any>(baseUrl);
  }

  GetUpcomingHolidays(calendarYear: number) {
    return this._http.post<any>(this._base + '/getUpcomingHolidays', {
      calendarYear: calendarYear,
    });
  }

  DeleteInstallmentTransaction(
    testMode: boolean,
    transactionId: any
  ): Observable<any> {
    return this._http.post<any>(this._base + '/deleteInstallmentTransaction', {
      testMode: testMode,
      transactionId: transactionId,
    });
  }

  LookUpBin(model: any): Observable<any> {
    return this._http.post<any>(this._base + '/InternalLookUpBin', model);
  }
}
