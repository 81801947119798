import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root',
})

export class InvoiceDashboardService extends CrudService<any, any> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}CustomerInvoiceDashboard`);
  }

  getInvoiceTransactionAmount(paymentStatus: string, period: string) {
    return this._http.get<any>(
      this._base + '/getAllInvoiceTransactionAmount?PaymentStatus=' + paymentStatus + '&Period=' + period);
  }

  getCompanyBreakDownStatus(period: string) {
    return this._http.get<any>(this._base + '/getCompanyBreakDownStatus?Period=' + period);
  }

  getInvoiceStats(period: string): Observable<any> {
    return this._http.get<any>(this._base + "/getInvoicesStats?Period=" + period);
  }

  getTotalInvoiceCounts(period: string): Observable<any> {
    return this._http.get<any>(this._base + "/getTotalInvoicesCount?Period=" + period);
  }

  getPaymentMethodPercentage(period: string): Observable<any> {
    return this._http.get<any>(this._base + "/getPaymentMethodPercentage?Period=" + period);
  }

  getAllInvoiceTransactionGrid(customerId: any, sortBy: string, sortDirection: string): Observable<any> {
    let url = this._base + "/getAllInvoiceTransactionGrid?SortBy=" + sortBy + "&SortDirection=" + sortDirection;
    if (customerId != null)
      url += "&customerId=" + customerId;
    return this._http.get<any>(url);
  }

  getInvoiceDetailStats(): Observable<any> {
    return this._http.get<any>(this._base + "/getInvoiceDetailStats");
  }

  getAllViewInvoiceGrid(showRecurring: boolean, filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/getAllViewInvoiceGrid?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
    baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;
    baseUrl = showRecurring ? baseUrl + "&showRecurring=" + showRecurring : baseUrl;
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }
}