<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToDashboardPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Dashboard
    </mat-label>
  </div>

  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">
          {{ transactionHeaderLabel }} Transactions details
        </div>
        <div class="page-sub-header-text-1">
          All transactions, amount, individual, company related data can be seen
          from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
          mat-raised-button
          color="primary"
          class="page-header-button-style"
          (click)="onPrintClicked()"
        >
          PRINT
        </button>
      </div>
    </div>
  </div>
</div>

<div #canvasContainer id="canvasContainer">
  <mat-tab-group
    animationDuration="0ms"
    (selectedTabChange)="selectedTabChangeEvent($event)"
  >
    <mat-tab label="RETURN RATE" *ngIf="transactionType == 'ach'">
      <div #returnRateContainer id="returnRateContainer">
        <app-dashboard-report-return-rate></app-dashboard-report-return-rate>
      </div>
    </mat-tab>
    <mat-tab label="TRANSACTION STATUS">
      <div #transactionStatusContainer id="transactionStatusContainer">
        <app-dashboard-report-transaction-status></app-dashboard-report-transaction-status>
      </div>
    </mat-tab>
    <mat-tab label="TOTAL AMOUNT">
      <div #totalAmountContainer id="totalAmountContainer">
        <app-dashboard-report-total-amount></app-dashboard-report-total-amount>
      </div>
    </mat-tab>
    <mat-tab disabled></mat-tab>
  </mat-tab-group>
</div>
