<app-loading-spinner [isLoading]="isLoading" >
</app-loading-spinner>

<div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="start stretch">
  <app-button-preview [isLoading]="buttonIsLoading" [formData]="formData"></app-button-preview>
  <app-html-code [paymentLink]="paymentLink"></app-html-code>
  <div fxLayout="column" fxLayoutAlign="center end">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="submit-botton"
    >
      <button
        mat-stroked-button
        color="primary"
        class="white-button-style"
        (click)="resetEmailSetupForm()"
        *ngIf="!isLinkGenerated"
      >
        RESET TO DEFAULT
      </button>
      &nbsp;&nbsp;
      <button
        *ngIf="
          this.formData?.designTabForm?.linkType == 'Button' && !isLinkGenerated
        "
        mat-raised-button
        color="primary"
        class="page-header-button-style"
        (click)="GenerateButton()"
      >
        GENERATE BUTTON
      </button>
      <button
        *ngIf="
          this.formData?.designTabForm?.linkType == 'Link' && !isLinkGenerated
        "
        mat-raised-button
        color="primary"
        class="page-header-button-style"
        (click)="GenerateButton()"
      >
        GENERATE LINK
      </button>
      <button
        *ngIf="isLinkGenerated"
        mat-raised-button
        color="primary"
        class="page-header-button-style"
        (click)="navigateToLinkGrid()"
      >
        DONE
      </button>
    </div>
  </div>
</div>
