<mat-spinner *ngIf="isLoading" style="margin:0 auto;top:50%;" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{loadMessage}}
</mat-spinner>

<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
    <mat-label class="total-amount-text">Total Amount</mat-label>
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="6%">
        <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start stretch">
            <mat-label data-html2canvas-ignore="true" class="ach-amount-style">Invoice Transaction Amount </mat-label>
        </div>
    </div>
    <div fxLayout="column" fxFlex="94%">
        <div style="display: block">
            <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="barChartType"
                (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
        </div>
    </div>
</div>