<app-loading-spinner
  [isLoading]="isAchLoading || isCardsLoading || isEcheckLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToBackPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Reports
    </mat-label>
  </div>

  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Generated Transaction Report</div>
        <div class="page-sub-header-text-1">
          Reports for all type of transactions, amount, individual, company
          related data can be generated from this page
        </div>
      </div>
    </div>

    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
          mat-stroked-button
          color="primary"
          class="white-button-style"
          (click)="manageFilters()"
        >
          MANAGE FILTERS
        </button>
        <!-- &nbsp;&nbsp;
        <button mat-stroked-button color="primary" class="white-button-style">
          SEND EMAIL
        </button> -->
        &nbsp;&nbsp;
        <button
          mat-raised-button
          color="primary"
          [disabled]="
            !(achData?.length || cardsData?.length || eCheckData?.length)
          "
          class="page-header-button-style"
          (click)="downloadReport()"
        >
          DOWNLOAD
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="_reportService.generateReportModel?.typeOfService == 'ACH'">
  <app-data-table
    [columns]="achColumns"
    [allColumns]="achAllColumns"
    [data]="achData"
    [isLoading]="isAchLoading"
    [page]="achPage"
    [sortBy]="achSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onAchPageChanged($event)"
    (sortChangedEvent)="onAchSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'ACH Transactions'"
  >
  </app-data-table>
</div>
<div
  *ngIf="_reportService.generateReportModel?.typeOfService == 'CREDITDEBIT'"
>
  <app-data-table
    [columns]="creditDebiColumns"
    [allColumns]="cardsAllColumns"
    [data]="cardsData"
    [isLoading]="isCardsLoading"
    [page]="cardsPage"
    [sortBy]="cardsSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onCardsPageChanged($event)"
    (sortChangedEvent)="onCardsSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'Credit/Debit Transactions'"
  >
  </app-data-table>
</div>
<div *ngIf="_reportService.generateReportModel?.typeOfService == 'ECHECK'">
  <app-data-table
    [columns]="eCheckColumns"
    [allColumns]="eCheckAllColumns"
    [data]="eCheckData"
    [isLoading]="isEcheckLoading"
    [page]="eCheckPage"
    [sortBy]="eCheckSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onEcheckPageChanged($event)"
    (sortChangedEvent)="onEcheckSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'E-Check Transactions'"
  >
  </app-data-table>
</div>

<div *ngIf="_reportService.generateReportModel?.typeOfService == 'All'">
  <app-data-table
    [columns]="achColumns"
    [allColumns]="achAllColumns"
    [data]="achData"
    [isLoading]="isAchLoading"
    [page]="achPage"
    [sortBy]="achSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onAchPageChanged($event)"
    (sortChangedEvent)="onAchSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'ACH Transactions'"
  >
  </app-data-table>
  <app-data-table
    [columns]="creditDebiColumns"
    [allColumns]="cardsAllColumns"
    [data]="cardsData"
    [isLoading]="isCardsLoading"
    [page]="cardsPage"
    [sortBy]="cardsSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onCardsPageChanged($event)"
    (sortChangedEvent)="onCardsSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'Credit/Debit Transactions'"
  >
  </app-data-table>
  <app-data-table
    [columns]="eCheckColumns"
    [allColumns]="eCheckAllColumns"
    [data]="eCheckData"
    [isLoading]="isEcheckLoading"
    [page]="eCheckPage"
    [sortBy]="eCheckSortBy"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="true"
    [isSearch]="false"
    (pageChangedEvent)="onEcheckPageChanged($event)"
    (sortChangedEvent)="onEcheckSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="'E-Check Transactions'"
  >
  </app-data-table>
</div>
