import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GradeScoreChartModel } from './models/grade-score-chart.model';

@Component({
  selector: 'grade-score-gauge-chart',
  templateUrl: './grade-score-chart.component.html',
  styleUrls: ['./grade-score-chart.component.scss'],
})
export class GradeScoreChartComponent implements OnInit {
  
  public get needleValue(): number {
    return this.model.score / 10;
  }

  public get bottomLabel(): string {
    return `${this.model.score}(${this.model.grade})`;
  }
  

  @Input() public canvasWidth = 350;
  @Input() public centralLabel = '';
  @Input() public name = '';
  @Input() public options: any;

  private _model: GradeScoreChartModel;
  public get bottomItems(): string {
    return this._model.legends.map(l => l.color).join(',');
  }

  public get rangeOutlines(): string {
    return this._model.legends.map(l => l.color).join(',');
  }
  
  @Input()
  public get model(): GradeScoreChartModel {
    return this._model;
  }
  public set model(value: GradeScoreChartModel) {
    this._model = value;
  }

  constructor() { }

  ngOnInit(): void {
    if (!this.model) {
      this.model = GradeScoreChartModel.example(0, 'F');
    }
    this.draw();
  }
  
  draw() {
    if (!this.model) {
      return;
    }
    const arcDelimiters = this.model.legends.map(l => (l.toScore  * 100 / this.model.maxValue).toFixed(1)).reverse().slice(0, 4);
    const arcLabels  = this.model.legends.map(l => l.toScore).reverse().slice(0, 4);
    const arcColors  = this.model.legends.map(l => l.color).reverse();
    this.options = {
      hasNeedle: true,
      needleColor: 'black',
      needleUpdateSpeed: this.model.maxValue,
      arcColors: arcColors,
      arcDelimiters: arcDelimiters,
      rangeLabel: ['0', this.model.maxValue.toString()],
      arcLabels: arcLabels,
    }
  }
}