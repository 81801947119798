<mat-spinner *ngIf="isLoading" style="margin:0 auto;top:25%;" mode="indeterminate" [diameter]="50" [strokeWidth]="3">
    {{loadMessage}}
</mat-spinner>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <span class="paymentMethodLbl">Payment Method</span>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <span class="methodTextLbl">ACH</span>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" style="margin-left: 1em;">
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="achLastToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalLastACH}}%</span>
                <span class="amountLblCls">{{periodLastLabel}}</span>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex="5%"></div>
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="achCurrentToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalCurrentACH}}%</span>
                <span class="amountLblCls">{{periodCurrentLabel}}</span>
            </div>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <span class="methodTextLbl">E-Check</span>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" style="margin-left: 1em;">
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="echeckLastToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalLastEcheck}}%</span>
                <span class="amountLblCls">{{periodLastLabel}}</span>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex="5%"></div>
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="echeckCurrentToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalCurrentEcheck}}%</span>
                <span class="amountLblCls">{{periodCurrentLabel}}</span>
            </div>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <span class="methodTextLbl">Credit/Debit</span>
    </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" style="margin-left: 1em;margin-bottom: 2em;">
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="cardsLastToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalLastCards}}%</span>
                <span class="amountLblCls">{{periodLastLabel}}</span>
            </div>
        </div>
    </div>
    <div fxLayout="column" fxFlex="5%"></div>
    <div fxLayout="column" fxFlex="45%">
        <div fxLayout="row" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="35%">
                <img [matTooltip]="cardsCurrentToolTip" class="summaryIcons" src="assets/grid-icons/recurring.png" />
            </div>
            <div fxLayout="column" fxFlex="65%" class="mt7px">
                <span class="amountCls">{{totalCurrentCards}}%</span>
                <span class="amountLblCls">{{periodCurrentLabel}}</span>
            </div>
        </div>
    </div>
</div>
