import { maxLength, maxNumber, minNumber, pattern, prop, propObject, required } from "@rxweb/reactive-form-validators";

export class ClientPortalSettings {
    logoUrl: string;
    Logotext: string;
    themecolor: string;
    themenaccolor: string;    
}

export class DisplayWhiteLabelClientWiseSettings {
    @propObject(ClientPortalSettings)
    clientPortalSettings: ClientPortalSettings;    
}

export class DisplayWhiteLabellingClientSettings {
    @propObject(DisplayWhiteLabelClientWiseSettings)
    displayWhiteLabelClientWiseSettings: DisplayWhiteLabelClientWiseSettings;
}



