import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceStatsModel } from 'src/app/featureModules/models/invoice-stats.model';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';

@Component({
  selector: 'app-invoice-detail-stats',
  templateUrl: './invoice-detail-stats.component.html',
  styleUrls: ['./invoice-detail-stats.component.scss']
})
export class InvoiceDetailStatsComponent implements OnInit {

  isLoading: boolean = false;
  loadMessage: string = 'Loading ..';

  customerId: string;
  invoiceStatsModel: InvoiceStatsModel;

  constructor(private _toastrService: ToastrService,    
    private _invoiceDashboardService: InvoiceDashboardService) { }

  ngOnInit(): void {   
    this.invoiceStatsModel = new InvoiceStatsModel();
    this.getAllInvoiceStats();
  }

  getAllInvoiceStats() {
    this.isLoading = true;
    this._invoiceDashboardService.getInvoiceDetailStats()
      .subscribe(
        (data) => {
          this.isLoading = false;
          if(data.data.invoiceRecords){
            this.initializeInvoiceStats(data.data.invoiceRecords);
          } else {
            this._toastrService.error('Something went wrong, Please contact administrator!');
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }

  initializeInvoiceStats(response: any){
    this.invoiceStatsModel.totalInvoices = response.totalInvoices;
    this.invoiceStatsModel.totalAmount = response.totalAmount;
    this.invoiceStatsModel.paidStatus = response.paidStatus;
    this.invoiceStatsModel.unpaidStatus = response.unpaidStatus;
    this.invoiceStatsModel.sentStatus = response.sentStatus;
    this.invoiceStatsModel.overdueStatus = response.overdueStatus ?? 0;
  }

}
