<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<mat-card>
  <form
    [formGroup]="customerDetailsFormGroup"
    fxLayout="column"
    fxLayoutGap="4px"
    fxFlex="100"
  >
    <div *ngIf="isViewCustomerDetails">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-label class="section-header">Basic Details</mat-label>
      </div>
      <mat-divider></mat-divider>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('type')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Type: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{
              customerDetailsFormGroup.controls["type"].value == true
                ? "Company"
                : "Individual"
            }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('mobileNumber')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Phone: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            <ngx-intl-tel-input
              class="showMobileFileds"
              [value]="customerModel?.mobileNumber"
              name="mobileNumber"
              formControlName="mobileNumber"
            >
            </ngx-intl-tel-input>
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('address1')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Address: </mat-label>
        </div>
        <div fxFlex="70%" style="margin-top: 10px">
          <mat-label class="showDetailsValue format-address">
            {{ customerDetailsFormGroup.controls["address1"].value }}
            <span *ngIf="customerModel.address2"
              >, {{ customerDetailsFormGroup.controls["address2"].value }}</span
            >
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('city')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> City: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ customerDetailsFormGroup.controls["city"].value }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('state')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> State: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ customerDetailsFormGroup.controls["state"].value }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('zip')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName">Postal Code: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{ customerDetailsFormGroup.controls["zip"].value }}
          </mat-label>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start center"
        fxLayoutWrap
        *ngIf="customerModel?.ipAddressList?.length"        
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Whitelisted IPs: </mat-label>
        </div>
        <div fxFlex="70%" style="margin: 10px 0px 10px 0px;">          
          <mat-chip-list>
            <mat-chip  class="custom-chip-style" *ngFor="let ip of ipAddressList; index as i">
              <mat-label > {{ ip }} </mat-label>            
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <mat-divider></mat-divider>
      </div>

      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        *ngIf="isCustomerDetailsPresent('entryDate')"
      >
        <div fxFlex="20%">
          <mat-label class="showDetailsName"> Created On: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showDetailsValue">
            {{
              customerDetailsFormGroup.controls["entryDate"].value
                | date : "MMMM d, y, hh:mm a"
            }}
          </mat-label>
        </div>
      </div>
    </div>
  </form>
</mat-card>

<mat-card *ngIf="isDataManagement && userRole != 1">
  <form
    [formGroup]="customerDetailsFormGroup"
    fxLayout="column"
    fxLayoutGap="4px"
    fxFlex="100"
  >
    <div *ngIf="isViewCustomerDetails">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="4px">
        <mat-label class="section-header">Account Management</mat-label>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      style="padding: 2% 0% 2% 0%"
    >
      <mat-label style="padding: 3px; color: #617187; font-size: 14px"
        >Status:</mat-label
      >
      <mat-slide-toggle
        formControlName="isActive"
        name="isActive"
        class="form-control"
        (click)="onActivateDeactivateClick()"
      >
        <mat-label
          class="showDetailsValue"
          [ngStyle]="{
            ' color': customerDetailsFormGroup.controls['isActive'].value
              ? 'green'
              : 'red'
          }"
        >
          {{
            customerDetailsFormGroup.controls["isActive"].value
              ? "Active"
              : "Inactive"
          }}
        </mat-label>
      </mat-slide-toggle>
    </div>

    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
      <mat-label class="deleteNote">
        This customer's account status will change if s/he requests that, please
        be aware that inactivated account cannot initiate new transactions.
      </mat-label>
    </div>
  </form>
</mat-card>
