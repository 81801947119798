import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HostedPaymentService } from 'src/app/featureModules/services/hosted-payment.service';

@Component({
  selector: 'app-payment-link-view-details',
  templateUrl: './payment-link-view-details.component.html',
  styleUrls: ['./payment-link-view-details.component.scss'],
})
export class PaymentLinkViewDetailsComponent implements OnInit, OnChanges {
  @Input()
  linkId: any;

  isLoading: boolean = false;
  formData: any;
  entryDate: any;
  status: any;
  expiration: string;
  paymentLink: string;
  linkExpiryDateAndTime: Date;

  @Output()
  drawerCloseEvent = new EventEmitter();

  constructor(
    private _toastrService: ToastrService,
    private _hostedPaymentService: HostedPaymentService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.paymentLinkView();
  }

  ngOnInit(): void {

  }

  drawerClose() {
    this.drawerCloseEvent.emit();
  }

  copyHtmlCode() {
    this._toastrService.success(
      'HTML Code Link Copied successfully!'
    );
  }

  public paymentLinkView() {
    this.isLoading = true;
    this._hostedPaymentService.getHostedPaymentLinkById(this.linkId).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null && data.data.linkDetails != null) {
          this.formData = JSON.parse(data.data.linkDetails);
          this.entryDate = data.data.entryDate;
          //Setting hours and minutes  
          this.linkExpiryDateAndTime = null;
          if (this.formData.settingTabForm.specificTime) {
            let hoursAndMinutes = this.formData.settingTabForm.specificTime.split(':');
            let hours = +hoursAndMinutes[0];
            let minutes = +hoursAndMinutes[1];
            let seconds = +hoursAndMinutes[2] ? +hoursAndMinutes[2] : 0;
            this.linkExpiryDateAndTime = new Date(this.entryDate);
            this.linkExpiryDateAndTime.setHours(this.linkExpiryDateAndTime.getHours() + hours);
            this.linkExpiryDateAndTime.setMinutes(this.linkExpiryDateAndTime.getMinutes() + minutes);
            this.linkExpiryDateAndTime.setSeconds(this.linkExpiryDateAndTime.getSeconds() + seconds);
          }
          this.status = data.data.isActive;
          this.paymentLink = data.data.paymentLinkHtmlCode;
          this.expiration = this.formData.settingTabForm.dateExpiring;
        }
      },
      (error) => {
        this.isLoading = false;
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  paymentLinkClick(paymentLink: string) {
    window.open(paymentLink, '_blank');
  }
}
