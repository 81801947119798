<mat-card class="bank-quick-view-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="bank-quick-view-card" fxLayout="column" fxFlex="100%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      style="padding-bottom: 2%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/bankQuickView.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap>
        <span class="section-header">Bank Quick View</span>
      </div>
    </div>

    <div
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="start stretch"
      fxLayoutWrap
      style="overflow-y: auto"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        class="box-shadow"
        style="margin: 1%"
        fxLayoutWrap
      >
        <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
          <div
            fxLayout="row"
            fxLayoutAlign="start space-between"
            fxLayoutGap="10px"
            class="balance-row"
            style="text-align: left"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="25%"
              class="text-labels bold-style"
            >
              AVAILABLE BALANCE:
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="25%"
              class="text-labels bold-style"
            >
              CURRENT BALANCE:
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="25%"
              class="text-labels bold-style"
            >
              AVG. BALANCE:
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="25%"
              class="text-labels bold-style"
            >
              LOAN PAYMENTS:
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="start space-between"
            style="text-align: left"
            class="balance-row-white"
            fxLayoutGap="10px"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxFlex="25%"
              class="text-labels normal-style"
              fxLayoutAlign="center start"
            >
              ${{ balanceDetails?.availableBalance ?? 0 | number : "1.2-2" }}
            </div>
            <div
              fxLayout="column"
              fxFlex="25%"
              class="text-labels normal-style"
              fxLayoutAlign="center start"
            >
              ${{ balanceDetails?.currentBalance ?? 0 | number : "1.2-2" }}
            </div>
            <div
              fxLayout="column"
              fxFlex="25%"
              class="text-labels normal-style"
              fxLayoutAlign="center start"
            >
              ${{ balanceDetails?.averageDailyBalance ?? 0 | number : "1.2-2" }}
            </div>
            <div
              fxLayout="column"
              fxFlex="25%"
              class="text-labels normal-style"
              fxLayoutAlign="center start"
            >
              {{ balanceDetails?.loanPayments ?? 0 }}
            </div>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="start stretch"
        style="padding-top: 2%; margin: 1%"
        fxLayoutGap="16px"
        fxLayoutWrap
      >
        <div
          fxLayout="column"
          fxFlex="50%"
          class="balance-row-white box-shadow"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" fxFlex="10%" class="text-labels bold-style">
              Credits
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              class="text-labels normal-style alignment"
            >
              {{ balanceDetails?.totalNumberOfCredits ?? 0 }}
            </div>
            <div
              fxLayout="column"
              fxFlex="30%"
              class="text-labels normal-style alignment"
            >
              ${{ balanceDetails?.totalCreditsAmount ?? 0 | number : "1.2-2" }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" fxFlex="10%" class="text-labels bold-style">
              Debits
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              class="text-labels normal-style alignment"
            >
              {{ balanceDetails?.totalNumberOfDebits ?? 0 }}
            </div>
            <div
              fxLayout="column"
              fxFlex="30%"
              class="text-labels normal-style alignment"
            >
              ${{ balanceDetails?.totalDebitsAmount ?? 0 | number : "1.2-2" }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" fxFlex="10%" class="text-labels bold-style">
              Recurring Debits
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              class="text-labels normal-style alignment"
            >
              {{ balanceDetails?.totalNumberOfMonthlyDebits ?? 0 }}
            </div>
            <div
              fxLayout="column"
              fxFlex="30%"
              class="text-labels normal-style alignment"
            >
              ${{
                balanceDetails?.totalMonthlyDebitsAmount ?? 0 | number : "1.2-2"
              }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" fxFlex="10%" class="text-labels bold-style">
              NSF
            </div>
            <div
              fxLayout="column"
              fxFlex="10%"
              class="text-labels normal-style alignment"
            >
              {{ balanceDetails?.totalNumberOfNsf ?? 0 }}
            </div>
            <div
              fxLayout="column"
              fxFlex="30%"
              class="text-labels normal-style alignment"
            >
              ${{ balanceDetails?.totalNsfAmount ?? 0 | number : "1.2-2" }}
            </div>
          </div>
        </div>

        <div
          fxLayout="column"
          fxFlex="50%"
          class="balance-row-white box-shadow"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" class="text-labels bold-style">
              Avg. Credits
            </div>
            <div fxLayout="column" class="text-labels normal-style">
              ${{ balanceDetails?.averageCredits ?? 0 | number : "1.2-2" }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" class="text-labels bold-style">
              Avg. Debits
            </div>
            <div fxLayout="column" class="text-labels normal-style">
              ${{ balanceDetails?.averageDebits ?? 0 | number : "1.2-2" }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" class="text-labels bold-style">
              Avg. Recurring Debits
            </div>
            <div fxLayout="column" class="text-labels normal-style">
              ${{
                balanceDetails?.averageMonthlyDebits ?? 0 | number : "1.2-2"
              }}
            </div>
          </div>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            fxLayoutGap="10px"
            style="padding: 3% 0%"
          >
            <div fxLayout="column" class="text-labels bold-style">Avg. NSF</div>
            <div fxLayout="column" class="text-labels normal-style">
              ${{ balanceDetails?.averageNsfs ?? 0 | number : "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
