import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Component({
  selector: 'app-report-transaction-amount-chart',
  templateUrl: './report-transaction-amount-chart.component.html',
  styleUrls: ['./report-transaction-amount-chart.component.scss'],
})
export class ReportTransactionAmountChartComponent
  implements OnInit, OnChanges
{
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';

  @Input()
  searchFields: any;

  @Input()
  preHeadingLabel: string;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor(
    private reportsService: ReportsService,
    private _toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.GetTransactionTotalAmount();
  }

  GetTransactionTotalAmount() {
    this.isLoading = true;

    this.searchFields.startDate = new Date(this.searchFields.startDate);
    this.searchFields.endDate = new Date(this.searchFields.endDate);
    this.searchFields.startDate?.setHours(12, 0, 0);
    this.searchFields.endDate?.setHours(12, 0, 0);

    let pendingList = [];
    let returnedList = [];
    let successList = [];
    let recurringList = [];
    let failedList = [];
    let cancelledList = [];

    this.reportsService
      .GetTransactionTotalAmount(
        this.searchFields.customerId,
        this.searchFields.typeOfService,
        this.searchFields.processor,
        getSafeIsoDateString(this.searchFields.startDate),
        getSafeIsoDateString(this.searchFields.endDate)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.barChartYearlyLabels.forEach((month) => {

              // setting pending line chart value
              let pendingMonth = data.data.pendingAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (pendingMonth?.length) {
                pendingList.push(pendingMonth[0].monthlyAmount);
              } else {
                pendingList.push(0);
              }

              // setting returned line chart value
              let returnedMonth = data.data.returnedAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (returnedMonth?.length) {
                returnedList.push(returnedMonth[0].monthlyAmount);
              } else {
                returnedList.push(0);
              }

              // setting pending line chart value
              let successMonth = data.data.successAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (successMonth?.length) {
                successList.push(successMonth[0].monthlyAmount);
              } else {
                successList.push(0);
              }

              // setting recurring line chart value
              let recurringMonth = data.data.recurringAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (recurringMonth?.length) {
                recurringList.push(recurringMonth[0].monthlyAmount);
              } else {
                recurringList.push(0);
              }

              // setting failed line chart value
              let failedMonth = data.data.failedAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (failedMonth?.length) {
                failedList.push(failedMonth[0].monthlyAmount);
              } else {
                failedList.push(0);
              }

              // setting cancelled line chart value
              let cancelledMonth = data.data.cancelledAmountList?.filter(
                (obj: any) =>
                  obj.month.toLowerCase().includes(month.toLowerCase())
              );
              if (cancelledMonth?.length) {
                cancelledList.push(cancelledMonth[0].monthlyAmount);
              } else {
                cancelledList.push(0);
              }
            });
          }
          if(this.searchFields.typeOfService == 'ACH'){
            this.barChartData.datasets[0].data = pendingList;
            this.barChartData.datasets[1].data = returnedList;
            this.barChartData.datasets[2].data = successList;
            this.barChartData.datasets[3].data = recurringList;
            this.barChartData.datasets[4].data = failedList;
            this.barChartData.datasets[5].data = cancelledList;
            this.refresh_chart();
          }          
          //for card transaction data fill
          else{                      
            this.cardBarChartData.datasets[0].data = pendingList;
            this.cardBarChartData.datasets[1].data = returnedList;
            this.cardBarChartData.datasets[2].data = successList;
            this.cardBarChartData.datasets[3].data = recurringList;
            this.cardBarChartData.datasets[4].data = failedList;
            this.refresh_card_chart();
          }                                        
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  public barChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {},
    },
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        ticks: {
          // Include a percentrage sign in the ticks
          callback: function (value, index, ticks) {
            return '$' + value;
          },
        },
      },
    },
    plugins: {
      legend: { display: true, position: 'bottom', maxHeight: 45 },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return '$' + (+tooltipItem.formattedValue ? 
              (+tooltipItem.formattedValue)?.toFixed(2) : 
                (+tooltipItem.formattedValue == 0 ? (+tooltipItem.formattedValue).toFixed(2) : tooltipItem.formattedValue));
          },
        },
      },
    },
  };

  public barChartYearlyLabels: string[] = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];

  public barChartType: ChartType = 'line';

  public barChartData: ChartData<'line'> = {
    labels: this.barChartYearlyLabels,
    datasets: [
      {
        data: [],
        label: 'Pending',
        backgroundColor: '#FB8C00',
        borderColor: '#FB8C00',
      },
      {
        data: [],
        label: 'Returned',
        backgroundColor: '#FF5252',
        borderColor: '#FF5252',
      },
      {
        data: [],
        label: 'Success',
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
      },
      {
        data: [],
        label: 'Recurring',
        backgroundColor: '#1E4ED8',
        borderColor: '#1E4ED8',
      },
      {
        data: [],
        label: 'Failed',
        backgroundColor: '#e51717',
        borderColor: '#e51717',
      },
      {
        data: [],
        label: 'Cancelled',
        backgroundColor: '#525252',
        borderColor: '#525252',
      },
    ],
  };

  public cardBarChartData: ChartData<'line'> = {
    labels: this.barChartYearlyLabels,
    datasets: [
      {
        data: [],
        label: 'Pending',
        backgroundColor: '#FB8C00',
        borderColor: '#FB8C00',
      },
      {
        data: [],
        label: 'Returned',
        backgroundColor: '#FF5252',
        borderColor: '#FF5252',
      },
      {
        data: [],
        label: 'Success',
        backgroundColor: '#4CAF50',
        borderColor: '#4CAF50',
      },
      {
        data: [],
        label: 'Recurring',
        backgroundColor: '#1E4ED8',
        borderColor: '#1E4ED8',
      },
      {
        data: [],
        label: 'Failed',
        backgroundColor: '#e51717',
        borderColor: '#e51717',
      },
    ],
  };

  refresh_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = this.barChartData.labels;
        this.chart.chart.config.data.datasets = this.barChartData.datasets;     
        this.chart.chart.update();
      }
    });
  }

  refresh_card_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {              
        this.chart.chart.config.data.labels = this.cardBarChartData.labels;
        this.chart.chart.config.data.datasets = this.cardBarChartData.datasets;        
        this.chart.chart.update();
      }
    });
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}

  public selectChartType(type: any): void {
    this.barChartType = type;
  }
}
