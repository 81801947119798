import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskPipe'
})
export class MaskPipePipe implements PipeTransform {

  transform(str: string, visibleDigits: number): string {    
    let maskedNumbers = str.slice(0, -visibleDigits);
    let visibleNumbers = str.slice(-visibleDigits);
    return maskedNumbers.replace(/./g, '*') + visibleNumbers;
  }

}
