<app-loading-spinner
  [isLoading]="isDownloadLoading"
  [loadMessage]="loadMessage"
>
</app-loading-spinner>

<div class="page-header-bottom">
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Reports</div>
        <div class="page-sub-header-text-1">
          Reports for all type of transactions, amount, individual, company
          related data can be generated from this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button
        mat-stroked-button
        color="primary"
        class="white-button-style"
        (click)="originationReport()"
        >
        ORIGINATION REPORT
        </button>
        &nbsp;&nbsp;&nbsp;
        <button
          mat-stroked-button
          color="primary"
          class="white-button-style"
          (click)="generateReport()"
        >
          TRANSACTION REPORT
        </button>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div class="report-search-form">
    <form [formGroup]="reportSearchFormGroup">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxFlex="33%">
          <mat-form-field appearance="outline">
            <mat-label>Effective Date Range</mat-label>
            <mat-date-range-input
              [rangePicker]="dateRangePicker"
              [min]="minDate"
              [max]="maxDate"
            >
              <input
                matStartDate
                placeholder="Start date"
                formControlName="startDate"
                name="startDate"
                readonly
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="endDate"
                name="endDate"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="dateRangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
          </mat-form-field>
          <mat-error>
            {{ reportSearchFormGroup.controls["startDate"]["_errorMessage"] }}
          </mat-error> 
        </div>         
        <div fxLayout="column" fxFlex="33%" *ngIf="isMoreFilters">
          <mat-form-field appearance="outline">
            <mat-label class="required">Type Of Service</mat-label>
            <mat-select
              class="form-control"
              formControlName="typeOfService"
              name="typeOfService"
            >
              <mat-option *ngFor="let type of typeList" [value]="type.key"
                >{{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div fxLayout="column" fxFlex="25%" *ngIf="isMoreFilters">
          <mat-form-field appearance="outline">
            <mat-label class="required">Processor</mat-label>
            <mat-select
              class="form-control"
              formControlName="processor"
              name="processor"
            >
              <mat-option
                *ngFor="let processor of processorList"
                [value]="processor.key"
                >{{ processor.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div fxLayout="column" fxFlex="33%" *ngIf="isMoreFilters">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="customerName"
              [matAutocomplete]="auto"
              placeholder="Customer Name (min 3 characters)"
              pattern="^[a-zA-Z ]*$"
              style="padding-bottom: 1%"
            />
            <button
              *ngIf="this.reportSearchFormGroup.get('customerName').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSelection()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <button matPrefix mat-icon-button>
              <mat-icon>search</mat-icon>
            </button>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onSelected()"
            >
              <mat-option *ngIf="isLoading" class="is-loading"
                >Loading...</mat-option
              >
              <ng-container *ngIf="!isLoading && filteredCustomer.length > 0">
                <mat-option
                  *ngFor="let customer of filteredCustomer"
                  [value]="selectedCustomerName(customer)"
                >
                  <div class="name-email-style">
                    {{ customer.firstName?.trim() }}&nbsp;{{
                      customer.lastName?.trim()
                    }}
                  </div>
                  <div class="name-email-style sub-text">
                    {{ customer.email }}
                  </div>
                </mat-option>
              </ng-container>
              <ng-container
                *ngIf="isShowNoFound && !isLoading && !filteredCustomer.length"
              >
                <mat-option>
                  <div class="name-email-style">
                    <b>No Records Found</b>
                  </div>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div
          fxLayout="column"
          fxFlex="75%"
          *ngIf="!isMoreFilters"
          style="padding-top: 3%"
        >
          <div fxLayout="row" fxLayoutAlign="end end">                                 
            <button
              [disabled]="!reportSearchFormGroup.valid"
              mat-raised-button
              color="primary"
              class="page-header-button-style"
              (click)="searchReport()"
            >
              SEARCH
            </button>
            &nbsp;&nbsp;
            <button
              mat-stroked-button
              color="primary"
              class="white-button-style"
              (click)="moreFilters()"
              *ngIf="!isMoreFilters"
            >
              MORE FILTERS              
            </button> 
            &nbsp;&nbsp;           
            <button
              *ngIf="showReset()"
              mat-stroked-button
              color="primary"
              class="white-button-style"
              (click)="resetForm()"
            >
              RESET
            </button>
            &nbsp;&nbsp;
            <button
              mat-stroked-button
              color="primary"
              class="white-button-style"
              (click)="printReport()"
                >
                PRINT
            </button>
          </div>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        *ngIf="isMoreFilters"
      >
        <span class="spacer"></span>
        <div fxLayoutAlign="end end">
          <button
            mat-stroked-button
            color="primary"
            class="white-button-style"
            (click)="moreFilters()"
            *ngIf="isMoreFilters"
          >
            HIDE FILTERS
          </button>
          &nbsp;&nbsp;
          <button
            *ngIf="showReset()"
            mat-stroked-button
            color="primary"
            class="white-button-style"
            (click)="resetForm()"
          >
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            [disabled]="!reportSearchFormGroup.valid"
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="searchReport()"
          >
            SEARCH
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div #canvasContainer_Page1 id="canvasContainer_Page1">
  <app-reports-stats
    [transactionGrid]="transactionGrid"
    [searchFields]="reportSearchModel"
  ></app-reports-stats>

  <div class="reports-container">
    <div
      *ngIf="!transactionGrid"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
    >
      <div
        fxLayout="column"
        class="totalTransactionsDiv"
        fxLayoutGap="5px"
        fxFlex="70%"
      >
        <app-report-amount-transaction
          [searchFields]="reportSearchModel"
        ></app-report-amount-transaction>
      </div>
      <div
        fxLayout="column"
        class="recurringTransactionsDiv"
        fxLayoutGap="5px"
        fxFlex="30%"
      >
        <app-reports-recurring
          [searchFields]="reportSearchModel"
        ></app-reports-recurring>
      </div>
    </div>

    <div
      *ngIf="transactionGrid"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
    >
      <div style="width: 100%">
        <app-report-transaction-amount-chart
          [searchFields]="reportSearchModel"
          [preHeadingLabel]="preHeadingLabel"
        >
        </app-report-transaction-amount-chart>
      </div>
    </div>
  </div>
</div>

<div #canvasContainer_Page2 id="canvasContainer_Page2">
  <div
    *ngIf="!transactionGrid"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    class="reports-container"
  >
    <div style="width: 100%">
      <app-report-total-transaction
        [searchFields]="reportSearchModel"
      ></app-report-total-transaction>
    </div>
  </div>

  <app-data-table
    *ngIf="transactionGrid && (this.reportSearchModel.typeOfService =='ACH' || this.reportSearchModel.typeOfService =='ECHECK')"
    [columns]="columns"
    [data]="data"
    [isLoading]="isLoading"
    [page]="page"
    [sortBy]="sortBy"
    [allColumns]="allColumns"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="false"
    [showPaginator]="false"
    [isSearch]="false"
    (pageChangedEvent)="onPageChanged($event)"
    (sortChangedEvent)="onSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="preHeadingLabel + ' Transaction Amount'"
  >
  </app-data-table>

  <app-data-table
    *ngIf="transactionGrid && this.reportSearchModel.typeOfService =='CREDITDEBIT'"
    [columns]="cardColumns"
    [data]="data"
    [isLoading]="isLoading"
    [page]="page"
    [sortBy]="sortBy"
    [allColumns]="allCardColumns"
    [isExport]="false"
    [isFilter]="false"
    [showMoreMenu]="false"
    [showPaginator]="false"
    [isSearch]="false"
    (pageChangedEvent)="onPageChanged($event)"
    (sortChangedEvent)="onCardSortChanged($event)"
    [isTableHeading]="true"
    [isTableHeadingText]="preHeadingLabel + ' Transaction Amount'"
  >
  </app-data-table>
</div>
