<mat-card class="potential-loans-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card
    class="potential-loans-card"
    fxLayout="column"
    fxFlex="100%"
  >
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin-bottom: 1%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/potential_loans.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap fxLayoutAlign="start start">
        <span class="section-header">Potential Loans</span>
      </div>
    </div>

    <br />

    <div
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="start stretch"
      fxLayoutWrap
      style="overflow-y: auto"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        style="margin: 1%"
        fxLayoutWrap
      >
        <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
          <div
            fxLayout="row"
            fxLayoutAlign="start space-between"
            fxLayoutGap="2%"
            style="padding: 2%"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="35%"
              class="loan-header-labels"
            >
              LENDER
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              fxFlex="32.5%"
              class="loan-header-labels"
            >
              LOAN AMOUNT
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              fxFlex="32.5%"
              class="loan-header-labels"
            >
              PAYMENT AMOUNT
            </div>
          </div>
          <mat-divider></mat-divider>
          <div *ngIf="isPotentialLoansListPresent">
            <div
              fxLayout="row"
              fxLayoutAlign="start stretch"
              style="padding: 2%"
              fxLayoutGap="1%"
              fxLayoutWrap
              *ngFor="
                let lender of loanDetails?.potentialLoansList;
                let i = index
              "
            >
              <div
                fxLayout="column"
                fxFlex="36%"
                class="text-labels normal-style"
                fxLayoutAlign="center start"
              >
                {{ lender ?? na }}
              </div>
              <div
                fxLayout="column"
                fxFlex="32.5%"
                class="text-labels normal-style"
                fxLayoutAlign="center end"
              >
                {{ loanDetails?.potentialLoansAmountList[i] ?? 0 }}
              </div>
              <div
                fxLayout="column"
                fxFlex="32.5%"
                class="text-labels normal-style"
                fxLayoutAlign="center end"
              >
                {{ loanDetails?.potentialLoansPaidAmountList[i] ?? 0 }}
              </div>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="start stretch"
            style="padding: 2%"
            fxLayoutWrap
            *ngIf="!isPotentialLoansListPresent"
          >
            <div
              fxLayout="column"
              fxFlex="100%"
              class="text-labels normal-style"
              fxLayoutAlign="center center"
            >
              No data available
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
