import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ChartData, ChartEvent, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';

@Component({
  selector: 'app-dashboard-pie-chart',
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrls: ['./dashboard-pie-chart.component.scss']
})
export class DashboardPieChartComponent implements OnInit, OnChanges {

  @Input()
  period: string;
  
  @Input()
  doughnutChartData: any;

  @Input()
  doughnutChartType: any;

  @Input()
  doughnutChartOptions: any;

  @Input()
  name: any;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.refresh_chart();
  }

  refresh_chart() {
    setTimeout(() => {
      if (this.chart && this.chart.chart && this.chart.chart.config) {
        this.chart.chart.config.data.labels = this.doughnutChartData.labels;
        this.chart.chart.config.data.datasets = this.doughnutChartData.datasets;
        this.chart.chart.update();
      }
    });
  }

  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    // console.log(event, active);
  }

}
