import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-hosted-payment-page-success',
  templateUrl: './hosted-payment-page-success.component.html',
  styleUrls: ['./hosted-payment-page-success.component.scss']
})
export class HostedPaymentPageSuccessComponent implements OnInit {

  @Input()
  changesIsLoading: boolean;
  
  @Input()
  formData: any;
  invoiceNumber: any;
  paymentMethod: any;
  amount: number;
  totalAmount: number;
  transactionId: any;
  transactionDate: any;
  agreesToSurcharge: boolean = false;
  surchargeFee: any;
  surchargePercentage: any;
  description: any;
  agreesToConvenienceFees: boolean = false;
  convenienceFees: any;
  availConvenienceFee = true;

  isPaymentSuccessful: boolean = false;
  paymentMessage: any;
  paymentIcon: any;
  paymentClass: any;

  companyLogo: string;
  paymentHeaderBGColor: string;
  primaryButtonBGColor: string;
  labelColor: string;
  stateData: any;
  token: string;
  logoBlobPath: string;
  logotext: string;

  @Input()
  isSmallView: boolean;

  @Input()
  deviceType: string;

  previewWiseStyle = {
    divPreviewHeight: "",
    headerLableFontSize: "",
    orderLableFontSize: "",
    orderValueFontSize: "",
    buttonTextFontSize: "",
    logoImageHeight: "",
    headerToolbarHeight: "",
    footerDivHeight: "",
    successImageSize: "",
    inputControlClass: ""
  }

  deviceWiseStyle = {
    headerToolbarClass: "",
    orderSuccessContainerClass: "",
    circleDrawLeftClass: "",
    circleDrawRightClass: "",
    paymentSuccessLogoClass: "",
    circleDrawClass: ""
  }

  constructor() { }

  ngOnInit(): void {

    this.formData;

    this.companyLogo = "/assets/logo.png";
    this.invoiceNumber = "MER-898u96-yuyt";
    this.amount = 231.88;
    this.description = "this is related to payment";
    this.paymentMethod = "Card";
    this.totalAmount = 240;
    this.transactionId = "FGGHF78657";
    this.transactionDate = new Date();
    this.agreesToSurcharge = true;
    this.surchargeFee = 8.12;
    this.isPaymentSuccessful = true;
    this.paymentMessage = "Transaction was successful !";
    this.paymentIcon = 'assets/payment-widget-icons/payment-success.png';
    this.paymentClass = 'success-payemnt-text';

    this.paymentHeaderBGColor = '#1A237E';
    this.primaryButtonBGColor = '#3D4EAE';
    this.labelColor = '#1A237E';

    this.convenienceFees = this.formData?.configurationTabForm?.paymentOptionTypes?.ACH?.convenienceFeeAmount;
    this.availConvenienceFee = this.formData?.configurationTabForm?.paymentOptionTypes?.ACH?.availConvenienceFee == "Yes" ?? false;
    this.logotext = localStorage.getItem('logotext');

    // small - big view related changes
    // this.previewWiseStyle.divPreviewHeight = this.isSmallView ? "160px !important" : "100%";
    // this.previewWiseStyle.headerLableFontSize = this.isSmallView ? "4px" : "8px";
    // this.previewWiseStyle.orderValueFontSize = this.isSmallView ? "3px" : "6.5px";
    // this.previewWiseStyle.orderLableFontSize = this.isSmallView ? "3px" : "6.5px";
    // this.previewWiseStyle.buttonTextFontSize = this.isSmallView ? "3.5px" : "7px";
    // this.previewWiseStyle.logoImageHeight = this.isSmallView ? "15px" : "40px";
    // this.previewWiseStyle.headerToolbarHeight = this.isSmallView ? "10%" : "15%";
    // this.previewWiseStyle.footerDivHeight = this.isSmallView ? "25px !important" : "50px !important";
    // this.previewWiseStyle.successImageSize = this.isSmallView ? "10px" : "20px";

    this.previewWiseStyle.divPreviewHeight = this.isSmallView ? "160px !important" : "100%";
    this.previewWiseStyle.headerLableFontSize = this.isSmallView ? "4px" : "12px";
    this.previewWiseStyle.orderValueFontSize = this.isSmallView ? "3px" : "11px";
    this.previewWiseStyle.orderLableFontSize = this.isSmallView ? "3px" : "11px";
    this.previewWiseStyle.buttonTextFontSize = this.isSmallView ? "3.5px" : "11px";
    this.previewWiseStyle.logoImageHeight = this.isSmallView ? "15px" : "40px";
    this.previewWiseStyle.headerToolbarHeight = this.isSmallView ? "10%" : "15%";
    this.previewWiseStyle.footerDivHeight = this.isSmallView ? "25px !important" : "50px !important";
    this.previewWiseStyle.successImageSize = this.isSmallView ? "10px" : "20px";
    this.previewWiseStyle.inputControlClass = "input-control-" + (this.isSmallView ? "small" : "big");

    //device type wise changes
    if (this.deviceType) {
      this.deviceWiseStyle.headerToolbarClass = "header-toolbar-" + this.deviceType;
      this.deviceWiseStyle.orderSuccessContainerClass = "order-success-container-" + this.deviceType;
      this.deviceWiseStyle.circleDrawClass = "circle-draw-" + this.deviceType;
      this.deviceWiseStyle.circleDrawLeftClass = this.deviceWiseStyle.circleDrawClass + " circle-draw-left-" + this.deviceType;
      this.deviceWiseStyle.circleDrawRightClass = this.deviceWiseStyle.circleDrawClass + " circle-draw-right-" + this.deviceType;
      this.deviceWiseStyle.paymentSuccessLogoClass = "payment-success-logo-" + this.deviceType;
    }
  }

  validateField(data: string) {
    if (data && data.toLocaleLowerCase() == "show") {
      return true;
    }
    return false;
  }

  getLogoPath(data: any) {     
    if (data != undefined) {
      var logo = data.customisationTabForm.logo;
      this.logoBlobPath = `${environment.ADMIN_CONTAINER_PATH}` + logo;
      return this.logoBlobPath;
    }
}

  
}
