import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-image',
  templateUrl: './left-image.component.html',
  styleUrls: ['./left-image.component.scss']
})
export class LeftImageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
