import { Injectable } from '@angular/core';
import { StatementServiceProxy } from 'src/app/sharedModules/services/ach-transactions/ach-transactions.proxies';
import { ToastrService } from 'ngx-toastr';
import FileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { CrudService } from 'src/app/sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class StatementService extends CrudService<any, any> {
  constructor(
    private statementService: StatementServiceProxy,
    private _toastrService: ToastrService,
    private notifyService: NotificationService,
    protected override _http: HttpClient
  ) {
    super(_http, `${environment.API_ACH_TRANSACTION_URL}/api/v2/Statement`);
  }

  getStatementDetail(statementId: string) {
    return this.statementService.getStatement(statementId);
  }
  getCurrentStatement(fromDate: any, toDate: any) {
    return this.statementService.currentStatement('', fromDate, toDate);
  }
  getStatementList(
    searchDate: any,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    return this.statementService.getStatements(
      searchDate,
      sortBy,
      sortDirection,
      page,
      pageSize
    );
  }
  deleteStatement(statmentList: any) {
    return this.statementService.deleteStatement(statmentList);
  }
  generateNewStatementRecord(fromDate: any, toDate: any) {
    return this.statementService.generateStatement(fromDate, toDate, '');
  }
  generateStatementPDF(id: string, fromDate: any, toDate: any) {
    this._toastrService.info(
      'Please wait, we are downloading Statement detailed PDF report for you.'
    );
    this._http
      .post(
        this._base + '/DownloadStatement?statementId=' + id,
        {},
        {
          responseType: 'blob',
          headers: { Accept: 'application/pdf' },
        }
      )
      .subscribe(
        (res) => {
          if (res != null) {
            FileSaver.saveAs(
              res,
              'Statement_' +
                moment(fromDate).format('yyyyMMDD') +
                '_' +
                moment(toDate).format('yyyyMMDD')
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded.');
          }
        },
        (error) => {
          if (error.status == 401) {
            this.notifyService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');

            JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
              ? window.location.replace('csr')
              : window.location.replace('/');
          } else {
            this._toastrService.clear();
            this._toastrService.error(error.errorMessage);
          }
        }
      );
  }
  generateCurrentStatementPDF(fromDate: any, toDate: any) {
    this._toastrService.info(
      'Please wait, we are downloading Statement detailed PDF report for you.'
    );
    const queryParams = `?fromDate=${fromDate}&toDate=${toDate}`;
    const url = this._base + '/DownloadCurrentStatement' + queryParams;
    this._http
      .post(
        url,
        {},
        {
          responseType: 'blob',
          headers: { Accept: 'application/pdf' },
        }
      )
      .subscribe(
        (res) => {
          if (res != null) {
            FileSaver.saveAs(
              res,
              'CurrentStatement_' +
                moment(fromDate).format('yyyyMMDD') +
                '_' +
                moment(toDate).format('yyyyMMDD')
            );
            this._toastrService.clear();
            this._toastrService.success('File is downloaded.');
          }
        },
        (error) => {
          if (error.status == 401) {
            this.notifyService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');

            JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
              ? window.location.replace('csr')
              : window.location.replace('/');
          } else {
            this._toastrService.clear();
            this._toastrService.error(error.errorMessage);
          }
        }
      );
  }

  PreviewPDF(id: string) {
    this._toastrService.info(
      'Please wait, we are Generating Statement detailed PDF report.'
    );
    this._http
      .post(
        this._base + '/PreviewStatement?statementId=' + id,
        {},
        {
          responseType: 'blob',
          headers: { Accept: 'application/pdf' },
        }
      )
      .subscribe(
        (res) => {
          if (res != null) {
            var blob = new Blob([res], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
          }
        },
        (error) => {
          if (error.status == 401) {
            this.notifyService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');

            JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
              ? window.location.replace('csr')
              : window.location.replace('/');
          } else {
            this._toastrService.clear();
            this._toastrService.error(error.errorMessage);
          }
        }
      );
  }
  PreviewCurrentPDF(fromDate: any, toDate: any) {
    this._toastrService.info(
      'Please wait, we are Generating Statement detailed PDF report.'
    );
    const queryParams = `?fromDate=${fromDate}&toDate=${toDate}`;
    const url = this._base + '/PreviewCurrentStatement' + queryParams;
    this._http
      .post(
        url,
        {},
        {
          responseType: 'blob',
          headers: { Accept: 'application/pdf' },
        }
      )
      .subscribe(
        (res) => {
          if (res != null) {
            var blob = new Blob([res], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
          }
        },
        (error) => {
          if (error.status == 401) {
            this.notifyService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');

            JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
              ? window.location.replace('csr')
              : window.location.replace('/');
          } else {
            this._toastrService.clear();
            this._toastrService.error(error.errorMessage);
          }
        }
      );
  }
}
