import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDashboardService } from 'src/app/featureModules/services/invoice-dashboard.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { PageChanged, SortChanged } from 'src/app/sharedModules/models/pagination.model';


@Component({
  selector: 'app-invoice-dashboard-company-breakdown',
  templateUrl: './invoice-dashboard-company-breakdown.component.html',
  styleUrls: ['./invoice-dashboard-company-breakdown.component.scss']
})
export class InvoiceDashboardCompanyBreakdownComponent implements OnInit, OnChanges {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..'
  allColumns: TableColumn[];
  @Input()
  period: string;

  periodCurrentLabel: string = '';
  periodLastLabel: string = '';

  data: any;
  isLoadingPaging = false;
  @Input()
  noRecordMessage: string;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 12,
    length: 12,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: '',
    direction: '',
  };

  // columns: TableColumn[] = [
  //   { displayName: 'Comapny Name', name: 'companyName', width: '20%' },
  //   { displayName: 'Number Invoices(LY)', name: 'last.totalInvoiceCount', width: '20%' },
  //   { displayName: 'Number Invoices(CY)', name: 'current.totalInvoiceCount', width: '10%' },
  //   { displayName: 'Total Amount(LY)', name: 'last.totalInvoiceAmount', width: '20%' },
  //   { displayName: 'Total Amount(CY)', name: 'current.totalInvoiceAmount', width: '10%' }
  // ];

  columns: TableColumn[] = [
    { displayName: 'Month', name: 'month', width: '20%' },
    { displayName: 'Paid Amount', name: 'paidAmount', width: '10%' },
    { displayName: 'Paid Total', name: 'paidTotal', width: '10%' },
    { displayName: 'Unpaid Amount', name: 'unpaidAmount', width: '10%' },
    { displayName: 'Unpaid Total', name: 'unpaidTotal', width: '10%' },
    { displayName: 'Past Due Amount', name: 'pastDueAmount', width: '10%' },
    { displayName: 'Past Due Total', name: 'pastDueTotal', width: '10%' },
    { displayName: 'Returned Amount', name: 'returnedAmount', width: '10%' },
    { displayName: 'Returned Total', name: 'returnedTotal', width: '10%' }
  ];

  constructor(private dashboardService: InvoiceDashboardService, private _toastrService: ToastrService) {
    this.allColumns = this.columns;
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.period.toLowerCase() == 'yearly') {
      this.periodCurrentLabel = 'This Year';
      this.periodLastLabel = 'Last Year';
    }
    else if (this.period.toLowerCase() == 'monthly') {
      this.periodCurrentLabel = 'This Month';
      this.periodLastLabel = 'Last Month';
    }
    else {
      this.periodCurrentLabel = 'This Week';
      this.periodLastLabel = 'Last Week';
    }
    this.getAllInvoiceTransactionGrid(null, this.sortBy.active,
      this.sortBy.direction);
  }

  GetCompanyBreakDownStatus(period: string) {
    this.isLoading = true;


    this.dashboardService.getCompanyBreakDownStatus(period).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          data = data.data;
        }
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  getAllInvoiceTransactionGrid(customerId: any, sortBy: string, sortDirection: string) {
    this.isLoading = true;

    this.dashboardService.getAllInvoiceTransactionGrid(customerId, sortBy, sortDirection).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data.amountRecord != null) {
          this.data = data.data.amountRecord;
          this.page.length = 12;
        }
        else if (data.data!= null) {
          this.data = data.data;
          this.page.length = 12;
        }
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        this.loadMessage = '';
        this._toastrService.error(
          'Something went wrong, Please contact administrator!'
        );
      }
    );
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
    }

    this.getAllInvoiceTransactionGrid(null, this.sortBy.active,
      this.sortBy.direction);
  }
}
