function twoDigits(value: number): string {
    if (value < 10) {
      return `0${value}`;
    }
    return String(value);
  }
  
  export function getSafeIsoDateString(date: string | Date): string {
    if (date instanceof Date) {
      if (isNaN(date.getTime())) {
        return undefined;
      }
      // YYYY-MM-DD
      // var newDate=new Date(date);
      // var tmpDateTime=`${newDate.getFullYear()}-${twoDigits(newDate.getMonth() + 1)}-${twoDigits(newDate.getDate())} ${twoDigits(newDate.getHours())}:${twoDigits(newDate.getMinutes())}`;
      // var theDate = new Date(Date.UTC(newDate.getFullYear(),parseInt(twoDigits(newDate.getMonth())),parseInt(twoDigits(newDate.getDate())),parseInt(twoDigits(newDate.getHours())),parseInt(twoDigits(newDate.getMinutes()))));
      // let timezone:string=Intl.DateTimeFormat().resolvedOptions().timeZone;
      // return theDate.toLocaleString(); //TODO: WORKING ON TIME PART
        
      return `${date.getFullYear()}-${twoDigits(date.getMonth() + 1)}-${twoDigits(date.getDate())}`;
    }
    if (date) {
      return getSafeIsoDateString(new Date(date));
    }
    return undefined;
  }
  
  export function getSafeUSDateString(date: string | Date): string {
    const isoDate = getSafeIsoDateString(date);
    if (isoDate) {
      const day = isoDate.substr(8, 2);
      const month = isoDate.substr(5, 2);
      const year = isoDate.substr(0, 4);
      // MM/DD/YYYY
      return `${month}/${day}/${year}`;
    }
  }
  
  export function getDaysInMonth(month: number, year: number) {
    const date = new Date(year, month, 1);
    const days = [];
  
    while (date.getMonth() === month) {
      days.push(new Date(date).getDate());
      date.setDate(date.getDate() + 1);
    }
  
    return days;
  }
  
  export function getSafeIsoDateTimeStringToLocal(date: string | Date): string {
    var newDate=new Date(date);
    var tmpDateTime=`${newDate.getFullYear()}-${twoDigits(newDate.getMonth() + 1)}-${twoDigits(newDate.getDate())} ${twoDigits(newDate.getHours())}:${twoDigits(newDate.getMinutes())}`;
    var theDate = new Date(Date.UTC(newDate.getFullYear(),parseInt(twoDigits(newDate.getMonth())),parseInt(twoDigits(newDate.getDate())),parseInt(twoDigits(newDate.getHours())),parseInt(twoDigits(newDate.getMinutes()))));
    let timezone:string=Intl.DateTimeFormat().resolvedOptions().timeZone;
    return theDate.toLocaleTimeString([], {timeZone:timezone,year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}); //TODO: WORKING ON TIME PART
  }
  
  