<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <!-- <mat-icon (click)="drawer.close()">close</mat-icon> -->
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div class="divMargin" fxLayout="row">Select Category</div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <!-- <mat-label>Created On</mat-label> -->
            <div fxLayout="column" class="divMargin" fxFlex="90%">
              <ng-multiselect-dropdown
                name="categoryId"
                placeholder="---Select---"
                [settings]="dropdownSettings"
                [data]="categoryList"
                formControlName="categoryId"
                (onSelect)="onItemSelect($event)"
                [(ngModel)]="selectedCategory"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>

          <div fxLayout="row">Select Range</div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="5%"
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxLayout="column" fxLayoutAlign="center start">Avail. Quantity</div>
            <div fxLayout="column">
              <mat-form-field
                appearance="outline"
                class="mat-form-field-width1"
              >
                <mat-label>From</mat-label>
                <input
                  title="From Quantity"
                  matInput
                  class="form-control"
                  formControlName="quantityFrom"
                  type="text"
                  name="quantityFrom"
                  placholder="From"
                  autocomplete="off"
                  appTwoDigitDecimalNumber
                />
              </mat-form-field>
            </div>
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="mat-form-field-width">
                <mat-label>To</mat-label>
                <input
                  title="To Quantity"
                  matInput
                  class="form-control"
                  formControlName="quantityTo"
                  type="text"
                  name="quantityTo"
                  placholder="To"
                  autocomplete="off"
                  appTwoDigitDecimalNumber
                />
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="5%"
          >
            <div fxLayout="column" fxLayoutAlign="center start">UnitPrice</div>
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="mat-form-field-width">
                <mat-label>From</mat-label>
                <input
                  title="From Price"
                  matInput
                  class="form-control"
                  formControlName="priceFrom"
                  type="text"
                  name="priceFrom"
                  placholder="From"
                  autocomplete="off"
                  appTwoDigitDecimalNumber
                />
                <span matPrefix class="material-icons">attach_money</span>
              </mat-form-field>
            </div>
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="mat-form-field-width">
                <mat-label>To</mat-label>
                <input
                  title="To Price"
                  matInput
                  class="form-control"
                  formControlName="priceTo"
                  type="text"
                  name="priceTo"
                  placholder="To"
                  autocomplete="off"
                  appTwoDigitDecimalNumber
                />
                <span matPrefix class="material-icons">attach_money</span>
              </mat-form-field>
            </div>
          </div>
          <div class="mb" fxLayout="row">Created On</div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <!-- <mat-label>Created On</mat-label> -->
            <div fxLayout="column" fxFlex="50%">
              <mat-form-field
                appearance="outline"
                class="mat-form-field-width2"
              >
                <mat-label>From - To</mat-label>
                <mat-date-range-input [rangePicker]="dateRangePicker">
                  <input
                    matStartDate
                    placeholder="Start date"
                    formControlName="periodStartDate"
                    name="periodStartDate"
                    readonly
                  />
                  <input
                    matEndDate
                    placeholder="End date"
                    formControlName="periodEndDate"
                    name="periodEndDate"
                    readonly
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dateRangePicker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                <mat-error>
                  {{
                    filterFormGroup.controls["periodStartDate"]["_errorMessage"]
                  }}
                </mat-error>
                <mat-error>
                  {{
                    filterFormGroup.controls["periodEndDate"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mb" fxLayout="row">Created By</div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxLayout="column" fxFlex="50%">
              <mat-form-field
                appearance="outline"
                class="mat-form-field-width2"
              >
                <mat-label>Search</mat-label>                
                <input
                  matInput
                  formControlName="createdBy"
                  name="createdBy"                                   
                  placeholder="Search"                  
                  style="padding-bottom: 1%"                                  
                  (blur)="onBlurSearch()"
                  pattern="{{ searchTextPattern }}"
                  autocomplete="off"
                />
                <button
                  *ngIf="this.filterFormGroup.get('createdBy').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSelection()"
                >
                  <mat-icon>close</mat-icon>
                </button>                
                <!-- <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="onSelected()"
                >
                  <mat-option *ngIf="isLoading" class="is-loading"
                    >Loading...</mat-option
                  >
                  <ng-container
                    *ngIf="!isLoading && filteredCustomer.length > 0"
                  >
                    <mat-option
                      *ngFor="let customer of filteredCustomer"
                      [value]="selectedCustomerName(customer)"
                    >
                      <div class="name-email-style">
                        {{ customer.firstName?.trim() }}&nbsp;{{
                          customer.lastName?.trim()
                        }}
                      </div>
                      <div class="name-email-style sub-text">
                        {{ customer.email }}
                      </div>
                    </mat-option>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      isShowNoFound && !isLoading && !filteredCustomer.length
                    "
                  >
                    <mat-option>
                      <div class="name-email-style">
                        <b>No Records Found</b>
                      </div>
                    </mat-option>
                  </ng-container>
                </mat-autocomplete> -->
                <mat-error>
                  {{ filterFormGroup.controls["createdBy"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>              
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutAlign="start center"
            fxLayoutGap="10%"
          >
            <div fxLayout="column" class="marginLeft">Status</div>
            <div fxLayout="column">
              <mat-slide-toggle
                formControlName="isActive"
                name="isActive"
                class="form-control"
              >
                <mat-label
                  class="showDetailsValue"
                  [ngStyle]="{
                    ' color': filterFormGroup.controls['isActive'].value
                      ? 'green'
                      : 'red'
                  }"
                >
                  {{
                    filterFormGroup.controls["isActive"].value
                      ? "Active"
                      : "Inactive"
                  }}
                </mat-label>
              </mat-slide-toggle>
            </div>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end" class="marginTop">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            [disabled]="!filterFormGroup.valid"
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <app-data-table
      [columns]="columns"
      [data]="data"
      [isLoading]="isLoading"
      [isLoadingPaging]="isLoadingPaging"
      [page]="page"
      [sortBy]="sortBy"
      [noRecordMessage]="noRecordMessage"
      [actionDelete]="actionDelete"
      [showMoreMenu]="true"
      [allColumns]="allColumns"
      [selection]="selection"
      (actionDeleteEvent)="onDelete($event)"
      [deletedFilter]="deletedFilter"
      [actionRefresh]="actionRefresh"
      (actionRefreshEvent)="onRefresh($event)"
      (pageChangedEvent)="onPageChanged($event)"
      (serviceDeleteEvent)="onDeleteClick($event)"
      (sortChangedEvent)="onSortChanged($event)"
      (onFilterEvent)="onFilter($event)"
      (advanceFilterEvent)="openFilter(); drawer.toggle()"
      (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)"
      (serviceEditEvent)="serviceEdit($event)"
      [isInventory]="true"
      [isExport]="false"
      [advanceFilterCount]="advanceFilterCount"
      [filterText]="this.filter?.toString() ?? ''"
      [role]="this.userRole"
    >
    </app-data-table>
  </mat-drawer-content>
</mat-drawer-container>
