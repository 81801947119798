import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { InvoiceService } from 'src/app/featureModules/services/invoice.service';
import { InvoiceFilterModel } from 'src/app/featureModules/models/invoice-filter.model';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

@Component({
  selector: 'app-generate-invoice',
  templateUrl: './generate-invoice.component.html',
  styleUrls: ['./generate-invoice.component.scss'],
})
export class GenerateInvoiceComponent implements OnInit {
  isLoading: boolean;
  loadMessage: string = 'Please wait..';
  invoiceGenerateFormGroup: FormGroup;
  invoiceFilterModel: InvoiceFilterModel;

  minDate = new Date();
  maxDate = new Date();

  startDate: string;
  endDate: string;
  invoiceNumber: number;
  customerId: string;

  filteredCustomer: any[] = [];
  minLengthTerm = 3;
  isShowNoFound: boolean = false;
  dynamicInvoice: boolean = false;
  invoiceData: any;
  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private _customerService: CustomerService,
    private _toastrService: ToastrService,
    private _invoiceService: InvoiceService,
    private activatedRoute: ActivatedRoute,
    private _commonService : CommonService
  ) {
    this.minDate.setFullYear(this.minDate.getFullYear() - 2);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 1);
  }

  ngOnInit(): void {
    this.isShowNoFound = false;
    this.invoiceFilterModel = new InvoiceFilterModel();
    this.invoiceGenerateFormGroup = this.formBuilder.formGroup(
      this.invoiceFilterModel
    );
    let cId = this.activatedRoute.snapshot.paramMap.get('customerId');
    if (cId) {
      this.invoiceFilterModel.customerName = this._invoiceService.cName;
      this.invoiceFilterModel.customerId = this._invoiceService.cId;
      this.customerId = this._invoiceService.cId;
      this.invoiceGenerateFormGroup.controls['customerName'].setValue(
        this.invoiceFilterModel.customerName
      );
    }
    
    this.invoiceGenerateFormGroup.controls['customerName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.invoiceGenerateFormGroup.controls['customerName'].valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomer = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(10),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this._customerService.SearchCustomers(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.filteredCustomer = [];
          this.isShowNoFound = true;
        } else {
          this.filteredCustomer = data.data;
          this.isShowNoFound = false;
          this.customerId = this.filteredCustomer[0].customerId;
        }
      });
  }

  navigateToBackPage() {
    this.router.navigate(['invoices']);
  }

  addNewCustomer() {    
    this._commonService.navigationEvent.emit('customers');
    this.router.navigate(['customers/manage-customer']);
  }

  onApplyClick() {
    if (this.invoiceGenerateFormGroup.valid) {
      this.dynamicInvoice = true;
      this.invoiceGenerateFormGroup.value.startDate?.setHours(12, 0, 0);
      this.invoiceGenerateFormGroup.value.endDate?.setHours(12, 0, 0);
      this.startDate = getSafeIsoDateString(this.invoiceGenerateFormGroup.value.startDate);
      this.endDate = getSafeIsoDateString(this.invoiceGenerateFormGroup.value.endDate);
      this.isLoading = true;
      if (this.customerId == undefined) {
        this._toastrService.error('Please select valid customer');
        this.dynamicInvoice = false;
        this.isLoading = false;
        return false;
      }
      this._invoiceService.getInvoiceDetails(this.customerId).subscribe(
        (data) => {
          this._invoiceService.invoiceServiceMainModel = data.data;
          this.invoiceData = this._invoiceService.invoiceServiceMainModel;
          this.isLoading = false;
          this._invoiceService.invoiceDataEmitter.emit(data.data);
        },
        (error) => {
          if (error.error.message != null || error.error.message != undefined) {
            this._toastrService.warning(error.error.message);
          } else {
            this._toastrService.warning(error.error.content);
          }
        }
      );
    }
  }

  // onApplyClick(){
  //   this.dynamicInvoice=true;
  // }
  selectedCustomerName(customer: any) {
    if(customer){
    let lastName  = customer.lastName?.trim() == undefined ? '' : customer.lastName?.trim()
    return customer.firstName?.trim() + ' ' + lastName;
    }
    else{
      return "";
    }
  }
  onSelected(event:any) {    
  this.customerId = event.option.value.customerId;
  this.invoiceGenerateFormGroup.get('customerId').setValue(event.option.value.customerId);
  }
  clearSelection() {
    this.invoiceGenerateFormGroup.get('customerName').setValue(null);
    this.invoiceGenerateFormGroup.get('customerId').setValue(null);
    this.filteredCustomer = [];
  }
}
