import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddAchTransactionComponent } from 'src/app/featureModules/components/ach-related/add-ach-transaction/add-ach-transaction.component';
import { CustomerAchTransactionComponent } from 'src/app/featureModules/components/transactions-related/ach/customer-ach-transaction/customer-ach-transaction.component';
import { AuthGuard } from 'src/app/sharedModules/guards/auth.guard';

const routes: Routes = [ 
  { path: 'add-ach', component: AddAchTransactionComponent, pathMatch: 'full',canActivate:[AuthGuard] },
  { path: 'add-ach/:customerId', component: AddAchTransactionComponent, pathMatch: 'full',canActivate:[AuthGuard] },
  {
    path: 'customer-ach/:customerId',
    component: CustomerAchTransactionComponent,
    pathMatch: 'full',
    canActivate:[AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TransactionsRoutingModule { }
