import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import {
  HostedPaymentTabModel, HostedPaymentConfiguration,
  HostedPaymentDesignCustomisation
} from 'src/app/sharedModules/models/hosted-payment-tab.model';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';

import {
  DesignCustomisation,DesignConfiguration, HostedPaymentSendModel
} from 'src/app/sharedModules/models/hosted-payment-send.model';
import { ToastrService } from 'ngx-toastr';
import { HostedPaymentCustomizationService } from 'src/app/featureModules/services/hosted-payment-customization.service';

@Component({
  selector: 'app-hosted-payment-page-setting-layout',
  templateUrl: './hosted-payment-page-setting-layout.component.html',
  styleUrls: ['./hosted-payment-page-setting-layout.component.scss']
})
export class HostedPaymentPageSettingLayoutComponent implements OnInit {
  public hostedPaymentpageFormGroup: FormGroup;
  public hostedPaymentTabModel: HostedPaymentTabModel;
  public hostedPaymentSendModel: HostedPaymentSendModel;
  finzeoPaymentOption:string;
  formData: any;
  isLoading = false;
  loadMessage: string = '';
  changesIsLoading: boolean;

  constructor(
    private formBuilder: RxFormBuilder,
    private _toastrService: ToastrService,
    private _hostedPaymentCustomService: HostedPaymentCustomizationService,
  ) { }

  ngOnInit(): void {
    this.getPaymentCustmizationData();
  }

  getPaymentCustmizationData() {
    this.isLoading = true;
    this._hostedPaymentCustomService
      .getHostedPaymentCustomizationSettings(false)
      .subscribe(
        (data) => {          
          if (data.data != null) {
            let hostedPaymentTabModel = new HostedPaymentTabModel();
            hostedPaymentTabModel.configurationTabForm = new HostedPaymentConfiguration();
            hostedPaymentTabModel.customisationTabForm = new HostedPaymentDesignCustomisation();
            this.hostedPaymentpageFormGroup = this.formBuilder.formGroup(hostedPaymentTabModel);
            this.finzeoPaymentOption=data.data["finzeoPaymentOption"];
            var parsedData = JSON.parse(data.data["defaultSettingData"]);
            this.hostedPaymentpageFormGroup.valueChanges.pipe(
              filter(() => {
                this.changesIsLoading = true;
                return true;
              }),
              distinctUntilChanged(),
              debounceTime(1000),
              tap(() => {
                this.changesIsLoading = false;
              })).subscribe(data => {
              this.formData = data;
              this.changesIsLoading = false;
            });            
            this.hostedPaymentpageFormGroup.patchValue(parsedData);            
          }
          this.isLoading = false;          
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }


  updatePaymentSettings() {
    this.isLoading = true;
    let isConvenienceAch = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availConvenienceFee'].value;
    let convenienceFee = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].value 
                              ? this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].value 
                              : 0.0;

    if(isConvenienceAch == "Yes" && convenienceFee < 0.1){
      this.isLoading = false;
      this._toastrService.warning('Convenience Fee should not be less than 0.1');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'].markAllAsTouched();
      return false;
    }

    if(isConvenienceAch == "Yes" && convenienceFee > 100){
      this.isLoading = false;
      this._toastrService.warning('Convenience Fee should not be greater than 100');
      this.hostedPaymentpageFormGroup.controls['configurationTabForm'].markAllAsTouched();
      return false;
    }

    if(this.hostedPaymentpageFormGroup.controls['customisationTabForm']['controls']['logo'].value == null)
    {
      this.isLoading = false;
      this._toastrService.warning('Please upload Company Logo!');
      return false;
    }

    if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionAch'].value == false
    && this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionCard'].value == false)
    {
      this.isLoading = false;
      this._toastrService.warning('Please select any payment option in configuration');
      return false;
    }

    if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionAch'].value 
     &&
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionCard'].value)
    {
      
        let isRecurringCard = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availRecuringCard'].value ? true : false;
        let isRecurringAch = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availRecuringAch'].value ? true : false;
        let isConvenienceAch = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availConvenienceFee'].value;
        let convenienceFee = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].value 
                              ? this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['convenienceFeeAmount'].value 
                              : 0.0;
        let items =
        {
          "Card": { isRecurring: isRecurringCard },
          "ACH": { isRecurring: isRecurringAch ,availConvenienceFee:isConvenienceAch ,convenienceFeeAmount:convenienceFee},
        };
        this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionTypes'].value=items;

    }
    else if(!this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionAch'].value    &&
    !this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionCard'].value)
    {
      
        this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionTypes'].value="";

    }
    else if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionAch'].value    &&
    !this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionCard'].value)
    {
      
      
      let isRecurringAch = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availRecuringAch'].value ? true : false;
      
      let items =
      {
       
        "ACH": { isRecurring: isRecurringAch ,availConvenienceFee:isConvenienceAch ,convenienceFeeAmount:convenienceFee},
      };
      this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionTypes'].value=items;


    }
    else if(!this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionAch'].value    &&
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionCard'].value)
    {
      
        let isRecurringCard = this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['availRecuringCard'].value ? true : false;
        let items =
        {
          "Card": { isRecurring: isRecurringCard },         
        };
        this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionTypes'].value=items;

    }


    if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationSMS'].value == false
    &&
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationEmail'].value == false)
    {
      this.isLoading = false;
      this._toastrService.error('Please select any option to send notification in confirguration');
      return false;
    }
    if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationSMSOwner'].value == false
    &&
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationEmailOwner'].value == false)
    {
      this.isLoading = false;
      this._toastrService.error('Please select any option to send notification to owner in confirguration');
      return false;
    }

    
    if(this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['customerPortalOnBoarding'].value != "Yes"
      &&
      this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['customerPortalOnBoarding'].value != "No")
      {
        this.isLoading = false;
        this._toastrService.error('Please select any option to send email while customer onboarding through Hpp Payment');
        return false;
      }
  
    var settingData = this.bindPostingData();

    if (this.hostedPaymentpageFormGroup.valid) {
      this._hostedPaymentCustomService
        .updatePaymentCustomizationSettings(settingData, null)
        .subscribe(
          (data) => {
            this.isLoading = false;
            if (data.code == 200) {
              this._toastrService.success(
                'Payment Customization Settings Updated successfully!'
              );
            }
            else {
              this._toastrService.warning(data.errorMessage);
            }
          },
          (error) => {
            this.isLoading = false;
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        );
    }
    else{
      this.isLoading = false;
    }
  }

  getDefaultHostedPaymentSettings() {
    this.isLoading = true;
    this._hostedPaymentCustomService
      .getHostedPaymentCustomizationSettings(true)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null && data.data["defaultSettingData"] != null) {
            var parsedData = JSON.parse(data.data["defaultSettingData"]);
            this.hostedPaymentpageFormGroup.valueChanges.subscribe(data => {
              this.formData = data;
            });
            this.hostedPaymentpageFormGroup.patchValue(parsedData);

            this._toastrService.success(
              'Settings Reset-to-Default successfully!'
            );
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  bindPostingData(){
    let hostedPaymentSendModel = new HostedPaymentSendModel(); 
    hostedPaymentSendModel.customisationTabForm = new DesignCustomisation();
    hostedPaymentSendModel.configurationTabForm = new DesignConfiguration;

    hostedPaymentSendModel.customisationTabForm.logo = 
    this.hostedPaymentpageFormGroup.value['customisationTabForm'].logo;
    hostedPaymentSendModel.customisationTabForm.themeColor = 
    this.hostedPaymentpageFormGroup.value['customisationTabForm'].themeColor;
    hostedPaymentSendModel.customisationTabForm.themeFont = 
    this.hostedPaymentpageFormGroup.value['customisationTabForm'].themeFont;
    hostedPaymentSendModel.customisationTabForm.themeTextColor = 
    this.hostedPaymentpageFormGroup.value['customisationTabForm'].themeTextColor;

    hostedPaymentSendModel.configurationTabForm.isInvoiceNumberView = 
    this.hostedPaymentpageFormGroup.value['configurationTabForm'].isInvoiceNumberView;
    hostedPaymentSendModel.configurationTabForm.isDescriptionView = 
    this.hostedPaymentpageFormGroup.value['configurationTabForm'].isDescriptionView;
    hostedPaymentSendModel.configurationTabForm.shippingDetails = 
    this.hostedPaymentpageFormGroup.value['configurationTabForm'].shippingDetails; 

    let isEmailChecked = 
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationEmailOwner'].value?true:false;
    if(isEmailChecked == true)
    {
      hostedPaymentSendModel.configurationTabForm.emailList = 
      this.hostedPaymentpageFormGroup.value['configurationTabForm'].emailList;
    }else{
      hostedPaymentSendModel.configurationTabForm.emailList =[];
    }

    let isSMSChecked = 
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationSMSOwner'].value?true:false;
    if(isSMSChecked == true)
    {
      hostedPaymentSendModel.configurationTabForm.mobileList =
      this.hostedPaymentpageFormGroup.value['configurationTabForm'].mobileList;
    }else{
      hostedPaymentSendModel.configurationTabForm.mobileList =[];
    }


    hostedPaymentSendModel.configurationTabForm.sendNotificationOn =
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationOn'].value; 
    hostedPaymentSendModel.configurationTabForm.sendNotificationToOwnerOn =
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['sendNotificationToOwnerOn'].value;      
    hostedPaymentSendModel.configurationTabForm.paymentOptionTypes =
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['paymentOptionTypes'].value;   
    hostedPaymentSendModel.configurationTabForm.customerPortalOnBoarding =
    this.hostedPaymentpageFormGroup.controls['configurationTabForm']['controls']['customerPortalOnBoarding'].value;
    var settingData = JSON.stringify(hostedPaymentSendModel);
    return settingData;
  }

}


