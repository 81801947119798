<div style="min-height: 100%;">
    <div fxLayout="column" fxFlex="40%" fxLayout.xs="column" fxLayoutAlign="start stretch" style="background-color: #032859; text-align: center;">
        <app-left-image></app-left-image>
    </div>

    <div fxLayout="column" fxFlex="60%" >
        <div fxLayout="column" fxFlex="30%"  >
            
            <div class="form-Signin">

                <div>
                    <div>
                        <p class="title">Password reset</p>
                        <br>
                        <p >Your password has been successfully reset.</p>
                        <p >Click below to sign in.</p>
                    </div>

                    <br>
                    <br>
                    <br>

                    <div >
                        <button mat-flat-button color="primary"   style="width: 100%; height: 50px;" (click)="signInEvent()" >SIGN IN</button>
                    </div> 
                    
                </div>
            </div>
        </div>
    </div>
</div>