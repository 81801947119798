<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text">Setting</div>
                <div class="page-sub-header-text-1">
                    All kind of setup related data can be managed from this page.
                </div>
            </div>
        </div>
    </div>
</div>

<div class="setting-container">
    <div class="setting-inner-container">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5%" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('invoice-setup')">INVOICE SET UP</label>
                <div class="description">
                    All Invoice setup related data like generating labels, Invoice<br>
                    number, Installments settings,Interest and tax related <br>
                    information, Return policy and theme etc. can be managed <br>
                    from this page.
                </div>
            </div>
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('setting/hosted-payment-link')">HOSTED PAYMENT PAGE LINK
                    GENERATION</label>
                <div class="description">
                    All payment link setup related data like Design, setting of link <br>
                    expiration, pricing details like invoice number, Entry method <br>
                    can be managed from this page.
                </div>
            </div>
            <div fxLayout="column" fxFlex="10%" fxLayout.xs="column" fxLayoutAlign="start start">

            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5%" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('manage-notification')">MANAGE INVOICE NOTIFICATION</label>
                <div class="description">
                    All Notification related like data like scheduling payment <br>
                    reminder, Mode of notification, invoice due dates etc. can be <br>
                    managed from this page.
                </div>
            </div>
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('challengerScoreSettings')">CHALLENGER SCORE SETTING</label>
                <div class="description">
                    All Challenger Score Setting and Numerical Score to Alphabetical <br>
                    Grade Settings can be managed from this page. <br>                    
                </div>
            </div>
            <div fxLayout="column" fxFlex="10%" fxLayout.xs="column" fxLayoutAlign="start start">

            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('email-setup')">INVOICE EMAIL SETUP</label>
                <div class="description">
                    All Email setup related data for customization of Email <br>
                    notification template content can be managed from this page.
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="37%" fxLayout.xs="column" fxLayoutAlign="start start">
                <label class="header" (click)="navigate('hosted-payment-page-setting')">HOSTED PAYMENT PAGE
                    CUSTOMIZATION </label>
                <div class="description">
                    All payment page setup related data like Design <br>
                    customization of theme, color, logo, font etc and <br>
                    configuration of fields can be managed from this page.
                </div>
            </div>
        </div>
    </div>
</div>