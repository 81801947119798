import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-customer-transactions',
  templateUrl: './customer-transactions.component.html',
  styleUrls: ['./customer-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomerTransactionsComponent implements OnInit, OnDestroy {
  loadAchTransaction: boolean;
  loadCreditDebit: boolean;
  loadInstantFund: boolean;
  loadEcheck: boolean;

  @Input()
  customerId: any;

  @Input()
  isActive: boolean;

  @Input()
  userRole: any;

  @Input()
  selectedTabIndex: number = 0;

  @Input()
  customerType: boolean;

  constructor(private router: Router) {}

  ngOnInit() {
    this.onTabChange();
  }

  onTabChange() {
    switch (this.selectedTabIndex) {
      case 0:
        this.loadAchTransaction = true;
        break;
      case 1:
        this.loadCreditDebit = true;
        break;
      case 2:
        this.loadInstantFund = true;
        this.loadEcheck = true;
        break;
      case 3:
        this.loadEcheck = true;
        break;
    }
  }

  AddTransaction() {
    switch (this.selectedTabIndex) {
      case 0:
        this.router.navigate(['add-ach', this.customerId], {
          state: {
            url: '/customers/customer-profile/' + this.customerId,
            selectedTransactionTabIndex: this.selectedTabIndex,
            transactionIndex: 2,
          },
        });
        break;
      case 1:
        this.router.navigate(
          ['customers/add-creditdebit', this.customerId],
          {
            state: {
              url: '/customers/customer-profile/' + this.customerId,
              selectedTransactionTabIndex: this.selectedTabIndex,
              transactionIndex: 2
            },
          }
        );
        break;
      case 2:
        this.router.navigate(
          ['customers/add-echeck', this.customerId], // INF hide 
          {
            state: {
              url: '/customers/customer-profile/' + this.customerId,
              selectedTransactionTabIndex: this.selectedTabIndex,
              transactionIndex: 2
            },
          }
        );
        break;
      case 3:
        this.router.navigate(
          ['customers/add-echeck', this.customerId],
          {
            state: {
              url: '/customers/customer-profile/' + this.customerId,
              selectedTransactionTabIndex: this.selectedTabIndex,
              transactionIndex: 2
            },
          }
        );
        break;
    }
  }

  ngOnDestroy() {}
}
