import { pattern, prop, required } from '@rxweb/reactive-form-validators';

export class InvoiceFilterModel {
  
  @prop()
  customerId: any;
  
  @prop()
  @required()
  //@pattern({ expression: { Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z.\-\s]*$/,},})
  //@pattern({ expression: { Alpha_Hyphen_Period_Space: /^[a-zA-Z_]+$/}})
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  customerName: string;

  @prop()
  @required()
  startDate: any;

  @prop()
  @required()
  endDate: any;

}