<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
  <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Invoices</div>
        <div class="page-sub-header-text-1">
          All Invoice related data can be seen on this page
        </div>
      </div>
    </div>
    <span class="spacer"></span>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <button mat-stroked-button color="primary" class="white-button-style" (click)="downloadReport()">
          DOWNLOAD
        </button>
        &nbsp;&nbsp;
        <button *ngIf = "this.userRole != '1'" mat-raised-button color="primary" class="page-header-button-style" (click)="generateInvoice()">
          GENERATE INVOICE
        </button>
      </div>
    </div>
  </div>
</div>

<div class="compare-data-container">
  <div class="compare-data-inner-div" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end center">
    <div fxLayout="column" fxLayoutAlign="end end">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <mat-form-field appearance="outline">
          <mat-label>Select to Compare Data</mat-label>
          <mat-select [(value)]="period">
            <mat-option value="weekly">Current VS Last Week</mat-option>
            <mat-option value="monthly">Current VS Last Month</mat-option>
            <mat-option value="yearly">Current VS Last Year</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

<div #canvasContainer_Page1 id="canvasContainer_Page1">
  <app-invoice-dashboard-stats [period]="period"></app-invoice-dashboard-stats>
  <div class="invoice-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" class="totalInvoicesDiv" fxLayoutGap="5px" fxFlex="70%">
      <app-invoice-dashboard-total-invoices [period]="period"></app-invoice-dashboard-total-invoices>
    </div>
    <div fxLayout="column" class="paymentMethodDiv" fxFlex="30%">
      <app-invoice-dashboard-payment-method [period]="period"></app-invoice-dashboard-payment-method>
    </div>
  </div>
</div>
<div #canvasContainer_Page2 id="canvasContainer_Page2">
  <div class="invoice-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" class="totalTransactionsDiv" fxLayoutGap="5px" fxFlex="100%">
      <app-invoice-dashboard-total-amount-tabs [period]="period"></app-invoice-dashboard-total-amount-tabs>
    </div>
  </div>
</div>

<div #canvasContainer_Page3 id="canvasContainer_Page3">
  <div class="invoice-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" class="totalTransactionsDiv" fxLayoutGap="5px" fxFlex="100%">
      <app-invoice-dashboard-company-breakdown [period]="period"></app-invoice-dashboard-company-breakdown>
    </div>
  </div>
</div>