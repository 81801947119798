import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimeZoneInterceptorService implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      (req.responseType == 'json' || req.responseType == 'blob') &&
      !this.checkIfAPIInSkipList(req.url, this.otherSkipApiList)
    ) {
      const modifiedReq = req.clone({
        headers: req.headers
          .set('Zone-Name', Intl.DateTimeFormat().resolvedOptions().timeZone)
          .set('Content-Type', 'application/json'),
      });
      return next.handle(modifiedReq);
    } else return next.handle(req);
  }

  checkIfAPIInSkipList(url: string, skipList: any) {
    return skipList.find((x) => url.includes(x)) ? true : false;
  }

  otherSkipApiList = [
    'api/FileUpload/uploadAttachment',
    'api/FileUpload/downloadUploadedFile',
    'api/HostedPaymentCustomization/uploadAttachment',
    'api/Transactions/getUpcomingHolidays',
    'api/CustomerInvoice/getUpcomingHolidays',
    'api/payment/lookUpBin',

    //Ach API
    '/api/v2/Event/Transaction',
    '/api/v2/Statement/DownloadStatement',
    '/api/v2/Statement/PreviewStatement',
    '/api/v2/Statement/DownloadCurrentStatement',
    '/api/v2/Statement/PreviewCurrentStatement',
    '/api/v2/Voice/start',
    '/api/v2/Voice/language',
    '/api/v2/Statement/GenerateStatement',
    '/api/v2/InternalAch/GetUpcomingHolidays',
    'ExternalAchHostedPaymentPage/GetUpcomingHolidays',
  ];
}
