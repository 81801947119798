import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { EulaDialogComponent } from 'src/app/sharedModules/components/dialogs/eula-dialog/eula-dialog.component';
import {
  AuthenticationServiceProxy,
  UpdateEulaApprovalRequest,
  GetEulaApprovalRequest,
  ClientValidationRequest,
} from 'src/app/sharedModules/services/login/login.proxies';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public signInForm: FormGroup;
  submitted = false;
  filterFormGroup: FormGroup;
  password!: string;
  company!: string;
  email!: string;
  @Input()
  data: String;
  key: any;
  public updateEulaApprovalRequest: UpdateEulaApprovalRequest;
  public getEulaApprovalRequest: GetEulaApprovalRequest;
  public clientValidationRequest: ClientValidationRequest;
  isEulaApproved: boolean = false;
  public showPassword: boolean = false;
  isAdminLogin:boolean = false;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthenticationServiceProxy,
    private notifyService: NotificationService
  ) {}
  ngOnInit(): void {
    this.logout();
    localStorage.setItem('isAdminLogin', 'false');    
    this.signInForm = this.formBuilder.group({
      company: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.key = event.key;
  }

  forgotPassword() {
    if (this.key != 'Enter') {
      this.router.navigate(['/forgotPasswordEmail']);
    }
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  doLogin(data: any) {
    if (!this.signInForm.valid) {
      this.key = null;
      return;
    }
    this.password = data.password;
    this.company = data.company;
    this.email = data.email;

    this.confirmEulaApproved();
  }

  confirmEulaApproved() {
    this.getEulaApprovalRequest = new GetEulaApprovalRequest({
      company: this.company,
      email: this.email,
      isAdminLogin: this.isAdminLogin
    });

    this.authService
      .getEulaApprovalStatus(this.getEulaApprovalRequest)
      .subscribe(
        (res) => {
          if (res.code == 200 && res.data == 'Approved') {
            this.loginService.doSignIn(
              this.company,
              this.email,
              this.password,
              this.signInForm
            );
          } else if (res.code == 200 && res.data == 'UnApproved') {
            this.CheckLogin(
              this.company,
              this.email,
              this.password,
              this.signInForm
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.errorMessage);
        }
      );
  }

  updateEulaApprovalDateAndStatus() {
    this.updateEulaApprovalRequest = new UpdateEulaApprovalRequest({
      status: true,
      company: this.company,
      email: this.email,
      password: this.password,
      isAdminLogin: this.isAdminLogin
    });

    this.authService
      .updateEulaApprovalStatus(this.updateEulaApprovalRequest)
      .subscribe(
        (res) => {
          if (res.code == 201) {
            this.loginService.doSignIn(
              this.company,
              this.email,
              this.password,
              this.signInForm
            );
          }
        },
        (err) => {
          this.notifyService.showError(err.errorMessage);
        }
      );
  }

  OpenEulaDialog() {
    const dialogRef = this.dialog.open(EulaDialogComponent, {
      height: '550px',
      width: '700px',
      data: {
        buttonName: 'ACCEPT',
        actionButton: true,
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.updateEulaApprovalDateAndStatus();
      }
    });
  }

  CheckLogin(
    company: string,
    email: string,
    password: string,
    signInForm: FormGroup
  ) {
    this.clientValidationRequest = new ClientValidationRequest();
    this.clientValidationRequest.password = password;
    this.clientValidationRequest.clientId = company;
    this.clientValidationRequest.login = email;

    const messageLocked: string = 'Account is locked';
    const messageInvalid: string = 'Login or Password does not match';
    const messageCompanyIdInvalid: string = 'Invalid Client Id';
    const messageDeactivatedCompany: string = 'Deactivated Company';

    this.authService.getToken(this.clientValidationRequest).subscribe(
      (res) => {
        if (res.code == 200) {
          this.OpenEulaDialog();
        } else {
          if (res.data == messageInvalid) {
            signInForm.setErrors({ unauthenticated: true });
          } else if (res.data.toString().includes(messageLocked)) {
            this.notifyService.showError(res.data.toString());
          } else if (res.data.toString().includes(messageCompanyIdInvalid)) {
            this.notifyService.showError(
              'Invalid Company Id entered. Please try again.'
            );
          } else if (res.data.toString().includes(messageDeactivatedCompany)) {
            this.notifyService.showError(
              'This company is deactivated, Please contact to Administrator.'
            );
          }
        }
      },
      (error) => {
        this.notifyService.showError(error.errorMessage);
      }
    );
  }

  logout() {    
    if(!localStorage.getItem('accessToken')) return;    
    let userEmail = sessionStorage.getItem("userEmail");
    if (userEmail) {
      this.authService.doLogout({ "emailId": userEmail }).subscribe(
        (res) => {
          if (res.code == 200) {
            this.removeAccess();
          }
        },
        (error) => {
          this.removeAccess();
        });
    } else {
      this.removeAccess();
    }
  }

  removeAccess() {
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('validUser');
    localStorage.removeItem('userEmail');

    localStorage.removeItem('inventory');
    localStorage.removeItem('invoice');
    localStorage.removeItem('users');
    localStorage.removeItem('dashboard');
    localStorage.removeItem('reports');
    localStorage.removeItem('hostedLink');
    localStorage.removeItem('customers');
    localStorage.removeItem('instantBankAnalysis');
    localStorage.removeItem('statements');
    localStorage.removeItem('ticket');
    localStorage.removeItem('ach');
    localStorage.removeItem('cards');
    localStorage.removeItem('echeck');
    localStorage.removeItem('instantFund');
    localStorage.removeItem('callCompanyList');
    localStorage.removeItem('billing');

    this.isAdminLogin
      ? window.location.replace('csr')
      : window.location.replace('/');
    localStorage.removeItem('isAdminLogin');
  }
}
