import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, from, Observable, throwError } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from 'src/app/blockModules/services/login.service';
import { NotificationService } from '../services/notification/notification.service';
import { Router } from '@angular/router';
import { PaymentErrorConstants } from 'src/app/externalModules/paymentWidget/constants/payment-error.const';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  private timeUseExpired(lastTimeCallAPI: string) {
    const expiry = new Date(lastTimeCallAPI);
    return (
      Math.floor(
        new Date(new Date().toUTCString()).getTime() - 1000 * 60 * 15
      ) >= expiry.getTime()
    );
  }

  intercept(
    request: HttpRequest<any>,

    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          if (
            err.url.toLowerCase().includes('widgetinformation') ||
            err.url.toLowerCase().includes('lookupbin') ||
            err.url.toLowerCase().includes('sale') ||
            err.url.toLowerCase().includes('submitachpaymentwidget') ||
            err.url
              .toLowerCase()
              .includes('ExternalAchHostedPaymentPage/GetUpcomingHolidays')
          ) {
            var uniqueToken = localStorage.getItem('UniqueToken');
            localStorage.removeItem('UniqueToken');
            localStorage.removeItem(uniqueToken);

            this.router.navigate(['payment/failure/' + uniqueToken], {
              state: {
                errorMessage: PaymentErrorConstants.InvalidToken,
                paymentPageSettingData: err?.error?.paymentPageSettingData,
                logotext : err?.error?.clientName,
              },
            });
          } else {
            this.notificationService.showError(
              'Your current session times out. Please login again! '
            );
            localStorage.removeItem('userProfile');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('validUser');
            localStorage.removeItem('callCompanyList');
            localStorage.removeItem('billing');

            JSON.parse(localStorage.getItem('isAdminLogin')) ?? false
              ? window.location.replace('csr')
              : window.location.replace('/');
          }
        } else if (
          err.url.toLowerCase().includes('widgetinformation') ||
          err.url.toLowerCase().includes('sale') ||
          err.url.toLowerCase().includes('submitachpaymentwidget') ||
          err.url
            .toLowerCase()
            .includes('ExternalAchHostedPaymentPage/GetUpcomingHolidays')
        ) {
          var uniqueToken = localStorage.getItem('UniqueToken');
          localStorage.removeItem('UniqueToken');

          this.router.navigate(['payment/failure/' + uniqueToken], {
            state: {
              errorMessage: PaymentErrorConstants.InvalidToken,
              paymentPageSettingData: err?.error?.paymentPageSettingData,
            },
          });
        } else {
          return throwError(err);
        }
      })
    );
  }
  private async handleAccess(
    request: HttpRequest<any>,

    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    //NOTES: As Login is not yet tested and to keep XOR QA in process, Hardcoding the token. This is a temp change
    let hardCodedTempToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaHJ1dmFsQHRyYW5zY2VuZHBheS5jb20iLCJqdGkiOiIxNWY3MmI2Zi04MDNlLTQ5YWYtYjA5MC1lYmQ2ODU4MzRmZmQiLCJpZCI6IjE2MDhhZTY3LTU0MzktNGQ0OS1hNGVkLTgyNjNlZmY2MjE4MyIsImNsaWVudCI6IkZJTlpFTyIsInVzZXIiOiJkaHJ1dmFsQHRyYW5zY2VuZHBheS5jb20iLCJ1c2VyUm9sZSI6IjAiLCJuYmYiOjE2NTcyNTk0MzIsImV4cCI6MTY2MjQ0MzQzMiwiaWF0IjoxNjU3MjU5NDMyfQ.jgPR3jcTkqorNK4QVoqe3GaiaFD5QDu7bPNpDNwf0_4';
    var accessToken = localStorage.getItem('accessToken');
    var uniqueToken = localStorage.getItem('UniqueToken');
    var isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
    // var accessToken = hardCodedTempToken;

    if (sessionStorage.getItem('lastestCallAPI')) {
      if (
        this.timeUseExpired(sessionStorage.getItem('latestCallAPI')) &&
        !request.url.endsWith('GetUserProfile') &&
        !request.url.endsWith('SubmitForgetPassword') &&
        !request.url.endsWith('RequestForgetPassword')
      ) {
        isAdminLogin
          ? this.router.navigate(['/csr'])
          : this.router.navigate(['/']);

        localStorage.removeItem('validUser');
        localStorage.removeItem('userProfile');
        this.notificationService.showError(
          'Your current session times out. Please login again! '
        );
      }
    }
    if (accessToken) {
      // clone the incoming request and add JWT token in the cloned request's Authorization Header
      sessionStorage.setItem('latestCallAPI', new Date().toString());
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Encoding': 'deflate, gzip',
        },
      });
    }
    if (uniqueToken) {
      request = request.clone({
        setHeaders: {
          UniqueToken: uniqueToken,
          'Content-Encoding': 'deflate, gzip',
        },
      });
    }
    return next.handle(request).toPromise();
  }
}
