<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToIbvPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Instant Bank Analysis
  </mat-label>
</div>
<br />

<div class="add-page-header-spacing page-header-text">
  Create Instant Bank Analysis Request
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="40%">
    <img src="assets/grid-icons/female-hands.png" />
  </div>
  <div fxLayout="column" fxFlex="50%" style="background: #f5f5f5">
    <mat-label class="create-now">Create Now</mat-label>
    <mat-stepper
      orientation="vertical"
      [linear]="true"
      #stepper
      style="background: #f5f5f5"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="customerFormGroup">
        <form [formGroup]="customerFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CUSTOMER
              <span
                class="stepper-header-label"
                *ngIf="customerId && selectedCustomerName != ''"
              >
                : {{ selectedCustomerName }}
              </span>
            </span>
          </ng-template>

          <br />
          <mat-label class="inner-stepper-text">Select Customer</mat-label>

          <app-search-customer
            style="margin-top: 2%"
            (onCustomerSelectionEvent)="onCustomerSelection($event)"
            [onlyIndividual]="true"
          >
          </app-search-customer>

          <div class="inner-stepper-text">
            Continue with Bank account
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
              class="form-group"
              style="padding: 20px 0px 10px 0px"
            >
              <div fxLayout="column" fxLayoutWrap fxFlex="40%">
                <mat-radio-group
                  fxFlex="100%"
                  class="form-control"
                  formControlName="bankAccount"
                  name="bankAccount"
                  (change)="onRadioSelected($event)"
                  style="font-size: 12px"
                >
                  <mat-radio-button class="add-page-radio-button" [value]="true"
                    >Yes
                  </mat-radio-button>
                  <mat-radio-button
                    class="add-page-radio-button"
                    [value]="false"
                    >No
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div fxLayout="column" fxLayoutWrap fxFlex="60%">
                {{ bankNameRelatedMessage }}
              </div>
            </div>
          </div>

          <div style="padding-top: 10px">
            <button
              mat-flat-button
              class="page-header-button-style"
              (click)="customerContinue(stepper)"
            >
              CONTINUE
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="bankFormGroup">
        <form [formGroup]="bankFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              BANK ACCOUNT
              <span *ngIf="customerId && !customerFormGroup.value.bankAccount">
                : NOT AVAILABLE
              </span>
              <span
                *ngIf="
                  customerId &&
                  customerFormGroup.value.bankAccount &&
                  selectedBank?.bankName != ''
                "
              >
                : {{ selectedBank?.bankName }}</span
              >
            </span>
          </ng-template>
          <br />

          <div *ngIf="customerFormGroup?.value?.bankAccount">
            <mat-label class="inner-stepper-text"
              >Choose Bank Account</mat-label
            >
            <div *ngFor="let bank of bankList">
              <div class="bank-account">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutWrap
                  fxLayoutGap="16px"
                  fxLayoutAlign="start stretch"
                  style="padding: 20px"
                >
                  <div fxLayout="column" fxFlex="5%">
                    <mat-radio-group
                      (change)="onBankSelected($event, bank)"
                      fxFlex="100%"
                      class="form-control"
                      name="bankSelection"
                    >
                      <mat-radio-button
                        class="add-page-radio-button"
                        [value]="true"
                      >
                      </mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <div
                    fxLayout="column"
                    fxFlex="80%"
                    fxLayoutWrap
                    fxLayoutGap="10px"
                  >
                    <div fxLayout="row">
                      <span class="ellip" matTooltip="{{ bank.bankName }}">
                        {{ bank.bankName }}
                      </span>
                    </div>
                    <div fxLayout="row">
                      {{ bankEncreption(bank.bankAccountNumber) }}
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="15%">
                    <div style="margin-top: 8px">
                      <img
                        src="assets/grid-icons/dummy-bank.png"
                        height="28px"
                        width="28px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
            &nbsp;&nbsp;
            <button mat-button matStepperPrevious class="white-button-style">
              BACK
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="employerFormGroup">
        <form [formGroup]="employerFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              EMPLOYER
              <span
                *ngIf="
                  employerFormGroup.controls['employerSubmitted'].value == ''
                "
              >
                : NOT AVAILABLE
              </span>
              <span
                *ngIf="
                  employerFormGroup.controls['employerSubmitted'].value != ''
                "
              >
                :
                {{
                  employerFormGroup.controls["employerSubmitted"].value
                }}</span
              >
            </span>
          </ng-template>
          <br />

          <mat-label class="inner-stepper-text">Employer Name</mat-label>
          <div fxLayout="column" fxLayoutWrap>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="employerSubmitted"
                class="form-control"
                name="employerSubmitted"
                placeholder="Employer Name"
                autocomplete="off"
                maxlength="30"
              />
              <mat-error>
                {{
                  employerFormGroup.controls["employerSubmitted"][
                    "_errorMessage"
                  ]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
            &nbsp;&nbsp;
            <button mat-button matStepperPrevious class="white-button-style">
              BACK
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="finalFormGroup">
        <form [formGroup]="finalFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <mat-label class="stepper-header-label"
              >SEND VERIFICATION VIA</mat-label
            >
          </ng-template>

          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
            class="form-group"
            style="padding: 20px 0px 10px 0px"
          >
            <mat-radio-group
              fxFlex="100%"
              class="form-control"
              formControlName="verification"
              name="verification"
            >
              <mat-radio-button class="add-page-radio-button" [value]="1"
                >SMS
              </mat-radio-button>
              <mat-radio-button class="add-page-radio-button" [value]="2"
                >Email
              </mat-radio-button>
              <mat-radio-button class="add-page-radio-button" [value]="3"
                >Both
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <br />
        </form>
        <div style="padding-top: 10px">
          <button
            mat-stroked-button
            matStepperNext
            class="page-header-button-style"
            (click)="sendVerification()"
          >
            SEND
          </button>
          &nbsp;&nbsp;
          <button mat-button matStepperPrevious class="white-button-style">
            BACK
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div fxLayout="column" fxFlex="10%" style="background: #f5f5f5"></div>
</div>
