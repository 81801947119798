<!-- <mat-toolbar class="header-toolbar" [style.--active-bg-color]="pageSettingModel?.themeColor">
  <div class="w-100" fxLayout="row" fxLayout.xs="row" fxLayoutAlign="center center">
    <img [src]="pageSettingModel?.logo" alt="logo" class="header-success-logo" />
  </div>
</mat-toolbar>
<br /> -->

<div class="order-success-container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch"
  [style.--active-fontFamily]="pageSettingModel?.themeFont">
  <div class="w-100" fxLayout="column" fxLayoutGap="4%" fxLayoutAlign="start stretch">
    <div fxLayout="row" [style.--active-bg-color]="pageSettingModel?.themeColor" class="payment-success-logo-dv">
      <img class="payment-success-logo" [src]="pageSettingModel?.logo" alt="image" />
    </div>

    <div class="margin-success-text" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="2%"
      fxLayoutAlign="center center">
      <!-- <div fxLayout="column">
        <img class="successIcons" [src]="paymentIcon" alt="sucess-icon" />
      </div> -->
      <div fxLayout="column">
        <div [class]="paymentClass">
          {{ paymentMessage }}
        </div>
      </div>
    </div>

    <div *ngIf="isPaymentSuccessful">
      <mat-divider class="margin-mat-start"></mat-divider>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
        *ngIf="displayFields(pageSettingModel?.isInvoiceNumberView)">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Confirmation Number </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            {{ invoiceNumber }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName"> Payment Method </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            {{ paymentMethod }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
        *ngIf="transactionId">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName"> Transaction Id </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            {{ transactionId }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Transaction Date </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            {{ transactionDate | date : "dd MMM YYYY hh:mm a" }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Amount </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            ${{ amount | number : "1.2-2" }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
        *ngIf="this.agreesToSurcharge && this.surchargePercentage !== 0">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Surcharge Fees ({{ surchargePercentage ?? 0 }}%)</mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            ${{ surchargeFee ?? 0 | number : "1.2-2" }}
          </mat-label>
        </div>
      </div>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center"
        *ngIf="agreesToConvenienceFees">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Convenience Fees</mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsValue">
            ${{ convenienceFees ?? 0 | number : "1.2-2" }}
          </mat-label>
        </div>
      </div>

      <mat-divider class="margin-mat-end"></mat-divider>

      <div fxLayout="row" fxLayoutWrap class="margin-details-text" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="showDetailsName">Total </mat-label>
        </div>
        <div fxLayout="column" fxLayoutWrap>
          <mat-label class="font-bold showDetailsValue">
            ${{ totalAmount ?? 0 | number : "1.2-2" }}
          </mat-label>
        </div>
      </div>

      <div class="mar-b-0" fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
        <span class="circle-draw circle-draw-left"></span>
        <hr class="circle-mat-divder" />
        <span class="circle-draw circle-draw-right"></span>
      </div>

      <div *ngIf="displayFields(pageSettingModel?.isDescriptionView)" fxLayout="row" fxLayout.xs="column" fxLayoutWrap
        fxLayoutGap="10px" class="margin-details-text">
        <div class="description-text">{{ description }}</div>
      </div>
    </div>
   
    <div fxLayout="row" fxFlex="50%" fxLayoutWrap class="payment-footer-dv" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutWrap fxLayoutGap="2%" fxLayoutAlign="center center" class="payment-footer-div-col">
      <img  class="payment-success-logo safetySecurity-logo" src="/assets/payment-widget-icons/securePayment3.png" alt="secure-payment logo" />
          <label style="text-align:center"> Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <br />
</div>