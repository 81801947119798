import { AbstractControl } from '@angular/forms';
import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
} from '@rxweb/reactive-form-validators';

export class CreateCustomerGroupModel {


  @required()
  @maxLength({ value: 50 })
  @minLength({ value: 3 })
  // @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ]*$/ } })
  @pattern({ expression: { Alpha_Numbers: /^[a-zA-Z0-9 ,()\-\*#]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  GroupName: string;

}
