<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>
<form [formGroup]="invoiceProductFormGroup">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div class="invoice-gererate-form">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start center"
        class="invoice-header">
        <div fxLayout="column" fxLayout.xs="column" fxFlex="30%" fxLayoutGap="2%" fxLayoutAlign="start stretch ">         
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3%" fxLayoutAlign="start stretch">
            <mat-label class="label">DATE'S :</mat-label>
            <mat-label class="label-value">{{ startDate | date : "MM/dd/yyyy" }} -
              {{ endDate | date : "MM/dd/yyyy" }}</mat-label>
          </div>
        </div>
        <div fxLayout="column" fxFlex="30%" class="invoice-text">INVOICE</div>
        <div fxLayout="column" fxFlex="30%" class="invoice-text"></div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="4.5%" fxLayoutAlign="start stretch"
        class="invoice-middle">
        <div fxLayout="column" fxFlex="20%">
          <img *ngIf="invoiceMainModel.clientLogo" src="{{ invoiceMainModel.clientLogo }}" class="logo"
            alt="clientlogo image.svg" title="sample clientlogo image" />
          <img *ngIf="!invoiceMainModel.clientLogo" src="{{ getIconSrc(clientlogo_const) }}" class="logo"
            alt="clientlogo image.png" title="No Logo Available" />
        </div>
        <div fxLayout="column" fxFlex="40%" class="logo-right-text" fxLayoutGap="1%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1.5%" fxLayoutAlign="start center">
            <img matTooltip="Client Address" src="{{ getLogoSrc(location_const) }}" />
            <mat-chip-list aria-label="Client Address">
              <mat-chip class="chip-list">
                {{ invoiceMainModel.address }}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1.5%" fxLayoutAlign="start center">
            <img matTooltip="Client Email" src="{{ getLogoSrc(email_const) }}" />
            <mat-chip-list aria-label="Client Email">
              <mat-chip class="chip-list">
                {{ invoiceMainModel.email }}
              </mat-chip>
            </mat-chip-list>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1.5%" fxLayoutAlign="start center">
            <img matTooltip="Client Phone" src="{{ getLogoSrc(mobile_const) }}" />
            <mat-chip-list aria-label="Client Phone">
              <mat-chip class="chip-list">
                {{ invoiceMainModel.phone }}
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <div fxLayout="column" fxFlex="30%" fxLayoutAlign="start end">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="5%" fxLayoutAlign="start end">
            <mat-label class="label">DATE: </mat-label>
            <mat-label class="label-value">
              {{ today | date : "MM/dd/yyyy" }}</mat-label>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" class="customerAddress">
        <mat-label>BILL TO: {{ invoiceMainModel.customerName }} ,
          {{ invoiceMainModel.customerBillToAddress }}</mat-label>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="1%">
        <div class="dynamic-invoice-gererate-form" fxLayout="column" fxLayout.xs="column" fxLayoutGap="1%">
          <div class="dynamic-invoice-header" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%"
            fxLayoutAlign="start center">
            <div fxLayout="column" fxFlex="22%">ITEM NAME</div>
            <div fxLayout="column" fxFlex="16%">SKU</div>
            <div fxLayout="column" fxFlex="9%">QUANTITY</div>
            <div fxLayout="column" fxFlex="9%">UNIT PRICE</div>            
            <div fxLayout="column" fxFlex="9%">SALES TAX</div>
            <div fxLayout="column" fxFlex="7%">DISCOUNT</div>
            <div fxLayout="column" fxFlex="5%"></div>
            <div fxLayout="column" fxFlex="9%">TOTAL PRICE</div>
            <div fxLayout="column" fxFlex="6%" fxLayoutAlign="center center">
              <div fxLayout="column" fxLayoutGap="2px">
                <img class="addImg" matTooltip="Add Item" src="{{ getIconSrc(add_const) }}" (click)="addProduct()" />
              </div>
            </div>
          </div>

          <mat-divider class="margin-decay"></mat-divider>

          <ng-container formArrayName="invoiceProductModelList" *ngFor="
              let products of invoiceProductFormGroup.get(
                'invoiceProductModelList'
              )['controls'];
              let i = index
            ">
            <ng-container [formGroupName]="i">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1%" fxLayoutAlign="start stretch" class="margin-decay">
                <div fxLayout="column" fxFlex="22%" fxLayoutAlign="center start">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput placeholder="Item Name" formControlName="productName" class="form-control"
                      name="productName" (input)="searchProduct(i)" (focus)="checkProduct(i)"  style="padding-bottom: 1%;"
                      [matAutocomplete]="auto"
                      matTooltipClass="custom-tooltip"
                      [readonly]="isItemSelected"
                      [matTooltip]="getErrorMessage('productName')"                      
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.productName.valid'/>
                      <button *ngIf="this.invoiceProductFormGroup.controls['invoiceProductModelList']['controls'][i]['controls'].productName.value"
                          matSuffix mat-icon-button aria-label="Clear" (click)="clearSelection(i)" >
                      <mat-icon>close</mat-icon>
                      </button>
                      <button matSuffix mat-icon-button>
                        <mat-icon>search</mat-icon>
                      </button>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event,i)">
                        <mat-option *ngIf="isFilterLoading" class="is-loading">Loading...</mat-option>
                        <ng-container *ngIf="!this.isFilterLoading && this.filteredProduct.length > 0">
                          <mat-option *ngFor="let product of filteredProduct" [value]="product" >
                              <div class="itemName-style">
                              {{ product.itemName?.trim() }}
                              </div>
                              <div class="sub-text">
                                Category: {{ product.categoryName }}  
                              </div>
                              <div class="sub-text">
                                Avl.Quant: {{ product.availableQuantity }}
                              </div>
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="isShowNoFound && !isFilterLoading && !filteredProduct.length">
                          <mat-option>
                              <div class="itemName-style">
                              <b>No Records Found</b>
                              </div>
                          </mat-option>
                        </ng-container>
                    </mat-autocomplete> 
                  </mat-form-field>
                </div>                
                <div fxLayout="column" fxFlex="16%" fxLayoutAlign="center start">                  
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput placeholder="Item SKU (11 Char)" formControlName="sku" class="form-control"
                      name="sku" (input)="searchSkuNumber(i)" (focus)="checkProduct(i)"  style="padding-bottom: 1%;"
                      [matAutocomplete]="autoSku"
                      [readonly]="isSkuSelected"
                      matTooltipClass="custom-tooltip"
                      [matTooltip]="getErrorMessage('sku')"
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.sku.valid'/>
                      <button *ngIf="this.invoiceProductFormGroup.controls['invoiceProductModelList']['controls'][i]['controls'].sku.value"
                          matSuffix mat-icon-button aria-label="Clear" (click)="clearSelectedSku(i)" >
                      <mat-icon>close</mat-icon>
                      </button>
                      <button matSuffix mat-icon-button>
                        <mat-icon>search</mat-icon>
                      </button>
                    <mat-autocomplete #autoSku="matAutocomplete" (optionSelected)="onSkuSelected($event,i)" >
                        <mat-option *ngIf="isSkuFilterLoading" class="is-loading">Loading...</mat-option>
                        <ng-container *ngIf="!this.isSkuFilterLoading && this.filteredSkuRecord.length > 0">
                          <mat-option *ngFor="let product of filteredSkuRecord" [value]="product" >
                              <div class="itemName-style" matTooltip="{{ product.sku?.trim() }} | {{ product.categoryName }} | {{ product.availableQuantity }}">
                              {{ product.sku?.trim() }} &nbsp;  
                              </div>                              
                              <div class="sub-text">
                              Category: {{ product.categoryName }} 
                              </div>
                              <div class="sub-text">
                              Avl.Quant: {{ product.availableQuantity }}
                              </div>
                          </mat-option>
                        </ng-container>
                        <ng-container *ngIf="isShowNoFoundSku && !isSkuFilterLoading && !filteredSkuRecord.length">
                          <mat-option>
                              <div class="name-email-style">
                              <b>No Records Found</b>
                              </div>
                          </mat-option>
                        </ng-container>
                    </mat-autocomplete> 
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="9%" fxLayoutAlign="center end">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput placeholder="Quantity" formControlName="quantity" class="form-control"
                      name="quantity" appTwoDigitDecimalNumber (change)="setQuantity($event,i)"  (blur)="checkNumberFormater(i)"
                      autocomplete="off" maxlength="6"
                      matTooltipClass="custom-tooltip"
                      [matTooltip]="getErrorMessage('quantity')"
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.quantity.valid'/>
                  </mat-form-field>
                </div>                
                <div fxLayout="column" fxFlex="9%" fxLayoutAlign="center end">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput placeholder="Unit Price" formControlName="basePrice"
                      class="form-control doller-alignment" name="basePrice" appTwoDigitDecimalNumber
                      matTooltipClass="custom-tooltip"
                      [matTooltip]="getErrorMessage('basePrice')"
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.basePrice.valid'
                      (input)="setBasePrice($event,i)" (blur)="checkNumberFormaterbasep(i)" autocomplete="off" maxlength="6" 
                      />
                    <span matPrefix class="material-icons">attach_money</span>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="9%" fxLayoutAlign="center end">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput  formControlName="salesTax" placeholder="SalesTax"
                      class="form-control doller-alignment" name="salesTax" 
                      matTooltipClass="custom-tooltip"
                      [matTooltip]="getErrorMessage('salesTax')"
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.salesTax.valid'(input)="setSalesTax($event,i)" (blur)="checkNumberFormaterSalesTax(i)"/>
                    <span  matSuffix class="material-icons">percent</span>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="7%" fxLayoutAlign="center end"> 
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput  formControlName="discount" placeholder="Discount"
                      class="form-control doller-alignment" name="discount" 
                      matTooltipClass="custom-tooltip"
                      [matTooltip]="getErrorMessage('discount')"
                      [matTooltipDisabled]='invoiceProductFormGroup.controls[
                      "invoiceProductModelList"
                      ]["controls"][i].controls.discount.valid'(blur)="checkNumberFormaterDiscount(i)" (input)="setDiscountPercent($event,i)"/>                                         
                  </mat-form-field>
                </div>              
                <div fxLayout="column" fxFlex="5%" fxLayoutAlign="center end">                                       
                      <mat-form-field appearance="outline" class="form-field-search">
                        <mat-select class="form-control" placeholder="%"
                          formControlName="discountType" name="discountType"                                                
                          (selectionChange)="selectDiscountType($event,i)">
                          <mat-option *ngFor="let discount of discounttype" [value]="discount.key">
                            {{ discount.value }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="9%" fxLayoutAlign="center end">
                  <mat-form-field appearance="outline" class="form-field-search">
                    <input matInput placeholder="Total Price" formControlName="totalPrice"                   
                      class="form-control doller-alignment" name="totalPrice" (change)="setTotalPrice(i)" readonly />
                    <span matPrefix class="material-icons disable-dollar">attach_money</span>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="6%" fxLayoutAlign="center center">
                  <img class="deleteImg" matTooltip="Remove Item" alt="deleteimg"
                    src="{{ getIconSrc(delete_const) }}" (click)="deleteProduct(i)" />
                </div>
              </div>

              <mat-divider class="margin-decay"></mat-divider>
            </ng-container>
          </ng-container>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" class="margin-decay">
            <div fxLayout="column" fxLayout.xs="column" fxFlex="30%" fxLayoutGap="2%" fxLayoutAlign="start stretch "
              class="margin-add">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="3%" fxLayoutAlign="start center "
                class="recurringsection">
                <mat-slide-toggle formControlName="isRecurring" name="isRecurring" class="form-control"
                  (change)="onIsRecurringSelected($event)">
                </mat-slide-toggle>
                <mat-label name="transactionFrequency">RECURRING
                  {{ invoiceMainModel.transactionFrequency }}</mat-label>
              </div>
              <div *ngIf="isRecurringEnabled" fxLayout="row" fxLayout.xs="column" fxLayoutGap="3%"
                fxLayoutAlign="start center ">
                <mat-label class="label-value">Recurring Count</mat-label>
                <mat-form-field appearance="outline" fxFlex="20%" class="form-field-search">
                  <mat-label>Count</mat-label>
                  <input matInput formControlName="recurringCount" class="form-control" name="recurringCount"
                    appTwoDigitDecimalNumber (blur)="checkCount($event);checkNumberFormaterOther($event)" />
                </mat-form-field>
                <mat-error>
                  {{ invoiceProductFormGroup.controls["recurringCount"]["_errorMessage"] }}
                </mat-error>
              </div>
            </div>
          </div>

          <mat-divider></mat-divider>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch" fxLayoutGap="5%">
            <div fxLayout="column" fxLayout.xs="row" fxFlex="60%" fxLayoutAlign="start stretch"></div>
            <div fxLayout="column" fxLayout.xs="row" fxFlex="35%" fxLayoutWrap fxLayoutGap="5%"
              fxLayoutAlign="start stretch" class="totalsection">              
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4%" class="align-right"
                fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="48%" class="totalinfoRightSection">
                  <mat-label>SUB-TOTAL :</mat-label>
                </div>                
                <div fxLayout="column" fxFlex="48%" fxLayoutWrap>
                  <mat-label name="subTotalAmount">$
                    {{
                    invoiceMainModel.subTotalAmount | number : "1.2-2"
                    }}</mat-label>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4%" class="align-right"
                fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="48%" class="totalinfoRightSection">
                  <mat-label>TOTAL SALES-TAX :</mat-label>
                </div>
                <!-- <div fxLayout="column" fxFlex="20%">
                  <mat-label name="salesTax">{{
                    invoiceMainModel.appliedServiceTaxPercentage
                    | number : "1.2-2"
                    }}
                    %</mat-label>
                </div> -->
                <div fxLayout="column" fxFlex="48%">
                  <mat-label name="salesTaxAmount">$
                    {{
                    invoiceMainModel.serviceTaxAmount | number : "1.2-2"
                    }}</mat-label>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4%" class="align-right"
                fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="48%" class="totalinfoRightSection">
                  <mat-label>TOTAL DISCOUNT :</mat-label>
                </div>                
                <div fxLayout="column" fxFlex="48%" fxLayoutWrap>
                  <mat-label name="appliedDiscountAmount">$
                    {{
                    invoiceMainModel.appliedDiscountAmount | number : "1.2-2"
                    }}</mat-label>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="4%" class="totalinfoEndSection"
                fxLayoutAlign="start center">
                <div fxLayout="column" fxFlex="48%">
                  <mat-label>TOTAL :</mat-label>
                </div>
                <div fxLayout="column" fxFlex="48%">
                  <mat-label name="totalAmount">$
                    {{
                    invoiceMainModel.totalAmount | number : "1.2-2"
                    }}</mat-label>
                </div>
              </div>
            </div>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center center" class="notesection">
            {{ invoiceMainModel.returnPolicyText }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" class="sendanddownload">
      <!--  [disabled]="!invoiceProductFormGroup.valid" -->
      <button   mat-raised-button color="primary" class="page-header-button-style" (click)="sendInvoice()">
        SEND INVOICE
      </button>
    </div>
  </div>
</form>