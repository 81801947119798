<mat-card class="finzeo-grade-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="finzeo-grade-card" fxLayout="column" fxFlex="100%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin-bottom: 1%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/finzeoGrade.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap fxLayoutAlign="start start">
        <span class="section-header">Grade</span>
      </div>

      <span class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="end start">
        <span class="grade-style">{{ finzeoGradeDetails?.grade ?? "F" }}</span>
      </div>

    </div>
    <div fxLayoutAlign="center center">
      <grade-score-gauge-chart [model]="gradeScoreChartModel"></grade-score-gauge-chart>
    </div>
    
  </mat-card>
</div>
