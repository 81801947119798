import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { AuthGuard } from 'src/app/sharedModules/guards/auth.guard';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { EditUserComponent } from 'src/app/featureModules/components/user-management/edit-user/edit-user.component';
import { AddUserComponent } from 'src/app/featureModules/components/user-management/add-user/add-user.component';

const routes: Routes = [
  { path: 'add-user', component: AddUserComponent, pathMatch: 'full',canActivate:[AuthGuard] },
  { path: 'finzeoUsers', component: UsersComponent, pathMatch: 'full',canActivate:[AuthGuard] },
  { path: 'edit-user/:userId', component: EditUserComponent, pathMatch: 'full',canActivate:[AuthGuard] },
  { path: 'user-profile', component: UserProfileComponent, pathMatch: 'full',canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
