<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"> </app-loading-spinner>

<div class="page-header-bottom">
    <div class="backHeader">
        <mat-label>
          <button mat-icon-button (click)="navigateToBackPage()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          Inventory Category Management
        </mat-label>
      </div>
    <div class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
                <div class="page-header-text" *ngIf="!isEdit">Add Category</div>
                <div class="page-header-text" *ngIf="isEdit">Edit Category</div>
                <div class="page-sub-header-text-1">
                    All Invoice Inventory Management related data can be managed from this page
                </div>
            </div>
        </div>
    </div>
</div>


<div fxLayout="row" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxFlex="100%">
        <div class="form-AddUserv" class="mainDiv">
            <div class="info-AddUser">
                <div *ngIf="!isEdit">
                    <p class="title">Add Category Details</p>
                </div>
                <div *ngIf="isEdit">
                    <p class="title">Update Category Details</p>
                </div>

                <form [formGroup]="addCategoryFormGroup" autocomplete="off">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap
                            fxLayoutAlign="start stretch">
                            <div fxFlex="30%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Category Name</mat-label>
                                    <input type="text" matInput formControlName="categoryName">
                                    <mat-error>
                                        {{ addCategoryFormGroup.controls["categoryName"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>SalesTax</mat-label>
                                    <input type="text" matInput formControlName="salesTax" appTwoDigitDecimalNumber>
                                    <mat-error>
                                        {{ addCategoryFormGroup.controls["salesTax"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="22%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Discount</mat-label>
                                    <input type="text" matInput formControlName="discount" appTwoDigitDecimalNumber maxlength="4">
                                    <mat-error>
                                        {{ addCategoryFormGroup.controls["discount"]["_errorMessage"] }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="1%"></div>
                            <div fxFlex="9%">
                                <mat-form-field appearance="outline" class="form-field-search">
                                    <mat-select class="form-control" placeholder="Percent"
                                    formControlName="discountType" name="discountType"                                                
                                    (selectionChange)="selectDiscountType($event)">
                                    <mat-option *ngFor="let discount of discounttype" [value]="discount.key">
                                        {{ discount.value }}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    &nbsp;
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch">
                        <div fxLayout="row" fxFlex="100%" fxLayout.xs="column" fxLayoutWrap
                            fxLayoutAlign="start stretch">
                            <div fxFlex="22%">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="10%">
                                    <div fxLayout="column"  >
                                      Status
                                    </div>
                                    <div fxLayout="column"  >
                                      <mat-slide-toggle formControlName="isActive" name="isActive" class="form-control">
                                        <mat-label
                                        class="showDetailsValue"
                                        [ngStyle]="{
                                          ' color': addCategoryFormGroup.controls['isActive'].value
                                            ? 'green'
                                            : 'red'
                                        }"
                                            >
                                        {{
                                            addCategoryFormGroup.controls["isActive"].value
                                            ? "Active"
                                            : "Inactive"
                                        }}
                                      </mat-label>
                                      </mat-slide-toggle>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div fxLayout="row" fxLayoutAlign="start stretch" style="float: left;">
                <div style="padding-top: 20px; float: right;">
                    <button mat-button class="white-button-style" (click)="navigateToBackPage()">CANCEL</button>
                    &nbsp;&nbsp;
                    <button *ngIf="!isEdit" mat-raised-button  color="primary" class="page-header-button-style" (click)="addInventoryCategory()">ADD</button>                    
                    <button *ngIf="isEdit" mat-raised-button  color="primary" class="page-header-button-style" (click)="updateInventoryCategory()">UPDATE</button>
                    &nbsp;&nbsp;
                </div>
            </div>
        </div>
    </div>

</div>
