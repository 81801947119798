import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddCustomerBankDetailsModel } from 'src/app/sharedModules/models/add-customer-bank-details.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerBankDetailsService extends CrudService<
  AddCustomerBankDetailsModel,
  any
> {
  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}CustomerBankAccounts`);
  }

  AddCustomerBankDetails(model: AddCustomerBankDetailsModel): Observable<any> {
    return this._http.post<any>(this._base + '/addCustomerBankAccount', model);
  }

  updateCustomerBank(
    customerBankAccountId: any,
    model: AddCustomerBankDetailsModel
  ): Observable<any> {
    return this._http.put<any>(
      this._base + '/updateCustomerBankAccount/' + customerBankAccountId,
      model
    );
  }

  GetAllBankAccounts(
    customerID: string,
    onlyActive: boolean = false
  ): Observable<any> {
    return this._http.get<any>(
      this._base + '/getAllBankAccounts/' + customerID + '/' + onlyActive
    );
  }

  deactivateCustomerBankAccount(
    customerBankAccountId: any,
    status: boolean
  ): Observable<any> {
    return this._http.put<any>(
      this._base +
        '/deactivateCustomerBankAccount/' +
        customerBankAccountId +
        '/' +
        status,
      null
    );
  }
}
