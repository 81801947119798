import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from "rxjs";
import { SubmitReviewDialogComponent } from 'src/app/sharedModules/components/dialogs/submit-review-dialog/submit-review-dialog.component';
import { SubmitReviewModel } from 'src/app/sharedModules/models/submit-review.model';
import { CrudService } from "src/app/sharedModules/services/http/crud.service";
import { environment } from "src/environments/environment";
import { CreateTicketModel } from '../models/create-ticket.model';
import { UpdateTicketModel } from '../models/update-ticket.model';

@Injectable({
    providedIn: 'root',
  })

  export class TicketService extends CrudService<any, any> {
    dialogRef : MatDialogRef<SubmitReviewDialogComponent, any>;
    
    constructor(protected override _http: HttpClient) {
      super(_http, `${environment.API_BASE_URL_CUSTOMER}Ticket`);
    }

    createTicket(model: CreateTicketModel): Observable<any> {
      return this._http.post<any>(this._base + "/createTicket", model);
    }

    updateTicket(ticketId:any,model: UpdateTicketModel): Observable<any> {
      return this._http.put<any>(this._base + "/updateTicket/"+ ticketId,model);
    }
  
    submitReview(ticketId:any ,model: SubmitReviewModel): Observable<any> {
      return this._http.put<any>(this._base + "/submiReview/" + ticketId,model);
    }

    withdrawTicket(ticketId: any): Observable<any> {
      return this._http.put<any>(this._base + "/withdrawTicket/" + ticketId ,null);
    }

    reOpenTicket(ticketId: any): Observable<any> {
      return this._http.put<any>(this._base + "/reopenTicket/" + ticketId ,null);
    }

    getTicketDetails(ticketId:any): Observable<any> {
      return this._http.get<any>(this._base + "/getTicketDetailsById/" +ticketId);
    }

    getAllTickets(filterBy: string, sortBy: string, sortDirection: string,
      page: number, pageSize: number, advanceFilters: string): Observable<any> {
      let baseUrl = this._base + "/getAllTickets?sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;
      baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;
      baseUrl = sortBy ? baseUrl + "&sortBy=" + sortBy : baseUrl;      
      baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
      return this._http.get<any>(baseUrl);
    }   

}
