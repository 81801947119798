import { required, prop, maxLength, pattern, maxNumber, minNumber } from '@rxweb/reactive-form-validators';

export class InstantFundTransactionModel {
  @prop()
  customerId: string;

  @prop()
  @required()
  @minNumber({ value: 0.01 })  
  @pattern({ expression: { 'number_with_2_decimal_places': /^\d+\.\d{2}$/ } })
  amount: number;
}
