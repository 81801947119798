import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LeftSideNavComponent } from 'src/app/sharedModules/components/left-side-nav/left-side-nav.component';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import moment from 'moment';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { StatementService } from 'src/app/featureModules/services/statement.service';
import { ListFilter } from '../statement-history/statement-history.component';
import { Utils } from 'src/app/featureModules/helper/utils';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-current-statement',
  templateUrl: './current-statement.component.html',
  styleUrls: ['./current-statement.component.scss'],
  providers: [DecimalPipe]
})
export class CurrentStatementComponent implements OnInit {

  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;
  @Input()
  tabIndex: number;
  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  filterText: string = '';
  userName: string = null;
  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 5,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'UpdateDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'ID', name: 'statementPageId', width: '5%' },
    { displayName: 'EFFECTIVE DATE', name: 'statementEffectiveDate', width: '14%' },
    { displayName: 'TOTAL DEBITS', name: 'statementTotalDebits', width: '14%' },
    { displayName: 'TOTAL CREDITS', name: 'statementTotalCredits', width: '15%' },
    { displayName: 'TOTAL RETURNS', name: 'statementTotalReturns', width: '15%' },
    { displayName: 'DAILY NET', name: 'statementDailyNet', width: '14%' }, 
    { displayName: 'ENDING BALANCE', name: 'statementEndingBalance', width: '15%' }, 
    { displayName: 'PENDING DEBITS', name: 'statementPendingDebits', width: '15%' }, 
    { displayName: 'AVAILABLE BALANCE', name: 'statementAvaiableBalance', width: '15%' },
  ];
  		 
  allColumns: TableColumn[];

  customerModel: AddCustomerModel;
  userModel: addUserModel;
  userId: any;
  response: {
    companyName: string;
    accountNumber: string;
    routingNumber: string;
    endingAccountBalance: number;
    pendingDebits: number;
    pendingCredits: number;
    availableBalance: number;
    endDate: string;
    galileoPrn: string;
    lastEffectiveDate: string
  }
  firstName: string;
  statementId: string;
  fromDate;
  toDate;
  constructor(
    private router: Router,
    private leftNav: LeftSideNavComponent,
    private _toastrService: ToastrService,
    private statementService: StatementService,
    private activatedRoute: ActivatedRoute,
    private _utils: Utils,
    private decimalPipe: DecimalPipe
  ) {
  }

  ngOnInit(): void {
    this.userModel = new addUserModel();
    this.response = {
      accountNumber: '',
      availableBalance: 0,
      companyName: '',
      routingNumber: '',
      pendingDebits: 0,
      pendingCredits: 0,
      endingAccountBalance: 0,
      endDate: '',
      galileoPrn: '',
      lastEffectiveDate: ''
    }
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userModel.firstName = userProfile['firstName'];
    this.userModel.lastName = userProfile['lastName'];
    var today = new Date();
    this.fromDate = moment(today).subtract(31, 'days').format('MMM DD, yyyy');
    this.toDate = moment(today).format('MMM DD, yyyy');
    this.loadData(
      null,
      null,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }


  loadData(
    userName: string,
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    this.isLoading = true;
    this.selection.clear();
    this.statementService
      .getCurrentStatement(this.fromDate,this.toDate)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.data != null) {
            this.page.length = res.data.totalItems;
            var list = [];
            this.response.accountNumber = res.data.accountNumber;
            this.response.availableBalance = res.data.availableBalance;
            this.response.routingNumber = res.data.routingNumber;
            this.response.galileoPrn = res.data.galileoPrn;
            this.response.endingAccountBalance = res.data.endingBalance;
            this.response.pendingDebits = res.data.pendingDebits;
            this.response.pendingCredits = res.data.pendingCredits;
            this.response.companyName = res.data.companyName;
            this.response.endDate = this._utils.removeZ(res.data.endDate).toString();
            this.response.lastEffectiveDate = this._utils.removeZ(res.data.endDate).toString();

            res.data?.transactions.map((item) => {
              let record = { 
                statementPageId: item.id,
                statementEffectiveDate: item.effectiveDate,
                statementTotalDebits: item.totalDebits,
                statementTotalCredits: item.totalCredits,
                statementTotalReturns: item.totalReturns,
                statementDailyNet: item.dailyNet,
                statementEndingBalance: item.endingBalance,                  
                statementPendingDebits: item.pendingDebits,                 
                statementAvaiableBalance: item.availableBalance, 
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = []
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error('Something went wrong, Please contact administrator!');
        }
      );
  }
  onPrintClicked() {
    this.statementService.generateCurrentStatementPDF(
      this.fromDate,
      this.toDate)
  }
  onPreviewClicked() {
    this.statementService.PreviewCurrentPDF(this.fromDate,
      this.toDate)
  }

  dataShow(data: any) {
    if (!data) {
      return '-';
    }
    const formattedData = this.decimalPipe.transform(Math.abs(data), '1.2-2');
    return data < 0 ? `($${formattedData})` : `$${formattedData}`;
  }
}
