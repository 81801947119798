import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { ListFilter } from '../../instant-verify-related/customer-refresh-details/customer-refresh-details.component';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-ach-transaction-report',
  templateUrl: './dashboard-ach-transaction-report.component.html',
  styleUrls: ['./dashboard-ach-transaction-report.component.scss'],
})
export class DashboardAchTransactionReportComponent
  implements OnInit, OnChanges, OnDestroy
{
  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  @Input()
  noRecordMessage: string;

  @Input()
  period: string;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'TODAY’S BATCH', name: 'totalBatch', width: '20%' },
    {
      displayName: 'TRANSACTIONS TOTAL',
      name: 'transactionTotal',
      width: '20%',
    },
    { displayName: 'CREDIT', name: 'credit', width: '10%' },
    { displayName: 'TOTAL CREDIT', name: 'totalCredit', width: '20%' },
    { displayName: 'DEBIT', name: 'debit', width: '10%' },
    { displayName: 'TOTAL DEBIT', name: 'totalDebit', width: '10%' },
    { displayName: 'TIME REMAINING', name: 'timeRemaining', width: '10%' },
  ];

  allColumns: TableColumn[];

  transactionType = 'ach';

  constructor(private _commonService: CommonService, private _router: Router) {
    this.allColumns = this.columns;

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'dashboardAchGridPageIndex',
          'dashboard',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'dashboardAchGridPageSize',
          'dashboard',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'dashboardAchGridSortActive',
        'dashboard',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'dashboardAchGridSortDirection',
        'dashboard',
        'desc'
      ),
    };
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'dashboardAchGridPageSize',
        'dashboard'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'dashboardAchGridPageIndex',
        'dashboard'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;

      this._commonService.setListDataToLocalStorage(
        event.active,
        'dashboardAchGridSortActive',
        'dashboard'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'dashboardAchGridSortDirection',
        'dashboard'
      );
    }
  }
  ngOnDestroy() {
    if (!this._router.url.includes('dashboard'))
      localStorage.removeItem('dashboard');
  }
}
