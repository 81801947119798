import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { Pattern } from 'src/app/sharedModules/constants/pattern.const';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';
import { ConfirmedValidator } from './confirmed.validator';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';

interface DrowpDown {
  key: number;
  value: string;
}

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit {
  RoleList: DrowpDown[] = [
    { key: 0, value: 'Admin' },
    { key: 1, value: 'Viewer' },
  ];

  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  dialogRef: MatDialogRef<any, any>;
  public showPassword: boolean = false;
  public showPasswordConfirm: boolean = false;
  addUserModel: addUserModel;
  addUser: FormGroup;
  emailId: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  role: string;
  jobTitle: string;
  isSubscribeReceivingTransactionEmail: boolean = false;
  passwordPattern: any;
  firstNamePattern: any;
  lastNamePattern: any;
  errorMgs: string;
  requiredMgs: string;
  invalidPhoneMgs: string;
  contactFlag: string;
  emailMgs: string;
  errorLastName: string;
  errorFirstName: string;
  errorJobTitle: string;
  jobTitlePattern: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  phoneNumber;
  phone: string;
  maxLength: string = '15';
  phonehint: any = '(201) 555-0123';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private userManagementService: UserManagementService
  ) {
    this.addUser = this.formBuilder.group(
      {
        emailId: [
          null,
          [
            Validators.required,
            Validators.email,
            Validators.pattern(Pattern.email),
          ],
        ],
        password: [null, [Validators.required]],
        confirmPassword: [null, [Validators.required]],
        phone: [null, [Validators.required]],
        firstName: ['', [Validators.required]],
        lastName: [null, [Validators.required]],
        role: [null, [Validators.required]],
        jobTitle: [null, [Validators.required]],
        isSubscribeReceivingTransactionEmail: [],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {
    this.resetForm();
    this.requiredMgs = 'This field is required.';
    this.emailMgs = 'Invalid email address.';
    this.invalidPhoneMgs = 'Invalid phone number.';
    this.passwordPattern = Pattern.password;
    this.firstNamePattern = Pattern.name;
    this.lastNamePattern = Pattern.name;
    this.jobTitlePattern = Pattern.Alpha_Numbers;
    if (this.passwordPattern === Pattern.password) {
      this.errorMgs =
        'Minimum eight characters, at least one uppercase letter, one lowercase letter, one special character and one number';
    }
    if (this.firstNamePattern === Pattern.name) {
      this.errorFirstName = 'Only alphabets are allowed';
    }
    if (this.lastNamePattern === Pattern.name) {
      this.errorLastName = 'Only alphabets are allowed';
    }
    if (this.jobTitlePattern === Pattern.Alpha_Numbers) {
      this.errorJobTitle = 'Only numbers are not allowed';
    }
  }

  get f() {
    return this.addUser.controls;
  }

  navigateToUserPage() {
    this.router.navigate(['/finzeoUsers']);
  }
  addUserManagement() {
    let userModel = new addUserModel();
    userModel.firstName = this.addUser.controls['firstName'].value.trim();
    userModel.lastName = this.addUser.controls['lastName'].value.trim();
    userModel.userName = this.addUser.controls['emailId'].value;
    userModel.email = this.addUser.controls['emailId'].value;
    userModel.jobTitle = this.addUser.controls['jobTitle'].value;
    userModel.role = this.addUser.controls['role'].value;
    userModel.phone = this.addUser.controls['phone'].value.internationalNumber;
    userModel.password = this.addUser.controls['password'].value;
    userModel.isSubscribeReceivingTransactionEmail =
      this.addUser.controls['isSubscribeReceivingTransactionEmail'].value;
    if (userModel.isSubscribeReceivingTransactionEmail == null) {
      userModel.isSubscribeReceivingTransactionEmail = false;
    }
    this.userManagementService.createUser(userModel, this.dialogRef);
  }
  openDialog() {
    if (this.addUser.invalid) {
      this.addUser.controls['firstName'].markAsTouched();
      this.addUser.controls['lastName'].markAsTouched();
      this.addUser.controls['emailId'].markAsTouched();
      this.addUser.controls['role'].markAsTouched();
      this.addUser.controls['phone'].markAsTouched();
      this.addUser.controls['password'].markAsTouched();
      this.addUser.controls['confirmPassword'].markAsTouched();
      this.addUser.controls['jobTitle'].markAsTouched();
      return;
    }
    this.dialogRef = this.dialog.open(this.callAPIDialog);
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
  public togglePasswordConfirmVisibility(): void {
    this.showPasswordConfirm = !this.showPasswordConfirm;
  }
  changePhone(event: any) {
    if (event.iso2.toLowerCase() == this.addUserModel?.isoCode?.toLowerCase()) {
      this.addUser.controls['phone'].setValue(this.addUserModel.phone);
    } else {
      this.addUser.controls['phone'].setValue('');
    }
    this.phonehint = event.placeHolder;
  }
  resetForm() {
    this.addUser.controls['firstName'].setValue(null);
    this.addUser.controls['lastName'].setValue(null);
    this.addUser.controls['emailId'].setValue(null);
    this.addUser.controls['jobTitle'].setValue(null);
    this.addUser.controls['role'].setValue(null);
    this.addUser.controls['phone'].setValue(null);
    this.addUser.controls['password'].setValue(null);
    this.addUser.controls['confirmPassword'].setValue(null);
  }
}
