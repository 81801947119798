import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxMaskModule } from 'ngx-mask';
import { AppMaterialModule } from 'src/app/app-material.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { SharedModule } from 'src/app/sharedModules/shared.module';
import { NonMfaLoginComponent } from './pages/login/non-mfa-login/non-mfa-login.component';
import { NonMfaLoginFailureComponent } from './pages/login/non-mfa-login-failure/non-mfa-login-failure.component';
import { NonMfaLoginFrameComponent } from './pages/login/non-mfa-login-frame/non-mfa-login-frame.component';

@NgModule({
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    NgxIntlTelInputModule,
    SharedModule
  ],
  declarations: [
    NonMfaLoginComponent,
    NonMfaLoginFailureComponent,
    NonMfaLoginFrameComponent
  ],
  exports: [],
  providers: [],
})
export class NonMfaLoginModule {}
