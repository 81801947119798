import { prop } from '@rxweb/reactive-form-validators';

export class InstantBankVerifyModel {
  @prop()
  userId: any;

  @prop()
  customerId: any;

  @prop()
  bankAccountId: any;

  @prop()
  bankName: string;

  @prop()
  SendVia: string;

  @prop()
  employerSubmitted: string;
}
