import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerCardDetailsService } from 'src/app/featureModules/services/customer-card-details.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import creditCardType, {
  getTypeInfo,
  types as CardType,
} from 'credit-card-type';

@Component({
  selector: 'app-debit-credit-cards',
  templateUrl: './debit-credit-cards.component.html',
  styleUrls: ['./debit-credit-cards.component.scss'],
})
export class DebitCreditCardsComponent implements OnInit {
  panelOpenState = true;

  @Input()
  debitCreditCardsList = [];

  @Input()
  customerId: any;

  isLoading: boolean = false;

  @Input()
  activateDeactButton: boolean = true;

  @Output()
  editCardEvent = new EventEmitter();

  userRole: any;
  
  constructor(
    private dialog: MatDialog,
    private _customerCardDetailsService: CustomerCardDetailsService,
    private _toastrService: ToastrService,
    private router: Router
  ) {
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  ngOnInit(): void {}

  deleteCard(card: any, type: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: type.toUpperCase(),
        successMessage: 'Are you sure you want to ' + type + ' this?',
        subSuccessMessage:
          'Clicking on ' + type + ' button will update the status',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._customerCardDetailsService
          .deactivateCustomerCard(card.customerCardId, !card.status)
          .subscribe((data) => {
            this.isLoading = false;
            if (data.data != null) {
              let index = this.debitCreditCardsList.findIndex(
                (obj) => obj.customerCardId == card.customerCardId
              );
              if (index > -1) {
                this.debitCreditCardsList[index].status = !card.status;
              }
              this._toastrService.success('Card status updated successfully!');
            }
          });
      }
    });
  }

  editCardDetails(card: any) {
    this.editCardEvent.emit(card);
  }

  getExpiryDateFormat(card: any) {
    if (card.expMonth.toString().length < 2) {
      card.expMonth = '0' + card.expMonth;
    }
    let expiryDayYear = new Date();

    if (card.expYear.toString().length == 2) {
      card.expYear = expiryDayYear.getFullYear().toString().substring(0,2) + card.expYear;
    }

    expiryDayYear.setMonth(parseInt(card.expMonth)-1);
    expiryDayYear.setFullYear(card.expYear);
    if (expiryDayYear < new Date()) {
      card.isExpiredCard = true;
    } else{
      card.isExpiredCard = false;
    }
    return card.expMonth + '/' + card.expYear;
  }

  getAddressFormat(addres: any, city: any, state: any, zip: any) {
    var address = [];
    addres ? address.push(' ' + addres) : '';
    city ? ', ' + address.push(' ' + city) : '';
    state ? ', ' + address.push(' ' + state) : '';
    zip ? ', ' + address.push(' ' + zip) : '';
    return address.toString();
  }

  cardNumberEncreption(cardNumber: any) {
    if (cardNumber) {
      cardNumber = '**** **** **** ' + cardNumber.slice(cardNumber.length - 4);
    }
    return cardNumber;
  }

  getCardName(cardNumber: any) {
    let cardName = 'Customer Card';
    if (cardNumber) {
      var visaCards = creditCardType(cardNumber);
      if (visaCards && visaCards[0] && visaCards[0].niceType)
        cardName = visaCards[0].niceType;
    }
    return cardName;
  }

  getCardImage(cardNumber: any) {
    let cardImageName = 'assets/grid-icons/default-card.png';
    if (cardNumber) {
      var visaCards = creditCardType(cardNumber);
      if (visaCards && visaCards[0] && visaCards[0].type)
        cardImageName =
          'assets/grid-icons/' + visaCards[0].type + '.png';
    }
    return cardImageName;
  }
}
