<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToInstantVerifyPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Instant Bank Analysis
  </mat-label>
</div>

<div class="page-header-customer-details">
  <div fxLayout="column" fxLayoutGap="16px">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="30px"
      fxLayoutAlign="start stretch"
    >
      <div fxLayout="column">
        <mat-label class="page-header-userNameCircle">{{
          getNameInitials(customerModel?.firstName, customerModel?.lastName)
        }}</mat-label>
      </div>

      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerName">
            {{ customerModel?.firstName }} {{ customerModel?.lastName }}
          </mat-label>
        </div>
        <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start stretch">
          <mat-label class="page-header-customerEmail"
            >Email/Unique id: &nbsp;</mat-label
          >
          <mat-chip-list>
            <mat-chip class="page-header-customerEmailChip">
              {{ customerModel?.email != null ? customerModel?.email : "NA" }}
            </mat-chip>
          </mat-chip-list>

          &nbsp;&nbsp;&nbsp;&nbsp;

          <mat-label class="page-header-customerEmail"
            >Contact No: &nbsp;</mat-label
          >
          <mat-chip-list>
            <mat-chip class="page-header-customerEmailChip">
              {{
                customerModel?.mobileNumber != null
                  ? customerModel?.mobileNumber
                  : "NA"
              }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <span class="spacer"></span>
      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button
            data-html2canvas-ignore="true"
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="onViewReportClick()"
          >
            VIEW REPORT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-data-table
  [columns]="columns"
  [data]="data"
  [isLoading]="isLoading"
  [isLoadingPaging]="isLoadingPaging"
  [page]="page"
  [sortBy]="sortBy"
  [noRecordMessage]="noRecordMessage"
  [showMoreMenu]="true"
  [allColumns]="allColumns"
  [selection]="selection"
  [actionRefresh]="actionRefresh"
  (actionRefreshEvent)="onRefresh($event)"
  (pageChangedEvent)="onPageChanged($event)"
  (sortChangedEvent)="onSortChanged($event)"
  (onFilterEvent)="onFilter($event)"
  (refreshBankTransactionEvent)="refreshBankTransaction($event)"
  [isTableHeading]="true"
  [isTableHeadingText]="'Transaction Log'"
  [isRefreshBanKTransaction]="this.userRole != '1'"
  [isRefreshbankBalance]="this.userRole != '1'"
  [isRefreshall]="this.userRole != '1'"
  [isSearch]="false"
  [isExport]="false"
  [isFilter]="false"
  (ibvViewEvent)="onViewReportClick()"
  [isInstantBankAnalysis]="true"
  [filterText]="this.filter?.toString() ?? ''"
>
</app-data-table>
