import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { paymentLinkAdvanceFilterModel } from 'src/app/featureModules/models/payment-link-advance-filter.model';
import { HostedPaymentService } from 'src/app/featureModules/services/hosted-payment.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-hosted-payment-link-list',
  templateUrl: './hosted-payment-link-list.component.html',
  styleUrls: ['./hosted-payment-link-list.component.scss'],
})
export class HostedPaymentLinkListComponent implements OnInit, OnDestroy {
  advanceFilterOrViewDetails: boolean = true;

  @Input()
  listRefreshEvent = new EventEmitter();

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  countFilter: ListFilter;
  gotDataResponse = false;
  allColumns: TableColumn[];
  filterFormGroup: FormGroup;
  advanceFilters: string;
  advanceFiltersList: any;
  advanceFilterCount:number;
  linkId: any;

  @ViewChild('drawer') drawer: MatDrawer;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'Link Text', name: 'linkText', width: '10%' },
    { displayName: 'Link Purpose', name: 'linkPurpose', width: '34%' },
    { displayName: 'DATE CREATED', name: 'entryDate', width: '12%' },
    { displayName: 'TYPE', name: 'linkType', width: '8%' },
    { displayName: 'AMOUNT', name: 'amount', width: '8%' },
    { displayName: 'LINK EXPIRATION', name: 'dateExpiring', width: '12%' },
    { displayName: 'STATUS', name: 'isActive', width: '8%' },
    { displayName: 'ACTION', name: 'actionLinkPayment', width: '8%' },
  ];

  constructor(
    private router: Router,
    private formBuilder: RxFormBuilder,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _hostedPaymentService: HostedPaymentService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'hostedLinkGridFilter',
      'hostedLink',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'hostedLinkGridPageIndex',
          'hostedLink',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'hostedLinkGridPageSize',
          'hostedLink',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'hostedLinkGridSortActive',
        'hostedLink',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'hostedLinkGridSortDirection',
        'hostedLink',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'hostedLinkAdvanceFilter',
      'hostedLink',
      ''
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'hostedLinkAdvanceFilterForm',
        'hostedLink',
        JSON.stringify(new paymentLinkAdvanceFilterModel())
      )
    );

    let filterModel = new paymentLinkAdvanceFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    //this.restForm();
    this._hostedPaymentService
      .getAllHostedPaymentLink(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();
          if (data.data != null) {
            this.page.length = data.data.totalItems;
            this.data = data.data.items;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;
      this.filter = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'hostedLinkGridFilter',
        'hostedLink'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'hostedLinkGridPageSize',
        'hostedLink'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'hostedLinkGridPageIndex',
        'hostedLink'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'hostedLinkGridSortActive',
        'hostedLink'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'hostedLinkGridSortDirection',
        'hostedLink'
      );
    }
  }

  AddPaymentLinkGeneration() {
    this.router.navigate(['setting/hosted-payment-link/configuration']);
  }

  public paymentLinkView(linkId: any) {
    this.advanceFilterOrViewDetails = false;
    this.linkId = linkId;
  }

  public paymentDuplicateLink(linkId: any) {
    this.router.navigate(['setting/hosted-payment-link/configuration', linkId]);
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();
      let filterModel = new paymentLinkAdvanceFilterModel();
      filterModel.type = 'All';
      filterModel.status = 'All';
      (filterModel.linkExpiry = 'All'),
        (this.filterFormGroup = this.formBuilder.formGroup(filterModel));
    }
  }

  public openFilter() {
    this.advanceFilterOrViewDetails = true;
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'hostedLinkGridPageSize',
          'hostedLink',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'hostedLinkGridSortActive',
        'hostedLink',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'hostedLinkGridSortDirection',
        'hostedLink',
        'desc'
      ),
    };
    this.advanceFilters = '';
    if (data.type != 'All') {
      this.advanceFilters += 'type:' + data.type + '|';
    }
    if (data.status != 'All') {
      this.advanceFilters += 'status:' + data.status + '|';
    }
    if (data.linkExpiry != 'All') {
      this.advanceFilters += 'linkExpiry:' + data.linkExpiry + '|';
    }

    if (data.fromAmount && data.toAmount) {
      if (parseFloat(data.fromAmount) > parseFloat(data.toAmount)) {
        this._toastrService.warning(
          'Amount From cannot be greater than Amount To'
        );
        return;
      } else {
        this.advanceFilters +=
          'amount:' + data.fromAmount + 'to' + data.toAmount + '|';
      }
    }
    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'hostedLinkAdvanceFilter',
      'hostedLink'
    );

    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'hostedLinkAdvanceFilterForm',
      'hostedLink'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  deleteBasedOnSelection(data: any) {
    let type = !data.isActive ? 'Activate' : 'Deactivate';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: !data.isActive ? false : true,
        buttonName: type,
        successMessage: "Are you sure you want to '" + type + "' this?",
        subSuccessMessage:
          "Clicking on '" + type + "' button will update the status",
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._hostedPaymentService
          .updatePaymentLinkStatus(data.id, !data.isActive)
          .subscribe(
            (data) => {
              this.isLoading = false;
              this.loadData(
                this.filter,
                this.sortBy.active,
                this.sortBy.direction,
                this.page.pageIndex,
                this.page.pageSize,
                this.advanceFilters
              );
            },
            (error) => {
              this.isLoading = false;
              this._toastrService.error(
                'Something went wrong, Please contact administrator!'
              );
            }
          );
      }
    });
  }

  ngOnInit() {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.deletedFilterEvent.subscribe(this.onDeletedFilter.bind(this));
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    if(!this.router.url.includes('hosted-payment-link'))
    localStorage.removeItem('hostedLink');
  }

  navigateToPage() {
    this.router.navigate(['setting']);
  }
}
