import { Injectable } from '@angular/core';
import { PaymentPageCustomizationModel } from 'src/app/externalModules/paymentWidget/models/payment-page-customization.model';
import { PaymentThemeModel } from 'src/app/externalModules/paymentWidget/models/payment-theme.model';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    isExists(key: string): boolean {
        return this.getItemByKey(key) ? true : false;
    }

    getItemByKey(key: string): any {
        return localStorage.getItem(key);
    }

    setItem(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    clearItemByKey(key: string): void {
        localStorage.removeItem(key);
    }

    clearItems(): void {
        localStorage.clear();
    }

    getItemFromJsonForm(key: string): any {
        let jsonStringValue = this.getItemByKey(key);
        return jsonStringValue ? JSON.parse(jsonStringValue) : null;
    }

    setItemInJsonForm(key: string, value: any): void {
        let jsonStringValue = JSON.stringify(value);
        this.setItem(key, jsonStringValue);
    }

    getItemFromObjectByKey(key: string, objectType: string): any {
        let dataObject = this.getItemFromJsonForm(key);
        if (dataObject && dataObject[objectType]) {
            return dataObject[objectType];
        }
        return "";
    }

    getPaymentThemeBasedObjectByKey(key: string) : PaymentThemeModel {
        return this.getItemFromJsonForm(key);
    }

    setPaymentThemeBasedObjectByKey(key: string, headerBgColor: string, buttonBgColor: string, labelColor: string, companyLogo: string) {
        let value: PaymentThemeModel = {
            headerBgColor: headerBgColor,
            buttonBgColor: buttonBgColor,
            labelColor: labelColor,
            companyLogo: companyLogo
        }
        this.setItemInJsonForm(key, value);
    }

    getPaymentPageCustomizationBasedObjectByKey(key: string) : PaymentPageCustomizationModel {
        return this.getItemFromJsonForm(key);
    }

    setPaymentPageCustomizationBasedObjectByKey(key: string, pageCustomizationSettingsJsonData: string, allowPaymentOptions: string) {
        let value: PaymentPageCustomizationModel = {
            pageCustomizationSettings: JSON.parse(pageCustomizationSettingsJsonData),
            allowPaymentOptions: allowPaymentOptions
        }
        this.setItemInJsonForm(key, value);
    }
}