import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-labels';
import { BaseChartDirective } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReportsService } from 'src/app/featureModules/services/reports.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

@Component({
  selector: 'app-report-amount-transaction',
  templateUrl: './report-amount-transaction.component.html',
  styleUrls: ['./report-amount-transaction.component.scss'],
})
export class ReportAmountTransactionComponent implements OnInit, OnChanges {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';
  chartData: any = [];
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  @Input()
  searchFields: any;

  constructor(
    private _toastrService: ToastrService,
    private router: Router,
    private reportsService: ReportsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.GetTotalTransactionAmountForAllServices();
  }

  ngOnInit(): void {}

  GetTotalTransactionAmountForAllServices() {
    this.isLoading = true;
    this.chartData = [];

    this.searchFields.startDate = new Date(this.searchFields.startDate);
    this.searchFields.endDate = new Date(this.searchFields.endDate);
    this.searchFields.startDate?.setHours(12, 0, 0);
    this.searchFields.endDate?.setHours(12, 0, 0);

    this.reportsService
      .GetTotalTransactionAmountForAllServices(
        getSafeIsoDateString(this.searchFields.startDate),
        getSafeIsoDateString(this.searchFields.endDate)
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.chartData.push(
              {},
              {
                data: [
                  data.data.ach.percentage?.toFixed(2),
                  data.data.cards.percentage?.toFixed(2),
                  data.data.eCheck.percentage?.toFixed(2),
                  //data.data.instantFund.percentage?.toFixed(2),
                ],
                backgroundColor: '#283593',
                label: '',
              },
              {}
            );
          }
          this.barChartData.datasets = this.chartData;
          this.chart?.update();
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {},
      y: {
        min: 0,
        max: 100,
        ticks: {
          // Include a percentrage sign in the ticks
          callback: function (value, index, ticks) {
            return value + '%';
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return (+tooltipItem.formattedValue)?.toFixed(2) + '%';
          },
        },
      },
    },
  };

  public barChartType: ChartType = 'bar';

  public barChartPlugins = [DataLabelsPlugin];

  public barChartData: ChartData<'bar'> = {
    // hiding the INF feature
    //labels: ['ACH', 'Credit/Debit', 'E-Check', 'Instant Fund'],
    labels: ['ACH', 'CREDIT/DEBIT', 'E-CHECK'],
    datasets: this.chartData,
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: {}[];
  }): void {}
}
