import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { scoreSettingsModel } from 'src/app/sharedModules/models/score-settings.model';
import { CustomerChallengerScoreServiceProxy, ScoreSettingsModel } from 'src/app/sharedModules/services/customer/customer.proxies';
import { RangeDeductScoreComponent } from '../range-deduct-score/range-deduct-score.component';
import { RatioMaxPointComponent } from '../ratio-max-point/ratio-max-point.component';
import { validateScoreSettings } from '../validation/validateScoreSettings';
import { ScoreSettingRuleCardModel, ScoreSettingRuleCardModelArgs } from './models/score-settings-model';
@Component({
  selector: 'app-score-settings',
  templateUrl: './score-settings.component.html',
  styleUrls: ['./score-settings.component.scss']
})
export class ScoreSettingsComponent implements OnInit {
  isLoading: boolean = false;
  dataSource: ScoreSettingsModel;
  _scoreRuleModels: ScoreSettingRuleCardModel[] = [];
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  @ViewChild('callAPIDialogReset') callAPIDialogReset: TemplateRef<any>;

  dialogRef : MatDialogRef<any, any>;
  public canSave : boolean = true;
  public validateErrors : any;
  constructor(
    private dialog : MatDialog,
    private _toastrService: ToastrService,
    private _scoreService: CustomerChallengerScoreServiceProxy) { }

  ngOnInit(): void {
    this.initRules();
    
  }

  public initRules() {
    this.isLoading = true;
    this._scoreRuleModels = [];
    this._scoreService.getChallengerScoreSettings().subscribe({
      next: (resp: any) => {
        this.dataSource = resp.data as ScoreSettingsModel;
        this.bindingRules();
      }, 
      error: (error: any) => {}, 
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  private bindingRules() {
    this._scoreRuleModels = [];
    this.dataSource.settingRules.forEach((s => {
      this._scoreRuleModels.push(ScoreSettingRuleCardModel.fromDto(s));
    }));
  }

  public onSettingRuleChanged(args: ScoreSettingRuleCardModelArgs) {
    this._scoreRuleModels[args.ruleIndex] = args.rule;

    for(var i = 0; i < this._scoreRuleModels.length; i++)
    {
      this.validateErrors = validateScoreSettings(this._scoreRuleModels[i]);
      this.canSave = true;
      if(this.validateErrors?.length > 0){
        this.canSave = false;
        return;
      }
    }
  }
  
  public saveRules() {

    for(var i = 0; i < this._scoreRuleModels.length; i++)
    {
      this.validateErrors = validateScoreSettings(this._scoreRuleModels[i]);

      if(this.validateErrors?.length > 0){
        this._toastrService.error('Failed to save Global Grade Settings');
        return;
      }
    }
    
    let scoreSettingsModel = new ScoreSettingsModel();
    scoreSettingsModel.settingRules = this._scoreRuleModels.map(r => ScoreSettingRuleCardModel.toDto(r));
    this.isLoading = true;
    this._scoreService.setChallengerScoreSettings(scoreSettingsModel).subscribe({
      next: (resp: any) => {
        if(resp.code == 200)
        {
          this._toastrService.success('Saved Score setting successfully');
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.isLoading = false;
      }
    });
  }


  public resetRules() {
    this.isLoading = true;
    this.dialogRef.close();

    this.isLoading = true;
    this._scoreRuleModels = [];
    this._scoreService.getChallengerScoreSettings().subscribe({
      next: (resp: any) => {
        this.validateErrors = null;
        this.canSave = true;
        this.dataSource = resp.data as ScoreSettingsModel;
        this.bindingRules();
        this.canSave = true;
        this._toastrService.success('Reset Score Settings to values before edit successfully');

      }, 
      error: (error: any) => {}, 
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  
  public restoreRules() {
    this.isLoading = true;
    this.dialogRef.close();
    this._scoreService.resetChallengerScoreSettings().subscribe({
      next: (resp: any) => {
        if(resp.code == 200)
        {
          this.validateErrors = null;
          this.canSave = true;
          this.dataSource = resp.data as ScoreSettingsModel;
          this.bindingRules();
          this._toastrService.success('Restored Score Settings to default values successfully');
        }
        else
        {
          this._toastrService.error(resp.errorMessage);
        }
      },
      error: (error: any) => {
        this.isLoading = false;
        this._toastrService.error(error);
      }, 
      complete: () => {
        this.isLoading = false;
      }
    });
  }
 

  openDialog() {
    this.dialogRef = this.dialog.open(this.callAPIDialog);
  }

  openDialogReset() {
    this.dialogRef = this.dialog.open(this.callAPIDialogReset);
  }

}
