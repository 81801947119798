import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription, take, timer } from 'rxjs';

@Component({
  selector: 'app-session-time-out-dialog',
  templateUrl: './session-time-out-dialog.component.html',
  styleUrls: ['./session-time-out-dialog.component.scss'],
})
export class SessionTimeOutDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionTimeOutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  countDown: Subscription;
  tick = 1000;
  counter: number = 60 * 1;

  ngOnInit(): void {
    this.countdownTime();
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  countdownTime() {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.countDown.unsubscribe();
          this.closeDialog();
        }
      });
  }
}
