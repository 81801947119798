import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { CreditDebitTransactionService } from 'src/app/featureModules/services/creditDebit-transaction.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { DashboardService } from 'src/app/featureModules/services/dashboard.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
  ChartConfiguration,
  ChartData,
  ChartEvent,
  ChartOptions,
  ChartType,
} from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-dashboard-report-return-rate',
  templateUrl: './dashboard-report-return-rate.component.html',
  styleUrls: ['./dashboard-report-return-rate.component.scss'],
})
export class DashboardReportReturnRateComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  isLoading: boolean = false;
  loadMessage: string = 'Please wait..';
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  returnRateGridData;
  transactionType: string = '';
  private timeout: number;
  tableLabel: string = '';
  period: string = 'yearly';

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'month',
    direction: 'asc',
  };

  returnRateColumns: TableColumn[] = [
    { displayName: 'MONTH', name: 'month', width: '11%' },
    {
      displayName: 'RETURN RATE (LY)',
      name: 'lastYearReturnRate',
      width: '20%',
    },
    {
      displayName: 'RETURN RATE (TY)',
      name: 'currentYearReturnRate',
      width: '20%',
    },
  ];

  totalAllColumns: TableColumn[];

  periodCurrentLabel: string = '';
  periodLastLabel: string = '';

  isAdminDataAvailable: boolean = false;
  isHostileDataAvailable: boolean = false;
  isNonHostileDataAvailable: boolean = false;

  constructor(
    private router: Router,
    private _dashboardService: DashboardService,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService
  ) {
    this.totalAllColumns = this.returnRateColumns;
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.transactionType = params.get('product');
      this.tableLabel = 'Return Rate';
    });
    this.loadData(
      this.transactionType,
      this.sortBy.active,
      this.sortBy.direction
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.periodCurrentLabel = 'This Year';
    this.periodLastLabel = 'Last Year';
    this.loadData(
      this.transactionType,
      this.sortBy.active,
      this.sortBy.direction
    );
    this.GetReturnRateData();
  }

  public doughnutChartLabels: string[] = [
    this.periodLastLabel,
    this.periodCurrentLabel,
  ];
  public doughnutChartAdminData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#2196F3'],
        borderJoinStyle: 'round',
        borderWidth: 0,
      },
    ],
  };

  public doughnutChartHostileData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#FB8C00'],
        borderJoinStyle: 'round',
        borderWidth: 0,
      },
    ],
  };

  public doughnutChartNonHostileData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        data: [0, 0],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0,
      },
    ],
  };

  GetReturnRateData() {
    this.isLoading = true;
    this.doughnutChartLabels = [this.periodLastLabel, this.periodCurrentLabel];
    this.doughnutChartAdminData.labels = this.doughnutChartLabels;
    this.doughnutChartHostileData.labels = this.doughnutChartLabels;
    this.doughnutChartNonHostileData.labels = this.doughnutChartLabels;

    this._dashboardService
      .GetTransactionsReturnedRatePercentage(this.period)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.doughnutChartAdminData.datasets[0].data = [
              data.data.admin.previous,
              data.data.admin.current,
            ];

            this.doughnutChartHostileData.datasets[0].data = [
              data.data.hostile.previous,
              data.data.hostile.current,
            ];

            this.doughnutChartNonHostileData.datasets[0].data = [
              data.data.nonHostile.previous,
              data.data.nonHostile.current,
            ];

            this.isAdminDataAvailable =
              data.data.admin.previous == 0 && data.data.admin.current == 0
                ? false
                : true;
            this.isHostileDataAvailable =
              data.data.hostile.previous == 0 && data.data.hostile.current == 0
                ? false
                : true;
            this.isNonHostileDataAvailable =
              data.data.nonHostile.previous == 0 &&
              data.data.nonHostile.current == 0
                ? false
                : true;
          }
        },
        (error) => {
          this.isLoading = false;
          this.loadMessage = '';
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  navigateToDashboardPage() {
    this.router.navigate(['dashboard']);
  }

  onRefresh(id: string) {}

  loadData(transactionType, sortBy: string, sortDirection: string) {
    this.isLoading = true;

    this._dashboardService
      .GetTransactionsReturnedRateComparision(this.transactionType)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.returnRateGridData = data.data;
            this.page.length = data.data.totalRecords;
          } else {
            this.returnRateGridData = [];
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.transactionType,
        this.sortBy.active,
        this.sortBy.direction
      );
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.transactionType,
        this.sortBy.active,
        this.sortBy.direction
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.totalAllColumns[event.active];
    var column = this.totalAllColumns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.transactionType,
        this.sortBy.active,
        this.sortBy.direction
      );
    }
  }

  sortByMapping() {
    switch (this.sortBy.active) {
      case 'month':
        this.sortBy.active = 'month';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick(customer: any) {}

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: this.doughnutChartLabels,
    datasets: [
      {
        label: 'Return Rate',
        data: [50, 50],
        backgroundColor: ['#1E4ED8', '#FF5252'],
        borderJoinStyle: 'round',
        borderWidth: 0,
      },
    ],
  };
  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartReturnRateOptions: ChartOptions<'doughnut'> = {
    cutout: 50,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
    radius: 60,
  };

  // events
  public chartClicked({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event: ChartEvent;
    active: {}[];
  }): void {
    // console.log(event, active);
  }
}
