

<div fxLayout="column" [class]="previewWiseStyle.inputControlClass" class="container" [style.height]="previewWiseStyle.divPreviewHeight" fxLayoutWrap>
    <div class="payment-header-dv" fxLayout="row" fxFlex="50%" fxLayoutWrap
        [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'" fxLayoutAlign="center center">
        <img [class]="deviceWiseStyle.companyLogoClass" class="company-logo" *ngIf="!isLoading" [src]="getLogoPath(this.formData)" alt="company logo" />
    </div>
    <div [class]="deviceWiseStyle.paymentDivClass" class="payment-div"  [style.--active-color-fontFamily]="this.formData?.customisationTabForm?.themeFont">
        <div *ngIf="!isLoading" [class]="deviceWiseStyle.paymentOrderContainerClass" class="payment-order-container w100" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10%">
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
                <label class="header-label" [style.font-size]="previewWiseStyle.headerLableFontSize">Order Details</label>
            </div>
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
                <div class="w100" *ngIf="!isLoading" fxLayout="column" fxLayoutGap="2.5%" fxLayoutWrap fxLayoutAlign="start space-between">
                    <div *ngIf="validateField(this.formData?.configurationTabForm?.isInvoiceNumberView)" fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
                        <div class="order-label" [style.font-size]="previewWiseStyle.orderLableFontSize"  fxLayout="column" fxLayoutWrap
                            fxLayoutAlign="center start">
                            CONFIRMATION NUMBER
                        </div>
                        <div class="order-value" [style.font-size]="previewWiseStyle.orderValueFontSize" fxLayout="column" fxLayoutWrap fxLayoutAlign="center end">
                            {{ this.invoiceNumber }}
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutWrap fxLayoutAlign="space-between center">
                        <div class="order-label" [style.font-size]="previewWiseStyle.orderLableFontSize" fxLayout="column" fxLayoutWrap
                            fxLayoutAlign="center start">
                            AMOUNT
                        </div>
                        <div class="order-value"  [style.font-size]="previewWiseStyle.orderValueFontSize" fxLayout="column" fxLayoutWrap fxLayoutAlign="center end">
                            ${{this.amount | number : "1.2-2" }}
                        </div>
                    </div>
                </div>
            </div>
            <mat-spinner class="loadSpinner"  *ngIf="changesIsLoading"  color="accent" diameter="50"></mat-spinner>
            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="start space-between"  *ngIf="validateField(this.formData?.configurationTabForm?.isDescriptionView)">
                <div class="w100" fxLayout="column" fxLayoutGap="1.25%" fxLayoutWrap
                    fxLayoutAlign="start space-between">
                    <div class="order-description-label" [style.font-size]="previewWiseStyle.orderLableFontSize" fxLayout="row" fxLayoutWrap
                        fxLayoutAlign="start space-between">
                        Description
                    </div>
                    <div class="order-description-value" [style.font-size]="previewWiseStyle.orderValueFontSize" fxLayout="row" fxLayoutWrap
                        fxLayoutAlign="start space-between"
                        title="{{ this.description }}">
                        {{this.description}}
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutWrap fxLayoutAlign="center center">
                <button [disabled]="true" class="payment-button"  [style.font-size]="previewWiseStyle.buttonTextFontSize"
                [style.--active-color-buttonBgColor]="this.formData?.customisationTabForm?.themeColor ?? '#1e4ed8'"
                [style.--active-color-textColor]="this.formData?.customisationTabForm?.themeTextColor ?? '#1e4ed8'"
                [style.font-family]="this.formData?.customisationTabForm?.themeFont" mat-flat-button>Make Payment</button>
                
            </div>  

        </div>
    </div>
    <div fxLayout="row" fxFlex="50%" fxLayoutWrap class="payment-footer-dv" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutWrap fxLayoutAlign="center center">
        <img [class]="deviceWiseStyle.securePaymentLogo" [style.width]="previewWiseStyle.logoImageHeight" class="secure-payment-logo" src="/assets/payment-widget-icons/securePayment3.png"
            alt="secure-payment logo" /> 
            <label [style.font-size]="previewWiseStyle.buttonTextFontSize"
            [style.font-family]="this.formData?.customisationTabForm?.themeFont"> 
            Powered By {{logotext}}, an Elavon payments partner & registered MSP/ISO of Elavon</label>
        </div>
    </div>
</div>
