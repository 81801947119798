import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-setting-tab',
  templateUrl: './setting-tab.component.html',
  styleUrls: ['./setting-tab.component.scss']
})
export class SettingTabComponent implements OnInit {

  
  @Input()
  paymentLinkFormGroup : FormGroup;

  //DateTimePicker Controls
  @ViewChild('picker') picker: any;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = true;
  public enableMeridian = false;
  public minDate:Date = new Date();
  public maxDate:Date = new Date();;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateControl = new FormControl(new Date());

  timePick : boolean = false;
  dateTimePick : Boolean =false;
  
  constructor() { }
  

  ngOnInit(): void {
    this.maxDate.setMonth(this.maxDate.getMonth() + 1);
    this.onExpirationChanged();
  }
  onExpirationChanged(){
    switch (this.paymentLinkFormGroup.controls['settingTabForm']['controls']['dateExpiring'].value) {     
      case 'Never':
        this.timePick = false;
        this.dateTimePick = false ; 
        this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificTime'].setValue('');
        this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificDateTime'].setValue('');
        break;
      case 'One Time':
        this.timePick = false;
        this.dateTimePick = false ; 
        this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificTime'].setValue(''); 
        this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificDateTime'].setValue('');       
        break;
      case 'Time Bound':
          this.timePick = true ;
          this.dateTimePick = false ;  
          this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificDateTime'].setValue(''); 
          break;
      case 'DateTime Bound':
          this.dateTimePick = true ;   
          this.timePick = false ;    
          this.paymentLinkFormGroup.controls['settingTabForm']['controls']['specificTime'].setValue('');   
          break;
    }       
  }

  isRecurringAllowedChanged(event : any){
    this.paymentLinkFormGroup.controls['settingTabForm']['controls']['isRecurringAllowed'].setValue(event.value)
  }

}
