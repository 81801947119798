<mat-card class="recurring-credit-card" *ngIf="isLoading">
  <mat-spinner
    class="loadSpinner"
    mode="indeterminate"
    [diameter]="50"
    [strokeWidth]="3"
  >
  </mat-spinner>
</mat-card>

<div
  *ngIf="!isLoading"
  fxLayout="row"
  fxLayoutGap="12px"
  fxLayoutAlign="start stretch"
>
  <mat-card class="recurring-credit-card" fxLayout="column" fxFlex="100%">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      style="margin-bottom: 1%"
    >
      <div fxLayout="column">
        <img
          class="icons"
          src="assets/ibv-report-icons/checking_savings/sectionIcons/potentialPayments.png"
        />
      </div>
      <div fxLayout="column" fxLayoutWrap fxLayoutAlign="start start">
        <span class="section-header">Recurring Credits</span>
      </div>
    </div>

    <div
      fxLayout="column"
      fxFlex="100%"
      fxLayoutAlign="start stretch"
      fxLayoutWrap
      style="overflow-y: auto"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="center start"
        style="margin: 1%"
        fxLayoutWrap
      >
        <div fxLayout="column" fxFlex="100%" fxLayoutWrap>
          <div
            fxLayout="row"
            fxLayoutAlign="start space-between"
            fxLayoutGap="1%"
            style="padding: 2%"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="16.6%"
              class="recurring-credit-header-labels"
            >
              SOURCE
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="16%"
              class="recurring-credit-header-labels"
            >
              FIRST DEPOSIT
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="20.6%"
              class="recurring-credit-header-labels"
            >
              # OF RECURRING
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center start"
              fxFlex="13.6%"
              class="recurring-credit-header-labels"
            >
              FREQUENCY
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              fxFlex="15.6%"
              class="recurring-credit-header-labels"
            >
              AVG. PAYMENT
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="center end"
              fxFlex="15.6%"
              class="recurring-credit-header-labels"
            >
              LAST PAYMENT
            </div>
          </div>
          <mat-divider></mat-divider>
          <div
            *ngIf="noDataForRecurringCredit"
            fxLayout="row"
            fxLayoutAlign="start stretch"
            style="padding: 1.3%"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxFlex="100%"
              class="text-labels normal-style"
              fxLayoutAlign="center center"
            >
              No data available
            </div>
          </div>
          <div
            *ngFor="let transaction of transactions"
            fxLayout="row"
            fxLayoutAlign="start space-between"
            fxLayoutGap="1%"
            style="padding: 1% 2%"
            fxLayoutWrap
          >
            <div
              fxLayout="column"
              fxFlex="16.6%"
              fxLayoutAlign="start stretch"
              class="recurring-credit-content"
            >
              {{ transaction.source }}
            </div>
            <div
              fxLayout="column"
              fxFlex="16%"
              fxLayoutAlign="center center"
              class="recurring-credit-content"
            >
              {{ transaction.firstDeposit }}
            </div>
            <div
              fxLayout="column"
              fxFlex="20.6%"
              fxLayoutAlign="center center"
              class="recurring-credit-content"
            >
              {{ transaction.recurringDates }}
            </div>
            <div
              fxLayout="column"
              fxFlex="13.6%"
              fxLayoutAlign="center center"
              class="recurring-credit-content"
            >
              {{ transaction.frequency }}
            </div>
            <div
              fxLayout="column"
              fxFlex="15.6%"
              fxLayoutAlign="center end"
              class="recurring-credit-content"
            >
              {{ transaction.avgPayment | currency }}
            </div>
            <div
              fxLayout="column"
              fxFlex="15.6%"
              fxLayoutAlign="center end"
              class="recurring-credit-content"
            >
              {{ transaction.lastPayment | currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
