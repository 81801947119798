<div fxLayout="column" fxLayout.xs="column">
  <div fxLayout="row" fxLayout.xs="column" class="html-code-box" fxFlex="100%">
    <div
      fxLayout="column"
      fxFlex="40%"
      fxLayoutAlign="center start"
      class="html-code-text"
    >
      Html Code
    </div>
    <div fxLayout="column" fxFlex="60%" fxLayoutAlign="center end">
      <button
        (click)="copyHtmlCode()"
        [cdkCopyToClipboard]="this.paymentLink"
        class="copyButtonStyle"
      >
        <img
          *ngIf ="this.paymentLink"
          class="img-copyhtmlcode"
          src="/assets/payment-widget-icons/copy-html-code.png"
          alt="Copy-Code"
          title="Copy Html Code"
        />
      </button>
    </div>
  </div>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
    class="html-code-editor-style"
  >
    <mat-label class="link-style-code">
      <pre>{{ paymentLink }}</pre>
    </mat-label>
  </div>
</div>
