import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payment-link-advance-filter',
  templateUrl: './payment-link-advance-filter.component.html',
  styleUrls: ['./payment-link-advance-filter.component.scss']
})
export class PaymentLinkAdvanceFilterComponent implements OnInit {

  @Input()
  filterFormGroup: FormGroup;

  statusList = [
    { key: 'All', value: 'All' },
    { key: true, value: 'Active' },
    { key: false, value: 'InActive' },
  ];

  typeList = [
    { key: 'All', value: 'All' },
    { key: 'Button', value: 'Button' },
    { key: 'Link', value: 'Link' },
  ];

  expiryList = [
    { key: 'All', value: 'All' },
    { key: 'Never', value: 'Never' },
    { key: 'One Time', value: 'One Time' },   
    { key: 'Time Bound', value: 'Time Bound' },
    { key: 'DateTime Bound', value: 'DateTime Bound' },
  ];

  @Output()
  drawerCloseEvent = new EventEmitter();

  @Output()
  applyAdvanceFilterEvent = new EventEmitter();

  @Output()
  restFormEvent = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  drawerClose() {
    this.drawerCloseEvent.emit();
  }

  applyAdvanceFilter() {
    this.applyAdvanceFilterEvent.emit();
  }

  restForm() {
    this.restFormEvent.emit();
  }
}
