import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InstantVerifyService } from 'src/app/blockModules/services/instant-verify.service';
import { SendVia } from 'src/app/featureModules/enums/sendVia';
import { InstantBankVerifyModel } from 'src/app/featureModules/models/add-instant-bank-verify';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-instant-bank-verify',
  templateUrl: './add-instant-bank-verify.component.html',
  styleUrls: ['./add-instant-bank-verify.component.scss'],
})
export class AddInstantBankVerifyComponent implements OnInit {
  customerFormGroup: FormGroup;
  bankFormGroup: FormGroup;
  finalFormGroup: FormGroup;
  employerFormGroup: FormGroup;

  isLoading: boolean = false;
  bankList = [];
  customerId: any;
  selectedBank: any;

  @ViewChild('stepper') stepper: MatStepper;
  bankNameRelatedMessage: string =
    'Bank accounts with bank names will only appear on this list.';

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    private _customerBankDetailsService: CustomerBankDetailsService,
    private instantVerifyService: InstantVerifyService,
    private dialog: MatDialog,
    private _toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.customerFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
      bankAccount: [true, Validators.required],
    });
    this.bankFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
    });
    this.employerFormGroup = this._formBuilder.group({
      employerSubmitted: [
        '',
        Validators.pattern(/^(?=.*\S)[a-zA-Z0-9 ,.\-\(\)]*$/),
      ],
    });
    this.finalFormGroup = this._formBuilder.group({
      verification: [2, Validators.required],
    });
  }

  customerContinue(stepper: MatStepper) {
    if (
      this.customerId &&
      !this.customerFormGroup.controls['bankAccount'].value
    ) {
      stepper.next();
      stepper.next();
    } else {
      stepper.next();
    }
  }

  onRadioSelected(event: any) {
    if (!event.value) {
      this.bankNameRelatedMessage = '';
      this.bankFormGroup.patchValue({ secondCtrl: 'selected' });
      this.selectedBank = null;
    } else {
      this.bankNameRelatedMessage =
        'Bank accounts with bank names will only appear on this list.';
      this.bankFormGroup.patchValue({ secondCtrl: null });
    }
  }

  navigateToIbvPage() {
    this.router.navigate(['instantBankAnalysis']);
  }

  selectedCustomerName: string = '';
  onCustomerSelection(customer: any) {
    if (customer != null) {
      this.customerId = customer.customerId;
      this.selectedCustomerName = (customer.firstName + ' ' + customer.lastName)
        .trim()
        .toLocaleUpperCase();
      this.customerFormGroup.patchValue({ firstCtrl: customer.firstName });
      this.getBankDetails();
    } else {
      this.customerId = null;
      this.selectedCustomerName = '';
      this.customerFormGroup.patchValue({ firstCtrl: '' });
    }
    this.selectedBank = '';
  }

  getBankDetails() {
    this.isLoading = true;
    this.bankList = [];
    this._customerBankDetailsService
      .GetAllBankAccounts(this.customerId, true)
      .subscribe(
        (data) => {
          this.isLoading = false;
          if (data.data != null) {
            this.bankList = data.data.listOfBankAccountsToReturn.filter(
              (obj) =>
                obj.bankName != null &&
                obj.bankName != '' &&
                obj.bankName != 'N/A'
            );
            this.customerFormGroup.controls['bankAccount'].enable();
          }
          if (this.bankList?.length == 0) {
            this.bankNameRelatedMessage = '';
            this.customerFormGroup.patchValue({ bankAccount: false });
            this.bankFormGroup.patchValue({ secondCtrl: 'selected' });
            this.customerFormGroup.controls['bankAccount'].disable();
          }
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  sendVerification() {
    if (
      this.customerFormGroup.valid &&
      this.bankFormGroup.valid &&
      this.employerFormGroup.valid &&
      this.finalFormGroup.valid
    ) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '200px',
        data: {
          buttonName: 'YES',
          successMessage: 'Are you sure you want to send this?',
          subSuccessMessage:
            'Verification link will be sent via ' +
            (this.finalFormGroup.value.verification == 3
              ? SendVia.SMS_EMAIL
              : SendVia[this.finalFormGroup.value.verification]),
        },
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          let instantBankVerify = new InstantBankVerifyModel();
          instantBankVerify.userId = 'd0a53cae-2bfd-4d4b-993a-2be5cc8a2309';
          instantBankVerify.customerId = this.customerId;

          if (this.customerFormGroup.value.bankAccount) {
            if (this.selectedBank) {
              instantBankVerify.bankAccountId =
                this.selectedBank.customerBankAccountId;
              instantBankVerify.bankName = this.selectedBank.bankName;
            }
          }
          instantBankVerify.SendVia =
            SendVia[this.finalFormGroup.value.verification];

          instantBankVerify.employerSubmitted =
            this.employerFormGroup.value.employerSubmitted;

          this.isLoading = true;
          this.instantVerifyService
            .SendInstantVerifyRequest(instantBankVerify)
            .subscribe(
              (data) => {
                this.isLoading = false;
                if (data.code == 201) {
                  this._toastrService.success(
                    'Verification Link Sent Successfully'
                  );
                  this.router.navigate(['instantBankAnalysis']);
                } else if (data.errorMessage != null) {
                  this._toastrService.error(data.errorMessage);
                }
              },
              (error) => {
                this.isLoading = false;
                this._toastrService.error(
                  'Something went wrong, Please contact administrator!'
                );
              }
            );
        }
      });
    }
  }

  onBankSelected(event: any, bank: any) {
    this.bankList.forEach((ob) => (ob.bankSelection = false));
    bank.bankSelection = event.value;
    this.selectedBank = bank;
    this.bankFormGroup.patchValue({ secondCtrl: bank.bankSelection });
  }

  bankEncreption(bankAccountNumber: any) {
    if (bankAccountNumber) {
      bankAccountNumber =
        '**** **** **** ' +
        bankAccountNumber.slice(bankAccountNumber.length - 4);
    }
    return bankAccountNumber;
  }
}
