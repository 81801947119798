import { AbstractControl } from "@angular/forms";
import { maxLength, pattern, prop, required } from "@rxweb/reactive-form-validators";

export class InventoryFilterModel {
  @prop()
  quantityFrom: number;

  @prop()
  quantityTo: number;

  @prop()
  priceFrom: number;

  @prop()
  priceTo: number;

  @prop()
  @required({
    conditionalExpression: function (control: AbstractControl) {      
      return this.periodEndDate != null ? true : false;
    },
  })
  periodStartDate: Date;

  @prop() 
  @required({
    conditionalExpression: function (control: AbstractControl) {      
      return this.periodStartDate != null ? true : false;
    },
  }) 
  periodEndDate: Date;

  @prop()
  createdBy: string;

  @prop()
  createdById: string;

  @prop()
  isActive: boolean;

  @prop()
  categoryId:any ;  
}
