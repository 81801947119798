import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerScoreStatsCard1Component } from 'src/app/featureModules/components/customer-score-stats-related/customer-score-stats-card1/customer-score-stats-card1.component';
import { CustomerScoreStatsCard2Component } from 'src/app/featureModules/components/customer-score-stats-related/customer-score-stats-card2/customer-score-stats-card2.component';
import { CCustomerScoreStatsComponent as CustomerScoreStatsComponent } from './customer-score-stats.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule } from 'ng2-charts';
import { Ng5SliderModule } from 'ng5-slider';
import { AppMaterialModule } from 'src/app/app-material.module';
import { SharedModule } from 'src/app/sharedModules/shared.module';
import { CustomerScoreStatsRoutingModule } from './customer-score-stats-routing.module';


@NgModule({
  declarations: [
    CustomerScoreStatsComponent,
    CustomerScoreStatsCard1Component,
    CustomerScoreStatsCard2Component
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AppMaterialModule,
    SharedModule,
    Ng5SliderModule,
    NgChartsModule,
    GaugeChartModule,
    CustomerScoreStatsRoutingModule
  ],
  exports: [
    CustomerScoreStatsComponent
  ]
})
export class CustomerScoreStatsModule { }
